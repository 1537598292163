import update from 'immutability-helper';

const WIDGET_SET_EXTERNAL_USER_ID = 'WIDGET_SET_EXTERNAL_USER_ID';

const setExternalUserIdAction = (externalUserId) => {
  return (dispatch) => {
    dispatch({ type: WIDGET_SET_EXTERNAL_USER_ID, externalUserId  });
  }
}

const setExternalUserIdReducer = (state, action) => {
  return {
    ...state,
    externalUserId: update(state.externalUserId, { $set: action.externalUserId })
  };
}

export {
  WIDGET_SET_EXTERNAL_USER_ID,
  setExternalUserIdAction,
  setExternalUserIdReducer
}
