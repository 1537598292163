export function isFormValid (formValidation: any): boolean {
  return (
    Object
      .keys(formValidation)
      .reduce(
        (result: boolean, validationKey: string) => {

          if (!result) {
            return result;
          }

          if (typeof formValidation[validationKey] === 'object') {
            return isFormValid(formValidation[validationKey]);
          }

          if (typeof formValidation[validationKey] === 'string') {
            return false;
          } else {
            return true;
          }

        },
        true
      )
  );
}
