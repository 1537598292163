import update from 'immutability-helper';
import { handleError } from '../../_module';

export function getVriRequest(state, action) {
  return {
    ...state,
    _flags: update(state._flags, {
      isGettingVri: { $set: true }
    })
  };
}

export function getVriSuccess(state, action) {
  return {
    ...state,
    data: update(state.data, { vri: { $set: action.data } }),
    _flags: update(state._flags, { isGettingVri: { $set: false } })
  };
}

export function getVriFailure(state, action) {
  return handleError(state, action, ['isGettingVri']);
}
