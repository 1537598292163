import { API_SYM } from '@aventus/middleware-api';
import { GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_REQUEST, GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_SUCCESS, GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_FAILURE, GUEST_QUOTES_NO_QUOTES } from './types';
import clearQuoteIdsAction from '../clear-quote-ids/actions';

export default function assignQuoteToUserAction() {
  return (dispatch, getStore) => {
    const { guestQuotes } = getStore().app.core;
    if (guestQuotes.length > 0) {
      dispatch({
        type: 'API_REQUEST',
        [API_SYM]: {
          requestConfig: {
            url: 'quotes/associatewithuser',
            method: 'POST',
            data: guestQuotes
          },
          actions: [ assignQuoteToUserRequestAction, assignQuoteToUserSuccessAction, assignQuoteToUserFailureAction ]
        }
      })
    } else {
      dispatch({
        type: GUEST_QUOTES_NO_QUOTES
      })
    }
  }
}

export function assignQuoteToUserRequestAction() {
  return {
    type: GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_REQUEST
  }
}

export function assignQuoteToUserSuccessAction() {
  return (dispatch) => {
    dispatch({ type: GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_SUCCESS });
    dispatch(clearQuoteIdsAction());
  }
}

export function assignQuoteToUserFailureAction() {
  return {
    type: GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_FAILURE
  }
}
