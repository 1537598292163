import {
  BladeButton,
  BladeLink,
  BladePriceDisplay,
  BladeView
} from '@aventus/blade';
import css from './index.css';
import React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import cx from 'classnames';
import { MtaInformation, PricingPlan } from '@aventus/platform';
import { findPaymentPlanConfiguration } from '@aventus/mvmt-pricing/services/find-payment-plan-configuration';
import { getDisplayFinePrintForAdjust } from '@aventus/mvmt-pricing/services/get-display-fine-print-for-adjust';
import { getDisplayFinePrint } from '@aventus/mvmt-pricing/services/get-display-fine-print';
import { configDrivenFeeFinePrint } from '@aventus/mvmt-pricing/services/get-display-fine-print';
import { InsuranceProductConfiguration } from '@aventus/configuration/product';
import { getDisplayPriceForAdjust } from '@aventus/mvmt-pricing/services/get-display-price-for-adjust';
import { getDisplayPrice } from '@aventus/mvmt-pricing/services/get-display-price';

export const QuoteFooter: React.FunctionComponent<IQuoteFooter> = props => {
  const { matchesQuery } = useMediaQuery('(max-width: 550px)');
  const variant = props.variant || 'inline';
  return (
    <div
      className={cx(
        css.ask_controls,
        {
          [css.ask_controls_isSpaceLimited]: matchesQuery,
          [css.ask_variant_inline]: variant === 'inline',
          [css.ask_variant_fixed]: variant === 'fixed'
        },
        'quoteFooter'
      )}
    >
      <BladeView
        variant={'s'}
        className={cx(css.ask_controls_wrap, {
          [css.ask_controls_wrap_mobile]: matchesQuery
        })}
      >
        <div className={css.ask_controls_left}>
          {props.paymentPlan && props.canPrice && (
            <BladePriceDisplay
              viewVariant={'s'}
              priceCompact={true}
              isAdjust={props.isAdjustment ? true : false}
              price={
                props.isAdjustment && props.mtaInformation
                  ? getDisplayPriceForAdjust(props.mtaInformation)
                  : props.paymentPlan && getDisplayPrice(props.paymentPlan)
              }
              paymentPeriod={
                props.isAdjustment
                  ? findPaymentPlanConfiguration(
                      props.paymentPlan.paymentPlan.type,
                      props.productConfiguration.pricing.paymentPlans
                    )?.labelShorthand
                  : props.paymentPlan
                  ? findPaymentPlanConfiguration(
                      props.paymentPlan.paymentPlan.type,
                      props.productConfiguration.pricing.paymentPlans
                    )?.labelShorthand
                  : undefined
              }
              smallPrint={
                props.isAdjustment
                  ? getDisplayFinePrintForAdjust(
                      props.productConfiguration.pricing,
                      props.mtaInformation
                    )
                  : props.paymentPlan &&
                    getDisplayFinePrint(
                      props.paymentPlan,
                      props.productConfiguration.pricing
                    )
              }
              showSmallPrintAsterisk={
                props.paymentPlan &&
                configDrivenFeeFinePrint(
                  props.paymentPlan,
                  props.productConfiguration.pricing
                ) !== ''
              }
              isUpdatingPrice={false}
              isDeclined={false}
            />
          )}
        </div>
        <div className={css.ask_controls_right}>
          {props.hideBackButton !== true && (
            <BladeLink
              variant={'secondary'}
              isDisabled={props.isFirstPage}
              onClick={() => {
                if (typeof props.backAction === 'function') {
                  props.backAction();
                }
              }}
            >
              {'Back'}
            </BladeLink>
          )}
          <BladeButton
            isWorking={props.isWorking}
            isDisabled={!props.isFormValid || props.isCurrentPageFailed}
            onClick={props.nextAction}
          >
            {!props.isLastPage
              ? props.confirmButtonText || 'Continue'
              : 'Continue'}
          </BladeButton>
        </div>
      </BladeView>
    </div>
  );
};

export interface IQuoteFooter {
  isLastPage: boolean;
  isFirstPage: boolean;
  isFormValid: boolean;
  backAction?: () => void;
  nextAction: () => void;
  hideBackButton?: boolean;
  paymentPeriod?: string;
  isCurrentPageFailed: boolean;
  variant?: QuoteFooterVariant;
  isWorking?: boolean;
  confirmButtonText?: string | undefined;
  isAdjustment: boolean;
  paymentPlan?: PricingPlan;
  mtaInformation?: MtaInformation;
  productConfiguration: InsuranceProductConfiguration;
  canPrice: boolean;
}

export type QuoteFooterVariant = 'inline' | 'fixed';
