import React from 'react';
import _render from './_render';
import { withRouter } from 'react-router-dom';

export default withRouter(class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.dismissErrorAndGoTo = this.dismissErrorAndGoTo.bind(this);
    this.render = _render.bind(this);

    if ('errorCallback' in props) this.errorCallback = props.errorCallback
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.history.action === "POP" && this.props.location.pathname !== nextProps.location.pathname && this.state.hasError)
    this.setState({ hasError: false });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if(this.errorCallback) this.errorCallback(error, info);
  }

  dismissErrorAndGoTo(route) {
    this.setState({hasError: false});
    this.props.history.push(route)
  }

})
