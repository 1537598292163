import * as React from 'react';
import css from './index.css';
import cx from 'classnames';
import { BladeButton } from '@aventus/blade';
import { getProductCallToActions } from '../../services/get-product-call-actions';

import { useGetCustomConfig } from '@aventus/symphony-client/hooks';

import { useMediaQuery } from '@aventus/pockethooks';

export const PolicyListQuote: React.FunctionComponent<IPolicyListQuote> = props => {
  const { customConfig } = useGetCustomConfig();
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');
  const [selectedAction, setSelectedAction] = React.useState<number>();

  let activeProducts;
  if (customConfig)
  {
    activeProducts = getProductCallToActions(customConfig, props.goToProduct);
  }

  const handleOnClick = (action: VoidFunction, index: number) => {
    action();
    setSelectedAction(index);
  }

  return (
    <>
      {customConfig && activeProducts && activeProducts?.length > 0 &&
      (
        <div className={cx(css.policyListQuote)}>
          <div
            className={cx(css.policyListQuote_content, {
              [css.policyListQuote_content_empty]: props.isPolicyListEmpty,
              [css.policyListQuote_content_mobile]: matchesQuery
            })}
          >
            {!props.isPolicyListEmpty && (
              <span
              className={cx(css.policyListQuote_line, {
                [css.policyListQuote_line_mobile]: matchesQuery
              })}
            >
              Looking for a quote?
            </span>
            )}

            {activeProducts.map((productAction, index) => (
              <div key={index} className={cx(css.policyListQuote)}>
                <BladeButton
                  className={css.policyListQuote_button}
                  onClick={() => handleOnClick(productAction.action, index)}
                  isWorking={selectedAction === index}
                  workingMessage='Redirecting...'
                >
                  Get a {productAction.title} Quote
                </BladeButton>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

interface IPolicyListQuote {
  isPolicyListEmpty: boolean;
  goToProduct: (reference: string, id: string) => void;
}
