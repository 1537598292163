import React from 'react';
import {
  BladeLink,
  BladeButton,
  BladeFormFieldset,
  BladeInputText,
  BladeInputPassword
} from '@aventus/blade';
import isemail from 'isemail';
import { useForm, Controller } from 'react-hook-form';
import SocialAuth from '../_social-auth';
import SocialAuthFirebase from '../_social-auth/firebase-google';
import { Scaffold, Typo } from '@honey/consume-react-jss';
import {
  AuthenticationConfiguration,
  AuthenticationProvider
} from '@aventus/platform';

export type SignInFormDataOld = {
  username: string;
  password: string;
};

type SignInComponentProps = {
  routes: any;
  onSubmit: (data: SignInFormDataOld) => void;
  goToSignUp: () => void;
  companyName: string;
  usernamePrefill: string;
  authenticationMethods: string[];
  authenticationConfig: AuthenticationConfiguration;
  authenticationProvider: AuthenticationProvider;
};

const SignInComponentOld = ({
  routes,
  onSubmit,
  goToSignUp,
  companyName,
  usernamePrefill = '',
  authenticationConfig,
  authenticationMethods,
  authenticationProvider
}: SignInComponentProps) => {
  const { handleSubmit, errors, control } = useForm<SignInFormDataOld>({
    defaultValues: {
      username: usernamePrefill,
      password: ''
    }
  });

  return (
    <>
      {authenticationMethods.includes('form') && (
        <React.Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BladeFormFieldset
              errorMessage={errors?.username && errors.username.message}
            >
              <Controller
                name="username"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message:
                      authenticationConfig?.signIn?.validationMessages
                        ?.usernameRequired || 'Username is required'
                  },
                  validate: {
                    isEmail: (value: string) =>
                      isemail.validate(value, { minDomainAtoms: 2 })
                        ? true
                        : authenticationConfig?.signIn?.validationMessages
                            ?.emailAddressInvalid ||
                          'Email address provided is invalid'
                  }
                }}
                render={({ onChange, onBlur, value }) => (
                  <BladeInputText
                    name="username"
                    label={
                      authenticationConfig?.signIn?.usernameLabel ||
                      'Your email'
                    }
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </BladeFormFieldset>
            <BladeFormFieldset
              errorMessage={errors?.password && errors.password.message}
              addonBottom={
                <span>
                  {authenticationConfig?.signIn?.forgottenPasswordMessage ||
                    'Forgotten?'}{' '}
                  <BladeLink size={'small'} url={routes.resetPassword.path}>
                    {authenticationConfig?.signIn
                      ?.forgottenPasswordLinkMessage || 'Reset your password'}
                  </BladeLink>
                </span>
              }
            >
              <Controller
                name="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message:
                      authenticationConfig?.signIn?.validationMessages
                        ?.passwordRequired || 'Password is required'
                  }
                }}
                render={({ onChange, onBlur, value }) => (
                  <BladeInputPassword
                    name="password"
                    label={
                      authenticationConfig?.signIn?.passwordLabel ||
                      'Your password'
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </BladeFormFieldset>

            <BladeButton id="sign-in-sign-in-button" type="submit">
              {authenticationConfig?.signIn?.signInButtonText || `Sign In`}
            </BladeButton>

            <Scaffold position={'c'} size={'default'} only={'children'}>
              <Typo>
                {authenticationConfig?.signIn?.newToCompanyText ||
                  `New to ${companyName}?`}
              </Typo>
              <BladeLink onClick={() => goToSignUp()}>
                {authenticationConfig?.signIn?.signUpButtonText ||
                  `Create account`}
              </BladeLink>
            </Scaffold>
          </form>
        </React.Fragment>
      )}

      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth
            type="google"
            label={
              authenticationConfig?.signIn?.socialAuthText || 'Sign in with'
            }
          />
        )}

      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Firebase' && (
          <SocialAuthFirebase
            type="google"
            label={
              authenticationConfig?.signIn?.socialAuthText || 'Sign in with'
            }
          />
        )}

      {authenticationMethods.includes('facebook') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth
            type="facebook"
            label={
              authenticationConfig?.signIn?.socialAuthText || 'Sign in with'
            }
          />
        )}
    </>
  );
};

export default SignInComponentOld;
