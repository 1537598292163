import update from 'immutability-helper';

export function sendSignInLinkSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          successfullySentSignInLink: { $set: true }
        }
      })
    }
  };
}

export function sendSignInLinkFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            successfullySentSignInLink: false
          }
        }
      })
    }
  };
}
