import { APP_UPDATE_PRIVACY_POLICY_VERSION } from './types';

export default function updatePrivacyPolicyVersionAction() {
  return (dispatch, getState) => {
    const privacyPolicyVersion = getState().app.core.customConfig.client.legalCopy.privacyPolicyVersion;
    dispatch({
      type: APP_UPDATE_PRIVACY_POLICY_VERSION,
      privacyPolicyVersion
    });
  }
}
