import * as React from 'react';
import { BladeIcon } from '../icon';
import { BladeLink } from '../link';
import css from './index.css';

export const BladeLinkImageIconOrLabel: React.FunctionComponent<IBladeLinkImageIconOrLabel> = props => {
  return (
    <>
      {props.image && (
        <a href={props.href}>
          <img className={props.classNameImage || ''} src={props.image} />
        </a>
      )}

      {props.icon && !props.image && (
        <a href={props.href} className={css.linkImageIconOrLabel_icon}>
          <BladeIcon
            name={props.icon}
            classNameWrap={props.classNameIcon || ''}
            ignoreTriggerStyle={true}
          />
        </a>
      )}

      {props.label && !props.image && !props.icon && (
        <BladeLink
          url={props.href}
          isExternal={true}
          className={props.classNameLabel || ''}
        >
          {props.label}
        </BladeLink>
      )}
    </>
  );
};

export interface IBladeLinkImageIconOrLabel extends BladeImageIconOrLabelLink {
  classNameImage?: string;
  classNameIcon?: string;
  classNameLabel?: string;
}
