import React, { useState } from 'react';
import { useSimpleQuote } from '../../../../../hooks/use-simple-quote';
import { History } from 'history';
import { SimpleQuoteAsk } from '../simple-quote-ask';
import { QuoteFooter } from '@aventus/mvmt-simplequote/component/quote-footer';
import { CustomConfig, QuoteType } from '@aventus/platform';
import { IProduct } from '@aventus/platform-client-context/models/product';
import { OraclesConfiguration } from '@aventus/configuration/oracles';
import { InsuranceProductConfiguration } from '@aventus/configuration/product';
import { ProductInterfaceConfiguration } from '@aventus/configuration/product-interface';
import { IOrganisationSettings } from '@aventus/platform-client';
import { useLocation } from 'react-router-dom';

export const SimpleQuoteDigitalUnderwriting: React.FunctionComponent<
  ISimpleQuoteDigitalUnderwriting
> = ({
  type,
  questionSetReferenceID,
  pageIndex,
  organisationSettings,
  oraclesConfiguration,
  productInterfaceConfiguration,
  productConfiguration,
  customConfiguration,
  ...props
}) => {
  const location = useLocation();

  const editSessionID =
    new URLSearchParams(location?.search).get('editSessionId') || undefined;

  const exposureID =
    new URLSearchParams(location?.search).get('exposureId') || undefined;

  const [changeMade, setChangesMade] = useState<boolean>(false);
  const [disableWarning, setDisableWarning] = useState<boolean>(false);

  const {
    pages,
    pageConfig,
    getNextQuestionPage,
    isFirstPage,
    isLastPage,
    questions,
    isCurrentPageFailed,
    getQuestionAnswer,
    changeQuestionAnswer,
    getArrayDescription,
    tobesError,
    isWorking,
    validationDetails,
    tobesQuoteBundle,
    paymentPlan,
    isLoadingPricing,
    formState,
    clearFieldValidation
  } = useSimpleQuote(
    props.product.id.toString(),
    questionSetReferenceID,
    props.history,
    setChangesMade,
    false, // IsHybridFlag
    editSessionID,
    exposureID,
    type,
    pageIndex,
    props.orgLeveltoken
  );

  return (
    <SimpleQuoteAsk
      formState={formState}
      pages={pages}
      pageConfig={pageConfig}
      currencyCode={organisationSettings.currencyCode}
      currencySymbol={organisationSettings.currencySymbol}
      dateFormat={organisationSettings.dateFormat}
      countryCode={organisationSettings.defaultCountryCode}
      oraclesConfiguration={oraclesConfiguration}
      questions={questions}
      getQuestionAnswer={getQuestionAnswer}
      changeQuestionAnswer={changeQuestionAnswer}
      getArrayDescription={getArrayDescription}
      tobesError={tobesError}
      disableWarning={disableWarning}
      getSubmitToRender={(isFormValid = false) => (
        <QuoteFooter
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          isFormValid={isFormValid}
          confirmButtonText={productConfiguration.quote?.confirm?.buttonText}
          isCurrentPageFailed={isCurrentPageFailed}
          backAction={() => {
            if (changeMade) {
              const isConfirmed = window.confirm(
                'Are you sure you want to leave?'
              );
              if (isConfirmed) {
                getNextQuestionPage(true);
              }
            } else {
              getNextQuestionPage(true);
            }
          }}
          nextAction={() => {
            setDisableWarning(true);
            getNextQuestionPage(
              false,
              paymentPlan.selectedPricingPlan?.paymentPlan.type
            );
          }}
          isWorking={isWorking}
          isAdjustment={tobesQuoteBundle?.requestedQuote.quoteType === 'MTA'}
          paymentPlan={paymentPlan.selectedPricingPlan}
          mtaInformation={tobesQuoteBundle?.mtaInfo}
          productConfiguration={productConfiguration}
          canPrice={pageConfig.canPrice}
        />
      )}
      isLoading={isWorking}
      tobesQuoteBundle={tobesQuoteBundle}
      paymentPlan={paymentPlan}
      productConfiguration={productConfiguration}
      productInterfaceConfiguration={productInterfaceConfiguration}
      validationDetails={validationDetails}
      progressBarConfig={customConfiguration?.userInterface?.progressBar}
      isLoadingPricing={isLoadingPricing}
      clearFieldValidation={clearFieldValidation}
    />
  );
};

export interface ISimpleQuoteDigitalUnderwriting {
  product: IProduct;
  type: QuoteType;
  questionSetReferenceID: string;
  pageIndex?: string;
  isAuthenticated: boolean;
  dashboardPath: string;
  hideFooter: () => void;
  history: History;
  orgLeveltoken?: string | null;
  oraclesConfiguration: OraclesConfiguration;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  customConfiguration: CustomConfig;
  organisationSettings: IOrganisationSettings;
}
