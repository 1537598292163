import React from 'react';
import {
  BladeButton,
  BladeList,
  BladeMessage,
  BladeRunner,
  BladeSurface,
  BladeTitleBar,
  BladeView
} from '@aventus/blade';
import { IQuestionSetList } from '../../views/quick-quote-summary';
import { IExposureQuestionSetList } from '../../views/quick-quote-summary';
import css from './index.css';
import { AlertDataType } from '@aventus/mvmt-simplequote/hooks/use-alerts/use-alerts';

const QuickQuoteSummaryView = ({
  title,
  alerts,
  coreQuestionSets,
  exposures,
  onAddExposure,
  actions
}: IQuickQuoteSummaryView): JSX.Element => {
  return (
    <div className={css.scaffolding}>
      <BladeRunner>
        <div className={css.scaffolding}>
          <BladeTitleBar viewVariant={'s'} title={title} />
          <BladeView variant={'s'} className={css.contentScaffold}>
            {alerts
              .filter((alert): alert is AlertDataType => alert !== undefined)
              .map((alert, key) => (
                <div className={css.messageWrapper} key={key}>
                  <BladeMessage
                    variant={alert.type}
                    message={alert.message}
                    detail={alert.validationErrors
                      ?.map(
                        error =>
                          `* ${error.propertyIdentifier} ${error.message}`
                      )
                      .join('\n')}
                  />
                </div>
              ))}
            {coreQuestionSets && (
              <BladeSurface shouldRespectViewWidth={true}>
                <BladeList
                  isFlush={true}
                  shouldRespectViewWidth={true}
                  list={coreQuestionSets.map(questionSet => ({
                    ...questionSet,
                    ...{
                      buttonClass: css.button
                    }
                  }))}
                />
              </BladeSurface>
            )}
            {exposures.map((exposure, i) => {
              return (
                <BladeSurface
                  key={`${exposure.referenceID}_${exposure.questionSetReferenceID}_${i}`}
                  title={`${exposure.name}` || 'Exposure #' + (i + 1)}
                  shouldRespectViewWidth={true}
                  titleBarContentRight={
                    <div className={css.exposure_actions}>
                      <BladeButton
                        className={css.button}
                        onClick={() =>
                          onAddExposure(
                            exposure.questionSetReferenceID,
                            exposure.referenceID
                          )
                        }
                      >
                        Add New
                      </BladeButton>
                    </div>
                  }
                >
                  <BladeList
                    isFlush={true}
                    shouldRespectViewWidth={true}
                    isRemoveDisabled={exposure.riskExposures.length <= 1}
                    list={exposure.riskExposures.map(questionSet => ({
                      ...questionSet,
                      ...{
                        buttonClass: css.button
                      }
                    }))}
                  />
                </BladeSurface>
              );
            })}
            {actions !== undefined && (
              <BladeSurface
                classNameContent={css.summaryActions}
                shouldRespectViewWidth={true}
              >
                {actions}
              </BladeSurface>
            )}
          </BladeView>
        </div>
      </BladeRunner>
    </div>
  );
};

interface IQuickQuoteSummaryView {
  title: string;
  alerts: (AlertDataType | undefined)[];
  coreQuestionSets: IQuestionSetList[];
  exposures: IExposureQuestionSetList[];
  onAddExposure: (questionSetReferenceID: string, exposureID?: string) => void;
  onRemoveExposure?: any;
  actions?: React.ReactNode;
}

export default QuickQuoteSummaryView;
