import moment, { Moment } from 'moment';

export const useDate: UseDate = (
  value,
  onChange,
  now
) => {

  // If this input was initialised with no
  // starting date, then we can just create
  // a value of now.

  const _date: Moment = (
    !value
      ? (
          !now
            ? moment()
            : moment(now)
        )
      : moment(value)
  );

  const _value: Date = {
    day: _date.date(),
    month: _date.month() + 1,
    year: _date.year()
  }

  function _onChange (__value: Date) {
    const dateInIsoNoTimezone = moment(`${ __value.day }/${ __value.month }/${ __value.year }`, 'DD-MM-YYYY');
    onChange(dateInIsoNoTimezone);
  }

  return {
    value: _value,
    onChange: _onChange
  };

}

export type UseDate = (
  value: string,
  onChange: (value: Moment) => void,
  now?: string
) => {
  value: Date,
  onChange: (value: Date) => void
}

export interface Date {
  day: number,
  month: number,
  year: number
}
