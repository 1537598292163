import * as React from 'react';
import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import css from './index.css';
import cx from 'classnames';

/**
 * A buttin init, fam.
 */
export const BladeButton: React.FunctionComponent<IBladeButton> = ({
  variant = '',
  id,
  type,
  style = {},
  onClick,
  isDisabled,
  isDestructive,
  invert,
  isInline,
  children,
  basis,
  workingMessage = 'Loading...',
  ...props
}) => {
  let isDataWorking = props.isWorking;
  if (props.isWorking && _isArray(props.isWorking)) {
    isDataWorking = _find(props.isWorking, true) || false;
  }

  const formatedStyles = { ...style, ...(basis && { flexBasis: basis }) };
  const isSecondary = variant === 'secondary';
  return (
    <button
      id={id}
      type={type}
      style={formatedStyles}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled && !isDataWorking && onClick) {
          onClick(event);
        }
      }}
      className={cx('blade-button', css.button, props.className, {
        [css.disabled]: isDisabled,
        [css.destructive]: isDestructive,
        [css.inverted]: invert,
        [css.inline]: isInline,
        [css.secondary]: isSecondary
      })}
    >
      {isDataWorking ? workingMessage : children}
    </button>
  );
};

export interface IBladeButton {
  id?: string;
  /** The style of button defined within the theme. */
  variant?: ButtonVariants;
  /** Sets button to a loading state when set to `true`. */
  isWorking?: boolean | Array<boolean>;
  workingMessage?: string;
  /** Access to HTML button property `type`*/
  type?: 'button' | 'submit' | 'reset';
  /** Access to Style  property `flex-basis`*/
  basis?: string | boolean;
  /** Triggered when the button is clicked. */
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  /** Custom style the button. */
  style?: any;
  /** Disable the button; removes the ability to click and greying the button visually. */
  isDisabled?: boolean;
  /** Add extra style emphasis if the associated action is destructive. */
  isDestructive?: boolean;
  /** Extend CSS with custom classes */
  className?: string;
  /** Invert the color and background colours with each other. */
  invert?: boolean;
  /** If set to `true`, dont apply 100% width to the button, and allow to button to grow inline. */
  isInline?: boolean;
}

export type ButtonVariants = 'primary' | 'secondary' | 'action';
