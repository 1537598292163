import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import usePlatform from '@aventus/platform-client-context/use-platform';
import { IProduct } from '@aventus/platform-client-context/models/product';

export function useGetProductRef(
  productRef?: string,
  coverType?: Nullable<string>
): UseGetProduct {
  const platform = usePlatform();

  const [product, setProduct] = React.useState<IProduct | undefined>(undefined);
  const [productErrored, setProductErrored]  = React.useState<boolean>(false);
  const [isGettingProduct, setIsGettingProduct] = React.useState<boolean>(false);

  useAsyncEffect(async () => {
    if (productRef && coverType) {
      try{
        setIsGettingProduct(true)
        const _product = await platform.product.getProductByRef(
          productRef,
          coverType
        );
        setProduct(_product);
        setIsGettingProduct(false)
      }
      catch(e){
        setIsGettingProduct(false)
        setProductErrored(true)
      }

    }
  }, [productRef]);

  return {
    product,
    isGettingProduct,
    productErrored
  };
}

export interface UseGetProduct {
  product: IProduct | undefined;
  isGettingProduct: boolean;
  productErrored: boolean;
}
