import React, { useEffect, useMemo } from 'react';
import { useGetProductConfigurationById } from '../../hooks/use-product-configuration-by-id';
import {
  RouteComponentProps,
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useGetVariantSession } from '@aventus/mvmt-simplequote/hooks/use-get-variant-session';
import { getToken } from '@aventus/platform-client-context/token-service';
import css from './index.css';
import { IExposure, QuestionSetType } from '@aventus/platform-client';
import {
  coreQuestionSets,
  exposureQuestionSets,
  handleEditQuestionSet
} from '@aventus/mvmt-simplequote/helpers';
import { useQueryClient, useMutation } from 'react-query';
import usePlatform from '@aventus/platform-client-context/use-platform';
import { useTrack } from '@aventus/application-tracking/use-track';
import { BladeButton } from '@aventus/blade';
import QuickQuoteSummaryView from '../../component/summary-view';
import RemoveExposureModal from '../../component/remove-exposure-modal';
import { useAlerts } from '../../hooks/use-alerts/use-alerts';

const QuickQuoteSummary: React.FC<
  QuickQuoteSummaryRouteProps
> = (): JSX.Element => {
  const { productID, editSessionID } = useParams<IQuickQuoteSummaryParams>();

  const history = useHistory();
  const { search } = useLocation();
  const platform = usePlatform();
  const { track } = useTrack();
  const { addAlert, hasAlert, clearAlert, toArray: alertsArray } = useAlerts();
  const queryClient = useQueryClient();

  const chrome = new URLSearchParams(search).get('chrome') || 'false';
  const token = new URLSearchParams(search).get('token') || getToken() || '';
  const [removeModal, setRemoveModal] = React.useState<{ exposureId: string, label: string } | undefined>(undefined);

  const {
    data: productConfiguration,
    isLoading: isProductConfigurationLoading,
    isError: isProductConfigurationError
  } = useGetProductConfigurationById(productID);

  const {
    data: policyDetailEditSession,
    isLoading: isGetSessionLoading,
    isError: isGetSessionError
  } = useGetVariantSession(editSessionID);

  const confirmQuickQuote = useMutation((editSessionID: string) =>
    platform.quickQuote.confirmQuickQuote(editSessionID)
  );

  const riskExposures = useMemo(() => {
    return policyDetailEditSession !== undefined
      ? policyDetailEditSession.risk.exposures
      : undefined;
  }, [policyDetailEditSession]);

  const handleConfirm = (editSessionID: string) => {
    clearAlert('api-error');

    confirmQuickQuote
      .mutateAsync(editSessionID)
      .then(response => {
        addAlert('quick-quote-complete', {
          type: 'success',
          message: 'Quick quote completed.'
        });
        track({
          event: 'aventus.quickQuote.completed',
          value: response.quoteDetails.quote.id
        });
      })
      .catch(error => {
        addAlert('api-error', {
          type: 'error',
          message: 'Sorry, we were unable to complete this quick quote.',
          error: error
        });
      });
  };

  const onClickAction = handleEditQuestionSet(
    history,
    productID,
    editSessionID,
    chrome,
    token,
    'Vri'
  );

  useEffect(() => {
    if (riskExposures !== undefined && riskExposures.length === 0) {
      addAlert('min-exposures', {
        type: 'warning',
        message: 'Please add at least one exposure to this quote'
      });
    } else if (riskExposures !== undefined && riskExposures.length > 0) {
      clearAlert('min-exposures');
    }
  }, [riskExposures, addAlert, clearAlert]);

  useEffect(() => {
    if (isProductConfigurationError || isGetSessionError) {
      addAlert('fetch-error', {
        type: 'error',
        message: 'There has been an error fetching this quote session.'
      });
    }
  }, [isProductConfigurationError, isGetSessionError, addAlert]);

  const removeExposure = useMutation(({ editSessionId, exposureId }: { editSessionId: string | null, exposureId: string | undefined }) => 
    platform.variantEditSession.removeExposure(editSessionId as string, exposureId as string),
    {
      onSuccess: () => {
        queryClient.fetchQuery(['getEditSession', editSessionID])
          .finally(() => setRemoveModal(undefined));
      },
      onError: () => {
        setRemoveModal(undefined);
        addAlert('fetch-error', {
          type: 'error',
          message: 'There has been an error removing this exposure.'
        });
      }
    }
  )

  const isViewLoading =
  isProductConfigurationLoading ||
  isGetSessionLoading ||
  confirmQuickQuote.isLoading ||
  removeExposure.isLoading;

  return (
    <>
      {removeModal !== undefined && (
        <RemoveExposureModal
          close={() => setRemoveModal(undefined)}
          isLoading={removeExposure.isLoading || isGetSessionLoading}
          label={removeModal.label}
          onRemove={() => {
            removeExposure.mutate({
            exposureId: removeModal.exposureId,
            editSessionId: editSessionID,
          })}}
        />
      )}
      <QuickQuoteSummaryView
        title="Quick quote"
        alerts={alertsArray}
        coreQuestionSets={
          productConfiguration
            ? coreQuestionSets(
                'CoreQuickQuote',
                productConfiguration,
                onClickAction
              )
            : []
        }
        exposures={
          productConfiguration !== undefined
            ? exposureQuestionSets(
                riskExposures || [],
                'ExposureQuickQuote',
                productConfiguration,
                onClickAction,
                setRemoveModal
              )
            : []
        }
        onAddExposure={onClickAction}
        actions={
          <BladeButton
            className={css.summaryActions_complete}
            isInline={true}
            variant={'primary'}
            onClick={() => handleConfirm(editSessionID)}
            isDisabled={
              confirmQuickQuote.isSuccess ||
              hasAlert('fetch-error') ||
              hasAlert('api-error') ||
              hasAlert('min-exposures')
            }
            isWorking={isViewLoading}
          >
            {isViewLoading ? 'Loading...' : 'Quote'}
          </BladeButton>
        }
      />
    </>
  );
};

export interface IQuestionSetList {
  label: string;
  value: string;
  buttonVariant: string;
  buttonClass?: string;
  buttonAction: () => void;
}

export interface IExposureQuestionSetList
  extends Omit<IExposure, 'questionSets'> {
  questionSetReferenceID: string;
  questionSetType: QuestionSetType;
  riskExposures: IQuestionSetList[];
}

export interface IQuickQuoteSummaryParams {
  productID: string;
  editSessionID: string;
}

export type QuickQuoteSummaryRouteProps =
  RouteComponentProps<IQuickQuoteSummaryParams>;

export default QuickQuoteSummary;
