import { IAtomsReduce } from '@aventus/honey-definitions';
import { compileCSSClass } from './compile-css-class';
import { cssDeclarationsReduce } from './css-declarations-reduce';

export const atomsReduce: IAtomsReduce = (accumulator, atom, index, atoms) => {

  const reducedAtom: string = cssDeclarationsReduce(accumulator, atom, index, atoms);

  if (index === atoms.length - 1) {
    const key: string = ':root';
    const compiledAtoms: string = compileCSSClass(key, reducedAtom);
    return compiledAtoms;
  } else {
    return reducedAtom;
  }
}
