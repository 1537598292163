import moment from 'moment';

export default function valueGetFromISO(isoDate: string) {
  if (!isoDate) {
    const now = moment();
    return {
      day: now.date(),
      month: now.month() + 1,
      year: now.year()
    };
  }

  const date = moment(isoDate);
  return {
    day: date.date(),
    month: date.month() + 1,
    year: date.year()
  };
}
