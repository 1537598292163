import * as React from 'react';
import { BladeFormFieldset, IBladeFormFieldset } from '../../index';
import { BladeInputText, IBladeInputText } from '../../../input/text';
import { BladeInputSelect, IBladeInputSelect } from '../../../input/select';
import { BladeLink } from '@aventus/blade';
import css from './index.css';

const LINE_1: string = '-line1';
const LINE_2: string = '-line2';
const TOWN: string = '-town';
const POSTAL_CODE: string = '-postcalCode';
const COUNTRY: string = '-country';

export const AddressManual: React.FunctionComponent<IAddressManual> = props => {
  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
    >
      <div className={css.address}>
        <BladeInputText
          variant={props.variant}
          label={'Line 1'}
          name={props.name + LINE_1}
          id={props.name + LINE_1}
          value={props.value ? props.value.address1 : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ address1: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: string | boolean) => {
            props.onValidate?.({ ...props.error, ...{ [LINE_1]: result } });
          }}
          error={
            typeof props.error === 'object' ? props.error?.[LINE_1] : undefined
          }
        />

        <BladeInputText
          variant={props.variant}
          label={'Line 2'}
          name={props.name + LINE_2}
          id={props.name + LINE_2}
          value={props.value ? props.value.address2 : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ address2: value }
            });
          }}
        />

        <BladeInputText
          variant={props.variant}
          label={'Town'}
          name={props.name + TOWN}
          id={props.name + TOWN}
          value={props.value ? props.value.town : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ town: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: string | boolean) => {
            props.onValidate?.({ ...props.error, ...{ [TOWN]: result } });
          }}
          error={
            typeof props.error === 'object' ? props.error?.[TOWN] : undefined
          }
        />

        <BladeInputText
          variant={props.variant}
          label={'Postcode'}
          name={props.name + POSTAL_CODE}
          id={props.name + POSTAL_CODE}
          value={props.value ? props.value.postalCode : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ postalCode: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: string | boolean) => {
            props.onValidate?.({
              ...props.error,
              ...{ [POSTAL_CODE]: result }
            });
          }}
          error={
            typeof props.error === 'object'
              ? props.error?.[POSTAL_CODE]
              : undefined
          }
        />

        <BladeInputSelect
          variant={props.variant}
          label={'Country'}
          name={props.name + COUNTRY}
          id={props.name + COUNTRY}
          options={props.options}
          matchOn={props.matchOn}
          value={props.value ? props.value.country : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ country: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: string | boolean) => {
            props.onValidate?.({ ...props.error, ...{ [COUNTRY]: result } });
          }}
          error={
            typeof props.error === 'object' ? props.error?.[COUNTRY] : undefined
          }
        />

        <BladeLink isSmall={true} onClick={props.enterWithLookup}>
          Back to address lookup
        </BladeLink>
      </div>
    </BladeFormFieldset>
  );
};

export interface IAddressManual
  extends IBladeFormFieldset,
    IBladeInputText,
    IBladeInputSelect {
  enterWithLookup: () => void;
}
