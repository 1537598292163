import moment, { Moment } from 'moment';

const CURRENT_YEAR = moment().year();
const PAST_YEAR_RANGE = 100;
const FUTURE_YEAR_RANGE = 100;

const createArrayRange = (min = 1, length = 1) => Array.from({ length }, (_, i) => i + min);
const createRangeOptions = (array: number[]) => array.map((number: number) => ({ id: `${number}`, label: `${number}` }));

const daysRange = createArrayRange(1, 31);
const monthsRange = createArrayRange(1, 12);
const futureYearRange = createArrayRange(
  CURRENT_YEAR - 1,
  FUTURE_YEAR_RANGE + 2
);
const pastYearRange = createArrayRange(
  CURRENT_YEAR - PAST_YEAR_RANGE,
  PAST_YEAR_RANGE + 1
);

export const generateOptions = (future = false) => ({
  dayOptions: createRangeOptions(daysRange),
  monthOptions: monthsRange.map((monthNumber) => ({ id: monthNumber, label: moment(monthNumber, 'MM').format('MMM') })),
  yearOptions: future ? createRangeOptions(futureYearRange) : createRangeOptions(pastYearRange).reverse(),
})

export const initialValues = (date: Moment | null) => {
  try {
    const dayNumber = date ? date.date() : null;
    const monthNumber = date ? date.month() + 1 : null;
    const yearNumber = date ? date.year() : null;

    const initialDay = dayNumber ? { id: dayNumber, label: dayNumber } : null;
    const initialMonth = monthNumber ? { id: monthNumber, label: moment(monthNumber, "MM").format("MMM") } : null;
    const initialYear = yearNumber ? { id: yearNumber, label: yearNumber } : null;

    return { initialDay, initialMonth, initialYear };
  } catch(e) {
    return { initialDay: null, initialMonth: null, initialYear: null }
  }
};
