import React from 'react';
import { History } from 'history';
import { EProductType } from '@aventus/platform-client-context/models/tobes';
import css from './index.css';
import {
  useGetOraclesConfiguration,
  useGetProductConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import { useGetOrganisationSettings } from '@aventus/mvmt-simplequote/hooks/use-get-organisation-settings';
import { BladeRunner } from '@aventus/blade';
import { BladeTitleBar } from '@aventus/blade';
import { BladeMessage } from '@aventus/blade';
import { BladeView } from '@aventus/blade';
import { QuoteType } from '@aventus/platform';
import { IProduct } from '@aventus/platform-client-context/models/product';
import { useGetCustomConfig } from '@aventus/symphony-client/hooks/use-get-custom-config';
import { useIsHybridProduct } from '@aventus/mvmt-simplequote/hooks/use-is-hybrid-product';
import { SimpleQuoteDigitalUnderwriting } from './views/simple-quote-digital-underwriting';
import { SimpleQuoteHybrid } from './views/simple-quote-hybrid';

export const SimpleQuotePage: React.FunctionComponent<
  ISimpleQuotePage
> = props => {
  const { organisationSettings, orgSettingsErrored } =
    useGetOrganisationSettings();

  const { oraclesConfiguration } = useGetOraclesConfiguration();

  const { customConfig } = useGetCustomConfig();

  const { isHybridProduct } = useIsHybridProduct(
    customConfig,
    props.product.id.toString()
  );

  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    props.product?.referenceID,
    EProductType.standard
  );

  const { productConfiguration } = useGetProductConfiguration(
    props.product?.referenceID,
    EProductType.standard
  );

  React.useEffect(() => {
    const onUnload = (event: Event) => {
      event.preventDefault();
      event.returnValue = false;
      return '';
    };

    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  React.useEffect(() => {
    if (productInterfaceConfiguration?.footer === 'quote') {
      props.hideFooter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInterfaceConfiguration, props.hideFooter]);

  if (orgSettingsErrored) {
    return (
      <div className={css.scaffolding}>
        <BladeRunner>
          <div className={css.scaffolding}>
            <BladeTitleBar viewVariant={'s'} title={`${props.product?.name}`} />
            <BladeView variant={'s'}>
              <div className={css.messageWrapper}>
                <BladeMessage
                  variant={'error'}
                  message={'Error loading org settings, please try again later'}
                />
              </div>
            </BladeView>
          </div>
        </BladeRunner>
      </div>
    );
  }

  if (
    oraclesConfiguration &&
    productConfiguration &&
    organisationSettings &&
    productInterfaceConfiguration &&
    customConfig
  ) {
    if (isHybridProduct) {
      return (
        <div className={css.scaffolding}>
          <SimpleQuoteHybrid
            {...props}
            productInterfaceConfiguration={productInterfaceConfiguration}
            organisationSettings={organisationSettings}
            oraclesConfiguration={oraclesConfiguration}
            productConfiguration={productConfiguration}
            customConfiguration={customConfig}
            orgLeveltoken={props.orgLeveltoken}
          />
        </div>
      );
    } else {
      return (
        <div className={css.scaffolding}>
          <SimpleQuoteDigitalUnderwriting
            {...props}
            productInterfaceConfiguration={productInterfaceConfiguration}
            organisationSettings={organisationSettings}
            oraclesConfiguration={oraclesConfiguration}
            productConfiguration={productConfiguration}
            customConfiguration={customConfig}
            orgLeveltoken={props.orgLeveltoken}
          />
        </div>
      );
    }
  }

  return null;
};

export interface ISimpleQuotePage {
  product: IProduct;
  type: QuoteType;
  questionSetReferenceID: string;
  pageIndex?: string;
  isAuthenticated: boolean;
  dashboardPath: string;
  hideFooter: () => void;
  history: History;
  orgLeveltoken?: string | null;
}
