import * as React from 'react';
import { BladeMessage, IBladeMessage } from '../message';
import css from './index.css';

export const BladeMessageNotification: React.FunctionComponent<IBladeMessageNotification> = props => {
  return (
    <div className={css.messageNotification}>
      <BladeMessage {...props} />
    </div>
  );
};

export interface IBladeMessageNotification extends IBladeMessage {}
