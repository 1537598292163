import * as React from 'react';
import { BladeView, BladeMarkdown, BladeSurface } from '@aventus/blade';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useTrackView } from '@aventus/application-tracking';
import css from './index.css';

export const PolicyCancelAgentRequired: React.FunctionComponent<IPolicyCancelAgentRequired> = props => {
  useTrackView('PolicyCancelAgentRequired');

  const { productConfiguration } = useGetProductConfiguration(
    props.productReference,
    props.productCoverReference
  );

  if (productConfiguration) {
    return (
      <BladeView variant={'s'} className={css.cancelAgentRequired}>
        <BladeSurface
          classNameContent={css.cancelAgentRequired_content}
          title={productConfiguration.policy.cancel.agentRequired?.title}
        >
          <BladeMarkdown
            markdown={
              productConfiguration.policy.cancel.agentRequired?.description
            }
          />
        </BladeSurface>
      </BladeView>
    );
  }

  return null;
};

export interface IPolicyCancelAgentRequired {
  productReference: string;
  productCoverReference: string;
}
