import { PricingPlan, Money } from '@aventus/platform';
import { ApplicationError } from '@aventus/errors';

export function getDisplayPrice(plan: PricingPlan): Money {
  switch (plan.paymentPlan.type) {
    case 'AnnualMonthlyCredit':
    case 'AnnualMonthlySubscription':
    case 'MonthlySubscription':
      return plan.monthlyPrice;

    case 'FixedTermSinglePayment':
    case 'SinglePayment':
      return plan.upfrontPrice;
    case 'ExternalCollection':
      return plan.totalPayable.gross;
    default:
      throw new ApplicationError(
        'The `paymentPlan.type` found in `getDisplayPrice` is not supported'
      );
  }
}
