import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import {
  ConnectionsConfiguration,
  AppConfiguration,
  OrganisationConfiguration,
  InterfaceConfiguration
} from '@aventus/configuration';
import {
  createConfigurationClient,
  ConfigurationClient
} from '@aventus/configuration-client';
import { compile } from '@aventus/honey-compiler';
import { honeyCompilerCSSBrowser } from '@aventus/honey-compiler-css-browser';
import { honeyAtomicOperatorWebfonts } from '@aventus/honey-atomic-operator-webfonts';
import { honeyMolecularSpacing } from '@aventus/honey-molecular-spacing';
import { createSymphonyClient, SymphonyClient } from '@aventus/symphony-client';
import { useInitialiseTracking } from '@aventus/application-tracking';
import { OrganisationSettings } from '@aventus/platform';
import { createFirebaseApp } from '@aventus/authentication-firebase';

// Important note:
// The external id work here is a temporary workaround
// to bridge redux state into the new react-only state
// management. Please look to remove this asap. The work required
// in order to do this is:
//   * move the app's authentication to use the auth0 hosted page

export const useApp: UseApp = externalId => {
  const [isReady, setIsReady] = React.useState<boolean>(false);

  const [configuration, setConfiguration] = React.useState<
    ConfigurationClient | undefined
  >(undefined);
  const [symphony, setSymphony] = React.useState<SymphonyClient | undefined>(
    undefined
  );

  const [organisationSettings, setOrganisationSettings] = React.useState<
    OrganisationSettings | undefined
  >(undefined);
  const [connectionsConfiguration, setConnectionsConfiguration] =
    React.useState<ConnectionsConfiguration | undefined>(undefined);
  const [appConfiguration, setAppConfiguration] = React.useState<
    AppConfiguration | undefined
  >(undefined);
  const [organisationConfiguration, setOrganisationConfiguration] =
    React.useState<OrganisationConfiguration | undefined>(undefined);
  const [interfaceConfiguration, setInterfaceConfiguration] = React.useState<
    InterfaceConfiguration | undefined
  >(undefined);

  useAsyncEffect(async () => {
    if (!isReady) {
      // First we'll create the configuration client,
      // so that we can fetch the code for this applications.

      const configuration = createConfigurationClient('symphony');
      const _appConfiguration = await configuration.getAppConfiguration();

      const applicationConfiguration =
        await configuration.getApplicationsConfiguration('symphony');
      const designSystemConfiguration =
        await configuration.getDesignSystemConfiguration(
          applicationConfiguration.designSystem
        );

      const _connectionsConfiguration =
        await configuration.getConnectionsConfiguration();
      const _organisationConfiguration =
        await configuration.getOrganisationConfiguration();
      const _interfaceConfiguration =
        await configuration.getInterfaceConfiguration();

      // Create the symphony API client with the configured
      // env variables.

      // We will force this to v2 as all v3 calls are done on
      // the platform client side

      const symphony = createSymphonyClient({
        baseURL: `${_appConfiguration.API}/v2`
      });

      // We can now use the symphony client to fetch the
      // organisation settings associated with the configured
      // tenant organisation.

      const _organisationSettings = await symphony.getOrganisationSettings();
      setOrganisationSettings(_organisationSettings);

      if (_organisationSettings.authentication.providers.includes('Firebase')){
        createFirebaseApp(_organisationSettings.authentication.firebase);
      }      

      // With our org's design system configuration, we can now
      // initialise and compile the Honey Design System
      // with the org's Symphony flavour. Nom nom.

      compile(
        designSystemConfiguration,
        [honeyCompilerCSSBrowser],
        [honeyAtomicOperatorWebfonts],
        honeyMolecularSpacing
      );

      setConfiguration(configuration);
      setConnectionsConfiguration(_connectionsConfiguration);
      setAppConfiguration(_appConfiguration);
      setOrganisationConfiguration(_organisationConfiguration);
      setInterfaceConfiguration(_interfaceConfiguration);
      setSymphony(symphony);
      setIsReady(true);
    }
  }, []);

  // See comment at the top of the async function above for more details,
  // but this is a temporary workaround.

  React.useEffect(() => {
    if (externalId && symphony) {
      symphony.saveExternalId(externalId);
    }
  }, [externalId, symphony]);

  // Initialise application-wide
  // tracking (This module uses GTM to track).

  useInitialiseTracking(
    isReady && appConfiguration?.GOOGLE_TAG_MANAGER
      ? appConfiguration.GOOGLE_TAG_MANAGER
      : false,
    isReady && connectionsConfiguration?.googleTagManager
      ? connectionsConfiguration.googleTagManager
      : false
  );

  return {
    isReady,
    configuration,
    symphony,
    organisationSettings,
    organisationConfiguration,
    connectionsConfiguration,
    interfaceConfiguration
  };
};

export type UseApp = (externalId: string) => {
  isReady: boolean;
  configuration: ConfigurationClient | undefined;
  symphony: SymphonyClient | undefined;
  organisationSettings: OrganisationSettings | undefined;
  organisationConfiguration: OrganisationConfiguration | undefined;
  connectionsConfiguration: ConnectionsConfiguration | undefined;
  interfaceConfiguration: InterfaceConfiguration | undefined;
};
