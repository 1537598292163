import * as React from 'react';
import { updateForm } from '@aventus/formio';

export function useCollection(collection: Collection = []): IUseCollection {
  // Flag to represent when a
  // collection item's form is clicked open.

  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(false);

  // If an item is opened up, this index keeps track
  // of it's index as an address to put the updated item back.

  const [selectedItemIndex, setSelectedItemIndex] = React.useState<number>(-1);

  // Stores the collection item's
  // form state, which as a whole is

  const [formState, setFormState] = React.useState<any>(undefined);

  function updateFormState(formStateAddress: string, value: any) {
    const updatedFormState = updateForm(formState, formStateAddress, {
      $set: value
    });
    setFormState(updatedFormState);
  }

  // C(!R)UD functions to manipulate the
  // collection. Pretty self-explanatory from
  // here on.
  // It is very important that each of these function
  // return a new copy of the collection and not
  // modify the existing collection.

  function addToCollection(item: any) {
    return [...(collection || []), item];
  }

  function deleteFromCollection(index: number) {
    const _collection = [...collection];
    _collection.splice(index, 1);
    return _collection;
  }

  function updateInCollection(item: any, index: number) {
    const _collection = [...collection];
    _collection[index] = item;
    return _collection;
  }

  return {
    isFormOpen,
    setIsFormOpen: (value: boolean, index: number = -1) => {
      setIsFormOpen(value);
      setSelectedItemIndex(index);
    },
    selectedItemIndex,
    formState,
    setFormState,
    updateFormState,
    addToCollection,
    deleteFromCollection,
    updateInCollection
  };
}

export type Collection = Array<any>;

export interface IUseCollection {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean, index?: number) => void;
  formState: any;
  setFormState: (value: any) => void;
  updateFormState: (formStateAddress: string, value: any) => void;
  selectedItemIndex: number;
  addToCollection: (item: any) => Collection;
  deleteFromCollection: (index: number) => Collection;
  updateInCollection: (item: any, index: number) => Collection;
}
