import { CreateExperianClient, Search, SearchResults, Format } from './types';
import { AxiosPromise } from 'axios';
import { createClient } from '@aventus/client';
import { interceptResponse } from './intercept-response';

export const createExperianClient: CreateExperianClient = function (
  options,
  token
) {

  const experian = createClient(options);

  experian.interceptors.response.use(
    interceptResponse.onFulfilled,
    interceptResponse.onRejected
  );

  // Get an organisation's global configuration settings.
  // These include global definitions for a given org like what
  // timezone they are based in along with what currency.

  const search: Search = async (query, country) => {

    const response = (
      await <AxiosPromise<SearchResults>>
      experian.get(`/search?query=${ query }&country=${ country }&auth-token=${ token }&dataset=${ 'DataFusion' }` )
    );

    return response.data;
  }

  // Fetch address data from GBG lookup. This is a
  // hard-coded address lookup service that is associated with the
  // client because Homelyfe. Hopefully this is something we can
  // fix down the line.

  const format: Format = async (formatUrl: string) => {

    const response = (
      await <AxiosPromise<any>>
      experian.get(`${ formatUrl }&auth-token=${ token }`)
    );

    return response.data;
  }

  return {
    search,
    format
  };

}
