import * as React from 'react';

export function useStickyMode (
  useStickyMode?: boolean,
  onStickyModeChange?: (stickyMode: boolean) => void
) {

  const appDisplayRef = React.useRef<any>(null);
  const [ appDisplayHeight, setAppDisplayHeight ] = React.useState<number>(220);

  const [ isSticky, setIsSticky ] = React.useState<boolean>(false);

  const _setIsSticky = (stickyMode: boolean) => {
    setIsSticky(stickyMode);
    if (onStickyModeChange && typeof onStickyModeChange === 'function') {
      onStickyModeChange(stickyMode);
    }
  }

  React.useEffect(() => {

    // On initial mount, we need to find out what the height
    // of the app display component is. This isn't fixed, since the BladeAppDisplay
    // component is a helper component used by other components, which
    // can vary the height.

    if (appDisplayRef?.current?.clientHeight) {
      setAppDisplayHeight(appDisplayRef?.current?.clientHeight);
    }

  }, []);

  React.useLayoutEffect(
    () => {
      if (useStickyMode) {

        const handleScroll = () => {

          // We add an extra 20px here to give some breathing
          // room for the whole experience.

          if (window.scrollY >= (appDisplayHeight + 20)) {
            !isSticky && _setIsSticky(true);
          } else {
            isSticky && _setIsSticky(false);
          }
        };

        window.addEventListener('scroll', handleScroll);

        return (
          () => window.removeEventListener('scroll', handleScroll)
        );

      } else {
        return () => {};
      }
    },
    [ window.scrollY ]
  );

  return {
    isSticky,
    appDisplayRef
  };

}
