import { ToApplicationReadable } from '@aventus/application-timezone';

export function builtInInputDateValidator(
  value: string,
  dateformat: string,
  toApplicationReadable: ToApplicationReadable
): string | undefined {
  var isValid = true;

  if (value == null) {
    return undefined; //We have a required rule which checks this, so its not this ones job
  }

  if (value.toString().includes('/')) {
    //Reason for this below is because moment will see a partial date as a valid date :(
    var splitDate = value.split('/');
    var splitFormat = dateformat.split('/');
    var day = '';
    var month = '';
    var year = '';

    for (var i = 0; i < splitFormat.length; i++) {
      if (splitDate[i] != '' && splitDate[i] != null) {
        switch (splitFormat[i].toUpperCase()) {
          case 'DD':
            day = splitDate[i];
            break;
          case 'MM':
            month = splitDate[i];
            break;
          case 'YYYY':
            year = splitDate[i];
            break;
        }
      }
    }

    if (day == '' || month == '' || year == '' || year.length < 4) {
      isValid = false;
    }
  }

  if (value == '//') {
    return 'Please fill in this field';
  }

  if (isValid) {
    //If we have a complete date, then lets let moment validate it
    isValid = toApplicationReadable(value).isValid();
  }

  if (!isValid) {
    return 'This date is invalid';
  }

  return undefined;
}
