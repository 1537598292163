import * as React from 'react';
import { ServerError } from '@aventus/errors';

export class PolicyBoundary extends React.Component<IPolicyBoundary> {
  constructor(props: any) {
    super(props);
  }

  componentDidCatch(error: any) {
    if (error instanceof ServerError) {
      this.props.goHome && this.props.goHome();
    }

    throw error;
  }

  render() {
    return this.props.children;
  }
}

export interface IPolicyBoundary {
  goHome?: () => void;
  goToOrigin?: () => void;
}
