import React, { useEffect } from 'react';
import isemail from 'isemail';
import { useForm, Controller } from 'react-hook-form';
import { BladeButton, BladeInputText, BladeFormFieldset } from '@aventus/blade';

type ResetPasswordFormData = {
  email: string;
};

interface IResetPasswordComponentProps {
  flags: any;
  email: string;
  onSubmit: (data: ResetPasswordFormData) => void;
}

const ResetPasswordComponent: React.FC<IResetPasswordComponentProps> = ({
  flags,
  email,
  onSubmit
}): JSX.Element => {
  const { control, errors, handleSubmit, setValue } =
    useForm<ResetPasswordFormData>({
      defaultValues: {
        email
      }
    });

  useEffect(() => {
    setValue('email', email);
  }, [email]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BladeFormFieldset errorMessage={errors?.email && errors.email.message}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Email is required'
            },
            validate: {
              isEmail: (value: string) =>
                isemail.validate(value, { minDomainAtoms: 2 })
                  ? true
                  : 'Email address provided is invalid'
            }
          }}
          render={({ onChange, onBlur, value }) => (
            <BladeInputText
              name="username"
              label={'Your email'}
              type="text"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </BladeFormFieldset>

      <BladeButton
        isWorking={flags.isResettingPassword}
        isDisabled={flags.isResettingPassword}
        type="submit"
      >
        {'Send reset request'}
      </BladeButton>
    </form>
  );
};

export default ResetPasswordComponent;
