import React, { useState } from 'react';
import {
  BladeButton,
  BladeMarkdown,
  BladeMessage,
  BladeSurface
} from '@aventus/blade';
import { IBNPPaymentRequestData, Money, Risk } from '@aventus/platform';
import { BNPOverview, BNPView, BNPNavigation } from '../';
import { IUniversalAddress } from '@aventus/platform-client-context/models/policyholder';
import { Moment } from 'moment';
import YourDetails from './sub-views/bnp-your-details';
import AddressHistory from './sub-views/bnp-address-history';
import IncomeDetails from './sub-views/bnp-income-details';
import PaymentDetails from './sub-views/bnp-payment-details';
import css from './index.css';
import { useBladeInlineError } from '@aventus/blade/inline-error';

export enum EFieldMessages {
  required = 'This field is required.'
}

const VALIDATION = {
  required: [
    'title',
    'firstName',
    'lastName',
    'telephone',
    'emailAddress',
    'dateOfBirth',
    'nationality',
    'countryOfBirth',
    'currentAddress',
    'yearsAtCurrentAddress',
    'employmentStatus',
    'netMonthlyIncome',
    'bankAccountNumber',
    'sortCode',
    'isApplicantBankAccount',
    'isJointAccount'
  ]
};

export const BNPComponent: React.FunctionComponent<IBNPWrapper> = props => {
  const [store, setStore] = useState<IDataStore>({
    title: null,
    firstName: props.risk.proposer.personName.firstName,
    lastName: props.risk.proposer.personName.lastName,
    emailAddress: props.risk.proposer.emailAddress,
    telephone: null,
    dateOfBirth: props.risk.proposer.dateOfBirth,
    nationality: { referenceID: 'GBR', text: 'United Kingdom' },
    countryOfBirth: { referenceID: 'GBR', text: 'United Kingdom' },
    currentAddress: null,
    previousAddress: null,
    yearsAtPreviousAddress: null,
    yearsAtCurrentAddress: null,
    employmentStatus: null,
    industrySector: null,
    employmentSector: null,
    netMonthlyIncome: null,
    bankAccountNumber: null,
    sortCode: null,
    isApplicantBankAccount: null,
    isJointAccount: null,
    thirdPartyAccountName: null
  });

  const { setError, clearErrors } = useBladeInlineError();

  const [viewIndex, setViewIndex] = useState<number>(0);
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);

  const validateDataStore = (
    data: IDataStore
  ): IBNPPaymentRequestData | null => {
    const errors: string[] = [];

    Object.keys(data).forEach((key: string) => {
      if (
        VALIDATION.required.includes(key) &&
        data[key as keyof IDataStore] === undefined
      ) {
        errors.push(key);
      }
    });

    if (errors && errors.length > 0) {
      setError(
        'bnperror',
        'Sorry, some of the fields in the payment form are incomplete. Please check your details and try again.'
      );

      return null;
    }

    return {
      encryptedCreditPlan: props.encryptedCreditPlan,
      title: data.title?.value as string,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      dateOfBirth:
        typeof data.dateOfBirth === 'string'
          ? data.dateOfBirth
          : data.dateOfBirth.format('YYYY-MM-DDT00:00:00'),
      telephone: data.telephone as string,
      nationality: data.nationality.referenceID,
      countryOfBirth: data.countryOfBirth.referenceID,
      currentAddress: {
        line1: data.currentAddress?.line1,
        line2: data.currentAddress?.line2,
        line3: data.currentAddress?.line3 || null,
        locality: data.currentAddress?.locality,
        postalCode: data.currentAddress?.postalCode,
        province:
          data.currentAddress?.province &&
          typeof data.currentAddress?.province !== 'string' &&
          data.currentAddress?.province.referenceID
      } as IUniversalAddress,
      yearsAtCurrentAddress: Number(data.yearsAtCurrentAddress) as number,
      previousAddress: data.previousAddress
        ? ({
            line1: data.previousAddress.line1,
            line2: data.previousAddress.line2,
            line3: data.previousAddress.line3 || null,
            locality: data.previousAddress.locality,
            postalCode: data.previousAddress.postalCode,
            province:
              data.previousAddress?.province &&
              typeof data.previousAddress?.province !== 'string' &&
              data.previousAddress?.province.referenceID
          } as IUniversalAddress)
        : null,
      yearsAtPreviousAddress: Number(data.yearsAtPreviousAddress) || null,
      employmentStatus: data.employmentStatus?.referenceID as string,
      industrySector: Number(data.industrySector?.referenceID) || null,
      employmentSector: Number(data.employmentSector?.referenceID),
      netMonthlyIncome: data.netMonthlyIncome as Money,
      bankAccountNumber: data.bankAccountNumber as string,
      sortCode: data.sortCode as string,
      isApplicantBankAccount: data.isApplicantBankAccount?.value as boolean,
      isJointAccount: data.isJointAccount?.value as boolean,
      thirdPartyAccountName: data.thirdPartyAccountName
    };
  };

  const handleSubmit = () => {
    // This is where we are going to pass the
    // data back as part of the paymentRequest.

    // We will need to format this for the API call
    // as a lot of the fields are objects at the moment.
    const validatedStore = validateDataStore(store);

    if (validatedStore !== null) {
      setIsFormSubmited(true);
      props.onReady(validatedStore);
      props.submitCallback(true);
    }
  };

  const handleUpdate = (data: object) => {
    setStore(prevStore => {
      return {
        ...prevStore,
        ...data
      };
    });
  };

  return (
    <BladeSurface
      title={props.title || 'BNP Finance'}
      shouldRespectViewWidth={true}
      className={css.bnpSurface}
    >
      {props.error && (
        <BladeMessage isFlush={true} variant={'error'} message={props.error} />
      )}

      {!isFormSubmited && (
        <BNPNavigation
          navigation={[
            { title: 'Your details', index: 0 },
            { title: 'Address history', index: 1 },
            { title: 'Income details', index: 2 },
            { title: 'Payment details', index: 3 },
            { title: 'Finance terms', index: 4 }
          ]}
          currentIndex={viewIndex}
        />
      )}

      {isFormSubmited && (
        <BNPOverview
          store={store}
          formCallback={(reset: boolean) => {
            setViewIndex(0);
            setIsFormSubmited(reset);
          }}
        />
      )}

      {!isFormSubmited && (
        <div className={css.bnpViewsWrapper}>
          <BNPView active={viewIndex === 0} title="Your Details">
            <YourDetails
              title={store.title}
              firstName={
                store.firstName || props.risk.proposer.personName.firstName
              }
              lastName={
                store.lastName || props.risk.proposer.personName.lastName
              }
              telephone={store.telephone}
              emailAddress={
                store.emailAddress || props.risk.proposer.emailAddress
              }
              dateOfBirth={store.dateOfBirth || props.risk.proposer.dateOfBirth}
              nationality={
                store.nationality || {
                  referenceID: 'GBR',
                  text: 'United Kingdom'
                }
              }
              countryOfBirth={
                store.countryOfBirth || {
                  referenceID: 'GBR',
                  text: 'United Kingdom'
                }
              }
              updateStore={handleUpdate}
              nextStep={() => {
                setViewIndex(1);
                clearErrors();
              }}
              validationError={message => setError('bnperror', message)}
            />
          </BNPView>
          <BNPView active={viewIndex === 1} title="Address History">
            <AddressHistory
              currentAddress={store.currentAddress}
              previousAddress={store.previousAddress}
              yearsAtCurrentAddress={store.yearsAtCurrentAddress}
              yearsAtPreviousAddress={store.yearsAtPreviousAddress}
              nextStep={() => {
                setViewIndex(2);
                clearErrors();
              }}
              prevStep={() => setViewIndex(0)}
              updateStore={handleUpdate}
              validationError={message => setError('bnperror', message)}
            />
          </BNPView>
          <BNPView active={viewIndex === 2} title="Income">
            <IncomeDetails
              employmentStatus={store.employmentStatus}
              industrySector={store.industrySector}
              employmentSector={store.employmentSector}
              netMonthlyIncome={store.netMonthlyIncome}
              nextStep={() => {
                setViewIndex(3);
                clearErrors();
              }}
              prevStep={() => setViewIndex(1)}
              updateStore={handleUpdate}
              validationError={message => setError('bnperror', message)}
            />
          </BNPView>
          <BNPView active={viewIndex === 3} title="Payment Details">
            <PaymentDetails
              bankAccountNumber={store.bankAccountNumber}
              sortCode={store.sortCode}
              isApplicantBankAccount={store.isApplicantBankAccount}
              isJointAccount={store.isJointAccount}
              thirdPartyAccountName={store.thirdPartyAccountName}
              nextStep={() => {
                setViewIndex(4);
                clearErrors();
              }}
              prevStep={() => setViewIndex(2)}
              updateStore={handleUpdate}
              validationError={message => setError('bnperror', message)}
              financeGuarentee={props.financeGuarentee}
            />
          </BNPView>
          <BNPView active={viewIndex === 4} title="Finance Terms">
            <div className={css.bnpTermsWrapper}>
              <BladeMarkdown markdown={props.financeTerms} />
            </div>
            <div className={css.bnpButtonWrap}>
              <BladeButton
                type="button"
                onClick={() => setViewIndex(3)}
                variant="secondary"
              >
                Previous
              </BladeButton>
              <BladeButton
                type="button"
                onClick={handleSubmit}
                variant="primary"
              >
                Accept
              </BladeButton>
            </div>
          </BNPView>
        </div>
      )}
    </BladeSurface>
  );
};

export type Honorific = {
  text: string;
  value: string;
};

export type InputSelectFieldType = {
  referenceID: string;
  text: string;
};

export type BooleanFieldType = {
  referenceID: string;
  text: string;
  value: boolean;
};

export interface IDataStore {
  title: Honorific | null;
  firstName: string;
  lastName: string;
  telephone: string | null;
  emailAddress: string;
  dateOfBirth: Moment;
  nationality: InputSelectFieldType;
  countryOfBirth: InputSelectFieldType;
  currentAddress: IUniversalAddress | null;
  previousAddress: IUniversalAddress | null;
  yearsAtPreviousAddress: string | null;
  yearsAtCurrentAddress: string | null;
  employmentStatus: InputSelectFieldType | null;
  industrySector: InputSelectFieldType | null;
  employmentSector: InputSelectFieldType | null;
  netMonthlyIncome: Money | null;
  bankAccountNumber: string | null;
  sortCode: string | null;
  isApplicantBankAccount: BooleanFieldType | null;
  isJointAccount: BooleanFieldType | null;
  thirdPartyAccountName: string | null;
}

export interface IBNPWrapper {
  title?: string;
  risk: Risk;
  financeGuarentee: string | undefined;
  financeTerms: string | undefined;
  encryptedCreditPlan: string;
  onReady: (request: IBNPPaymentRequestData) => void;
  submitCallback: (arg: boolean) => void;
  error?: string | undefined;
}
