import PricePanel from './price-panel';
import React from 'react';
import icons from '../icons/styles.css'
import styles from './styles.css';
import { InsuranceProductConfiguration } from '@aventus/configuration';

interface BenefitsPanelProps {
  stackedPrice: boolean;
  annualSaving: string;
  monthlyPrice: string;
  annualPlan: string;
  percentageSaving: number;
  adminFee: string;
  firstMonthlyPayment: string;
  productConfiguration?: InsuranceProductConfiguration
}

const benefitText = (benefits: { [key: string]: string } | undefined, key: string, fallback: string) => {
  return benefits && benefits[key] ? benefits[key] : fallback;
}

const BenefitsPanel = (props: BenefitsPanelProps) => (

  <>
    <div
      className={`${styles.whitePanel} ${
        props.stackedPrice ? 'stackedPrice' : ''
      }`}
    >
      <PricePanel
        annualSaving={props.annualSaving}
        monthlyPrice={props.monthlyPrice}
        percentageSaving={props.percentageSaving}
        annualPrice={props.annualPlan}
        firstMonthlyPayment={props.firstMonthlyPayment}
      />

      <p className={styles.smallprint}>
        Incl. GST, Gov't charges and {props.adminFee} admin fee.
      </p>

      <h2 className={styles.sectionTitle}>
        Your Comprehensive Car Insurance includes:
      </h2>

      <p>
        Cover for things like accidents, fire, theft and vandalism, natural
        disasters, and for damage your car causes to other people's property.
      </p>

      <div className={styles.benefitsContainer}>
        <div>
          <div className={icons.accidentIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "accident", "Accident damage, fire and theft") }</div>
        </div>
        <div>
          <div className={icons.newCarIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "newCar", "New car replacement") }</div>
        </div>
        <div>
          <div className={icons.noClaimIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "noClaims", "No Claim Reward") }</div>
        </div>
        <div>
          <div className={icons.hireCarIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "hireCar", "Hire car when you're not at fault") }</div>
        </div>
        <div>
          <div className={icons.thirdPartyIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "thirdPartyLiability", "$30m Third Party Liability") }</div>
        </div>
        <div>
          <div className={icons.personalItemsIcon}></div>
          <div>{benefitText(props.productConfiguration?.quote.estimate.benefits, "personalItems", "Up to $1,000 for personal items") }</div>
        </div>
        {/* <div>
            <div className={styles.repairsIcon}></div>
            <div>Lifetime guarantee on repairs</div>
          </div>
          <div>
            <div className={styles.babyGearIcon}></div>
            <div>Up to $2,000 for baby gear</div>
          </div> */}
      </div>

      <h2 className={`${styles.sectionTitle} ${styles.leftAligned}`}>
        Optional Extras
      </h2>

      <p className={styles.leftAligned}>
        Optional extras so you can customise your comprehensive policy until
        it's just right for you.
      </p>

      <div className={`${styles.benefitsContainer}`}>
        <div>
          <div className={icons.spannerIcon}></div>
          <div>Roadside Assistance</div>
        </div>
        <div>
          <div className={icons.windscreenIcon}></div>
          <div>'No Excess' Windscreen</div>
        </div>
        <div>
          <div className={icons.keysIcon}></div>
          <div>Hire Car - Extra</div>
        </div>
      </div>
    </div>
  </>
);

export default BenefitsPanel;
