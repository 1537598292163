import * as React from 'react';
import css from './index.css';
import { BladeSurface, BladeMarkdown } from '@aventus/blade';

export const DetailsOfYourCover: React.FunctionComponent<IDetailsOfYourCover> = props => {
  return (
    <BladeSurface
      title={props.title || 'Your cover'}
      shouldRespectViewWidth={props.shouldRespectViewWidth}
    >
      <BladeMarkdown className={css.content_class} markdown={props.markdown} />
    </BladeSurface>
  );
};

interface IDetailsOfYourCover {
  shouldRespectViewWidth?: boolean;
  markdown: any;
  title: string;
}
