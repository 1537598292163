import usePlatform from '@aventus/platform-client-context/use-platform';
import { IPolicyDetailEditSession } from '@aventus/platform-client-context/models';
import { useQuery, UseQueryResult } from 'react-query';

export function useGetVariantSession(
  editSessionID: string | null | undefined
): UseQueryResult<IPolicyDetailEditSession> {
  const platform = usePlatform();

  return useQuery(
    ['getEditSession', editSessionID],
    () => {
      if (!editSessionID) {
        throw new Error('Cannot get variant session without EditSessionID.');
      }

      return platform.getEditSession(editSessionID);
    },
    {
      enabled: !!editSessionID
    }
  );
}
