import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import SocialAuth from '../_social-auth';
import SocialAuthFirebase from '../_social-auth/firebase-google';
import { Scaffold, Typo } from '@honey/consume-react-jss';
import {
  BladeLink,
  BladeButton,
  BladeFormFieldset,
  BladeInputPassword,
  BladeEmail
} from '@aventus/blade';
import isemail from 'isemail';
import {
  AuthenticationConfiguration,
  AuthenticationProvider
} from '@aventus/platform';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { OrganisationConfiguration } from '@aventus/configuration';

export type SignUpFormData = {
  username: string;
  password: string;
  orgConfig?: OrganisationConfiguration | undefined;
};

type SignUpComponentProps = {
  usernamePrefill?: string;
  passwordPrefill?: string;
  goToSignIn: () => void;
  onSubmit: (data: SignUpFormData) => void;
  authenticationMethods: string[];
  authenticationProvider: AuthenticationProvider;
  authenticationConfig: AuthenticationConfiguration;
};

const SignUpComponent = ({
  usernamePrefill = '',
  passwordPrefill = '',
  goToSignIn,
  onSubmit,
  authenticationConfig,
  authenticationMethods,
  authenticationProvider
}: SignUpComponentProps) => {
  const { control, errors, handleSubmit } = useForm<SignUpFormData>({
    defaultValues: {
      username: usernamePrefill,
      password: passwordPrefill
    }
  });

  const { organisationConfiguration } = useGetOrganisationConfiguration();

  return (
    <>
      {authenticationMethods.includes('form') && (
        <React.Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BladeFormFieldset
              errorMessage={errors?.username && errors.username.message}
              addonBottom={
                authenticationConfig?.signUp?.policyEmailText ||
                "This is where we'll send your policy information."
              }
            >
              <Controller
                name="username"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message:
                      authenticationConfig?.signUp?.validationMessages
                        ?.usernameRequired || 'Email is required'
                  },
                  validate: {
                    isEmail: (value: string) =>
                      isemail.validate(value, { minDomainAtoms: 2 })
                        ? true
                        : authenticationConfig?.signUp?.validationMessages
                            ?.emailAddressInvalid ||
                          'Email address provided is invalid'
                  }
                }}
                render={({ onChange, onBlur, value }) => (
                  <BladeEmail
                    name="username"
                    label={
                      authenticationConfig?.signUp?.usernameLabel ||
                      'Your email'
                    }
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    customDomains={
                      organisationConfiguration?.emailSuggestion?.customDomains
                    }
                  />
                )}
              />
            </BladeFormFieldset>

            <BladeFormFieldset
              errorMessage={errors?.password && errors.password.message}
              addonBottom={
                authenticationConfig?.signUp?.passwordHelperText ||
                'Ensure your password includes at least 8 characters, upper and lower case letters and at least 1 number.'
              }
            >
              <Controller
                name="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message:
                      authenticationConfig?.signUp?.validationMessages
                        ?.passwordRequired || 'Password is required'
                  },
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long'
                  },
                  validate: {
                    hasNumbers: (value: string) =>
                      new RegExp('(?=.*[0-9])').test(value) ||
                      'Ensure your password includes at least one number',
                    hasUppercase: (value: string) =>
                      new RegExp('(?=.*[A-Z])').test(value) ||
                      'Ensure your password includes at least one uppercase letter',
                    hasLowercase: (value: string) =>
                      new RegExp('(?=.*[a-z])').test(value) ||
                      'Ensure your password includes at least one lowercase letter'
                  }
                }}
                render={({ onChange, onBlur, value }) => (
                  <BladeInputPassword
                    name="password"
                    label={
                      authenticationConfig?.signUp?.passwordLabel ||
                      'Choose a password'
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </BladeFormFieldset>
            <BladeButton id="sign-up-sign-up-button" type="submit">
              {authenticationConfig?.signUp?.createAccountLabel ||
                'Create Account'}
            </BladeButton>

            <Scaffold position={'c'} size={'default'} only={'children'}>
              <Typo>
                {authenticationConfig?.signUp?.alreadyRegisteredLabel ||
                  'Already registered?'}
              </Typo>
              <BladeLink onClick={() => goToSignIn()}>
                {authenticationConfig?.signUp?.signInButtonText || `Sign in`}
              </BladeLink>
            </Scaffold>
          </form>
        </React.Fragment>
      )}

      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth
            type="google"
            label={
              authenticationConfig?.signUp?.socialAuthText || 'Sign up with'
            }
          />
        )}

      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Firebase' && (
          <SocialAuthFirebase
            type="google"
            label={
              authenticationConfig?.signUp?.socialAuthText || 'Sign up with'
            }
          />
        )}

      {authenticationMethods.includes('facebook') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth
            type="facebook"
            label={
              authenticationConfig?.signUp?.socialAuthText || 'Sign up with'
            }
          />
        )}
    </>
  );
};

export default SignUpComponent;
