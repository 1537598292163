import * as React from 'react';
import css from './index.css';
import { BladePage, BladePageCover, BladeView } from '@aventus/blade';
import { PolicyListMessage } from '../../components/policy-list-message';
import { PolicyListQuote } from '../../components/policy-list-quote';
import { PolicyList } from '../../components/policy-list';
import { useGetPolicies } from '@aventus/symphony-client/hooks';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';
import { useGetCustomConfig } from '@aventus/symphony-client/hooks';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const ProductDashboardCustom: React.FunctionComponent<
  IProductDashboardCustom
> = props => {
  const { applicationConfiguration } = useGetApplicationConfiguration();
  const { customConfig } = useGetCustomConfig();
  const { updateProductRef } = useDispatchEvent();

  React.useEffect(() => {
    updateProductRef(null);
  }, []);

  if (props.auth) {
    const { policiesCount } = useGetPolicies(false);
    const isPoliciesEmpty = policiesCount === 0;
    return (
      <BladePageCover
        pageBackground={
          applicationConfiguration?.customDashboardSettings?.dashboardImage ||
          ''
        }
        pageBackgroundColor={
          applicationConfiguration?.customDashboardSettings?.dashboardColor ||
          ''
        }
      >
        <BladePage viewWidth={'dashboard'} title={'Dashboard'}>
          <BladeView className={css.productDashboardCustom} variant="s">
            <PolicyListMessage isPolicyListEmpty={isPoliciesEmpty} />

            <PolicyList
              goToPolicy={props.goToPolicy}
              layout="custom"
              image={
                applicationConfiguration?.customDashboardSettings
                  ?.dashboardListIcon ||
                customConfig?.userInterface.policy.image ||
                ''
              }
              icon={customConfig?.userInterface.policy.icon || ''}
              label={customConfig?.userInterface.policy.label || ''}
            />
            <PolicyListQuote
              goToProduct={props.goToProduct}
              isPolicyListEmpty={isPoliciesEmpty}
            />
          </BladeView>
        </BladePage>
      </BladePageCover>
    );
  } else {
    return (
      <BladePageCover
        pageBackground={
          applicationConfiguration?.customDashboardSettings?.dashboardImage ||
          ''
        }
        pageBackgroundColor={
          applicationConfiguration?.customDashboardSettings?.dashboardColor ||
          ''
        }
      >
        <BladePage viewWidth={'dashboard'} title={'Dashboard'}>
          <BladeView className={css.productDashboardCustom} variant="s">
            <PolicyListMessage isPolicyListEmpty={true} />
            <PolicyListQuote
              goToProduct={props.goToProduct}
              isPolicyListEmpty={true}
            />
          </BladeView>
        </BladePage>
      </BladePageCover>
    );
  }
};

export interface IProductDashboardCustom {
  goToPolicy: (arg: string) => void;
  goToProduct: (reference: string, id: string) => void;
  goToSignIn: () => void;
  signOut: () => void;
  auth: boolean | {};
}
