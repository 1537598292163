import { QuoteType, Risk } from '@aventus/platform';
import { IntelligentQuoteQuestion } from '@aventus/platform';
import { getFromForm } from '@aventus/formio';
import moment from 'moment';

export const roadsideIsHiddenOracle = (
  question?: IntelligentQuoteQuestion,
  risk?: Risk,
  quoteType?: QuoteType,
  updateRisk?: (
    riskAddress: string,
    value: any,
    isCollection: boolean,
    isValid?: boolean
  ) => void
) => {
  if (question?.referenceID === 'stella_q_roadside_assistance' && risk) {
    const maxYears = 15;
    const isNewBusiness = quoteType === 'NewBusiness';
    const hasRoadside = getFromForm(risk, 'addon.roadsideAssistance') === true;
    const vehicleYear: number = getFromForm(risk, 'vehicle.specification.year');
    const currentYear = moment().year();
    const difference = currentYear - vehicleYear;

    if (difference <= maxYears) return false;

    if (isNewBusiness || (!isNewBusiness && !hasRoadside)) {
      updateRisk && hasRoadside && updateRisk(question.riskPath, false, false);
      return true;
    }
  }
  return false;
};
