import * as React from 'react';
import { BladeIcon } from '../../../icon';
import css from './index.css';
import cx from 'classnames';

export const BladeAppBarPhone: React.FunctionComponent<IBladeAppBarPhone> = props => {
  return (
    <div className={cx(
      css.appBarContent_right_phoneNumber,
      props.className
    )}>

      {
        !props.hideIcon &&
          <BladeIcon
            name={'faPhoneAlt'}
            className={css.appBarContent_right_phoneNumber_icon}
          />
      }

      <a
        href={`tel:${props.phone.number}`}
        className={css.appBarContent_right_phoneNumber_link}
        id={'phone-number'}
      >
        {props.phone.label}
      </a>
    </div>
  );
};

interface IBladeAppBarPhone {
  /** Renders an optional phone number on top of the burger menu if defined */
  phone: {
    label: string;
    number: string;
  };
  hideIcon?: boolean
  className?: string
}
