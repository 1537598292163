import { AUTH_RESET_PASSWORD_NEW_PASSWORD_REQUEST, AUTH_RESET_PASSWORD_NEW_PASSWORD_SUCCESS, AUTH_RESET_PASSWORD_NEW_PASSWORD_FAILURE } from './types';
import { API_SYM, transformObject } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';

export default function resetPasswordNewPasswordAction(password, resetToken) {

  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/updateforgottenpassword',
        method: 'POST',
        transformRequest: [transformObject],
        data: {
          password,
          requestID: resetToken
        }
      },
      actions: [resetPasswordNewPasswordRequestAction, resetPasswordNewPasswordSuccessAction, resetPasswordNewPasswordFailureAction]
    }
  };
}

export function resetPasswordNewPasswordRequestAction() {
  return {
    type: AUTH_RESET_PASSWORD_NEW_PASSWORD_REQUEST
  };
}

export function resetPasswordNewPasswordSuccessAction() {
  return {
    type: AUTH_RESET_PASSWORD_NEW_PASSWORD_SUCCESS
  }
}

export function resetPasswordNewPasswordFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_RESET_PASSWORD_NEW_PASSWORD_FAILURE
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
