import * as React from 'react';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { BladeView, BladeViewVariant } from '../view';
import { BladeLoader } from '../loader';
import { BladeAppDisplay } from '../app-display';
import css from './index.css';
import cx from 'classnames';
import Markdown from 'markdown-to-jsx';

export const BladePriceDisplay: React.FunctionComponent<
  BladeIPriceDisplay
> = props => {
  const [isInStickyMode, setIsInStickyMode] = React.useState<boolean>(false);

  const humanReadablePrice = props.price
    ? currencyToHumanReadable(props.price)
    : undefined;

  const priceInParts = humanReadablePrice
    ? humanReadablePrice.split('.')
    : undefined;

  return (
    <BladeAppDisplay
      useStickyMode={props.useStickyMode}
      className={cx('bladePriceDisplay', {
        [css.display_compact]: props.priceCompact
      })}
      classNameContainer={props.priceCompact ? css.display_wrapper : ''}
      onStickyModeChange={(stickyMode: boolean) => {
        setIsInStickyMode(stickyMode);
        props.onStickyModeChange?.(stickyMode);
      }}
    >
      <BladeView
        variant={props.viewVariant}
        className={
          props.priceCompact ? css.priceDisplayCompact : css.priceDisplay
        }
      >
        {!props.isDeclined && !props.isUpdatingPrice && priceInParts && (
          <div
            className={
              !props.priceCompact ? css.priceDisplay_content : undefined
            }
          >
            {props.title && !isInStickyMode && (
              <label
                className={
                  props.priceCompact
                    ? css.priceDisplayCompact_label
                    : css.priceDisplay_label
                }
              >
                {props.isAdjust && props.price?.value === 0
                  ? 'No fee'
                  : props.title}
              </label>
            )}

            <div className={css.priceDisplay_priceBreakdown}>
              {props.isAdjust &&
                props.price &&
                props.price?.value < 0 &&
                false && (
                  <span className={css.priceDisplay_priceBreakdown_change}>
                    {'-'}
                  </span>
                )}

              {props.isAdjust && props.price && props.price?.value > 0 && (
                <span
                  className={cx(
                    props.priceCompact
                      ? css.priceDisplayCompact_priceBreakdown_major
                      : css.priceDisplay_priceBreakdown_change,
                    {
                      [css.priceDisplay_priceBreakdown_major_isSticky]:
                        isInStickyMode
                    }
                  )}
                >
                  {'+'}
                </span>
              )}

              <span
                className={cx(
                  props.priceCompact
                    ? css.priceDisplayCompact_priceBreakdown_major
                    : css.priceDisplay_priceBreakdown_major,
                  isInStickyMode
                    ? css.priceDisplay_priceBreakdown_major_isSticky
                    : ''
                )}
              >
                {priceInParts[0]}
              </span>

              {priceInParts.length > 1 && (
                <span className={css.priceDisplay_priceBreakdown_minor}>
                  .{priceInParts[1]}
                </span>
              )}

              {props.paymentPeriod && (
                <span
                  className={
                    props.priceCompact
                      ? css.priceDisplayCompact_priceBreakdown_paymentPeriod
                      : css.priceDisplay_priceBreakdown_paymentPeriod
                  }
                >
                  {props.paymentPeriod}
                  {props.showSmallPrintAsterisk && '*'}
                </span>
              )}
            </div>

            {props.alternatePrice && (
              <label className={css.priceDisplay_content_alternatePrice}>
                {props.alternatePrice}
              </label>
            )}

            {props.smallPrint && !isInStickyMode && (
              <div
                className={
                  props.priceCompact
                    ? css.priceDisplayCompact_smallPrint
                    : css.priceDisplay_content_smallPrint
                }
              >
                {props.smallPrint.map((smallPrint: string, i: number) => (
                  <small key={i}>
                    <Markdown options={{ forceInline: true }}>
                      {smallPrint}
                    </Markdown>
                  </small>
                ))}
              </div>
            )}
          </div>
        )}

        {props.isUpdatingPrice && <BladeLoader />}

        {!props.isUpdatingPrice && props.isDeclined && (
          <span className={css.priceDisplay_priceBreakdown_major}>{'--'}</span>
        )}
      </BladeView>
    </BladeAppDisplay>
  );
};

export interface BladeIPriceDisplay {
  title?: string;
  price?: IBladeMoney;
  alternatePrice?: string;
  isAdjust?: boolean;
  paymentPeriod?: string;
  smallPrint?: Array<string>;
  smallPrintSummary?: string;
  showSmallPrintAsterisk?: boolean;
  viewVariant: BladeViewVariant;
  isUpdatingPrice?: boolean;
  isDeclined?: boolean;
  useStickyMode?: boolean;
  priceCompact?: boolean;
  onStickyModeChange?: (isSticky: boolean) => void;
}
