import css from './index.css';

export function getDisplayClass(display: BladeInputDisplay): string {
  switch (display) {
    case 'inline':
      return css.input_display_inline;
    case 'block':
      return css.input_display_block;
    default:
      return '';
  }
}
