import update from 'immutability-helper';

export default function updateShowCookiePolicyReducer(state, action) {
  const { value } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        showCookiePolicy: { $set: value }
      })
    }
  };
}
