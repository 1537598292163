import React from 'react';
import appApi from '../../../api';
import routes from '../../../routes';
import { BladePage } from '@aventus/blade';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import IdTokenVerifier from 'idtoken-verifier';
import { getAuth } from "firebase/auth";

class SocialCallback extends React.Component {

  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.verifyAndDecodeJWT = this.verifyAndDecodeJWT.bind(this);
    this.returnAccountType = this.returnAccountType.bind(this);
    this.handleFirebaseLogin = this.handleFirebaseLogin.bind(this);
  }

  componentDidMount() {
    if (!this.props.app.auth.user) {
      const { nonce } = this.props.app.auth;
      this.props.handleTracking('newScreen', 'Screen_name', {
        'Screen_name': routes.socialCallback.trackingName
      });

      const urlParams = this.props.location.hash.replace('#', '');

      if (urlParams.includes('access_token')) {
        const authAccessToken = queryParamGet(urlParams, 'access_token');
        const idToken = queryParamGet(urlParams, 'id_token');
        this.verifyAndDecodeJWT(idToken, nonce, authAccessToken);
      } else if (urlParams.includes('access_denied') || urlParams.includes('error')) {
        const error = decodeURI(queryParamGet(urlParams, 'error_description'));
        this.handleError(error);
      }
      else if (urlParams.includes('firebase')){
        this.handleFirebaseLogin();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const authProps = this.props.app.auth;
    const nextAuthProps = nextProps.app.auth;

    if (!authProps._flags.successfullySyncedSocialAuth && nextAuthProps._flags.successfullySyncedSocialAuth) {

      const {
        isMidFlowAuth = false,
        onSuccessGoToRoute,
        passToNextRoute = {}
      } = this.props.app.auth.midAuthFlow || {};

      if (isMidFlowAuth && onSuccessGoToRoute) {
        this.props.history.push(onSuccessGoToRoute, passToNextRoute);
      } else {
        this.props.history.push(routes.home.path);
      }

      this.props.handleAuthUpdateMidAuthFlowState(null);
    }
  }

  handleFirebaseLogin(){
    const auth = getAuth();
    if (this.props.app.core.config.auth0.firebase.tenantID) {
      auth.tenantId = this.props.app.core.config.auth0.firebase.tenantID;
    }

    if (auth.currentUser != null) {
      var payload = {
        email: auth.currentUser.email,
        sub: auth.currentUser.uid
      };

      this.props.handleAuthSocialAuth(auth.currentUser.accessToken, payload, 'google', 'Firebase');
      this.props.handleTracking('customEvent', 'Social_Login', {'type': 'google' });
    }
    else {
      this.handleError("currentUser not set")
    }
  }

  verifyAndDecodeJWT(idToken, nonce, authAccessToken) {
    const { env, auth0 } = this.props.app.core.config;

    const verifier = new IdTokenVerifier({
      issuer: `${auth0.url}/`,
      audience: env.AUTH0_CLIENT_ID_WEB
    });

    verifier.verify(idToken, nonce, (error, payload) => {
      if (payload) {
        const accountType = this.returnAccountType(payload);
        this.props.handleAuthSocialAuth(authAccessToken, payload, accountType, 'Auth0');
        this.props.handleTracking('customEvent', 'Social_Login', {
          'type': accountType
        });
      } else {
        this.handleError(error);
      }
    });
  }
  returnAccountType(payload) {
    if (payload.sub.includes('google-oauth2')) {
      return 'google'
    } else if (payload.sub.includes('facebook')) {
      return 'facebook'
    } else {
      return null
    }
  }

  handleError(error) {
    console.log("Social error", error)
    this.props.handleQueueAlert({
      message: 'There was an error, please try again',
      type: 'error',
      location: routes.signIn.path
    })
    this.props.history.push(routes.signIn.path);
  }

  render() {
    return <BladePage isLoading={ true } />
  }
}

export default appApi(SocialCallback);
