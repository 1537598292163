import { PaymentPlanType } from '@aventus/platform';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';

export function getDefaultPaymentPlanTypeQuery(
  search: string
): PaymentPlanType | undefined {
  const _paymentPlanType = String(queryParamGet(search, 'plan')).trim();

  switch (_paymentPlanType) {
    case 'FixedTermSinglePayment':
    case 'AnnualMonthlyCredit':
    case 'AnnualMonthlySubscription':
    case 'MonthlySubscription':
    case 'SinglePayment':
      return _paymentPlanType;

    default:
      return;
  }
}
