import React from 'react';
import cx from 'classnames';
import { BladeIcon } from '../../../icon';
import css from './index.css';
import { useDate } from '../use-date';
import { Moment } from 'moment';

export const DPHeader: React.FunctionComponent<IDPHeader> = ({
  date,
  setDate,
  decreaseMonth,
  changeMonth,
  changeYear,
  increaseMonth,
  toApplicationReadable,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const { today, tomorrow } = useDate(toApplicationReadable());
  const { currentYear, currentMonth } = useDate(toApplicationReadable(date));

  const setNewDate = (date: Date) => {
    setDate(date);
    changeMonth(date.getMonth());
    changeYear(date.getFullYear());
  };

  return (
    <div className={css.dp_header}>
      <div className={css.dp_header_button} onClick={() => setNewDate(today)}>
        Today
      </div>
      <div
        className={css.dp_header_button}
        onClick={() => setNewDate(tomorrow)}
      >
        Tomorrow
      </div>
      <div className={css.dp_header_controls}>
        <div className={css.dp_header_title}>
          {currentMonth} {currentYear}
        </div>
        <div className={css.dp_header_icons}>
          <BladeIcon
            className={cx(css.dp_header_chevron, {
              [css.dp_header_chevron_disabled]: prevMonthButtonDisabled
            })}
            name="faChevronLeft"
            onClick={decreaseMonth}
          />
          <BladeIcon
            className={cx(css.dp_header_chevron, {
              [css.dp_header_chevron_disabled]: nextMonthButtonDisabled
            })}
            name="faChevronRight"
            onClick={increaseMonth}
          />
        </div>
      </div>
    </div>
  );
};

interface IDPHeader {
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  changeMonth: (arg: number) => void;
  changeYear: (arg: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  setDate: (date: Date) => void;
  toApplicationReadable: (arg?: Date | String) => Moment;
  date: Date;
}
