import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';

export default function updateFlagOrFlags(flagOrFlags, value) {

  if (!flagOrFlags) {
    return {};
  }

  let updateCommand = {};

  if (_isString(flagOrFlags)) {
    updateCommand = { [flagOrFlags]: { $set: value } };
  }

  else if (_isArray(flagOrFlags)) {
    updateCommand = {
      ...Object.assign(
        ...flagOrFlags.map((flagKey) => {
          return (
            { [flagKey]: { $set: value } }
          );
        })
      )
    }
  }

  return updateCommand;
}
