import * as React from 'react';
import { BladeAppBar } from '../app-bar';
import { BladeButton } from '../button';
import { BladeMarkdown } from '../markdown';
import css from './index.css';
import cx from 'classnames';

export const BladeCookies: React.FunctionComponent<IBladeCookies> = props => (
  <BladeAppBar
    overrideVariant={'default'}
    size={'default'}
    className={cx('blade-cookies', css.cookies)}
    renderLeft={<BladeMarkdown markdown={props.text} />}
    renderRight={
      <BladeButton
        className={css.cookies_accept}
        onClick={props.acceptCookiePolicy}
        isInline={true}
      >
        {props.buttonText}
      </BladeButton>
    }
  />
);

BladeCookies.defaultProps = {
  text:
    'We use cookies to improve your experience. By using our site you are accepting our Cookie Policy.',
  buttonText: 'Accept'
};

interface IBladeCookies {
  acceptCookiePolicy: () => void;
  text?: string;
  buttonText?: string;
}
