import { ROW_COL } from './common';

export const COL = {
  display: 'flex',
  flexDirection: 'column',

  '& > *': {
    flexShrink: 0
  },

  '&.center-vertical': {
    justifyContent: 'center'
  },

  '&.center-horizontal': {
    alignItems: 'center'
  },

  ...ROW_COL
}
