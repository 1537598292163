import objectPath from 'object-path';
import axios from 'axios';

/**
 * Any action that wishes to be caught by this middleware
 * needs to dispatch the request details wrapped in this
 * Symbol.
 *
 * @example
 * `[API_SYM]: { ...requestDetails }`
 */

export const API_SYM = Symbol('AVENTUS_MIDDLEWARE_API');

export const transformObject = (data:any) => {
  return JSON.stringify(data)

}

/**
 * The default export returns a function allowing the consumer to configure
 * this middleware with specific options. The return of this invokation
 * is the actual Redux middleware.
 */

export default function middlewareApi(options:MiddlewareApiOptions) {

  const {
    apiUrlStateAddress,
    apiVersionStateAddress,
    authTokenStateAddress,
    appMode = 'prod',
    errorCodes,
    genericErrorMessage = 'An error occurred',
    dispatchIfUnauthorized
  } = options;

  return (store:any) => (next:any) => (action:MiddlewareApiAction) => {

    const requestDetails = action[API_SYM];

    if (!requestDetails) {
      return next(action);
    }

    const {

      // An ordered array of expected actions
      // dispatched according to the state of the
      // API request. The states:
      //  1. requesting
      //  2. successful response
      //  3. failed response
      actions,

      // Any bespoke data that will be passed
      // to any of the `actions` defined above.
      passToAction,

      // The expected config with all the necessary
      // information needed for the middleware to
      // make the API request
      requestConfig

    } = requestDetails;

    const [
      requestAction,
      successAction,
      errorAction
    ] = actions;

    store.dispatch(requestAction(passToAction));

    const state = store.getState();
    const apiUrl = objectPath.get(state, apiUrlStateAddress);
    const authToken = objectPath.get(state, authTokenStateAddress) || false;
    const apiVersion = requestConfig.apiVersion || objectPath.get(state, apiVersionStateAddress);

    const defaultHeaders: Record<string, string> = {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    };

    const config = {
      ...requestConfig,
      url: (
        apiVersion
          ? `${ apiUrl }/${ apiVersion }/${ requestConfig.url }`
          : `${ apiUrl }/${ requestConfig.url }`
      ),
      headers: {
        ...defaultHeaders,
        ...requestConfig.headers
      }
    };

    if (requestConfig.requiresToken === false || requestConfig.allowGuest) {
      delete config.headers.Authorization;
    }

    axios(config)
      .then(response => {
        if (response.status === 200) {
          store.dispatch(successAction(response.data.data, passToAction));
        }
      })
      .catch(error => {

        // Log the error, this gets stripped in production packages.
        console.error(error);

        if (!error.response && typeof dispatchIfUnauthorized === 'function') {

          // Since our application is multi-tenanted it could result
          // in a cross-wiring of auth tokens. This handling makes sure
          // that in this scenario, we sign the user out to clear browser storage
          // from any tokens and a delayed reload to re-fetch tenant configuration.

          /*
          store.dispatch(dispatchIfUnauthorized());
          setTimeout(
            () => { window.location.reload(); },
            2000
          );
          return;
          */
        }

        if (appMode === 'dev') {
          window['console']['error'](error);
        }

        let errorResponseObj = {
          message: genericErrorMessage
        };

        if (typeof errorCodes === 'object') {
          const knownError = errorCodes[error.response.data.error];

          if (knownError) {
            errorResponseObj = { ...knownError };
          }
        }

        let errorResponse = {
          ...errorResponseObj,
          ...{
            error: error.response.data.error,
            validationErrors: error.response.data.validationErrors
          }
        };

        if (error.response.data.error === 401 && typeof dispatchIfUnauthorized === 'function') {
          store.dispatch(dispatchIfUnauthorized());
        }

        store.dispatch(errorAction(errorResponse, passToAction));
      });
  };
}

interface MiddlewareApiOptions {
  apiUrlStateAddress:string
  apiVersionStateAddress:string|number
  authTokenStateAddress:string
  errorCodes?:{
    [prop:number]: ErrorCode
    [prop:string]: ErrorCode
  }
  genericErrorMessage?:string
  appMode?:string
  dispatchIfUnauthorized?:Function
}

interface ErrorCode {
  message:string
  recoverable?:boolean
}

interface MiddlewareApiAction {
  [API_SYM]: {
    actions:Array<Function>
    passToAction:object
    requestConfig: {
      url:string
      apiVersion?:string
      headers?:object
      requiresToken?:boolean
      allowGuest?:boolean
    }
  }
}

export interface ApiErrorResponse {
  error:string|number
  message:string
  validationErrors:Array<string>
}
