import * as React from 'react';
import { useAreAtomsEqual } from '@aventus/honey-utility-react';
import { BladeIcon } from '../icon';
import css from './index.css';
import cx from 'classnames';
import { BladeMessage } from '@aventus/blade';

export const BladeActionList: React.FunctionComponent<IBladeActionList> = props => {
  const { areAtomsEqual } = useAreAtomsEqual('--background', '--surface');
  return (
    <div className={cx('blade-action-list', css.actionList)}>
      {props.actions.length > 0 &&
        props.actions.map((actionItem, i) => (
          <div
            key={i}
            onClick={() => {
              if (!actionItem.disabled) {
                actionItem.action();
              }
            }}
            className={cx(
              css.actionListItem,
              actionItem.disabled ? css.actionListItem_disabled : '',
              areAtomsEqual ? css.actionListItem_alt : ''
            )}
          >
            <div
              className={cx(
                css.actionListItem_content,
                actionItem.disabled ? css.actionListItem_content_disabled : ''
              )}
            >
              <div className={css.actionListItem_contentInner}>
                {actionItem.image && (
                  <img
                    className={css.actionListItem_contentImage}
                    src={actionItem.image}
                  />
                )}

                {!actionItem.image && actionItem.icon && (
                  <BladeIcon name={actionItem.icon} />
                )}

                <label>{actionItem.label}</label>
              </div>

              <BladeIcon name={'faCaretRight'} />
            </div>

            {actionItem.alerts &&
              actionItem.alerts.length > 0 &&
              actionItem.alerts.map(alert => {
                return (
                  <div className={css.actionListItemAlert}>
                    <BladeMessage
                      variant={alert.type}
                      message={alert.message}
                    />
                  </div>
                );
              })}
          </div>
        ))}
    </div>
  );
};

export interface IBladeActionList {
  /** The list of actions to be rendered. */
  actions: Array<IActionItem>;
}

interface ActionItemAlert {
  type: 'info' | 'warning' | 'error';
  message: string;
  errorCode: number;
}

export interface IActionItem {
  /** A callback fn triggered when the action is clicked */
  action: () => void;
  /** The name of the action */
  label: string;
  /** Font Awesome icon */
  icon?: string;
  /** Optionally display an image instead of an icon */
  image?: string;
  /** Option alerts - if a notification state is required for a menu item */
  alerts?: ActionItemAlert[];
  /** Optional - disable button */
  disabled?: boolean;
}
