// Taken form https://github.com/sl-julienamblard/email-misspelled/
export const hotmail: string[] = [
	"hotmail.com",
	"hotmail.com.ar",
	"hotmail.com.au",
	"hotmail.be",
	"hotmail.com.br",
	"hotmail.ca",
	"hotmail.cz",
	"hotmail.cl",
	"hotmail.dk",
	"hotmail.fi",
	"hotmail.fr",
	"hotmail.gr",
	"hotmail.de",
	"hotmail.com.hk",
	"hotmail.hu",
	"hotmail.co.in",
	"hotmail.co.id",
	"hotmail.co.il",
	"hotmail.it",
	"hotmail.co.jp",
	"hotmail.co.kr",
	"hotmail.lv",
	"hotmail.lt",
	"hotmail.my",
	"hotmail.nl",
	"hotmail.no",
	"hotmail.ph",
	"hotmail.rs",
	"hotmail.sg",
	"hotmail.sk",
	"hotmail.co.za",
	"hotmail.es",
	"hotmail.se",
	"hotmail.com.tw",
	"hotmail.co.th",
	"hotmail.com.tr",
	"hotmail.com.vn",
	"hotmail.co.uk",
]

export default hotmail
