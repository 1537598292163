import * as React from 'react';
import { OrganisationConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetOrganisationConfiguration (): UseGetOrganisationConfiguration {

  const client = useConfigurationClient();
  const [ organisationConfiguration, setOrganisationConfiguration ] = React.useState<OrganisationConfiguration | undefined>(undefined);

  useAsyncEffect(async () => {
    const _organisationConfiguration = await client.getOrganisationConfiguration();
    setOrganisationConfiguration(_organisationConfiguration);
  }, []);

  return {
    organisationConfiguration
  };

}

interface UseGetOrganisationConfiguration {
  organisationConfiguration: OrganisationConfiguration | undefined
}
