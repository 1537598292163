import * as React from 'react';
import { BladeSurface } from '@aventus/blade';
import { BladeList } from '@aventus/blade';
import { Quote, PricingPlan } from '@aventus/platform';
import { getPremiumBreakdown } from '../../services/get-premium-breakdown';
import {
  InsuranceProductConfiguration,
} from '@aventus/configuration/product';
import MonthlyDisclaimer from '../monthly-disclaimer';

export const DetailsOfPremium: React.FunctionComponent<IDetailsOfPremium> = props => {

  const isMonthly = props.pricingPlan && [
    'AnnualMonthlySubscription',
    'MonthlySubscription'
  ].includes(props.pricingPlan.paymentPlan.type);

  return (
    <>
      <BladeSurface
        title={'Your payment summary'}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
      >
        <BladeList
          isFlush={true}
          shouldRespectViewWidth={props.shouldRespectViewWidth}
          list={getPremiumBreakdown(
            props.productConfiguration.pricing.paymentPlans,
            props.productConfiguration.product.addons,
            props.pricingPlan,
            props.productConfiguration.pricing.taxRecalculations,
            props.productConfiguration.pricing.basePremiumLabel
          )}
        />
      </BladeSurface>
      {props.pricingPlan && isMonthly && (
        <MonthlyDisclaimer
          plan={props.pricingPlan}
          pricingConfiguration={props.productConfiguration.pricing}
          shouldRespectViewWidth={props.shouldRespectViewWidth}
        />
      )}
    </>
  );
};

interface IDetailsOfPremium {
  shouldRespectViewWidth?: boolean;
  quote: Quote;
  pricingPlan?: PricingPlan;
  productConfiguration: InsuranceProductConfiguration;
}
