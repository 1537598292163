import * as React from 'react';
import { Lifecycles } from '../../../interface/lifecycles';
import {
  Quote,
  PricingSet,
  MtaInformation,
  Risk,
  CallingApplication
} from '@aventus/platform';
import {
  InsuranceProductConfiguration,
  PaymentProvidersConfiguration,
  OrganisationConfiguration,
  ProductInterfaceConfiguration,
  ApplicationConfiguration
} from '@aventus/configuration';
import { BladeLoadOver, BladeMessage } from '@aventus/blade';
import { BladeButton } from '@aventus/blade';
import { ICheckoutBoundaryError } from '../../../boundary/types';
import checkoutCss from '../index.css';
import { useCheckout } from '../use-checkout';
import { StripePayWithCreditCard } from '@aventus/payment-provider-stripe';
import { ReceiptAdjust } from '../../../components/receipt-adjust';
import { AdjustInfo } from '../../../components/adjust-info';
import { getAdjustCallToAction } from './get-adjust-call-to-action';
import { useBladeInlineError } from '@aventus/blade/inline-error';
import { FatZebraWrapper } from '@aventus/payment-provider-fatzebra';

export const CheckoutAdjust: React.FunctionComponent<
  ICheckoutAdjust
> = props => {
  const { getError, clearErrors } = useBladeInlineError();
  const {
    payment,
    adjustNow,
    checkoutNow,
    quotePricing,
    isCheckedOut,
    isCheckingOut,
    adjustmentType,
    canMakePayment,
    depositPayment,
    setIsCheckingOut,
    policyPaymentMethod,
    onProviderPaymentReady,
    isGettingPolicyPaymentMethod
  } = useCheckout(
    props.quote,
    props.quotePricing,
    undefined,
    props.adjustmentInformation,
    props.lifecycles,
    props.productConfiguration,
    props.organisationConfiguration
  );

  const [isCardEntryComplete, setIsCardEntryComplete] =
    React.useState<boolean>(false);

  if (adjustmentType) {
    const isStripeComponentRequired =
      (payment === 'Stripe' || depositPayment === 'Stripe') &&
      ['FixedSinglePayment', 'FinancePayment', 'SubscriptionPayment'].includes(
        adjustmentType
      );

    const isFatZebraComponentRequired =
      (payment === 'FatZebra' || depositPayment === 'FatZebra') &&
      adjustmentType === 'FixedSinglePayment';

    const isIndicative =
      props.adjustmentInformation.creditAgreementChange &&
      props.adjustmentInformation.creditAgreementChange.isIndicative;

    return (
      <>
        <BladeLoadOver
          isLoading={isCheckingOut || isGettingPolicyPaymentMethod}
          isSuccessful={isCheckedOut}
        />

        <div className={checkoutCss.checkout_frame_content}>
          <AdjustInfo
            adjustmentType={adjustmentType}
            adjustmentInformation={props.adjustmentInformation}
            adjustmentConfiguration={
              props.productConfiguration.checkout.adjustmentInformation
            }
            last4Digits={policyPaymentMethod?.last4Digits}
          />

          {/**
           * Because subscription payments do not require
           * the payment form and therefore do not initialise
           * the component/error display. We need to display
           * a separate error banner for this payment type.
           */}
          {adjustmentType === 'SubscriptionPayment' &&
            getError('fatzebraerror') && (
              <BladeMessage
                isFlush={true}
                variant={'error'}
                message={getError('fatzebraerror')}
              />
            )}

          {isFatZebraComponentRequired &&
            props.paymentProvidersConfiguration.FatZebra && (
              <FatZebraWrapper
                payment={{
                  amount: props.adjustmentInformation.amountToPayNow.value,
                  currency:
                    props.adjustmentInformation.amountToPayNow.currencyCode,
                  reference: props.quote.quoteReferenceID
                }}
                quoteID={props.quote.id}
                verificationProps={{
                  quoteID: props.quote.id,
                  paymentPlanReferenceID:
                    props.adjustmentInformation.paymentPlan
                      .paymentPlanReferenceID,
                  encryptedDiscount: quotePricing?.discountState || undefined
                }}
                title={
                  props.productConfiguration.checkout.payment.title ||
                  'Pay by card'
                }
                titleWithOptions={
                  props.productConfiguration.checkout.payment.titleWithOptions
                }
                inputVariant={props.productInterfaceConfiguration.inputs}
                onPayNow={adjustNow}
                onComplete={request =>
                  onProviderPaymentReady('FatZebra', request)
                }
                setIsComplete={setIsCardEntryComplete}
                setIsCheckingOut={setIsCheckingOut}
                isCheckingOut={isCheckingOut}
                error={getError('fatzebraerror')}
                fatZebraConfig={props.lifecycles.onFatZebraGetConfig}
                fatZebraPaymentIntentVerification={
                  props.lifecycles.onFatZebraGeneratePaymentIntentVerification
                }
              />
            )}

          {isStripeComponentRequired &&
            props.paymentProvidersConfiguration.Stripe?.key && (
              <StripePayWithCreditCard
                isHidden={adjustmentType === 'SubscriptionPayment'}
                stripeKey={props.paymentProvidersConfiguration.Stripe.key}
                title={undefined}
                titleWithOptions={undefined}
                inputVariant={props.productInterfaceConfiguration.inputs}
                setIsComplete={setIsCardEntryComplete}
                onComplete={request =>
                  onProviderPaymentReady('Stripe', request)
                }
                error={getError('stripecard')}
              />
            )}

          <BladeButton
            onClick={() => {
              clearErrors();
              if (isFatZebraComponentRequired) {
                checkoutNow();
              } else {
                adjustNow();
              }
            }}
            isWorking={isCheckingOut}
            isDisabled={
              !canMakePayment ||
              // Only disable if the stripe component is required
              (isStripeComponentRequired &&
                // If it is required, then if it's hidden or its complete then don't disable.
                !(
                  adjustmentType === 'SubscriptionPayment' ||
                  isCardEntryComplete
                ))
            }
          >
            {getAdjustCallToAction(
              adjustmentType,
              props.productConfiguration.checkout.callToActions
            ) || 'Amend'}
          </BladeButton>
        </div>

        <div className={checkoutCss.checkout_frame_receipt}>
          <ReceiptAdjust
            productLabel={props.productConfiguration.product.label}
            taxLabel={
              (isIndicative &&
                props.productConfiguration.pricing
                  .receiptTaxDescriptionWithEstimate) ||
              props.productConfiguration.pricing.receiptTaxDescription ||
              props.productConfiguration.pricing.taxDescription
            }
            quote={props.quote}
            adjustmentInformation={props.adjustmentInformation}
            risk={props.risk}
            receiptStyledFoot={props.applicationConfiguration.receiptStyledFoot}
            receiptPaymentPlanLabel={
              props.productConfiguration.pricing.paymentPlans.find(
                p =>
                  p.paymentPlanReferenceType ===
                  props.adjustmentInformation.paymentPlan.type
              )?.label
            }
          />
        </div>
      </>
    );
  }

  return null;
};

interface ICheckoutAdjust {
  quote: Quote;
  quotePricing: PricingSet;
  risk: Risk;
  adjustmentInformation: MtaInformation;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  paymentProvidersConfiguration: PaymentProvidersConfiguration;
  organisationConfiguration: OrganisationConfiguration;
  applicationConfiguration: ApplicationConfiguration;
  lifecycles: Lifecycles;
  callingApplication: CallingApplication;
  errors?: ICheckoutBoundaryError;
}
