import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function mustBeValidPhoneNumberAU(
  value = '',
  country = 'AU'
): string | undefined {
  const phoneRegex = RegExp(/^([\\+]{0,1})([0-9\\-\\ ]+)$/);

  const defaultValue = value === null ? '' : value.toString();
  const phoneNumber = parsePhoneNumberFromString(
    defaultValue,
    // @ts-ignore: next-line
    country.trim()
  );

  if (phoneNumber && phoneRegex.test(value)) {
    if (phoneNumber.isValid() && phoneNumber?.country?.trim() === country) {
      return undefined;
    } else {
      return `Please enter a valid Australian phone number`;
    }
  } else {
    return 'Please enter a valid phone number';
  }
}

export type TCountryCode = 'AU' | undefined;
