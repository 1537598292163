import * as React from 'react';

import { CardElement, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PaymentMethod } from '@aventus/platform';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import {
  BladeView,
  BladeSurface,
  BladeGuide,
  BladeButton,
  BladeIcon,
  BladeLoadOver,
  BladeMessage
} from '@aventus/blade';

import { StripeCreditCard } from '../stripe-credit-card';
import { useUpdateSavedCard, Lifecycles } from './use-update-saved-card';
import {
  BladeInlineErrorBoundary,
  useBladeInlineError
} from '@aventus/blade/inline-error';

import css from './index.css';

export const UpdateSavedCardInner: React.FunctionComponent<IUpdateSavedCard> = props => {
  const { getError, clearErrors } = useBladeInlineError();

  const [isUpdatingSavedCard, setIsUpdatingSavedCard] = React.useState<boolean>(
    false
  );

  const [isCardEntryComplete, setIsCardEntryComplete] = React.useState<boolean>(
    false
  );
  const elements = useElements();
  const element = elements?.getElement(CardElement);

  const {
    updateSavedCard,
    updatedPaymentMethod,
    isCardExpired
  } = useUpdateSavedCard(
    props.policyId,
    props.paymentMethod,
    props.lifecycles,
    setIsUpdatingSavedCard
  );

  const inlineError = getError('stripecard');

  return (
    <BladeView variant={'s'} className={css.view}>
      <BladeLoadOver
        isLoading={isUpdatingSavedCard}
        isSuccessful={updatedPaymentMethod ? true : false}
      />

      {props.error && (
        <BladeMessage variant={'error'} message={props.error?.message} />
      )}

      {isCardExpired && (
        <BladeMessage
          variant={'error'}
          message={
            props.productConfiguration.checkout.updateCardDetails?.expired ||
            'This card has expired, please update it with a newer card.'
          }
        />
      )}

      <BladeSurface
        title={
          props.productConfiguration.checkout.updateCardDetails?.title ||
          'Update your card details'
        }
        classNameContent={css.updateSavedCard}
      >
        <div className={css.updateSavedCard_details}>
          <BladeIcon
            name={
              props.productConfiguration.checkout.updateCardDetails?.icon ||
              'faCreditCard'
            }
          />

          <label className={css.updateSavedCard_details_label}>
            {`**** **** **** ${props.paymentMethod.last4Digits}, Expiry: ${props.paymentMethod.expiryMonth}/${props.paymentMethod.expiryYear}`}
          </label>
        </div>

        <BladeGuide
          markdown={
            props.productConfiguration.checkout.updateCardDetails
              ?.description ||
            "You can update your credit card information at any time. To delete your credit card information from our system, please give us a call. This is the card we'll use to take monthly payments and make one-off charges to your account. If you'd like to update it, enter your new card details below."
          }
        />

        {inlineError && (
          <BladeMessage variant={'error'} message={inlineError} isFlush />
        )}

        <div className={css.updateSavedCard_element}>
          <StripeCreditCard setIsComplete={setIsCardEntryComplete} />
        </div>

        <BladeGuide
          markdown={props.productConfiguration.checkout.payment.mandateForSCA}
        />

        <div className={css.updateSavedCard_controls}>
          <BladeButton
            onClick={() => {
              clearErrors();
              updateSavedCard();
            }}
            isDisabled={!element || !isCardEntryComplete || isUpdatingSavedCard}
          >
            {props.productConfiguration.checkout.updateCardDetails
              ?.updateCallToAction || 'Update card details'}
          </BladeButton>
        </div>
      </BladeSurface>
    </BladeView>
  );
};

export const UpdateSavedCard: React.FunctionComponent<IUpdateSavedCard> = props => {
  const { stripeKey } = props;

  const stripePromise = React.useMemo(() => loadStripe(stripeKey), [stripeKey]);

  return (
    <BladeInlineErrorBoundary>
      <Elements stripe={stripePromise}>
        <UpdateSavedCardInner {...props} />
      </Elements>
    </BladeInlineErrorBoundary>
  );
};
interface IUpdateSavedCard {
  stripeKey: string;
  policyId: string;
  paymentMethod: PaymentMethod;
  productConfiguration: InsuranceProductConfiguration;
  lifecycles: Lifecycles;
  error?: Nullable<Error>;
}
