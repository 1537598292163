import { BladeRunnerConfiguration } from './context';
import { useAreAtomsEqual } from '@aventus/honey-utility-react';
import { useMediaQuery } from '@aventus/pockethooks';
import { useRunner } from './use-runner';

export const useProvider = (
  props: BladeRunnerConfiguration
): BladeRunnerConfiguration => {

  // BladeRunner is designed to support many of these
  // providers nested together. This allows you to easily overwrite
  // an existing Runner prop. In order to do so, we need to check
  // for any parent BladeRunners and spread against those
  // properties.

  const wrappingRunner = useRunner();

  const { areAtomsEqual: areBackgroundAndSurfaceEqual } = useAreAtomsEqual(
    '--background',
    '--surface'
  );

  const { matchesQuery: isSpaceLimited } = useMediaQuery('(max-width: 769px)');

  return {
    ...wrappingRunner,
    ...props,
    layer: areBackgroundAndSurfaceEqual ? 'combine' : 'layer',
    space: isSpaceLimited ? 'limited' : 'comfortable'
  };

}
