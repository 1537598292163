import { childComponentUpdated, updateProductRef } from "@aventus/pocketknife/dispatch-event";

/**
 * The hook exposes simple methods which dispatch events. These events can be handled anywhere in the codebase by
 * registering a custom javascript event handler e.g. window.addEventListener(EventType.ProductReferenceUpdated)
 * */
const useDispatchEvent = () => {
  return {
    updateProductRef,
    childComponentUpdated
  };
};

export default useDispatchEvent;
