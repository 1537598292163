import React from 'react';
import { BladeToast } from '../toast';
import { BladeMessage, IBladeMessage } from '../message';
import css from './index.css';
import cx from 'classnames';

/**
 * TODO
 * - handle the `show` logic in here. it can infer itself if it needs to display just by checking alert length.
 * - see about using React context API for this instead of redux ? It could be a good trial.
 */

export const BladeNotification: React.FunctionComponent<IBladeNotification> = ({
  isDismissable = true,
  ...props
}) => (
  <BladeToast
    className={cx('blade-notification', css.notification, props.className)}
    show={props.show}
    zIndex={10}
    showOverflow={true}
  >
    {props.alerts.map((alert: IBladeMessage, i: number) => (
      <BladeMessage
        key={i}
        variant={alert.variant}
        isFlush={
          props.hasOwnProperty('isMessageFlush') ? props.isMessageFlush : true
        }
        isDismissable={isDismissable}
        isOverlayed={props.isMessageOverlayed}
        message={alert.message}
        callBack={alert.callBack}
      />
    ))}
  </BladeToast>
);

export interface IBladeNotification {
  /** When set to `true`, the notification will be slid into view. */
  show: boolean;

  alerts: Array<IBladeMessage>;

  isMessageFlush?: boolean;
  isMessageOverlayed?: boolean;
  isDismissable?: boolean;

  className?: string;
}

/*
onClick={ () => { props.deleteAlert(alert); } } />
*/
