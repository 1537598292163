import { PaymentPlanType } from '@aventus/platform';

export function paymentPlanToHumanReadable(
  paymentPlan: PaymentPlanType,
  paymentPlanLabels: { [key: string]: string } | undefined
): string {
  if (paymentPlanLabels && paymentPlanLabels[paymentPlan]) {
    return paymentPlanLabels[paymentPlan];
  } else {
    switch (paymentPlan) {
      case 'AnnualMonthlySubscription':
      case 'MonthlySubscription':
        return 'Monthly Subscription';

      case 'AnnualMonthlyCredit':
        return 'Monthly direct debit';

      case 'FixedTermSinglePayment':
      case 'SinglePayment':
        return 'Single card payment';

      default:
        return paymentPlan;
    }
  }
}
