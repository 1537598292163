import update from 'immutability-helper';

export default function updateCredentialsReducer(state, action) {
  const { updateCommand } = action;

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        credentials: {
          $set: {
            username: updateCommand.username
          }
        }
      })
    }
  };
}
