import { IListItem } from '@aventus/blade/list';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { PolicyBundle } from '@aventus/platform';
import { findAddonConfiguration } from '@aventus/mvmt-quote/services/find-addon-configuration';
import {
  AddonConfiguration,
  PricingPaymentPlanConfiguration
} from '@aventus/configuration/product';
import { findPaymentPlanConfiguration } from '@aventus/mvmt-pricing';
import { STELLA_ROADSIDE_IDS } from '@aventus/client-helpers/stella';

export function getPolicyPremiumBreakdown(
  policyBundle: PolicyBundle,
  addonConfigurations: AddonConfiguration[],
  plansConfiguration: PricingPaymentPlanConfiguration[],
  basePremiumLabel?: string
): IListItem[] {
  var premiumBreakdownList: IListItem[] = [];

  const {
    policy,
    totalPaid: policyTotalPaid,
    discountInfo,
    creditAgreement
  } = policyBundle;

  premiumBreakdownList.push({
    label: 'Payment frequency',
    value: findPaymentPlanConfiguration(
      policyTotalPaid.paymentPlanType,
      plansConfiguration
    )?.label,
    isBold: true
  });

  const voucherNetPremium =
    discountInfo && discountInfo.type === 'VoucherNetPremium'
      ? `(incl. ${currencyToHumanReadable(
          policy.customerDiscount
        )} discount)`
      : '';


  policyTotalPaid.premiumBreakdown[0].rateableComponentBreakdown.forEach(re => {
    if (re.referenceID === '_primary') {
      const voucherBasePremium =
        discountInfo && discountInfo.type === 'VoucherBasePremium'
          ? `(incl. ${currencyToHumanReadable(
              policy.customerDiscount
            )} discount)`
          : '';
      
      const basePremium = basePremiumLabel ? basePremiumLabel : `Base premium`; 

      premiumBreakdownList.push({
        label: `${basePremium} ${voucherBasePremium}`,
        value: currencyToHumanReadable(re.value.net, { forceMinor: true }),
        hasBorder: true
      });
    }
  });

  policyTotalPaid.premiumBreakdown[0].rateableComponentBreakdown.forEach(re => {
    if (re.referenceID !== '_primary') {
      const addonConfiguration = findAddonConfiguration(
        re.referenceID,
        addonConfigurations
      );
      const addonLabel = addonConfiguration?.receiptLabel
        ? addonConfiguration?.receiptLabel
        : addonConfiguration?.label;
      premiumBreakdownList.push({
        label: addonLabel,
        value: currencyToHumanReadable(re.value.net, { forceMinor: true })
      });
    }
  });

  if (policyTotalPaid.paymentPlanType === 'SinglePayment'){
    premiumBreakdownList.push({
      label: `Premium ${voucherNetPremium}`,
      value: currencyToHumanReadable(
        policyTotalPaid.premiumBreakdown[0].value.net,
        { forceMinor: true }
      ),
      hasBorder: true,
      isBold: true
    });
  }

  policyTotalPaid.premiumBreakdown[0].value.tax.taxes.forEach(tax => {
    premiumBreakdownList.push({
      label: tax.name,
      value: currencyToHumanReadable(tax.amount, { forceMinor: true })
    });
  });

  if (policyTotalPaid.paymentPlanType !== 'SinglePayment'){
    premiumBreakdownList.push({
      label: 'Annual Premium',
      value: currencyToHumanReadable(
        policyTotalPaid.premiumBreakdown[0].value.gross,
        { forceMinor: true }
      ),
      hasBorder: true,
      isBold: true
    });
  }

  policyTotalPaid.additionalProducts?.forEach(additionalProduct => {
    const addonConfiguration = findAddonConfiguration(
      additionalProduct.referenceID,
      addonConfigurations
    );
    const addonLabel = addonConfiguration?.receiptLabel
      ? addonConfiguration?.receiptLabel
      : addonConfiguration?.label;

    // This could be moved into config as an optional
    // display toggle later on when price is 0.
    const addonValueDisplayFree =
      STELLA_ROADSIDE_IDS.includes(additionalProduct.referenceID) &&
      additionalProduct.priceGross.value === 0;

    premiumBreakdownList.push({
      label: addonLabel,
      value: addonValueDisplayFree
        ? 'FREE'
        : currencyToHumanReadable(additionalProduct.priceGross, {
            forceMinor: true
          })
    });
  });

  if (policyTotalPaid.fees) {
    policyTotalPaid.fees.fees.forEach(fee => {
      premiumBreakdownList.push({
        label: fee.name,
        value: currencyToHumanReadable(fee.amount, { forceMinor: true })
      });
    });
  }

  if (policyTotalPaid.total) {

    const totalCostLabel = policyTotalPaid.paymentPlanType === 'SinglePayment' ? 'Total Cost' : 'Total annual cost'

    premiumBreakdownList.push({
      label: totalCostLabel,
      value: currencyToHumanReadable(policyTotalPaid.total.gross),
      isBold: true,
      hasBorder: true
    });
  }

  if (policyTotalPaid.paymentPlanType !== 'FixedTermSinglePayment' && policyTotalPaid.paymentPlanType !== 'SinglePayment') {
    premiumBreakdownList.push({
      label: `First payment *`,
      value: policyTotalPaid.recurringMonthlyCharge
        ? currencyToHumanReadable(creditAgreement.depositAmount, {
            forceMinor: true
          })
        : '',
      isBold: true,
      hasBorder: true
    });

    premiumBreakdownList.push({
      label: `Monthly premium instalments`,
      value: policyTotalPaid.recurringMonthlyCharge
        ? currencyToHumanReadable(policyTotalPaid.recurringMonthlyCharge, {
            forceMinor: true
          })
        : '',
      isBold: true
    });
  }
  return premiumBreakdownList;
}
