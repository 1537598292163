import update from 'immutability-helper';

export function socialAuthRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isSyncingSocialAuth: { $set: true }
        }
      })
    }
  };
}

export function socialAuthSuccessReducer(state, action) {

  const { email, token, accountType } = action;

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        user: {
          $set: {
            email,
            token,
            socialAccount: true,
            accountType
          }
        },
        _flags: {
          $set: {
            isSyncingSocialAuth: false,
            successfullySyncedSocialAuth: true
          }
        }
      })
    }
  };

}

export function socialAuthFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isSyncingSocialAuth: false,
            successfullySyncedSocialAuth: false
          }
        }
      })
    }
  }
}
