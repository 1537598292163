export function matchesPassword (value: string, allValues: PasswordPartial): string | undefined {
  return (
    value === allValues.password
      ? undefined
      : 'Your passwords must match'
  )
}

interface PasswordPartial {
  password?: string,
  [propName: string]: any
}
