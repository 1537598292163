import * as React from 'react';
import { BladeRunnerContext, BladeRunnerConfiguration } from './context';
import { useProvider } from './use-provider';

export const BladeRunnerProvider: React.FunctionComponent<IBladeRunnerProvider> = props => {

  const runnerConfig = useProvider(props);

  return (
    <BladeRunnerContext.Provider
      value={ runnerConfig }>
      { props.children }
    </BladeRunnerContext.Provider>
  );

};

export type IBladeRunnerProvider = BladeRunnerConfiguration;
