import { CreateMotorWebClient, Search, MotorWebResponse, MotorDetails } from './types';
import { AxiosPromise } from 'axios';
import { createClient } from '@aventus/client';
import { interceptResponse } from './intercept-response';

export const createMotorWebClient: CreateMotorWebClient = function (options) {

  const motorweb = createClient(options);

  motorweb.interceptors.response.use(
    interceptResponse.onFulfilled,
    interceptResponse.onRejected
  );

  // Get an organisation's global configuration settings.
  // These include global definitions for a given org like what
  // timezone they are based in along with what currency.

  const search: Search = async (regOrVin, registeredState, token) => {

    const response = (
      await <AxiosPromise<MotorWebResponse<MotorDetails>>>
        motorweb.get(`/motorWeb?identifier=${regOrVin}&state=${registeredState}&token=${token}`)
    );

    return response.data.data;
  }

  return {
    search
  };

}
