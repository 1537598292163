import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';

export const BladeInputNumber: React.FunctionComponent<
  IBladeInputNumber
> = props => {
  const inputProps = useInput(props);

  const _value =
    inputProps.value != null
      ? inputProps.value.toString().replace(/\s/g, '')
      : '';

  const [showValidation, setShowValidation] = React.useState(false);

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    inputProps.onBlur && inputProps.onBlur(event);
    setShowValidation(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange && inputProps.onChange(event);
    setShowValidation(false);
  };

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={showValidation && inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <input
        ref={null}
        type={'text'}
        className={inputProps.className || ''}
        value={_value}
        onChange={onChange}
        onFocus={inputProps.onFocus}
        onBlur={onBlur}
        inputMode="numeric"
        pattern="[0-9]*"
      />
    </BladeInput_Frame>
  );
};

export interface IBladeInputNumber extends IBladeInput {}
