import { AxiosResponse, AxiosError } from 'axios';
import { SymphonyResponse } from '@aventus/symphony-client';

import {
  ServerError,
  PclRecoverableError,
  PclUnrecoverableError,
  VoucherCodeError,
  PlatformUnauthorisedError,
  InvalidProductError,
  MTAError
} from '@aventus/errors';

export const interceptResponse = {
  onFulfilled,
  onRejected
};

export function onFulfilled(response: AxiosResponse<SymphonyResponse<any>>) {
  return response;
}

export function onRejected(error: AxiosError<SymphonyResponse<any>>) {
  if (error.response?.status === 401 || error.response?.data.error === 401) {
    throw new PlatformUnauthorisedError(
      "For security reasons we've signed you out, please sign in and try again."
    );
  }

  if (error.response?.data.error === 1014) {
    if (error.response.data.validationErrors.length > 0) {
      const [voucherError] = error.response.data.validationErrors;
      if (voucherError && voucherError.message) {
        throw new VoucherCodeError(voucherError.message);
      }
    }

    throw new VoucherCodeError(
      'The voucher code is invalid. Please try again.'
    );
  }

  if (error.response?.data.error === 1017) {
    throw new PclUnrecoverableError(
      "We weren't able to set up your Direct Debit and the deposit payment has been cancelled. Please try again or contact support."
    );
  }

  if (error.response?.data.error === 1018) {
    throw new PclRecoverableError(
      "We couldn't set up your direct debit. Please check your account details and try again."
    );
  }

  if (error.response?.data.error === 2020) {
    throw new PclUnrecoverableError(
      'We can’t process your adjustment right now, please try again later or contact support.'
    );
  }

  if (error.response?.data.error === 2021) {
    throw new PclUnrecoverableError(
      "We've taken you back to the start due to an error. Please try again."
    );
  }

  if (error.response?.data.error === 1144) {
    throw new MTAError(
      "We can’t process your adjustment right now as it's the billing day for this policy, please try again later or contact support."
    );
  }

  if (
    error.response?.data.error === 1145
  ) {
    throw new ServerError(
      'Unexpected error occured. Please try again or contact support'
    );
  }

  if (error.response?.data.error === 1143){
    throw new InvalidProductError(
      'This product is invalid'
    );
  }

  if (
    error.response?.data.error === 0 ||
    error.response?.data.error === 400 ||
    error.response?.data.error === 404
  ) {
    throw new ServerError(
      'Unexpected error occured. Please try again or contact support'
    );
  }

  return Promise.reject(error);
}
