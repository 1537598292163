export function handleDeprProductReference (productReference: string): string {

  switch (productReference) {

    case 'hl_pr_standard_contents':
    case 'hl_pr_standard_buildings':
    case 'hl_pr_standard_buildings_and_contents':
      return 'hl_pr_home';

    default:
      return productReference;

  }

}
