import { AxiosResponse, AxiosError } from 'axios';
import { ExperianRecoverableError } from '../errors/experian-recoverable-error';
import { ExperianUnrecoverableError } from '../errors/experian-unrecoverable-error';

export const interceptResponse = {
  onFulfilled,
  onRejected
}

export function onFulfilled (response: AxiosResponse<any>) {
  return response;
}

export function onRejected (error: AxiosError<any>) {

  if (
    error.response?.data.error === 404 ||
    error.response?.data.error === 500 ||
    error.response?.data.error === 403
  ) {

    throw new ExperianRecoverableError(
      "An unexpected error occured. Please try again, enter your address manually or contact support."
    );

  } else {

    throw new ExperianUnrecoverableError(
      "An unexpected error occured. Please enter your address manually."
    );

  }

}
