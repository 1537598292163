import React, { useEffect, useState } from 'react';
import { BladeLink, BladePage, BladeSurface } from '@aventus/blade';
import { Scaffold, Typo } from '@honey/consume-react-jss';
import appApi from '../../../api';
import routes from '../../../routes';
import ResetPasswordComponent from './resetPasswordComponent';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

const ResetPassword: React.FC<any> = ({
  app,
  handleTracking,
  handleAuthClearAllFlags,
  handleAuthResetPassword
}): JSX.Element => {
  const { credentials, _flags } = app.auth;
  const [email, setEmail] = useState<string>('');
  const { updateProductRef } = useDispatchEvent();

  useEffect(() => {
    if (!email && credentials && credentials.username) {
      setEmail(credentials.username);
    }
  }, [credentials, email]);

  useEffect(() => {
    handleAuthClearAllFlags();
    handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.resetPassword.trackingName
    });
    updateProductRef(null);
  }, []);

  return (
    <BladePage title={routes.resetPassword.documentTitle}>
      <Scaffold orient={'col'} size={'large'}>
        <BladeSurface>
          <Scaffold grow={true} orient={'col'} size={'large'}>
            {_flags.successfullyResetPassword ? (
              <React.Fragment>
                <Typo variant={'body'}>
                  {`We've sent you an email. Click the link in the email to reset your password.`}
                </Typo>
                <BladeLink size={'small'} url={routes.signIn.path}>
                  {'Back to sign in'}
                </BladeLink>
              </React.Fragment>
            ) : (
              <ResetPasswordComponent
                flags={_flags}
                email={email}
                onSubmit={({ email }) => {
                  setEmail(email);
                  handleAuthResetPassword(email);
                }}
              />
            )}
          </Scaffold>
        </BladeSurface>
      </Scaffold>
    </BladePage>
  );
};

export default appApi(ResetPassword);
