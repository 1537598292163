import * as React from 'react';
import { BladeView } from '../../view';
import { BladeMarkdown } from '../../markdown';
import { BladeLinkImageIconOrLabel } from '../../link-image-icon-or-label';
import { IBladeAppFooter } from '../';
import css from './index.css';
import cx from 'classnames';

export const BladeAppFooterCompressed: React.FunctionComponent<IBladeAppFooterCompressed> = props => {
  return (
    <div className={css.footer}>
      <BladeView
        variant={props.viewVariant || 's'}
        className={cx(
          { [css.footer_view_isSpacious]: !props.isSpaceLimited },
          { [css.footer_view_isSpaceLimited]: props.isSpaceLimited }
        )}
      >
        <div className={css.footer_view_statement}>
          {props.regulatoryStatement && (
            <BladeMarkdown markdown={props.regulatoryStatement} />
          )}
        </div>

        <div className={css.footer_view_links}>
          {props.links?.map((link, i) => (
            <BladeLinkImageIconOrLabel
              key={i}
              image={link.image}
              icon={link.icon}
              label={link.label}
              href={link.href}
              classNameLabel={css.footer_view_links_label}
              classNameIcon={css.footer_view_links_icon}
              classNameImage={css.footer_view_links_image}
            />
          ))}
        </div>
      </BladeView>
    </div>
  );
};

export interface IBladeAppFooterCompressed extends IBladeAppFooter {
  isSpaceLimited: boolean;
}
