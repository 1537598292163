import * as React from 'react';
import { BladeSurface } from '@aventus/blade';
import { BladeList } from '@aventus/blade';
import { getDriverDetails } from '../../services/get-driver-details';

export const DetailsOfDrivers: React.FunctionComponent<IDriversDetails> = props => {
    return (
        <BladeSurface
            title={'Driver details'}
            shouldRespectViewWidth={props.shouldRespectViewWidth}>
            <BladeList
            isFlush={true}
            shouldRespectViewWidth={props.shouldRespectViewWidth}
            list={
                getDriverDetails(props.proposer, props.driverDetails)
            }/>
        </BladeSurface>
    )
}

export interface IDriversDetails{
    proposer: any;
    driverDetails: any;
    shouldRespectViewWidth?: boolean;
}