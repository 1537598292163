import * as React from 'react';
import { BladeLink } from '@aventus/blade';
import { BladeFormFieldset, IBladeFormFieldset } from '../../index';
import configuration from './configuration.json';

export const AddressInternationalManual: React.FunctionComponent<IAddressInternationalManual> = props => {
  // We only support specific configuration for a handful of the major countries,
  // treating all the others with an umbrella 'rest of the world' configuration,
  // denoted by '*',
  // See https://www.edq.com/documentation/apis/address-validate/global-intuitive/
  // for more details.

  let selectedCountry = configuration.supportedCountries.includes(
    props.countryCode
  )
    ? props.countryCode
    : '*';

  // This is not the most reliable approach, because it assumes
  // that the fieldset's inputs are the immediate children of this component.
  // It's safe enough because that's the question mapping design, but this
  // needs to be kept in mind for future changes.

  const fieldsetInputs = React.Children.map(
    props.children,
    (input: any, i: number) =>
      React.cloneElement(input, {
        ...configuration[selectedCountry][i]
      })
  );

  return (
    <BladeFormFieldset {...props}>
      {fieldsetInputs}
      <BladeLink isSmall onClick={props.enterWithLookup}>Back to address lookup</BladeLink>
    </BladeFormFieldset>
  );
};

interface IAddressInternationalManual extends IBladeFormFieldset {
  isGroup?: boolean;
  countryCode: string;
  enterWithLookup: any;
  renderOracle: any;
}
