import * as React from 'react';
import css from './index.css';
import { TobesError } from '../errors/tobes-error';
import { BladeButton, BladeIcon } from '@aventus/blade';
import { AxiosError } from 'axios';

export class SimpleQuoteBoundary extends React.Component<
  ISimpleQuoteBoundary,
  ISimpleQuoteBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      message: null,
      error: null,
      recoverable: true,
      redirect: false
    };
  }

  componentDidCatch(error: any) {
    if (error instanceof TobesError) {
      // @ts-ignore
      window.Rollbar?.error(
        new Error(`TOBES: ${error.error.message}`),
        error.error
      );
      this.setState({
        message: error.message,
        error: error.error,
        recoverable: error.recoverable
      });
    } else {
      throw error;
    }
  }

  render() {
    if (this.state.error && !this.state.recoverable) {
      return (
        <div className={css.boundary}>
          <BladeIcon
            className={css.boundary_icon}
            name={'faExclamationCircle'}
          />
          <h1 className={css.boundary_title}>Something went wrong</h1>
          <p className={css.boundary_message}>{this.state.message}</p>
          {'response' in this.state.error &&
            this.state.error.response?.status !== 401 && (
              <BladeButton
                className={css.boundary_action}
                onClick={() => window.location.reload()}
                isInline={true}
              >
                Try again
              </BladeButton>
            )}
        </div>
      );
    }
    return this.props.children;
  }
}

export interface ISimpleQuoteBoundary {}

export interface ISimpleQuoteBoundaryState {
  message: string | null;
  error: Error | AxiosError | null;
  recoverable: boolean;
  redirect: boolean;
}
