import * as React from 'react';
import {
  OptionFormatted,
  SliderOptions,
  SliderOptionsReference,
  UseSlider
} from './types';

export function useSlider(
  optionsFormatted: OptionFormatted[],
  options: any[],
  value: any,
  formValue: any,
  showMiddleValue: boolean,
  defaultIndex:number,
  onChange: (value: any) => void
): UseSlider {
  const [sliderOptionsCollection, setSliderOptionsCollection] = React.useState<
    SliderOptions[]
  >([]);
  const [sliderOptions, setSliderOptions] = React.useState<SliderOptions>({});
  const [sliderValue, setSliderValue] = React.useState<number>(0);
  React.useEffect(() => {
    // Given the number of options, we can
    // figure out how to divide the slider.

    const _dividedInto = 100 / (optionsFormatted.length - 1);

    let _reference: SliderOptionsReference = {};

    // Now we've got everything to build up
    // the slider options object.

    // We use the divisor here to map each option
    // into the structure understood by the slider:
    // { [position on slider]: label or value }

    const _sliderOptionsCollection = optionsFormatted.map(
      (option: OptionFormatted, i: number): SliderOptions => {
        const position: number = Math.round(_dividedInto * i);
        const middleValue: number = showMiddleValue ? Math.ceil((optionsFormatted.length - 1) /2):0;

        // We want to create this reference because
        // the key (position) is computed, and so we can't
        // use any external keys to check against the collection
        // we've got. This is due to the poor design choice
        // of the slider component.

        _reference = {
          ..._reference,
          ...{ [option.value]: position }
        };

        return {
          [position]:
            i === 0 || i === middleValue || i === optionsFormatted.length - 1 ? option.value : ''
        };
      }
    );

    setSliderOptionsCollection(_sliderOptionsCollection);

    // Using the reference we created above,
    // we can use this to idenitfy the position
    // of the default or already defined value.

    setSliderValue(_reference[value]);

    // And lastly reduce the array into
    // an object of slider-understood options.

    const _sliderOptions = _sliderOptionsCollection.reduce(
      (acc: SliderOptions, curr: SliderOptions) => ({
        ...acc,
        ...curr
      }),
      {}
    );

    setSliderOptions(_sliderOptions);
  }, [optionsFormatted.length]);

  // If no pre-defined value is found on the form,
  // we need to fire off the first onChange with the default
  // value selected.

  React.useEffect(() => {
    if (sliderValue && !formValue) {
      _onChange(sliderValue);
    }
  }, [formValue, sliderValue]);

  // We need to wrap around the IQ defined on change
  // since we need to use the returned value to be able
  // to map back to the original option, and use that to
  // call IQ onChange

  function _onChange(index: number) {
    const i = sliderOptionsCollection.findIndex(
      (option: any) => option[index] !== undefined
    );
    onChange(options[i]);
  }

  function reset(defaultPercentage:number) {
    setSliderValue(defaultPercentage);
    onChange(options[defaultIndex]);
  }

  function getLabel(index: number) {
    const i = sliderOptionsCollection.findIndex(
      (option: any) => option[index] !== undefined
    );
    return optionsFormatted[i]?.value || '';
  }

  return {
    sliderOptions,
    sliderValue,
    setSliderValue,
    onChange: _onChange,
    getLabel,
    reset
  };
}
