import * as React from 'react';
import inject from 'react-jss';
import { describer } from '@honey/core';

/**
  This context will be the home of all the user defined raw descriptors
  and their mapped guides.

  The descriptors will be deserialized at runtime, so we can simply
  initialise with an empty object.
 */

export const HoneyContext = React.createContext({});

/**
  Provide's HoneyContext's state. On-top of providing the descriptors
  and their respective mapped guides, it exposes relevant functions
  to edit them.
 */
export class GuideProvider extends React.Component<
  IProviderProps,
  IProviderState
> {
  state = {
    // Raw state of the style description. Initially mirrors
    // what's found in the saved jsons. Any updates to the description
    // are focused here, and subsequent serialisations happen
    // off of this. This is the single source of truth for the
    // style description.

    descriptors: this.props.descriptors,

    // This is the serialised, component-friendly version of the style
    // description. Only cares about the actual values, in an easy to access
    // format for the UI components to bind to. This is re-generated
    // everytime there is an update to any of the descriptors.

    guide: describer(this.props.descriptors || {}),

    // The function used to edit any of the descriptors. Also takes care of
    // triggering off a guide serialisation.

    edit: () => {},

    meta: this.props.meta
  };

  static getDerivedStateFromProps(
    props: IProviderProps,
    state: IProviderState
  ) {
    if (state.descriptors !== props.descriptors) {
      return {
        descriptors: props.descriptors,
        guide: describer(props.descriptors),
        meta: props.meta
      };
    }
    return null;
  }

  render() {
    if (!this.state.guide) {
      return null;
    }
    return [
      <HoneyContext.Provider key={2} value={this.state}>
        {this.props.children}
      </HoneyContext.Provider>
    ];
  }
}

/**
  HOC to wrap around your Component and pass the HoneyContext guide
  to it.
 */
export function guideConsumer(Component: any) {
  return function(props: any) {
    return (
      <HoneyContext.Consumer>
        {({ guide, meta }: any) => (
          <Component guide={guide} meta={meta} {...props} />
        )}
      </HoneyContext.Consumer>
    );
  };
}

/**
  A wrapper around the wrapper, this adds JSS support
  along with the Context wrapping of `guideConsumer`.
 */
export const guideConsumerJSS = (Component: any) => (styles: any) =>
  guideConsumer(inject(styles)(Component));

interface IProviderProps {
  descriptors: any;
  meta?: any;
}

interface IProviderState {
  descriptors: any;
  guide: any;
  edit: any;
  meta: any;
}
