import * as React from 'react';

export function useOneByOne (questions: any[]) {

  const [ pointer, setPointer ] = React.useState<number>(0);
  const [ isForward, setIsForward ] = React.useState<boolean>(true);
  const [ frameHeight, setFrameHeight ] = React.useState<number>(300);

  const animatedQuestionRef = React.useRef<any>();
  const frameRef = React.useRef<any>();

  React.useEffect(() => {
    calculateHeightWithDelay();
  });

  function nextQuestion () {
    if (pointer !== questions.length - 1) {
      setPointer(pointer + 1);
      setIsForward(true);
    }
  }

  function previousQuestion () {
    if (pointer !== 0) {

      // Cycle through the skipped question pointers
      // to make sure we also skip over those when
      // going backwards.

      let skipped: Array<number> = [];
      let skipping: Array<string> = [];

      const prevPointer: number = skipped.reverse().reduce(
        (acc: number, curr: number) => {
          if (acc === curr) {
            return acc - 1;
          } else {
            skipped.push(curr);
            const skippedQuestionId: string = questions[curr];
            skipping.push(skippedQuestionId);
            return acc;
          }
        },
        pointer - 1
      );

      setPointer(prevPointer);
      setIsForward(false);

    }
  }

  function calculateHeight () {

    // Since we have two different widths handled here, and dynamic content
    // wrapped in an absolute element, we can't rely on CSS to calculate height.
    // This calculation tries to figure out the best height to give to the
    // frame element, keeping both widths happy.

    if (animatedQuestionRef && animatedQuestionRef.current && frameRef.current) {
      const animatedQuestionHeight = animatedQuestionRef.current.offsetHeight;
      const differenceOfWidths = Math.abs(animatedQuestionRef.current.offsetWidth - frameRef.current.offsetWidth);
      const calculatedPadding = differenceOfWidths / 2;
      const newHeight = animatedQuestionHeight + calculatedPadding + 20;

      if (newHeight !== frameHeight) {
        setFrameHeight(newHeight);
      }
    }
  }

  function calculateHeightWithDelay () {
    var calcHeightFunc = calculateHeight;
    setTimeout(() => { calcHeightFunc() }, 0);
    setTimeout(() => { calcHeightFunc() }, 50);
  }

  return {

    animatedQuestionRef,
    frameRef,

    pointer,
    isForward,
    frameHeight,

    nextQuestion,
    previousQuestion,
    calculateHeight,
    calculateHeightWithDelay
  };

}

// Technically this should be used to type the
// useRefs above, but getting a weird type mismatch
// with the animated.div element in the component.
// Refactoring the renderprop springs to the hooks
// should fix this issue. 

export interface ElementReference {
  [x: string]: any;
  current: null | HTMLDivElement
}
