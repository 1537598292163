import * as React from 'react';
import { animated } from 'react-spring/renderprops';
import { BladeIcon } from '../../icon';
import { BladeMarkdown } from '../../markdown';
import { IBladeCallToAction } from '../index';
import css from './index.css';
import cx from 'classnames';

export const BladeCallToActionHorizontal: React.FunctionComponent<
  IBladeCallToAction
> = props => {
  return (
    <animated.div
      className={cx('blade-call-to-action-horizontal', css.wrap_callToAction)}
      style={props.animation}
    >
      <div
        className={css.callToAction}
        onClick={() => {
          if (!props.disabled) {
            props.action();
          }
        }}
      >
        <div className={css.callToAction_image}>
          {props.labelImage && <img src={props.labelImage} />}

          {!props.labelImage && props.labelIcon && (
            <BladeIcon
              className={css.callToAction_image_icon}
              name={props.labelIcon}
            />
          )}
        </div>

        <div className={css.callToAction_content}>
          <h2>{props.title}</h2>

          {props.subTitle && (
            <BladeMarkdown
              size={'small'}
              className={css.callToAction_content_subtitle}
              markdown={props.subTitle}
            />
          )}
        </div>

        <div className={css.callToAction_button}>
          {props.actionLabel && <span>{props.actionLabel}</span>}

          {!props.actionLabel && props.actionIcon && (
            <BladeIcon
              className={css.callToAction_button_icon}
              name={props.actionIcon}
            />
          )}
        </div>
      </div>
    </animated.div>
  );
};
