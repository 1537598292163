import { TobesQuestionState } from '@aventus/mvmt-simplequote/store/form-state';

export const clearValidation = (questions: TobesQuestionState[]) => {
  const clearFn = (questions: TobesQuestionState[]): TobesQuestionState[] =>
    questions.map(item => ({
      ...item,
      ...{
        validationErrors: [],
        children: clearFn(item.children)
      }
    }));

  return clearFn(questions);
};
