import * as React from 'react';
import { useStickyMode } from './use-sticky-mode';
import css from './index.css';
import cx from 'classnames';

export const BladeAppDisplay: React.FunctionComponent<IBladeAppDisplay> = props => {
  const { isSticky, appDisplayRef } = useStickyMode(
    props.useStickyMode,
    props.onStickyModeChange
  );

  return (
    <div className={props.classNameContainer || ''}>
      <div
        ref={appDisplayRef}
        className={cx('blade-app-display', css.appDisplay, props.className, {
          [css.isStickyMode]: isSticky
        })}
      >
        {props.children}
      </div>

      {isSticky && (
        <div className={cx(css.appDisplay_dummy, props.className)}>
          {props.children}
        </div>
      )}
    </div>
  );
};

interface IBladeAppDisplay {
  /** If set to `true`, then the height
      will default to the `headerHeight` defined in `guide`. */
  useStickyMode?: boolean;

  /**
   * Callback trigged when the value of the
   * sticky mode changes.
   */
  onStickyModeChange?: (stickyMode: boolean) => void;

  /** CSS */
  className?: string;

  /** CSS for the wrapping container */
  classNameContainer?: string;
}
