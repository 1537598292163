import * as React from 'react';
import { BladeCallToActionVertical } from './vertical';
import { BladeCallToActionHorizontal } from './horizontal';
import { BladeCallToActionCustom } from './custom';

export const BladeCallToAction: React.FunctionComponent<
  IBladeCallToAction
> = props => {
  switch (props.ui) {
    case 'vertical':
      return <BladeCallToActionVertical {...props} />;

    case 'horizontal':
      return <BladeCallToActionHorizontal {...props} />;

    case 'custom':
      return <BladeCallToActionCustom {...props} />;

    default:
      return null;
  }
};
export interface IBladeCallToAction {
  ui?: BladeCallToActionVariant;
  /** Space the elements from each other and within the frame. */
  size?: string;
  /** Custom image rendered as the CTA icon. */
  labelImage?: string;
  /** Alternatively, you can define a Font Awesome icon instead of the image. */
  labelIcon?: string;
  /** The call to action title. */
  title: string;
  /** Any extra information you want to expose. */
  subTitle?: string;
  /** A label for the action, which will be used over the icon */
  actionLabel?: string;
  /** A Font Awesome icon used to represent the kind of action behind the call. */
  actionIcon?: string;
  /** Triggered when the CTA frame is clicked. */
  action: Function;
  /** Disable the CTA from being clicked. */
  disabled?: boolean;
  /** Animation object */
  animation?: object;
  /** If true, any siblings found together will alternate their background colour using the brand palette. */
  alternateBackground?: boolean;
  /** Default key & policy id */
  policyID: string;
  productReferenceID: string;
  coverType: string;
  /** Optional status value (JSX / string) */
  status?: CtaStatus;
}

type CtaStatus = {
  value: string;
  display: React.ReactNode;
};

export type BladeCallToActionVariant = 'vertical' | 'horizontal' | 'custom';
