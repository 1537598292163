import * as React from 'react';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { RouteComponentProps, Redirect } from 'react-router';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const ProductOverviewRedirect: React.FunctionComponent<IProductOverviewRedirect> = (props) => {

  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const productReference: ProductReference = `${ organisationConfiguration?.reference }_pr_${ props.match.params.lineOfBusiness }`;
  const productCoverReference: ProductCoverReference = props.match.params.coverType;

  if (organisationConfiguration){
    return (
      <Redirect
        to={ props.goToProductOverview(productReference, productCoverReference) } />
    );
  }

  return null;

};

interface IPathParams {
  productId: string,
  lineOfBusiness: string,
  coverType: string
};

interface IProductOverviewRedirect extends RouteComponentProps<IPathParams> {
  goToProductOverview: (productReference: ProductReference, productCoverReference: ProductCoverReference) => string
}
