import React, { useState } from 'react';
import { useSimpleQuote } from '../../../../../hooks/use-simple-quote';
import { History } from 'history';
import { SimpleQuoteAsk } from '../simple-quote-ask';
import { QuoteFooter } from '@aventus/mvmt-simplequote/component/quote-footer';
import { CustomConfig, QuoteType } from '@aventus/platform';
import { IProduct } from '@aventus/platform-client-context/models/product';
import { OraclesConfiguration } from '@aventus/configuration/oracles';
import { InsuranceProductConfiguration } from '@aventus/configuration/product';
import { ProductInterfaceConfiguration } from '@aventus/configuration/product-interface';
import { IOrganisationSettings } from '@aventus/platform-client';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useError } from '@aventus/pockethooks/use-error';
import { useSymphonyClient } from '@aventus/symphony-client/hooks';
import usePlatform from '@aventus/platform-client-context/use-platform';

export const SimpleQuoteHybrid: React.FunctionComponent<ISimpleQuoteHybrid> = ({
  product,
  type,
  questionSetReferenceID,
  pageIndex,
  organisationSettings,
  oraclesConfiguration,
  productInterfaceConfiguration,
  productConfiguration,
  customConfiguration,
  ...props
}) => {
  const location = useLocation();
  const platform = usePlatform();
  const symphony = useSymphonyClient();

  const { throwError } = useError();

  const quoteType = type;

  const _editSessionID =
    new URLSearchParams(location?.search).get('editSessionId') || undefined;

  const policyId =
    new URLSearchParams(location?.search).get('policyId') || undefined;

  const [quoteID, setQuoteID] = useState<string | undefined>(
    new URLSearchParams(location?.search).get('quoteId') || undefined
  );

  const exposureID =
    new URLSearchParams(location?.search).get('exposureId') || undefined;

  // const [tobesStarted, setTobesStarted] = useState<
  //   ITobeStartSuccess | undefined
  // >(undefined);

  const [editSessionID, setEditSessionID] = useState<string | undefined>(
    _editSessionID
  );

  const [changeMade, setChangesMade] = useState<boolean>(false);
  const [disableWarning, setDisableWarning] = useState<boolean>(false);

  // CreateMTAHybridQuote
  useQuery(
    ['hybrid', 'quote', 'MTA', policyId],
    () => {
      if (!policyId) {
        throwError(new Error('Unable to create MTA. Policy ID missing.'));
        return;
      }

      return platform.quote.createMTAQuote(policyId);
    },
    {
      enabled: !!policyId && quoteType === 'MTA',
      onSuccess: response => {
        if (response) {
          setQuoteID(response.quoteDetails.quote.id);
        }
      }
    }
  );

  // AdjustHybridQuote
  useQuery(
    ['hybrid', 'quote', 'adjust', quoteID],
    () => {
      if (!quoteID) {
        throwError(new Error('Unable to adjust quote. Quote ID missing.'));
        return;
      }

      return platform.quote.editHybridQuote(quoteID);
    },
    {
      enabled: !!quoteID && quoteType === 'Adjust',
      onSuccess: adjustQuote => {
        if (adjustQuote) {
          setEditSessionID(adjustQuote?.id);
        }
      }
    }
  );

  // CreateHybridQuote
  useQuery(
    [
      'hybrid',
      'quote',
      'create',
      {
        productReferenceID: product.referenceID,
        externalID: symphony.getExternalId()
      }
    ],
    () =>
      platform.hybrid.hybridCreate(
        product.referenceID,
        symphony.getExternalId()
      ),
    {
      enabled: !quoteID && quoteType === 'NewBusiness',
      onSuccess: newQuote => {
        if (newQuote) {
          setQuoteID(newQuote.quoteDetails.quote.id);
        }
      }
    }
  );

  // HybridStartEditSession
  useQuery(
    ['hybrid', 'startEditSession', quoteID],
    () => {
      if (!quoteID) {
        throwError(new Error('Unable to start session. Quote ID missing.'));
        return;
      }

      return platform.hybrid.hybridStartEditSession(quoteID);
    },
    {
      enabled:
        editSessionID === undefined && !!quoteID && quoteType !== 'Adjust',
      onSuccess: response => {
        setEditSessionID(response.id);
      }
    }
  );

  const {
    pages,
    pageConfig,
    getNextQuestionPage,
    isFirstPage,
    isLastPage,
    questions,
    isCurrentPageFailed,
    getQuestionAnswer,
    changeQuestionAnswer,
    getArrayDescription,
    tobesError,
    isWorking,
    validationDetails,
    tobesQuoteBundle,
    paymentPlan,
    isLoadingPricing,
    formState,
    clearFieldValidation
  } = useSimpleQuote(
    product.id.toString(),
    questionSetReferenceID,
    props.history,
    setChangesMade,
    true, // IsHybridFlag
    editSessionID,
    exposureID,
    type,
    pageIndex,
    props.orgLeveltoken
  );

  return (
    <SimpleQuoteAsk
      formState={formState}
      pages={pages}
      pageConfig={pageConfig}
      currencyCode={organisationSettings.currencyCode}
      currencySymbol={organisationSettings.currencySymbol}
      dateFormat={organisationSettings.dateFormat}
      countryCode={organisationSettings.defaultCountryCode}
      oraclesConfiguration={oraclesConfiguration}
      questions={questions}
      getQuestionAnswer={getQuestionAnswer}
      changeQuestionAnswer={changeQuestionAnswer}
      getArrayDescription={getArrayDescription}
      tobesError={tobesError}
      disableWarning={disableWarning}
      getSubmitToRender={(isFormValid = false) => (
        <QuoteFooter
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          isFormValid={isFormValid}
          confirmButtonText={productConfiguration.quote?.confirm?.buttonText}
          isCurrentPageFailed={isCurrentPageFailed}
          backAction={() => {
            if (changeMade) {
              const isConfirmed = window.confirm(
                'Are you sure you want to leave?'
              );
              if (isConfirmed) {
                getNextQuestionPage(true);
              }
            } else {
              getNextQuestionPage(true);
            }
          }}
          nextAction={() => {
            setDisableWarning(true);
            getNextQuestionPage(
              false,
              paymentPlan.selectedPricingPlan?.paymentPlan.type
            );
          }}
          isWorking={isWorking}
          isAdjustment={tobesQuoteBundle?.requestedQuote.quoteType === 'MTA'}
          paymentPlan={paymentPlan.selectedPricingPlan}
          mtaInformation={tobesQuoteBundle?.mtaInfo}
          productConfiguration={productConfiguration}
          canPrice={pageConfig.canPrice}
        />
      )}
      isLoading={isWorking}
      tobesQuoteBundle={tobesQuoteBundle}
      paymentPlan={paymentPlan}
      productConfiguration={productConfiguration}
      productInterfaceConfiguration={productInterfaceConfiguration}
      validationDetails={validationDetails}
      progressBarConfig={customConfiguration?.userInterface?.progressBar}
      isLoadingPricing={isLoadingPricing}
      clearFieldValidation={clearFieldValidation}
    />
  );
};

export interface ISimpleQuoteHybrid {
  product: IProduct;
  type: QuoteType;
  questionSetReferenceID: string;
  pageIndex?: string;
  isAuthenticated: boolean;
  dashboardPath: string;
  hideFooter: () => void;
  history: History;
  orgLeveltoken?: string | null;
  oraclesConfiguration: OraclesConfiguration;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  customConfiguration: CustomConfig;
  organisationSettings: IOrganisationSettings;
}
