import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { OrganisationSettings } from '@aventus/platform';

export const useGetOrganisationSettings: UseGetOrganisationSettings = function () {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [ organisationSettings, setOrganisationSettings ] = React.useState<OrganisationSettings | undefined>(undefined);
  const [ isGettingOrganisationSettings, setIsGettingOrganisationSettings ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      setIsGettingOrganisationSettings(true);

      try {
        const _organisationSettings = await symphony.getOrganisationSettings();
        setOrganisationSettings(_organisationSettings);
      } catch (error) {
        throwError(error);
      }

      setIsGettingOrganisationSettings(false);

    }());
  }, []);

  return {
    organisationSettings,
    isGettingOrganisationSettings
  };

}

export type UseGetOrganisationSettings = () => {
  organisationSettings: OrganisationSettings | undefined,
  isGettingOrganisationSettings: boolean
}
