import * as React from 'react';
import { BladeCallToActionSet } from '@aventus/blade';

export const PolicyList: React.FunctionComponent<IPolicyList> = props => {
  return (
    <BladeCallToActionSet
      size={'large'}
      layout={props.layout || 'vertical'}
      callToActions={props.policies}
      title={props.title}
      heading={props.heading}
    />
  );
};

export interface IPolicyList {
  policies: any[];
  goToPolicy: (policyId: string) => void;
  image: string; //TODO: Need to work this out
  label: string;
  icon: string;
  layout?: string;
  title?: string;
  heading?: string;
}
