import { AUTH_CHANGE_PASSWORD_REQUEST, AUTH_CHANGE_PASSWORD_SUCCESS, AUTH_CHANGE_PASSWORD_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';

export default function changePasswordAction(oldPassword, newPassword) {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/changepassword',
        method: 'POST',
        data: {
          password: oldPassword,
          newPassword: newPassword
        }
      },
      actions: [changePasswordRequestAction, changePasswordSuccessAction, changePasswordFailureAction]
    }
  };
}

export function changePasswordRequestAction() {
  return {
    type: AUTH_CHANGE_PASSWORD_REQUEST
  };
}

export function changePasswordSuccessAction() {
  return {
    type: AUTH_CHANGE_PASSWORD_SUCCESS
  }
}

export function changePasswordFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_CHANGE_PASSWORD_FAILURE
    });

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
