export default {
  credentials: {
    username: ''
  },
  user: false,
  midAuthFlow: null,
  nonce: null,
  _flags: {
    
  }
};
