import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { BladeIcon } from '../../icon';
import { BladeLoader } from '../../loader';
import css from './index.css';

export const BladeInputText: React.FunctionComponent<IBladeInputText> = props => {
  const inputProps = useInput(props);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      {inputProps.questionImage && (
        <div>
          <img
            src={inputProps.questionImage}
            alt={'question'}
            className={css.imagebox}
          />
        </div>
      )}

      <div className={css.text_scaffold}>
        <input
          ref={null}
          type={inputProps.type || 'text'}
          id={inputProps.id}
          name={inputProps.name}
          className={inputProps.className || ''}
          value={inputProps.value || ''}
          onFocus={inputProps.onFocus}
          disabled={props.disabled || false}
          onBlur={event => {
            if (inputProps.type !== 'password') {
              const value = event.target.value || null;
              if (value) {
                inputProps.onChange(value.trim());
              }
            }

            typeof inputProps.onBlur === 'function' && inputProps.onBlur(event);
          }}
          onChange={inputProps.onChange}
        />

        <div className={css.text_action}>
          {props.isLoading && (
            <BladeLoader className={css.text_action_loader} />
          )}

          {props.onAction && (
            <BladeIcon
              name={props.actionIcon || 'faLocation'}
              onClick={() => props.onAction?.(inputProps.value)}
            />
          )}
        </div>
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputText extends IBladeInput {
  isLoading?: boolean;
  onAction?: (value: any) => void;
  actionIcon?: string;
  disabled?: boolean;
}
