import update from 'immutability-helper';

export function createAccountPasswordRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isCreatingAccountPassword: { $set: true }
        }
      })
    }
  };
}

export function createAccountPasswordSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isCreatingAccountPassword: false,
            successfullyCreatedAccountPassword: true
          }
        }
      })
    }
  };
}

export function createAccountPasswordFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isCreatingAccountPassword: false,
            successfullyCreatedAccountPassword: false
          }
        }
      })
    }
  }
}
