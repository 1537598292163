import React, { useState } from 'react';
import { BladeInputText, IBladeInputText } from '../text';

export const BladeInputPassword: React.FunctionComponent<IBladeInputPassword> = props => {
  const [isVisible, setVisibility] = useState(false);

  return (
    <BladeInputText
      type={isVisible ? 'text' : 'password'}
      actionIcon={isVisible ? 'faEyeSlash' : 'faEye'}
      onAction={() => setVisibility(!isVisible)}
      {...props}
    />
  );
};

export interface IBladeInputPassword extends IBladeInputText {}
