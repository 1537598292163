import React from 'react';
import { BladePage, BladeMarkdown } from '@aventus/blade';
import { Scaffold } from '@honey/consume-react-jss';
import routes from '../../../../app/routes';

export default function renderPrivacyPolicy() {
  return (
    <BladePage title={routes.privacyPolicy.documentTitle}>
      <Scaffold size={'large'} only={'parent'}>
        <BladeMarkdown
          size={'base'}
          markdown={
            this.props.app.core.customConfig.client.legalCopy.privacyPolicy
          }
        />
      </Scaffold>
    </BladePage>
  );
}
