import * as React from 'react';
import { BladeIcon } from '../icon';
import { BladeMarkdown } from '../markdown';
import css from './index.css';
import cx from 'classnames';

export const BladeMessage: React.FunctionComponent<IBladeMessage> = props => {
  const [isDismissed, setIsDismissed] = React.useState<boolean>(false);
  const { message } = props;
  React.useEffect(() => {
    setIsDismissed(false);
  }, [message]);

  if (isDismissed) {
    return null;
  }

  return (
    <div
      id={props.id}
      className={cx(
        'blade-message',
        css.message,
        props.variant === 'success' ? css.message_success : '',
        props.variant === 'error' ? css.message_error : '',
        props.variant === 'info' ? css.message_info : '',
        props.variant === 'warning' ? css.message_warning : '',
        props.isFlush ? css.message_isFlush : '',
        props.isDismissable ? css.message_isDismissable : '',
        props.isOverlayed ? css.message_isOverlayed : ''
      )}
    >
      <div className={css.message_icon}>
        <BladeIcon
          name={props.icon || MESSAGE_TYPE_ICONS[props.variant]}
          color={'white'}
        />
      </div>

      <div className={css.message_content}>
        {props.message && (
          <div className={css.message_content_message}>
            <p>{props.message}</p>
          </div>
        )}

        {props.children && (
          <div className={css.message_content_message}>
            <p>{props.children}</p>
          </div>
        )}

        {props.detail && (
          <div className={css.message_content_detail}>
            <BladeMarkdown size={'base'} markdown={props.detail} />
          </div>
        )}

        {props.custom && (
          <div className={css.message_content_detail}>{props.custom}</div>
        )}

        {props.isDismissable && (
          <button
            onClick={() => {
              setIsDismissed(true);
              props.callBack && props.callBack();
            }}
            className={` ${css.message_content_dismiss} `}
          >
            {props.dismissLabel || 'Dismiss'}
          </button>
        )}
      </div>
    </div>
  );
};

export interface IBladeMessage {
  id?: string;
  variant: 'success' | 'error' | 'info' | 'warning';
  icon?: string;
  message?: string;
  detail?: string;
  custom?: React.ReactNode;
  isFlush?: boolean;
  isDismissable?: boolean;
  isOverlayed?: boolean;
  dismissLabel?: string;
  callBack?: () => void;
}

export type MessageVariant = 'success' | 'error' | 'info' | 'warning';

const MESSAGE_TYPE_ICONS: MessageTypeIcons = {
  success: 'faCheck',
  error: 'faExclamation',
  info: 'faInfo',
  warning: 'faExclamationTriangle'
};

interface MessageTypeIcons {
  [type: string]: string;
}
