import React from 'react';
import routes from '../../../routes';
import { Scaffold } from '@honey/consume-react-jss';
import {
  BladePage,
  BladeButton,
  BladeInputPassword,
  BladeSurface
} from '@aventus/blade';

export default function renderChangePassword() {
  const { credentials, _flags } = this.props.app.auth;
  const { oldPassword, newPassword } = this.state;

  return (
    <BladePage
      isLoading={_flags.isChangingPassword}
      loadingMessage={'Changing your password'}
      title={routes.changePassword.documentTitle}
    >
      <Scaffold orient={'col'} size={'large'}>
        <BladeSurface>
          <Scaffold orient={'col'} size={'default'}>
            <BladeInputPassword
              name="oldPassword"
              label="Confirm your old password"
              error={credentials?._oldPassword?.error}
              value={oldPassword || ''}
              onChange={value => this.setState({ oldPassword: value })}
            />

            <BladeInputPassword
              name="newPassword"
              label="Choose a new password"
              error={credentials?._newPassword?.error}
              value={newPassword || ''}
              onChange={value => {
                this.setState({ newPassword: value });
              }}
              onBlur={e =>
                this.props.handleAuthValidateCredentials(
                  'newPassword',
                  newPassword
                )
              }
            />

            <BladeButton onClick={this.onPressChangePassword}>
              {'Change password'}
            </BladeButton>
          </Scaffold>
        </BladeSurface>
      </Scaffold>
    </BladePage>
  );
}
