export const isValidRegistration = (
  registration?: string | null,
  regOrVin?: string | null
): boolean => {
  if (typeof registration === 'string' && registration.length > 1) {
    return true;
  } else if (typeof regOrVin === 'string' && regOrVin.length > 1) {
    // This is backwards compatibility if regOrVin
    // set over registration in risk
    return true;
  } else {
    return false;
  }
};
