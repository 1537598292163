///<reference path='./types/index.d.ts' />
import { FatZebra, FatZebraConstructor } from '@aventus/fatzebra.js';

type LoadFatZebra = (
  ...args: Parameters<FatZebraConstructor>
) => Promise<FatZebra | null>;

const VERSION = 1;

const V1_URL = 'https://cdn.pmnts.io/sdk/v1/fatzebra.js';
const V1_SANDBOX_URL = 'https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js';
const V1_REGEX = /\/sdk\/v1\/fatzebra.js$/;

let fatZebraPromise: Promise<FatZebraConstructor | null> | null = null;

const registerWrapper = (
  fatZebra: any,
  startTime: number,
  is_sandbox = false
) => {
  if (!fatZebra || !fatZebra._registerWrapper) {
    return;
  }

  fatZebra._registerWrapper({
    name: 'fatzebra.js',
    version: VERSION,
    startTime,
    env: is_sandbox === false ? 'production' : 'development'
  });
};

const loadScript = (
  is_sandbox = false
): Promise<FatZebraConstructor | null> => {
  if (fatZebraPromise !== null) {
    return fatZebraPromise;
  }

  const findScript = (): HTMLScriptElement | null => {
    const scripts = document.querySelectorAll<HTMLScriptElement>(
      `script[src^="${is_sandbox ? V1_SANDBOX_URL : V1_URL}"]`
    );

    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];

      if (!V1_REGEX.test(script.src)) {
        continue;
      }

      return script;
    }

    return null;
  };

  const injectScript = (): HTMLScriptElement => {
    const script = document.createElement('script');
    script.src = is_sandbox ? V1_SANDBOX_URL : V1_URL;

    const headOrBody = document.head || document.body;

    if (!headOrBody) {
      throw new Error(
        'Expected document.body not to be null. Fatzebra.js requires a body element.'
      );
    }

    headOrBody.appendChild(script);

    return script;
  };

  fatZebraPromise = new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      // Resolve to null when imported server side. This makes the module
      // safe to import in an isomorphic code base.
      resolve(null);
      return;
    }

    if (window.FatZebra) {
      console.warn('FatZebra already exists in document');
      resolve(window.FatZebra);
      return;
    }

    try {
      let script = findScript();

      if (script) {
        console.warn('FatZebra already exists in document');
      } else {
        script = injectScript();
      }

      script.addEventListener('load', () => {
        if (window.FatZebra) {
          resolve(window.FatZebra);
        } else {
          reject(new Error('fatzebra.js is not available'));
        }
      });

      script.addEventListener('error', () => {
        reject(new Error('Failed to load fatzebra.js'));
      });
    } catch (error) {
      reject(error);
      return;
    }
  });

  return fatZebraPromise;
};

const bindFatZebra = (
  instance: any,
  args: Parameters<FatZebraConstructor>
): FatZebra => {
  return new (Function.prototype.bind.apply(instance, args))();
};

const initFatZebra = (
  maybeFatZebra: FatZebraConstructor | null,
  args: Parameters<FatZebraConstructor>,
  startTime: number,
  is_sandbox: boolean = false
): FatZebra | null => {
  if (maybeFatZebra === null) {
    return null;
  }

  const fatZebra = bindFatZebra(maybeFatZebra, args);
  registerWrapper(fatZebra, startTime, is_sandbox);
  return fatZebra;
};

export const loadFatZebra: LoadFatZebra = (_, args) => {
  const startTime = Date.now();
  const is_sandbox = args.test ?? false;

  return loadScript(is_sandbox).then(maybeFatZebra =>
    initFatZebra(maybeFatZebra, [_, args], startTime, is_sandbox)
  );
};
