import { useEffect, useState } from 'react';
import { isEmpty, filter } from 'lodash';

export const useValidation: UseValidation = validation => {
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();

  useEffect(() => {
    if (!isEmpty(validation)) {
      const minDateMatch = filter(validation, s => s.indexOf('min:') !== -1);
      if (minDateMatch.length > 0) {
        const minDateString = minDateMatch[0].replace('min:', '');

        setMinDate(minDateString);
      }
      const maxDateMatch = filter(validation, s => s.indexOf('max:') !== -1);
      if (maxDateMatch.length > 0) {
        const maxDateString = maxDateMatch[0].replace('max:', '');
        setMaxDate(maxDateString);
      }
    }
  }, [validation]);

  return { minDate, maxDate };
};

export type UseValidation = (
  validation: Array<string>
) => { minDate: string | undefined; maxDate: string | undefined };
