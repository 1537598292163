import * as React from 'react';
import { BladeList, BladeSurface } from '@aventus/blade';
import { PolicyBundle } from '@aventus/platform';
import { ToHumanReadable } from '@aventus/application-timezone';
import { getPolicyDetails } from '../../services/get-policy-details';
import css from '../your-policy/index.css';
import { getMotorPolicyDetails } from '@aventus/mvmt-quote/services/get-motor-risk-details';
import { AutoRenewalConfiguration } from '@aventus/configuration/product';

export const YourPolicy: React.FunctionComponent<IYourPolicy> = props => {
  return (
    <BladeSurface
      title={'Your policy'}
      className={css.yourPolicy}
      shouldRespectViewWidth={props.shouldRespectViewWidth}
    >
      <BladeList
        isFlush={true}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
        list={
          props.isMotorPolicy
            ? getMotorPolicyDetails(
                props.policyBundle.risk,
                props.policyBundle.totalPaid?.additionalProducts
              )
            : getPolicyDetails(
                props.policyBundle.policy,
                props.toHumanReadable,
                props.policyBundle.creditAgreement,
                props.policyBundle.discountInfo,
                props.policyLabel,
                props.paymentPlanLabels,
                props.policyBundle.totalPaid,
                props.allowAutoBind,
                props.allowAutoBindValue,
                props.autoRenewalConfiguration,
                props.autoRenewalToggle,
                props.togglableLoading,
                undefined, // renewal status
                props.coverStartLabel,
                props.coverEndLabel,
                props.showPurchasedDate
              )
        }
      />
    </BladeSurface>
  );
};

export interface IYourPolicy {
  policyBundle: PolicyBundle;
  policyLabel?: string;
  isMotorPolicy: boolean;
  toHumanReadable: ToHumanReadable;
  shouldRespectViewWidth?: boolean;
  paymentPlanLabels: { [key: string]: string };
  allowAutoBindValue?: boolean;
  autoRenewalToggle?: (value: boolean) => any;
  togglableLoading?: boolean;
  allowAutoBind?: boolean;
  autoRenewalConfiguration?: AutoRenewalConfiguration;
  coverStartLabel?: string;
  coverEndLabel?: string;
  showPurchasedDate?: boolean;
}
