import update from 'immutability-helper';
import { buildObjectFromDotAddress } from '@aventus/pocketknife/build-object-from-dot-address';

export function updateForm (
  formState: object,
  formFieldAddress: string,
  formFieldValue: any
): object {

  // The update function here accepts an object,
  // and a partial of that same object, with a new value.
  // With the dot-notion address, eg. a.b.c, we just received,
  // we can build up this partial object, eg. a: { b: { c: formFieldValue } }.

  const updateObjectCommand: object = buildObjectFromDotAddress(formFieldAddress, 0, formFieldValue);

  // Now we can directly update the object
  // with this partial, and the new value.

  const updatedFormState = update(formState, updateObjectCommand);
  return updatedFormState;

}

export function getFromForm (
  formState: object,
  formFieldAddress: string
): any {
  return (
    formFieldAddress
      .split('.')
      .reduce(
        (acc: any, current: string) => {

          // If an expected object's value is null,
          // then we can obviously assume the object property
          // is also null. 

          if (acc === null) {
            return null;
          }

          return acc[current];
        },
        formState
      )
  );

}
