import {
  TobesValidationMap,
  TobesQuestionState
} from '@aventus/mvmt-simplequote/store/form-state';
import { createRefKey } from '..';

export const mapValidationErrors = (
  questions: TobesQuestionState[],
  validationMap: TobesValidationMap
): TobesQuestionState[] => {
  const mapper = (
    questions: TobesQuestionState[],
    validationMap: TobesValidationMap,
    parentReferenceID?: string
  ): TobesQuestionState[] =>
    questions.map(question => {
      // This is a combination of referenceIDs from the
      // question map. We test against these.
      const questionKey = createRefKey(question.referenceID, parentReferenceID);

      return {
        ...question,
        ...(validationMap.has(questionKey)
          ? { validationErrors: validationMap.get(questionKey)?.errors }
          : {}),
        children: mapper(question.children, validationMap, questionKey)
      };
    });

  return mapper(questions, validationMap);
};
