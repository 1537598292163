export const initialState = {
  partnerId: null,
  partnerReference: null,
  partnerConfig: {},
  payload: null,
  data: {
    vri: null
  },
  _flags: {}
}
