import { BladeFormFieldset, BladeInputSegmented, BladeInputSelect, BladeInputText } from "@aventus/blade";
import { QuestionMissingConfiguration } from "@aventus/errors";
import { ICommonFieldProps, ICommonInputProps } from "@aventus/mvmt-simplequote/models/commonProps";
import { ITobesQuestion } from "@aventus/platform-client-context/models";
import React from 'react';

export function renderExtra(
    question: ITobesQuestion,
    commonFieldsetProps: ICommonFieldProps,
    commonInputProps: ICommonInputProps
  ) {
    if (!question.answerOptions) {
      throw new QuestionMissingConfiguration(
        `Tried to render ${question.questionType} question without any possibleAnswers`
      );
    }
  
    switch (question.questionType) {
      case 'Segmented':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSegmented
              {...commonInputProps}
              options={question.answerOptions}
              matchOn={'referenceID'}
            />
          </BladeFormFieldset>
        );
      case 'Picklist':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSelect
              {...commonInputProps}
              options={question.answerOptions}
              matchOn={'referenceID'}
            />
          </BladeFormFieldset>
        );
      case 'MoneyStepper':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSelect
              {...commonInputProps}
              isStepper={true}
              options={question.answerOptions}
              matchOn={'referenceID'}
            />
          </BladeFormFieldset>
        );
      case 'BoxMoneyStepper':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSelect
              {...commonInputProps}
              isStepper={true}
              options={question.answerOptions}
              matchOn={'referenceID'}
            />
          </BladeFormFieldset>
        );
      case 'Select':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSelect
              {...commonInputProps}
              options={question.answerOptions}
              matchOn={'referenceID'}
            />
          </BladeFormFieldset>
        );
      case 'multiSelect':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputSelect
              {...commonInputProps}
              options={question.answerOptions}
              matchOn={'referenceID'}
              multiSelect={true}
              supressMobile={true}
            />
          </BladeFormFieldset>
        );
      default:
        return (
          // if no question type has been defined for date then use string
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputText {...commonInputProps} />
          </BladeFormFieldset>
        );
    }
  }