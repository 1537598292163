import { ITobesQuestion } from '@aventus/platform-client-context/models';
import { createFormStateMap } from '../helpers/FormState/createFormStateMap';
import { clearFieldValidationWithKey } from '../helpers/Validation/clearFieldValidationWithKey';
import { clearValidation } from '../helpers/Validation/clearValidation';
import { mapValidationErrors } from '../helpers/Validation/mapValidationErrors';

export type TobesQuestionPageState = {
  questions: TobesQuestionState[];
  validationErrorMap: TobesValidationMap;
};

export type TobesQuestionState = {
  referenceID: string;
  value: unknown;
  validationErrors: string[];
  children: TobesQuestionState[];
};

type TobesQuestionPageInitAction = {
  type: 'INIT_FORM_STATE';
  payload: ITobesQuestion[];
};

type TobesQuestionPageSetValidationErrorsAction = {
  type: 'SET_VALIDATION_ERRORS';
  payload: TobesValidationMap;
};

type TobesQuestionPageClearValidationAction = {
  type: 'CLEAR_VALIDATION';
};

type TobesQuestionPageClearFieldValidation = {
  type: 'CLEAR_FIELD_VALIDATION';
  payload: string;
};

export type TobesQuestionPageStateActions =
  | TobesQuestionPageInitAction
  | TobesQuestionPageSetValidationErrorsAction
  | TobesQuestionPageClearValidationAction
  | TobesQuestionPageClearFieldValidation;

export type TobesValidationMap = Map<
  string,
  {
    result: boolean;
    errors: string[];
  }
>;

export const initialFormState = {
  questions: [],
  validationErrorMap: new Map()
};

export const formStateReducer = (
  state: TobesQuestionPageState,
  action: TobesQuestionPageStateActions
) => {
  switch (action.type) {
    case 'INIT_FORM_STATE':
      return {
        ...state,
        questions: createFormStateMap(action.payload)
      };
    case 'SET_VALIDATION_ERRORS':
      return {
        questions: mapValidationErrors(state.questions, action.payload),
        validationErrorMap: new Map(action.payload)
      };
    case 'CLEAR_VALIDATION':
      return {
        questions: clearValidation(state.questions),
        validationErrorMap: new Map()
      };
    case 'CLEAR_FIELD_VALIDATION':
      return clearFieldValidationWithKey(state, action.payload);
    default:
      return state;
  }
};
