import { valueGet } from './_value-get';
import { valueSet } from './_value-set';

export function useMoney (
  _value: IBladeMoney,
  _onChange: BladeInputOnChangeCustom,
  currencyCode: string): IUseMoney {

  // The money object expected here looks like
  // { value: 'x' }
  // so we need to make sure we pick out that value.

  const value = valueGet(_value?.value);

  // On change, we need to take the value
  // and put it back in the structure it was given
  // to us, ie:
  // 1,000 => { value: 100000 }

  const onChange: BladeInputOnChange = (e) => {
    const valueToSet = valueSet(e.target.value);
    _onChange({ value: valueToSet, currencyCode });
  };

  return {
    value,
    onChange
  };

}

interface IUseMoney {
  value: string,
  onChange: BladeInputOnChange
}
