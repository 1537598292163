import { AUTH_VALIDATE_ME_REQUEST, AUTH_VALIDATE_ME_SUCCESS, AUTH_VALIDATE_ME_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import setExternalIdAction from '../../../api/core/tracking/set-external-id/actions';
import track from '../../../api/core/tracking/track/actions';

export default function validateMeAction(options = {}) {

  const {
    fromSignIn = false
  } = options;

  return (dispatch) => {
    dispatch({
      type: 'API_REQUEST',
      [API_SYM]: {
        requestConfig: {
          url: 'user/me',
        },
        actions: [
          validateMeRequestAction,
          validateMeSuccessAction,
          validateMeFailureAction
        ],
        passToAction: {
          fromSignIn
        }
      }
    });
  }
}

export function validateMeRequestAction() {
  return {
    type: AUTH_VALIDATE_ME_REQUEST
  };
}

export function validateMeSuccessAction(res, { fromSignIn }) {
  const {
    externalID,
    email
  } = res.user;

  return (dispatch) => {
    dispatch({
      type: AUTH_VALIDATE_ME_SUCCESS
    })

    dispatch(setExternalIdAction(externalID, email));

    if (fromSignIn) {
      dispatch(
        track('changeUser', null, { externalId: externalID, email }));
    }
  }
}

export function validateMeFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_VALIDATE_ME_FAILURE
    });

    dispatch(setExternalIdAction());

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
