import * as React from 'react';
import Slider from 'rc-slider';
import { IBladeInputSlider } from './types';
import './rc-slider.css';
import { useSlider } from './use-slider';
import css from './index.css';
import { BladeLink, BladeMarkdown } from '@aventus/blade';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

export const BladeInputSlider: React.FunctionComponent<IBladeInputSlider> = props => {
  const {
    sliderOptions,
    sliderValue,
    setSliderValue,
    onChange,
    getLabel,
    reset
  } = useSlider(
    props.optionsFormatted,
    props.options,
    props.defaultValue,
    props.value,
    props.showMiddleValue,
    props.defaultIndex,
    props.onChange
  );

  return (
    <div>
      <div className={css.slider}>
        <SliderWithTooltip
          step={null}
          marks={sliderOptions}
          value={sliderValue}
          onChange={setSliderValue}
          onAfterChange={onChange}
          tipFormatter={getLabel}
          tipProps={{ visible: true }}
        ></SliderWithTooltip>
      </div>
      {props.showResetButton && (
        <div className={css.resetLink}>
          <BladeLink
            onClick={() => {
              reset(props.defaultPercentage);
            }}
          >
            Reset
          </BladeLink>
        </div>
      )}

      {props.footerText && (
        <BladeMarkdown className={css.footerText} markdown={props.footerText} />
      )}
    </div>
  );
};
