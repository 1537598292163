import React from 'react';
import css from './css/index.css';
import cx from 'classnames';

interface DateRangeValueProps {
    value?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    className: string;
}

const DateRangeValue = (props : DateRangeValueProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  var dateValues = props.value?.match(/^(.*)\s-\s(.*)$/)
  if(!dateValues){
    return null
  }

  return (
    <div
      className={cx(props.className, css.daterange_input)}
      onClick={props.onClick}
      ref={ref}
    >
      <span>{dateValues[1]}&nbsp;-&nbsp;</span>
      <span>{dateValues[2]}</span>
    </div>
  );
};

export default DateRangeValue
