import { BladeInputText, BladeLink, IBladeInputText } from '@aventus/blade';
import * as React from 'react';
import mailcheck from 'mailcheck';
import { emailSuggestion } from './emailSuggestion';
import css from './index.css';
import { all } from './internalDomains/all';

export const BladeEmail: React.FunctionComponent<IBladeInputEmail> = props => {
  const [suggestion, setSuggestion] = React.useState<string>('');
  const [emailVal, setEmailVal] = React.useState<string>('');

  var internalLists = all;

  if (props.customDomains) {
    internalLists.concat(props.customDomains);
  }

  if(props.value && emailVal === ''){
    setEmailVal(props.value)
  }

  var mailCheckBlur = () => {
    // This threshold if set to 3 will take full domain over top level
    mailcheck.domainThreshold = 3;
    mailcheck.topLevelThreshold = 2;

    var mailCheckoptions: MailcheckModule.IAsynchronousOptions = {
      email: emailVal,
      domains: internalLists,
      topLevelDomains: ['com'],
      suggested: (emailSuggestion: emailSuggestion) => {
        setSuggestion(`${emailSuggestion.full}`);
      },
      empty: () => {
        setSuggestion('');
      }
    };

    mailcheck.run(mailCheckoptions);
  };

  return (
    <>
      <BladeInputText
        variant={props.variant}
        name={props.name}
        type={props.type}
        className={props.className}
        label={props.label}
        id={props.id}
        value={emailVal}
        onFocus={props.onFocus}
        error={props.error}
        validate={props.validate}
        onValidate={props.onValidate}
        onChange={v => {
          setEmailVal(v);
          props.onChange(v);
        }}
        onBlur={v => {
            mailCheckBlur();
            // We may not always have an onblur event declared
            if(props.onBlur){
              props.onBlur(v);
            }
        }}
      />
      {suggestion && (
        <div className={css.suggestionbox}>
          <label>Did you mean </label>
          <BladeLink
            onClick={() => {
              setEmailVal(suggestion);
              props.onChange(suggestion);
              setSuggestion('');
            }}
          >
            {suggestion}
          </BladeLink>
        </div>
      )}
    </>
  );
};

export interface IBladeInputEmail extends IBladeInputText {
  customDomains?: string[];
}
