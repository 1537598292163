import React from 'react';
import { BladeButton, BladeInputMoney, BladeInputSelect } from '@aventus/blade';
import { Controller, useForm } from 'react-hook-form';
import { EFieldMessages, InputSelectFieldType } from '../..';
import { Money } from '@aventus/platform';
import sectors from './../../../../data/industry-employment-sectors.json';
import css from '../../index.css';
import cx from 'classnames';

const IncomeDetails = (props: IBNPIncomeDetailsProps): JSX.Element => {
  const { control, handleSubmit, errors, watch } = useForm<any>({
    defaultValues: {
      employmentStatus: props.employmentStatus,
      industrySector: props.industrySector,
      employmentSector: props.employmentSector,
      netMonthlyIncome: props.netMonthlyIncome
    }
  });

  const onSubmit = (data: IIncomeDetailsForm) => {
    props.updateStore(data);
    props.nextStep();
  };

  const onError = () => {
    if (typeof props.validationError === 'function') {
      props.validationError(
        'Some fields are incomplete, please check the form and try again'
      );
    }
  };

  const employmentStatus = watch('employmentStatus');
  const industrySector = watch('industrySector');

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={css.bnpFieldWrapper}>
        <Controller
          name={'employmentStatus'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <BladeInputSelect
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  label={'Employment status'}
                  options={[
                    { referenceID: 'FT', text: 'Full time' },
                    { referenceID: 'PT', text: 'Part time' },
                    { referenceID: 'S', text: 'Student' },
                    { referenceID: 'R', text: 'Retired' },
                    { referenceID: 'SE', text: 'Self employed' },
                    { referenceID: 'U', text: 'Unemployed' },
                    { referenceID: 'H', text: 'Home maker' },
                    { referenceID: 'D', text: 'Disability allowance' }
                  ]}
                  matchOn="referenceID"
                />
                {errors && 'employmentStatus' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.employmentStatus?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        {employmentStatus && employmentStatus.referenceID === 'SE' && (
          <>
            <Controller
              name={'industrySector'}
              control={control}
              rules={{ required: EFieldMessages.required }}
              render={props => {
                return (
                  <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                    <BladeInputSelect
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      name={props.name}
                      label={'Industry sector'}
                      options={sectors}
                      matchOn="referenceID"
                      labelOn="name"
                    />
                    {errors && 'industrySector' in errors && (
                      <span className={css.bnpFieldError}>
                        {errors.industrySector?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            {industrySector && (
              <Controller
                name={'employmentSector'}
                control={control}
                rules={{ required: EFieldMessages.required }}
                render={props => {
                  return (
                    <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                      <BladeInputSelect
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        name={props.name}
                        label={'Employment sector'}
                        options={industrySector.employmentSectors || []}
                        matchOn="referenceID"
                        labelOn="name"
                      />
                      {errors && 'employmentSector' in errors && (
                        <span className={css.bnpFieldError}>
                          {errors.employmentSector?.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            )}
          </>
        )}

        <Controller
          name={'netMonthlyIncome'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <BladeInputMoney
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  currencySymbol="£"
                  label={'Net monthly income'}
                  currencyCode="GBP"
                />
                {errors && 'netMonthlyIncome' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.netMonthlyIncome?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
      </div>
      <div className={css.bnpButtonWrap}>
        <BladeButton
          type="button"
          onClick={() => props.prevStep()}
          variant="secondary"
        >
          Previous
        </BladeButton>
        <BladeButton type="submit" variant="primary">
          Continue
        </BladeButton>
      </div>
    </form>
  );
};

interface IIncomeDetailsForm {
  employmentStatus: InputSelectFieldType;
  industrySector: InputSelectFieldType;
  employmentSector: InputSelectFieldType;
  netMonthlyIncome: Money;
}

interface IBNPIncomeDetailsProps {
  employmentStatus: InputSelectFieldType | null;
  industrySector: InputSelectFieldType | null;
  employmentSector: InputSelectFieldType | null;
  netMonthlyIncome: Money | null;
  nextStep: () => void;
  prevStep: () => void;
  updateStore: (data: IIncomeDetailsForm) => void;
  validationError?: (message: string) => void;
}

export default IncomeDetails;
