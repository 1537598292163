import * as React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import { BladeSurface } from '../../../surface';
import { BladeLink } from '../../../link';
import css from './index.css';
import cx from 'classnames';

export const MotorwebCarDetails: React.FunctionComponent<IMotorwebCarDetails> = props => {
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  return (
    <div className={css.carDetails}>
      <strong className={css.carDetails_title}>
        <label>{'Your car'}</label>
      </strong>

      <BladeSurface>
        <div
          className={cx(
            css.carDetails_content,
            { [css.carDetails_content_isSpaceLimited]: matchesQuery },
            { [css.carDetails_content_isSpacious]: !matchesQuery }
          )}
        >
          <div className={css.carDetails_content_breakdown}>
            <p>
              <strong>
                {props.year} {props.make} {props.model}
              </strong>
            </p>
            <p className={cx(css.specification_details)}>
              {props.variant && (
                <span title="Vehicle variant">{props.variant}</span>
              )}
              {props.series && (
                <span title="Vehicle series">{props.series}</span>
              )}
              {props.transmission && (
                <span title="Vehicle body type">{props.bodyType}</span>
              )}
              {props.transmission && (
                <span title="Vehicle transmission">{props.transmission}</span>
              )}
              {props.cc && (
                <span title="Vehicle engine capacity">{props.cc}</span>
              )}
              {props.cc && (
                <span title="Vehicle engine configuration">{props.engine}</span>
              )}
            </p>
          </div>

          {props.rego?.length < props.maxLength && (
            <div className={css.carDetails_content_rego}>
              <p>{props.rego}</p>
              <p>{props.registeredState}</p>
            </div>
          )}
        </div>
      </BladeSurface>

      {props.clearDetails && (
        <BladeLink isSmall={true} onClick={() => props.clearDetails()}>
          {'Not your car?'}
        </BladeLink>
      )}
    </div>
  );
};

export interface IMotorwebCarDetails {
  make: string;
  model: string;
  variant?: string;
  series?: string;
  bodyType?: string;
  year?: string;
  transmission?: string;
  cc?: string;
  engine?: string;
  rego: string;
  registeredState?: string;
  maxLength: number
  clearDetails: () => void;
}
