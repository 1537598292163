import { useCallback, useState } from 'react';
import { IValidationError } from '@aventus/platform-client';

export type AlertStatusType = 'success' | 'warning' | 'info' | 'error';

export type AlertDataType = {
  type: AlertStatusType;
  message: string;
  error?: Error;
  validationErrors?: IValidationError[];
};

export type UseAlerts = () => {
  alerts: Map<string, AlertDataType>;
  getAlert: (key: string) => AlertDataType | undefined;
  hasAlert: (keys: string | string[]) => boolean;
  addAlert: (key: string, value: AlertDataType) => void;
  clearAlert: (key: string) => void;
  clearAlerts: () => void;
  toArray: (AlertDataType | undefined)[];
};

export const useAlerts: UseAlerts = () => {
  const [alerts, setAlerts] = useState<Map<string, AlertDataType>>(
    new Map<string, AlertDataType>()
  );

  const getAlert = (key: string) => alerts.get(key);

  const hasAlert = (keys: string | string[]) => {
    if (Array.isArray(keys)) {
      return keys.some(key => alerts.has(key));
    }

    return alerts.has(keys);
  };

  const addAlert = useCallback(
    (key: string, value: AlertDataType) =>
      setAlerts(prev => new Map(prev.set(key, value))),
    []
  );

  const clearAlert = useCallback(
    (key: string) =>
      setAlerts(prev => {
        prev.delete(key);

        return new Map(prev);
      }),
    []
  );

  const clearAlerts = useCallback(() => setAlerts(new Map()), []);

  const toArray = [...alerts.keys()].map(key => alerts.get(key));

  return {
    alerts,
    getAlert,
    hasAlert,
    addAlert,
    clearAlert,
    clearAlerts,
    toArray
  };
};
