export async function setTimeoutAsync (fnAsync: () => Promise<any>, delay: number): Promise<any> {
  return new Promise(
    resolve => {
      setTimeout(async () => {
        const result = await fnAsync();
        resolve(result);
      }, delay);
    }
  );
}
