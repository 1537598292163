import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import InputMask from 'react-input-mask';
import { useInput } from '../use-input';

export const BladeInputSortCode: React.FunctionComponent<IBladeInputSortCode> = props => {
  const {
    onChange,
    hasBeenInteractedWith,
    error,
    id,
    label,
    value,
    onBlur,
    name,
    className = ''
  } = useInput(props);

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      onChange(event.target.value.replace(/-/g, ''));
    } else {
      onChange(event);
    }
  };

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={hasBeenInteractedWith}
      error={error}
    >
      <BladeInput_Label id={id} label={label} />

      <InputMask
        inputRef={null}
        mask={'99-99-99'}
        maskChar={null}
        value={value}
        onBlur={onBlur}
        onChange={_onChange}
      >
        {(innerProps: any) => (
          <input
            {...innerProps}
            name={name}
            type={'text'}
            className={className}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        )}
      </InputMask>
    </BladeInput_Frame>
  );
};

export interface IBladeInputSortCode extends IBladeInput {}
