import { BladeAppLoader, BladeButton, BladeMarkdown } from '@aventus/blade';

import BenefitsPanel from './benefits-panel';
import Header from './header';
import ProgressBar from './progress-bar';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import YourDuty from './your-duty';
import icons from './icons/styles.css';
import styles from './styles.css';
import { useLandingPageQuote } from './use-landing-page-quote';
import { useTrack } from '@aventus/application-tracking';
import { useDocumentTitle } from '@aventus/pockethooks/use-document-title';
import { useGetOrganisationConfiguration, useGetProductConfiguration } from '@aventus/configuration-client-context';

interface MotorLandingPageProps
  extends RouteComponentProps<{ quoteId: string, productReference: string }> {}

const MotorLandingPage = (props: MotorLandingPageProps) => {
  const { track } = useTrack();
  const { quote, error } = useLandingPageQuote(props.match.params.quoteId);
  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(quote?.productReferenceId, quote?.coverType);

  useDocumentTitle(`Your Quick Quote | ` + organisationConfiguration?.name);

  const onCtaClick = () => {
    const productReference = quote?.productReferenceId ?? props.match.params.productReference;

    props.history.push(`/quote/new?quoteId=${props.match.params.quoteId}&productReference=${productReference}`);
  };

  React.useEffect(() => {
    if (quote){
      track({
        event: 'aventus.quickquote.partner.motor',
        quoteId: quote.id,
        partnerId: quote.partnerId,
        productReference: quote.productReferenceId,
        productCoverReference: quote.coverType
      });
    }
  }, [quote]);

  if (!quote) {
    if (error) {
      // cannot render the lander, pass through to main quote process
      onCtaClick();
    }

    return <BladeAppLoader />;
  }

  return (
    <div className={styles.container}>
      <Header
        partnerName={quote.partnerName}
        registration={quote.registration}
      />

      <BenefitsPanel
        percentageSaving={quote.percentageSaving}
        adminFee={quote.adminFee}
        annualSaving={quote.annualSaving}
        firstMonthlyPayment={quote.firstMonthlyPayment}
        monthlyPrice={quote.monthlyPrice}
        annualPlan={quote.annualPrice}
        stackedPrice={quote.stackedPriceDisplay}
        productConfiguration={productConfiguration}
      />

      <ProgressBar />

      <YourDuty />

      <BladeButton
        style={{ height: 'auto', borderRadius: '50px' }}
        onClick={onCtaClick}
      >
        <div className={styles.cta}>
          <div className={icons.lockIcon}></div>
          Agree and Continue
        </div>
      </BladeButton>

      {productConfiguration?.product.recaptchaPrint && (
        <div className={styles.recaptcha_print}>
          <BladeMarkdown
            size={'base'}
            markdown={productConfiguration.product.recaptchaPrint}
          />
        </div>
      )}
    </div>
  );
};

export default MotorLandingPage;
