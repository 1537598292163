import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationError } from '@aventus/errors';
import { IntelligentQuoteDecline } from '../intelligent-quote-decline';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration
} from '@aventus/configuration-client-context';
import { useGetProduct } from '@aventus/symphony-client/hooks';

export const VriDeclined: React.FunctionComponent<IVriDeclined> = props => {
  const productReference: string = queryParamGet(
    props.location.search,
    'productReference'
  );

  const productCoverReference: string = queryParamGet(
    props.location.search,
    'productCoverReference'
  );

  if (!productReference || !productCoverReference) {
    throw new ApplicationError(
      '`VriDeclined` is trying to fetch the required configuration, but no productReference or productCoverReference was found'
    );
  }

  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );

  const { product } = useGetProduct(productReference, productCoverReference);

  if (productConfiguration && organisationConfiguration && product) {
    return (
      <IntelligentQuoteDecline
        documentTitle={props.documentTitle}
        title={productConfiguration.quote.decline.unrecoverableTitle}
        declineWithNoReason={
          productConfiguration.quote.decline.declineWithNoReason
        }
        help={productConfiguration.quote.decline.help}
        supportLink={organisationConfiguration.links.support}
        onAcknowledgement={props.goHome}
      />
    );
  }

  return null;
};

export interface IVriDeclined extends RouteComponentProps {
  goHome: () => void;
  documentTitle?: string;
}
