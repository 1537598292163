import React from 'react';
import routes from '../../../routes';
import appApi from '../../../api';
import track from 'react-tracking';
import SignUpComponent, { SignUpFormData } from './signUpComponent';
import { Scaffold } from '@honey/consume-react-jss';
import { BladePage, BladeMessage, BladeSurface } from '@aventus/blade';
import { updateProductRef } from '@aventus/pocketknife/dispatch-event';


class SignUp extends React.Component<any, SignUpFormData> {
  isMidFlowAuth: boolean;

  constructor(props: any) {
    super(props);
    this.goToSignIn = this.goToSignIn.bind(this);

    const { isMidFlowAuth = false, emailAddress } = props.location.state || {};
    this.isMidFlowAuth = isMidFlowAuth;
    this.state = {
      username: emailAddress || '',
      password: '',
      orgConfig: undefined
    };
  }

  componentDidMount() {
    const { isMidFlowAuth = false, onSuccessGoToRoute, passToNextRoute } =
      this.props.location.state || {};

    if (isMidFlowAuth) {
      this.props.handleAuthUpdateMidAuthFlowState({
        isMidFlowAuth,
        onSuccessGoToRoute,
        passToNextRoute
      });
    }

    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.signUp.trackingName
    });

    this.props.tracking.trackEvent({
      event: 'aventus.signup'
    });

    updateProductRef(null);
  }

  componentDidUpdate(prevProps: any) {
    const authProps = this.props.app.auth;
    const prevAuthProps = prevProps.app.auth;

    if (
      !prevAuthProps._flags.successfullyValidatedMe &&
      authProps._flags.successfullyValidatedMe
    ) {
      const {
        isMidFlowAuth = false,
        onSuccessGoToRoute,
        passToNextRoute = {}
      } = prevAuthProps.midAuthFlow || {};

      if (isMidFlowAuth && onSuccessGoToRoute) {
        this.props.history.replace(onSuccessGoToRoute, passToNextRoute);
        this.props.tracking.trackEvent({
          event: 'aventus.user.registered',
          view: 'sign-up'
        });
      } else {
        this.props.history.push(routes.home.path);
      }

      this.props.handleAuthUpdateMidAuthFlowState(null);
    }

    if (
      !prevAuthProps._flags.successfullySignedUp &&
      authProps._flags.successfullySignedUp
    ) {
      this.props.tracking.trackEvent({ event: 'aventus.account.create' });
    }
  }

  goToSignIn() {
    const { midAuthFlow } = this.props.app.auth;
    const { location } = this.props;

    if (midAuthFlow) {
      this.props.history.replace(routes.signIn.path, location.state);
    } else {
      this.props.history.push(routes.signIn.path, location.state);
    }
  }

  render() {
    const _flags = this.props.app.auth._flags;
    const isLoading =
      _flags.isSigningUp || _flags.isSigningIn || _flags.isValidatingMe || _flags.isFirebaseSigningIn;
    const { authenticationMethods = [], defaultAuthenticationProvider } = this.props.app.core.config.auth0;
    const authenticationConfig = this.props.app.core.customConfig.userInterface
      ?.authentication;

    return (
      <BladePage
        isLoadingOver={isLoading}
        loadingMessage={
          this.props.app.core.customConfig.userInterface.authentication?.signUp
            ?.setupAccountLoadingMessage || 'Setting up your account..'
        }
        title={routes.signUp.documentTitle}
      >
        <Scaffold orient={'col'} size={'large'}>
          <BladeSurface
            title={authenticationConfig?.signUp.title || 'Create your account'}
            description={authenticationConfig?.signUp.description || undefined}
            shouldRespectViewWidth={true}
          >
            <Scaffold fill={true} position={'c'}>
              <Scaffold
                grow={true}
                orient={'col'}
                size={'large'}
                style={{ paddingTop: 0 }}
              >
                {this.isMidFlowAuth && (
                  <BladeMessage
                    variant={'info'}
                    message={
                      authenticationConfig?.signUp.signupFlavourText ||
                      `Before buying this policy you'll need to create an account, this keeps your information secure and in one place`
                    }
                  />
                )}
                <SignUpComponent
                  usernamePrefill={this.state.username}
                  passwordPrefill={this.state.password}
                  authenticationMethods={authenticationMethods}
                  authenticationProvider={defaultAuthenticationProvider}
                  onSubmit={({ username, password }) => {
                    this.setState((prevState: SignUpFormData) => {
                      return {
                        ...prevState,
                        username,
                        password
                      };
                    });
                    this.props.handleAuthSignUp(username, password);
                  }}
                  goToSignIn={this.goToSignIn}
                  authenticationConfig={
                    this.props.app.core.customConfig.userInterface
                      .authentication
                  }
                />
              </Scaffold>
            </Scaffold>
          </BladeSurface>
        </Scaffold>
      </BladePage>
    );
  }
}

export default appApi(track({})(SignUp));
