import * as React from 'react';
import {
  BladeView,
  BladeSurface,
  BladeMarkdown,
  BladeButton,
  useRunner,
  BladeMessage
} from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import css from './index.css';
import cx from 'classnames';
import { useGetPolicyBundle } from '@aventus/symphony-client/hooks/use-get-policy-bundle';
import { useTrack } from '@aventus/application-tracking';

export const IntelligentQuoteAdjustOverview: React.FunctionComponent<IIntelligentQuoteAdjustOverview> = props => {
  const { track } = useTrack();
  const { trim } = useRunner();

  const { policyBundle } = useGetPolicyBundle(props.policyId.trim());

  const canAdjustPolicy =
    policyBundle &&
    policyBundle.actions &&
    policyBundle.actions.find(
      action => action.referenceID === 'avt_wfs_policymta'
    );

  React.useEffect(() => {
    if (policyBundle){
      track({
        event: 'aventus.mta.overview',
        policyId: policyBundle?.policy.id,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
      });
    }
  }, [policyBundle]);

  if (policyBundle) {
    if (canAdjustPolicy && canAdjustPolicy.allowed) {
      return (
        <BladeView variant={'s'} className={css.adjustOverview}>
          <BladeSurface title={props.productConfiguration.quote.adjust.title}>
            <div
              className={cx(css.adjustOverview_description, {
                [css.adjustOverview_description_trimIsDivider]:
                  trim === 'divider'
              })}
            >
              <BladeMarkdown
                size={'base'}
                markdown={props.productConfiguration.quote.adjust.description}
              />

              {props.productConfiguration.product.recaptchaPrint && (
                <div className={css.recaptcha_print}>
                  <BladeMarkdown
                    size={'base'}
                    markdown={props.productConfiguration.product.recaptchaPrint}
                  />
                </div>
              )}
            </div>

            <div className={css.adjustOverview_action}>
              <BladeButton
                onClick={() =>
                  props.goToIntelligentQuoteAskForAdjust(props.policyId)
                }
              >
                {props.productConfiguration.quote.adjust.callToAction}
              </BladeButton>
            </div>
          </BladeSurface>
        </BladeView>
      );
    } else {
      var message = props.productConfiguration.quote.adjust.disableAdjustments;
      if (canAdjustPolicy && canAdjustPolicy.errorCode === 1140){
        message = props.productConfiguration.quote.adjust.disableAdjustmentOutstandingPayment;
      }

      return (
        <BladeView variant={'s'} className={css.adjustOverview}>
          <BladeMessage
            variant="info"
            message={message}
          />
        </BladeView>
      );
    }
  }

  return null;
};

export interface IIntelligentQuoteAdjustOverview {
  policyId: string;
  productConfiguration: InsuranceProductConfiguration;
  goToIntelligentQuoteAskForAdjust: (policyId: string) => void;
}
