export default ({ offTime }) => ({
  splash: {

    position: 'fixed',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 99999999,

    '&.off': {
      opacity: 0,
      transition: `all ${ offTime }s ease-in`
    },

    '&.remove': {
      display: 'none'
    }
  }
});
