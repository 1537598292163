import React from 'react';
import { BladePage, BladeSurface } from '@aventus/blade';
import { Scaffold } from '@honey/consume-react-jss';
import appApi from '../../../api';
import routes from '../../../routes';
import track from 'react-tracking';
import { updateProductRef } from '@aventus/pocketknife/dispatch-event';
import { AuthenticationConfiguration } from '@aventus/platform';
import CreateAccountComponent, {
  SetAccountPasswordFormData
} from './createAccountComponent';

class CreateAccountPassword extends React.Component<any> {
  success: { message: string; detail?: string | undefined } | undefined;
  authenticationConfig: AuthenticationConfiguration;
  description?: string;
  token = '';

  constructor(props: any) {
    super(props);

    this.token = this.props.match.params.token;
    this.authenticationConfig =
      this.props.app.core.customConfig.userInterface.authentication;
  }

  componentDidMount() {
    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.createAccountPassword.trackingName
    });

    this.props.tracking.trackEvent({
      event: 'aventus.createaccount.setpassword'
    });

    updateProductRef(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps: { app: { auth: any } }) {
    const authProps = this.props.app.auth;
    const nextAuthProps = nextProps.app.auth;
    if (
      !authProps._flags.successfullyCreatedAccountPassword &&
      nextAuthProps._flags.successfullyCreatedAccountPassword
    ) {
      this.props.handleQueueAlert({
        message: 'Your account was created',
        location: routes.signIn.path,
        type: 'success'
      });
      this.props.history.push(routes.signIn.path, { view: 'password' });
    }
  }

  render() {
    return (
      <BladePage title={routes.createAccountPassword.documentTitle}>
        <Scaffold orient={'col'} size={'large'}>
          <BladeSurface
            title={
              this.authenticationConfig?.postPurchaseAccountCreation.title ||
              'Create your account'
            }
            description={
              this.authenticationConfig?.postPurchaseAccountCreation
                .setPasswordDescription
            }
          >
            <Scaffold grow={true} orient={'col'} size={'large'}>
              <CreateAccountComponent
                onSubmit={({ password }) => {
                  this.setState((prevState: SetAccountPasswordFormData) => {
                    return {
                      ...prevState,
                      password
                    };
                  });
                  this.props.handleCreateAccountPassword(password, this.token);
                }}
                authenticationConfig={
                  this.props.app.core.customConfig.userInterface.authentication
                }
                _flags={this.props.app.auth._flags}
              />
            </Scaffold>
          </BladeSurface>
        </Scaffold>
      </BladePage>
    );
  }
}

export default appApi(track({})(CreateAccountPassword));
