import { AUTH_CREATE_ACCOUNT_PASSWORD_REQUEST, AUTH_CREATE_ACCOUNT_PASSWORD_SUCCESS, AUTH_CREATE_ACCOUNT_PASSWORD_FAILURE } from './types';
import { API_SYM, transformObject } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';

export default function createAccountPasswordAction(password, resetToken) {

  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/setaccountpassword',
        method: 'POST',
        transformRequest: [transformObject],
        data: {
          password,
          requestID: resetToken
        }
      },
      actions: [createAccountPasswordRequestAction, createAccountPasswordSuccessAction, createAccountPasswordFailureAction]
    }
  };
}

export function createAccountPasswordRequestAction() {
  return {
    type: AUTH_CREATE_ACCOUNT_PASSWORD_REQUEST
  };
}

export function createAccountPasswordSuccessAction() {
  return {
    type: AUTH_CREATE_ACCOUNT_PASSWORD_SUCCESS
  }
}

export function createAccountPasswordFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_CREATE_ACCOUNT_PASSWORD_FAILURE
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
