import * as React from 'react';
import {
  BladeFormFieldset,
  BladeInputText,
  BladeLink,
  BladeSelector,
  IBladeFormFieldset,
  IBladeInputText,
  BladeMessage
} from '@aventus/blade';
import css from './index.css';
import { useLookupAddress } from './use-lookup-address';
import { addressToHumanReadable } from '@aventus/pocketknife/address-to-human-readable';

export const AventusUkAddressLookup: React.FunctionComponent<IAventusUkAddressLookup> = props => {
  const { query, setQuery, addresses, isQuerying, error } = useLookupAddress(
    props.oracleUrl
  );

  const helperText =
    props.helperText || "Can't find your address? Enter it manually";

  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
    >
      <div className={css.addressLookup}>
        <BladeInputText
          variant={props.variant}
          name={props.name}
          id={props.name}
          value={query}
          onChange={setQuery}
          isLoading={isQuerying}
        />

        {addresses?.length > 0 && (
          <BladeSelector
            isScrollable={true}
            options={addresses.map(address => ({
              label: addressToHumanReadable(address),
              data: address
            }))}
            onSelect={option => {
              props.onChange({
                address1: option.data.address1,
                address2: option.data.address2,
                town: option.data.town,
                postalCode: option.data.postalCode,
                country: option.data.country
              });
            }}
          />
        )}

        {props.enterManually && (
          <BladeLink isSmall={true} onClick={() => props.enterManually?.()}>
            {helperText}
          </BladeLink>
        )}

        {error && <BladeMessage variant={'error'} message={error} />}
      </div>
    </BladeFormFieldset>
  );
};

export interface IAventusUkAddressLookup
  extends IBladeFormFieldset,
    IBladeInputText {
  enterManually?: () => void;
  oracleUrl: string;
  helperText?: string;
}
