import { v4 as uuidV4 } from 'uuid';
import { AUTH_SET_NONCE } from './types';

export default function setNonceAction() {
  const nonce = uuidV4();
  return {
    type: AUTH_SET_NONCE,
    nonce
  }
}
