import { AUTH_RESET_PASSWORD_REQUEST, AUTH_RESET_PASSWORD_SUCCESS, AUTH_RESET_PASSWORD_FAILURE } from './types';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import { API_SYM, transformObject } from '@aventus/middleware-api';

export default function resetPasswordAction(username) {

  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/forgottenpassword',
        method: 'POST',
        transformRequest: [transformObject],
        data: {
          username
        }
      },
      actions: [resetPasswordRequestAction, resetPasswordSuccessAction, resetPasswordFailureAction]
    }
  };
}

export function resetPasswordRequestAction() {
  return {
    type: AUTH_RESET_PASSWORD_REQUEST
  };
}

export function resetPasswordSuccessAction() {
  return {
    type: AUTH_RESET_PASSWORD_SUCCESS
  }
}

export function resetPasswordFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_RESET_PASSWORD_FAILURE
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
