import React, { ReactElement } from 'react';
import css from './index.css';

export const ShimOl: React.FunctionComponent<IShimOl> = ({ items }) => (
  <ol className={css.ol}>
    {items.map((child: ReactElement, i: number) =>
      React.cloneElement(child, { key: i })
    )}
  </ol>
);

interface IShimOl {
  items: ReactElement[];
}
