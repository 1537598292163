import React from 'react';
import styles from './styles.css';

interface PricePanelProps {
  annualSaving: string;
  monthlyPrice: string;
  annualPrice: string;
  percentageSaving: number;
  firstMonthlyPayment: string;
}

const PricePanel = (props: PricePanelProps) => (
  <>
    <div className={styles.absoluteContainer}>
      <div className={styles.flash}>
        <span>Save</span>
        <span>{props.annualSaving}</span>
      </div>

      <div className={styles.pricesContainer}>
        <div className={styles.priceDisplay}>
          <strong className={styles.planType}>Monthly</strong>
          <span className={styles.price}>{props.monthlyPrice}<span className={styles.asterisk}>*</span></span>
          <span className={styles.subText}>*First monthly payment<br/>of {props.firstMonthlyPayment}</span>
        </div>

        <div className={styles.priceDisplay}>
          <strong className={styles.planType}>Yearly</strong>
          <span className={styles.price}>{props.annualPrice}</span>
          <span className={styles.subText}>Per year, save {props.percentageSaving}%</span>
        </div>
      </div>
    </div>
  </>
);

export default PricePanel;
