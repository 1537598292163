import update from 'immutability-helper';

export default function clearAllAlertsReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        alerts: {
          alertsQueue: { $set: [] }
        }
      })
    }
  };
}
