import { MaxEndDateRules, parseValidationRules } from './parse-validation';
import { useEffect, useMemo, useState } from 'react';

import { IDateRange } from './types';
import moment from 'moment';

export const useDateRangeConstraints = (
  rawValidation: string[],
  { startDate, endDate }: IDateRange,
  startDateLocked: boolean
) => {
  const [permittedDateRange, setPermittedDateRange] = useState<{
    min: Date | null;
    max: Date | null;
  }>({ min: null, max: null });

  const validationRules = useMemo(
    () => parseValidationRules(rawValidation),
    [rawValidation]
  );

  useEffect(() => {
    if (endDate && !startDateLocked) {
      // when picking start date:
      const maxStartDate = validationRules.minDaysRange
        ? moment(validationRules.maxDate)
            .subtract(validationRules.minDaysRange, 'days')
            .toDate()
        : validationRules.maxDate;

      setPermittedDateRange({
        min: validationRules.minDate,
        max: maxStartDate
      });
    } else {
      // when picking end date:
      const maxEndDate = (() => {
        const maxDays = validationRules.maxDaysRange
          ? validationRules.maxDaysRange - 1
          : null;

        const maxRangeDate = moment(startDate).add(maxDays, 'days').toDate();

        if (
          !validationRules.maxDate ||
          validationRules.maxEndDateRule === MaxEndDateRules.MaxRange
        ) {
          return maxRangeDate;
        }

        return validationRules.maxDate < maxRangeDate
          ? validationRules.maxDate
          : maxRangeDate;
      })();

      const minEndDate = validationRules.minDaysRange
        ? moment(startDate)
            .add(validationRules.minDaysRange - 1, 'days')
            .toDate()
        : startDate;

      setPermittedDateRange({
        min: minEndDate,
        max: maxEndDate
      });
    }
  }, [startDate, endDate, validationRules, startDateLocked]);

  return permittedDateRange;
};
