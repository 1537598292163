import update from 'immutability-helper';
import validateEmail from '../../../../_module/validators/validate-email';
import validatePassword from '../../../../_module/validators/validate-password';

export default function validateCredentials(state, action) {

  const {
    key,
    value
  } = action;

  switch (key) {

    case 'username':
      return { 
        ...state,
        ...{ 
          auth: update(state.auth, {
            credentials: {
              $set: validateEmail(state.auth.credentials, key)
            }
          })
        }
      };

    case 'password':
    case 'oldPassword':
    case 'newPassword':
      return {
        ...state,
        ...{
          auth: update(state.auth, {
            credentials: {
              $set: validatePassword(state.auth.credentials, key, value)
            }
          })
        }
      };

    default: return state;
  }
}
