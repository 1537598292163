import * as React from 'react';

import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import css from './index.css';
import cx from 'classnames';

library.add(fas, fab, farExclamationCircle)

const convertIconName = (input:string | IconProp): IconProp => {
  if(typeof input !== 'string' || !input.startsWith('fa')) return input as IconProp
  if(input in fas) return [fas[input].prefix, fas[input].iconName]
  if(input in fab) return [fab[input].prefix, fab[input].iconName]
  return input as IconProp
}

export const BladeIcon: React.FunctionComponent<IBladeIcon> = props => {
  return (
    <div
      className={cx('blade-icon', props.classNameWrap)}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        props.onClick && props.onClick(event);
      }}
    >
      <FontAwesomeIcon
        style={props.style || {}}
        color={props.color}
        className={cx(
          props.className,
          props.onClick ? css.icon_isClickable : '',
          props.onClick && !props.ignoreTriggerStyle
            ? css.icon_isClickable_style
            : ''
        )}
        icon={convertIconName(props.name)}
      />
    </div>
  );
};

export interface IBladeIcon {
  /** Name of the Font Awesome icon to be rendered. */
  name: string | IconProp;
  /** Extend the CSS with custom classes. */
  className?: string;
  classNameWrap?: string;
  /** Make an icon clickable by defining an onClick fn. */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** Extend the styling with custom style. */
  style?: any;
  /** Define a fill colour for the icon. */
  color?: string;
  ignoreTriggerStyle?: boolean;
}
