import { IListItem } from "@aventus/blade/list";
import { useApplicationTimezone } from "@aventus/application-timezone";
import { getMotorPolicyDetails } from "./get-motor-risk-details";

export function getQuoteDetails(risk: any, productName: string, quoteReferenceID: string, isMotorPolicy: boolean, coverStartLabel?: string, coverEndLabel?: string) :IListItem[] {
    const { toHumanReadable } = useApplicationTimezone();

    // This will probably grow in future
    if(isMotorPolicy){
        return getMotorPolicyDetails(risk);
    }
    else{// Generic summary screen
        return [
            {
              label: 'Policy name',
              value: productName
            },
            {
              label: 'Quote reference',
              value: quoteReferenceID
            },
            {
              label: coverStartLabel || 'Cover starts',
              value: toHumanReadable(risk.coverStartDate)
            },
            {
              label: coverEndLabel || 'Cover ends',
              value: toHumanReadable(risk.coverEndDate)
            }
          ];
    }
    
}