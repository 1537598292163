import React from 'react';
import { AuthenticationProvider } from '@aventus/platform';
import SocialAuth from '../_social-auth';
import SocialAuthFirebase from '../_social-auth/firebase-google';

type ExternalProvidersProps = {
  authenticationMethods: string[];
  authenticationProvider: AuthenticationProvider;
  externalProviderLabel: string;
};

const ExternalProviders = ({
  authenticationMethods,
  authenticationProvider,
  externalProviderLabel
}: ExternalProvidersProps) => {
  return (
    <>
      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth type="google" label={externalProviderLabel} />
        )}
      {authenticationMethods.includes('google') &&
        authenticationProvider === 'Firebase' && (
          <SocialAuthFirebase type="google" label={externalProviderLabel} />
        )}
      {authenticationMethods.includes('facebook') &&
        authenticationProvider === 'Auth0' && (
          <SocialAuth type="facebook" label={externalProviderLabel} />
        )}
    </>
  );
};

export default ExternalProviders;
