import queueAlertAction from '../../../app/api/core/alerts/queue-alert/actions';

export default function asyncActionFailure(response = {}, passToAction = {}) {

  if (!passToAction.typeKeys || !passToAction.typeKeys.failure) {
    return false;
  }

  return (dispatch) => {

    dispatch({
      type: passToAction.typeKeys.failure,
      error: response.error,
      ...passToAction
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: response.message,
      location: false
    }));
  }

}
