import React from 'react';
import {
  BladeFormFieldset,
  BladeInputPassword,
  BladeLink
} from '@aventus/blade';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { SignInFormData } from './signInComponent';
import { AuthenticationConfiguration } from '@aventus/platform';

type PasswordFieldProps = {
  routes: any;
  errors: DeepMap<SignInFormData, FieldError>;
  control: Control<SignInFormData>;
  authenticationConfig: AuthenticationConfiguration;
  email: string;
};

const PasswordField: React.FC<PasswordFieldProps> = ({
  routes,
  errors,
  control,
  authenticationConfig
}) => {
  return (
    <BladeFormFieldset
      errorMessage={errors?.password && errors.password.message}
      addonBottom={
        <BladeLink size={'small'} url={routes.resetPassword.path}>
          {authenticationConfig?.signIn?.forgottenPasswordLinkMessage ||
            'Reset password?'}
        </BladeLink>
      }
    >
      <Controller
        name="password"
        control={control}
        rules={{
          required: {
            value: true,
            message:
              authenticationConfig?.signIn?.validationMessages
                ?.passwordRequired || 'Password is required'
          }
        }}
        render={({ onChange, onBlur, value }) => (
          <BladeInputPassword
            name="password"
            label={
              authenticationConfig?.signIn?.passwordLabel || 'Your password'
            }
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    </BladeFormFieldset>
  );
};

export default PasswordField;
