import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { BladeInputSelect } from '../select';
import { OPTIONS_DAYS, OPTIONS_MONTHS, generateYearOptions } from './_options';
import { useDate } from './use-date';
import css from './index.css';
import './date.css';

export const BladeInputDate: React.FunctionComponent<
  IBladeInputDate
> = props => {
  const inputProps = useInput(props);

  const { value, onChange } = useDate(
    props.value,
    inputProps.onChange,
    props.now
  );

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <div className={css.dateSelects}>
        <BladeInputSelect
          variant={props.variant}
          matchOn={'value'}
          labelOn={'text'}
          defaultLabel={'DD'}
          name={props.name + '-day'}
          value={inputProps.value ? { value: value.day } : 0}
          onChange={(day: any) => {
            onChange({ ...value, ...{ day: day.value } });
          }}
          onBlur={inputProps.onBlur}
          options={OPTIONS_DAYS}
          hideSearch={true}
        />

        <BladeInputSelect
          variant={props.variant}
          matchOn={'value'}
          labelOn={'text'}
          defaultLabel={'MM'}
          name={props.name + '-month'}
          value={inputProps.value ? { value: value.month } : 0}
          onChange={(month: any) => {
            onChange({ ...value, ...{ month: month.value } });
          }}
          onBlur={inputProps.onBlur}
          options={OPTIONS_MONTHS}
          hideSearch={true}
        />

        <BladeInputSelect
          variant={props.variant}
          matchOn={'value'}
          labelOn={'text'}
          defaultLabel={'YYYY'}
          name={props.name + '-year'}
          value={inputProps.value ? { value: value.year } : 0}
          onChange={(year: any) => {
            onChange({ ...value, ...{ year: year.value } });
          }}
          onBlur={inputProps.onBlur}
          options={generateYearOptions({})}
          hideSearch={true}
        />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputDate extends IBladeInput {
  now?: string;
}
