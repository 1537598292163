import { useState, useReducer, useEffect } from 'react';
import { isEmpty, head, cloneDeep } from 'lodash';

import {
  ITobesArrayAnswer,
  ITobesArrayAnswerItem
} from '@aventus/platform-client-context/models/tobes';
import uuid from 'uuid/v4';

export enum EFormMode {
  AddToCollection = 'add',
  EditInCollection = 'edit'
}

export function useCollection(
  collection: ITobesArrayAnswer[] | undefined,
  updateMainAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null,
    arrayQuestion?: boolean
  ) => void,
  questionReference: string
): IUseCollection {
  const [currentCollectionState, setCurrentCollectionState] = useState<
    ITobesArrayAnswer | undefined
  >(undefined);

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [collectionElements, setCollectionElements] = useState<
    ITobesArrayAnswer[] | []
  >(collection || []);

  const [formState, setFormState] = useState<
    { isOpen: boolean; mode: EFormMode } | undefined
  >(undefined);

  const updateMainAnswerSet = (collectionElements: ITobesArrayAnswer[]) => {
    updateMainAnswer(questionReference, collectionElements, null, true);
  };

  const prepareForm = (settings: {
    isOpen: boolean;
    mode: EFormMode;
    id?: string | null;
  }) => {
    if (!settings?.id) {
      setCurrentCollectionState({
        id: uuid(),
        answers: []
      });
    } else {
      const selectedCollection = collectionElements.filter(
        ({ id: colletionID }) => colletionID === settings.id
      );
      setCurrentCollectionState(head(selectedCollection));
    }
    setFormState({ isOpen: true, mode: settings.mode });
  };

  useEffect(() => {
    if (isEmpty(collectionElements)) {
      prepareForm({ isOpen: true, mode: EFormMode.AddToCollection });
    }
  }, [collectionElements]);

  const dismissForm = () => {
    setFormState(undefined);
    setCurrentCollectionState(undefined);
  };

  const submitForm = () => {
    if (formState?.mode === EFormMode.AddToCollection) {
      if (currentCollectionState) {
        setCollectionElements([...collectionElements, currentCollectionState]);
        updateMainAnswerSet([...collectionElements, currentCollectionState]);
      }
      dismissForm();
    } else if (formState?.mode === EFormMode.EditInCollection) {
      if (currentCollectionState) {
        let collectionState = (collectionElements as ITobesArrayAnswer[]).map(
          collectionElement => {
            if (collectionElement.id === currentCollectionState.id) {
              let selectedElement = collectionElement;
              selectedElement.answers = currentCollectionState.answers;
              return selectedElement;
            }
            return collectionElement;
          }
        );

        setCollectionElements(collectionState);
        updateMainAnswerSet(collectionState);
        dismissForm();
      }
    }
  };

  const removeFromCollection = (collectionID: string) => {
    const updatedList = collectionElements.filter(
      ({ id }) => id !== collectionID
    );
    setCollectionElements(updatedList);
    updateMainAnswerSet(updatedList);
  };

  const isAnswerInCollection = (referenceID: string) =>
    isEmpty(
      currentCollectionState?.answers.filter(
        answer => answer.questionReferenceID === referenceID
      )
    );

  const getCollectionAnswer = (referenceID: string) => {
    if (currentCollectionState) {
      const _answer = currentCollectionState.answers.filter(
        ({ questionReferenceID }) => referenceID === questionReferenceID
      );
      return head(_answer)?.answer;
    }
    return null;
  };

  const updateColletion = (referenceID: string, value: any) => {
    if (isAnswerInCollection(referenceID)) {
      let collectionElement = cloneDeep(currentCollectionState);
      if (collectionElement) {
        collectionElement.answers = [
          ...collectionElement.answers,
          {
            questionReferenceID: referenceID,
            answer: value,
            fields: [],
            arrayItems: []
          }
        ];
        setCurrentCollectionState(collectionElement);
      }
    } else {
      let collectionElement = cloneDeep(currentCollectionState);
      if (collectionElement) {
        collectionElement.answers = collectionElement?.answers.map(
          (answer: ITobesArrayAnswerItem) =>
            answer.questionReferenceID === referenceID
              ? { ...answer, answer: value }
              : answer
        );

        if (collectionElement) {
          setCurrentCollectionState(collectionElement);
        }
      }
    }
    forceUpdate();
  };

  return {
    prepareForm,
    dismissForm,
    submitForm,
    formState,
    collectionElements,
    updateColletion,
    getCollectionAnswer,
    removeFromCollection,
    collectionElementsSize: collectionElements.length
  };
}

export interface IUseCollection {
  formState: { isOpen: boolean; mode?: EFormMode } | undefined;
  collectionElements: ITobesArrayAnswer[];
  collectionElementsSize: number;
  submitForm: () => void;
  dismissForm: () => void;
  prepareForm: (settings: {
    isOpen: boolean;
    mode: EFormMode;
    id?: string | null;
  }) => void;
  removeFromCollection: (collectionID: string) => void;
  getCollectionAnswer: (referenceID: string, collectionID: string) => any;
  updateColletion: (referenceID: string, value: any) => void;
}
