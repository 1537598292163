import * as React from 'react';
import { BladeMarkdown, BladeFormFieldset } from '@aventus/blade';
import cx from 'classnames';
import css from './index.css';

export const BladeTextBlock: React.FunctionComponent<IBladeTextBlock> = props => {
  return (
    <BladeFormFieldset question={props.question ? props.question : undefined}>
      {props.detail && (
        <BladeMarkdown
          className={cx(css.textBlockContent, {
            [css.textBlockContentNoPaddingTop]: props.question
          })}
          size={'base'}
          markdown={props.detail}
        />
      )}
    </BladeFormFieldset>
  );
};

export interface IBladeTextBlock {
  question: string | null;
  detail: Nullable<string>;
}
