import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  ICoverage,
  IFinanceFee,
  IFinanceNetLoadingDiscount,
  IPolicyDetail,
  IPolicyDetailEditSession,
  IPolicyDetailHeader,
  IPriceHeader,
  ISection,
  Nullable,
  INewEndorsement,
  INewEndorsementResponse
} from '@aventus/platform-client-context/models/quote-edit-session';
import {
  IQuoteController,
  IPolicyController
} from '@aventus/platform-client-context/controllers';
import {
  IAddSubsection,
  IUpdateSubsection
} from '@aventus/platform-client-context/models/quote-builder';
import {
  ITobesAnswer,
  ITobeStartSuccess,
  IHybridCreate
} from '@aventus/platform-client-context/models/tobes';
import { IProduct } from '@aventus/platform-client-context/models/product';
import { ITobesController } from '@aventus/platform-client-context/controllers/tobes';
import { IQuote } from '@aventus/platform-client-context/models/quote';
import { IQuickQuoteController } from '@aventus/platform-client-context/controllers/quick-quote';

type TNullable<T> = T | null;

export type TAddCoverage = Pick<
  ICoverage,
  'declaredAmount' | 'name' | 'referenceID' | 'sumInsured'
> & { subsectionID: string };

export type TAddCustomCoverage = Pick<
  ICoverage,
  'declaredAmount' | 'name' | 'rateMultiplier' | 'sumInsured' | 'type'
> & { subsectionID: string };

export type TUpdateCoverage = Omit<
  ICoverage,
  'premium' | 'baseRateMultiplier' | 'baseRatedAmount' | 'id'
>;

export type TAddFee = Pick<IFinanceFee, 'name' | 'referenceID' | 'net'> & {
  taxRuleSetReferenceID: string;
};

export type TUpdateSection = Pick<ISection, 'name' | 'schemeID'> & {
  commission?: IUpdateCommission;
  netLoadingDiscounts?: Nullable<IFinanceNetLoadingDiscount[]>;
};

export interface IUpdateCommission {
  name: TNullable<string>;
  referenceID: TNullable<string>;
  ruleAmount: TNullable<number>;
}

export interface IQuestionAnswerOption {
  active: boolean;
  answeredText: TNullable<string>;
  listReferenceID: TNullable<string>;
  referenceID: TNullable<string>;
  text: TNullable<string>;
}

type ConditionType = 'Boolean' | 'ListItem';

export interface IQuestionCondition {
  conditionType: ConditionType;
  conditionValue: TNullable<string>;
  questionReferenceID: TNullable<string>;
}

export type QuestionDataSource = 'None' | 'Default' | 'User' | 'Oracle';

export type QuestionDataType =
  | 'NotSet'
  | 'String'
  | 'Integer'
  | 'Decimal'
  | 'Boolean'
  | 'DateTime'
  | 'LocalDateTime'
  | 'Object'
  | 'Array';

export interface IQuestionText {
  detail: TNullable<string>;
  footer: TNullable<string>;
  help: TNullable<string>;
  question: TNullable<string>;
  quickflow: TNullable<string>;
  summary: TNullable<string>;
}

export interface IQuestionFieldValidation {
  message: TNullable<string>;
  referenceID: string;
}

export interface IQuestionAnswer {
  answer: TNullable<unknown>;
  fields: TNullable<IQuestionAnswer[]>;
  questionReferenceID: TNullable<string>;
}

export interface ICreateQuote {
  answers: TNullable<IQuestionAnswer[]>;
  partnerID: TNullable<string>;
  partnerReference: TNullable<string>;
  productReferenceID: string;
}

export interface IQuestion {
  answerOptions: IQuestionAnswerOption[];
  arrayPage: number;
  conditions: IQuestionCondition[];
  dataSource: QuestionDataSource;
  dataType: QuestionDataType;
  existingValue: TNullable<string>;
  fields: TNullable<IQuestion[]>;
  questionType: TNullable<string>;
  referenceID: string;
  text: IQuestionText;
  validation: IQuestionFieldValidation[];
}

export interface INewQuoteQuestionPage {
  title: TNullable<string>;
  description: TNullable<string>;
  number: number;
  questions: IQuestion[];
}

export type QuestionSetType =
  | 'None'
  | 'Core'
  | 'CoreQuickQuote'
  | 'Secondary'
  | 'Exposure'
  | 'ExposureQuickQuote';

export interface IQuestionSet {
  name: string;
  questionSetType: QuestionSetType;
  referenceID: string;
}

export interface IProductConfiguration {
  newQuoteQuestionPage: INewQuoteQuestionPage;
  coreQuestionSets: IQuestionSet[];
  exposures: IExposure[];
}

export interface IExposure {
  referenceID: string;
  name: string;
  questionSets: IQuestionSet[];
}

export interface IOpportunityCreate {
  opportunity: {
    id: string;
    dateCreated: string;
    name: string;
    description: string;
    dateClosed: string;
    opportunityStatus: string;
  };
  variant: {
    id: string;
    opportunityID: string;
    name: string;
    description: string;
    dateCreated: string;
    policyDetailHeaderID: string;
  };
  questionSetReferenceID: string;
  sessionID: string;
}

export interface IValidateSession {
  validationErrors: IValidationError[];
  session: IPolicyDetailEditSession;
}

export interface ICompleteSessionResponse {
  data: ICompleteSessionData | null;
  validationErrors: IValidationError[] | [];
}

export interface ICompleteSessionData {
  id: string;
  oppertunityID: string;
  name: string;
  description: string;
  dateCreated: string;
  policyDetailHeaderID: string;
}

export interface IValidationError {
  message: Nullable<string>;
  propertyIdentifier: Nullable<string>;
}

export interface IOrganisationSettings {
  currencyCode: string;
  currencySymbol: string;
  timezone: string;
  defaultCountryCode: string;
  dateFormat: string;
}

/**
 * This quote bundle will likely change
 * into something that works across both
 * models. Only typed what is needed so far.
 */
export interface IV7QuoteBundle {
  quoteDetails: {
    quote: {
      id: string;
    };
    risk: {
      core: TNullable<unknown>;
      exposures: TNullable<unknown>;
    };
    policyDetail: IPolicyDetail;
  };
  product: TNullable<unknown>;
  paymentPlans: TNullable<unknown>;
}

export interface IProductAPI {
  getProductConfiguration: (
    productReference: string
  ) => Promise<IProductConfiguration>;

  getVariantQuotes: (variantID: string) => Promise<IQuote[] | []>;

  getHybridProduct: (productID: string) => any;

  getProductConfigurationById: (
    productId: string
  ) => Promise<IProductConfiguration>;

  getProductById: (
    productId: string,
    coverType?: Nullable<string>
  ) => Promise<IProduct>;

  getProductByRef: (
    productRef: string,
    coverType?: Nullable<string>
  ) => Promise<IProduct>;
}

export interface IVariantEditSessionsAPI {
  getEditSession: (editSessionId: string) => Promise<IPolicyDetailEditSession>;
  completeSession: (editSessionId: string) => Promise<ICompleteSessionResponse>;
  discardSession: (editSessionId: string) => Promise<IPolicyDetailEditSession>;
  validateSession: (editSessionId: string) => Promise<IValidateSession>;
  removeExposure: (editSessionId: string, exposureId: string) => Promise<any>
}

export interface IOpportunityAPI {
  createOpportunity: (
    openSession: boolean,
    productReferenceID: string,
    name?: string,
    description?: string,
    partnerID?: string,
    partnerReference?: string,
    answers?: ITobesAnswer[]
  ) => Promise<IOpportunityCreate>;
}

export interface IHybridAPI {
  hybridCreate: (
    productReferenceID: string,
    externalID: string
  ) => Promise<IHybridCreate>;
  hybridStartEditSession: (hybridID: string) => any;
  hybridTobes: (
    editSessionID: string,
    questionSetReferenceID: string,
    exposureReferenceID?: string | null,
    exposureID?: string | null
  ) => Promise<ITobeStartSuccess>;
}

export interface IQuoteBuilderAPI {
  addCoverage: (
    editSessionID: string,
    data: TAddCoverage
  ) => Promise<IPolicyDetailEditSession>;
  updateCoverage: (
    editSessionID: string,
    coverageID: string,
    data: TUpdateCoverage
  ) => Promise<IPolicyDetailEditSession>;
  deleteCoverage: (
    editSessionID: string,
    coverageID: string
  ) => Promise<IPolicyDetailEditSession>;
  addCustomCoverage: (
    editSessionID: string,
    data: TAddCustomCoverage
  ) => Promise<IPolicyDetailEditSession>;

  addFee: (
    editSessionId: string,
    data: TAddFee
  ) => Promise<IPolicyDetailEditSession>;
  updateFee: (
    editSessionId: string,
    feeId: string,
    data: TAddFee
  ) => Promise<IPolicyDetailEditSession>;
  deleteFee: (
    editSessionId: string,
    feeId: string
  ) => Promise<IPolicyDetailEditSession>;
  addSubsection: (
    editSessionID: string,
    data: IAddSubsection
  ) => Promise<IPolicyDetailEditSession>;
  updateSubsection: (
    editSessionID: string,
    subsectionID: string,
    data: IUpdateSubsection
  ) => Promise<IPolicyDetailEditSession>;
  deleteSubsection: (
    editSessionID: string,
    subsectionID: string
  ) => Promise<IPolicyDetailEditSession>;

  updateSection: (
    editSessionID: string,
    sectionId: string,
    section: TUpdateSection
  ) => Promise<IPolicyDetailEditSession>;

  addEndorsement: (
    endorsment: INewEndorsement
  ) => Promise<INewEndorsementResponse>;
}

export interface IConfigApi {
  organisation: () => Promise<IOrganisationSettings>;
}

export interface IPlatformClient {
  token?: string;
  client: AxiosInstance;

  /**
   * TODO: Move these functions to a
   * quoteEditSession property to keep
   * things clean/grouped
   */
  startSession: (
    userId?: string,
    token?: string
  ) => Promise<string | undefined>;
  startEditSession: (quoteId: string) => Promise<IPolicyDetailEditSession>;
  getActiveEditSessions: (
    quoteId: string
  ) => Promise<IPolicyDetailEditSession[]>;
  saveEditSession: (editSessionId: string) => Promise<IPolicyDetailHeader>;
  rerateEditSession: (
    editSessionId: string
  ) => Promise<IPolicyDetailEditSession>;
  priceEditSession: (editSessionId: string) => Promise<IPriceHeader>;
  discardEditSession: (
    editSessionId: string
  ) => Promise<IPolicyDetailEditSession>;
  getEditSession: (editSessionId: string) => Promise<IPolicyDetailEditSession>;

  product: IProductAPI;
  quoteBuilder: IQuoteBuilderAPI;
  tobes: ITobesController;
  hybrid: IHybridAPI;
  quote: IQuoteController;
  quickQuote: IQuickQuoteController;
  policy: IPolicyController;
  variantEditSession: IVariantEditSessionsAPI;
  configuration: IConfigApi;
}

export type TPlatformResponse = IPolicyDetailEditSession;

type TCreatePlatformClient = (
  token?: string,
  apiUrl?: string,
  version?: string,
  options?: AxiosRequestConfig
) => IPlatformClient;

export const createPlatformClient: TCreatePlatformClient = (
  token?: string,
  apiUrl = '/api',
  version = 'v3',
  options = {}
) => {
  const platform = axios.create({
    ...options,
    ...{
      timeout: 30000,
      baseURL: `${apiUrl}/${version}`,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  });

  // This is here to make calls to start session, these don't exist on v3
  const nonVersionedApi = axios.create({
    ...options,
    ...{
      timeout: 30000,
      baseURL: `${apiUrl}`,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  });

  const startSession = async (
    userId?: string,
    token?: string
  ): Promise<string> => {
    let sessionStartUrl = `session/start`;

    if (userId) {
      sessionStartUrl += `?subjectUserID=${userId}`;
    }

    const response = await nonVersionedApi.get(sessionStartUrl, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data.data;
  };

  const startEditSession = async (
    quoteId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.get(`quote/editsessions/${quoteId}/start`);

    return response.data.data;
  };

  const getActiveEditSessions = async (
    quoteId: string
  ): Promise<IPolicyDetailEditSession[]> => {
    const response = await platform.get(`quote/editsessions/${quoteId}/active`);

    return response.data.data;
  };

  const saveEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailHeader> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/save`
    );

    return response.data.data;
  };

  const rerateEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/rerate`
    );

    return response.data.data;
  };

  const priceEditSession = async (
    editSessionId: string
  ): Promise<IPriceHeader> => {
    const response = await platform.get(
      `quote/editsessions/${editSessionId}/paymentplans`
    );
    return response.data.data;
  };

  const discardEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/discard`
    );

    return response.data.data;
  };

  const getEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.get(`quote/editsessions/${editSessionId}`);

    return response.data.data;
  };

  const product: IProductAPI = {
    getProductConfiguration: async (productReference: string) => {
      const response = await platform.get(
        `products/configuration?productReference=${productReference}`
      );
      return response.data.data;
    },

    getHybridProduct: async productID => {
      const response = await platform.get(
        `products/configuration/${productID}`
      );
      return response.data.data;
    },

    getVariantQuotes: async (variantID: string) => {
      const response = await platform.get(`variants/${variantID}/quotes`);
      return response.data.data;
    },

    getProductConfigurationById: async (productId: string) => {
      const response = await platform.get(
        `products/configuration/${productId}`
      );
      return response.data.data;
    },

    getProductById: async (productId: string, coverType?: Nullable<string>) => {
      let url = `products/${productId}`;

      if (coverType && coverType != null) {
        url += `?coverType=${coverType}`;
      }

      const response = await platform.get(url);
      return response.data.data;
    },

    getProductByRef: async (
      productRef: string,
      coverType?: Nullable<string>
    ) => {
      let url = `products?productReference=${productRef}`;

      if (coverType && coverType != null) {
        url += `&coverType=${coverType}`;
      }

      const response = await platform.get(url);
      return response.data.data;
    }
  };

  const configuration: IConfigApi = {
    organisation: async () => {
      const response = await platform.get('config/organisation');
      return response.data.data;
    }
  };

  const opportunity: IOpportunityAPI = {
    createOpportunity: async (
      openSession = true,
      productReferenceID,
      name,
      description,
      partnerID,
      partnerReference,
      answers
    ) => {
      const response = await platform.post(`opportunities/create`, {
        openSession,
        productReferenceID,
        name,
        description,
        partnerID,
        partnerReference,
        answers
      });
      return response.data.data;
    }
  };

  const variantEditSession: IVariantEditSessionsAPI = {
    getEditSession: async (editSessionId: string) => {
      const response = await platform.get(
        `variant/editsessions/${editSessionId}`
      );
      return response.data.data;
    },

    /* This is a very very special case
    We need the validation errors that come out of
    a 400 call
    */
    completeSession: async (editSessionId: string) => {
      const response = await platform.post(
        `variant/editsessions/${editSessionId}/complete`
      );

      return response.data;
    },
    discardSession: async (editSessionId: string) => {
      const response = await platform.post(
        `variant/editsessions/${editSessionId}/discard`
      );
      return response.data.data;
    },
    validateSession: async (editSessionId: string) => {
      const response = await platform.get(
        `variant/editsessions/${editSessionId}/validated`
      );
      return response.data.data;
    },
    removeExposure: async (editSessionID: string, exposureId: string) => {
      const response = await platform.delete(
        `quotebuilder/exposures/${exposureId}`, { data: { editSessionID } }
      );
      return response.data.data;
    },
  };

  const hybrid: IHybridAPI = {
    hybridCreate: async (productReferenceID: string, externalID: string) => {
      const response = await platform.post(`quotes/create`, {
        productReferenceID,
        externalID
      });
      return response.data.data;
    },
    hybridStartEditSession: async hybridID => {
      const response = await platform.get(
        `quote/editsessions/${hybridID}/start`
      );
      return response.data.data;
    },
    hybridTobes: async (
      editSessionID,
      questionSetReferenceID,
      exposureReferenceID,
      exposureID
    ) => {
      const response = await platform.post(`tobes/start`, {
        data: { questionSetReferenceID, exposureReferenceID, exposureID },
        editSessionID
      });
      return response.data.data;
    }
  };

  const tobes: ITobesController = {
    getQuestionPage: async (
      exposureID,
      page,
      questionSetReferenceID,
      editSessionID
    ) => {
      const response = await platform.post(`tobes/questionpage`, {
        data: {
          questionSetReferenceID,
          exposureID,
          page
        },
        editSessionID
      });
      return response.data.data;
    },
    tobesPrice: async (
      editSessionID: string,
      page: number,
      questionSetReferenceID: string | null,
      exposureID: string | null,
      answers: any
    ) => {
      const response = await platform.post(`tobes/6/price`, {
        data: {
          exposureID,
          questionSetReferenceID,
          page,
          answers
        },
        editSessionID
      });
      return response.data.data;
    },

    tobesMTAPrice: async (
      editSessionID: string,
      page: number,
      questionSetReferenceID: string | null,
      exposureID: string | null,
      answers: any
    ) => {
      const response = await platform.post(`tobes/6/mta/price`, {
        data: {
          exposureID,
          questionSetReferenceID,
          page,
          answers
        },
        editSessionID
      });
      return response.data.data;
    },
    tobesMTAQuote: async (editSessionId: string, confirmQuote: boolean) => {
      const response = await platform.post(`tobes/6/mta/quote`, {
        data: {
          confirmQuote: confirmQuote
        },
        editSessionID: editSessionId
      });
      return response.data.data;
    },

    confirmQuote: async editSessionID => {
      const response = await platform.post(`tobes/6/quote`, {
        data: {
          confirmQuote: true
        },
        editSessionID
      });
      return response.data.data;
    },
    tobesStart: async (
      editSessionID,
      questionSetReferenceID,
      exposureReferenceID,
      exposureID
    ) => {
      const response = await platform.post(`tobes/start`, {
        data: {
          exposureReferenceID,
          exposureID,
          questionSetReferenceID
        },
        editSessionID
      });
      return response.data.data;
    },
    tobesSubmit: async (
      editSessionID,
      page,
      questionSetReferenceID,
      answers,
      exposureID = null
    ) => {
      const response = await platform.post(`tobes/submit`, {
        data: { answers, exposureID, page, questionSetReferenceID },
        editSessionID
      });
      return response.data.data;
    }
  };

  const quoteBuilder: IQuoteBuilderAPI = {
    addCoverage: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/coverages`, {
        editSessionID,
        data
      });
      return response.data.data;
    },
    updateCoverage: async (editSessionID, coverageID, data) => {
      const response = await platform.put(
        `quotebuilder/coverages/${coverageID}`,
        {
          editSessionID,
          data
        }
      );
      return response.data.data;
    },
    deleteCoverage: async (editSessionID, coverageID) => {
      const response = await platform.delete(
        `quotebuilder/coverages/${coverageID}`,
        {
          data: {
            editSessionID
          }
        }
      );
      return response.data.data;
    },
    addCustomCoverage: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/coverages/addcustom`, {
        editSessionID,
        data
      });
      return response.data.data;
    },

    addFee: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/fees`, {
        editSessionID,
        data
      });
      return response.data.data;
    },

    updateFee: async (editSessionID, feeId, data) => {
      const response = await platform.put(`quotebuilder/fees/${feeId}`, {
        editSessionID,
        data
      });
      return response.data.data;
    },

    deleteFee: async (editSessionID, feeId) => {
      const response = await platform.delete(`quotebuilder/fees/${feeId}`, {
        data: { editSessionID: editSessionID }
      });
      return response.data.data;
    },

    updateSection: async (editSessionId, sessionId, data) => {
      const response = await platform.put(
        `quotebuilder/sections/${sessionId}`,
        {
          editSessionId,
          data
        }
      );
      return response.data.data;
    },

    addSubsection: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/subsections`, {
        editSessionID: editSessionID,
        data: data
      });

      return response.data.data;
    },

    updateSubsection: async (editSessionID, subsectionID, data) => {
      const response = await platform.put(
        `quotebuilder/subsections/${subsectionID}`,
        {
          editSessionID,
          data
        }
      );

      return response.data.data;
    },

    deleteSubsection: async (editSessionID, subsectionID) => {
      const response = await platform.delete(
        `quotebuilder/subsections/${subsectionID}`,
        {
          data: {
            editSessionID
          }
        }
      );

      return response.data.data;
    },

    addEndorsement: async (
      endorsment: INewEndorsement
    ): Promise<INewEndorsementResponse> => {
      const response = await platform.post(`endorsements`, endorsment);

      return response.data.data;
    }

    /**
     * NOTE FOR SYMPHONY WE HAVE REMOVED MOCKS
     */
  };

  const policy: IPolicyController = {
    getPolicy: async policyID => {
      const response = await platform.get(`policies/${policyID}`);
      return response.data.data;
    }
  };

  const quickQuote: IQuickQuoteController = {
    confirmQuickQuote: async editSessionID => {
      const response = await platform.post(
        `quickquotes/${editSessionID}/complete`
      );
      return response.data.data;
    }
  };

  const quote: IQuoteController = {
    getQuote: async quoteID => {
      const response = await platform.get(`quotes/${quoteID}`);
      return response.data.data;
    },
    confirmQuote: async quoteID => {
      const response = await platform.post(`quotes/${quoteID}/confirm`);
      return response.data.data;
    },
    getDocuments: async (quoteID, historyID) => {
      const response = await platform.get(
        `quotes/${quoteID}/documents${
          historyID ? '&historyID=' + historyID : ''
        }`
      );
      return response.data.data;
    },
    createQuote: async quoteData => {
      const response = await platform.post('quotes/create', quoteData);
      return response.data.data;
    },
    createMTAQuote: async (policyId: string) => {
      const response = await platform.post(
        `quotes/hybrid/${policyId}/createmta`
      );
      return response.data.data;
    },
    editHybridQuote: async (quoteId: string) => {
      const response = await platform.get(`quotes/hybrid/${quoteId}/edit`);
      return response.data.data;
    },
    convertQuote: async (quoteID, paymentPlanRef, voucherCode = undefined) => {
      const response = await platform.get(
        `debug/quote/${quoteID}/convert?paymentPlanRef=${
          paymentPlanRef || ''
        }&voucherCode=${voucherCode || ''}`
      );
      return response.data.data;
    }
  };

  return {
    client: platform,
    startEditSession,
    getActiveEditSessions,
    saveEditSession,
    rerateEditSession,
    priceEditSession,
    discardEditSession,
    getEditSession,
    product,
    variantEditSession,
    tobes,
    hybrid,
    quote,
    policy,
    quoteBuilder,
    opportunity,
    configuration,
    startSession,
    quickQuote
  };
};

export default createPlatformClient;
