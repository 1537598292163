import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest, ProductReference, ProductCoverReference } from '@aventus/platform';
import { InvalidProductError } from '@aventus/errors';


export const useStartIntelligentQuoteForNew: UseStartIntelligentQuoteForNew = function (
  productReference,
  productCoverReference,
  quoteId,
  partnerId,
  isContinuing,
  voucherCode,
  partnerReference
) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [intelligentQuoteRequest, setIntelligentQuoteRequest] = React.useState<IntelligentQuoteRequest | undefined>(undefined);
  const [isStartingIntelligentQuoteForNew, setIsStartingIntelligentQuoteForNew] = React.useState<boolean>(false);
  const [invalidProductError, setInvalidProductError] = React.useState<any>();

  React.useEffect(() => {
    (async function () {

      if (
        ((productReference && productCoverReference) || quoteId) &&
        !isContinuing && !isStartingIntelligentQuoteForNew
      ) {

        setIsStartingIntelligentQuoteForNew(true);

        try {

          const _intelligentQuoteRequest = await symphony.startIntelligentQuoteForNew(
            productReference,
            productCoverReference,
            quoteId,
            partnerId,
            voucherCode,
            partnerReference
          );

          setIntelligentQuoteRequest(_intelligentQuoteRequest);

        } catch (error) {
          if (error instanceof InvalidProductError) {
            setInvalidProductError(error);
          }
          else{
            throwError(error);
          }
        }

        setIsStartingIntelligentQuoteForNew(false);

      }

    }());
  }, [productReference, productCoverReference, quoteId]);

  return {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForNew,
    invalidProductError
  };

}

export type UseStartIntelligentQuoteForNew = (
  productReference?: ProductReference,
  productCoverReference?: ProductCoverReference,
  quoteId?: string,
  partnerId?: string,
  isContinuing?: boolean,
  voucherCode?: string,
  partnerReference?: string
) => {
  intelligentQuoteRequest: IntelligentQuoteRequest | undefined,
  isStartingIntelligentQuoteForNew: boolean,
  invalidProductError: any
}
