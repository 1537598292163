import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle } from '@aventus/platform';

export const useGetQuoteRenewal: UseGetQuoteRenewal = function (
  activeRenewalQuoteID
) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [quoteBundle, setQuoteBundle] = React.useState<QuoteBundle | undefined>(
    undefined
  );
  const [
    isGettingQuoteRenewalBundle,
    setIsGettingQuoteRenewalBundle
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {
      if (activeRenewalQuoteID) {
        setIsGettingQuoteRenewalBundle(true);

        try {
          const _quoteBundle = await symphony.getQuoteRenewal(
            activeRenewalQuoteID
          );
          setQuoteBundle(_quoteBundle);
        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteRenewalBundle(false);
      }
    })();
  }, [activeRenewalQuoteID]);

  return {
    quoteBundle,
    isGettingQuoteRenewalBundle
  };
};

export type UseGetQuoteRenewal = (
  activeRenewalQuoteID?: string
) => {
  quoteBundle: QuoteBundle | undefined;
  isGettingQuoteRenewalBundle: boolean;
};
