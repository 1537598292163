import * as React from 'react';
import { BladeIcon } from '../icon';
import css from './index.css';
import cx from 'classnames';

export const BladeAppNav: React.FunctionComponent<IBladeAppNav> = ({
  isOpen,
  close,
  config,
  footer,
  version
}) => (
  <div
    className={cx('blade-app-nav', {
      [css.navWrap_isOpen]: isOpen,
      [css.navWrap_isClosed]: !isOpen
    })}
  >
    {isOpen && <div className={css.appNav_underlay} onClick={() => close()} />}

    <div
      className={cx(css.appNav, {
        [css.appNav_isOpen]: isOpen,
        [css.appNav_isClosed]: !isOpen
      })}
    >
      <div className={css.appNav_sections}>
        {config.map(({ ignore, title, subTitle, items }, i) => {
          if (ignore) {
            return null;
          }

          return (
            <div key={i} className={css.appNav_section}>
              <div className={css.appNav_section_heading}>
                {title && <h3>{title}</h3>}

                {subTitle && (
                  <small className={css.appNav_section_heading_smallText}>
                    {subTitle}
                  </small>
                )}
              </div>

              {items.map(
                ({ ignore: navItemIgnore, onClick, icon, label }, i) => {
                  if (navItemIgnore) {
                    return null;
                  }

                  return (
                    <div
                      key={i}
                      className={css.appNav_navItem}
                      onClick={() => {
                        close();
                        onClick();
                      }}
                    >
                      {icon && <BladeIcon name={icon} />}

                      <label>{label}</label>

                      <BladeIcon name={'faCaretRight'} />
                    </div>
                  );
                }
              )}
            </div>
          );
        })}

        {footer && (
          <div className={css.appNav_footer}>
            <small className={css.appNav_section_heading_smallText}>
              {footer}
            </small>
          </div>
        )}
      </div>

      {version && (
        <div className={css.appNav_version}>
          <small>{version}</small>
        </div>
      )}
    </div>
  </div>
);

interface IBladeAppNav {
  /** If `true`, this will open up the full page overlay and the navigation menu. */
  isOpen: boolean;
  /** Function called to close the nav. It should ideally toggle whatever is bound to `isOpen`. */
  close: () => void;
  /** */
  config: Array<IConfig>;
  /** Space all the nav items from each other and within the frame. */
  size?: string;
  /** Render some footer text at the bottom of the nav component. */
  footer?: string;
  /** Version to render in footer of nav component */
  version?: string;
}

interface IConfig {
  title?: string;
  subTitle?: string;
  items: Array<IConfigItem>;
  ignore?: boolean;
}

interface IConfigItem {
  label: string;
  icon?: string;
  onClick: () => void;
  ignore?: boolean;
}
