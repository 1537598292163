import { GET_CUSTOM_CONFIG_REQUEST, GET_CUSTOM_CONFIG_SUCCESS, GET_CUSTOM_CONFIG_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import { track, setAppReady, setExternalId } from '../../index';
import updatePrivacyPolicyVersionAction from '../update-privacy-policy-version/actions';
import queueAlertAction from '../alerts/queue-alert/actions';

export default function getCustomConfigAction () {
  return (dispatch, getStore) => {
    dispatch({
      type: 'API_REQUEST',
      [API_SYM]: {
        requestConfig: { url: 'config/app' },
        actions: [
          getCustomConfigRequest,
          getCustomConfigSuccess,
          getCustomConfigFailure
        ]
      }
    });
  };
}

const getCustomConfigRequest = (action) => {
  return {
    type: GET_CUSTOM_CONFIG_REQUEST
  };
}

const getCustomConfigSuccess = (res) => {
  return (dispatch, getStore) => {

    dispatch({
      type: GET_CUSTOM_CONFIG_SUCCESS,
      customConfig: res
    });

    dispatch(setExternalId());
    dispatch(track('initialise', null, { externalId: getStore().app.core.tracking.externalId }));
    dispatch(setAppReady());
    dispatch(updatePrivacyPolicyVersionAction())
  };
}

const getCustomConfigFailure = (res) => {
  return (dispatch, getStore) => {
    dispatch({
      type: GET_CUSTOM_CONFIG_FAILURE,
    });

    dispatch(queueAlertAction({
      type: 'error',
      message: response.message,
      location: getStore().routing.location.pathname
    }));
  }
}
