import { APP_SET_GUARD_ROUTE, APP_RESET_GUARD_ROUTE } from './types';

export function setRouteGuardAction() {
  return {
    type: APP_SET_GUARD_ROUTE
  };
}

export function resetRouteGuardAction() {
  return {
    type: APP_RESET_GUARD_ROUTE
  };
}