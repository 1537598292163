import * as React from 'react';
import css from './index.css';
import cx from 'classnames';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';
import { useMediaQuery } from '@aventus/pockethooks';

export const PolicyListMessage: React.FunctionComponent<IPolicyListMessage> = props => {
  const { applicationConfiguration } = useGetApplicationConfiguration();
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  return (
    <div
      className={cx(css.policyListMessage, {
        [css.policyListMessage_empty_list]: props.isPolicyListEmpty,
        [css.policyListMessage_mobile]: matchesQuery,
        [css.policyListMessage_mobile_empty]:
          matchesQuery && props.isPolicyListEmpty
      })}
    >
      <span className={cx(css.policyListMessage_welcome)}>Hi there!</span>
      {applicationConfiguration?.customDashboardSettings
        ?.dashboardHelloImage && (
        <img
          src={
            applicationConfiguration?.customDashboardSettings
              ?.dashboardHelloImage
          }
          alt="Waving hand"
          className={css.policyListMessage_image}
        />
      )}

      {props.isPolicyListEmpty && (
        <span className={css.policyListMessage_empty}>
          You don't have any policies
        </span>
      )}
    </div>
  );
};

interface IPolicyListMessage {
  isPolicyListEmpty?: boolean;
}
