import { UPDATE_NETWORK_STATUS } from './types';
import clearAlertAction from '../../../api/core/alerts/clear-alert/actions';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';

export default function updateNetworkStatusAction(status) {

  return (dispatch) => {

    dispatch({
      type: UPDATE_NETWORK_STATUS,
      isConnected: status.isConnected
    });

    const noNetworkAlert = {
      id: 'NO_NETWORK_ALERT',
      message: "No internet connection",
      type: 'error',
      location: '/*',
      something: true,
      dismissible: false
    }

    if (!status.isConnected) {
      dispatch(queueAlertAction(noNetworkAlert));
    } else {
      dispatch(clearAlertAction(noNetworkAlert));
      dispatch(queueAlertAction({
        message: "Connected!",
        type: 'success'
      }));
    }

  }

}
