import * as React from 'react';
import { Quote, IRisk } from '@aventus/platform';
import { BladeSurface, BladeList } from '@aventus/blade';
import { getQuoteDetails } from '../../services/get-quote-details';

export const DetailsOfPolicy: React.FunctionComponent<IDetailsOfPolicy> = props => {

  return (
    <BladeSurface
      title={'Your quote breakdown'}
      shouldRespectViewWidth={props.shouldRespectViewWidth}
    >
      <BladeList
        isFlush={true}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
        list={getQuoteDetails(props.risk, props.productName, props.quote.quoteReferenceID, props.isMotorQuote, props.coverStartLabel, props.coverEndLabel )}
      />
    </BladeSurface>
  );
};

interface IDetailsOfPolicy {
  productName: string;
  quote: Quote;
  risk: IRisk;
  isMotorQuote: boolean;
  shouldRespectViewWidth?: boolean;
  coverStartLabel?: string;
  coverEndLabel?: string;
}
