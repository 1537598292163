import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Policy, CancellationReason } from '@aventus/platform';

export const usePolicyCancel: UseGetPolicyCancel = function(
  policyId,
  selectedCancellationReason
) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [ policy, setPolicy ] = React.useState<Policy | undefined>(undefined);
  const [ isPolicyCanceling, setIsPolicyCanceling ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {
      if (policyId && selectedCancellationReason) {
        setIsPolicyCanceling(true);

        try {
          const policyCancelResponse = await symphony.cancelPolicy(policyId, selectedCancellationReason);
          setPolicy(policyCancelResponse);
        } catch (error) {
          throwError(error);
        }

        setIsPolicyCanceling(false);
      }
    })();
  }, [ policyId, selectedCancellationReason ]);

  return {
    policy,
    isPolicyCanceling
  };
  
};

export type UseGetPolicyCancel = (
  policyId?: string,
  selectedCancellationReason?: CancellationReason
) => {
  policy: Policy | undefined;
  isPolicyCanceling: boolean;
};
