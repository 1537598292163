import * as React from 'react';
import { MtaInformation, AdjustmentType } from '@aventus/platform';

export const useAdjustmentInformation = (
  adjustmentInformation?: MtaInformation
): UseAdjustmentInformation => {

  const [ adjustmentType, setAdjustmentType ] = React.useState<AdjustmentType | undefined>(undefined);

  React.useEffect(() => {
    if (adjustmentInformation) {

      if (adjustmentInformation.paymentPlan.type === 'FixedTermSinglePayment' || adjustmentInformation.paymentPlan.type === 'SinglePayment') {
        if (adjustmentInformation.totalPriceChange.value === 0) {
          setAdjustmentType('FixedSingleNoFee');
        } else if (adjustmentInformation.totalPriceChange.value < 0) {
          setAdjustmentType('FixedSingleRefund');
        } else if (adjustmentInformation.totalPriceChange.value > 0) {
          setAdjustmentType('FixedSinglePayment');
        }
      }

      if (adjustmentInformation.paymentPlan.type === 'AnnualMonthlyCredit') {
        if (!adjustmentInformation.recurringPaymentChange) {
          setAdjustmentType('FinanceNoFee');
        } else {
          switch(adjustmentInformation.recurringPaymentChange.changeType) {
            case 'RequiresPayment':
              setAdjustmentType('FinancePayment');
              break;
            case 'RequiresRefund':
              setAdjustmentType('FinanceRefund');
              break;
            case 'ProviderChange':
              setAdjustmentType('FinanceUpdate');
              break;
          }
        }
      }

      if (
        adjustmentInformation.paymentPlan.type === 'AnnualMonthlySubscription' ||
        adjustmentInformation.paymentPlan.type === 'MonthlySubscription'
      ) {
        if (adjustmentInformation.totalPriceChange.value === 0) {
          setAdjustmentType('SubscriptionNoFee');
        } else if (adjustmentInformation.totalPriceChange.value < 0) {
          setAdjustmentType('SubscriptionRefund');
        } else if (adjustmentInformation.totalPriceChange.value > 0) {
          setAdjustmentType('SubscriptionPayment');
        }
      }

    }
  }, [ adjustmentInformation ]);

  return {
    adjustmentType
  };

}

export interface UseAdjustmentInformation {
  adjustmentType: AdjustmentType | undefined
}
