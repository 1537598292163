import * as React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { BladeInputVariant } from '@aventus/blade';
import { BladeSurface, BladeGuide, BladeMessage } from '@aventus/blade';

import { usePayWithCreditCard } from './use-pay-with-credit-card';
import { PaymentRequest } from '../../interface/payment-request';
import { StripeCreditCard } from '../stripe-credit-card';

import css from './index.css';

export const StripePayWithCreditCardInner: React.FunctionComponent<IStripePayWithCreditCard> = props => {
  const { handleOnReady } = usePayWithCreditCard(
    props.onComplete,
    props.isHidden
  );

  return (
    <>
      {!props.isHidden && (
        <BladeSurface
          shouldRespectViewWidth={true}
          title={props.title || 'Pay by card'}
        >
          {props.error && (
            <BladeMessage
              isFlush={true}
              variant={'error'}
              message={props.error}
            />
          )}

          {props.guide && <BladeGuide markdown={props.guide} />}

          <div className={css.payWithCreditCard_inputs}>
            <div
              className={css.payWithCreditCard_inputs_scaffold}
              style={{ marginBottom: 0 }}
            >
              <StripeCreditCard
                onReady={handleOnReady}
                setIsComplete={props.setIsComplete}
                inputVariant={props.inputVariant}
              />
            </div>
          </div>

          {props.guideFooter && <BladeGuide markdown={props.guideFooter} />}
        </BladeSurface>
      )}
    </>
  );
};

export const StripePayWithCreditCard: React.FunctionComponent<IStripePayWithCreditCard> = props => {
  const { stripeKey } = props;

  const stripePromise = React.useMemo(() => loadStripe(stripeKey), [stripeKey]);

  return (
    <Elements stripe={stripePromise}>
      <StripePayWithCreditCardInner {...props} />
    </Elements>
  );
};

interface IStripePayWithCreditCard {
  stripeKey: string;
  title?: string;
  titleWithOptions?: string;
  inputVariant?: BladeInputVariant;
  guide?: string;
  guideFooter?: string;
  onComplete: (paymentRequest: PaymentRequest) => void;
  setIsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | undefined;
  /*
  In certain scenarios, the payment flow might request
  that the stripe be initalised, however no inputs displayed.
  This could be because of saved cards or any other customer
  knowledge scenarios.
  */
  isHidden?: boolean;
}
