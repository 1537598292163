import axios from 'axios';
import { createContext } from 'react';
import { IPlatformClient } from '@aventus/platform-client';

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <PlatformContext.Provider>'
  );
};

export const initialPlatformState = {
  token: undefined,
  client: axios.create(),
  startSession: stub,
  startEditSession: stub,
  getActiveEditSessions: stub,
  saveEditSession: stub,
  rerateEditSession: stub,
  priceEditSession: stub,
  discardEditSession: stub,
  getEditSession: stub,
  product: {
    getProductConfiguration: stub,
    getProductConfigurationById: stub,
    getProductById: stub,
    getVariantQuotes: stub,
    getHybridProduct: stub,
    getProductByRef: stub
  },
  hybrid: {
    hybridCreate: stub,
    hybridStartEditSession: stub,
    hybridTobes: stub
  },
  tobes: {
    getQuestionPage: stub,
    tobesStart: stub,
    tobesSubmit: stub,
    tobesMTAQuote: stub,
    tobesPrice: stub,
    confirmQuote: stub,
    tobesMTAPrice: stub
  },
  variantEditSession: {
    getEditSession: stub,
    completeSession: stub,
    discardSession: stub,
    validateSession: stub,
    removeExposure: stub
  },
  quoteBuilder: {
    addCoverage: stub,
    addEndorsement: stub,
    updateCoverage: stub,
    deleteCoverage: stub,
    addCustomCoverage: stub,
    deleteExposure: stub,
    addFee: stub,
    updateFee: stub,
    deleteFee: stub,
    addSection: stub,
    updateSection: stub,
    deleteSection: stub,
    addSubsection: stub,
    updateSubsection: stub,
    deleteSubsection: stub,
    updateSubsectionExposures: stub
  },
  quote: {
    getQuote: stub,
    getQuoteProduct: stub,
    confirmQuote: stub,
    getDocuments: stub,
    createQuote: stub,
    convertQuote: stub,
    editHybridQuote: stub,
    createMTAQuote: stub
  },
  policy: {
    getPolicy: stub
  },
  configuration: {
    organisation: stub
  },
  quickQuote: {
    confirmQuickQuote: stub
  }
};

const PlatformContext = createContext<IPlatformClient>(initialPlatformState);

export default PlatformContext;
