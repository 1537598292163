import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import usePlatform from '@aventus/platform-client-context/use-platform';

export function useSessionStart(
  userId: string | null,
  token: string | null
): UseSessionStart {
    const platform = usePlatform();

  const [processedToken, setProcessedToken] = React.useState<
    string | undefined
  >(undefined);
  useAsyncEffect(async () => {

    if (token != null && userId != null) {
      const _sessionStart = await platform.startSession(userId, token);
      setProcessedToken(_sessionStart);
    }
  }, [token]);

  return {
    processedToken
  };
}

interface UseSessionStart {
  processedToken: string | undefined;
}
