import { AxiosRequestConfig, AxiosPromise } from 'axios';
import { createClient } from '@aventus/client';
import { handleDeprProductReference } from './handle-depr-product-reference';
import * as rax from 'retry-axios';

import {
  AppConfiguration,
  Applications,
  OrganisationConfiguration,
  DesignSystemConfiguration,
  InsuranceProductConfiguration,
  ProductInterfaceConfiguration,
  OraclesConfiguration,
  PaymentProvidersConfiguration,
  ApplicationsConfiguration,
  ConnectionsConfiguration,
  InterfaceConfiguration
} from '@aventus/configuration';

export function createConfigurationClient(
  app: Applications,
  options?: AxiosRequestConfig
): ConfigurationClient {
  if (!app) {
    throw new Error(
      'createConfigurationClient requires an expected app is defined.'
    );
  }

  const configurationClient = createClient(options);

  configurationClient.defaults.raxConfig = {
    instance: configurationClient,
    retry: 3
  };

  rax.attach(configurationClient);

  const tenantReferences = new Map();
  const appsConfiguration = new Map();
  const applicationsConfiguration = new Map();
  const organisationsConfiguration = new Map();
  const interfaceConfiguration = new Map();
  const designSystemsConfiguration = new Map();
  const productsConfiguration = new Map();
  const productsInterfaceConfiguration = new Map();
  const oraclesConfiguration = new Map();
  const paymentProviders = new Map();
  const connectionsConfiguration = new Map();

  // Get the application configuration for a
  // the hostname context we're in.
  // The app is selected from the app defined at creation
  // of this client.

  const getAppConfiguration: GetAppConfiguration = async () => {
    if (appsConfiguration.has(app)) {
      return appsConfiguration.get(app);
    }

    if (!window) {
      throw new Error('Could not find global window');
    }

    const response = await (<AxiosPromise<AppConfiguration>>(
      configurationClient.get(
        `configuration/apps/${app}/${window.location.hostname}.json`
      )
    ));

    appsConfiguration.set(app, response.data);

    tenantReferences.set('tenantReference', response.data.TENANT_REFERENCE);
    tenantReferences.set(
      'tenantOrgReference',
      response.data.TENANT_ORG_REFERENCE
    );

    return response.data;
  };

  // Retrieves the specific configuration for an application.

  const getApplicationsConfiguration: GetApplicationsConfiguration = async application => {
    if (!application) {
      throw new Error(
        `\`configuration-client\` is trying to get applications configuration, but no application key was defined`
      );
    }

    if (applicationsConfiguration.has(application)) {
      return applicationsConfiguration.get(application);
    }

    const { tenantReference, tenantOrgReference } = getTenantReferences();

    const response = await (<AxiosPromise<ApplicationsConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/applications.json`
      )
    ));

    if (response.data[application]) {
      return response.data[application];
    } else {
      throw new Error(
        `\`configuration-client\` is trying to get applications configuration, but the requested configuration was not found`
      );
    }
  };

  // Retrieves the tenant and tenant org reference
  // that exists in this client's local cache. Keep in mind that
  // the references could either be the default ones associated
  // with the app and domain, or client overriden ones.

  const getTenantReferences: GetTenantReferences = () => {
    if (
      !tenantReferences.has('tenantReference') ||
      !tenantReferences.has('tenantOrgReference')
    ) {
      throw new Error(
        'Either of the tenantReference or the tenantOrgReference was not configured correctly'
      );
    }

    return {
      tenantReference: tenantReferences.get('tenantReference'),
      tenantOrgReference: tenantReferences.get('tenantOrgReference')
    };
  };

  // If the user of this client wants to specify a different
  // tenant and org to the one associated with the domain and app,
  // this function overrides these defaults.

  const overrideTenantReferences: OverrideTenantReferences = (
    tenantReference: string,
    tenantOrgReference: string
  ) => {
    tenantReferences.set('tenantReference', tenantReference);
    tenantReferences.set('tenantOrgReference', tenantOrgReference);
  };

  // Get the configuration for the specific
  // organisation.

  const getOrganisationConfiguration: GetOrganisationConfiguration = async (
    tenantReference?: string,
    tenantOrgReference?: string
  ) => {
    let _tenantReference = tenantReference;
    let _tenantOrgReference = tenantOrgReference;

    let ref: string;

    if (_tenantReference && _tenantOrgReference) {
      ref = _tenantReference + _tenantOrgReference;
    } else {
      const tenantReferences = getTenantReferences();
      _tenantReference = tenantReferences.tenantReference;
      _tenantOrgReference = tenantReferences.tenantOrgReference;
      ref =
        tenantReferences.tenantReference + tenantReferences.tenantOrgReference;
    }

    if (organisationsConfiguration.has(ref)) {
      return organisationsConfiguration.get(ref);
    }

    const response = await (<AxiosPromise<OrganisationConfiguration>>(
      configurationClient.get(
        `configuration/platform/${_tenantReference}/${_tenantOrgReference}/organisation.json`
      )
    ));

    organisationsConfiguration.set(ref, response.data);
    return response.data;
  };

  // Get the configuration for the specific
  // organisation's design system.

  const getDesignSystemConfiguration: GetDesignSystemConfiguration = async designSystem => {
    if (designSystemsConfiguration.has(designSystem)) {
      return designSystemsConfiguration.get(designSystem);
    }

    const response = await (<AxiosPromise<DesignSystemConfiguration>>(
      configurationClient.get(
        `configuration/design-systems/${designSystem}.json`
      )
    ));

    designSystemsConfiguration.set(designSystem, response.data);
    return response.data;
  };

  // Get an organisation's product configuration.
  // This represents all the org-defined strings
  // for a particular insurance product.

  const getProductConfiguration: GetProductConfiguration = async (
    productReferenceParam,
    productCoverReferenceParam
  ) => {
    const _productReference = handleDeprProductReference(
      productReferenceParam.toLowerCase()
    );
    const _productCoverReference = productCoverReferenceParam.toLowerCase();

    const ref: string = _productReference + _productCoverReference;

    if (productsConfiguration.has(ref)) {
      return productsConfiguration.get(ref);
    }

    const { tenantReference, tenantOrgReference } = getTenantReferences();

    const response = await (<AxiosPromise<InsuranceProductConfiguration[]>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/${_productReference}/${_productCoverReference}/product.json`
      )
    ));

    productsConfiguration.set(ref, response.data);
    return response.data;
  };

  // Get an organisation's product configuration.
  // This represents all the org-defined strings
  // for a particular insurance product.

  const getProductInterfaceConfiguration: GetProductInterfaceConfiguration = async (
    productReferenceParam,
    productCoverReferenceParam
  ) => {
    const _productReference = handleDeprProductReference(
      productReferenceParam.toLowerCase()
    );
    const _productCoverReference = productCoverReferenceParam.toLowerCase();
    const ref: string = _productReference + _productCoverReference;

    if (productsInterfaceConfiguration.has(ref)) {
      return productsInterfaceConfiguration.get(ref);
    }

    const { tenantReference, tenantOrgReference } = getTenantReferences();

    const response = await (<AxiosPromise<ProductInterfaceConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/${_productReference}/${_productCoverReference}/product-interface.json`
      )
    ));

    productsInterfaceConfiguration.set(ref, response.data);
    return response.data;
  };

  // Retrieves the oracles configuration for a given organisation.
  // Oracles define external data sources, so this configuration
  // would store any keys and URLs to allow an application to retrieve
  // data from these sources.

  const getOraclesConfiguration: GetOraclesConfiguration = async () => {
    const { tenantReference, tenantOrgReference } = getTenantReferences();
    const ref: string = tenantReference + tenantOrgReference;

    if (oraclesConfiguration.has(ref)) {
      return oraclesConfiguration.get(ref);
    }

    const response = await (<AxiosPromise<OraclesConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/oracles.json`
      )
    ));

    oraclesConfiguration.set(ref, response.data);
    return response.data;
  };

  const getInterfaceConfiguration: GetInterfaceConfiguration = async () => {
    const { tenantReference, tenantOrgReference } = getTenantReferences();
    const ref: string = tenantReference + tenantOrgReference;

    if (interfaceConfiguration.has(ref)) {
      return interfaceConfiguration.get(ref);
    }

    const response = await (<AxiosPromise<InterfaceConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/interface.json`
      )
    ));

    interfaceConfiguration.set(ref, response.data);
    return response.data;
  };

  // Retrieves the payment providers configuration for a given organisation.

  const getPaymentProvidersConfiguration: GetPaymentProvidersConfiguration = async () => {
    const { tenantReference, tenantOrgReference } = getTenantReferences();
    const ref: string = tenantReference + tenantOrgReference;

    if (paymentProviders.has(ref)) {
      return paymentProviders.get(ref);
    }

    const response = await (<AxiosPromise<PaymentProvidersConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/payment-providers.json`
      )
    ));

    paymentProviders.set(ref, response.data);
    return response.data;
  };

  const getConnectionsConfiguration: GetConnectionsConfiguration = async () => {
    const { tenantReference, tenantOrgReference } = getTenantReferences();
    const ref: string = tenantReference + tenantOrgReference;

    if (connectionsConfiguration.has(ref)) {
      return connectionsConfiguration.get(ref);
    }

    const response = await (<AxiosPromise<ConnectionsConfiguration>>(
      configurationClient.get(
        `configuration/platform/${tenantReference}/${tenantOrgReference}/connections.json`
      )
    ));

    connectionsConfiguration.set(ref, response.data);
    return response.data;
  };

  return {
    getAppConfiguration,
    getApplicationsConfiguration,
    getTenantReferences,
    overrideTenantReferences,
    getOrganisationConfiguration,
    getDesignSystemConfiguration,
    getProductConfiguration,
    getProductInterfaceConfiguration,
    getOraclesConfiguration,
    getPaymentProvidersConfiguration,
    getConnectionsConfiguration,
    getInterfaceConfiguration
  };
}
