import * as React from 'react';
import { BladeFormFieldset, IBladeFormFieldset } from '../index';
import { BladeInputText, IBladeInputText } from '../../input/text';
import css from './index.css';

const FIRSTNAME: string = '-first';
const LASTNAME: string = '-last';

export const BladeFormFieldsetName: React.FunctionComponent<IBladeFormFieldsetName> = props => {
  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
      tooltip={props.tooltip}
    >
      <div className={css.name}>
        <BladeInputText
          label={'First'}
          name={props.name + FIRSTNAME}
          id={props.name + FIRSTNAME}
          variant={props.variant}
          value={props.value ? props.value.firstName : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ firstName: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: ValidationResult) => {
            props.onValidate?.({ ...props.error, ...{ [FIRSTNAME]: result } });
          }}
          error={
            typeof props.error === 'object' ? props.error?.[FIRSTNAME] : ''
          }
        />

        <BladeInputText
          label={'Last'}
          name={props.name + LASTNAME}
          id={props.name + LASTNAME}
          variant={props.variant}
          value={props.value ? props.value.lastName : ''}
          onChange={(value: string) => {
            props.onChange({
              ...props.value,
              ...{ lastName: value }
            });
          }}
          validate={props.validate}
          onValidate={(result: ValidationResult) => {
            props.onValidate?.({ ...props.error, ...{ [LASTNAME]: result } });
          }}
          error={
            typeof props.error === 'object'
              ? props.error?.[LASTNAME]
              : undefined
          }
        />
      </div>
    </BladeFormFieldset>
  );
};

export interface IBladeFormFieldsetName
  extends IBladeFormFieldset,
    IBladeInputText {}
