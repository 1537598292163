import {
  BladeAsk,
  BladeRunner,
  BladeTitleBar,
  BladeView,
  BladeMessage,
  BladeProgressBar,
  BladeLoader,
  BladeLink
} from '@aventus/blade';

import React from 'react';
import { useApplicationTimezone } from '@aventus/application-timezone';
import {
  ITobesPage,
  ITobesQuestionMap,
  ITobesQuoteBundle
} from '@aventus/platform-client-context/models/tobes';
import css from './index.css';
import {
  InsuranceProductConfiguration,
  OraclesConfiguration,
  ProductInterfaceConfiguration
} from '@aventus/configuration';

import { useScrollToTop } from '@aventus/pockethooks';
import { IStoredFieldAnswer } from '../../../../../models/fieldAnswer';
import { renderQuestionsForPage } from '../../../../../helpers/TobesRenderHelpers/renderQuestion';
import {
  IUsePaymentPlan,
  PaymentPlanSelectorCards
} from '@aventus/mvmt-pricing';
import { IProgressBarConfig } from '@aventus/platform';
import { TobesQuestionPageState } from '../../../../../store/form-state';
import { getQuestionState } from '../../../../../helpers/FormState/getQuestionState';

export const SimpleQuoteAsk: React.FunctionComponent<
  IQuestionPageProps
> = props => {
  const {
    toHumanReadable,
    toPlatformReadable,
    toApplicationReadable,
    timezone
  } = useApplicationTimezone();

  useScrollToTop([props.tobesError], props.tobesError === true, 'smooth');

  const [collectionStates, setCollectionStates] = React.useState<
    IStoredFieldAnswer[]
  >([]);

  const pagesFromSession: ITobesPage[] =
    JSON.parse(String(sessionStorage.getItem('question_pages'))) || [];

  return (
    <BladeRunner>
      <div className={css.scaffolding}>
        {props.progressBarConfig && props.progressBarConfig?.showProgressBar && (
          <BladeProgressBar
            pages={props.pages || pagesFromSession}
            currentStep={{
              group: props.pageConfig.group,
              order: props.pageConfig.number
            }}
            showQuickQuoteStep={props.progressBarConfig?.showQuickQuoteStep}
            showPaymentStep={props.progressBarConfig?.showPaymentStep}
          />
        )}

        <BladeTitleBar
          viewVariant={'s'}
          title={props.pageConfig.title}
          description={props.pageConfig.description}
          tooltip={props.pageConfig.tooltip}
        />

        {props.pageConfig.image && (
          <img
            className={css.pageImage}
            src={props.pageConfig?.image}
            alt="page"
          />
        )}
        <BladeView variant={'s'} className={css.ask}>
          {props.tobesError && (
            <div className={css.messageWrapper}>
              <BladeMessage
                variant={'error'}
                message={'There was an error please try again'}
                detail={props.validationDetails}
              />
            </div>
          )}

          {props.formState.validationErrorMap.size > 0 && (
            <div className={css.messageWrapper}>
              <BladeMessage
                id="ValidationErrors"
                variant={'warning'}
                message={
                  'We found some fields with validation errors, please check the list below:'
                }
                custom={
                  <ul>
                    {[...props.formState.validationErrorMap.entries()]?.map(
                      ([key, error]) => {
                        const keyArr = key.split('.');
                        const ref = keyArr[keyArr.length - 1];
                        return error.errors.map((str, i) => (
                          <li key={`${key}_${i}`}>
                            <BladeLink
                              onClick={() => {
                                const el = document.getElementById(
                                  `FIELDSET_${key}`
                                );

                                if (el) {
                                  el.scrollIntoView({ behavior: 'smooth' });
                                }
                              }}
                            >
                              <strong
                                style={{
                                  display: 'inline-flex',
                                  maxWidth: '150px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {ref}
                              </strong>
                              {' - '}
                              {str}
                            </BladeLink>
                          </li>
                        ));
                      }
                    )}
                  </ul>
                }
              />
            </div>
          )}

          {props.isLoadingPricing && (
            <div className={css.pricingLoader}>
              <BladeLoader className={'Component'} />
            </div>
          )}

          {!props.isLoadingPricing &&
            props.pageConfig.canPrice &&
            props.productConfiguration &&
            props.tobesQuoteBundle &&
            props.tobesQuoteBundle.requestedQuotePricing &&
            props.paymentPlan && (
              <PaymentPlanSelectorCards
                label={
                  props.productConfiguration.pricing?.paymentPlanToggleLabel
                }
                description={
                  props.productConfiguration.pricing.paymentPlanDescription
                }
                pricingSet={props.tobesQuoteBundle.requestedQuotePricing}
                productConfiguration={props.productConfiguration}
                selectedPaymentPlan={props.paymentPlan.selectedPricingPlan}
                setSelectedPaymentPlan={
                  props.paymentPlan.setSelectedPricingPlan
                }
                variant={props.productInterfaceConfiguration?.inputs}
              />
            )}

          {!props.isLoadingPricing && (
            <BladeAsk
              variant="alltogether"
              shouldRespectViewWidth={true}
              getQuestionsToRender={() =>
                renderQuestionsForPage(
                  props.questions,
                  {
                    dateToHumanReadable: toHumanReadable,
                    dateToPlatformReadable: toPlatformReadable,
                    dateToApplicationReadable: toApplicationReadable
                  },
                  {
                    currencyCode: props.currencyCode,
                    currencySymbol: props.currencySymbol,
                    countryCode: props.countryCode,
                    dateFormat: props.dateFormat,
                    oracles: props.oraclesConfiguration,
                    timezone: timezone,
                    questionIcons:
                      props.productConfiguration?.quote.customise.questionIcons,
                    questionImages:
                      props.productConfiguration?.quote.customise.questionImages
                  },
                  props.getQuestionAnswer,
                  props.changeQuestionAnswer,
                  props.getArrayDescription,
                  null, // Note we don't have a parent at this stage, so pass null here
                  collectionStates,
                  setCollectionStates,
                  props.formState,
                  getQuestionState,
                  props.clearFieldValidation,
                  props.productConfiguration
                )
              }
              getSubmitToRender={props.getSubmitToRender}
            />
          )}
        </BladeView>
      </div>
    </BladeRunner>
  );
};

export interface IQuestionPageProps {
  formState: TobesQuestionPageState;
  pages: ITobesPage[];
  pageConfig: any;
  currencyCode: string;
  currencySymbol: string;
  countryCode: string;
  dateFormat: string;
  questions: ITobesQuestionMap[] | [];
  getSubmitToRender: (isFormValid: boolean) => React.ReactNode;
  getQuestionAnswer: (referenceID: string) => any;
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null
  ) => void;
  getArrayDescription: (referenceID: string, template?: string) => string;
  oraclesConfiguration: OraclesConfiguration;
  productConfiguration?: InsuranceProductConfiguration;
  productInterfaceConfiguration?: ProductInterfaceConfiguration;
  tobesError: boolean;
  validationDetails?: string;
  disableWarning: boolean;
  tobesQuoteBundle?: ITobesQuoteBundle;
  paymentPlan?: IUsePaymentPlan;
  progressBarConfig: IProgressBarConfig | undefined;
  isLoading: boolean;
  isLoadingPricing: boolean;
  clearFieldValidation: (referenceID: string) => void;
}
