import * as React from 'react';
import { MtaInformation, Quote, Risk } from '@aventus/platform';
import { BladeReceipt, BladeList } from '@aventus/blade';
import { ReceiptSummary } from '../receipt-summary';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';

import css from './index.css';

export const ReceiptAdjust: React.FunctionComponent<IReceiptAdjust> = props => {
  let tag;
  if (props.adjustmentInformation) {
    switch (props.adjustmentInformation.paymentPlan.type) {
      case 'FixedTermSinglePayment':
        tag = 'Annual';
        break;
      case 'SinglePayment':
        tag = props.receiptPaymentPlanLabel ?? 'Single';
        break;

      case 'AnnualMonthlyCredit':
      case 'AnnualMonthlySubscription':
      case 'MonthlySubscription':
        tag = 'Monthly';
        break;
    }
  }

  return (
    <BladeReceipt
      price={
        props.adjustmentInformation.recurringPaymentChange &&
        props.adjustmentInformation.recurringPaymentChange.changeInMonthlyPrice
          ? props.adjustmentInformation.recurringPaymentChange
              .changeInMonthlyPrice
          : props.adjustmentInformation.totalPriceChange
      }
      tag={tag}
      footnote={props.taxLabel}
      isPriceDiff={true}
      receiptStyledFoot={props.receiptStyledFoot}
    >
      <div className={css.receiptAdjust_scaffold}>
        <ReceiptSummary
          label={props.productLabel}
          quote={props.quote}
          risk={props.risk}
        />
        {props.adjustmentInformation.paymentPlan.type ===
          'AnnualMonthlySubscription' && (
          <BladeList
            size={'small'}
            isFlush={true}
            isSmall={true}
            className={css.receiptAdjust_itemisedList}
            moreDetailsStyle={{ fontSize: '14px' }}
            shouldRespectViewWidth={true}
            list={[
              {
                label: `Previous monthly amount`,
                value: currencyToHumanReadable(
                  props.adjustmentInformation.recurringPaymentChange
                    .previousMonthlyPrice
                )
              },
              {
                label: `Price change`,
                value: currencyToHumanReadable(
                  props.adjustmentInformation.recurringPaymentChange
                    .changeInMonthlyPrice
                )
              },
              {
                label: 'New monthly amount',
                value: currencyToHumanReadable(
                  props.adjustmentInformation.recurringPaymentChange
                    .totalNewMonthlyPrice
                )
              }
            ]}
          />
        )}
      </div>
    </BladeReceipt>
  );
};

interface IReceiptAdjust {
  productLabel: string;
  taxLabel: string;
  adjustmentInformation: MtaInformation;
  quote: Quote;
  risk: Risk;
  receiptStyledFoot: boolean | undefined;
  receiptPaymentPlanLabel?: string;
}
