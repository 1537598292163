import { useEffect, useState } from 'react';
import { Moment } from 'moment';
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const useDate: UseDate = date => {
  const today = date.toDate();
  const tomorrow = date.add('1', 'days').toDate();

  const [currentYear, setCurrentYear] = useState<Number>();
  const [currentMonth, setCurrentMonth] = useState<string>('');

  useEffect(() => {
    if (date) {
      setCurrentYear(date.year());
      setCurrentMonth(months[date.month()]);
    }
  }, [date]);

  return { today, tomorrow, currentYear, currentMonth };
};

export type UseDate = (
  date: Moment
) => {
  today: Date;
  tomorrow: Date;
  currentYear: any;
  currentMonth: string;
};
