import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration
} from '@aventus/configuration-client-context';
import { useGetProduct } from '@aventus/symphony-client/hooks';
import {
  BladeView,
  BladeViewIsLoading,
  BladeMarkdown,
  BladeButton,
  BladeSurface,
  BladeModal,
  useRunner
} from '@aventus/blade';
import { useTrackView, useTrack } from '@aventus/application-tracking';
import { useDocumentTitle } from '@aventus/pockethooks/use-document-title';
import { DetailsOfCover } from '../../components/details-of-cover';
import { head } from 'lodash';
import css from './index.css';
import cx from 'classnames';
import { ProductReference, ProductCoverReference } from '@aventus/platform';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const ProductOverview: React.FunctionComponent<
  IProductOverview
> = props => {
  const { track } = useTrack();
  useTrackView('ProductOverview');
  const { updateProductRef } = useDispatchEvent();
  const { trim } = useRunner();

  const productReference: ProductReference =
    props.match.params.productReference;
  const productCoverReference: ProductCoverReference =
    props.match.params.productCoverReference;

  const productID: string = props.match.params.productID;

  // Given these two references, we can pick out the specific configuration
  // for this insurance product, as well as the organisation configuration.

  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  useDocumentTitle(`Product overview | ${organisationConfiguration?.name}`);

  React.useEffect(() => {
    track({
      event: 'aventus.product.overview',
      productReference: productReference,
      productCoverReference: productCoverReference
    });
  }, []);

  // TODO
  // cmmt

  const { isGettingProduct, product } = useGetProduct(
    productReference,
    productCoverReference,
    productID
  );

  const productQuestionSet = head(product?.coreQuestionSets);

  // TODO
  // cmmt

  const [smallPrintDetails, setSmallPrintDetails] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    updateProductRef(productReference);
  }, [productReference]);

  if (isGettingProduct) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (productConfiguration && product) {
    return (
      <BladeView className={css.productOverview} variant={'s'}>
        <BladeSurface
          title={productConfiguration.product.label}
          shouldRespectViewWidth={true}
        >
          <div className={css.productOverview_description}>
            <BladeMarkdown
              size={'base'}
              markdown={productConfiguration.product.description}
            />
          </div>

          <div
            className={cx(css.productOverview_action, {
              [css.productOverview_action_trimDivider]: trim === 'divider'
            })}
          >
            <BladeButton
              onClick={() =>
                productQuestionSet
                  ? props.goToQuoteStartV3(
                      productQuestionSet.referenceID,
                      productID,
                      props.match.params.productCoverReference
                    )
                  : props.goToQuoteStart(
                      productReference,
                      productCoverReference
                    )
              }
            >
              {productConfiguration.quote.start.callToAction ||
                'Start your quote'}
            </BladeButton>
          </div>

          <BladeSurface isAside={true}>
            <div className={css.productOverview_smallPrint}>
              <BladeMarkdown
                size={'small'}
                action={() =>
                  setSmallPrintDetails(
                    productConfiguration.product.smallPrintDetails
                  )
                }
                markdown={`${productConfiguration.product.smallPrint} ${productConfiguration.product.recaptchaPrint ?? ''}`}
              />
            </div>
          </BladeSurface>
        </BladeSurface>

        {product && (
          <DetailsOfCover
            product={product}
            coverConfiguration={productConfiguration.product.cover}
            shouldRespectViewWidth={true}
          />
        )}

        {smallPrintDetails && (
          <BladeModal
            withFrame={true}
            close={() => setSmallPrintDetails(undefined)}
          >
            <BladeMarkdown
              size={'base'}
              markdown={productConfiguration.product.smallPrintDetails}
            />
          </BladeModal>
        )}
      </BladeView>
    );
  }

  return null;
};

interface IPathParams {
  productReference: ProductReference;
  productCoverReference: ProductCoverReference;
  productID: string;
}

interface IProductOverview extends RouteComponentProps<IPathParams> {
  goToQuoteStart: (
    productReference: ProductReference,
    productCoverReference: ProductCoverReference
  ) => void;

  goToQuoteStartV3: (
    questionSetReference: string,
    productID: string,
    productCoverReference: string
  ) => void;
}
