import React from 'react';
import { Scaffold } from '@honey/consume-react-jss';
import {
  BladeAppBar,
  BladeButton,
  BladePage,
  BladeMarkdown,
  BladeIcon
} from '@aventus/blade';
import routes from '../../../routes';

export default function render() {
  if (this.state.hasError) {
    return (
      <Scaffold fill={true} orient={'col'}>
        <BladeAppBar
          size={'default'}
          renderLeft={
            <img
              style={{ width: '190px' }}
              onClick={() => this.dismissErrorAndGoTo(routes.home.path)}
              src={this.props.companyLogo}
            />
          }
        />

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '5rem 0'
          }}
        >
          <BladePage title={'There has been a problem'}>
            <Scaffold position={'c'} orient={'col'} size={'default'}>
              {!!this.props.errorImageUrl ? (
                <img
                  style={{ maxWidth: '100px' }}
                  src={this.props.errorImageUrl}
                />
              ) : (
                <BladeIcon
                  name="farExclamationCircle"
                  style={{ fontSize: '5rem' }}
                ></BladeIcon>
              )}

              <BladeMarkdown
                markdown={
                  this.props.errorMessage || "Uh-oh, Something's gone wrong."
                }
              />

              <div
                style={{
                  display: 'block',
                  width: '100%',
                  marginTop: '5rem'
                }}
              >
                <BladeButton
                  onClick={() => this.dismissErrorAndGoTo(routes.home.path)}
                  style={{ width: '100%' }}
                >
                  {'Back to home'}
                </BladeButton>

                {!!this.props.tenantMarketing && (
                  <BladeButton
                    invert={true}
                    onClick={() => {
                      const newTab = window.open(
                        this.props.tenantMarketing,
                        '_blank'
                      );
                      newTab.focus();
                    }}
                    style={{ marginTop: 15 }}
                  >
                    {'Get a quote'}
                  </BladeButton>
                )}

                {!!this.props.tenantSupport && (
                  <BladeButton
                    invert={true}
                    onClick={() => {
                      const newTab = window.open(
                        this.props.tenantSupport,
                        '_blank'
                      );
                      newTab.focus();
                    }}
                    style={{ marginTop: 15 }}
                  >
                    {'Contact support'}
                  </BladeButton>
                )}
              </div>
            </Scaffold>
          </BladePage>
        </div>
      </Scaffold>
    );
  }

  return this.props.children;
}
