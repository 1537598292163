import * as React from 'react';

import { IBladeFormFieldset, PackageSelector } from '@aventus/blade';
import {
  AdditionalComponentPrices,
  IntelligentQuoteQuestion
} from '@aventus/platform';

import { IBladeInputText } from '../../input/text';
import { EventType } from '@aventus/pocketknife/dispatch-event';

export const BladeFormFieldsetTravelPackage: React.FunctionComponent<
  IBladeFormFieldsetTravelPackage
> = props => {
  const fieldsetInputs = React.Children.map(props.children, (input: any) =>
    React.cloneElement(input, {})
  );

  const [childComponentWasUpdated, setChildComponentWasUpdated] =
    React.useState(false);

  const handleChildComponentUpdated = (event: any) => {
    setChildComponentWasUpdated(props.name === event.detail.parentRef);
  };

  React.useEffect(() => {
    window.addEventListener(
      EventType.ChildComponentUpdated,
      handleChildComponentUpdated
    );

    return () => {
      window.removeEventListener(
        EventType.ChildComponentUpdated,
        handleChildComponentUpdated
      );
    };
  }, []);

  return (
    <PackageSelector
      value={props.value ? props.value.selected : false}
      label={props.question}
      name={'travelPackageSelector'}
      onChange={(value: boolean) => {
        props.onChange({ selected: value });
      }}
      questionData={props.questionData}
      additionalComponentPrices={props.additionalComponentPrices}
      selectedInOriginalRisk={props.selectedInOriginalRisk}
      isLoading={props.isLoadingPrice}
      childComponentWasUpdated={childComponentWasUpdated}
      setChildComponentWasUpdated={setChildComponentWasUpdated}
    >
      {fieldsetInputs}
    </PackageSelector>
  );
};

export interface IBladeFormFieldsetTravelPackage
  extends IBladeFormFieldset,
    IBladeInputText {
  questionData: IntelligentQuoteQuestion;
  additionalComponentPrices?: AdditionalComponentPrices;
  selectedInOriginalRisk: boolean | null;
  isLoadingPrice: boolean;
}
