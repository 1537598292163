import React from 'react';
import { matchPath } from 'react-router';
import _findIndex from 'lodash/findIndex';
import appApi from '../../../api';
import { BladeNotification } from '@aventus/blade';

class Alerts extends React.Component {
  componentDidUpdate = prevProps => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const alertLocation = prevProps.app.core.alerts.alertsQueue.filter(
        alert => alert.location === prevProps.location.pathname
      );

      alertLocation.forEach(alert => this.deleteAlert(alert));
    }
  };

  deleteAlert = alert => {
    if (this.props.app.core.alerts.alertsQueue.length > 0) {
      const queueIndex = _findIndex(
        this.props.app.core.alerts.alertsQueue,
        a => a.id === alert.id
      );
      this.props.handleClearAlert(queueIndex);
    }
  };

  render() {
    const { alertsQueue } = this.props.app.core.alerts;

    const alerts =
      alertsQueue.length > 0
        ? alertsQueue
            .filter(alert => alert.message !== undefined)
            .filter(alert => {
              const match = matchPath(this.props.location.pathname, {
                path: alert.location
              });

              return (
                (match && match.path === alert.location) ||
                alert.location === false
              );
            })
        : [];

    return (
      <BladeNotification
        show={alerts.length > 0}
        alerts={alerts.map(alert =>
          Object.assign({}, alert, {
            variant: alert.type,
            callBack: () => this.deleteAlert(alert)
          })
        )}
      />
    );
  }
}

export default appApi(Alerts);
