import update from 'immutability-helper';

export default function setExternalIdReducer(state, action) {
  const { externalId } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        tracking: {
          externalId: { $set: externalId }
        }
      })
    }
  };
}
