import update from 'immutability-helper';

export default function setAppReady(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        isAppReady: { $set: true }
      })
    }
  };
}
