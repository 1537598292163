import * as React from 'react';
import {
  PricingSet,
  PricingPlan,
  PaymentPlanType,
  IQuotePricing
} from '@aventus/platform';

export function usePaymentPlan(
  plans: PricingSet | IQuotePricing | undefined,
  defaultPaymentPlanType?: PaymentPlanType,
  paymentPlanReferenceID?: string | undefined
): IUsePaymentPlan {
  const [selectedPricingPlan, setSelectedPricingPlan] = React.useState<
    PricingPlan | undefined
  >(undefined);
  const [otherPricingPlan, setOtherPricingPlan] = React.useState<
    PricingPlan | undefined
  >(undefined);

  React.useEffect(() => {
    if (plans && plans.plans && plans.plans.length > 0) {
      if (!selectedPricingPlan) {
        // We'll first try and see whether we have a quote/renewal with
        // an existing payment plan.

        // Then we try and look for any defaults defined further
        // above. That default comes from our client's config or implementation
        // definitions, so they can be error prone. We should handle the scenario
        // where we don't find this default type, and select our own assumed
        // default in that case.

        if (paymentPlanReferenceID) {
          const existingPlan = plans.plans.find(
            plan =>
              plan.paymentPlan.paymentPlanReferenceID === paymentPlanReferenceID
          );
          if (existingPlan) {
            setSelectedPricingPlan(existingPlan);
          } else {
            // Set a default if plan doesn't exist
            setSelectedPricingPlan(plans.plans[plans.plans.length - 1]);
          }
        } else if (defaultPaymentPlanType) {
          const defaultPaymentPlan = plans.plans.find(
            plan => plan.paymentPlan.type === defaultPaymentPlanType
          );
          if (defaultPaymentPlan) {
            setSelectedPricingPlan(defaultPaymentPlan);
          } else {
            setSelectedPricingPlan(plans.plans[plans.plans.length - 1]);
          }
        } else {
          // We are going to default to the last item because
          // that's usually where the monthly plan comes.
          // This is in no way reliable and should be addressed
          // (TODO)

          setSelectedPricingPlan(plans.plans[plans.plans.length - 1]);
        }

        if (plans.plans.length > 1) {
          setOtherPricingPlan(plans.plans[0]);
        }
      } else {
        // We want to match on the same type. The price itself
        // could have changed within both pricing plans, so its important
        // to always refresh with the latest one.

        const updatedSelectedPricingPlan = plans.plans.find(
          plan => plan.paymentPlan.type === selectedPricingPlan.paymentPlan.type
        );
        setSelectedPricingPlan(updatedSelectedPricingPlan);

        const _otherPricingPlan = plans.plans.find(
          plan => plan.paymentPlan.type !== selectedPricingPlan.paymentPlan.type
        );
        setOtherPricingPlan(_otherPricingPlan);
      }
    }
  }, [
    paymentPlanReferenceID,
    plans,
    defaultPaymentPlanType,
    selectedPricingPlan
  ]);

  return {
    selectedPricingPlan,
    setSelectedPricingPlan,
    otherPricingPlan
  };
}

export interface IUsePaymentPlan {
  selectedPricingPlan: PricingPlan | undefined;
  setSelectedPricingPlan: (pricingPlan: PricingPlan) => void;
  otherPricingPlan: PricingPlan | undefined;
}
