import {
  IntelligentQuoteQuestionValidationRules,
  MtaInformation,
  PricingSet,
  Quote,
  QuoteEmbargo
} from '@aventus/platform';

export interface ITobesQuestionPage {
  readonly questions: ITobesQuestion[];
  title: string;
  description: string;
  number: number;
  image: string;
  tooltip?: string;
  canPrice: boolean;
  group: string;
}

export interface ITobesGetQuestionPageRequest {
  exposureID: string | null;
  page: number;
  questionSetReferenceID: string;
  editSessionID: string;
}

export interface ITobesSubmitQuestionPageRequest {
  editSessionID: string;
  page: number;
  questionSetReferenceID: string;
  answers: ITobesArrayAnswerItem[];
  exposureID: string | null;
}

interface ITobesQuestionCustomProperties {
  addressLookupCountryFilter?: string[];
  questionNoun?: string;
}

export interface ITobesQuestion {
  referenceID: string;
  text: ITobesText; //TODO: This property name may be changed
  questionType: ETobesQuestionType;
  dataType: string;
  objectType: string;
  conditionOperator: EQuestionConditionOperator;
  conditions: ITobesCondition[];
  validation: ITobesValidation[];
  validationRules: IntelligentQuoteQuestionValidationRules[];
  answerOptions: ITobesArrayAnswer[];
  dataSource: string;
  existingValue: object | ITobesArrayAnswer[];
  arrayPage: ITobesArrayPage<ITobesQuestion> | null;
  fields: ITobesQuestion[];
  customProperties: ITobesQuestionCustomProperties;
}

export interface ITobesArrayPage<T> {
  title: string;
  description: string;
  number: number;
  questions: T[];
}

export interface ITobesQuestionMap extends ITobesQuestion {
  fields: ITobesQuestionMap[];
  arrayPage: ITobesArrayPage<ITobesQuestionMap> | null;
  validate: (
    value: unknown,
    question: ITobesQuestionMap
  ) => Promise<{ result: boolean; errors: string[] }>;
}

export type ETobesQuestionType =
  | 'motorweb'
  | 'hidden'
  | 'claims'
  | 'itemarray'
  | 'ItemArrayForm'
  | 'boxmodal'
  | 'singleentitymodal'
  | 'singleentityboxmodal'
  | 'inputdate'
  | 'inputdatemonthyear'
  | 'datepicker'
  | 'datedropdownfuture'
  | 'datedropdownpast'
  | 'yesno'
  | 'boxboolean'
  | 'radio'
  | 'Email'
  | 'phonenumber'
  | 'Input'
  | 'Select'
  | 'Segmented'
  | 'Picklist'
  | 'MoneyStepper'
  | 'BoxMoneyStepper'
  | 'multiSelect'
  | 'TextBlock'
  | 'CorrectIncorrect'
  | 'ConfirmDoNotConfirm'
  | 'TravelPackage'
  | 'TravelPackageDateRange'
  | 'ItemArrayFormGeneric';

export type ETobesConditionType =
  | 'Boolean'
  | 'ListItem'
  | 'ListItemNotIn'
  | 'NotNull'
  | 'ArrayNotEmptyOrNull'
  | 'GreaterThan'
  | 'LessThan';

export type EQuestionConditionOperator = 'Or' | 'And';

export interface ITobesArrayAnswer {
  id: string | null;
  answers: ITobesArrayAnswerItem[];
}

export interface ITobesArrayAnswerItem {
  questionReferenceID: string;
  answer: any; // This needs to remain as any as we can have object questions
  fields: ITobesArrayAnswerItem[];
  arrayItems: ITobesArrayAnswer[];
}

export interface ITobesText {
  summary: string;
  detail: string;
  footer: string;
  question: string;
  help: string;
  quickflow: string;
  buttonText?: string;
  multipleButtonText?: string;
  image?: string;
  tooltip?: string;
  answeredText?: string;
  arrayButtonText?: string;
  arrayMultipleButtonText?: string[];
}

export interface ITobesValidation {
  referenceID: string;
  message: string;
}

export interface ITobesCondition {
  questionReferenceID: string;
  conditionType: ETobesConditionType;
  conditionValue: any;
  fieldPath: string;
}

export interface IHybridCreate {
  quoteDetails: { quote: { id: string } };
}

export interface IHybridEdit {
  createdByID: string;
  dateCreated: string;
  dateUpdated: string;
  policyDetail: any; // Todo
  risk: any; // Todo
  id: string;
  ownerID: string;
  policyDetailHeaderID: string;
  sourcePolicyDetailHistoryID: string;
  state: string;
  version: number;
}

export interface ITobeStartSuccess {
  exposureID: string | null;
  pages: ITobesPage[] | [];
}

export interface ITobesPage {
  title: string;
  description: string;
  number: number;
  image: string;
  group: string;
}

export interface ITobesAnswer {
  answer: object;
  arrayItems: ITobesAnswerItem[];
  fields: ITobesAnswer[];
  questionReferenceID: string;
}

export interface ITobesAnswerItem {
  answer: ITobesAnswer;
  id: string;
}

export interface ITobesSubmit {
  answers: ITobesArrayAnswerItem[];
  exposureID: string | null;
  page: number;
  questionSetReferenceID: string;
}

export interface IValidationErrorModel {
  message: string;
  propertyIdentifier: string;
}

export interface IMajorMoney {
  value: number;
  currencyCode: string;
}

export interface ITobesQuoteBundle {
  embargoes: QuoteEmbargo[];
  requestedQuote: Quote;
  mtaInfo: MtaInformation;
  requestedQuotePricing: PricingSet;
  risk: any;
}

export enum EProductType {
  hybrid = 'Hybrid',
  digital = 'DigitalUW',
  standard = 'standard'
}
