import update from 'immutability-helper';

export const getCustomConfigRequestReducer = (state, action) => {
  return {
    ...state,
    core: update(state.core, {
      _flags: {
        isGettingCustomConfig: { $set: true }
      }
    })
  }
}

export const getCustomConfigSuccessReducer = (state, action) => {
  return {
    ...state,
    core: update(state.core, {
      customConfig: { $set: action.customConfig },
      _flags: {
        isGettingCustomConfig: { $set: true },
        successfullyLoadedCustomConfig: { $set: true }
      }
    })
  }
}

export const getCustomConfigFailureReducer = (state, action) => {
  return {
    ...state,
    core: update(state.core, {
      _flags: {
        isGettingCustomConfig: { $set: false }
      }
    })
  }
}
