import {
  Policy,
  DiscountInfo,
  CreditAgreement,
  ITotalPaid,
  RenewalStatus
} from '@aventus/platform';
import { ToHumanReadable } from '@aventus/application-timezone';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { IListItem } from '@aventus/blade/list';
import { paymentPlanToHumanReadable } from './payment-plan-to-human-readable';
import { AutoRenewalConfiguration } from '@aventus/configuration/product';

export function getPolicyDetails(
  policy: Policy,
  toHumanReadable: ToHumanReadable,
  creditAgreement?: CreditAgreement,
  discountInfo?: DiscountInfo,
  policyLabel?: string,
  paymentPlanLabels?: { [key: string]: string },
  totalPaid?: ITotalPaid,
  allowAutoBind?: boolean,
  autoRenewalFlag?: boolean,
  autoRenewalConfiguration?: AutoRenewalConfiguration,
  autoRenewalToggle?: (value: boolean) => any,
  togglableLoading?: boolean,
  renewalStatus?: RenewalStatus,
  coverStartLabel?: string,
  coverEndsLabel?: string,
  showPurchasedDate?: boolean
): IListItem[] {
  if (!policy) {
    return [];
  }

  if (
    creditAgreement &&
    creditAgreement.paymentPlanType === 'AnnualMonthlySubscription'
  ) {
    let policyItemsArray: IListItem[] = [
      {
        label: 'Policy name',
        value: policy.label || policyLabel
      },
      {
        label: 'Policy reference',
        value: policy.policyReferenceID
      }
    ];

    if (
      creditAgreement.agreementHistory.agreementHistoryType === 'Cancellation'
    ) {
      policyItemsArray.push({
        label: 'Policy status',
        value: 'Cancelled'
      });

      policyItemsArray.push({
        label: 'Cancellation effective on',
        value: toHumanReadable(policy.cancellationEffectiveDate)
      });

      policyItemsArray.push({
        label: 'Cover starts',
        value: toHumanReadable(policy.coverStartDate)
      });

      policyItemsArray.push({
        label: 'Cover ends',
        value: toHumanReadable(policy.coverEndDate)
      });

      creditAgreement.agreementHistory &&
        creditAgreement.agreementHistory.recurringInstalmentAmount &&
        policyItemsArray.push({
          label: 'Monthly amount',
          value: currencyToHumanReadable(
            creditAgreement.agreementHistory.recurringInstalmentAmount
          )
        });
    }

    policyItemsArray.push({
      label: 'Payment frequency',
      value: paymentPlanToHumanReadable(
        creditAgreement.paymentPlanType,
        paymentPlanLabels
      )
    });

    if (discountInfo) {
      policyItemsArray.push({
        label: `Discount (${discountInfo.description})`,
        value:
          discountInfo.discountPercent > 0
            ? `${discountInfo.discountPercent * 100}%`
            : discountInfo.amount.value > 0
            ? `${currencyToHumanReadable(discountInfo.amount)}`
            : '-'
      });
    }

    allowAutoBind &&
      autoRenewalToggle &&
      renewalStatus !== 'Purchased' &&
      policyItemsArray.push({
        label: autoRenewalConfiguration?.label,
        isTogglable: true,
        togglableOnChange: autoRenewalToggle,
        togglableLoading: togglableLoading,
        value: autoRenewalFlag,
        additionalBlurb: autoRenewalConfiguration?.description
      });

    allowAutoBind &&
      autoRenewalToggle &&
      renewalStatus === 'Purchased' &&
      policyItemsArray.push({
        label: autoRenewalConfiguration?.completedLabel,
        value: true,
        additionalBlurb: autoRenewalConfiguration?.completedDescription
      });

    return policyItemsArray;
  } else {
    let policyItemsArray: IListItem[] = [
      {
        label: 'Policy name',
        value: policy.label ? policy.label : policy.product?.name
      },
      {
        label: 'Policy reference',
        value: policy.policyReferenceID
      }
    ];

    if (showPurchasedDate){
      policyItemsArray.push({
        label: 'Purchased date',
        value: toHumanReadable(policy.localDateCreated)
      });
    }

    policyItemsArray.push({
      label: coverStartLabel ? coverStartLabel : 'Cover starts',
      value: toHumanReadable(policy.coverStartDate)
    });
    policyItemsArray.push({
      label: coverEndsLabel ? coverEndsLabel : 'Cover ends',
      value: toHumanReadable(policy.coverEndDate)
    });

    if (creditAgreement) {
      policyItemsArray.push({
        label: 'Payment frequency',
        value: paymentPlanToHumanReadable(
          creditAgreement.paymentPlanType,
          paymentPlanLabels
        )
      });
    } else {
      policyItemsArray.push({
        label: 'Payment method',
        value: policy.paymentMethod
      });
    }
    if (totalPaid?.total) {
      policyItemsArray.push({
        label: 'Total amount paid',
        value: currencyToHumanReadable(totalPaid.total.gross)
      });
    } else {
      //We will have this in Opus
      if (policy.latestPolicyHistory) {
        policyItemsArray.push({
          label: 'Premium paid',
          value: currencyToHumanReadable(policy.latestPolicyHistory.priceGross)
        });
      }
    }

    if (discountInfo) {
      policyItemsArray.push({
        label: `Discount (${discountInfo.description})`,
        value:
          discountInfo.discountPercent > 0
            ? `${discountInfo.discountPercent * 100}%`
            : discountInfo.amount.value > 0
            ? `${currencyToHumanReadable(discountInfo.amount)}`
            : '-'
      });
    }

    allowAutoBind &&
      autoRenewalToggle &&
      renewalStatus !== 'Purchased' &&
      policyItemsArray.push({
        label: autoRenewalConfiguration?.label,
        isTogglable: true,
        togglableOnChange: autoRenewalToggle,
        togglableLoading: togglableLoading,
        value: autoRenewalFlag,
        additionalBlurb: autoRenewalConfiguration?.description
      });

    allowAutoBind &&
      autoRenewalToggle &&
      renewalStatus === 'Purchased' &&
      policyItemsArray.push({
        label: autoRenewalConfiguration?.completedLabel,
        value: true,
        additionalBlurb: autoRenewalConfiguration?.completedDescription
      });

    return policyItemsArray;
  }
}
