import * as React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BladeAppLoader: React.FunctionComponent<IBladeAppLoader> = ({
  off
}) => {
  const [isOff, setOff] = React.useState<boolean>(off);

  React.useEffect(() => {
    if (off === true) {
      setTimeout(() => {
        setOff(true);
      }, 2200);
    } else if (off === false) {
      setOff(false);
    }
  }, [off]);

  return (
    <div
      className={cx(css.appLoaderMask, {
        [css.appLoaderOff]: off,
        [css.appLoaderRemove]: isOff
      })}
    >
      <div className={css.appLoader}>
        <div className={css.appLoaderLine}></div>
        <div className={css.appLoaderLine}></div>
        <div className={css.appLoaderLine}></div>
      </div>
      {/* <div className={css.appLoaderText}>Just a moment...</div>  */}
    </div>
  );
};

export interface IBladeAppLoader {
  off: boolean;
}
