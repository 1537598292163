import * as React from 'react';
import { BladePriceDisplay } from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { PricingPlan, MtaInformation, QuoteType } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { getDisplayPrice } from '../../services/get-display-price';
import { getDisplayPriceForAdjust } from '../../services/get-display-price-for-adjust';
import { findPaymentPlanConfiguration } from '../../services/find-payment-plan-configuration';
import {
  getDisplayFinePrint,
  configDrivenFeeFinePrint
} from '../../services/get-display-fine-print';
import { getDisplayFinePrintForAdjust } from '../../services/get-display-fine-print-for-adjust';

const getPriceDisplayTitle = (
  productConfiguration: InsuranceProductConfiguration,
  quoteType?: QuoteType,
  hideTitle?: boolean
): undefined | string => {
  if (hideTitle) {
    return undefined;
  }
  switch (quoteType || 'NewBusiness') {
    case 'MTA':
      return productConfiguration.pricing.displayTitleForAdjust;
    case 'Renewal':
      return productConfiguration.pricing.displayTitleForRenew;
    case 'NewBusiness':
    case 'Vri':
    default:
      return productConfiguration.pricing.displayTitleForNew;
  }
};

export const PriceDisplay: React.FunctionComponent<IPriceDisplay> = props => {
  return (
    <BladePriceDisplay
      viewVariant={'s'}
      title={getPriceDisplayTitle(
        props.productConfiguration,
        props.quoteType,
        props.hideTitle
      )}
      isAdjust={props.adjustPlan ? true : false}
      price={
        props.adjustPlan
          ? getDisplayPriceForAdjust(props.adjustPlan)
          : props.plan && getDisplayPrice(props.plan)
      }
      alternatePrice={
        props.alternatePlan
          ? `(or ${currencyToHumanReadable(
              getDisplayPrice(props.alternatePlan)
            )} ${
              findPaymentPlanConfiguration(
                props.alternatePlan.paymentPlan.type,
                props.productConfiguration.pricing.paymentPlans
              )?.labelShorthand
            } )`
          : undefined
      }
      paymentPeriod={
        props.adjustPlan
          ? findPaymentPlanConfiguration(
              props.adjustPlan.paymentPlan.type,
              props.productConfiguration.pricing.paymentPlans
            )?.labelShorthand
          : props.plan
          ? findPaymentPlanConfiguration(
              props.plan.paymentPlan.type,
              props.productConfiguration.pricing.paymentPlans
            )?.labelShorthand
          : undefined
      }
      smallPrint={
        props.adjustPlan
          ? getDisplayFinePrintForAdjust(
              props.productConfiguration.pricing,
              props.adjustPlan
            )
          : props.plan &&
            getDisplayFinePrint(props.plan, props.productConfiguration.pricing)
      }
      showSmallPrintAsterisk={
        props.plan &&
        configDrivenFeeFinePrint(
          props.plan,
          props.productConfiguration.pricing
        ) !== ''
      }
      isUpdatingPrice={props.isUpdatingPrice}
      isDeclined={props.isDeclined}
      useStickyMode={props.useStickyMode}
      onStickyModeChange={props.onStickyModeChange}
    />
  );
};

interface IPriceDisplay {
  plan?: PricingPlan;
  alternatePlan?: PricingPlan;
  adjustPlan?: MtaInformation;
  productConfiguration: InsuranceProductConfiguration;
  isUpdatingPrice?: boolean;
  isDeclined?: boolean;
  hideTitle?: boolean;
  useStickyMode?: boolean;
  onStickyModeChange?: (isSticky: boolean) => void;
  quoteType?: QuoteType;
}
