import * as React from 'react';
import { Quote } from '@aventus/platform';
import {
  BladeView,
  BladeSurface,
  BladeButton,
  BladeMarkdown
} from '@aventus/blade';
import { useDocumentTitle } from '@aventus/pockethooks';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { useTrackView } from '@aventus/application-tracking';
import css from './index.css';

export const IntelligentQuoteReferred: React.FunctionComponent<
  IIntelligentQuoteReferred
> = props => {
  useDocumentTitle(props.documentTitle ?? 'Quote Referred');
  useTrackView('IntelligentQuoteReferred');

  return (
    <BladeView variant={'s'} className={css.referred}>
      <BladeSurface
        title={props?.title || 'Your quote has been referred'}
        shouldRespectViewWidth={true}
      >
        {props.subTitle && (
          <h3 className={css.referred_subTitle}>{props.subTitle}</h3>
        )}
        <div className={css.referred_bodyContent}>
          <BladeMarkdown
            markdown={stringReplacer(
              props.bodyContent ||
                `
We're sorry, but we weren't able to offer you a quote online.

You've been referred to our customer support team, who will be in
touch soon with more options.

If you'd like to discuss this further, contact us using the links and details
provided above and quote reference $$QUOTEREF.
              `,
              {
                $$QUOTEREF: props.quote.quoteReferenceID
              }
            )}
          />
        </div>

        <div className={css.referred_trigger}>
          <BladeButton onClick={() => props.onAcknowledgement()}>
            {props.acknowledgementLabel || 'Ok'}
          </BladeButton>
        </div>
      </BladeSurface>
    </BladeView>
  );
};

export interface IIntelligentQuoteReferred {
  documentTitle?: string;
  title?: string;
  subTitle?: string;
  bodyContent?: string;
  quote: Quote;
  supportLink?: string;
  acknowledgementLabel?: string;
  onAcknowledgement: (event?: React.ChangeEvent<HTMLButtonElement>) => void;
}
