import React from 'react';
import { isJwtExpired } from 'jwt-check-expiration';
import { isString } from 'lodash';
import { Route } from 'react-router-dom';
import appApi from '../../../api';
import routes from '../../../routes';

// We want to restrict some routes so they are only accessible to guest or signed in users
// This needs to run inside componentDidMount to avoid redirect issues with the midAuthFlow
// and componentWillReceiveProps
// We can check to see if the userType matches the route restriction in the RestrictedRoute class

export default ({ component: Component, ...routeProps }) => (
  <Route {...routeProps} render={(props) => (
    routeProps.guestOnly || routeProps.userOnly
      ?
        <RestrictedRouteComponent routeProps={routeProps}>
          <Component {...props} />
        </RestrictedRouteComponent>
      :
        <Component {...props} />
  )} />
);

class RestrictedRoute extends React.Component {
  componentDidMount() {
    const hasUserState = this.props.app.auth.user !== false;
    const hasExpiredToken =
      hasUserState &&
      isString(this.props.app.auth.user.token) &&
      isJwtExpired(this.props.app.auth.user.token);

    // If we have an expired token, do a silent sign-out now.
    if (hasExpiredToken) {
      this.props.handleSilentAuthSignOut();
    }

    // We're a guest if we dont have a user state or we do but we've got an
    // expired token.
    const isGuest = !hasUserState || hasExpiredToken;
    const { guestOnly, userOnly } = this.props.routeProps;

    if (guestOnly && !isGuest) {
      this.queueAlertAndRedirect('You are already logged in');
    } else if (userOnly && isGuest) {
      this.queueAlertAndRedirect('You must be logged in to view this page');
    }
  }

  queueAlertAndRedirect(message) {
    this.props.handleQueueAlert({
      message: message,
      location: routes.home.path,
      type: 'error'
    });
    this.props.history.replace(routes.home.path);
  }

  render() {
    return this.props.children
  }
}

const RestrictedRouteComponent = appApi(RestrictedRoute);
