import * as React from 'react';
import Switch from 'react-toggle-switch';
import cx from 'classnames';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { getAtomValue } from '@aventus/honey-utility';
import { BubbleLoader } from 'react-css-loaders';
import css from './index.css';
import './switch.css';

export const BladeInputSwitch: React.FunctionComponent<IBladeInputSwitch> = props => {
  const inputProps = useInput(props);

  const [primaryColour, setPrimaryColour] = React.useState<string>('');

  React.useEffect(() => {
    const _primaryColour = getAtomValue('--primary');
    _primaryColour && setPrimaryColour(_primaryColour);
  }, []);

  if (inputProps.value === null || inputProps.value === undefined) {
    inputProps.onChange(false);
  }

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      {!props.loading && (
        <div
          className={cx(
            'blade-input-switch',
            css.switchWrap,
            inputProps.className
          )}
        >
          <Switch
            onClick={() => {
              props.onChange(!inputProps.value);
            }}
            on={inputProps.value || false}
            className={'switch'}
          />

          <BladeInput_Label id={inputProps.id} label={inputProps.label} />
        </div>
      )}
      {props.loading && (
        <div
          className={cx(
            'blade-input-switch',
            css.switchWrap,
            inputProps.className
          )}
        >
          <BubbleLoader
            style={{ margin: '0' }}
            size={'3'}
            color={primaryColour}
          ></BubbleLoader>
        </div>
      )}
    </BladeInput_Frame>
  );
};

export interface IBladeInputSwitch extends IBladeInput {
  loading?: boolean;
}
