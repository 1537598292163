export function isFlat (value: FlatPartial, _allValues: any, props: any): string | undefined {
  return (
    value.text.toLowerCase() === 'flat' &&
    (props.coverType === 'homebuildings' || props.coverType === 'homebuildingsandcontents')
      ? 'We cannot provide Home Buildings Insurance Cover on flats. Please select Home Contents Insurance Cover to continue'
      : undefined
  )
}

interface FlatPartial {
  text: string,
  [propName: string]: any
}
