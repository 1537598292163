import { AxiosResponse, AxiosError } from 'axios';
import { MotorWebRecoverableError } from '../errors/motorweb-recoverable-error';
import { MotorWebUnrecoverableError } from '../errors/motorweb-unrecoverable-error';

export const interceptResponse = {
  onFulfilled,
  onRejected
}

export function onFulfilled (response: AxiosResponse<any>) {
  return response;
}

export function onRejected (error: AxiosError<any>) {

  if (error.response?.data.error === 1103) {
    throw new MotorWebRecoverableError(
      "We couldn't find a car matching the details provided, please try again or enter car details manually."
    );
  }

  if (error.response?.data.error === 1000) {
    throw new MotorWebRecoverableError(
      "We couldn't find a car matching the details provided, please try again or enter car details manually."
    );
  }

  throw new MotorWebUnrecoverableError(
    "Woops, we encountered an error. Please try again or contact us for any help"
  );

}
