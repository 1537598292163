import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { CustomConfig } from '@aventus/platform';
import { useQuery } from 'react-query';

export const useGetCustomConfig = () => {
  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();

  const { data: customConfig, isLoading: isGettingCustomConfig } =
    useQuery<CustomConfig>(['config', 'app'], symphonyClient.getCustomConfig, {
      onError: () => {
        throwError(new Error('Unable to get custom application config'));
      }
    });

  return {
    customConfig,
    isGettingCustomConfig
  };
};
