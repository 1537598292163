import update from 'immutability-helper';
import { initializeApp } from "firebase/app";
import createFirebaseApp from '../../../../middleware/firebase/initalizeApp';


export default function setAuthConfigReducer(state, action) {
  const { res } = action;

  if (res.providers.includes('Firebase'))
  {
    createFirebaseApp(res.firebase);
  }

  return {
    ...state,
    ...{
      core: update(state.core, {
        config: {
          auth0: { $set: res }
        },
        _flags: {
          $merge: {
            isGettingAuth0Config: false,
            successfullyGotAuth0Config: true
          }
        }
      })
    }
  }
}

