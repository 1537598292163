import update from 'immutability-helper';

export function signInRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isSigningIn: { $set: true }
        }
      })
    }
  };
}

export function signInSuccessReducer(state, action) {
  const { access_token, username } = action.data;
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        user: {
          $set: {
            email: username,
            token: access_token,
            accountType: 'homelyfe'
          }
        },
        _flags: {
          isSigningIn: { $set: false },
          successfullySignedIn: { $set: true }
        }
      })
    }
  };
}

export function signInFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isSigningIn: false,
            successfullySignedIn: false,
            signInErrorMessage: action.errorMessage
          }
        }
      })
    }
  };
}
