import * as React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BladeView: React.FunctionComponent<IBladeView> = props => {
  return (
    <div
      className={cx(
        'blade-view',
        css.view,
        getClassnameFromVariant(props.variant),
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export interface IBladeView {
  variant: BladeViewVariant;
  className?: string;
}

export type BladeViewVariant = 'xs' | 's' | 'm' | 'l' | 'xl' | '%';

function getClassnameFromVariant(variant: BladeViewVariant): string {
  switch (variant) {
    case 'xs':
      return css.view_xs;
    case 's':
      return css.view_s;
    case 'm':
      return css.view_m;
    case 'l':
      return css.view_l;
    case 'xl':
      return css.view_xl;
    default:
      return '';
  }
}
