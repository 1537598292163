import { CLEAR_LOCAL_STORAGE } from './types';
import signOutAction from '../../auth/sign-out/actions';

export default function clearLocalStorage() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LOCAL_STORAGE
    });
    localStorage.removeItem('state');
    dispatch(signOutAction());
  }
}
