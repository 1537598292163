import React from 'react';
import appApi from '../../../api';
import { BladeButtonSocialAuth } from '@aventus/blade';
import _redirectUri from './_redirect-uri';

class SocialAuth extends React.Component {

  constructor(props) {
    super(props);
    this.generateAuthUrl = this.generateAuthUrl.bind(this);
    this.returnTypeConfig = this.returnTypeConfig.bind(this);
    this.redirectUri = _redirectUri.bind(this);
  }

  generateAuthUrl() {
    const { env, auth0 } = this.props.app.core.config;
    const { audience, url } = auth0;
    const { nonce } = this.props.app.auth;
    const config = this.returnTypeConfig();
    const scope = config.scope;
    const connection = config.connection;
    const responseType = `token%20id_token`;
    const clientId = env.AUTH0_CLIENT_ID_WEB;
    const redirectUri = this.redirectUri();
    return `${url}/authorize?audience=${audience}&response_type=${responseType}&scope=${scope}&client_id=${clientId}&connection=${connection}&redirect_uri=${redirectUri}&nonce=${nonce}`;
  }

  returnTypeConfig() {
    const { type } = this.props;
    const { connections } = this.props.app.core.config.auth0;

    switch(type) {
      case 'google':
        return {
          connection: connections.google,
          scope: 'openid%20profile%20email%20read%20plus',
        }
      // Facebook is not live due to phone number logins
      // case 'facebook':
      //   return {
      //     connection: 'facebook',
      //     scope: 'openid%20profile%20email'
      //   }
      default:
        console.warn('invalid social auth type');
        return {};
    }
  }

  render() {
    return (
      <BladeButtonSocialAuth
        variant={ this.props.type }
        authUrl={ this.generateAuthUrl() }
        callToAction={ this.props.label } />
    );
  }
}

export default appApi(SocialAuth);
