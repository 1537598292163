import * as React from 'react';
import { PaymentPlanType, ProductReference, ProductCoverReference } from '@aventus/platform';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration,
  useGetProductInterfaceConfiguration,
  useGetOraclesConfiguration
} from '@aventus/configuration-client-context';
import {
  useGetOrganisationSettings,
  useGetQuote,
  useGetPolicy
} from '@aventus/symphony-client/hooks';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useAskForRenew } from './use-ask-for-renew';
import { ServerError } from '@aventus/errors';

export const AskForRenew: React.FunctionComponent<IAskForRenew> = props => {
  // With renewals, we can either start a quote for an existing renewal quote,
  // or the ID of the policy to be renewed.

  const policyId: string = queryParamGet(props.location.search, 'policyId');
  const quoteId: string = queryParamGet(props.location.search, 'quoteId');

  // We need to fetch the policy object so that we can pick out the
  // product reference and the product cover reference, to in turn
  // pick out the correct insurance product configuration.

  const [productReference, setProductReference] = React.useState<
    ProductReference | undefined
  >(undefined);
  const [productCoverReference, setProductCoverReference] = React.useState<
    ProductCoverReference | undefined
  >(undefined);

  // Given these two references, we can pick out the specific configuration
  // for this insurance product, as well as the organisation configuration.

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    productReference,
    productCoverReference
  );
  const { oraclesConfiguration } = useGetOraclesConfiguration();

  // If we're loading an existing quote or a policy, because all we've got is the
  // id, then we need to fetch the quote or policy object and pick out the product
  // and product cover reference to be able to load the correct configuration
  // file for that product.

  // Note, that these hooks are designed to only run if an actual ID is passed,
  // so we can safely run both side-by-side knowing only one will be
  // run, depending on which ID is passed into the query params.

  const { policy } = useGetPolicy(policyId);

  React.useEffect(() => {
    if (policy) {
      setProductReference(policy.productReferenceID.toLowerCase());
      setProductCoverReference(policy.coverType.toLowerCase());
    }
  }, [policy]);

  const { quoteBundle } = useGetQuote(quoteId);

  React.useEffect(() => {
    if (quoteBundle) {
      setProductReference(
        quoteBundle.requestedQuote.productReferenceID.toLowerCase()
      );
      setProductCoverReference(
        quoteBundle.requestedQuote.coverType.toLowerCase()
      );
    }
  }, [quoteBundle]);

  // As mentioned above, we can initialise an adjustment
  // only with the original policy's ID.

  const iq = useAskForRenew(quoteId);

  // Lastly we need to fetch the currency settings for this particular
  // organisation.

  const { organisationSettings } = useGetOrganisationSettings();

  React.useEffect(() => {
    props.setRateError(iq.rateError);
  }, [iq.rateError]);

  // Once we've got the product configuration,
  // we can assume we've done all the preparing we need to,
  // so we can go ahead and load the quote form.
  // Note that we don't need to wait for any of the IQ async
  // actions or initialisation. This is because the component
  // knows how to handle the IQ object in any of it's possible
  // states.

  if (
    productConfiguration &&
    organisationConfiguration &&
    productInterfaceConfiguration &&
    policy &&
    organisationSettings &&
    oraclesConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        defaultPaymentPlanType={props.defaultPaymentPlanType}
        goHome={props.goHome}
        currencyCode={organisationSettings.currencyCode}
        currencySymbol={organisationSettings.currencySymbol}
        countryCode={organisationSettings.defaultCountryCode}
        dateFormat={organisationSettings.dateFormat}
        organisationConfiguration={organisationConfiguration}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        oraclesConfiguration={oraclesConfiguration}
        renewalConfirmationConfirmLink={()=> {props.renewalConfirmationLink(policy?.id)}}
        getHomeRoute={props.getHomeRoute}
        getSummaryRoute={props.getSummaryRoute}
        skipConfirmationPage={false}
        quoteType='Renewal'
      />
    );
  }

  return null;
};

interface IAskForRenew extends IIntelligentQuote {
  defaultPaymentPlanType?: PaymentPlanType;
  renewalConfirmationLink: (policy: string)=> void;
  setRateError: React.Dispatch<React.SetStateAction<ServerError | undefined>>;
}
