import * as React from 'react';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { BladeBadge } from '../badge';
import css from './index.css';
import cx from 'classnames';

export const BladePriceSimple: React.FunctionComponent<IBladePriceSimple> = props => {
  const isPriceDiff = props.isPriceDiff || false;
  const priceObj = currencyToHumanReadable(props.price).match(
    /^(([+-])?[\$£€]?)((\d{1,3})*([\,\ ]\d{3})*)(\.(\d+))?$/
  );
  const currencySymbol = priceObj ? priceObj[1] : '';
  const prefix = isPriceDiff && props.price.value > 0 ? '+' : '';
  const priceParts = [priceObj ? priceObj[3] : '', priceObj ? priceObj[6] : ''];
  const isNoFee = props.price.value === 0;

  return (
    <div className={cx('blade-price', css.price)}>
      <div className={css.price_summary}>
        {!isNoFee && (
          <React.Fragment>
            <label className={css.price_summary_symbol}>
              {prefix}
              {currencySymbol}
            </label>

            <label className={css.price_summary_major}>{priceParts[0]}</label>

            {priceParts[1] && priceParts[1] != '' && (
              <label className={css.price_summary_minor}>
                {`${priceParts[1]}`}
              </label>
            )}
          </React.Fragment>
        )}

        {isNoFee && (
          <label className={css.price_summary_major}>{'No fee'}</label>
        )}
      </div>

      {props.badge && (
        <BladeBadge className={css.price_badge} label={props.badge} />
      )}
    </div>
  );
};

export interface IBladePriceSimple {
  price: Money;
  isPriceDiff?: boolean;
  badge?: string;
}

interface Money {
  value: number;
  currencyCode: string;
}
