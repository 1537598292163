import update from 'immutability-helper';

export default function updatePrivacyPolicyVersionReducer(state, action) {
  const { privacyPolicyVersion } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        privacyPolicyVersion: { $set: privacyPolicyVersion }
      })
    }
  };
}
