export function mustBeAMonetaryValue (value: Money | number): string | undefined {

  if (typeof value === 'object' && value.value) {
    return (
      value.value >= 0
        ? undefined
        : 'Must be a valid currency amount'
    );
  }

  return (
    value >= 0
      ? undefined
      : 'Must be a valid currency amount'
  );

}

interface Money {
  value: number,
  currencyCode: string
}
