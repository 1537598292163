import { ICompiler, COMPILE_TYPES } from '@aventus/honey-definitions';
import { atomsReduce } from './atoms-reduce';
import { moleculesReduce } from './molecules-reduce';

export const honeyCompilerCSS: ICompiler = {

  name: '@aventus/honey-compiler-css',

  atomsReduce: atomsReduce,
  moleculesReduce: moleculesReduce,

  compileType: COMPILE_TYPES.compile,
  compileTo: '.css'
};
