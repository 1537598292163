import {
  AUTH_START_BASIC_AUTH_REQUEST,
  AUTH_START_BASIC_AUTH_SUCCESS,
  AUTH_START_BASIC_AUTH_FAILURE
} from '../start-basic-auth/types';
import { API_SYM } from '@aventus/middleware-api';

export function startBasicAuthAction(username, password) {
  let headers = {};

  if (username && password) {
    headers = {
      Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
        'base64'
      )}`
    };
  }

  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'auth',
        method: 'GET',
        data: {},
        headers
      },
      actions: [
        startBasicAuthRequestAction,
        startBasicAuthSuccessAction,
        startBasicAuthFailureAction
      ]
    }
  };
}

export function startBasicAuthRequestAction() {
  return {
    type: AUTH_START_BASIC_AUTH_REQUEST
  };
}

export function startBasicAuthSuccessAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_START_BASIC_AUTH_SUCCESS,
      hasLoggedInPreviously: res
    });
  };
}

export function startBasicAuthFailureAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_START_BASIC_AUTH_FAILURE
    });
  };
}
