export default {
  initialised: {
    path: '/',
    trackingName: 'Dashboard',
    documentTitle: 'Dashboard'
  },
  home: {
    path: '/dashboard',
    trackingName: 'Dashboard',
    documentTitle: 'Dashboard'
  },
  termsAndConditions: {
    path: '/terms-and-conditions',
    trackingName: 'Terms and Conditions',
    documentTitle: 'App Terms and conditions'
  },
  privacyPolicy: {
    path: '/privacy-policy',
    trackingName: 'Privacy Policy',
    documentTitle: 'App Privacy Policy'
  },
  support: {
    path: '/support',
    trackingName: 'Support',
    documentTitle: 'Support'
  },

  signUp: {
    path: '/sign-up',
    trackingName: 'Sign Up',
    documentTitle: 'Sign up'
  },
  signIn: {
    path: '/sign-in',
    trackingName: 'Sign In',
    documentTitle: 'Sign in'
  },
  resetPassword: {
    path: '/reset',
    trackingName: 'Reset Password Request',
    documentTitle: 'Reset your password'
  },
  resetPasswordNewPassword: {
    path: '/reset/:resetToken',
    trackingName: 'Reset Password New Password',
    documentTitle: 'Reset your password'
  },
  changePassword: {
    path: '/change-password',
    trackingName: 'Change Password',
    documentTitle: 'Change your password'
  },
  createAccount: {
    path: '/create-account',
    trackingName: 'Create Account',
    documentTitle: 'Create your account'
  },
  createAccountPassword: {
    path: '/create-account/:token',
    trackingName: 'Create your account Password',
    documentTitle: 'Create your account'
  },
  socialCallback: {
    path: '/social-callback',
    trackingName: 'Social Auth Callback',
    documentTitle: 'Sign In'
  },

  widgets: {
    path: '/widgets',
    trackingName: 'No tracking',
    documentTitle: ''
  }
};
