import * as React from 'react';

import { BladeLink } from '../../../../link';
import { IBladeItemArrayItem } from '../..';
import { ItemForm } from '../itemForm';
import css from './index.css';
import { interpolate } from '@aventus/pocketknife/interpolate';

export const ItemElement: React.FunctionComponent<IItemElement> = props => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  if (isEditMode) {
    if (props.isFormModified !== props.itemIndex) {
      setIsEditMode(false);
    }
    return (
      <ItemForm
        getQuestions={props.getQuestions}
        formActions={isFormValid =>
          props.modifyItem(isFormValid, setIsEditMode, props.itemIndex)
        }
      />
    );
  }

  // retrieve label and format as a load of spans for mobile layout reasons
  const getLabel = () => {
    const displayText = interpolate(
      props.values,
      props.itemLabel || 'Item',
      '[Missing]'
    );
    return displayText
      .split(' ')
      .filter(split => split !== '')
      .map(item => {
        return <span>{item} </span>;
      });
  };

  return (
    <>
      <div className={css.collection_element}>
        <div>
          <label>{getLabel()}</label>
        </div>
        <div>
          {props.canModify && (
            <BladeLink
              onClick={() => {
                props.setFormState({ [props.itemKey]: props.values });
                props.setIsFormOpen(false);
                props.setIsFormModified(props.itemIndex);
                setIsEditMode(!isEditMode);
              }}
              className={css.collection_element_footer_link}
            >
              Update
            </BladeLink>
          )}

          {props.canDelete && (
            <BladeLink
              onClick={() => props.deleteItem()}
              className={css.collection_element_footer_link}
            >
              Remove
            </BladeLink>
          )}
        </div>
      </div>
    </>
  );
};

export interface IItemElement {
  isFormModified: number | undefined;
  setIsFormModified: (value: number | undefined) => void;
  modifyItem: (
    isFormValid: boolean,
    setIsEditMode: (value: boolean) => void,
    itemIndex: number
  ) => React.ReactNode;
  deleteItem: () => void;
  values: IBladeItemArrayItem;
  setIsFormOpen: (value: boolean) => void;
  setFormState: (value: any) => void;
  getQuestions: (
    validation: any,
    dispatchValidation: any
  ) => Array<React.ReactNode>;
  itemKey: number | string;
  itemIndex: number;
  itemLabel: string | null;
  canModify: boolean;
  canDelete: boolean;
}
