import React from 'react';
import {
  BladeButton,
  BladeFormFieldsetAddressInternationalV7,
  BladeInputNumber,
  BladeInputSelect,
  BladeInputText
} from '@aventus/blade';
import { Controller, useForm } from 'react-hook-form';
import { EFieldMessages } from '../..';
import css from '../../index.css';
import cx from 'classnames';
import { switchAddressOracle } from '@aventus/mvmt-quote/services/switch-address-oracle';
import { useGetOraclesConfiguration } from '@aventus/configuration-client-context';
import { IUniversalAddress } from '@aventus/platform-client-context/models/policyholder';
import countries from '@aventus/mvmt-quote/data/countries.json';

const AddressHistory = (props: IBNPAddressHistoryProps): JSX.Element => {
  const { control, handleSubmit, errors, watch } = useForm<any>({
    defaultValues: {
      currentAddress: props.currentAddress,
      previousAddress: props.previousAddress,
      yearsAtCurrentAddress: props.yearsAtCurrentAddress,
      yearsAtPreviousAddress: props.yearsAtPreviousAddress
    }
  });

  const { oraclesConfiguration: oracles } = useGetOraclesConfiguration();

  const onSubmit = (data: IAddressHistoryForm) => {
    props.updateStore(data);
    props.nextStep();
  };

  const onError = () => {
    if (typeof props.validationError === 'function') {
      props.validationError(
        'Some fields are incomplete, please check the form and try again'
      );
    }
  };

  const yearsAtCurrentAddress = watch('yearsAtCurrentAddress');

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={css.bnpFieldWrapper}>
        <Controller
          name={'currentAddress'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <label>Current address</label>
                <BladeFormFieldsetAddressInternationalV7
                  isGroup={true}
                  selectedCountry={'GB'}
                  renderOracle={
                    oracles?.address?.name && oracles?.address?.url
                      ? switchAddressOracle(
                          {
                            value: props.value,
                            name: props.name,
                            onChange: props.onChange,
                            onBlur: props.onBlur
                          },
                          oracles.address.name,
                          oracles.address.url,
                          oracles.address.token,
                          oracles.misc?.text
                        )
                      : null
                  }
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                >
                  <BladeInputText
                    key={'q_line1'}
                    label={'Line 1'}
                    value={(props.value && props.value.line1) || ''}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ line1: value }
                      });
                    }}
                    name={'line1'}
                  />
                  <BladeInputText
                    label={'Line 2'}
                    value={(props.value && props.value.line2) || ''}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ line2: value }
                      });
                    }}
                    name={'line2'}
                    key={'q_line2'}
                  />
                  <BladeInputText
                    label={'Line 3'}
                    value={(props.value && props.value.line3) || ''}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ line3: value }
                      });
                    }}
                    name={'line3'}
                    key={'q_line3'}
                  />
                  <BladeInputText
                    label={'Town'}
                    value={(props.value && props.value.locality) || ''}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ locality: value }
                      });
                    }}
                    name={'locality'}
                    key={'q_locality'}
                  />
                  <BladeInputText
                    label={'Postal code'}
                    value={(props.value && props.value.postalCode) || ''}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ postalCode: value }
                      });
                    }}
                    name={'postalCode'}
                    key={'q_postalCode'}
                  />
                  <BladeInputSelect
                    value={(props.value && props.value.country) || undefined}
                    onChange={value => {
                      props.onChange({
                        ...props.value,
                        ...{ country: value }
                      });
                    }}
                    key={'q_country'}
                    name={'country'}
                    label={'Country'}
                    options={countries}
                    matchOn="referenceID"
                    labelOn="text"
                  />
                </BladeFormFieldsetAddressInternationalV7>
                {errors && 'currentAddress' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.currentAddress?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'yearsAtCurrentAddress'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <BladeInputNumber
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  label={'Years at current address'}
                />
                {errors && 'yearsAtCurrentAddress' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.yearsAtCurrentAddress?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        {yearsAtCurrentAddress && Number(yearsAtCurrentAddress) < 3 && (
          <>
            <Controller
              name={'previousAddress'}
              control={control}
              rules={{ required: EFieldMessages.required }}
              render={props => {
                return (
                  <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                    <label>Previous address</label>
                    <BladeFormFieldsetAddressInternationalV7
                      isGroup={true}
                      selectedCountry={'GB'}
                      renderOracle={
                        oracles?.address?.name && oracles?.address?.url
                          ? switchAddressOracle(
                              {
                                value: props.value,
                                name: props.name,
                                onChange: props.onChange,
                                onBlur: props.onBlur
                              },
                              oracles.address.name,
                              oracles.address.url,
                              oracles.address.token,
                              oracles.misc?.text
                            )
                          : null
                      }
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      name={props.name}
                    >
                      <BladeInputText
                        key={'q_line1'}
                        label={'Line 1'}
                        value={(props.value && props.value.line1) || ''}
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ line1: value }
                          });
                        }}
                        name={'line1'}
                      />
                      <BladeInputText
                        label={'Line 2'}
                        value={(props.value && props.value.line2) || ''}
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ line2: value }
                          });
                        }}
                        name={'line2'}
                        key={'q_line2'}
                      />
                      <BladeInputText
                        label={'Line 3'}
                        value={(props.value && props.value.line3) || ''}
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ line3: value }
                          });
                        }}
                        name={'line3'}
                        key={'q_line3'}
                      />
                      <BladeInputText
                        label={'Town'}
                        value={(props.value && props.value.locality) || ''}
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ locality: value }
                          });
                        }}
                        name={'locality'}
                        key={'q_locality'}
                      />
                      <BladeInputText
                        label={'Postal code'}
                        value={(props.value && props.value.postalCode) || ''}
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ postalCode: value }
                          });
                        }}
                        name={'postalCode'}
                        key={'q_postalCode'}
                      />
                      <BladeInputSelect
                        value={
                          (props.value && props.value.country) || undefined
                        }
                        onChange={value => {
                          props.onChange({
                            ...props.value,
                            ...{ country: value }
                          });
                        }}
                        key={'q_country'}
                        name={'country'}
                        label={'Country'}
                        options={countries}
                        matchOn="referenceID"
                        labelOn="text"
                      />
                    </BladeFormFieldsetAddressInternationalV7>
                    {errors && 'previousAddress' in errors && (
                      <span className={css.bnpFieldError}>
                        {errors.previousAddress?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <Controller
              name={'yearsAtPreviousAddress'}
              control={control}
              rules={{ required: EFieldMessages.required }}
              render={props => {
                return (
                  <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                    <BladeInputNumber
                      value={props.value}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      name={props.name}
                      label={'Years at previous address'}
                    />
                    {errors && 'yearsAtPreviousAddress' in errors && (
                      <span className={css.bnpFieldError}>
                        {errors.yearsAtPreviousAddress?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
          </>
        )}
      </div>
      <div className={css.bnpButtonWrap}>
        <BladeButton
          type="button"
          onClick={() => props.prevStep()}
          variant="secondary"
        >
          Previous
        </BladeButton>
        <BladeButton type="submit" variant="primary">
          Continue
        </BladeButton>
      </div>
    </form>
  );
};

interface IAddressHistoryForm {
  currentAddress: IUniversalAddress;
  previousAddress: IUniversalAddress | null;
  yearsAtCurrentAddress: string;
  yearsAtPreviousAddress: string | null;
}

interface IBNPAddressHistoryProps {
  currentAddress: IUniversalAddress | null;
  previousAddress: IUniversalAddress | null;
  yearsAtCurrentAddress: string | null;
  yearsAtPreviousAddress: string | null;
  nextStep: () => void;
  prevStep: () => void;
  updateStore: (data: IAddressHistoryForm) => void;
  validationError?: (message: string) => void;
}

export default AddressHistory;
