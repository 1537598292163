import * as React from 'react';
import { createMotorWebClient } from '../../client';
import { createGlassesClient } from '../../../oracle-glasses/client';
import { MotorWebClient } from '../../client/types';
import { GlassesClient } from '../../../oracle-glasses/client/types';
import { IAventusCarDetails } from '@aventus/oracle-glasses/components/car-details/types';
import { useError } from '@aventus/pockethooks/use-error';
import { RegisteredState } from '@aventus/blade/form-fieldset/motor/manual-registration/types';
import { GlassesUnrecoverableError } from '@aventus/oracle-glasses/errors/glasses-unrecoverable-error';

export function useEnterCarDetails(
  oracleUrl: string,
  updateCarDetails: (carDetails: IAventusCarDetails) => void,
  enrichmentUrl?: string,
  enrichmentToken?: string,
) {
  const [motorWeb, setMotorWeb] = React.useState<MotorWebClient | undefined>(
    undefined
  );

  const [glasses, setGlasses] = React.useState<GlassesClient | undefined>(
    undefined
  );

  const [regOrVin, setRegOrVin] = React.useState<string>('');
  const [registeredState, setRegisteredState] = React.useState<
    RegisteredState | undefined
  >();
  const [isQuerying, setIsQuerying] = React.useState<boolean>(false);

  // Need to know whether Glasses client has be initialised
  // outside of hook to allow us to call it on render.
  // Using flag so we don't have to expose glasses itself
  // outside of the hook.
  const [isInitialised, setIsInitialised] = React.useState<boolean>(false);

  const { throwError } = useError();

  // Initialise the experian client first,
  // making sure this is only done once. Using
  // [] as the observables tells React to only
  // run this on initial mount. The conditional check
  // is just extra insurance, mind the pun.

  React.useEffect(() => {
    if (!motorWeb) {
      const _motorWeb = createMotorWebClient({ baseURL: oracleUrl });
      setMotorWeb(_motorWeb);
    }

    // Intialise Glasses for enrichment
    if (!glasses) {
      const _glasses = createGlassesClient(
        { baseURL: enrichmentUrl },
        enrichmentToken
      );
      setGlasses(_glasses);
    }
  }, []);

  React.useEffect(() => {
    if (glasses && isInitialised === false) {
      setIsInitialised(true);
    }
  }, [glasses, isInitialised]);

  // New flow for CTM where NVIC already present
  // This runs when the component renders after
  // we know that glasses is available to call.
  async function findMyCarDetails(risk: IAventusCarDetails): Promise<void> {
    if (!glasses) {
      return;
    }

    try {
      setIsQuerying(true);
      glasses
        .enrichDetails(risk.nvic)
        .then(glassesResponse => {
          updateCarDetails({
            nvic: risk.nvic,
            bodyType: glassesResponse[0].BodyName,
            variant: glassesResponse[0].VariantName,
            series: glassesResponse[0].SeriesName,
            make: glassesResponse[0].ManufacturerName,
            model: glassesResponse[0].FamilyName,
            year: glassesResponse[0].YearCreate,
            transmission: glassesResponse[0].TransmissionName,
            cc: glassesResponse[0].CCName,
            engineConfig: glassesResponse[0].Engine_ConfigName,
            vinNo: null,
            regOrVin: risk.regOrVin || null,
            registeredState: risk.registeredState || null,
            registration: risk.registration || null,
            kw: glassesResponse[0].KW,
            kerbWeight: glassesResponse[0].KerbWeight,
            segmentName: glassesResponse[0].SegmentName,
            fuelName: glassesResponse[0].FuelName
          });
        })
        .catch(error => {
          throwError(error);
        });
    } catch (error: any) {
      throw new GlassesUnrecoverableError(error);
    }
  }

  // TODO

  async function findMyCar(recaptchaToken: string) {
    if (!motorWeb || !glasses) {
      return;
    }

    setIsQuerying(true);

    try {
      // We want to always use the uppercase version of the registration, and
      // trim its spaces (even if the customer put spaces in - they're invalid)
      const trimmedRegOrVin = regOrVin.toLocaleUpperCase().trim();

      if (registeredState) {
        const motorWebresponse = await motorWeb.search(
          trimmedRegOrVin,
          registeredState.value,
          recaptchaToken
        );

        const glassesResponse = await glasses.enrichDetails(
          motorWebresponse.nvic
        );

        const vinLimit = 17; // Austrailian VIN numbers should be 17 characters

        // Stop querying before component is unmounted
        // by the updateCarDetails call. Fixes update state
        // on unmounted component issue.
        setIsQuerying(false);

        //In theory there should only be one entry for an NVIC
        updateCarDetails({
          nvic: motorWebresponse.nvic,
          bodyType: glassesResponse[0].BodyName,
          variant: glassesResponse[0].VariantName,
          series: glassesResponse[0].SeriesName,
          make: glassesResponse[0].ManufacturerName,
          model: glassesResponse[0].FamilyName,
          year: glassesResponse[0].YearCreate,
          transmission: glassesResponse[0].TransmissionName,
          cc: glassesResponse[0].CCName,
          engineConfig: glassesResponse[0].Engine_ConfigName,
          regOrVin: trimmedRegOrVin,
          registeredState: registeredState.value,
          vinNo: trimmedRegOrVin.length >= vinLimit ? trimmedRegOrVin : '',
          registration: trimmedRegOrVin.length < vinLimit ? trimmedRegOrVin : '',
          kw: glassesResponse[0].KW,
          kerbWeight: glassesResponse[0].KerbWeight,
          segmentName: glassesResponse[0].SegmentName,
          fuelName: glassesResponse[0].FuelName
        });
      }
    } catch (error) {
      throwError(error);
    }
  }

  return {
    regOrVin,
    setRegOrVin,
    registeredState,
    setRegisteredState,
    isQuerying,
    setIsQuerying,
    findMyCar,
    findMyCarDetails,
    isInitialised
  };
}
