import { IProductConfiguration } from '@aventus/platform-client';
import usePlatform from '@aventus/platform-client-context/use-platform';
import { useQuery, UseQueryResult } from 'react-query';

export function useGetProductConfigurationById(
  productID: string
): UseQueryResult<IProductConfiguration> {
  const platform = usePlatform();

  return useQuery(['product', 'configuration', productID], () =>
    platform.product.getProductConfigurationById(productID)
  );
}
