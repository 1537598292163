import * as React from 'react';
import {
  InsuranceProductConfiguration,
  PaymentProviderConfiguration
} from '@aventus/configuration';
import {
  IFatZebraVerificationProps,
  IFatZebraVerificationResponse,
  PolicyBundle,
  OrganisationSettings,
  PaymentMethod,
  IFatZebraConfigResponse
} from '@aventus/platform';
import {
  BladeInlineErrorBoundary,
  useBladeInlineError
} from '@aventus/blade/inline-error';
import {
  BladeButton,
  BladeGuide,
  BladeIcon,
  BladeLoadOver,
  BladeMessage,
  BladeSurface,
  BladeView
} from '@aventus/blade';
import { FatZebraWrapper } from '../wrapper';
import css from './index.css';
import moment from 'moment';

const UpdateCardDetailsInner: React.FunctionComponent<
  IUpdateSavedCard
> = props => {
  const { getError, setError, clearErrors } = useBladeInlineError();

  const [isUpdatingSavedCard, setIsUpdatingSavedCard] =
    React.useState<boolean>(false);

  const [isCardEntryComplete, setIsCardEntryComplete] =
    React.useState<boolean>(false);

  const [updatedPaymentMethod, setUpdatedPaymentMethod] = React.useState<
    PaymentMethod | undefined
  >();

  const [paymentRequest, setPaymentRequest] =
    React.useState<IFatZebraPaymentRequest>();

  const inlineError = getError('fatzebraerror');

  const isCardExpired = moment().isAfter(
    `${props.paymentMethod.expiryYear}-${props.paymentMethod.expiryMonth}-01`
  );

  const updateSavedCard = () => {
    // fire card form here.
    // On success the tokenization event will fire.
    if (isCardEntryComplete && paymentRequest) {
      setIsUpdatingSavedCard(true);
      paymentRequest.fatZebra.checkout();
    }
  };

  return (
    <BladeView variant={'s'} className={css.view}>
      <BladeLoadOver
        isLoading={isUpdatingSavedCard}
        isSuccessful={updatedPaymentMethod ? true : false}
      />

      {props.error && (
        <BladeMessage variant={'error'} message={props.error?.message} />
      )}

      {isCardExpired && (
        <BladeMessage
          variant={'error'}
          message={
            props.productConfiguration.checkout.updateCardDetails?.expired ||
            'This card has expired, please update it with a newer card.'
          }
        />
      )}

      <BladeSurface
        title={
          props.productConfiguration.checkout.updateCardDetails?.title ||
          'Update your card details'
        }
        classNameContent={css.updateSavedCard}
      >
        <div className={css.updateSavedCard_details}>
          <BladeIcon
            name={
              props.productConfiguration.checkout.updateCardDetails?.icon ||
              'faCreditCard'
            }
          />

          <label className={css.updateSavedCard_details_label}>
            {`**** **** **** ${props.paymentMethod.last4Digits}, Expiry: ${props.paymentMethod.expiryMonth}/${props.paymentMethod.expiryYear}`}
          </label>
        </div>

        <BladeGuide
          markdown={
            props.productConfiguration.checkout.updateCardDetails
              ?.description ||
            "You can update your credit card information at any time. To delete your credit card information from our system, please give us a call. This is the card we'll use to take monthly payments and make one-off charges to your account. If you'd like to update it, enter your new card details below."
          }
        />

        {inlineError && (
          <BladeMessage variant={'error'} message={inlineError} isFlush />
        )}

        <div className={css.updateSavedCard_element}>
          <FatZebraWrapper
            payment={{
              amount: 1,
              currency: props.organisationSettings.currencyCode,
              reference: props.policyBundle.policy.policyReferenceID
            }}
            policyID={props.policyBundle.policy.id}
            verificationProps={{
              policyID: props.policyBundle.policy.id
            }}
            onPayNow={token => {
              if (token) {
                props.lifecycles
                  .onUpdateSavedCard(props.policyBundle.policy.id, token)
                  .then(response => {
                    setUpdatedPaymentMethod(response);
                    setIsUpdatingSavedCard(false);

                    setTimeout(() => {
                      props.lifecycles.onFinish(
                        response,
                        props.productConfiguration.checkout.updateCardDetails
                          ?.success
                      );
                    }, 2000);
                  })
                  .catch(() => {
                    setError(
                      'fatzebraerror',
                      "We weren't able to update your card information, please try again later or contact customer support."
                    );
                    setIsUpdatingSavedCard(false);
                  });
              } else {
                setError(
                  'fatzebraerror',
                  "We weren't able to update your card information, please try again later or contact customer support."
                );
                setIsUpdatingSavedCard(false);
              }
            }}
            onComplete={request => {
              setIsCardEntryComplete(true);
              setPaymentRequest(request);
            }}
            setIsCheckingOut={setIsUpdatingSavedCard}
            isCheckingOut={isUpdatingSavedCard}
            fatZebraConfig={props.lifecycles.onFatZebraGetConfig}
            fatZebraPaymentIntentVerification={
              props.lifecycles.onFatZebraGeneratePaymentIntentVerification
            }
          />
        </div>

        <BladeGuide
          markdown={props.productConfiguration.checkout.payment.mandateForSCA}
        />

        <div className={css.updateSavedCard_controls}>
          <BladeButton
            onClick={() => {
              clearErrors();
              updateSavedCard();
            }}
            isDisabled={!isCardEntryComplete || isUpdatingSavedCard}
          >
            {props.productConfiguration.checkout.updateCardDetails
              ?.updateCallToAction || 'Update card details'}
          </BladeButton>
        </div>
      </BladeSurface>
    </BladeView>
  );
};

export const FatZebraUpdateCardDetails: React.FC<IUpdateSavedCard> = props => {
  return (
    <BladeInlineErrorBoundary>
      <UpdateCardDetailsInner {...props} />
    </BladeInlineErrorBoundary>
  );
};

interface Lifecycles {
  onFatZebraGetConfig: (
    quoteID?: string,
    policyID?: string
  ) => Promise<IFatZebraConfigResponse>;
  onFatZebraGeneratePaymentIntentVerification: (
    props: IFatZebraVerificationProps
  ) => Promise<IFatZebraVerificationResponse>;
  onUpdateSavedCard: (
    policyID: string,
    paymentMethodID: string
  ) => Promise<PaymentMethod>;
  onFinish: (paymentMethod: PaymentMethod, message: string | undefined) => void;
}

interface IUpdateSavedCard {
  policyBundle: PolicyBundle;
  paymentMethod: PaymentMethod;
  paymentProviderConfiguration: PaymentProviderConfiguration;
  productConfiguration: InsuranceProductConfiguration;
  organisationSettings: OrganisationSettings;
  lifecycles: Lifecycles;
  error?: { message: string };
}
