import * as React from 'react';
import { ProductInterfaceConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetProductInterfaceConfiguration (productReference?: string, productCoverReference?: string): UseGetProductInterfaceConfiguration {

  const client = useConfigurationClient();
  const [ productInterfaceConfiguration, setProductInterfaceConfiguration ] = React.useState<ProductInterfaceConfiguration | undefined>(undefined);

  useAsyncEffect(async () => {
    if (productReference && productCoverReference) {
      const _productInterfaceConfiguration = await client.getProductInterfaceConfiguration(productReference, productCoverReference);
      setProductInterfaceConfiguration(_productInterfaceConfiguration);
    }
  }, [ productReference, productCoverReference ]);

  return {
    productInterfaceConfiguration
  };

}

interface UseGetProductInterfaceConfiguration {
  productInterfaceConfiguration: ProductInterfaceConfiguration | undefined
}
