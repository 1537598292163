// This is a side load we don't care about in the frontend
// so there is no need to update the state
export function assignQuoteToUserRequestReducer(state, action) {
  return state;
}

export function assignQuoteToUserSuccessReducer(state, action) {
  return state;
}

export function assignQuoteToUserFailureReducer(state, action) {
  return state;
}
