import React from 'react';
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom';
import routes from '../../../routes';

// We want to restrict some routes so they are only accessible to guest or signed in users
// This needs to run inside componentDidMount to avoid redirect issues with the midAuthFlow
// and componentWillReceiveProps
// We can check to see if the userType matches the route restriction in the RestrictedRoute class

export default ({ component: Component, ...routeProps }) => {
  return (
    <Route {...routeProps} render={ (props) => {
      return (
        routeProps.guardFlag
          ?
            <Redirect to={ routes.home.path } />
          :
            <Component {...props} />
      )
    }} />
  )
}