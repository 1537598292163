export function stringReplacer(
  originalCopy = '',
  replacements: IReplacements = {},
  isMultiple?: boolean
): string {
  return Object.keys(replacements).reduce((copy, key) => {
    if (copy.indexOf(key) !== -1) {
      if (isMultiple) {
        return copy.replace(RegExp(key, 'g'), replacements[key]);
      } else {
        return copy.replace(key, replacements[key]);
      }
    } else {
      return copy;
    }
  }, originalCopy);
}

interface IReplacements {
  [key: string]: string;
}
