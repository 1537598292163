import { SET_AUTH_CONFIG } from './types';



export default function setAuthConfigAction(res) {
  return {
    type: SET_AUTH_CONFIG,
    res
  };
}
