import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPartnerInfoAction } from './_set-partner-info';
import { setExternalUserIdAction } from './_set-external-user-id';
import { setupVriAction } from './_setup-vri';
import { resetWidgetState } from './_reset-widget-state';

const mapStateToProps = (state, ownProps) => {
  return {
    widgets: state.widgets
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    handleSetPartnerInfo: (partnerInfo) => {
      dispatch(setPartnerInfoAction(partnerInfo));
    },

    // handleSetExternalUserId: (partnerId) => {
    //   dispatch(setExternalUserIdAction(partnerId));
    // },

    handleSetupVri: (lineOfBusiness, coverType, vriData) => {
      dispatch(setupVriAction(lineOfBusiness, coverType, vriData));
    },

    handleResetWidgetState: () => {
      dispatch(resetWidgetState());
    }

  }
}

export default function create(Component) {
  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Component)
  )
}
