export class TobesError extends Error {
  error: Error;
  recoverable: boolean;

  constructor(message: string, error: any, recoverable: boolean = false) {
    super(message);
    this.error = error;
    this.recoverable = recoverable;
    this.name = 'TobesError';
  }
}
