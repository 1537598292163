import update from 'immutability-helper';

export default function clearFlagReducer(state, action) {
  const { flagKey } = action;

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          [flagKey]: {
            $set: null
          }
        }
      })
    }
  }
}
