import * as React from 'react';
import track from 'react-tracking';

export const TrackApp: React.FunctionComponent<ITrackApp> = track(
  props => ({
    app: props.app,
    appVersion: props.appVersion
  }),
  {
    process: data => (data.screen ? { event: 'aventus.screenview' } : null),

    dispatch: data => {
      // If dev, console log the events
      if (process.env.NODE_ENV === 'development') console.info('Event', data);

      // This dispatches up to Google Tag Manager
      (window.dataLayer = window.dataLayer || []).push(data);

      // If we're in a child window, send events to the parent window. Enabling
      // other apps (such as Aventus Portal) to respond and choose next actions
      // to perform.
      //
      // This is secured by the 'frame-ancestors' property in the Content-Security-Policy
      // header.
      if (window.location !== window.parent.location)
        try {
          window.parent.postMessage(data, '*');
        } catch (error) {
          console.error(error);
        }
    }
  }
)(props => <>{props.children}</>);

interface ITrackApp {
  app: string;
  appVersion: string;
}
