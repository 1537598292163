import React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import {
  BladeFormFieldset,
  BladeInputText,
  BladeInputSelect,
  BladeButton,
  BladeInputCheckbox
} from '@aventus/blade';
import { IMotorwebManualRegistration } from './types';
import css from './index.css';
import cx from 'classnames';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { useManualRegistration } from './use-manual-registration';

export const MotorwebManualRegistration: React.FunctionComponent<IMotorwebManualRegistration> = props => {
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const {
    registration,
    registeredState,
    setRegistration,
    setRegisteredState,
    updateRegistrationDetails
  } = useManualRegistration(props.value, props.onChange, props.onValidate);

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  return (
    <div className={cx(css.scaffold, css.scaffold_paddingTop)}>
      <BladeFormFieldset
        question={
          organisationConfiguration?.carDetails?.manualRegistrationTitleText
        }
        description={''}
        help={''}
      >
        <div
          className={cx(
            css.carDetails,
            { [css.carDetails_isSpacious]: !matchesQuery },
            { [css.carDetails_isSpaceLimited]: matchesQuery }
          )}
        >
          <BladeInputSelect
            variant={props.variant}
            label={organisationConfiguration?.carDetails?.stateLabel}
            name={`${props.name}state`}
            id={`${props.name}state`}
            value={registeredState}
            onChange={setRegisteredState}
            defaultLabel={'Select State'}
            options={[
              { id: 1, text: 'ACT', value: 'ACT' },
              { id: 2, text: 'NSW', value: 'NSW' },
              { id: 3, text: 'NT', value: 'NT' },
              { id: 4, text: 'QLD', value: 'QLD' },
              { id: 5, text: 'SA', value: 'SA' },
              { id: 6, text: 'TAS', value: 'TAS' },
              { id: 7, text: 'VIC', value: 'VIC' },
              { id: 8, text: 'WA', value: 'WA' }
            ]}
            matchOn={'id'}
          />

          <BladeInputText
            className={cx(css.registration_input)}
            variant={props.variant}
            label={organisationConfiguration?.carDetails?.carRegistrationLabel}
            name={`${props.name}rego`}
            id={`${props.name}rego`}
            value={registration}
            onChange={setRegistration}
            validate={props.validate}
          />

          <BladeButton
            onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
              e && e.preventDefault();
              updateRegistrationDetails();
            }}
            isDisabled={
              !registration ||
              !registeredState ||
              registration.length > props.maxLength
            }
          >
            {organisationConfiguration?.carDetails?.addRegistrationButtonLabel}
          </BladeButton>
        </div>

        <div
          className={cx(
            css.registrationFlag_wrapper,
            { [css.registrationFlag_wrapper_col]: matchesQuery },
            { [css.registrationFlag_wrapper_row]: !matchesQuery }
          )}
        >
          <div
            className={cx(css.registrationFlag_checkbox_wrapper, {
              [css.registrationFlag_checkbox_wrapper_fixed_width]: !matchesQuery
            })}
          >
            <BladeInputCheckbox
              name={`${props.name}declaration`}
              value={props.registrationFlag}
              onChange={props.setRegistrationFlag}
              label={organisationConfiguration?.carDetails?.declarationLabel}
              className={css.registrationFlag_checkbox}
            ></BladeInputCheckbox>
          </div>

          {props.registrationFlag && (
            <div
              className={cx(css.registrationFlag_message, {
                [css.registrationFlag_message_fixed_width]: !matchesQuery
              })}
            >
              {organisationConfiguration?.carDetails?.declarationText}
            </div>
          )}
        </div>
      </BladeFormFieldset>
    </div>
  );
};
