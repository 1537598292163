import * as React from 'react';
import { OraclesConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetOraclesConfiguration (): UseGetOraclesConfiguration {

  const client = useConfigurationClient();
  const [ oraclesConfiguration, setOraclesConfiguration ] = React.useState<OraclesConfiguration | undefined>(undefined);

  useAsyncEffect(async () => {
    const _oraclesConfiguration = await client.getOraclesConfiguration();
    setOraclesConfiguration(_oraclesConfiguration);
  }, []);

  return {
    oraclesConfiguration
  };

}

interface UseGetOraclesConfiguration {
  oraclesConfiguration: OraclesConfiguration | undefined
}
