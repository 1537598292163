import update from 'immutability-helper';

export const setRouteGuard = (state, action) => {
  return {
    ...state,
    ...{
      core: update(state.core, {
        _flags: {
          guardRoute: { $set: true }
        }
      })
    }
  }
}

export const resetRouteGuard = (state, action) => {
  return {
    ...state,
    ...{
      core: update(state.core, {
        _flags: {
          guardRoute: { $set: false }
        }
      })
    }
  }
}