export function isDependencySatisfied(
  valueType: string,
  valueShouldBe: any,
  valueIs: any
): boolean {
  switch (valueType.toLowerCase()) {
    case 'boolean':
      return typeof valueIs === 'boolean'
        ? valueIs.toString().toLowerCase() ===
            valueShouldBe.toString().toLowerCase()
        : false;

    case 'listitem':
      return valueShouldBe.includes(valueIs);

    default:
      return false;
  }
}
