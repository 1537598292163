import moment, { Moment } from 'moment-timezone';

export const dateSerialise: DateSerialise = (date) => {
  if (moment.isMoment(date)){
    return date;
  }
  else{
    return moment(date);
  }
}

export type DateSerialise = (date: Moment) => Moment
