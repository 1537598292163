const WIDGET_RESET_STATE = 'WIDGET_RESET_STATE';

const resetWidgetState = () => {
  return { type: WIDGET_RESET_STATE  };
}

export {
  WIDGET_RESET_STATE,
  resetWidgetState
}
