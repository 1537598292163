import { ICompilers, IDesignSystem, IAtomicOperators, IMolecules, COMPILE_TYPES } from '@aventus/honey-definitions';
import { interpolateValues } from './interpolate-values';

export function compile (
  system: IDesignSystem,
  compilers: ICompilers,
  atomicOperators?: IAtomicOperators,
  molecularExtensions?: IMolecules
) {

  // Now we can extend with any additional molecular
  // operations defined.
  // TODO move to own function

  const extendedSystem: IDesignSystem = (
    molecularExtensions && system.molecules
      ? {
        ...system,
        ...{ molecules: [ ...system.molecules, ...molecularExtensions ] }
      }
      : system
  );

  // We need to make a first pass of all the atoms and molecule defined
  // atoms and replace any references to other atoms with the referenced
  // value.

  const interpolatedSystem = interpolateValues(extendedSystem);


  // First step is to run through the atoms and apply
  // any of the atomic operators.

  if (interpolatedSystem.atoms && atomicOperators) {
    for (const atomicOperator of atomicOperators) {
      interpolatedSystem.atoms.map(atomicOperator);
    }
  }

  for (const compiler of compilers) {

    let compiledAsset: string = '';

    // Check for any Atoms defined in the Design System,
    // and if the compiler is also setup to map and reduce the
    // atoms, then we can compile them.

    if (interpolatedSystem.atoms && compiler.atomsReduce) {
      const compiledAtoms: string = interpolatedSystem.atoms.reduce(compiler.atomsReduce, '');
      compiledAsset = `${ compiledAsset }\n${ compiledAtoms }`;
    }

    if (interpolatedSystem.molecules && compiler.moleculesReduce) {
      const compiledMolecules: string = interpolatedSystem.molecules.reduce(compiler.moleculesReduce, '');
      compiledAsset = `${ compiledAsset }\n${ compiledMolecules }`;
    }

    // We can now finish off compiling all the
    // compiled assets collected so far.

    switch (compiler.compileType) {

      // If we're compiling in runtime, then the plugin
      // is designed to handle a specific runtime environment.

      case COMPILE_TYPES.runtime:
        if (compiler.compileToInRuntime) {
          compiler.compileToInRuntime(compiledAsset);
        } else {
          console.error(
            `You\'re trying to compile a Design System in runtime, but no 'compileToInRuntime' function was found for the '${ compiler.name }' compiler.`
          );
        }
        return;

      case COMPILE_TYPES.compile:
        return;

      default:
        return;

    }
  }

}
