import * as React from 'react';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration,
  useGetOraclesConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import {
  useGetOrganisationSettings,
  useGetQuote
} from '@aventus/symphony-client/hooks';
import {
  IntelligentQuoteRequest,
  PaymentPlanType,
  ProductReference,
  ProductCoverReference
} from '@aventus/platform';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useAskForNew } from './use-ask-for-new';
import { useSessionStorage } from '@aventus/pockethooks';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';
import { ServerError } from '@aventus/errors';

export const AskForNew: React.FunctionComponent<IAskForNew> = props => {
  // A brand new quote can be started by defining the product reference
  // and the product cover reference of the intended insurance product.
  // In this scenario, the query for this view is:
  // ...?productReference=X&productCoverReference=Y

  const { updateProductRef } = useDispatchEvent();

  const _productReference: ProductReference = queryParamGet(
    props.location.search,
    'productReference'
  );
  const _productCoverReference: ProductCoverReference = queryParamGet(
    props.location.search,
    'productCoverReference'
  );

  // A quote can be started off the back of an existing
  // quote. The benefit of doing this is to have all of the answers a user
  // previously gave pre-filled in this new quote.
  // In this scenario, the query for this view is:
  // ...?quoteId=X
  // Meaning, the _productReference and _productCoverReference above would be null.

  const quoteId: string = queryParamGet(props.location.search, 'quoteId');

  // If we're loading an existing quote, because all we've got is the
  // quote id, then we need fetch the quote object and pick out the   product
  // and product cover reference to be able to load the correct configuration
  // file for that product.

  const { quoteBundle } = useGetQuote(quoteId);

  // Given these two references, we can pick out the specific configuration
  // for this insurance product, as well as the organisation configuration
  // and oracles configuration.

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    _productReference ||
      quoteBundle?.requestedQuote.productReferenceID.toLowerCase(),
    _productCoverReference ||
      quoteBundle?.requestedQuote.coverType.toLowerCase()
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    _productReference ||
      quoteBundle?.requestedQuote.productReferenceID.toLowerCase(),
    _productCoverReference ||
      quoteBundle?.requestedQuote.coverType.toLowerCase()
  );
  const { oraclesConfiguration } = useGetOraclesConfiguration();

  // If the user was re-directed by one of our partners, then we'd expect
  // that partner ID to be in our query params or localstorage under key partnerId. If it is, then we need to
  // pass it on to IQ Start so that we can attribute the conversion to them.

  const { storageValue } = useSessionStorage('partnerId');

  const partnerId: string =
    queryParamGet(props.location.search, 'partnerId') || storageValue;

  const showExpiry: boolean =
    queryParamGet(props.location.search, 'showExpiry') === 'true';

  const autoNext = queryParamGet(props.location.search, 'autoNext') !== null;

  const voucherCode = queryParamGet(props.location.search, 'voucherCode');

  const partnerReference = queryParamGet(props.location.search, 'fdclid');

  // As mentioned above, we can initialise a new quote using
  // either references or an existing quote id.
  // We can use either to create our IQ instance and render
  // that to the UI.

  // Note that its important we pass in the query param references
  // and not the ones we may have figured out above. The query params
  // are the only references the IQ Starter cares about.

  const iq = useAskForNew(
    _productReference,
    _productCoverReference,
    quoteId,
    partnerId,
    autoNext,
    props.intelligentQuoteRequest,
    voucherCode,
    partnerReference
  );

  React.useEffect(() => {
    updateProductRef(_productReference);
  }, [_productReference]);

  React.useEffect(() => {
    props.setRateError(iq.rateError);
  }, [iq.rateError]);

  // Lastly we need to fetch the currency settings for this particular
  // organisation.

  const { organisationSettings } = useGetOrganisationSettings();

  // Once we've got the product configuration,
  // we can assume we've done all the preparing we need to,
  // so we can go ahead and load the quote form.
  // Note that we don't need to wait for any of the IQ async
  // actions or initialisation. This is because the component
  // knows how to handle the IQ object in any of it's possible
  // states.

  if (
    productConfiguration &&
    organisationConfiguration &&
    organisationSettings &&
    oraclesConfiguration &&
    productInterfaceConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        quote={quoteBundle?.requestedQuote}
        quotePricing={quoteBundle?.requestedQuotePricing}
        defaultPaymentPlanType={props.defaultPaymentPlanType}
        currencyCode={organisationSettings.currencyCode}
        currencySymbol={organisationSettings.currencySymbol}
        countryCode={organisationSettings.defaultCountryCode}
        dateFormat={organisationSettings.dateFormat}
        oraclesConfiguration={oraclesConfiguration}
        organisationConfiguration={organisationConfiguration}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        goHome={props.goHome}
        getSummaryRoute={props.getSummaryRoute}
        getHomeRoute={props.getHomeRoute}
        showEstimate={props.showEstimate}
        skipConfirmationPage={false}
        quoteType="NewBusiness"
        showExpiry={showExpiry}
      />
    );
  }

  return null;
};

interface IAskForNew extends IIntelligentQuote {
  defaultPaymentPlanType?: PaymentPlanType;
  intelligentQuoteRequest?: IntelligentQuoteRequest;
  showEstimate?: boolean;
  setRateError: React.Dispatch<React.SetStateAction<ServerError | undefined>>;
}
