import moment, { Moment } from 'moment-timezone';

export const dateDeserialise: DateDeserialise = (date, timezone) => {
  if (date) {
    return moment.tz(date, timezone);
  } else {
    return moment.tz(timezone);
  }
};

export type DateDeserialise = (
  date: string | undefined,
  timezone: string
) => Moment;
