export const FRAMES_TYPE:string = 'frames';

export function framesMapper(rest:any, h:IFrame):IFrameMap {
  return {
    ...rest,
    ...{
      [ h.key ]: {
        ...h.accentBorder ? { accentBorder: h.accentBorder } : {},
        ...h.capture ? { capture: h.capture } : {},
        ...h.depth ? { depth: '0px 2px 4px rgba(0, 0, 0, 0.18)' } : {},
        ...{ corners: h.corners ? h.corners + 'px' : '0' }
      }
    }
  };
}

export interface IFrame {
  key:string
  accentBorder?:boolean
  depth?:boolean
  corners?:number
  capture?:string
  //fill?: boolean
  //stroke?: boolean
}

export interface IFrameMap {
  [key:string]: {
    accentBorder?:boolean
    depth?:string
    corners:string
    capture?:FrameCapture
  }
}

export enum FrameCapture {
  solid = 'solid',
  border = 'border',
  underline = 'underline'
}
