import update from 'immutability-helper';
import { initialState } from './initial-state';

// IMPORTANT - IF THIS FILE IS CHANGED, CHECK If WE NEED TO UPDATE THE STATE VERSION:
// - applications/lib/modules/lib/state-version.js

// Here we can cherry pick what we want to store in local storage
// from the app module.
// This will be saved each time to store is updated
// and loaded when the app is first started.

export default function localStorageStateWidgetPartial(state) {
  const { widgets } = state;

  return {
    ...initialState,
    ...{
      partnerId: update(initialState.partnerId, { $set: widgets.partnerId || null })
    }
  }
}