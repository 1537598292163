import { CustomConfig } from '@aventus/platform';
import { EProductType } from '@aventus/platform-client-context/models/tobes';

export function useIsHybridProduct(
  customConfig: CustomConfig | undefined,
  productID: string
): IUseIsHybridProduct {
  const lineOfBusiness = customConfig
    ? customConfig.activeLinesOfBusiness.find(
        ({ id }) => id === Number(productID)
      )
    : undefined;

  const isHybridProduct = lineOfBusiness
    ? EProductType.hybrid === lineOfBusiness?.productType
    : undefined;

  return { isHybridProduct };
}

export interface IUseIsHybridProduct {
  isHybridProduct: boolean | undefined;
}
