import { QuoteType } from '@aventus/platform';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { History } from 'history';
import { useGetProductRef } from '@aventus/mvmt-simplequote/hooks/use-get-product-ref';
import { SimpleQuotePage } from '../simple-quote-page';
import { useSessionStart } from '@aventus/mvmt-simplequote/hooks/use-session-start';
import React from 'react';

export const SimpleQuoteSF: React.FunctionComponent<ISimpleQuoteSF> = props => {

  const location = useLocation<History.Search>();
  const urlParams = new URLSearchParams(location.search);

  // Call Product Controller for latest product ID
  const coverType = urlParams.get('coverType');
  const { product: productData } = useGetProductRef(
    props.match.params.productRef,
    coverType
  );
  
  // Do magic with sf token
  const orgLevelToken = urlParams.get('orgToken');
  const userId = urlParams.get('userId');

  const { processedToken } = useSessionStart(
    userId,
    orgLevelToken
  );

  if (productData && processedToken) {
    return (
      <SimpleQuotePage
        type={props.match.params.type}
        product={productData}
        questionSetReferenceID={props.match.params.questionSetReferenceID}
        pageIndex={props.match.params.pageIndex}
        isAuthenticated={props.isAuthenticated}
        dashboardPath={props.dashboardPath}
        hideFooter={props.hideFooter}
        history={props.history}
        orgLeveltoken={processedToken}
      />
    );
  }
  return null;
};

export interface ISimpleQuoteSF
  extends RouteComponentProps<{
    productRef: string;
    type: QuoteType;
    questionSetReferenceID: string;
    pageIndex?: string;
  }> {
  isAuthenticated: boolean;
  dashboardPath: string;
  hideFooter: () => void;
}
