import moment from 'moment';

export function max (dynamicValidationValue: string): (value: string) => string | undefined {
  const maxDate = moment(dynamicValidationValue);
  return (value) => {
    return (
      moment(value).isSameOrBefore(maxDate,'day')
        ? undefined
        : `The date must be before ${ maxDate.format('dddd, MMMM Do YYYY, h:mm:ss a') }`
    )
  }
}
