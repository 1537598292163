import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { useMoney } from './use-money';
import css from './index.css';
import cx from 'classnames';

export const BladeInputMajorMoney: React.FunctionComponent<
  IBladeInputMajorMoney
> = props => {
  const inputProps = useInput(props);
  const { value, onChange, onBlur, onFocus } = useMoney(
    inputProps.value,
    inputProps.onChange,
    props.currencyCode,
    inputProps.onBlur,
    inputProps.onFocus
  );

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <div className={css.moneyInput}>
        <div className={css.moneyInput_symbol}>
          <span className={''}>{props.currencySymbol}</span>
        </div>

        <input
          ref={null}
          type={'text'}
          id={inputProps.id}
          name={inputProps.name}
          className={cx(inputProps.className || '', css.moneyInput_input)}
          value={value || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputMajorMoney extends IBladeInput {
  currencySymbol: string;
  currencyCode: string;
}
