import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import './index.css';

export const BladeInputCheckbox: React.FunctionComponent<IBladeInputCheckbox> = (props) => {

  const inputProps = useInput(props);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={ inputProps.hasBeenInteractedWith }
      error={ inputProps.error }>

      <div className={ `blade-input-switch ${ props.className }` }>

        <input
          type={ 'checkbox' }
          id={ props.id }
          name={ props.name }
          onChange={ () => { props.onChange(!props.value) } }
          onFocus={ inputProps.onFocus }
          onBlur={ inputProps.onBlur }
          checked={ props.value === true } />

        <BladeInput_Label
          id={ inputProps.id }
          label={ inputProps.label } />

      </div>

    </BladeInput_Frame>
  );
};

export interface IBladeInputCheckbox extends IBladeInput {}
