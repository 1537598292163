import * as React from 'react';
import { BladeNotification } from '@aventus/blade';
import {
  PclRecoverableError,
  PclUnrecoverableError,
  ServerError,
  PlatformUnauthorisedError
} from '@aventus/errors';
import {
  ExperianRecoverableError,
  ExperianUnrecoverableError
} from '@aventus/oracle-experian';
import { RouteComponentProps } from 'react-router-dom';
import css from './index.css';

export class AppBoundary extends React.Component<
  IAppBoundary,
  IAppBoundaryState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
      crash: null,
      redirectUnauthorised: false
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // These errors are handled by more granular boundaries directly
    // surrounding the integration's components. We just want to log
    // this error for our records

    if (
      error instanceof ExperianRecoverableError ||
      error instanceof ExperianUnrecoverableError
    ) {
      this.props.logError?.(error, errorInfo);
      this.setState({ error: null });
      return;
    }

    if (
      error instanceof PclRecoverableError ||
      error instanceof PclUnrecoverableError ||
      error instanceof ServerError
    ) {
      this.props.logError?.(error, errorInfo);
      this.setState({ error });
      return;
    }

    if (error instanceof PlatformUnauthorisedError) {
      this.setState({ redirectUnauthorised: true });
      return;
    }

    // For any other error, most likely default
    // JS errors, we need to crash the app. In this scenario
    // we'll render the fallback error page.

    this.setState({ crash: error });
  }

  render() {
    // Until we finish refactoring out the initialiser,
    // we need to use the existing error boundary that exists
    // to render the error page. We can do this by re-throwing
    // the error and letting that get caught -- as long as this
    // boundary is a child of the original one.
    // The goal is to render the error page if crash and not
    // throw.

    if (this.state.redirectUnauthorised) {
      this.setState({ redirectUnauthorised: false });
      this.props.onUnAuthorised();

      // Return null here not to render
      // anything else below. The onUnAuthorised lifecycle
      // should redirect to somewhere else.

      return null;
    }

    if (this.state.crash && !this.state.redirectUnauthorised) {
      throw this.state.crash;
    }

    return (
      <div className={css.boundary_frame}>
        {this.props.children}

        {this.state.error && (
          <BladeNotification
            show={this.state.error ? true : false}
            className={css.boundary_notification}
            isMessageFlush={false}
            isMessageOverlayed={true}
            alerts={[
              {
                variant: 'error',
                message: this.state.error?.message,
                callBack: () => this.setState({ error: null })
              }
            ]}
          />
        )}
      </div>
    );
  }
}

export interface IAppBoundary extends RouteComponentProps {
  logError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  onUnAuthorised: () => void;
}

export interface IAppBoundaryState {
  error: Nullable<Error>;
  crash: Nullable<Error>;
  redirectUnauthorised: boolean;
}
