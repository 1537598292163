import { isJwtExpired } from 'jwt-check-expiration';

const loadStateFromLocal = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

const saveStateToLocal = (state: { [key: string]: unknown }) => {
    window.localStorage.removeItem('state');
    window.localStorage.setItem('state', JSON.stringify(state));
};

export const storeToken = (token: string) => {
  if (!token) return;

  const oldState = loadStateFromLocal() || {};
  const newState = {
    ...oldState,
    app: {
      ...(oldState.app || {}),
      user: {
        ...(oldState.user || {}),
        token
      }
    }
  };

  saveStateToLocal(newState);
};

const setCookie = (name: string, value: string, exp: number = 1) => {
  const date = new Date();
  date.setTime(date.getTime() + exp * 60 * 60 * 1000);
  const expires = `expires="${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : '';
};

export const bakeTokenCookie = (token: string) => {
  if (!token || token === null) return;
  setCookie('simpleQuoteToken', token);
};

const handleToken = (orgLevelToken?: string | null): string => {
  const urlParams = new URLSearchParams(window.location.search);

  if (orgLevelToken && orgLevelToken !== null) {
    bakeTokenCookie(orgLevelToken)
  }
  
  let urlToken = urlParams.get('token');
  if (urlToken && urlToken !== null) return urlToken;

  const localState = loadStateFromLocal();
  const localToken = localState?.app?.auth.user.token;
  if (localToken && localToken !== null) return localToken;

  const cookieToken = getCookie('simpleQuoteToken');
  if (cookieToken && cookieToken !== null) return cookieToken;

  return '';
}

export const getToken = (orgLevelToken?: string | null): string => {
  const token = handleToken(orgLevelToken)

  if(token && token !== ""){
    if (isJwtExpired(token)){
      return "";
    } 
  }
  return token
};