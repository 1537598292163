const errorCodeMessages = {
  1001: {
    message:
      'The password provided was invalid, please try a different password and try again. If the issue persists please contact support.'
  },
  1002: {
    message:
      'The email adddress provided was invalid, please check the email was entered correctly and try again. If the issue persists please contact support.'
  },
  1003: {
    message:
      'An account for the email address already exists. Please try sign in with that email or use a different email address.'
  },

  1022: {
    message:
      'There was an error talking to our authentication service, please wait a few moments and try again. If the issue persists please contact support.'
  }
};

export default errorCodeMessages;
