import React from 'react';
import { matchPath } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { Scaffold, HoneyContext } from '@honey/consume-react-jss';
import {
  BladePage,
  BladeAppBar,
  BladeIcon,
  BladeAppNav,
  BladeCookies,
  BladeAppFooter,
  BladeLoader
} from '@aventus/blade';
import poweredByAventus from './powered-by-aventus-white.svg';
import widgetApi from '../../../../widgets/container';
import routes from '../../../routes';
import navConfig from './nav-config.ts';
import { appMap } from './app-map';
import {
  MvmtPolicyClaimInfo,
  MvmtPolicyCancel,
  MvmtPolicySummary,
  MvmtPolicySummaryOfCover,
  MvmtPolicyDocuments,
  MvmtPolicySummaryRedirect
} from '@aventus/mvmt-policy';
import {
  MvmtIntelligentQuickQuote,
  MvmtIntelligentQuoteRedirect,
  MvmtIntelligentQuoteSummaryRedirect,
  MvmtIntelligentQuoteAdjustOverviewRedirect,
  MvmtVriSummary,
  MvmtVriSummaryRedirect,
  MvmtVriMissingData,
  MvmtVriMissingDataRedirect,
  MvmtVriDeclinedRedirect,
  MvmtVriDeclined
} from '@aventus/mvmt-quote';
import {
  MvmtSimpleQuote,
  MvmtSimpleQuoteSf,
  MvmtSimpleQuoteConsole,
  MvmtSimpleQuoteVariantSummary,
  MvmtSimpleQuoteQuickQuoteSummary
} from '@aventus/mvmt-simplequote';
import { MvmtDocumentsSummary } from '@aventus/mvmt-documents';
import {
  ProductOverview,
  ProductOverviewRedirect,
  ProductDashboardRedirect,
  ProductBaseRedirect
} from '@aventus/mvmt-product';
import { AppBoundary } from '../app-boundary';
import { IntelligentQuote } from './views/intelligent-quote';
import { IntelligentQuoteAdjustOverview } from './views/intelligent-quote-adjust-overview';
import { Checkout } from './views/checkout';
import { UpdateCardDetails } from './views/update-card-details';
import { PolicyRenew } from './views/policy-renew';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import MotorLandingPage from '@aventus/mvmt-quote/views/partner-landing-pages/motor-landing-page';
import BasicAuthLogin from '../basic-auth-login';

import {
  appApi,
  SignIn,
  ChangePassword,
  ResetPassword,
  ResetPasswordNewPassword,
  CreateAccount,
  CreateAccountPassword,
  SocialCallback,
  PrivacyPolicy,
  TermsAndConditions,
  RestrictedRoute,
  Alerts,
  ErrorBoundary,
  SignUp
} from '../../../index';
import { EventType } from '@aventus/pocketknife/dispatch-event';

class Initialiser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      isFooterVisible: true,
      productReference: null
    };
  }

  componentDidUpdate = prevProps => {
    const authProps = this.props.app.auth;
    const prevAuthProps = prevProps.app.auth;

    // if (
    //   authProps._flags.successfullyStartedBasicAuth === true &&
    //   !sessionStorage.getItem('basicAuthSuccess')
    // ) {
    //   sessionStorage.setItem('basicAuthSuccess', 'true');
    // }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    // This is the same hack as in the componentDidMount(). See that for more
    // info. This is also handled here because if you sign out and in again,
    // the did mount does not trigger again in the same session.

    if (
      !prevProps.app?.auth?.user &&
      this.props.app?.auth?.user &&
      this.props.app?.auth?.user?.token
    ) {
      if (this.props.symphony) {
        this.props.symphony.logIn(this.props.app.auth.user.token);
      }
    }
  };

  componentDidMount() {
    // if (!sessionStorage.getItem('basicAuthSuccess')) {
    //   localStorage.setItem('basicAuthRedirectUrl', window.location.href);
    //   this.props.handleStartBasicAuth();
    // }
    // NOTE
    // the search query is a temprary addition
    // until we move all config up to servers. To help
    // with development, there is some handling to toggle
    // between different white label config in code.
    this.handleGetPartnerId();
    this.props.handleGetCustomConfig(this.props.location.search);
    this.props.handleUpdateStateVersion();
    this.props.handleSetAuthConfig(
      this.props.organisationSettings.authentication
    );

    if (this.isVri()) {
      this.initialiseWidget();
    } else {
      this.initialisePartner();
    }

    if (!this.props.app.auth.nonce) {
      this.props.handleAuthSetNonce();
    }

    this.checkForUnblockUserRoute();

    if (this.props.app.auth.user && this.props.app.auth.user.token) {
      // Another temporary hack to use the current authentication mechanism
      // with the new API client. Eventually authentication mechanism needs to be
      // re-done and moved to auth0 hosted page, until then we need to bridge
      // not to refactor too much.

      if (this.props.symphony) {
        this.props.symphony.logIn(this.props.app.auth.user.token);
      }
    }

    window.addEventListener(
      EventType.ProductReferenceUpdated,
      this.handleUpdateProductReference
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      EventType.ProductReferenceUpdated,
      this.handleUpdateProductReference
    );
  }

  updateProductReference = e => {
    if (this.state.productReference != e.detail.ref) {
      this.setState({ productReference: e.detail.ref });
    }
  };
  handleUpdateProductReference = this.updateProductReference.bind(this);

  // This was in the `componentWillReceiveProps` fn.
  // It currently doesn't work because the auth object is now
  // different. We need to figure out how to handle this:

  /*
  // Notes from the late Stuart Jones:
  // maybe move into some middleware that listens to the store for auth updates ?
  // GET ZENDESK WORKING WITH NEW AUTH0 - SJ
  if (!this.props.me && nextProps.me && nextProps.me.user && nextProps.me.user.username && (typeof zE === "function") && _isFunction(zE) && _isFunction(zE.identify)) {
    zE.identify({ email: nextProps.me.user.username });
  }
  */

  isVri = () => {
    return matchPath(this.props.history.location.pathname, {
      path: routes.widgets.path,
      exact: false,
      strict: false
    });
  };

  initialisePartner() {
    const { partnerId } = this.getParamsFromUrl();
    if (partnerId) {
      this.props.handleSetPartnerInfo({ partnerId });
    }
    return;
  }

  checkForUnblockUserRoute() {
    const match = matchPath(this.props.history.location.pathname, {
      path: '/unblock/:requestId',
      exact: true,
      strict: false
    });
    if (match) {
      this.props.handleAuthUnblockUser(match.params.requestId);
      this.props.history.push(routes.signIn.path);
    }
  }

  handleGetPartnerId = () => {
    const partnerId = queryParamGet(this.props.location.search, 'partnerId');

    if (partnerId) {
      sessionStorage.setItem('partnerId', partnerId);
    }
  };

  getParamsFromUrl = () => {
    return queryString.parse(this.props.location.search);
  };

  initialiseWidget = () => {
    const urlParamsAsObject = this.getParamsFromUrl();

    const { partnerId, lineOfBusiness, coverType, widget, ps, payload } =
      urlParamsAsObject;
    const uriDecodedPayload = decodeURIComponent(payload);

    const partnerConfig = {
      paymentSchedule: ps
    };

    this.props.handleSetPartnerInfo({ partnerId, payload, partnerConfig });

    switch (widget) {
      case 'vri':
        this.props.handleSetupVri(lineOfBusiness, coverType, uriDecodedPayload);
        break;
      default:
        this.props.history.push(routes.widgets.path);
    }
  };

  render = () => {
    const isGuest = !this.props.app.auth.user;
    const { appVersion, organisationConfiguration } = this.props;

    if (!this.props.app.core.isAppReady) {
      return null;
    }

    const resolveRegulatoryStatement = storedProductRef => {
      const regulatoryStatements =
        this.props.organisationConfiguration?.legalCopy?.regulatoryStatement;

      if (typeof regulatoryStatements == 'string') {
        return regulatoryStatements;
      }

      return (
        regulatoryStatements[storedProductRef] ?? regulatoryStatements.default
      );
    };

    const resolveSupportUrl = urlProductRef => {
      const supportConfig =
        this.props.app.core.customConfig.userInterface.support;

      if (!supportConfig?.productSpecific) {
        return supportConfig?.externalLink;
      }

      return (
        supportConfig?.productSpecific[urlProductRef] ??
        supportConfig?.externalLink
      );
    };

    const footerLinks = this.props.organisationConfiguration?.footer?.links;
    var privacyUrl = footerLinks?.find(link => link.label === 'Privacy')?.href;
    var termsUrl = footerLinks?.find(link => link.label === 'Terms')?.href;

    const config = navConfig({
      guest: isGuest,
      push: this.props.history.push,
      email: this.props.app.auth.user.email,
      isSocial: this.props.app.auth.user.socialAccount,
      signOut: () => {
        this.props.handleAuthSignOut();
        this.props.symphony.logOut();
      },
      appMode: this.props.app.core.config.env.APP_MODE,
      clearStorage: this.props.handleClearLocalStorage,
      supportButton: {
        label: this.props.app.core.customConfig.userInterface.support?.label,
        externalLink: resolveSupportUrl(this.state.productReference),
        openInNewTab:
          this.props.app.core.customConfig.userInterface.support?.openInNewTab
      },
      privacyUrlOverride: privacyUrl,
      termsUrlOverride: termsUrl
    });

    const signIn = () => this.props.history.push(routes.signIn.path);
    const signOut = () => {
      this.props.handleAuthSignOut();
      this.props.symphony.logOut();
    };
    const changePassword = () =>
      this.props.history.push(routes.changePassword.path);

    const boundaryProps = {
      goHome: () => this.props.history.push('/'),
      goToSignIn: signIn,
      goToPolicy: policyId => this.props.history.push(`/policy/${policyId}`),
      goToDashboard: () => this.props.history.push(`/dashboard`),
      goToQuoteSummary: (quoteID, quotePlan) =>
        this.props.history.push(`/quote/${quoteID}?plan=${quotePlan}`),
      goToProduct: (productReferenceId, name, productID, _, startUrl) => {
        if (startUrl) {
          window.location.href = startUrl;
        } else {
          this.props.history.push(
            `product/${productReferenceId}/${name}${
              productID ? `/${productID}` : ''
            }`
          );
        }
      },
      goToCheckout: quoteID => this.props.history.push(`/checkout/${quoteID}`),
      signOut,
      goToOrigin: () => {
        if (this.props.history.location.state?.origin) {
          this.props.history.push(this.props.history.location.state.origin);
        } else {
          this.props.history.push('/');
        }
      }
    };

    const chromeQuery = queryParamGet(this.props.location.search, 'chrome');
    const chrome = chromeQuery === null ? true : chromeQuery === 'true';
    var ref = document.referrer;

    return (
      <>
        <ErrorBoundary
          companyLogo={this.props.app.core.customConfig.client.companyLogo}
          tenantSupport={this.props.app.core.customConfig.client.support}
          tenantMarketing={this.props.app.core.customConfig.client.marketing}
          errorCallback={this.props.errorCallback}
          errorMessage={
            this.props.app.core.customConfig.app.errorMessages.genericError
          }
          errorImageUrl={
            this.props.app.core.customConfig.userInterface.somethingWentWrong
              .image
          }
        >
          <AppBoundary
            logError={this.props.errorCallback}
            onUnAuthorised={() => {
              // If the API has thrown a 401, we'll first
              // sign the user out. Note, we currently need to sign out
              // from both the Redux client and the new agnostic clients.
              this.props.handleAuthSignOut();
              this.props.symphony.logOut();
              // Next, we can redirect to the sign in
              // page.
              const signInConfig = {
                isMidFlowAuth: true,
                onSuccessGoToRoute: `${this.props.location.pathname}${this.props.location.search}`,
                ...this.props.location.state
              };
              this.props.history.replace('/sign-in', signInConfig);
            }}
          >
            {/* {!sessionStorage.getItem('basicAuthSuccess') && ( */}
            {/* <BasicAuthLogin
                logoSrc={this.props.app.core.customConfig.client.companyLogo}
              /> */}
            {/* )} */}
            {/* {sessionStorage.getItem('basicAuthSuccess') && ( */}
            <>
              <Scaffold fill={true} orient={'col'}>
                {/*
                Position absolute or overlayed. They will not take up
                pixel space from the other sibling Components.
                */}

                {chrome === true && (
                  <BladeAppNav
                    size={'default'}
                    isOpen={this.state.isMenuOpen}
                    config={config}
                    close={() => {
                      this.setState({ isMenuOpen: false });
                    }}
                    footer={
                      this.props.app.core.customConfig.client.legalCopy
                        .regulatoryStatement
                    }
                    version={appVersion}
                  />
                )}

                <Alerts />

                {/*
                Relatively positioned Components. These will take up
                layout space.
                */}

                {organisationConfiguration?.cookieBanner?.enabled !== false &&
                  this.props.app.core.showCookiePolicy &&
                  chrome === true && (
                    <BladeCookies
                      text={organisationConfiguration?.cookieBanner?.text}
                      buttonText={
                        organisationConfiguration?.cookieBanner?.buttonText
                      }
                      acceptCookiePolicy={() =>
                        this.props.handleUpdateShowCookiePolicy(false)
                      }
                    />
                  )}

                {chrome === true && (
                  <BladeAppBar
                    size={'default'}
                    viewVariant={
                      this.props.location.pathname.includes('checkout')
                        ? 'm'
                        : 's'
                    }
                    renderLeft={
                      <img
                        alt={'logo'}
                        style={{
                          width:
                            this.props.space === 'limited' ? '110px' : '190px',
                          cursor: 'pointer'
                        }}
                        onClick={
                          ref.includes('comparethemarket.com.au')
                            ? undefined
                            : () => {
                                this.props.app.core.customConfig.userInterface
                                  .dashboardLogoLink
                                  ? (window.location.href =
                                      this.props.app.core.customConfig.userInterface.dashboardLogoLink)
                                  : this.props.history.push(routes.home.path);
                              }
                        }
                        src={
                          this.props.app.core.customConfig.client.companyLogo
                        }
                        id="logo"
                      />
                    }
                    phone={{
                      label:
                        this.props.organisationConfiguration?.links?.phone
                          ?.label,
                      number:
                        this.props.organisationConfiguration?.links?.phone
                          ?.number,
                      ctmNumber:
                        this.props.organisationConfiguration?.links?.phone
                          ?.ctmNumber,
                      ctmLabel:
                        this.props.organisationConfiguration?.links?.phone
                          ?.ctmLabel,
                      iSelectNumber:
                        this.props.organisationConfiguration?.links?.phone
                          ?.iSelectNumber,
                      iSelectLabel:
                        this.props.organisationConfiguration?.links?.phone
                          ?.iSelectLabel
                    }}
                    isAuthorised={!isGuest}
                    signIn={signIn}
                    signOut={signOut}
                    renderRight={
                      <>
                        <BladeIcon
                          onClick={() =>
                            this.setState({
                              isMenuOpen: !this.state.isMenuOpen
                            })
                          }
                          ignoreTriggerStyle={true}
                          name={'faBars'}
                        />
                      </>
                    }
                  />
                )}

                <HoneyContext.Consumer>
                  {({ guide }) => (
                    <Scaffold
                      className={'content'}
                      style={{
                        ...(this.props.app.core.customConfig.userInterface
                          .footer
                          ? {
                              backgroundImage: `url("${this.props.app.core.customConfig.userInterface.footer.image}")`,
                              backgroundPosition: 'center bottom',
                              backgroundRepeat: 'repeat-x',
                              paddingBottom: '100px'
                            }
                          : {})
                      }}
                      orient={'col'}
                      grow={true}
                    >
                      <Scaffold
                        position={'ch'}
                        grow={true}
                        style={{
                          minHeight: '500px',
                          paddingBottom: this.props.organisationConfiguration
                            ?.footer
                            ? 0
                            : 20,
                          ...(this.props.app.core.customConfig.userInterface
                            .progressBar !== undefined &&
                          'showProgressBar' in
                            this.props.app.core.customConfig.userInterface
                              .progressBar &&
                          this.props.app.core.customConfig.userInterface
                            .progressBar.showProgressBar === true
                            ? { flexWrap: 'wrap' }
                            : {})
                        }}
                      >
                        <Switch>
                          <Route
                            exact
                            path={'/'}
                            render={props => (
                              <ProductBaseRedirect
                                dashboardPath={routes.home.path}
                                auth={this.props.app?.auth?.user}
                              />
                            )}
                          />
                          <Route
                            path={routes.widgets.path}
                            component={() => <BladePage loading={true} />}
                          />

                          <Route
                            path={'/loader'}
                            component={() => <BladeLoader />}
                          />

                          <Route
                            exact
                            path={routes.home.path}
                            render={props => (
                              <ProductDashboardRedirect
                                goToPolicy={boundaryProps.goToPolicy}
                                goToSignIn={boundaryProps.goToSignIn}
                                dashboardPath={routes.home.path}
                                goToProduct={boundaryProps.goToProduct}
                                signOut={boundaryProps.signOut}
                                auth={this.props.app?.auth?.user}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={routes.termsAndConditions.path}
                            component={TermsAndConditions}
                          />
                          <Route
                            exact
                            path={routes.privacyPolicy.path}
                            component={PrivacyPolicy}
                          />
                          <RestrictedRoute
                            exact
                            path={routes.signIn.path}
                            component={SignIn}
                            guestOnly={true}
                          />
                          {!this.props.app.core.customConfig.userInterface
                            ?.authentication?.signIn?.oneTimeSignInLink
                            ?.enabled && (
                            <RestrictedRoute
                              exact
                              path={routes.signUp.path}
                              component={SignUp}
                              guestOnly={true}
                            />
                          )}
                          <RestrictedRoute
                            exact
                            path={routes.resetPassword.path}
                            component={ResetPassword}
                            guestOnly={true}
                          />
                          <RestrictedRoute
                            exact
                            path={routes.resetPasswordNewPassword.path}
                            component={ResetPasswordNewPassword}
                            guestOnly={true}
                          />
                          <RestrictedRoute
                            exact
                            path={routes.createAccount.path}
                            component={CreateAccount}
                            guestOnly={true}
                          />
                          <RestrictedRoute
                            exact
                            path={routes.createAccountPassword.path}
                            component={CreateAccountPassword}
                            guestOnly={true}
                          />
                          <RestrictedRoute
                            exact
                            path={routes.socialCallback.path}
                            component={SocialCallback}
                            guestOnly={true}
                          />
                          <Route
                            exact
                            path={routes.changePassword.path}
                            component={ChangePassword}
                          />

                          <Route
                            exact
                            path="/partner/:productReference(stella_pr_motor|stella_pr_motor_asu)/quote/:quoteId"
                            render={props => <MotorLandingPage {...props} />}
                          />

                          <Route
                            exact
                            path={appMap.productOverview.pathConfiguration}
                            render={props => (
                              <ProductOverview
                                {...props}
                                goToQuoteStart={(
                                  productReference,
                                  productCoverReference
                                ) => {
                                  const productReferenceLabel =
                                    productReference.split('_')[2];

                                  let voucherCode =
                                    queryParamGet(
                                      this.props.location.search,
                                      'voucherCode'
                                    ) ?? null;
                                  voucherCode = !!voucherCode
                                    ? (voucherCode = `&voucherCode=${voucherCode}`)
                                    : '';

                                  this.props.history.push(
                                    `/quote/new?productReference=${productReference}&productCoverReference=${productCoverReference}${voucherCode}`,
                                    { origin: this.props.location.pathname }
                                  );
                                }}
                                goToQuoteStartV3={(
                                  questionSetReference,
                                  productID,
                                  productCoverReference
                                ) => {
                                  this.props.history.push(
                                    `/products/${productID}/quote/NewBusiness/${questionSetReference}?coverType=${productCoverReference}`,
                                    { origin: this.props.location.pathname }
                                  );
                                }}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/products/:productId/variantSummary/:type?'}
                            render={props => (
                              <MvmtSimpleQuoteVariantSummary {...props} />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/products/:productID/quick-quote-summary/:editSessionID'
                            }
                            render={props => (
                              <MvmtSimpleQuoteQuickQuoteSummary {...props} />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/products/:productId/quote/:type/:questionSetReferenceID/:pageIndex?'
                            }
                            render={props => (
                              <MvmtSimpleQuote
                                dashboardPath={routes.home.path}
                                isAuthenticated={this.props.app?.auth?.user}
                                hideFooter={() => {
                                  this.setState({ isFooterVisible: false });
                                }}
                                {...props}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/sf/:productRef/quote/:type/:questionSetReferenceID/:pageIndex?'
                            }
                            render={props => (
                              <MvmtSimpleQuoteSf
                                dashboardPath={routes.home.path}
                                isAuthenticated={this.props.app?.auth?.user}
                                hideFooter={() => {
                                  this.setState({ isFooterVisible: false });
                                }}
                                {...props}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/console/:productId/quote/:type/:questionSetReferenceID/:pageIndex?'
                            }
                            render={props => (
                              <MvmtSimpleQuoteConsole
                                dashboardPath={routes.home.path}
                                isAuthenticated={this.props.app?.auth?.user}
                                hideFooter={() => {
                                  this.setState({ isFooterVisible: false });
                                }}
                                {...props}
                              />
                            )}
                          />

                          {this.props.organisationConfiguration.policy
                            ?.multiDocumentsPage && (
                            <Route
                              exact
                              path={'/:type/:id/documents'}
                              render={props => (
                                <MvmtDocumentsSummary
                                  goToSignIn={boundaryProps.goToSignIn}
                                  goToCheckout={boundaryProps.goToCheckout}
                                  auth={this.props.app?.auth?.user}
                                  {...props}
                                />
                              )}
                            />
                          )}

                          <Route
                            exact
                            path={
                              '/quote/:productId/:lineOfBusiness/:coverType'
                            }
                            render={props => (
                              <ProductOverviewRedirect
                                {...props}
                                goToProductOverview={
                                  appMap.productOverview.goTo
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={appMap.intelligentQuote.pathConfiguration}
                            render={props => (
                              <IntelligentQuote {...boundaryProps} {...props} />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/:routeAction/:productId/:lineOfBusiness/:coverType/questions/:pageIndex'
                            }
                            render={props => (
                              <MvmtIntelligentQuoteRedirect
                                {...boundaryProps}
                                {...props}
                                goToIntelligentQuote={
                                  appMap.intelligentQuote.goTo
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/:routeAction/:productId/:lineOfBusiness/:coverType/summary/:quoteId'
                            }
                            render={props => (
                              <MvmtIntelligentQuoteSummaryRedirect
                                {...boundaryProps}
                                {...props}
                                goToIntelligentQuote={
                                  appMap.intelligentQuote.goTo
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/policy/:policyId/adjust'}
                            render={props => (
                              <IntelligentQuoteAdjustOverview
                                {...boundaryProps}
                                {...props}
                                goToIntelligentQuoteAskForAdjust={policyId => {
                                  this.props.history.push(
                                    appMap.intelligentQuote.goTo(
                                      'adjust',
                                      null,
                                      null,
                                      null,
                                      policyId
                                    ),
                                    { origin: this.props.location.pathname }
                                  );
                                }}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/mta/:productId/:lineOfBusiness/:coverType/:policyId'
                            }
                            render={props => (
                              <MvmtIntelligentQuoteAdjustOverviewRedirect
                                {...boundaryProps}
                                {...props}
                                goToIntelligentQuoteForAdjust={policyId =>
                                  this.props.history.replace(
                                    `/policy/${policyId}/adjust`
                                  )
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/checkout/:quoteId'}
                            render={props => (
                              <Checkout
                                {...props}
                                goToQuoteSummary={
                                  boundaryProps.goToQuoteSummary
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/:routeAction/:productId/:lineOfBusiness/:coverType/declined'
                            }
                            render={props => (
                              <MvmtVriDeclinedRedirect
                                {...boundaryProps}
                                {...props}
                                getVriDeclinedPath={(
                                  productReference,
                                  productCoverReference
                                ) =>
                                  `/vri/declined?productReference=${productReference}&productCoverReference=${productCoverReference}`
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/vri/:productId/:lineOfBusiness/:coverType/overview/:quoteId'
                            }
                            render={props => (
                              <MvmtVriSummaryRedirect
                                {...boundaryProps}
                                {...props}
                                getVriSummaryPath={quoteId =>
                                  `/vri/summary/${quoteId}`
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={
                              '/vri/:productId/:lineOfBusiness/:coverType/missing-data'
                            }
                            render={props => (
                              <MvmtVriMissingDataRedirect
                                {...boundaryProps}
                                {...props}
                                getVriMissingDataPath={(
                                  productReference,
                                  productCoverReference
                                ) =>
                                  `/vri/missing-data?productReference=${productReference}&productCoverReference=${productCoverReference}`
                                }
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/quick-quote/:quoteId'}
                            render={props => (
                              <MvmtIntelligentQuickQuote
                                {...boundaryProps}
                                {...props}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/policy/:policyId/update-card-details'}
                            component={UpdateCardDetails}
                          />
                          <Route
                            exact
                            path={
                              '/manage/:productId/:lineOfBusiness/:coverType/:policyId'
                            }
                            render={props => (
                              <MvmtPolicySummaryRedirect
                                {...boundaryProps}
                                {...props}
                                getPolicySummaryPath={policyId =>
                                  `/policy/${policyId}`
                                }
                              />
                            )}
                          />
                          <Route
                            exact
                            path={
                              '/manage/:productId/:lineOfBusiness/:coverType/:policyId/summary'
                            }
                            render={props => (
                              <MvmtPolicySummaryRedirect
                                {...boundaryProps}
                                {...props}
                                getPolicySummaryPath={policyId =>
                                  `/policy/${policyId}`
                                }
                              />
                            )}
                          />
                          <Route
                            exact
                            path={'/policy/:policyId'}
                            render={props => (
                              <MvmtPolicySummary
                                {...boundaryProps}
                                {...props}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/policy/:policyId/summary-of-cover'}
                            render={props => (
                              <MvmtPolicySummaryOfCover
                                {...boundaryProps}
                                {...props}
                              />
                            )}
                          />

                          {!this.props.organisationConfiguration.policy
                            ?.multiDocumentsPage && (
                            <Route
                              exact
                              path={'/policy/:policyId/documents'}
                              render={props => (
                                <MvmtPolicyDocuments
                                  {...boundaryProps}
                                  {...props}
                                />
                              )}
                            />
                          )}

                          <Route
                            exact
                            path={'/policy/:policyId/claim/:claimType'}
                            render={props => (
                              <MvmtPolicyClaimInfo
                                {...boundaryProps}
                                {...props}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/policy/:policyId/cancel'}
                            render={props => (
                              <MvmtPolicyCancel {...boundaryProps} {...props} />
                            )}
                          />

                          <Route
                            exact
                            path={'/policy/:policyId/renew'}
                            render={props => (
                              <PolicyRenew {...boundaryProps} {...props} />
                            )}
                          />

                          <Route
                            exact
                            path={'/vri/summary/:quoteId'}
                            render={props => (
                              <MvmtVriSummary
                                {...props}
                                documentTitle={`Quote Estimate | ${this.props.organisationConfiguration.name}`}
                                goToQuoteStart={quoteId => {
                                  this.props.history.push(
                                    `/quote/new?quoteId=${quoteId}`
                                  );
                                }}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/vri/missing-data'}
                            render={props => (
                              <MvmtVriMissingData
                                {...props}
                                documentTitle={`Quote Estimate | ${this.props.organisationConfiguration.name}`}
                                goToQuoteStart={(
                                  productReference,
                                  productCoverReference
                                ) => {
                                  this.props.history.push(
                                    `/quote/new?productReference=${productReference}&productCoverReference=${productCoverReference}`
                                  );
                                }}
                              />
                            )}
                          />

                          <Route
                            exact
                            path={'/vri/declined'}
                            render={props => (
                              <MvmtVriDeclined
                                {...props}
                                documentTitle={`Quote Estimate Declined | ${this.props.organisationConfiguration.name}`}
                                goHome={() =>
                                  this.props.history.push(`/dashboard`)
                                }
                              />
                            )}
                          />

                          {/* End of old policy routes */}

                          <Route component={() => 'Page not found'} />
                        </Switch>
                      </Scaffold>
                      {this.props.organisationConfiguration?.footer &&
                        this.state.isFooterVisible === true &&
                        chrome === true && (
                          <BladeAppFooter
                            viewVariant={
                              this.props.location.pathname.includes('checkout')
                                ? 'm'
                                : 's'
                            }
                            company={{
                              logo: this.props.organisationConfiguration?.footer
                                .logo,
                              link: this.props.organisationConfiguration?.links
                                .homepage,
                              description:
                                this.props.organisationConfiguration?.footer
                                  .description
                            }}
                            regulatoryStatement={resolveRegulatoryStatement(
                              this.state.productReference
                            )}
                            navigation={
                              this.props.organisationConfiguration?.footer
                                .navigation
                            }
                            connections={
                              this.props.organisationConfiguration?.footer
                                .connections
                            }
                            links={
                              this.props.organisationConfiguration?.footer.links
                            }
                            isAuthorised={!isGuest}
                            changePassword={changePassword}
                          />
                        )}

                      {this.props.app.core.customConfig.userInterface
                        .showPoweredBy &&
                        this.state.isFooterVisible === true &&
                        chrome === true && (
                          <Scaffold
                            style={{ backgroundColor: 'var(--app-footer)' }}
                            size={'large'}
                            position={'ch'}
                          >
                            <a
                              href="https://www.aventusplatform.com"
                              target="_BLANK"
                              rel="noreferrer"
                            >
                              <img
                                alt={'Powered by Aventus Platform'}
                                src={poweredByAventus}
                                style={{
                                  alignSelf: 'center',
                                  width: '150px',
                                  height: 'auto'
                                }}
                              />
                            </a>
                          </Scaffold>
                        )}
                    </Scaffold>
                  )}
                </HoneyContext.Consumer>
              </Scaffold>
            </>
            {/* )} */}
          </AppBoundary>
        </ErrorBoundary>
      </>
    );
  };
}

export default widgetApi(appApi(Initialiser));
