import { MARGIN_GUIDE, PADDING_GUIDE, CONTENT_GUIDE } from './common';
import { ROW } from './row';
import { COL } from './col';

const simpleSizeMap = ({ guide, size}) => guide.scaffold[size];

export default {

  row: { ...ROW },
  col: { ...COL },

  scaffoldCol: {
    '&:not(.xray)': {

      padding: ({ guide, size }) => {
        const sizeValue = guide.scaffold[size];
        return `${sizeValue} ${sizeValue} 0 ${sizeValue}`;
      },

      '& > * ': {
        marginBottom: simpleSizeMap
      },

      '& > form > * ': {
        marginBottom: simpleSizeMap
      },

      '&.only-children': {
        padding: 0,
        '& > *': {
          marginBottom: simpleSizeMap
        }
      },

      '&.only-parent': {
        padding: simpleSizeMap,
        '& > *': {
          marginBottom: 0
        }
      }
    },

    '&.xray': {
      ...PADDING_GUIDE,
      borderTopWidth: simpleSizeMap,
      borderRightWidth: simpleSizeMap,
      borderBottomWidth: 0,
      borderLeftWidth: simpleSizeMap,

      '& > *': {
        ...MARGIN_GUIDE,
        ...CONTENT_GUIDE,
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: simpleSizeMap,
        borderLeftWidth: 0
      },

      '&.only-children': {

        ...PADDING_GUIDE,
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,

        '& > *': {
          ...MARGIN_GUIDE,
          ...CONTENT_GUIDE,
          borderTopWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: simpleSizeMap,
          borderLeftWidth: 0
        }
      }
    }
  },

  scaffoldRow: {
    '&:not(.xray)': {
      padding: ({ guide, size }) => {
        const sizeValue = guide.scaffold[size];
        return `${sizeValue} 0 ${sizeValue} ${sizeValue}`;
      },

      '& > * ': {
        marginRight: simpleSizeMap
      },

      '&.wrap': {
        padding: ({ guide, size }) => {
          const sizeValue = guide.scaffold[size];
          return `${sizeValue} 0 0 ${sizeValue}`;
        },
        '& > *': {
          marginBottom: simpleSizeMap
        }
      },

      '&.only-children': {
        padding: 0,
        '& > *': {
          marginRight: simpleSizeMap,
          '&:last-child': {
            marginRight: 0
          }
        },
        '&.wrap': {
          padding: 0,
          '& > *': {
            marginBottom: simpleSizeMap,
            '&:last-child': {
              marginRight: simpleSizeMap
            }
          }
        }
      },

      '&.only-parent': {
        padding: simpleSizeMap,
        '& > *': {
          marginRight: 0
        },
        '&.wrap': {
          '& > *': {
            marginBottom: 0
          }
        }
      }
    },

    '&.xray': {
      ...PADDING_GUIDE,
      borderTopWidth: simpleSizeMap,
      borderRightWidth: 0,
      borderBottomWidth: simpleSizeMap,
      borderLeftWidth: simpleSizeMap,

      '& > *': {
        ...MARGIN_GUIDE,
        ...CONTENT_GUIDE,
        borderTopWidth: 0,
        borderRightWidth: simpleSizeMap,
        borderBottomWidth: 0,
        borderLeftWidth: 0
      },

      '&.wrap': {

        ...PADDING_GUIDE,
        borderTopWidth: simpleSizeMap,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: simpleSizeMap,

        '& > *': {
          ...MARGIN_GUIDE,
          ...CONTENT_GUIDE,
          borderTopWidth: 0,
          borderRightWidth: simpleSizeMap,
          borderBottomWidth: simpleSizeMap,
          borderLeftWidth: 0
        }
      },

      '&.only-children': {
        border: 0
      }
    }
  }
}
