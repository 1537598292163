import { ITobesQuestionMap } from '@aventus/platform-client-context/models';
import { IStoredFieldAnswer } from '@aventus/mvmt-simplequote/models/fieldAnswer';
import {
  alterAnswerInArray,
  initFromQuestion,
  removeAnswerInArray,
  updateAnswerArray,
  validateArrayAnswers
} from '../itemArrayHelpers';
import {
  BladeFormFieldset,
  BladeInputCollectionV7,
  BladeInputSelect
} from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { ICommonFieldProps } from '@aventus/mvmt-simplequote/models/commonProps';
import { ICommonInputProps } from '@aventus/mvmt-simplequote/models/commonProps';
import { IFormatters } from '@aventus/mvmt-simplequote/models/formatters';
import { ISettings } from '@aventus/mvmt-simplequote/models/settings';
import { renderQuestionsForPage } from './renderQuestion';
import React from 'react';
import { BladeItemArrayForm } from '@aventus/blade/input/item-array-form';
import { TobesQuestionPageState } from '../../store/form-state';
import { createRefKey } from '..';

export function renderArray(
  question: ITobesQuestionMap,
  commonFieldsetProps: ICommonFieldProps,
  commonInputProps: ICommonInputProps,
  settings: ISettings,
  formatters: IFormatters,
  parent: string | null,
  getQuestionAnswer: (referenceID: string) => any,
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null,
    arrayQuestion?: boolean
  ) => void,
  getArrayDescription: (referenceID: string, template?: string) => string,
  collectionState: IStoredFieldAnswer[],
  setCollectionState: (array: IStoredFieldAnswer[]) => void,
  formState: TobesQuestionPageState,
  productConfiguration?: InsuranceProductConfiguration
) {
  initFromQuestion(
    collectionState,
    Array.isArray(question.existingValue) ? question.existingValue : [],
    question.referenceID,
    setCollectionState
  );

  const arrayPageQuestions =
    question.arrayPage !== null ? question.arrayPage.questions : [];

  const commonInputWithFieldsetsProps = {
    parent,
    arrayPageQuestions,
    tobesQuestion: question,
    collectionTitle: question.arrayPage?.title || 'Collection',
    addToCollectionLabel: question.text?.buttonText
      ? question.text.buttonText
      : 'Add',
    addAnotherToCollectionLabel: question.text.multipleButtonText
      ? question.text.multipleButtonText
      : 'Add',
    itemLabel: getArrayDescription(question.referenceID),
    itemKey: question.referenceID,
    getQuestionAnswer,
    questionArrayAnswers: question.existingValue || [],
    getQuestions: (currentId: string) =>
      renderQuestionsForPage(
        arrayPageQuestions,
        formatters,
        settings,
        (referenceID: string) => {
          return collectionState?.find(
            x =>
              x.fieldReference === referenceID &&
              x.uniqueReferenceId === currentId
          )
            ? collectionState?.filter(
                x =>
                  x.fieldReference === referenceID &&
                  x.uniqueReferenceId === currentId
              )[0].answer
            : '';
        },
        (referenceID: string, value: any) => {
          updateAnswerArray(
            collectionState,
            setCollectionState,
            currentId,
            referenceID,
            question.referenceID,
            value
          );
        },
        getArrayDescription,
        parent,
        collectionState,
        setCollectionState,
        formState,
        (questionKey: string) => {
          const question = collectionState?.find(x => {
            return (
              createRefKey(x.fieldReference, x.parentQuestionReference) ===
                questionKey && x.uniqueReferenceId === currentId
            );
          });

          if (question === undefined) {
            return undefined;
          }

          return {
            referenceID: question?.fieldReference,
            value: question?.answer,
            validationErrors: question?.validationErrors,
            children: []
          };
        },
        (fieldKey: string) => {
          const collection = collectionState.map(item => {
            if (
              fieldKey ===
              createRefKey(item.fieldReference, item.parentQuestionReference)
            ) {
              return {
                ...item,
                validationErrors: []
              };
            }

            return item;
          });

          setCollectionState(collection);
        },
        productConfiguration
      )
  };

  const commonV7FieldSets = {
    addToCollection: (formInstanceId: string, questionName: string) => {
      alterAnswerInArray(
        formInstanceId,
        questionName,
        collectionState,
        changeQuestionAnswer
      );
    },
    updateItemInCollection: (formInstanceId: string, questionName: string) => {
      alterAnswerInArray(
        formInstanceId,
        questionName,
        collectionState,
        changeQuestionAnswer
      );
    },
    removeFromCollection: (formInstanceId: string, questionName: string) => {
      removeAnswerInArray(formInstanceId, questionName, changeQuestionAnswer);
    },
    validateCollectionItem: async (formID: string, parentReferenceID: string) =>
      validateArrayAnswers(
        formID,
        arrayPageQuestions,
        collectionState,
        setCollectionState,
        parentReferenceID
      )
  };

  switch (question.questionType) {
    case 'claims':
      return (
        <p>
          DO NOT USE "{question.questionType}", Use "ItemArrayForm" questionType
          instead.
        </p>
      );
    case 'itemarray':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputCollectionV7
            {...commonInputProps}
            {...commonInputWithFieldsetsProps}
            {...commonV7FieldSets}
          />
        </BladeFormFieldset>
      );
    case 'ItemArrayForm':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeItemArrayForm
            {...commonInputProps}
            {...commonInputWithFieldsetsProps}
            {...commonV7FieldSets}
            questionObject={question.customProperties.questionNoun}
          />
        </BladeFormFieldset>
      );
    case 'multiSelect':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputSelect
            {...commonInputProps}
            options={question.answerOptions}
            matchOn={'referenceID'}
            multiSelect={true}
            supressMobile={true}
          />
        </BladeFormFieldset>
      );
    case 'boxmodal':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputCollectionV7
            {...commonInputProps}
            {...commonInputWithFieldsetsProps}
            {...commonV7FieldSets}
          />
        </BladeFormFieldset>
      );
    case 'singleentitymodal':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputCollectionV7
            {...commonInputProps}
            {...commonInputWithFieldsetsProps}
            {...commonV7FieldSets}
            maxNumberOfItems={1}
          />
        </BladeFormFieldset>
      );

    case 'singleentityboxmodal':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputCollectionV7
            {...commonInputProps}
            {...commonInputWithFieldsetsProps}
            {...commonV7FieldSets}
            maxNumberOfItems={1}
          />
        </BladeFormFieldset>
      );
    default:
      return (
        <p>{question.objectType}</p>
      ); /*TODO: temporarily render to see if we are missing question types*/
  }
}
