import update from 'immutability-helper';

export function resetPasswordNewPasswordRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isResettingPasswordNewPassword: { $set: true }
        }
      })
    }
  };
}

export function resetPasswordNewPasswordSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isResettingPasswordNewPassword: false,
            successfullyResetPasswordNewPassword: true
          }
        }
      })
    }
  };
}

export function resetPasswordNewPasswordFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isResettingPasswordNewPassword: false,
            successfullyResetPasswordNewPassword: false
          }
        }
      })
    }
  }
}
