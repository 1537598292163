import * as React from 'react';

import { CheckoutBoundary } from './boundary';
import { Checkout, ICheckout } from './views/checkout';
import { UpdateCardDetails, IUpdateCardDetails } from './views/update-card-details';

export { LifecyclesUpdateSavedCard, OnStripeCreateSetupIntent, OnUpdatePolicyPaymentMethod } from './interface/lifecycles-update-saved-card';

export { VoucherCode } from './components/voucher-code';
export { ReceiptPay } from './components/receipt-pay';
export { ReceiptAdjust } from './components/receipt-adjust';
export { ReceiptSummary } from './components/receipt-summary';

export const MvmtCheckout = (props: ICheckout) => (
  <CheckoutBoundary
  goToLogin={props.goToLogin}
  goHome={props.onIsInvalid}>
    <Checkout { ...props } />
  </CheckoutBoundary>
);

export const MvmtUpdateCardDetails = (props: IUpdateCardDetails) => (
  <CheckoutBoundary
  goToLogin={props.goToLogin}
  goHome={props.goHome}>
    <UpdateCardDetails { ...props } />
  </CheckoutBoundary>
);
