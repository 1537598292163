import * as React from 'react';

export const useSessionStorage: UseSessionStorage = sessionStorageKey => {
  const [storageValue] = React.useState(
    sessionStorage.getItem(sessionStorageKey) || ''
  );

  return { storageValue };
};

export type UseSessionStorage = (
  sessionStorageKey: string
) => {
  storageValue: any;
};
