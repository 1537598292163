import moment from 'moment';
import {
  ToPlatformReadable,
  ToApplicationReadable
} from '@aventus/application-timezone';
import React, { MutableRefObject } from 'react';

export const useManualDateEntry: UseManualDateEntry = (
  value,
  dateFormat,
  _onChange,
  toPlatformReadable,
  toApplicationReadable,
  inputs
) => {
  const [day, setDay] = React.useState<string>('');
  const [month, setMonth] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');

  var splitFormat = dateFormat.split('/');

  const addLeadingZero = (n: number): string => {
    if (n > 9) {
      return n.toString();
    }

    return '0' + n.toString();
  };

  if (
    value !== undefined &&
    value !== null &&
    value !== '//' &&
    day === '' &&
    month === '' &&
    year === ''
  ) {
    var initValue = toApplicationReadable(value);
    setDay(addLeadingZero(initValue.date()));
    setMonth(addLeadingZero(initValue.month() + 1));
    setYear(initValue.year().toString());
    // We need to trigger onChange to push
    // the value back up to the risk/trigger validation
    _onChange(toPlatformReadable(initValue));
  }

  const handleChange: BladeInputOnChange = e => {
    var inputValue = '';
    var fieldName = e.target.placeholder;

    for (var i = 0; i < e.target.value.length; i++) {
      if (Number.isInteger(Number(e.target.value[i]))) {
        inputValue += e.target.value[i];
      }
    }

    switch (fieldName) {
      case 'DD':
        setDay(inputValue);
        focusNextInput(inputValue, inputs.month);
        break;
      case 'MM':
        setMonth(inputValue);
        focusNextInput(inputValue, inputs.year);
        break;
      case 'YYYY':
        setYear(inputValue);
        break;
    }
    onChange(e);
  };

  const focusNextInput = (
    inputValue: string,
    nextInput: MutableRefObject<Nullable<HTMLInputElement>>
  ) => {
    if (inputValue.length === 2) {
      nextInput.current?.focus();
    }
  };

  const onChange: BladeInputOnChange = e => {
    var inputValue = '';

    for (var i = 0; i < e.target.value.length; i++) {
      if (Number.isInteger(Number(e.target.value[i]))) {
        inputValue += e.target.value[i];
      }
    }

    var fieldName = e.target.placeholder;
    var isPartialDate = false;
    var newDate = '';
    for (var i = 0; i < splitFormat.length; i++) {
      if (fieldName === splitFormat[i]) {
        newDate += inputValue;
        if (inputValue === '') {
          isPartialDate = true;
        }
      } else {
        var fieldValue = '';
        switch (splitFormat[i]) {
          case 'DD':
            fieldValue = day;
            break;
          case 'MM':
            fieldValue = month;
            break;
          case 'YYYY':
            fieldValue = year;
            break;
        }

        newDate += fieldValue;

        if (fieldValue === '') {
          isPartialDate = true;
        }
      }

      if (i !== splitFormat.length - 1) {
        newDate += '/';
      }
    }

    // Always get the last element of the array
    // We can assume this will be the year
    var inferYear = newDate.split('/').slice(-1)[0];
    if (inferYear.length < 4) {
      isPartialDate = true;
    }

    //We still want the invalid date validator to work as is
    if (isPartialDate) {
      _onChange(newDate);
    } else {
      _onChange(toPlatformReadable(moment(newDate, dateFormat)));
    }
  };

  return {
    handleChange,
    day,
    month,
    year
  };
};

export type UseManualDateEntry = (
  value: string | null,
  dateFormat: string,
  _onChange: BladeInputOnChangeCustom,
  toPlatformReadable: ToPlatformReadable,
  toApplicationReadable: ToApplicationReadable,
  inputs: {
    month: MutableRefObject<Nullable<HTMLInputElement>>;
    year: MutableRefObject<Nullable<HTMLInputElement>>;
  }
) => {
  handleChange: BladeInputOnChange;
  day: string;
  month: string;
  year: string;
};
