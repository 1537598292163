import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import cx from 'classnames';
import css from './index.css';
import { useManualDateEntry } from './use-manual-date-entry';
import { useApplicationTimezone } from '@aventus/application-timezone';

export const BladeInputDateManualEntry: React.FunctionComponent<
  IBladeInputDate
> = props => {
  const inputProps = useInput(props);
  const monthInputId = 'month-input';
  const yearInputId = 'year-input';
  const splitFormat: string[] = props.dateFormat.split('/');
  const monthInput: React.MutableRefObject<Nullable<HTMLInputElement>> =
    React.useRef(null);
  const yearInput: React.MutableRefObject<Nullable<HTMLInputElement>> =
    React.useRef(null);

  const isValidNumber = (value: string): string | undefined =>
    !isNaN(Number(value)) ? value : undefined;

  const { toPlatformReadable, toApplicationReadable } =
    useApplicationTimezone();

  const { handleChange, day, month, year } = useManualDateEntry(
    props.value,
    props.dateFormat,
    inputProps.onChange,
    toPlatformReadable,
    toApplicationReadable,
    { month: monthInput, year: yearInput }
  );

  const [autoFocusedNextDobInput, setAutoFocusedNextDobInput] =
    React.useState<boolean>(false);

  const [showValidation, setShowValidation] = React.useState(false);

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setShowValidation(true);
    setAutoFocusedNextDobInput(
      event.relatedTarget?.id === monthInputId ||
        event.relatedTarget?.id === yearInputId
    );
    inputProps.onBlur && inputProps.onBlur(event);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange && handleChange(event);
    setShowValidation(false);
  };

  var createCustomLayout = () => {
    var htmlBody = [];
    for (var i = 0; i < splitFormat.length; i++) {
      switch (splitFormat[i]) {
        case 'DD':
          htmlBody.push(
            <input
              key={props.name + i}
              type="text"
              inputMode="numeric"
              pattern="^[0-9]*$"
              className={inputProps.className + ' ' + css.daybox}
              placeholder={'DD'}
              name={props.name + '-day'}
              value={isValidNumber(day)}
              onFocus={inputProps.onFocus}
              onBlur={onBlur}
              maxLength={2}
              autoComplete="off"
              onChange={onChange}
            />
          );
          break;
        case 'MM':
          htmlBody.push(
            <input
              id={monthInputId}
              key={props.name + i}
              ref={monthInput}
              type="text"
              inputMode="numeric"
              pattern="^[0-9]*$"
              className={inputProps.className + ' ' + css.monthbox}
              placeholder={'MM'}
              name={props.name + '-month'}
              value={isValidNumber(month)}
              autoComplete="off"
              maxLength={2}
              onBlur={onBlur}
              onChange={onChange}
            />
          );
          break;
        case 'YYYY':
          htmlBody.push(
            <input
              id={yearInputId}
              key={props.name + i}
              ref={yearInput}
              type="text"
              inputMode="numeric"
              pattern="^[0-9]*$"
              className={inputProps.className + ' ' + css.yearBox}
              placeholder={'YYYY'}
              name={props.name + '-year'}
              autoComplete="off"
              maxLength={4}
              value={isValidNumber(year)}
              onBlur={onBlur}
              onChange={onChange}
            />
          );
          break;
      }

      if (i !== splitFormat.length - 1) {
        htmlBody.push(
          <div key={'div-' + props.name + i} className={css.seperators}>
            /
          </div>
        );
      }
    }
    return htmlBody;
  };
  return (
    <BladeInput_Frame
      hasBeenInteractedWith={
        showValidation &&
        !autoFocusedNextDobInput &&
        inputProps.hasBeenInteractedWith
      }
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <div
        className={cx(
          inputProps.className,
          css.datepickerShellOveride,
          css.centerDateInputs
        )}
      >
        {createCustomLayout()}
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputDate extends IBladeInput {
  now?: string;
  dateFormat: string;
}
