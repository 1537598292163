import * as React from 'react';
import cx from 'classnames';
import css from './index.css';

export const MobileSelect: React.FunctionComponent<IMobileSelect> = props => {
  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    props.onChange(JSON.parse(event.target.value));
  };

  return (
    <div className={css.mobile_select_wrap}>
      <select
        onChange={onSelectChange}
        className={cx(css.mobile_select, props.classNames)}
        multiple={props.multiple ? true : false}
      >
        {props.options &&
          props.options.map(option => {
            const optionText =
              option?.name || option?.Name || Object.values(option)[0];
            const isSelected =
              option[props.matchOn] === props.initialValue ||
              option === props.initialValue;
            return (
              <option selected={isSelected} value={JSON.stringify(option)}>
                {option?.text || optionText}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export interface IMobileSelect {
  options: any[];
  initialValue: string | undefined;
  matchOn: string;
  classNames: string | undefined;
  multiple?: boolean
  onChange: (value: any) => void;
}
