import * as React from 'react';
import { BladeView, BladeSurface, BladeMarkdown } from '@aventus/blade';
import css from './index.css';
import { RouteComponentProps } from 'react-router-dom';
import { useDocumentTitle } from '@aventus/pockethooks';
import { useGetPolicyScheme } from '@aventus/symphony-client/hooks';
import { useGetPolicyAddons } from '@aventus/symphony-client/hooks/use-get-policy-addons';

export const PolicyClaimInfo: React.FunctionComponent<IPolicyClaimInfo> = (
  props: IPolicyClaimInfo
) => {
  useDocumentTitle('Claim Info');

  const { policyScheme } = useGetPolicyScheme(
    props.match.params.policyId.trim()
  );
  const { isGettingPolicyAddons, policyAddons } = useGetPolicyAddons(
    props.match.params.policyId.trim()
  );

  if (policyScheme && !isGettingPolicyAddons) {
    const renderPolicyAddons =
      props.match.params.claimType != 'main' &&
      policyAddons &&
      policyAddons.length > 0;
    if (props.match.params.claimType == 'main') {
      return (
        <BladeView variant={'s'} className={css.claimInfo}>
          <BladeSurface className={css.claimInfo_claim}>
            <BladeMarkdown
              size={'base'}
              markdown={policyScheme.claimInfo.content}
            />
          </BladeSurface>
        </BladeView>
      );
    }

    if (renderPolicyAddons && policyAddons) {
      const addon = policyAddons.filter(
        pa => pa.scheme.referenceCode === props.match.params.claimType
      )[0];

      return (
        <BladeView variant={'s'} className={css.claimInfo}>
          <BladeSurface className={css.claimInfo_claim}>
            <BladeMarkdown
              size={'base'}
              markdown={addon?.scheme?.claimInfo?.content}
            />
          </BladeSurface>
        </BladeView>
      );
    }
  }
  return null;
};

export interface IPolicyClaimInfo extends RouteComponentProps<IPathParams> {}

interface IPathParams {
  policyId: string;
  claimType: string;
}
