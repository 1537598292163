import * as React from 'react';
import { useAreAtomsEqual } from '@aventus/honey-utility-react';
import { BladeIcon } from '../index';
import { ButtonVariants } from '../button';
import { BladeListValue } from './value';
import css from './index.css';
import cx from 'classnames';
import { useMediaQuery } from '@aventus/pockethooks';

export const BladeList: React.FunctionComponent<IBladeList> = props => {
  const { areAtomsEqual } = useAreAtomsEqual('--background', '--surface');
  const [showMoreDetails, setShowMoreDetails] = React.useState<number>(-1);
  const { matchesQuery } = useMediaQuery('(max-width: 600px)');

  if (!props.list || props.list.length === 0) {
    return null;
  }

  return (
    <>
      <div
        className={cx(
          'blade-list',
          css.list,
          props.isFlush && !areAtomsEqual && props.shouldRespectViewWidth
            ? css.list_isFlush
            : '',
          props.isSmall ? css.list_isSmall : '',
          props.className || ''
        )}
      >
        {props.listTitle && (
          <div className={css.list_title}>
            <h4>{props.listTitle}</h4>
          </div>
        )}

        {props.list.map((listItem: IListItem, i: number) => {
          // The following handling of image is a hangover since the data formatting
          // is handled in an index.js for both mobile and web components.
          // Until mobile view components catch up, we'll need to handle this interface.
          // But we can do this in a way that's easy to delete - we can just delete
          // this block and the rest will work.

          if (!listItem || !listItem.label) {
            return null;
          }

          let value = listItem.value;

          if (listItem.image) {
            if (listItem.image.includes('tick')) {
              value = true;
            } else if (listItem.image.includes('cross')) {
              value = false;
            }
          }
          return (
            <div
              key={i}
              className={cx(
                css.list_item,
                listItem.hasBorder && css.list_item_border,
                listItem.moreDetails ? css.list_item_isClickable : ''
              )}
              onClick={() => {
                if (listItem.moreDetails) {
                  if (showMoreDetails === i) {
                    setShowMoreDetails(-1);
                  } else {
                    setShowMoreDetails(i);
                  }
                }
              }}
            >
              <div
                className={cx(
                  Array.isArray(value)
                    ? css.list_item_row_array
                    : css.list_item_row,
                  { [css.list_item_row_mobile]: matchesQuery }
                )}
              >
                <div
                  className={cx(
                    Array.isArray(value)
                      ? css.list_item_row_key_array
                      : css.list_item_row_key,
                    { [css.list_item_row_key_mobile]: matchesQuery }
                  )}
                >
                  {listItem.moreDetails && (
                    <BladeIcon
                      name={
                        showMoreDetails === i ? 'faCaretDown' : 'faCaretRight'
                      }
                    />
                  )}

                  <label
                    className={cx(
                      css.list_item_label,
                      listItem.isBold && css.list_item_label_bold
                    )}
                  >
                    {listItem.label}
                    {listItem.subLabel && (<><br/>{listItem.subLabel}</>)}
                  </label>
                </div>

                {!!(typeof value !== 'undefined') && (
                  <BladeListValue
                    value={value}
                    isBold={listItem.isBold}
                    isBoldFirstItem={listItem.isBoldFirstItem}
                    isCompactList={listItem.isCompactList}
                    isTogglable={listItem.isTogglable}
                    togglableOnChange={listItem.togglableOnChange}
                    togglableLoading={listItem.togglableLoading}
                    buttonAction={listItem.buttonAction}
                    buttonVariant={listItem.buttonVariant}
                    buttonClass={listItem.buttonClass}
                    onRemoveClick={listItem.removeAction}
                    isRemoveDisabled={props.isRemoveDisabled}
                  />
                )}
              </div>

              {showMoreDetails === i && (
                <div className={css.list_item_moreDetails}>
                  <label>{listItem.moreDetails}a</label>
                </div>
              )}
              {listItem.additionalBlurb && (
                <div
                  className={
                    props.isFlush &&
                    !areAtomsEqual &&
                    props.shouldRespectViewWidth
                      ? css.list_item_additionalBlurb
                      : ''
                  }
                >
                  {listItem.additionalBlurb}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export interface IBladeList {
  /** Space each list item from each other and within the frame. */
  size?: string;
  listTitle?: string;
  list?: IListItem[];
  depthy?: boolean;
  isFlush?: boolean;
  isSmall?: boolean;
  className?: string;
  shouldRespectViewWidth?: boolean;
  isRemoveDisabled?: boolean;
}

export interface IListItem {
  buttonAction?: () => void;
  removeAction?: () => void;
  isRemoveDisabled?: boolean;
  buttonVariant?: ButtonVariants;
  buttonClass?: string;
  label?: string;
  subLabel?: string;
  additionalBlurb?: string;
  value?: boolean | string | string[];
  image?: string;
  moreDetails?: string;
  hasBorder?: boolean;
  isBold?: boolean;
  isBoldFirstItem?: boolean;
  isCompactList?: boolean;
  isTogglable?: boolean;
  togglableOnChange?: (value: boolean) => any;
  togglableLoading?: boolean;
}
