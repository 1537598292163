import { CustomConfig } from '@aventus/platform';
import { EProductType } from '@aventus/platform-client-context/models';
import { groupBy } from 'lodash';

export type TypeGoToProduct = (
  productReferenceId: string,
  name: string,
  productId?: string,
  productQuoteType?: string,
  startUrl?: string
) => void;

export enum EnumProductType {
  hybrid = 'hybrid',
  digital = 'digitaluw'
}

export type TypeCallToActions = Array<{
  key: string;
  title: string;
  subTitle: string;
  labelIcon: string;
  labelImage: string;
  actionLabel: string;
  action: () => void;
}>;

export const getProductCallToActions = (
  customConfig: CustomConfig,
  goToProduct: TypeGoToProduct
) => {
  let callToActions: TypeCallToActions = [];

  const displayableLobs = [];
  const activeLinesOfBusiness = customConfig?.activeLinesOfBusiness;

  if (activeLinesOfBusiness) {
    const groupedByLobName = groupBy(activeLinesOfBusiness.filter(lob => lob.isActive && lob.coverTypes[0].productReferenceId !== 'stella_pr_motor'), 'name');

    for (const key of Object.keys(groupedByLobName)) {
      const lobProducts = groupedByLobName[key];
      const productsWithStartUrl = lobProducts.filter(product => product.coverTypes.some(coverType => !!coverType.quote.startUrl));

      // If a start url exists on one of the products with same lob name, we only want to display one button
      if (productsWithStartUrl.length > 0) {
        displayableLobs.push(productsWithStartUrl[0]);
      } else {
        displayableLobs.push(...lobProducts);
      }
    }
  }

  displayableLobs
    .sort((a, b) => a.dashboardOrder - b.dashboardOrder)
    .forEach(lob => {
      lob.coverTypes
        .sort((a, b) => a.dashboardOrder - b.dashboardOrder)
        .forEach(coverType => {
          callToActions.push({
            key: coverType.name,
            title: coverType.label,
            subTitle: coverType.desc,
            labelIcon: coverType.labelIcon || 'faQuoteRight',
            labelImage: coverType.labelImage,
            actionLabel: coverType.callToActionLabel,
            action: () =>
              EnumProductType.hybrid === lob.productType.toLowerCase() ||
                EnumProductType.digital === lob.productType.toLowerCase()
                ? goToProduct(
                  coverType.productReferenceId,
                  EProductType.standard,
                  String(lob.id)
                )
                : goToProduct(coverType.productReferenceId, coverType.name, undefined, undefined, coverType.quote.startUrl)
          });
        });
    });

  return callToActions;
};
