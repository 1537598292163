export const netherlands: string[] = [
	"home.nl",
	"hetnet.nl",
	"zonnet.nl",
	"chello.nl",
	"planet.nl",

];

export default netherlands;
