import update from 'immutability-helper';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.:;\^\\\]'<=>?@[_`{|}])[A-Za-z\d!"#$%&'()*+,-.:;\^\\\]'<=>?@[_`{|}]{8,}/;

export default function validatePassword(statePartial = {}, passwordKey = false, password = false) {

  if (!passwordKey) {
    return statePartial;
  }

  const isValidPassword = passwordRegex.test(password) || false;

  if (!isValidPassword) {
    return {
      ...statePartial,
      ...{
        [`_${passwordKey}`]: {
          error: 'That password is not valid. Make sure it\'s at least 8 characters with one number, one uppercase, one lowercase, and a symbol like \'!\' or \'?\''
        }
      }
    }
  } else {
    return {
      ...statePartial,
      ...{
        [`_${passwordKey}`]: false
      }
    }
  }
};
