import * as React from 'react';
import { useError } from '@aventus/pockethooks/use-error';
import { PaymentMethod, AdjustmentType } from '@aventus/platform';
import { OnGetPolicyPaymentMethod } from '../interface/lifecycles';

export const useGetPolicyPaymentMethod = (
  onGetPolicyPaymentMethod: OnGetPolicyPaymentMethod,
  policyId?: string,
  adjustmentType?: AdjustmentType
): UseGetPolicyPaymentMethod => {

  const { throwError } = useError();

  const [ policyPaymentMethod, setPolicyPaymentMethod ] = React.useState<PaymentMethod | undefined>(undefined);
  const [ isGettingPolicyPaymentMethod, setIsGettingPolicyPaymentMethod ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {
      if (
        (adjustmentType === 'SubscriptionPayment' || adjustmentType === 'SubscriptionRefund') &&
        policyId
      ) {

        setIsGettingPolicyPaymentMethod(true);

        try {
          const _policyPaymentMethod = await onGetPolicyPaymentMethod(policyId);
          setPolicyPaymentMethod(_policyPaymentMethod);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyPaymentMethod(false);
      }
    })();
  }, [ policyId, adjustmentType ])

  return {
    policyPaymentMethod,
    isGettingPolicyPaymentMethod
  };

}

export interface UseGetPolicyPaymentMethod {
  policyPaymentMethod?: PaymentMethod;
  isGettingPolicyPaymentMethod: boolean;
}
