import update from 'immutability-helper';
import initialState from './initial-state';

// IMPORTANT - IF THIS FILE IS CHANGED, CHECK If WE NEED TO UPDATE THE STATE VERSION:
// - applications/lib/modules/lib/state-version.js

// Here we can cherry pick what we want to store in local storage
// from the app module.
// This will be saved each time to store is updated
// and loaded when the app is first started.

export default function localStorageStatePartial(state) {
  const { core, auth } = state.app;
  return {
    ...initialState,
    ...{
      core: update(initialState.core, {
        stateVersion: { $set: core.stateVersion },
        showCookiePolicy: { $set: core.showCookiePolicy },
        showWelcomeScreen: { $set: core.showWelcomeScreen },
        guestQuotes: { $set: core.guestQuotes },
        tracking: {
          externalId: { $set: core.tracking.externalId }
        },
        config: {
          auth0: { $set: core.config.auth0 }
        },
        _flags: {
          guardRoute: { $set: core._flags.guardRoute }
        }
      }),
      auth: update(initialState.auth, {
        user: { $set: auth.user },
        midAuthFlow: { $set: auth.midAuthFlow },
        nonce: { $set: auth.nonce }
      })
    }
  }
}