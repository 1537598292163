import { BladeFormFieldset, BladeEmail, BladeInputText } from "@aventus/blade";
import { ICommonFieldProps, ICommonInputProps } from "@aventus/mvmt-simplequote/models/commonProps";
import { ITobesQuestion } from "@aventus/platform-client-context/models";
import React from 'react';

export function renderStrings(
    question: ITobesQuestion,
    commonFieldsetProps: ICommonFieldProps,
    commonInputProps: ICommonInputProps
  ) {
    switch (question.questionType) {
      case 'Email':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeEmail
              fullDomains={[] /*orgConfiguration.emailSuggestion?.customDomains*/}
              {...commonInputProps}
            />
          </BladeFormFieldset>
        );
  
      case 'phonenumber':
        return (
          <BladeFormFieldset {...commonFieldsetProps}>
            <BladeInputText type="tel" {...commonInputProps} />
          </BladeFormFieldset>
        );
    }
    // Just return a normal string field
    return (
      <BladeFormFieldset {...commonFieldsetProps}>
        <BladeInputText {...commonInputProps} />
      </BladeFormFieldset>
    );
  }