import * as React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import { getAtomValue } from '@aventus/honey-utility';
import cx from 'classnames';
import css from './index.css';

export const BladeSpace: React.FunctionComponent<IBladeSpace> = props => {
  const [spaceLimitedBreakPoint, setSpaceLimitedBreakPoint] = React.useState<
    string
  >();
  const [spaciousBreakPoint, setSpaciousBreakPoint] = React.useState<string>();

  React.useEffect(() => {
    const _spaceLimitedBreakPoint = getAtomValue('--app-view-s') || '769px';
    setSpaceLimitedBreakPoint(_spaceLimitedBreakPoint);

    const _spaciousBreakPoint = getAtomValue('--app-view-m') || '992px';
    setSpaciousBreakPoint(_spaciousBreakPoint);
  }, []);

  const { matchesQuery: spaceLimitedQuery } = useMediaQuery(
    `(max-width: ${spaceLimitedBreakPoint})`
  );
  const { matchesQuery: spaciousQuery } = useMediaQuery(
    `(min-width: ${spaceLimitedBreakPoint}) and (max-width: ${spaciousBreakPoint})`
  );

  return (
    <div
      className={cx(
        css.space,
        { ['isSpaceLimited']: spaceLimitedQuery },
        { ['isSpacious']: spaciousQuery }
      )}
    >
      {props.children}
    </div>
  );
};

export interface IBladeSpace {}
