import { ICompiler, COMPILE_TYPES } from '@aventus/honey-definitions';
import { honeyCompilerCSS } from '@aventus/honey-compiler-css';

export const honeyCompilerCSSBrowser: ICompiler = {
  ...honeyCompilerCSS,
  ...{

    name: '@aventus/honey-compiler-css-browser',

    compileType: COMPILE_TYPES.runtime,

    compileToInRuntime: (theCSS: string) => {
      const style = document.createElement('style');
      style.id = "HNY_ID";
      style.innerHTML = theCSS;
      document.getElementsByTagName('head')[0].append(style);
    }
  }
}
