import React, { useState } from 'react';
import {
  BladeView,
  BladeActionList,
  BladeTitleBar,
  BladeMessage
} from '@aventus/blade';
import css from './index.css';
import { getSummaryActions } from '../../services/get-summary-actions';
import { RouteComponentProps } from 'react-router';
import { BladeNotification } from '@aventus/blade';
import { YourPolicy } from '../../components/your-policy';
import { useMediaQuery } from '@aventus/pockethooks';
import { useDocumentTitle } from '@aventus/pockethooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useApplicationTimezone } from '@aventus/application-timezone';
import cx from 'classnames';
import { useGetPolicyBundle } from '@aventus/symphony-client/hooks/use-get-policy-bundle';
import { useGetPolicyAddons } from '@aventus/symphony-client/hooks/use-get-policy-addons';
import useAutoRenewalSettings from '@aventus/symphony-client/hooks/use-autorenewal-settings';
import { useGetCustomConfig } from '@aventus/symphony-client/hooks';
import { useIsHybridProduct } from '@aventus/mvmt-simplequote/hooks/use-is-hybrid-product';
import { useTrack } from '@aventus/application-tracking';

export const PolicySummary: React.FunctionComponent<IPolicySummary> = props => {
  useDocumentTitle('Your Policy');

  const [shouldRender, setRender] = useState<boolean>(false);

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const { policyBundle } = useGetPolicyBundle(
    props.match.params.policyId.trim(),
    shouldRender
  );

  const { isGettingPolicyAddons, policyAddons } = useGetPolicyAddons(
    props.match.params.policyId.trim()
  );

  const { productConfiguration } = useGetProductConfiguration(
    policyBundle?.policy?.productReferenceID,
    policyBundle?.policy?.coverType
  );

  const { toHumanReadable } = useApplicationTimezone();

  const { success } = props.location.state || {};

  const { customConfig } = useGetCustomConfig();

  const { isHybridProduct } = useIsHybridProduct(customConfig, policyBundle?.policy.productID.toString() || "");

  const {
    toggleOnChange,
    isSettingStatus,
    renewalStatus,
    renewalSettings
  } = useAutoRenewalSettings(props.match.params.policyId.trim(), policyBundle);

  const { track } = useTrack();

  React.useEffect(() => {
    if (policyBundle && productConfiguration){
      track({
        event: 'aventus.policy.view',
        policyId: policyBundle.policy.id,
        productReference: productConfiguration.productReference,
        productCoverReference: productConfiguration.productCoverReference
      });
    }
  }, [policyBundle, productConfiguration]);

  if (productConfiguration && policyBundle) {
    const supportsRenewals =
      renewalSettings && renewalSettings.supportsRenewals;

    const paymentPlanSettings =
      renewalSettings &&
      renewalSettings.paymentPlans.find(
        plan => policyBundle.policy.paymentPlanReferenceID === plan.referenceID
      );

    const allowAutoBind =
      supportsRenewals &&
      paymentPlanSettings &&
      paymentPlanSettings?.supportsAutoBind;

    return (
      <>
        {shouldRender && (
          <BladeNotification
            show={shouldRender}
            className={cx(css.summary_documents_notification, {
              [css.summary_documents_notification_mobile]: matchesQuery
            })}
            alerts={[
              {
                variant: 'error',
                message:
                  productConfiguration?.policy.documents.pendingErrorMessage,
                callBack: () => setRender(false)
              }
            ]}
          />
        )}
        <div className={css.scaffold}>
          {productConfiguration.policy.summary?.title && (
            <BladeTitleBar
              viewVariant={'s'}
              title={productConfiguration.policy.summary.title}
            />
          )}

          <BladeView variant={'s'}>
            <div className={css.summary}>
              {success && (
                <BladeMessage
                  variant={'success'}
                  icon={'faCheck'}
                  message={success.message}
                  detail={success.detail}
                />
              )}

              <YourPolicy
                policyBundle={policyBundle}
                paymentPlanLabels={
                  productConfiguration.quote.summary.summaryPaymentPlanLabels
                }
                isMotorPolicy={false}
                toHumanReadable={toHumanReadable}
                shouldRespectViewWidth={true}
                allowAutoBind={allowAutoBind}
                allowAutoBindValue={renewalStatus}
                autoRenewalConfiguration={
                  productConfiguration.policy.summary.autoRenewal
                }
                autoRenewalToggle={toggleOnChange}
                togglableLoading={isSettingStatus}
                coverStartLabel={productConfiguration.quote.summary.coverStartLabel}
                coverEndLabel={productConfiguration.quote.summary.coverEndLabel}
                showPurchasedDate={productConfiguration.policy.summary.showPurchasedDate}
              />

              {!isGettingPolicyAddons && (
                <BladeActionList
                  actions={getSummaryActions(
                    props,
                    policyBundle,
                    productConfiguration,
                    Boolean(policyBundle?.renewal?.activeRenewalQuoteID),
                    isHybridProduct || false,
                    policyAddons,
                    setRender
                  )}
                />
              )}
            </div>
          </BladeView>
        </div>
      </>
    );
  }

  return null;
};

export interface IPolicySummary
  extends RouteComponentProps<
    {
      policyId: string;
      event: string;
    },
    {},
    {
      success?: {
        message: string;
        detail?: string;
      };
      isPostPurchase?: boolean
    }
  > {}
