import { useGetOraclesConfiguration } from "@aventus/configuration-client-context";
import { useEffect, useState } from "react";

const useGoogleRecaptchaV3 = (action: string) => {
  const [token, setToken] = useState<string>('');

  const { oraclesConfiguration } = useGetOraclesConfiguration();

  const getToken = () => {
    setToken('');
    (window as any).grecaptcha.ready(() => {
      (window as any).grecaptcha
        .execute(oraclesConfiguration?.recaptcha?.siteKey, { action })
        .then((token: string) => {
          setToken(token);
        })
    })
  }

  useEffect(() => {
    if (oraclesConfiguration && !document.getElementById('recaptchav3script')) {
      // Add reCaptcha script
      const script = document.createElement("script");
      script.id = 'recaptchav3script';
      script.src = oraclesConfiguration.recaptcha.url;
      document.body.appendChild(script);
    }
  }, [oraclesConfiguration])

  return {
    getToken,
    token
  }
};

export default useGoogleRecaptchaV3;