import * as React from 'react';
import { BladeSurface } from '../surface';
import { BladeIcon } from '../icon';
import { BladeView, BladeViewVariant } from '../view';
import css from './index.css';
import cx from 'classnames';

export const BladeModal: React.FunctionComponent<IBladeModal> = ({
  allowClose = true,
  ...props
}) => {
  const modalClass = props.mobileFriendly === true ? css.mfModal :css.modal
  const scaffoldClass = props.mobileFriendly === true ? css.mfmodel_surface_scaffold : css.modal_surface_scaffold
  const surfaceOveride = props.mobileFriendly === true ? css.surfaceOverride : ''

  return (
    <div className={cx('blade-modal', modalClass)}>
      {props.useOnlyModalWrapper && props.children}

      {!props.useOnlyModalWrapper && (
        <BladeView
          variant={props.variant || 's'}
          className={scaffoldClass}
        >
          <BladeSurface
            title={props.title}
            isScrollable={true}
            className={surfaceOveride}
            borderlessButton={props.mobileFriendly}
            classNameContent={cx(
              css.modal_surface_content,
              props.withFrame ? css.modal_surface_content_withFrame : '',
              props.className
            )}
            actions={
              allowClose
                ? [
                    {
                      onAction: props.close,
                      icon: <BladeIcon name={'faTimes'} />
                    }
                  ]
                : []
            }
          >
            {props.children}
          </BladeSurface>
        </BladeView>
      )}

      <div onClick={props.close} className={css.modal_closeTrigger}></div>
    </div>
  );
};

export interface IBladeModal {
  title?: string;
  close: () => void;
  allowClose?: boolean;
  withFrame?: boolean;
  className?: string;
  variant?: BladeViewVariant;
  useOnlyModalWrapper?: boolean;
  mobileFriendly?: boolean
}
