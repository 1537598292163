import update from 'immutability-helper';

export default function clearAllFlagsReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: { 
          $set: {}
        }
      })
    }
  }
}
