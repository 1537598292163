import * as React from 'react';
import { useFormio, Validators, IFormio } from '@aventus/formio-depr';
import { PaymentRequest } from '../../interface/payment-request';

export const useSetupDirectDebit: UseSetupDirectDebit = (
  knownName,
  onReady
) => {

  const title = useFormio(null, [Validators.required]);
  const fullName = useFormio(knownName ? knownName : null, [Validators.required, Validators.isOnlyAlphaWithAccentsAndSpaces]);
  const sortCode = useFormio(null, [Validators.required]);
  const accountNumber = useFormio(null, [Validators.required, `isOnlyNumber:8`]);

  const [ showMoreDetails, setShowMoreDetails ] = React.useState<string>('');

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      !isReady &&
      title.meta.isValid &&
      fullName.meta.isValid &&
      sortCode.meta.isValid &&
      accountNumber.meta.isValid
    ) {
      setIsReady(true);
      onReady({
        title: title.value,
        fullName: fullName.value,
        sortCode: sortCode.value,
        accountNumber: accountNumber.value
      });
    } else {
      setIsReady(false);
    }
  }, [
    title.meta.isValid,
    fullName.meta.isValid,
    sortCode.meta.isValid,
    accountNumber.meta.isValid
  ])

  return {
    title,
    fullName,
    sortCode,
    accountNumber,
    showMoreDetails,
    setShowMoreDetails
  };

}

export type UseSetupDirectDebit = (
  knownName: string | undefined,
  onReady: (paymentRequest: PaymentRequest) => void
) => {
  title: IFormio,
  fullName: IFormio,
  sortCode: IFormio,
  accountNumber: IFormio,
  showMoreDetails: string,
  setShowMoreDetails: (moreDetailsKey: string) => void
}
