import * as React from 'react';
import { BladeFormFieldset, BladeLink, BladeMessage } from '@aventus/blade';
import { BladeComboBox } from '@aventus/blade/input/combobox';
import {
  AddressSuggestion,
  Format,
  FormatResponse,
  Search
} from '../../client/types';
import css from './index.css';
import { IAddressLookup } from './types';
import { useQuery } from 'react-query';
import { createAddressClient } from '../../client';
import { useDebounce } from 'use-debounce';
import { AxiosResponse } from 'axios';

const MIN_SEARCH_CHARACTERS = 4;

export const AventusAddressLookup: React.FunctionComponent<
  IAddressLookup
> = props => {
  const [query, setQuery] = React.useState<string>('');
  const [selectedItem, setSelectedItem] = React.useState<AddressSuggestion>();

  // debounce our query for the fast typers (and to throttle network requests)
  const [debouncedQuery] = useDebounce(query, 250);

  const hasMinCharacters = debouncedQuery.length >= MIN_SEARCH_CHARACTERS;

  const { search, format } = createAddressClient({ baseURL: props.oracleUrl });

  const searchResult = useQuery<
    Search,
    unknown,
    AxiosResponse<AddressSuggestion[]>
  >(
    ['address lookup', props.oracleUrl, debouncedQuery],
    async () => {
      if (search === undefined) {
        throw new Error('Universal address search client not available');
      }

      return search(debouncedQuery.trim(), props.countryFilter);
    },
    {
      enabled: search !== undefined && hasMinCharacters,
      structuralSharing: false,
      retry: 3,
      retryDelay: attempt =>
        // Retry immediately, then exponentially back off
        Math.min(attempt > 1 ? 2 ** attempt * 100 : 100, 3 * 1000),
      useErrorBoundary: false,

      // By keeping the previous data we prevent the search results from
      // disappearing after typing. Stops annoying flashes of content.
      keepPreviousData: true && hasMinCharacters
    }
  );

  const formatResult = useQuery<Format, unknown, AxiosResponse<FormatResponse>>(
    ['addressFormat', selectedItem],
    async () => {
      if (format === undefined || selectedItem === undefined) {
        throw new Error(
          'Universal address error - Unable to format query or query is invalid'
        );
      }

      return format(selectedItem.key);
    },
    {
      enabled:
        format !== undefined &&
        selectedItem !== undefined &&
        selectedItem.key !== '',
      onSuccess: response => {
        const { provinceReferenceID, province, addressIDs, ...address } =
          response.data;

        onChange({
          province: {
            referenceID: provinceReferenceID,
            text: province
          },
          addressIds: addressIDs,
          ...address
        });
      }
    }
  );

  const { onChange } = props;

  const helperText =
    props.helperText || "Can't find your address? Enter it manually";

  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
      errorMessage={props.errorMessage}
    >
      <div className={css.addressLookup}>
        <BladeComboBox
          name={props.name}
          value={query}
          items={(searchResult.isSuccess && searchResult.data?.data) || []}
          isLoading={searchResult.isFetching || formatResult.isLoading}
          disabled={formatResult.isLoading}
          onInputValueChange={({ inputValue }) =>
            inputValue !== undefined && setQuery(inputValue)
          }
          onSelectedItemChange={({ selectedItem }) => {
            if (selectedItem) setSelectedItem(selectedItem);
          }}
          itemToString={address => (address !== null ? address.address : '')}
          onFocus={event => props.onFocus?.(event)}
          autoFocus={props.autoFocus ?? false}
          validate={props.validate}
          onValidate={props.onValidate}
          error={props.error}
        />

        {props.enterManually && (
          <BladeLink isSmall={true} onClick={() => props.enterManually?.()}>
            {helperText}
          </BladeLink>
        )}

        {(props.error?.recoverableError || props.error?.unrecoverableError) && (
          <BladeMessage
            variant={'error'}
            message={
              props.error?.recoverableError?.message ||
              props.error?.unrecoverableError?.message
            }
          />
        )}
      </div>
    </BladeFormFieldset>
  );
};
