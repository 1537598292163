import * as React from 'react';
import { Trail } from 'react-spring/renderprops';
import { BladeCallToAction } from '../call-to-action/index';
import { BladeCallToActionVariant } from '../call-to-action';
import css from './index.css';
import cx from 'classnames';

export const BladeCallToActionSet: React.FunctionComponent<
  IBladeCallToActionSet
> = props => {
  return (
    <>
      <div
        className={cx('blade-call-to-action-set', css.callToActionSet, {
          [css.callToActionSet_isHorizontal]: props.layout === 'horizontal',
          [css.callToActionSet_isVertical]: props.layout !== 'horizontal',
          [css.callToActionSet_isCustom]: props.layout === 'custom'
        })}
      >
        {props.heading && (
          <h5 className={css.callToActionSet_title}>{props.heading}</h5>
        )}
        {props.label && <h5>{props.label}</h5>}
        <div
          className={cx(
            css.callToActionSet_wrap,
            props.layout === 'horizontal'
              ? css.callToActionSet_isHorizontal_wrap
              : css.callToActionSet_isVertical_wrap
          )}
        >
          <Trail
            native
            delay={300}
            items={props.callToActions}
            keys={item => {
              return item.title;
            }}
            config={{ tension: 2000, friction: 100 }}
            from={{ opacity: 0, transform: 'translate3d(0,-100px,0)' }}
            to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
          >
            {(cta, i) => transitionProps => {
              return (
                <BladeCallToAction
                  key={i}
                  animation={transitionProps}
                  ui={props.layout}
                  title={cta.title}
                  alternateBackground={props.alternateBackground}
                  subTitle={cta.subTitle}
                  size={props.size}
                  labelIcon={cta.labelIcon}
                  labelImage={cta.labelImage}
                  actionLabel={cta.actionLabel}
                  action={cta.action}
                  policyID={cta.key}
                  productReferenceID={cta.productReferenceID}
                  coverType={cta.coverType}
                  status={cta.status}
                />
              );
            }}
          </Trail>
        </div>
      </div>
    </>
  );
};

interface IBladeCallToActionSet {
  layout: BladeCallToActionVariant;
  alternateBackground?: boolean;
  size?: string;
  label?: string;
  callToActions?: any;
  heading?: string | undefined;
}
