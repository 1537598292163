import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { BladeViewIsLoading } from '@aventus/blade';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';

export const ProductBaseRedirect: React.FunctionComponent<IProductBaseRedirect> = props => {
  const { applicationConfiguration } = useGetApplicationConfiguration();

  if (applicationConfiguration) {
    if (props.auth) {
      return <Redirect to={props.dashboardPath} />;
    } else {
      window.location.href =
        (applicationConfiguration.customRedirects &&
          applicationConfiguration.redirects?.baseRedirect) ||
        props.dashboardPath;
      return <BladeViewIsLoading variant={'s'} />;
    }
  } else {
    return <BladeViewIsLoading variant={'s'} />;
  }
};

export interface IProductBaseRedirect extends RouteComponentProps {
  auth: boolean | {};
  dashboardPath: string;
}
