import { ITobesQuestion } from "@aventus/platform-client-context/models";
import { ICommonFieldProps } from "@aventus/mvmt-simplequote/models/commonProps";
import { BladeTextBlock } from "@aventus/blade";
import React from 'react';

export function renderNotSet(
    question: ITobesQuestion,
    commonFieldsetProps: ICommonFieldProps
  ) {
    switch (question.questionType) {
      case 'TextBlock':
        return <BladeTextBlock {...commonFieldsetProps} />;
    }
    // Just return a normal string field
    return null;
  }