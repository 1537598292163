import * as React from 'react';
import css from './index.css';
import {
  BladeView,
  BladeTitleBar,
  BladeSurface,
  BladeDocuments,
  BladeButton
} from '@aventus/blade';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { uppercaseFirstLetter } from '@aventus/pocketknife/uppercase-first-letter';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useDocuments } from '../../hooks/use-documents';
import { head } from 'lodash';

export const DocumentsSummary: React.FunctionComponent<IDocumentsSummary> = props => {
  if (!props.auth) {
    props.goToSignIn();
  }
  const { documents, product } = useDocuments(
    props.match.params.id,
    props.match.params.type
  );

  const { productConfiguration } = useGetProductConfiguration(
    product?.productReferenceID,
    product?.coverType
  );

  const isQuote = props.match.params.type === 'quote';

  const pageTitle = isQuote
    ? productConfiguration?.quote.documentsPage?.title
    : productConfiguration?.policy.documentsPage?.title;
  const pageDescription = isQuote
    ? productConfiguration?.quote.documentsPage?.description
    : productConfiguration?.policy.documentsPage?.description;

  const { state } = useLocation<{ origin: string }>();

  const orignalQuoteID = () => {
    const stringArr = state.origin.split('/');
    if (stringArr) {
      return stringArr[stringArr?.length - 1];
    }

    return null;
  };

  let parsedDocuments: { [key: string]: any[] } = {};

  documents.forEach(document => {
    const dateNameDay = head(document.dateCreated.split('T'));
    if (dateNameDay && parsedDocuments[dateNameDay]) {
      parsedDocuments[dateNameDay].push(document);
    } else if (dateNameDay) {
      parsedDocuments[dateNameDay] = [document];
    }
  });

  return (
    <div className={css.scaffolding}>
      <BladeTitleBar
        viewVariant={'s'}
        title={
          pageTitle ||
          `${uppercaseFirstLetter(props.match.params.type)} Documents`
        }
        description={
          pageDescription ||
          'Please check below to make sure your details are correct and you choosed right cover.'
        }
      />
      <BladeView variant={'s'}>
        <BladeSurface shouldRespectViewWidth className={css.documents}>
          {isQuote ? (
            <BladeDocuments
              title={`${uppercaseFirstLetter(
                props.match.params.type
              )} Documents`}
              documents={documents}
            />
          ) : (
            <BladeDocuments
              title={`${uppercaseFirstLetter(
                props.match.params.type
              )} Documents`}
              documentsGrouped={parsedDocuments}
            />
          )}
        </BladeSurface>

        {isQuote && (
          <BladeSurface shouldRespectViewWidth={true}>
            <div className={css.controls}>
              <BladeButton
                onClick={() =>
                  props.goToCheckout(orignalQuoteID() || props.match.params.id)
                }
                basis={'100%'}
                variant="primary"
              >
                Go to checkout
              </BladeButton>
            </div>
          </BladeSurface>
        )}
      </BladeView>
    </div>
  );
};

export interface IDocumentsSummary
  extends RouteComponentProps<{ id: string; type: string }> {
  goToCheckout: (quoteID: string) => void;
  goToSignIn: () => void;
  auth: boolean;
}
