import * as React from 'react';
import { ChangePermission } from '@aventus/platform';
import { updateForm } from '@aventus/formio';
import { v4 as uuidV4 } from 'uuid';
import { IBladeItemArrayItem } from '.';

export function useItemArrayFormCollection(
  collection: Collection = [],
  originalValue: IBladeItemArrayItem[] | undefined,
  defaultFormState: {
    [key: number]: Nullable<Object>;
  }
): IUseItemArrayFormCollection {
  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(false);

  const [formState, setFormState] = React.useState<any>(defaultFormState);

  function updateFormState(formStateAddress: string, value: any) {
    const updatedFormState = updateForm(formState, formStateAddress, {
      $set: value
    });
    setFormState(updatedFormState);
  }

  function addToCollection(item: any) {
    return [...(collection || []), { ...item, answerId: uuidV4() }];
  }

  function deleteFromCollection(index: number) {
    const _collection = [...collection];

    _collection.splice(index, 1);

    return _collection;
  }

  function updateInCollection(item: any, index: number) {
    const _collection = [...collection];
    _collection[index] = item;
    return _collection;
  }

  const resolvePermission = (
    permission: ChangePermission | undefined,
    answerId: string
  ) => {
    if (permission === 'Disabled') {
      return false;
    }

    const isNewItem = originalValue !== undefined && originalValue?.find(item => item.answerId === answerId) === undefined;

    return permission === undefined || permission === true || isNewItem;
  };

  return {
    isFormOpen,
    setIsFormOpen,
    formState,
    setFormState,
    updateFormState,
    addToCollection,
    deleteFromCollection,
    updateInCollection,
    resolvePermission
  };
}

export type Collection = Array<any>;

export interface IUseItemArrayFormCollection {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean, index?: number) => void;
  formState: any;
  setFormState: (value: any) => void;
  updateFormState: (formStateAddress: string, value: any) => void;
  addToCollection: (item: any) => Collection;
  deleteFromCollection: (index: number) => Collection;
  updateInCollection: (item: any, index: number) => Collection;
  resolvePermission: (
    permission: ChangePermission | undefined,
    answerId: string
  ) => boolean;
}
