import { ALERTS_QUEUE_ALERT } from './types';

export default function queueAlertAction(alert) {
  const {
    id,
    message,
    location,
    type,
    dismissible = true
  } = alert;

  return {
    type: ALERTS_QUEUE_ALERT,
    alert: {
      id: id || Date.now(),
      message,
      location,
      type,
      dismissible
    }
  }
}
