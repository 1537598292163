import * as React from 'react';
import { BladeSurface } from '../../surface';
import { useRunner } from '../../runner';
import { IBladeAsk } from '../index';
import css from './index.css';
import { useForm } from '../../form';
import cx from 'classnames';

export function BladeAskCustomise(props: IBladeAsk) {
  const { questions, submit } = useForm(
    props.getQuestionsToRender,
    props.getSubmitToRender
  );

  const { trim } = useRunner();

  return (
    <div className={css.customise}>
      {questions.map((question: any, i: number) => {
        if (!question) {
          return null;
        }

        return (
          <BladeSurface
            key={i}
            shouldRespectViewWidth={props.shouldRespectViewWidth}
            className={cx(css.allTogether_question, {
              [css.allTogether_question_trimIsDivider]: trim === 'divider'
            })}
          >
            {question}
          </BladeSurface>
        );
      })}

      {submit}
    </div>
  );
}
