import { ITobesQuestion } from '@aventus/platform-client-context/models';
import { TobesQuestionState } from '../../store/form-state';

export const createFormStateMap = (
  questions: ITobesQuestion[]
): TobesQuestionState[] => {
  const mapper = (questions: ITobesQuestion[]): TobesQuestionState[] =>
    questions.map(question => ({
      referenceID: question.referenceID,
      value: question.existingValue,
      validationErrors: [],
      children: mapper(question.fields)
    }));

  return mapper(questions);
};
