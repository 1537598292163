import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Scheme } from '@aventus/platform';

export const useGetPolicyScheme = (policyId: string) => {
  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();

  const [policyScheme, setPolicyScheme] = React.useState<Scheme | undefined>(
    undefined
  );
  const [isGettingPolicyScheme, setIsGettingPolicyScheme] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setIsGettingPolicyScheme(true);

        try {
          const _getscheme = await symphonyClient.getPolicyScheme(policyId);
          setPolicyScheme(_getscheme);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyScheme(false);
      }
    })();
  }, [policyId]);

  return {
    policyScheme,
    isGettingPolicyScheme
  };
};
