import * as React from 'react';

import { BladeMarkdown, BladeModal } from '@aventus/blade';

interface PackageInfoModalProps {
  title: string | null;
  summaryText: string | null;
  detailText: string | null;
  onClose: () => void;
}

const PackageMoreInfoModal = (props: PackageInfoModalProps) => {
  return (
    <BladeModal
      withFrame={true}
      close={props.onClose}
      title={props.title || undefined}
    >
      <BladeMarkdown
        markdown={`${props.summaryText}\r\n\r\n${props.detailText}`}
      />
    </BladeModal>
  );
};

export default PackageMoreInfoModal;
