import { accountNumberMustBe8Numbers } from '../validators/account-number-must-be-8-numbers';
import { dateMustBeBetweenNowAnd30Days } from '../validators/date-must-be-between-now-and-30-days';
import { isFlat } from '../validators/is-flat';
import { matchesPassword } from '../validators/matches-password';
import { max } from '../validators/max-date';
import { maxLength } from '../validators/max-length';
import { maxValue } from '../validators/max-value';
import { min } from '../validators/min-date';
import { minLength } from '../validators/min-length';
import { minValue } from '../validators/min-value';
import { mustBeAMonetaryValue } from '../validators/must-be-a-monetary-value';
import { mustBeEmptyOrMoreThan1 } from '../validators/must-be-empty-or-more-than-1';
import { mustBeOver18 } from '../validators/must-be-over-18';
import { mustBeTrue } from '../validators/must-be-true';
import { mustBeValidEmail } from '../validators/must-be-valid-email';
import { mustBeValidPhoneNumber } from '../validators/must-be-valid-phone-number';
import { mustBeValidPhoneNumberAU } from '../validators/must-be-valid-phone-number-au';
import noFutureDates from '../validators/no-future-dates';
import { required } from '../validators/required';
import { sortCodeEntry } from '../validators/sort-code-entry';
import verifyAge from '../validators/verify-age';
import verifyAgeNumber from '../validators/verify-age-number';

export const validatorFunctions: ValidatorFunctions = {
  accountNumberMustBe8Numbers,
  dateMustBeBetweenNowAnd30Days,
  mustBeEmptyOrMoreThan1,
  isFlat,
  matchesPassword,
  max,
  maxLength,
  maxValue,
  min,
  minLength,
  minValue,
  mustBeAMonetaryValue,
  mustBeOver18,
  mustBeTrue,
  mustBeValidEmail,
  mustBeValidPhoneNumber,
  mustBeValidPhoneNumberAU,
  required,
  sortCodeEntry,
  verifyAge,
  verifyAgeNumber,
  noFutureDates,
  maxDateRange: () => () => undefined,
  minDateRange: () => () => undefined,
  maxEndDateRule: () => () => undefined
};

interface ValidatorFunctions {
  [validatorFunctionName: string]: any;
}
