import update from 'immutability-helper';

export default function configureAppReducer(state, action) {
  const { config } = action;

  return {
    ...state,
    ...{
      core: update(state.core, {
        config: {
          env: { $set: config }
        }
      })
    }
  };
}
