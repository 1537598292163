import { ValidatorResponse } from './types';

export function isOnlyNumber(value:any, dynamicValue ?:number) :ValidatorResponse {

  const regex = (
    dynamicValue != null
      ? `^[0-9]{${ dynamicValue }}$`
      : `^[0-9]$`
  );

  if (value != null && value.match(regex) != null) {
    return true;
  }

  return (
    dynamicValue != null
      ? `You can only use numbers, and it must have ${ dynamicValue } digits.`
      : 'You can only use numbers.'
  );
}
