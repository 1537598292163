import { MtaInformation, Money } from '@aventus/platform';

export function getDisplayPriceForAdjust (adjustPlan: MtaInformation): Money {

  if (adjustPlan.totalPriceChange.value === 0) {
    return adjustPlan.totalPriceChange;
  } else {
    if (adjustPlan.recurringPaymentChange) {
      return adjustPlan.recurringPaymentChange.changeInMonthlyPrice;
    } else {
      return adjustPlan.totalPriceChange
    }
  }

}
