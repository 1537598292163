import React from 'react';
import appApi from '../../../api';
import routes from '../../../routes';
import _render from './render';
import { TRACKING_EVENTS } from '../tracking';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.render = _render.bind(this);
  }

  componentDidMount() {
    this.props.handleTracking(TRACKING_EVENTS.newScreen, 'Screen_name', {
      Screen_name: routes.privacyPolicy.trackingName
    });
  }
}

export default appApi(PrivacyPolicy);
