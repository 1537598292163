import * as React from 'react';
import css from './index.css';
import cx from 'classnames';
import { BladeAppBarPhone } from '../default/phone';
import { BladeView } from '../../view';
import { IBladeAppBar } from '../';
import { BladeButton } from '../../button';
import { BladeIcon } from '../../icon';
import { useMediaQuery } from '@aventus/pockethooks';

export const CustomLpoc: React.FC<IBladeAppBar> = props => {
  const { matchesQuery: isCondensed } = useMediaQuery(
    'only screen and (max-width: 1199px)'
  );
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const homeNav = (
    <a
      className={css.appBarContent_link}
      href="https://www.lpoc.co.uk/insurance/insurance2/"
    >
      HOME
    </a>
  );

  const faqNav = (
    <a
      className={css.appBarContent_link}
      href="https://www.lpoc.co.uk/insurance/insurance-faqs/"
    >
      FAQS
    </a>
  );

  const signInOrOut = (
    <>
      {props.isAuthorised && (
        <BladeButton
          variant={'primary'}
          isInline={true}
          className={css.appBarContent_right_row_button}
          onClick={() => props.signOut?.()}
        >
          LOGOUT
        </BladeButton>
      )}

      {!props.isAuthorised && (
        <BladeButton
          variant={'primary'}
          isInline={false}
          className={css.appBarContent_right_row_button}
          onClick={() => props.signIn?.()}
        >
          CLIENT LOGIN
        </BladeButton>
      )}
    </>
  );

  return (
    <div className={cx('blade-app-bar', css.appBar, props.className)}>
      <BladeView variant={props.viewVariant || 's'} className={css.appBar_view}>
        <div className={css.appBarContent}>
          {props.renderLeft}

          <div className={css.appBarContent_right}>
            {!isCondensed && (
              <div className={css.appBarContent_right_row}>
                <span>OPENING HOURS: 9AM - 5PM, MON - FRI</span>
                <div className={css.appBarContent_right_row_phone}>
                  <span>CALL US</span>
                  {props.phone && (
                    <BladeAppBarPhone
                      hideIcon
                      phone={props.phone}
                      className={css.appBarContent_right_row_phone_custom}
                    />
                  )}
                </div>
              </div>
            )}

            <div
              className={cx(
                css.appBarContent_right_row,
                isCondensed ? css.appBarContent_right_row__condensed : ''
              )}
            >
              <div className={css.appBarContent_right_row_phone}>
                {isCondensed && (
                  <>
                    {props.phone && (
                      <BladeAppBarPhone
                        hideIcon={false}
                        phone={props.phone}
                      />
                    )}
                    <BladeButton
                      variant={'primary'}
                      isInline={true}
                      className={css.appBarContent_right_row_burger}
                      onClick={() => setOpenDrawer(!openDrawer)}
                    >
                      <BladeIcon name={'faBars'} />
                    </BladeButton>
                  </>
                )}
              </div>

              {!isCondensed && signInOrOut}
            </div>
          </div>
        </div>
      </BladeView>

      <div
        className={cx(
          css.appBarContent_drawer,
          openDrawer && isCondensed ? css.appBarContent_drawer__isOpen : ''
        )}
      >
        <div className={css.appBarContent_drawer_row}>{homeNav}</div>
        <div className={css.appBarContent_drawer_row}>{faqNav}</div>
        <div className={css.appBarContent_drawer_row}>{signInOrOut}</div>
      </div>
    </div>
  );
};
