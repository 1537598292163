import * as React from 'react';
import css from './index.css';
import { BladeIcon } from '@aventus/blade';

export const BladeDocumentsGroup: React.FunctionComponent<IBladeDocumentsGroup> = props => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <div className={css.listDocumentsElem} onClick={() => setIsOpen(!isOpen)}>
        <span className={css.listDocumentsElemTitleIcon}>
          <BladeIcon name={!isOpen ? 'faCaretRight' : 'faCaretDown'} />
          <span className={css.listDocumentsGroupTitle}>
            {props.groupTitle}
          </span>
        </span>
      </div>
      {isOpen && props.children}
    </>
  );
};

export interface IBladeDocumentsGroup {
  groupTitle: string;
  children: React.ReactNode;
}
