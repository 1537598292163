import { IMoleculesReduce } from '@aventus/honey-definitions';
import { compileCSSClass } from './compile-css-class';
import { cssDeclarationsReduce } from './css-declarations-reduce';

export const moleculesReduce: IMoleculesReduce = (accumulator, molecule) => {

  const reducedAtoms: string = molecule.atoms.reduce(cssDeclarationsReduce, '');
  const compiledMolecule: string = compileCSSClass(molecule.key, reducedAtoms);

  return `${ accumulator }\n${ compiledMolecule }`;
}
