import update from 'immutability-helper';

export default function saveQuoteIdReducer(state, action) {
  const { quoteId } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        guestQuotes: { $push: [quoteId] }
      })
    }
  };
}
