import { Policy, PolicyStatus } from '@aventus/platform';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { ToHumanReadable } from '@aventus/application-timezone';
import { BladeBadge } from '@aventus/blade';
import React from 'react';
import css from '../components/policy-list/index.css';

export interface PolicyActions {
  active: any[];
  inactive: any[];
}

export const getPolicyListActions = (
  policies: Policy[],
  goToPolicy: (policyId: string) => void,
  toHumanReadable: ToHumanReadable,
  image: string,
  icon: string,
  label: string
): PolicyActions => {
  const getStatusBadgeCssClass = (status: PolicyStatus) => {
    switch (status) {
      case 'Active':
        return css.badgeActive;
      case 'Lapsed':
        return css.badgeLapsed;
      case 'Cancelled':
        return css.badgeCancelled;
      default:
        return '';
    }
  };

  const mapPolicy = (policy: Policy, i: number) => ({
    key: policy.id,
    id: `my-policy-list-item-${i}`,
    title: policy.label,
    subTitle: !policy.subTitleParsed
      ? stringReplacer(label, {
          $$MATCHDATA_COVERENDDATE: toHumanReadable(policy.coverEndDate)
        })
      : policy.subTitle,
    labelIcon: icon,
    labelImage: image,
    productReferenceID: policy.productReferenceID,
    coverType: policy.coverType,
    action: () => {
      goToPolicy(policy.id);
    },
    status: {
      value: policy.policyStatus,
      display: (
        <BladeBadge
          label={policy.policyStatus}
          className={getStatusBadgeCssClass(policy.policyStatus)}
        />
      )
    }
  });

  return {
    active: policies.filter(x => x.policyStatus === 'Active').map(mapPolicy),
    inactive: policies.filter(x => x.policyStatus !== 'Active').map(mapPolicy)
  };
};
