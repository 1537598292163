import * as React from 'react';
import { SymphonyClient } from '@aventus/symphony-client';

export const SymphonyClientContext = React.createContext<any>(undefined);

export function useSymphonyClient(
  ignoreUsageWithoutContext?: boolean
): SymphonyClient {
  const client = React.useContext(SymphonyClientContext);
  if (client === undefined && !ignoreUsageWithoutContext) {
    throw new Error(
      'useSymphonyClient must be used within a SymphonyClientContext.Provider'
    );
  }
  return client;
}
