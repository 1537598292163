import { QuoteType, PaymentPlanType, AdjustmentType } from '@aventus/platform';
import { CheckoutSuccessConfiguration } from '@aventus/configuration';
import { ApplicationError } from '@aventus/errors';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';

export function getSuccessMessage (
  successConfiguration: CheckoutSuccessConfiguration,
  quoteType: QuoteType,
  paymentPlanType: PaymentPlanType | undefined,
  adjustmentType: AdjustmentType | undefined,
  replacements: {
    support: string,
    trustpilot?: string,
    policyReferenceID?: string
  },
  accountCreationRequired?: boolean,
) {

  let message: string | undefined = undefined;
  let detail: string | undefined = undefined;
  console.log("nme" + quoteType)
  switch (quoteType) {


    case 'NewBusiness':
      message = successConfiguration.newPolicyTitle ? successConfiguration.newPolicyTitle :"Welcome";

      if (accountCreationRequired === true){
        detail = successConfiguration.newPolicyDetailAccountCreation;
      }
      else{
        switch (paymentPlanType) {
          case 'FixedTermSinglePayment':
          case 'SinglePayment':
            detail = successConfiguration.newPolicySinglePaymentDetail;
            break;
          case 'AnnualMonthlySubscription':
          case 'MonthlySubscription':
            detail = successConfiguration.newPolicySubscriptionDetail;
            break;
          case 'AnnualMonthlyCredit':
            detail = successConfiguration.newPolicyFinanceDetail;
            break;
          default:
            break;
        }
      }
      break;

      case 'Renewal':
        message = successConfiguration.renewPolicyTitle ? successConfiguration.renewPolicyTitle :"Welcome back";
        switch (paymentPlanType) {
          case 'FixedTermSinglePayment':
          case 'SinglePayment':
            detail = successConfiguration.renewPolicySinglePaymentDetail;
            break;
          case 'AnnualMonthlySubscription':
          case 'MonthlySubscription':
            detail = successConfiguration.renewPolicySubscriptionDetail;
            break;
          case 'AnnualMonthlyCredit':
            detail = successConfiguration.renewPolicyFinanceDetail;
            break;
          default:
            break;
        }
      break;

    case 'MTA':
      message = successConfiguration.adjustedPolicyTitle ? successConfiguration.adjustedPolicyTitle :"We have ammended your policy";
      switch (adjustmentType) {
        case 'FinanceNoFee':
        case 'FixedSingleNoFee':
        case 'SubscriptionNoFee':
          detail = successConfiguration.adjustedPolicyNoFeeDetail;
          break;
        case 'FixedSingleRefund':
        case 'FinanceRefund':
        case 'SubscriptionRefund':
          detail = successConfiguration.adjustedPolicyRefundDetail;
          break;
        case 'FixedSinglePayment':
        case 'SubscriptionPayment':
        case 'FinancePayment':
          detail = successConfiguration.adjustedPolicyPaymentDetail;
          break;
        case 'FinanceUpdate':
          detail = successConfiguration.adjustedPolicyFinanceUpdateDetail;
          break;
        default:
          break;
      }
      break;

    default:
      throw new ApplicationError(
        `Unable to find quote type with case ${quoteType}`
      );

  }

  if (message) {
    return {
      message,
      detail: (
        detail
          ? stringReplacer(detail, {
              '$$_support': replacements.support,
              ...(replacements.policyReferenceID) ?  {'$$_policyReferenceID': replacements.policyReferenceID} : {},
              ...(replacements.trustpilot)
                ? { '$$_trustpilot': replacements.trustpilot }
                : {}
            })
          : undefined
      )
    };
  } else {
    throw new ApplicationError(
      '`getSuccessMessage` did not find any matches for the success message.'
    );
  }


}
