// We can catch specific auth0 error codes here
// and return the error_description
// In some cases we may want to override the auth0
// error description and return our own
// If there is no error description returned, we will
// fall back to the failure error in the action

export default function deserializeError(error) {
  if (error && error.response && error.response.status) {
    const { error_description } = error.response.data;
    switch (error.response.status) {
      case 403:
      case 429:
      default:
        return error_description;
    }
  } else {
    return null;
  }
}