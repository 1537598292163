import update from 'immutability-helper';

export function startBasicAuthRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isStartingBasicAuth: { $set: true },
          successfullyStartedBasicAuth: { $set: false }
        }
      })
    }
  };
}

export function startBasicAuthSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isStartingBasicAuth: { $set: false },
          successfullyStartedBasicAuth: { $set: true }
        }
      })
    }
  };
}

export function startBasicAuthFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isStartingBasicAuth: { $set: false },
            successfullyStartedBasicAuth: { $set: false }
          }
        }
      })
    }
  };
}
