import update from 'immutability-helper';

export function signUpRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isSigningUp: { $set: true }
        }
      })
    }
  };
}

export function signUpSuccessReducer(state, action) {

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isSigningUp: { $set: false },
          successfullySignedUp: { $set: true }
        }
      })
    }
  };
}

export function signUpFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isSigningUp: false,
            successfullySignedUp: false
          }
        }
      })
    }
  }
}
