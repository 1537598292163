import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { PolicyCancellationInfo } from '@aventus/platform';

export const useGetPolicyCancel: UseGetPolicyCancel = function(policyId) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [ policyCancellationInfo, setPolicyCancellationInfo ] = React.useState<
    PolicyCancellationInfo | undefined
  >(undefined);

  const [isGettingPolicyCancel, setIsGettingPolicyCancel] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setIsGettingPolicyCancel(true);

        try {
          const _policyCancel = await symphony.getPolicyCancellationInfo(policyId);
          setPolicyCancellationInfo(_policyCancel);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyCancel(false);
      }
    })();
  }, [policyId]);

  return {
    policyCancellationInfo,
    isGettingPolicyCancel
  };
};

export type UseGetPolicyCancel = (
  policyId?: string
) => {
  policyCancellationInfo: PolicyCancellationInfo | undefined;
  isGettingPolicyCancel: boolean;
};
