import * as React from 'react';
import {
  BladeView,
  BladeSurface,
  BladeButton,
  BladeMarkdown
} from '@aventus/blade';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { useTrackView } from '@aventus/application-tracking';
import css from './index.css';

export const IntelligentQuoteExpiredOrConverted: React.FunctionComponent<
  IIntelligentQuoteExpiredOrConverted
> = props => {
  useTrackView('IntelligentQuoteExpiredOrConverted');

  return (
    <BladeView variant={'s'} className={css.expiredOrConverted}>
      <BladeSurface title={props.title} shouldRespectViewWidth={true}>
        <div className={css.expiredOrConverted_reason}>
          <BladeMarkdown markdown={props.reason} />
        </div>

        {props.help && (
          <BladeSurface isAside={true}>
            <div className={css.expiredOrConverted_help}>
              <BladeMarkdown
                markdown={stringReplacer(props.help, {
                  $$MATCHURL: props.supportLink || ''
                })}
              />
            </div>
          </BladeSurface>
        )}

        {props.onAcknowledgement && (
          <div className={css.expiredOrConverted_trigger}>
            <BladeButton onClick={() => props.onAcknowledgement!()}>
              {props.triggerLabel || 'Ok'}
            </BladeButton>
          </div>
        )}
      </BladeSurface>
    </BladeView>
  );
};

export interface IIntelligentQuoteExpiredOrConverted {
  title: string;
  reason: string;
  help?: string;
  supportLink?: string;
  triggerLabel?: string;
  onAcknowledgement?: (event?: React.ChangeEvent<HTMLButtonElement>) => void;
}
