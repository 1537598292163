import * as React from 'react';
import {
  useIntelligentQuoting,
  UseIntelligentQuoting
} from '@aventus/mvmt-quote';
import {
  useGetEditQuote,
  useNextIntelligentQuote,
  useNextQuoteIntelligentQuote
} from '@aventus/symphony-client/hooks';
import { ServerError } from '@aventus/errors';

export function useAskForRenew(
  quoteId: string,
): UseAskForRenew {
  const intelligentQuoting = useIntelligentQuoting();

  const {
    iqRequest,
    isGettingIqRequest
  } = useGetEditQuote(quoteId);

  React.useEffect(() => {
    if (iqRequest) {
      intelligentQuoting.handleStart(iqRequest, "renew");
    }
  }, [iqRequest]);

  const {
    intelligentQuoteResponse,
    isGettingIntelligentQuoteResponse
  } = useNextIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash,
    intelligentQuoting.customizationNext
  );

  React.useEffect(() => {
    if (intelligentQuoteResponse) {
      intelligentQuoting.handleNext(intelligentQuoteResponse);
    }
  }, [intelligentQuoteResponse]);

  const {
    intelligentQuoteBundle,
    isGettingQuoteBundle,
    rateError
  } = useNextQuoteIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.confirm,
    'renew',
    intelligentQuoting.quoteState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash,
    intelligentQuoting.previousPage
  );

  React.useEffect(() => {
    if (intelligentQuoteBundle) {
      intelligentQuoting.handleNextQuote(intelligentQuoteBundle);
    }
  }, [intelligentQuoteBundle]);

  return {
    ...intelligentQuoting,
    isWorking:
    isGettingIqRequest ||
      isGettingIntelligentQuoteResponse ||
      isGettingQuoteBundle,
    rateError
  };
}

export interface UseAskForRenew extends UseIntelligentQuoting {
  isWorking: boolean;
  rateError: ServerError;
}
