import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useDocumentTitle } from '@aventus/pockethooks';
import { getPolicyCancellationInformation } from '../../services/get-policy-cancellation-information';
import { PolicyCancelAgentRequired } from './../policy-cancel-agent-required';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { CancellationReason } from '@aventus/platform';
import { PolicyCancelSuccess } from '../policy-cancel-success';
import css from './index.css';
import {
  usePolicyCancel,
  useGetPolicy,
  useGetPolicyCancel
} from '@aventus/symphony-client/hooks';
import {
  BladeView,
  BladeViewIsLoading,
  BladeMessage,
  BladeButton,
  BladeModal,
  BladeInputSelect,
  BladeSurface
} from '@aventus/blade';
import { Scaffold } from '@honey/consume-react-jss';

export const PolicyCancel: React.FunctionComponent<IPolicyCancel> = ({
  match: {
    params: { policyId }
  },
  goHome
}) => {
  useDocumentTitle('Cancel policy');

  // We have two stores here for the same value, one private one not.
  // The private is what is directly bound to the select input, so any user
  // changes are reflected in the private property. Once the user is happy
  // to go ahead with a reason, and cancel the policy, that's when we update the
  // private value into the public one. This will then fire off the useEffect
  // that is listening to the public property, and begin cancelling.

  const [selectedCancellationReason, setSelectedCancellationReason] =
    React.useState<CancellationReason | undefined>(undefined);
  const [
    selectedCancellationReasonPrivate,
    setSelectedCancellationReasonPrivate
  ] = React.useState<CancellationReason | undefined>(undefined);

  const { policy, isGettingPolicy } = useGetPolicy(policyId);
  const { isGettingPolicyCancel, policyCancellationInfo } = useGetPolicyCancel(
    policy?.id
  );

  const { isPolicyCanceling, policy: cancelledPolicy } = usePolicyCancel(
    policy?.id,
    selectedCancellationReason
  );

  const { productConfiguration } = useGetProductConfiguration(
    policy?.productReferenceID,
    policy?.coverType
  );

  const { toHumanReadable } = useApplicationTimezone();

  // Modal state

  const [modal, setModal] = useState(false);

  if (productConfiguration) {
    return (
      <>
        {(isGettingPolicy || isGettingPolicyCancel) && (
          <BladeViewIsLoading variant={'s'} />
        )}

        {cancelledPolicy && (
          <PolicyCancelSuccess
            policyId={policyId}
            cancelledPolicy={policyCancellationInfo}
            goHome={goHome}
          />
        )}

        {!cancelledPolicy &&
          policyCancellationInfo?.cancellationMethod === 'AgentRequired' &&
          policy?.productReferenceID &&
          policy?.coverType && (
            <PolicyCancelAgentRequired
              productReference={policy?.productReferenceID}
              productCoverReference={policy?.coverType}
            />
          )}

        {!cancelledPolicy &&
          policyCancellationInfo?.cancellationMethod === 'SelfService' && (
            <BladeView variant={'s'}>
              {modal && (
                <BladeModal
                  close={() => setModal(false)}
                  withFrame={true}
                  title={
                    productConfiguration.policy?.cancel?.selfService?.areYouSure
                      .title || 'Cancel Policy'
                  }
                >
                  <div className={css.scaffoldingModal}>
                    <p>
                      {productConfiguration.policy?.cancel?.selfService
                        ?.areYouSure.confirmationMessage ||
                        'Are you sure you want to cancel this policy?'}
                    </p>

                    <div className={css.buttonWrapper}>
                      <BladeButton
                        style={{ flexBasis: '100px', flexGrow: 1 }}
                        onClick={() => setModal(false)}
                      >
                        {productConfiguration.policy?.cancel?.selfService
                          ?.areYouSure.notSureCallToAction || 'No'}
                      </BladeButton>

                      <BladeButton
                        isDestructive={true}
                        style={{ flexBasis: '100px', flexGrow: 1 }}
                        isWorking={isPolicyCanceling}
                        onClick={() => {
                          setSelectedCancellationReason(
                            selectedCancellationReasonPrivate
                          );
                        }}
                      >
                        {productConfiguration.policy?.cancel?.selfService
                          ?.areYouSure.sureCallToAction || 'Yes'}
                      </BladeButton>
                    </div>
                  </div>
                </BladeModal>
              )}

              <div className={css.scaffolding}>
                {productConfiguration.policy.cancel.selfService?.descriptions
                  ?.warning && (
                  <BladeMessage
                    variant={'warning'}
                    message={
                      productConfiguration.policy.cancel.selfService
                        ?.descriptions?.warning
                    }
                  />
                )}

                <BladeMessage
                  variant={'info'}
                  message={`${
                    productConfiguration.policy.cancel.selfService?.descriptions
                      ?.info
                  } ${getPolicyCancellationInformation(
                    policyCancellationInfo,
                    productConfiguration.policy.cancel.selfService?.descriptions
                      ?.cancellationTypes,
                    toHumanReadable
                  )}`}
                />

                <BladeSurface>
                  <Scaffold orient={'col'} size="default">
                    <BladeInputSelect
                      name="reason"
                      label={
                        productConfiguration.policy.cancel.selfService
                          ?.cancellationReasonsPlaceholder
                      }
                      value={selectedCancellationReasonPrivate}
                      onChange={(
                        _selectedCancellationReason: CancellationReason
                      ) => {
                        setSelectedCancellationReasonPrivate(
                          _selectedCancellationReason
                        );
                      }}
                      options={
                        policyCancellationInfo?.cancellationReasons ?? []
                      }
                      matchOn="referenceID"
                    />
                  </Scaffold>
                </BladeSurface>

                <BladeButton
                  onClick={() => setModal(true)}
                  isDisabled={!selectedCancellationReasonPrivate}
                >
                  {productConfiguration.policy.cancel.selfService
                    ?.cancelCallToAction || 'Cancel'}
                </BladeButton>
              </div>
            </BladeView>
          )}
      </>
    );
  }

  return null;
};

export interface IPolicyCancel
  extends RouteComponentProps<{
    policyId: string;
  }> {
  goHome: () => void;
}
