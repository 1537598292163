import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';
import { AppConfiguration } from '@aventus/configuration';

export function useGetAppConfiguration(): UseGetAppConfiguration {
  const client = useConfigurationClient();
  const [appConfiguration, setAppConfiguration] = React.useState<
    AppConfiguration | undefined
  >(undefined);

  useAsyncEffect(async () => {
    const _applicationConfiguration = await client.getAppConfiguration();

    setAppConfiguration(_applicationConfiguration);
  }, []);

  return {
    appConfiguration
  };
}

interface UseGetAppConfiguration {
  appConfiguration: AppConfiguration | undefined;
}
