import React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BladeToast: React.FunctionComponent<IBladeToast> = props => (
  <div
    className={cx(
      'blade-toast',
      css.toast,
      props.className || '',
      props.show ? css.toast_isVisible : css.toast_isHidden,
      props.showOverflow ? css.toast_showOverflow : css.toast_hideOverflow
    )}
  >
    {props.children}
  </div>
);

interface IBladeToast {
  /** When set to `true`, the content will be displayed. */
  show: boolean;
  /** This trims or allows the overflow of the content. */
  showOverflow?: boolean;
  /** Specify a custom z-index. */
  zIndex?: number;
  /** Extend the CSS with custom classes. */
  className?: string;
}
