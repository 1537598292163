import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';

export const BladeInputElement: React.FunctionComponent<IBladeInputElement> = props => {
  const inputProps = useInput(props);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <div
        className={ inputProps.className  }>
      </div>

    </BladeInput_Frame>
  );
};

export interface IBladeInputElement extends IBladeInput {}
