import * as React from 'react';
import { BladeSurface, BladeList } from '@aventus/blade';
import {
  ProductCoverConfiguration,
  ProductCoverProductItemConfiguration
} from '@aventus/configuration';
import { QuoteProduct, ProductItem } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';

export const DetailsOfCover: React.FunctionComponent<IDetailsOfCover> = props => {
  return (
    <React.Fragment>
      {props.coverConfiguration.map(
        (cover: ProductCoverConfiguration, i: number) => (
          <BladeSurface
            key={i}
            title={cover.title}
            description={cover.description}
            shouldRespectViewWidth={props.shouldRespectViewWidth}
          >
            <BladeList
              isFlush={true}
              shouldRespectViewWidth={props.shouldRespectViewWidth}
              list={cover.productItems.map(
                (_productItem: ProductCoverProductItemConfiguration) => {
                  const productItem:
                    | ProductItem
                    | undefined = props.product?.productItems?.find(
                    item => item.referenceID === _productItem.referenceID
                  );

                  if (!productItem) {
                    return null;
                  }

                  switch (productItem.displayValueType.toLowerCase()) {
                    case 'money':
                      return {
                        label: productItem.description,
                        value:
                          productItem.displayValue?.value === 0 ||
                          !productItem.displayValue
                            ? false
                            : currencyToHumanReadable(productItem.displayValue)
                      };

                    case 'boolean':
                    case 'text':
                      return {
                        label: productItem.description,
                        value: productItem.displayValue
                      };

                    default:
                      return {
                        label: productItem.description,
                        value: productItem.displayValue
                      };
                  }
                }
              )}
            />
          </BladeSurface>
        )
      )}
    </React.Fragment>
  );
};

interface IDetailsOfCover {
  product: QuoteProduct;
  coverConfiguration: ProductCoverConfiguration[];
  shouldRespectViewWidth?: boolean;
}
