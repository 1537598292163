import React from 'react';
import css from './index.css';
import cx from 'classnames';
import { PricingSet, PricingPlan } from '@aventus/platform/pricing';
import { InsuranceProductConfiguration } from '@aventus/configuration/product';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { getDisplayPrice } from '@aventus/mvmt-pricing/services/get-display-price';
import { configDrivenPricingFinePrint } from '@aventus/mvmt-pricing/services/get-display-fine-print';
import { BladeSurface } from '@aventus/blade/surface';
import { BladeInputVariant } from '@aventus/blade';

export const PaymentPlanSelectorCards: React.FC<
  IPaymentPlanSelectorCardsProps
> = ({
  label,
  description,
  pricingSet,
  setSelectedPaymentPlan,
  selectedPaymentPlan,
  productConfiguration,
  variant = 'solid'
}) => {
  if (pricingSet.plans.length === 0) {
    return null;
  }

  // If only one plan is available
  // and nothing is currently selected
  // auto select the plan.
  if (pricingSet.plans.length === 1 && selectedPaymentPlan === undefined) {
    const selected = [...pricingSet.plans].shift();

    if (selected) {
      setSelectedPaymentPlan(selected);
    }
  }

  const plansConfiguration = productConfiguration.pricing.paymentPlans;

  return (
    <BladeSurface shouldRespectViewWidth={true}>
      {label && <div className={css.paymentPlansLabel}>{label}</div>}
      {description && (
        <div className={css.paymentPlansDescription}>{description}</div>
      )}
      <div className={css.paymentPlanCards}>
        {pricingSet.plans.map(plan => {
          const planConfiguration = plansConfiguration.find(
            config => config.paymentPlanReferenceType === plan.paymentPlan.type
          );

          if (planConfiguration === undefined) {
            // If we can't find the configuration
            // we wont be able to display the pricing card
            return null;
          }

          const isSelectedPlan =
            selectedPaymentPlan?.paymentPlan.type === plan.paymentPlan.type;

          const { label, labelShorthand, priceFinePrint, priceDescription } =
            configDrivenPricingFinePrint(plan, planConfiguration);

          return (
            <div
              className={cx(
                css.paymentPlanCard,
                {
                  [css.paymentPlanCardSelected]: isSelectedPlan
                },
                variant
              )}
              onClick={() => {
                setSelectedPaymentPlan(plan);
              }}
            >
              <div className={css.paymentPlanCard_title}>
                {label}
                {isSelectedPlan && (
                  <svg
                    className={css.paymentPlanCard_checkMark}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                  </svg>
                )}
              </div>
              <div
                className={css.paymentPlanCard_body}
                data-selected-text="SELECTED"
              >
                <span className={css.paymentPlanCard_body_price}>
                  {currencyToHumanReadable(getDisplayPrice(plan))}{' '}
                  {labelShorthand}
                </span>
                {priceFinePrint && (
                  <span className={css.paymentPlanCard_body_priceSmallPrint}>
                    {priceFinePrint}
                  </span>
                )}
                <span className={css.paymentPlanCard_body_spacer}></span>
                {priceDescription && (
                  <span className={css.paymentPlanCard_body_description}>
                    {priceDescription}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </BladeSurface>
  );
};

export interface IPaymentPlanSelectorCardsProps {
  label?: string;
  description?: string;
  pricingSet: PricingSet;
  productConfiguration: InsuranceProductConfiguration;
  selectedPaymentPlan?: PricingPlan;
  setSelectedPaymentPlan: (pricingPlan: PricingPlan) => void;
  variant?: BladeInputVariant;
}
