import * as React from 'react';
import ReactSVG from 'react-svg';
import { BladeButton } from '@aventus/blade';
import { IBladeCallToAction } from '../index';
import { useMediaQuery } from '@aventus/pockethooks';
import { useGetPolicyRisk } from '@aventus/symphony-client/hooks';
import css from './index.css';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useApplicationTimezone } from '@aventus/application-timezone/use-application-timezone';

export const BladeCallToActionCustom: React.FunctionComponent<
  IBladeCallToAction
> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');
  /** useGetPolicyRisk should be deleted after policy would have vehicle plate,year,model */

  const [line1, setLine1] = React.useState<string | undefined>(undefined);
  const [line2, setLine2] = React.useState<string | undefined>(undefined);

  const { risk, isGettingRisk } = useGetPolicyRisk(props.policyID);

  React.useEffect(() => {
    if (risk?.lineOfBusiness === 'motor') {
      const vehicleSpec = risk?.vehicle?.specification;

      setLine1(vehicleSpec?.registration);
      setLine2(
        [vehicleSpec?.year, vehicleSpec?.make, vehicleSpec?.model].join(' ')
      );
    } else if (risk?.lineOfBusiness === 'travel') {
      let destinations = risk?.destinations
        .map((d: any) => d.text.toUpperCase())
        .join(', ');
      if (destinations.length > 30) {
        destinations = destinations.substring(0, 30) + '...';
      }

      const tripPeriod =
        toHumanReadable(risk?.coverStartDate) +
        ' - ' +
        toHumanReadable(risk?.coverEndDate);

      setLine1(destinations);
      setLine2(tripPeriod);
    }
  }, [risk]);

  const { productConfiguration } = useGetProductConfiguration(
    props.productReferenceID,
    props.coverType
  );

  const labelImage =
    productConfiguration?.product.dashboardPolicyImage ?? props.labelImage;
  let isImageSvg: boolean = false;
  if (labelImage) {
    isImageSvg = labelImage.includes('.svg') ? true : false;
  }

  return (
    <div
      className={cx('blade-call-to-action-vertical', css.callToAction, {
        [css.callToAction_mobile]: matchesQuery
      })}
    >
      {labelImage && !isImageSvg && (
        <img className={css.callToAction_image} alt="icon" src={labelImage} />
      )}

      {labelImage && isImageSvg && (
        <ReactSVG className={css.callToAction_image} src={labelImage} />
      )}

      <div className={css.callToAction_titles}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div>
            <h4>
              {isGettingRisk ? <Skeleton width="6ch" /> : line1 ?? line2}{' '}
            </h4>
          </div>
          <div style={{ width: '80px' }}>
            {props.status && <>{props.status.display}</>}
          </div>
        </div>
        {isGettingRisk || line1 !== undefined}
        <label className={cx(css.callToAction_subTitle)}>
          {isGettingRisk ? (
            <Skeleton width="30ch" />
          ) : line1 !== undefined ? (
            line2
          ) : (
            ''
          )}
        </label>
      </div>

      {!props.disabled && (
        <BladeButton
          className={cx(css.callToAction_button, {
            [css.callToAction_button_mobile]: matchesQuery
          })}
          onClick={() => props.action()}
        >
          View Policy
        </BladeButton>
      )}
    </div>
  );
};
