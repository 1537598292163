import update from 'immutability-helper';
import { resetWidgetState } from './_reset-widget-state';
import { getVri } from './get-vri';

export const WIDGET_VRI = 'WIDGET_VRI';

export function setupVriAction(lineOfBusiness, coverType, vriData) {
  return (dispatch, getStore) => {
    const vriRequestObject = {
      partnerId: getStore().widgets.partnerId || null,
      partnerReference: getStore().widgets.partnerReference || null,
      firstName: vriData.firstName || null,
      lastName: vriData.lastName || null,
      emailAddress: vriData.emailAddress || null,
      dateOfBirth: vriData.dateOfBirth || null,
      address1: vriData.address1 || null,
      address2: vriData.address2 || null,
      town: vriData.town || null,
      postalCode: vriData.postalCode || null,
      phoneNumber: vriData.phoneNumber || null,
      coverType: coverType,
      lineOfBusiness,
      payload: vriData
    };

    dispatch(getVri(lineOfBusiness, coverType, vriRequestObject));
  };
}
