import valueGetFromISO from './_value-get-from-iso';

export const OPTIONS_DAYS = [
  {
    value: 1,
    text: '1'
  },
  {
    value: 2,
    text: '2'
  },
  {
    value: 3,
    text: '3'
  },
  {
    value: 4,
    text: '4'
  },
  {
    value: 5,
    text: '5'
  },
  {
    value: 6,
    text: '6'
  },
  {
    value: 7,
    text: '7'
  },
  {
    value: 8,
    text: '8'
  },
  {
    value: 9,
    text: '9'
  },
  {
    value: 10,
    text: '10'
  },
  {
    value: 11,
    text: '11'
  },
  {
    value: 12,
    text: '12'
  },
  {
    value: 13,
    text: '13'
  },
  {
    value: 14,
    text: '14'
  },
  {
    value: 15,
    text: '15'
  },
  {
    value: 16,
    text: '16'
  },
  {
    value: 17,
    text: '17'
  },
  {
    value: 18,
    text: '18'
  },
  {
    value: 19,
    text: '19'
  },
  {
    value: 20,
    text: '20'
  },
  {
    value: 21,
    text: '21'
  },
  {
    value: 22,
    text: '22'
  },
  {
    value: 23,
    text: '23'
  },
  {
    value: 24,
    text: '24'
  },
  {
    value: 25,
    text: '25'
  },
  {
    value: 26,
    text: '26'
  },
  {
    value: 27,
    text: '27'
  },
  {
    value: 28,
    text: '28'
  },
  {
    value: 29,
    text: '29'
  },
  {
    value: 30,
    text: '30'
  },
  {
    value: 31,
    text: '31'
  }
];

export const OPTIONS_MONTHS = [
  {
    value: 1,
    text: 'Jan'
  },
  {
    value: 2,
    text: 'Feb'
  },
  {
    value: 3,
    text: 'Mar'
  },
  {
    value: 4,
    text: 'Apr'
  },
  {
    value: 5,
    text: 'May'
  },
  {
    value: 6,
    text: 'Jun'
  },
  {
    value: 7,
    text: 'Jul'
  },
  {
    value: 8,
    text: 'Aug'
  },
  {
    value: 9,
    text: 'Sep'
  },
  {
    value: 10,
    text: 'Oct'
  },
  {
    value: 11,
    text: 'Nov'
  },
  {
    value: 12,
    text: 'Dec'
  }
];

interface IYearOptions {
  value: number;
  text: string;
}

export const generateYearOptions = ({
  minValue = '1900-01-01T00:00:00Z',
  maxValue = `${new Date().getFullYear()}-12-31T00:00:00Z`
}: {
  minValue?: string;
  maxValue?: string;
}): IYearOptions[] => {
  const minDate = valueGetFromISO(minValue);
  const maxDate = valueGetFromISO(maxValue);

  let options: IYearOptions[] = [];
  for (let year = maxDate.year; year >= minDate.year; year--) {
    options.push({ value: year, text: `${year}` });
  }

  return options;
};
