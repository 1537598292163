import * as React from 'react';
import { BladeSurface } from '../surface';
import { BladeMarkdown } from '../markdown';
import css from './index.css';
import cx from 'classnames';

export const BladeGuide: React.FunctionComponent<IBladeGuide> = (
  props: IBladeGuide
) => {
  return (
    <BladeSurface isAside={true}>
      <div className={cx('blade-guide', css.guide)}>
        <BladeMarkdown size={'large'} markdown={props.markdown} />
      </div>
    </BladeSurface>
  );
};

export interface IBladeGuide {
  markdown?: string;
}
