import { BladeFormFieldset, BladeInputNumber } from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { IStoredFieldAnswer } from '@aventus/mvmt-simplequote/models/fieldAnswer';
import { IFormatters } from '@aventus/mvmt-simplequote/models/formatters';
import { ISettings } from '@aventus/mvmt-simplequote/models/settings';
import { ITobesQuestionMap } from '@aventus/platform-client-context/models';
import { massageValue } from '../massageValue';
import { renderArray } from './renderArray';
import { renderBoolean } from './renderBoolean';
import { renderDate } from './renderDates';
import { renderNotSet } from './renderNotSet';
import { renderObjectType } from './renderObject';
import { renderStrings } from './renderStrings';
import React from 'react';
import {
  TobesQuestionPageState,
  TobesQuestionState
} from '../../store/form-state';
import { createRefKey } from '..';

export function renderQuestionsForPage(
  questions: ITobesQuestionMap[],
  formatters: IFormatters,
  settings: ISettings,
  getQuestionAnswer: (referenceID: string) => any,
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null
  ) => void,
  getArrayDescription: (referenceID: string, template?: string) => string,
  parent: string | null,
  collectionArrayState: IStoredFieldAnswer[],
  setCollectionState: (array: IStoredFieldAnswer[]) => void,
  formState: TobesQuestionPageState,
  getQuestionState: (
    questionReferenceID: string,
    formState: TobesQuestionPageState,
    parentReferenceID?: string
  ) => TobesQuestionState | undefined,
  clearFieldValidation: (questionKey: string) => void,
  productConfiguration?: InsuranceProductConfiguration
) {
  const renderedQuestions: React.ReactNode[] = [];
  questions.forEach(rawQuestion => {
    const renderedQuestion = renderQuestion(
      rawQuestion,
      formatters,
      settings,
      getQuestionAnswer,
      changeQuestionAnswer,
      getArrayDescription,
      parent,
      collectionArrayState,
      setCollectionState,
      formState,
      getQuestionState,
      clearFieldValidation,
      productConfiguration
    );
    if (renderedQuestion) {
      renderedQuestions.push(renderedQuestion);
    }
  });

  return renderedQuestions;
}

function renderQuestion(
  question: ITobesQuestionMap,
  formatters: IFormatters,
  settings: ISettings,
  getQuestionAnswer: (referenceID: string, parent?: string | null) => any,
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null
  ) => void,
  getArrayDescription: (referenceID: string, template?: string) => string,
  parent: string | null,
  collectionArrayState: IStoredFieldAnswer[],
  setCollectionState: (array: IStoredFieldAnswer[]) => void,
  formState: TobesQuestionPageState,
  getQuestionState: (
    questionKey: string,
    formState: TobesQuestionPageState
  ) => TobesQuestionState | undefined,
  clearFieldValidation: (questionKey: string) => void,
  productConfiguration?: InsuranceProductConfiguration
) {
  // Building a unique key for each question
  const questionKey = createRefKey(question.referenceID, parent || undefined);

  // Our forms are composed of Fieldsets, each with one or more inputs.
  // Knowing this we can prepare the common props for each of
  // these layers and spread them on the relevant components below.

  const _value = getQuestionAnswer(question.referenceID, parent);

  const questionState = getQuestionState(questionKey, formState);

  // This is temporary until we support multiple
  // error messages for components.
  const [firstError] = questionState ? questionState.validationErrors : [];

  const isRequiredField = question.validation.some(
    item => item.referenceID === 'required'
  );

  const commonFieldsetProps = {
    name: question.referenceID,
    question: question.text.question,
    description: question.text.summary,
    help: question.text.help,
    detail: question.text.detail,
    moreDetails: question.text.detail,
    icon: settings.questionIcons?.[question.referenceID],
    image: settings.questionImages?.[question.referenceID],
    tooltip: question.text.tooltip,
    key: question.referenceID,
    hidden: question.questionType === 'hidden',
    errorMessage: firstError,
    referenceKey: `FIELDSET_${questionKey}`,
    required: isRequiredField
  };

  const commonInputProps = {
    name: question.referenceID,
    id: `${questionKey}`,
    value: massageValue(question, formatters, _value),
    questionImage: question.text.image,
    onChange: (value: any) => {
      var convertedValue = value;

      // Fixes behaviour around multiselect clear breaking the quote flow
      if (question.questionType === 'multiSelect' && convertedValue === null) {
        convertedValue = [];
      }

      changeQuestionAnswer(question.referenceID, convertedValue, parent);

      if (firstError) {
        clearFieldValidation(questionKey);
      }
    }
  };

  // If a question is marked as hidden then not showing it takes precedence
  if (
    question.questionType != null &&
    question.questionType?.toLowerCase() === 'hidden'
  ) {
    return null;
  }

  switch (question.dataType) {
    case 'NotSet':
      return renderNotSet(question, commonFieldsetProps);

    case 'String':
      return renderStrings(question, commonFieldsetProps, commonInputProps);
    case 'Integer':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputNumber {...commonInputProps} />
        </BladeFormFieldset>
      );

    case 'Decimal':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputNumber {...commonInputProps} />
        </BladeFormFieldset>
      );

    case 'Boolean':
      return renderBoolean(question, commonFieldsetProps, commonInputProps);
    case 'DateTime':
      return renderDate(
        question,
        commonFieldsetProps,
        commonInputProps,
        settings,
        formatters
      );
    case 'LocalDateTime':
      return renderDate(
        question,
        commonFieldsetProps,
        commonInputProps,
        settings,
        formatters
      );
    case 'Object':
      return renderObjectType(
        question,
        commonFieldsetProps,
        commonInputProps,
        settings,
        formatters,
        questionKey,
        getQuestionAnswer,
        changeQuestionAnswer,
        getArrayDescription,
        collectionArrayState,
        setCollectionState,
        formState,
        getQuestionState,
        clearFieldValidation,
        productConfiguration
      );
    case 'Array':
      return renderArray(
        question,
        commonFieldsetProps,
        commonInputProps,
        settings,
        formatters,
        questionKey,
        getQuestionAnswer,
        changeQuestionAnswer,
        getArrayDescription,
        collectionArrayState,
        setCollectionState,
        formState,
        productConfiguration
      );
    default:
      return (
        <p>{question.dataType}</p>
      ); /*TODO: temporarily render to see if we are missing question types*/
  }
}
