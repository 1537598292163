import * as React from 'react';
import { ConfigurationClientContext } from '@aventus/configuration-client-context';
import { SymphonyClientContext } from '@aventus/symphony-client/hooks';
import { ApplicationTimezoneContext } from '@aventus/application-timezone';
import { ApplicationInterfaceProvider } from '@aventus/application-interface';
import { PlatformProvider } from '@aventus/platform-client-context/provider';
import { TrackApp } from '@aventus/application-tracking';
import {
  BladeRunner,
  BladeAppLoader,
  BladeSpace,
  BladeFontFace
} from '@aventus/blade';
import { useMediaQuery } from '@aventus/pockethooks';
import { useApp } from './use-app';

// Important note:
// The external id work here is a temporary workaround
// to bridge redux state into the new react-only state
// management. Please look to remove this asap. The work required
// in order to do this is:
//   * move the app's authentication to use the auth0 hosted page

export const SymphonyApp: React.FunctionComponent<Appy> = props => {
  const {
    isReady,
    configuration,
    symphony,
    organisationSettings,
    organisationConfiguration,
    interfaceConfiguration
  } = useApp(props.externalId);

  // Temporary hack until the mediaquery / view configuration
  // is moved into BladeRunner. Note that any other usage of this
  // throughout the app is also temporary, and should be removed
  // in favour of the BladeRunner when done.

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  // Temporary hack to be able to pass the
  // symphony client down to the initialiser.

  const childrenWithSymphony =
    isReady &&
    React.Children.map(props.children, (child: any) =>
      React.cloneElement(child, {
        symphony,
        organisationConfiguration,
        organisationSettings,
        space: matchesQuery === true ? 'limited' : 'comfortable' // <-- also temporary, until Initialiser is modernised into an React.FC, so we can use runner hook.
      })
    );

  return (
    <>
      <BladeAppLoader off={isReady} />
      {isReady && (
        <BladeRunner {...interfaceConfiguration}>
          <BladeSpace>
            <BladeFontFace fonts={organisationConfiguration?.fonts || []} />

            <TrackApp app={'symphony'} appVersion={__PKG_VERSION__}>
              <ConfigurationClientContext.Provider value={configuration}>
                <SymphonyClientContext.Provider value={symphony}>
                  <PlatformProvider>
                    <ApplicationInterfaceProvider>
                      <ApplicationTimezoneContext.Provider
                        value={{
                          orgTimezone: organisationSettings?.timezone,
                          formats: {
                            human: 'Do MMMM YYYY',
                            platform: 'YYYY-MM-DDTHH:mm:ss'
                          }
                        }}
                      >
                        {childrenWithSymphony}
                      </ApplicationTimezoneContext.Provider>
                    </ApplicationInterfaceProvider>
                  </PlatformProvider>
                </SymphonyClientContext.Provider>
              </ConfigurationClientContext.Provider>
            </TrackApp>
          </BladeSpace>
        </BladeRunner>
      )}
    </>
  );
};

interface Appy {
  mode: 'dev' | 'production';
  externalId: string;
}
