import * as React from 'react';
import { BladeInput_Frame as BladeInputFrame } from '../_frame';
import { useInput } from '../use-input';
import { BladeButton } from '../../button';
import {
  ITobesArrayAnswerItem,
  ITobesQuestionMap
} from '@aventus/platform-client-context/models/tobes';
import { ITobesQuestion } from '@aventus/platform-client-context/models/tobes';
import { useMediaQuery } from '@aventus/pockethooks';
import moment from 'moment';
import css from './index.css';
import cx from 'classnames';
import { useState } from 'react';
import { BladeAsk, BladeLink } from '@aventus/blade';
import uuid from 'uuid/v4';
import { currencyToHumanReadable } from '@aventus/pocketknife';

enum EFormMode {
  NotSet = 'notset',
  Add = 'add',
  Edit = 'edit'
}

const getAnswersPeak = (
  answers: ITobesArrayAnswerItem[],
  questions: ITobesQuestion[]
) => {
  const arrayOfAnswers = answers
    .map((questionAnswer: { questionReferenceID: string; answer: any }) => {
      const question = questions.find(
        question => question.referenceID === questionAnswer.questionReferenceID
      );

      if (question === undefined) {
        return null;
      }

      const answerTitle = question.text.answeredText || question.text.question;

      switch (question.dataType) {
        case 'String':
          return { title: answerTitle, content: String(questionAnswer.answer) };

        case 'LocalDateTime':
          return {
            title: answerTitle,
            content: moment(String(questionAnswer.answer)).format('DD-MM-YYYY')
          };

        case 'Boolean':
          return {
            title: answerTitle,
            content: questionAnswer.answer ? 'Yes' : 'No'
          };

        case 'Object':
          switch (question.objectType) {
            case 'Money':
              return {
                title: answerTitle,
                content: String(
                  currencyToHumanReadable(questionAnswer.answer, {
                    isMajorCurrency: true
                  })
                )
              };
            case 'ListItem':
              return {
                title: answerTitle,
                content: String(
                  'text' in questionAnswer.answer && questionAnswer.answer?.text
                )
              };
            default:
              return {
                title: answerTitle,
                content: JSON.stringify(questionAnswer.answer)
              };
          }
        default:
          return null;
      }
    })
    .filter(item => item !== null);

  return arrayOfAnswers.filter(item => item).flat();
};

export const BladeItemArrayForm: React.FunctionComponent<
  IBladeItemArrayForm
> = ({ tobesQuestion, ...props }) => {
  const inputProps = useInput(props);
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const [formMode, setFormMode] = useState<EFormMode>(EFormMode.Add);
  const [currentInstanceID, setCurrentInstanceID] = useState<string>(uuid());

  const incomingCollection = Array.isArray(tobesQuestion.existingValue)
    ? tobesQuestion.existingValue
    : [];

  return (
    <BladeInputFrame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <div className={css.itemArrayForm}>
        <div>
          {incomingCollection.map(({ id, answers }) => {
            const answersArray = getAnswersPeak(
              answers,
              props.arrayPageQuestions
            );

            /*
              If the item doesn't have an ID
              We should skip it.
            */
            if (id === null || id === undefined) {
              return null;
            }

            /*
              Edit item form
              Uses same form wrapper as add but with different button options.
            */
            if (
              formMode &&
              formMode === EFormMode.Edit &&
              currentInstanceID === id
            ) {
              return (
                <FormWrapper
                  getQuestionsToRender={() => props.getQuestions(id)}
                  getSubmitToRender={(isFormValid: boolean) => (
                    <div className={css.itemArrayForm_form_buttons}>
                      <BladeLink
                        className={cx(css.itemArrayForm_form_link, {
                          [css.itemArrayForm_form_button_wide]: matchesQuery
                        })}
                        isDisabled={!isFormValid}
                        onClick={() => {
                          setFormMode(EFormMode.NotSet);
                          setCurrentInstanceID('');
                        }}
                      >
                        {`Cancel`}
                      </BladeLink>
                      <BladeButton
                        className={cx(css.itemArrayForm_form_button, {
                          [css.itemArrayForm_form_button_wide]: matchesQuery
                        })}
                        isDisabled={!isFormValid}
                        onClick={async () => {
                          const isValid = await props.validateCollectionItem(
                            currentInstanceID,
                            tobesQuestion.referenceID
                          );

                          if (isValid) {
                            if (formMode === EFormMode.Edit) {
                              props.updateItemInCollection(
                                id,
                                tobesQuestion.referenceID
                              );
                            }

                            setFormMode(EFormMode.NotSet);
                            setCurrentInstanceID('');
                          }
                        }}
                      >
                        {`Save ${props.questionObject ?? 'item'}`}
                      </BladeButton>
                    </div>
                  )}
                />
              );
            }

            /*
              Item display
              Shows items that have been entered.
            */
            return (
              <div>
                <div className={css.itemArrayForm_element}>
                  <dl
                    className={cx(css.description_list, {
                      [css.description_list__mobile]: matchesQuery
                    })}
                  >
                    {answersArray &&
                      answersArray
                        .map(item => {
                          return (
                            <>
                              <dt>{item?.title}</dt>
                              <dd>{item?.content}</dd>
                            </>
                          );
                        })
                        .filter(item => item)}
                  </dl>
                  <div className={css.itemArrayForm_element_footer}>
                    <BladeLink
                      onClick={() => {
                        id &&
                          props.removeFromCollection(
                            id,
                            tobesQuestion.referenceID
                          );
                      }}
                      className={css.itemArrayForm_element_footer_link}
                    >
                      Remove
                    </BladeLink>

                    <BladeLink
                      onClick={() => {
                        setFormMode(EFormMode.Edit);
                        setCurrentInstanceID(id);
                      }}
                      className={css.itemArrayForm_element_footer_link}
                    >
                      Modify
                    </BladeLink>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/*
        Add item form.
      */}
      {formMode === EFormMode.Add && currentInstanceID && (
        <FormWrapper
          getQuestionsToRender={() => props.getQuestions(currentInstanceID)}
          getSubmitToRender={(isFormValid: boolean) => (
            <div className={css.itemArrayForm_form_buttons}>
              <BladeLink
                className={cx(css.itemArrayForm_form_link, {
                  [css.itemArrayForm_form_button_wide]: matchesQuery
                })}
                isDisabled={!isFormValid}
                onClick={() => {
                  setFormMode(EFormMode.NotSet);
                  setCurrentInstanceID('');
                }}
              >
                {`Cancel`}
              </BladeLink>
              <BladeButton
                className={cx(css.itemArrayForm_form_button, {
                  [css.itemArrayForm_form_button_wide]: matchesQuery
                })}
                isDisabled={!isFormValid}
                onClick={async () => {
                  const isValid = await props.validateCollectionItem(
                    currentInstanceID,
                    tobesQuestion.referenceID
                  );

                  if (isValid) {
                    props.addToCollection(
                      currentInstanceID,
                      tobesQuestion.referenceID
                    );

                    setFormMode(EFormMode.NotSet);
                    setCurrentInstanceID('');
                  }
                }}
              >
                {`Save ${props.questionObject?.toLocaleLowerCase() ?? 'item'}`}
              </BladeButton>
            </div>
          )}
        />
      )}

      {(!props.maxNumberOfItems ||
        props.maxNumberOfItems < incomingCollection.length) &&
        ![EFormMode.Add, EFormMode.Edit].includes(formMode) && (
          <BladeButton
            className={cx(css.itemArrayForm_add_button, {
              [css.itemArrayForm_form_button_wide]: matchesQuery
            })}
            onClick={() => {
              setFormMode(EFormMode.Add);
              setCurrentInstanceID(uuid());
            }}
          >
            {incomingCollection.length === 0
              ? `Add new ${props.questionObject?.toLocaleLowerCase() ?? 'item'}`
              : `Add another ${
                  props.questionObject?.toLocaleLowerCase() ?? 'item'
                }`}
          </BladeButton>
        )}
    </BladeInputFrame>
  );
};

const FormWrapper: React.FC<IBladeItemArrayFormWrapper> = props => {
  return (
    <div className={css.itemArrayForm_form_wrapper}>
      <BladeAsk
        variant={'alltogether'}
        getQuestionsToRender={props.getQuestionsToRender}
        getSubmitToRender={props.getSubmitToRender}
      />
    </div>
  );
};

interface IBladeItemArrayFormWrapper {
  getQuestionsToRender: (validation: any, dispatchValidation: any) => any;
  getSubmitToRender: (isFormValid: boolean) => React.ReactElement;
}

export interface IBladeItemArrayForm extends IBladeInput {
  arrayPageQuestions: ITobesQuestionMap[];
  questionObject?: string;
  itemLabel: string | null;
  itemKey: string;
  maxNumberOfItems?: number;
  questionArrayAnswers: any;
  tobesQuestion: ITobesQuestionMap;
  addToCollection: (formInstanceId: string, questionName: string) => void;
  updateItemInCollection: (
    formInstanceId: string,
    questionName: string
  ) => void;
  removeFromCollection: (formInstanceId: string, questionName: string) => void;
  validateCollectionItem: (
    formID: string,
    parentReferenceID: string
  ) => Promise<boolean>;
  getQuestions: (currentId: string) => any;
}
