import { AUTH_SIGN_OUT } from './types';
import setNonceAction from '../set-nonce/actions';
import { replace } from 'connected-react-router';
import routes from '../../../routes';
import { getAuth, signOut } from "firebase/auth";

export default function signOutAction(silent = false) {

  return (dispatch, getStore) =>
  {
    const store = getStore();

    if (store.app.core.config.auth0.providers.includes('Firebase'))
    {
      const auth = getAuth();
      if (store.app.core.config.auth0.firebase.tenantID) {
        auth.tenantId = store.app.core.config.auth0.firebase.tenantID;
      }

      signOut(auth);
    }


    dispatch (() => {
      dispatch({
        type: AUTH_SIGN_OUT
      });
      // Only redirect if we're not being silent.
      if (!silent) dispatch(replace(routes.signIn.path));
      dispatch(setNonceAction());
    });
  }
}

// We don't need a reducer for signOut as the this is handled higher up
// in the store so we can reset update multiple state modules
// Check signOutStatePartial from the app api which is called directly
// from configure store in product web and mobile
