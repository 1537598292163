import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationError, ServerError } from '@aventus/errors';
import { PaymentPlanType, QuoteActions } from '@aventus/platform';
import { getDefaultPaymentPlanTypeQuery } from '@aventus/mvmt-pricing';

import { AskForNew } from './ask-for-new';
import { AskForAdjust } from './ask-for-adjust';
import { AskForRenew } from './ask-for-renew';
import { Summary } from './summary';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useGetPolicyPaymentMethod } from '@aventus/symphony-client/hooks';
import { BladeNotification } from '@aventus/blade';
import { useState } from 'react';
import css from './index.css';

export const IntelligentQuote: React.FunctionComponent<
  IIntelligentQuote
> = props => {
  if (!props.match.params.quoteActionOrId) {
    throw new ApplicationError(
      'Could not render component, a quote action or ID was not defined in the path'
    );
  }

  const goHome = () => props.history.push(getHomeRoute());

  const [rateError, setRateError] = useState<ServerError | undefined>();

  const commonProps = {
    getHomeRoute,
    getSummaryRoute,
    goHome
  };

  const iqR =
    props.location.state?.risk && props.location.state?.riskState
      ? {
          risk: props.location.state.risk,
          tobesState: props.location.state.riskState
        }
      : undefined;

  const defaultPaymentPlanType = getDefaultPaymentPlanTypeQuery(
    props.location.search
  );

  const orignalPolicyId = queryParamGet(props.location.search, 'policyId');
  const { policyPaymentMethod, isGettingPolicyPaymentMethod } =
    useGetPolicyPaymentMethod(orignalPolicyId);

  const renewalConfirm = (policyId: string) => {
    props.history.push(getRenewalDashboardRoute(policyId));
  };

  const errorNotification = (
    <BladeNotification
      show={!!rateError}
      isMessageFlush={false}
      isMessageOverlayed={true}
      className={css.error_notification}
      alerts={[
        {
          variant: 'error',
          message: rateError?.message,
          callBack: () => setRateError(undefined)
        }
      ]}
    />
  );

  if (!isGettingPolicyPaymentMethod) {
    let renewalPaymentPlan = policyPaymentMethod
      ? policyPaymentMethod.paymentPlanType
      : 'NotSet';

    switch (props.match.params.quoteActionOrId) {
      case 'new':
      case 'edit':
      case 'estimate':
        return (
          <>
            <AskForNew
              {...props}
              {...commonProps}
              defaultPaymentPlanType={defaultPaymentPlanType}
              intelligentQuoteRequest={iqR}
              showEstimate={
                props.match.params.quoteActionOrId === 'estimate' ? true : false
              }
              setRateError={setRateError}
            />
            {rateError && <>{errorNotification}</>}
          </>
        );

      case 'renew':
        return (
          <>
            <AskForRenew
              {...props}
              {...commonProps}
              defaultPaymentPlanType={defaultPaymentPlanType}
              renewalConfirmationLink={renewalConfirm}
              setRateError={setRateError}
            />
            {rateError && <>{errorNotification}</>}
          </>
        );

      case 'adjust':
        return (
          <>
            <AskForAdjust
              {...props}
              {...commonProps}
              setRateError={setRateError}
            />{' '}
            {rateError && <>{errorNotification}</>}
          </>
        );

      default:
        return (
          <Summary
            {...props}
            quoteId={props.match.params.quoteActionOrId}
            defaultPaymentPlanType={
              renewalPaymentPlan !== 'NotSet'
                ? renewalPaymentPlan
                : defaultPaymentPlanType
            }
            goHome={() => props.history.push(getHomeRoute())}
            customiseQuote={(route: string) => {
              props.history.push(route, {
                origin: props.location.pathname
              });
            }}
            customiseQuoteForRenew={(quoteId: string) => {
              props.history.push(getEditQuoteForRenewRoute(quoteId), {
                origin: props.location.pathname
              });
            }}
            goToDocuments={(type: string, id: string) => {
              props.history.push(getDocumentsRoute(type, id), {
                origin: props.location.pathname
              });
            }}
            goToCheckout={(
              quoteId: string,
              defaultPaymentPlanType?: PaymentPlanType
            ) => {
              props.history.push(
                getCheckoutRoute(quoteId, defaultPaymentPlanType),
                {
                  origin: props.location.pathname
                }
              );
            }}
            goToLogin={() => {
              props.history.push(getLoginRoute(), {
                origin: props.location.pathname,
                isMidFlowAuth: true,
                onSuccessGoToRoute: props.location.pathname
              });
            }}
          />
        );
    }
  }
  return null;
};

// Note that this whole component is meant to be pulled out
// to the app level. These fns should be defined by the app,
// through some sort of route configuration. They are temporarily
// placed here.

const getSummaryRoute = (
  quoteId: string,
  defaultPaymentPlanType?: PaymentPlanType
) =>
  `/quote/${quoteId}${
    defaultPaymentPlanType ? `?plan=${defaultPaymentPlanType}` : ''
  }`;
const getHomeRoute = () => `/dashboard`;
const getDocumentsRoute = (type: string, id: string) =>
  `/${type}/${id}/documents`;
const getCheckoutRoute = (
  quoteId: string,
  defaultPaymentPlanType?: PaymentPlanType,
  token?: string
) =>
  `/checkout/${quoteId}${
    defaultPaymentPlanType ? `?plan=${defaultPaymentPlanType}` : ''
  }
    ${token && defaultPaymentPlanType ? '&' : ''}
    ${token && !defaultPaymentPlanType ? '?' : ''}
    ${token ? `token=${token}` : ''}`;
const getLoginRoute = () => `/sign-in`;

const getRenewalDashboardRoute = (policyId: string) =>
  `/policy/${policyId}/renew`;
const getEditQuoteForRenewRoute = (quoteId: string) =>
  `/quote/renew?quoteId=${quoteId}`;

export interface IIntelligentQuote
  extends RouteComponentProps<
    {
      quoteActionOrId: QuoteActions;
    },
    {},
    {
      origin?: string;
      risk?: any;
      isMidFlowAuth?: boolean;
      riskState?: string;
      onSuccessGoToRoute?: string;
    }
  > {
  getSummaryRoute: (quoteId: string) => string;
  getHomeRoute: () => string;
  goHome: () => void;
}
