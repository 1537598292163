import * as React from 'react';

import { BladeSurface } from '../../surface';
import { IBladeAsk } from '../index';
import { PanelLayout } from '@aventus/blade/form-fieldset';
import css from './index.css';
import cx from 'classnames';
import { useForm } from '../../form';

export function BladeAskCustomisePanels(props: IBladeAsk) {
  const { questions, submit } = useForm(
    props.getQuestionsToRender,
    props.getSubmitToRender
  );

  return (
    <div className={css.customise}>
      {questions.map((question: any, i: number) => {
        if (!question) {
          return null;
        }

        const panelLayout: PanelLayout | undefined = question.props.isPanel;
        const isPanel = panelLayout !== undefined;

        return (
          <BladeSurface
            key={i}
            shouldFillHeight={isPanel}
            shouldRespectViewWidth={props.shouldRespectViewWidth}
            // Added props.name as class here so we can target
            // for styling and testing where required.
            className={cx(
              question.props.name,
              { [css.alltogether_question_isDefault]: !isPanel },
              {
                [css.allTogether_question_isPanel_3Col]:
                  panelLayout === PanelLayout.ThreeColumns
              },
              {
                [css.allTogether_question_isPanel_2Col]:
                  panelLayout === PanelLayout.TwoColumns
              },
              {
                [css.allTogether_question_isSectionHeader]:
                  question.props.isSectionHeader
              }
            )}
            classNameContent={cx(
              {
                [css.allTogether_question_panel_3Col]:
                  panelLayout === PanelLayout.ThreeColumns
              },
              {
                [css.allTogether_question_panel_2Col]:
                  panelLayout === PanelLayout.TwoColumns
              }
            )}
          >
            {question}
          </BladeSurface>
        );
      })}

      {submit}
    </div>
  );
}
