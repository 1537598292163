import * as React from 'react';
import {
  BladeView,
  BladeTitleBar,
  BladeButton,
  BladeMarkdown
} from '@aventus/blade';
import {
  PaymentPlanType,
  PolicyBundle,
  QuoteBundle,
  QuoteProduct
} from '@aventus/platform';
import { Redirect } from 'react-router';
import {
  InsuranceProductConfiguration,
  OrganisationConfiguration
} from '@aventus/configuration';
import { PriceDisplay, usePaymentPlan } from '@aventus/mvmt-pricing';
import { useApplicationTimezone } from '@aventus/application-timezone/use-application-timezone';
import { useDocumentTitle } from '@aventus/pockethooks';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { YourDetails } from '../../components/your-details';
import { DetailsOfPolicy } from '@aventus/mvmt-quote/components/details-of-policy';
import { DetailsOfPremium } from '@aventus/mvmt-quote/components/details-of-premium';
import { DetailsOfYourCover } from '@aventus/mvmt-quote/components/details-of-your-cover';
import { DetailsOfCover } from '@aventus/mvmt-product';
import { DetailsOfDrivers } from '@aventus/mvmt-quote/components/details-of-drivers';
import css from './index.css';
import { IntelligentQuoteDecline } from '@aventus/mvmt-quote/views/intelligent-quote-decline';
import { useTrack } from '@aventus/application-tracking';

const ViewDocumentsAction: React.FC<{
  label?: string;
  onClick: () => void;
}> = props => (
  <BladeButton variant={'secondary'} onClick={props.onClick}>
    {props.label || 'View documents'}
  </BladeButton>
);

const PayNowAction: React.FC<{
  label?: string;
  paymentPlanType?: PaymentPlanType;
  onClick: () => void;
}> = props => (
  <BladeButton
    variant={
      props.paymentPlanType === 'FixedTermSinglePayment' ||
      props.paymentPlanType === 'SinglePayment'
        ? 'primary'
        : 'secondary'
    }
    onClick={props.onClick}
  >
    {props.label || 'Pay Now'}
  </BladeButton>
);

const EditRenewalAction: React.FC<{
  label?: string;
  paymentPlanType?: PaymentPlanType;
  onClick: () => void;
}> = props => (
  <BladeButton
    onClick={props.onClick}
    variant={
      props.paymentPlanType === 'FixedTermSinglePayment' ||
      props.paymentPlanType === 'SinglePayment'
        ? 'secondary'
        : 'primary'
    }
  >
    {props.label || 'Edit Renewal'}
  </BladeButton>
);

export const PolicyRenew: React.FunctionComponent<IPolicyRenew> = props => {
  const { track } = useTrack();
  useDocumentTitle('Your Policy Renewal');

  const originalPaymentPlanType = props.policyBundle?.totalPaid.paymentPlanType;

  const paymentPlan = usePaymentPlan(
    props.quoteBundle?.requestedQuotePricing,
    originalPaymentPlanType
  );

  const { toHumanReadable } = useApplicationTimezone();

  const isPolicyCancelled =
    props.policyBundle?.policy.policyStatus === 'Cancelled';

  const isQuoteDeclined = props.quoteBundle?.requestedQuote.declined;

  React.useEffect(() => {
    if (props.policyBundle && props.quoteBundle) {
      track({
        event: 'aventus.renewal.overview',
        policyId: props.policyBundle?.policy.id,
        productReference: props.productConfiguration?.productReference,
        productCoverReference:
          props.productConfiguration?.productCoverReference,
        quoteId: props.quoteBundle?.requestedQuote.id
      });
    }
  }, [props.policyBundle, props.quoteBundle]);

  if (
    props.productConfiguration &&
    props.policyBundle &&
    props.quoteBundle &&
    props.organsationConfiguration
  ) {
    if (isPolicyCancelled) {
      return <Redirect to={`/policy/${props.policyId}`} />;
    }
    if (isQuoteDeclined) {
      return (
        <IntelligentQuoteDecline
          documentTitle={props.productConfiguration.quote.renewalDecline.title}
          title={props.productConfiguration.quote.decline.unrecoverableTitle}
          declineWithNoReason={
            props.productConfiguration.quote.decline.declineWithNoReason
          }
          help={props.productConfiguration.quote.decline.help}
          supportLink={props.organsationConfiguration.links.support}
          onAcknowledgement={props.goBackToPolicy}
        />
      );
    }
    return (
      <>
        <div className={css.scaffold}>
          <BladeTitleBar
            viewVariant={'s'}
            title={
              props.productConfiguration.policy.renew.title ||
              'Manage your renewal'
            }
            description={props.productConfiguration.policy.renew.description}
            cssClass={css.titleNoSpaceBottom}
          />
          <BladeView variant={'s'}>
            {props.quoteBundle.requestedQuote.converted && (
              <div className={css.renewalDashboard_alreadyConverted}>
                <BladeMarkdown
                  markdown={
                    props.productConfiguration.policy.renew
                      .alreadyConvertedDescription ||
                    'This renewal quote has already been purchased. You can view your policy instead.'
                  }
                />
                <BladeButton onClick={() => props.onViewConvertedPolicy()}>
                  {props.productConfiguration.policy.renew
                    .alreadyConvertedCTA || 'View my policy'}
                </BladeButton>
              </div>
            )}

            {!props.quoteBundle.requestedQuote.converted && (
              <div className={css.renewalDashboard}>
                <PriceDisplay
                  productConfiguration={props.productConfiguration}
                  plan={paymentPlan.selectedPricingPlan}
                  quoteType={props.quoteBundle.requestedQuote.quoteType}
                />

                {props.productConfiguration.renewal && (
                  <div className={css.renewalDisclaimer}>
                    <BladeMarkdown
                      markdown={
                        originalPaymentPlanType === 'FixedTermSinglePayment' ||
                        originalPaymentPlanType === 'SinglePayment'
                          ? props.productConfiguration.renewal.summary.payment
                              .annual
                          : props.productConfiguration.renewal.summary.payment
                              .monthly
                      }
                    />
                  </div>
                )}

                <div className={css.renewalActions}>
                  <div className={css.renewalActions_primary}>
                    {originalPaymentPlanType === 'FixedTermSinglePayment' ||
                    originalPaymentPlanType === 'SinglePayment' ? (
                      <PayNowAction
                        onClick={() => props.onCheckoutRenewal()}
                        label={
                          props.productConfiguration.policy.renew
                            .checkoutCTALabel
                        }
                        paymentPlanType={originalPaymentPlanType}
                      />
                    ) : (
                      <EditRenewalAction
                        onClick={() => props.onEditRenewal()}
                        label={
                          props.productConfiguration.policy.renew.editCTALabel
                        }
                        paymentPlanType={originalPaymentPlanType}
                      />
                    )}
                  </div>
                  <div className={css.renewalActions_secondary}>
                    <ViewDocumentsAction
                      onClick={() => props.onViewDocuments()}
                      label={
                        props.productConfiguration.policy.renew
                          .documentsCTALabel
                      }
                    />
                    {originalPaymentPlanType === 'FixedTermSinglePayment' ||
                    originalPaymentPlanType === 'SinglePayment' ? (
                      <EditRenewalAction
                        onClick={() => props.onEditRenewal()}
                        label={
                          props.productConfiguration.policy.renew.editCTALabel
                        }
                        paymentPlanType={originalPaymentPlanType}
                      />
                    ) : (
                      <PayNowAction
                        onClick={() => props.onCheckoutRenewal()}
                        label={
                          props.productConfiguration.policy.renew
                            .checkoutCTALabel
                        }
                        paymentPlanType={originalPaymentPlanType}
                      />
                    )}
                  </div>
                </div>
                {props.productConfiguration.renewal && (
                  <div className={css.renewalDisclaimer}>
                    <BladeMarkdown
                      markdown={stringReplacer(
                        props.productConfiguration.renewal.summary.disclaimer,
                        {
                          $$MATCHURL_TERMS: '/terms-and-conditions',
                          $$MATCHURL_PRIVACYPOLICY: '/privacy-policy'
                        }
                      )}
                    />
                  </div>
                )}
              </div>
            )}

            {!props.quoteBundle.requestedQuote.converted && (
              <>
                {props.productConfiguration.quote.summary
                  .showInsurerDetails && (
                  <YourDetails
                    proposer={props.policyBundle.risk.proposer}
                    toHumanReadable={toHumanReadable}
                    showEmail={false}
                    shouldRespectViewWidth={true}
                  />
                )}

                <DetailsOfPolicy
                  productName={props.productConfiguration.product.label}
                  quote={props.quoteBundle.requestedQuote}
                  risk={props.quoteBundle.risk}
                  isMotorQuote={
                    props.productConfiguration.quote.summary.showMotorDetails
                  }
                  coverStartLabel={
                    props.productConfiguration.quote.summary.coverStartLabel
                  }
                  coverEndLabel={
                    props.productConfiguration.quote.summary.coverEndLabel
                  }
                  shouldRespectViewWidth={true}
                />

                {props.productConfiguration.quote.summary.showDriverDetails && (
                  <DetailsOfDrivers
                    proposer={props.quoteBundle.risk.proposer}
                    driverDetails={props.quoteBundle.risk.vehicle.driverDetails}
                    shouldRespectViewWidth={true}
                  />
                )}
                {props.productConfiguration.quote.summary
                  .showPremiumSummary && (
                  <DetailsOfPremium
                    quote={props.quoteBundle.requestedQuote}
                    pricingPlan={paymentPlan.selectedPricingPlan}
                    productConfiguration={props.productConfiguration}
                    shouldRespectViewWidth={true}
                  />
                )}

                {props.quoteProduct && (
                  <DetailsOfCover
                    product={props.quoteProduct}
                    coverConfiguration={
                      props.productConfiguration.quote.summary.cover
                    }
                    shouldRespectViewWidth={true}
                  />
                )}

                {props.productConfiguration.quote.summary
                  .coverTypesMarkdown && (
                  <DetailsOfYourCover
                    title={
                      props.productConfiguration.quote.summary
                        .coverTypesMarkdownTitle
                    }
                    markdown={
                      props.productConfiguration.quote.summary
                        .coverTypesMarkdown
                    }
                  />
                )}
              </>
            )}
          </BladeView>
        </div>
      </>
    );
  }

  return null;
};

export interface IPolicyRenew {
  className?: string;
  policyId?: string;
  policyBundle?: PolicyBundle;
  productConfiguration?: InsuranceProductConfiguration;
  organsationConfiguration?: OrganisationConfiguration;
  quoteBundle?: QuoteBundle;
  quoteProduct?: QuoteProduct;
  onCheckoutRenewal: () => void;
  onViewDocuments: () => void;
  onEditRenewal: () => void;
  onViewConvertedPolicy: () => void;
  goBackToPolicy: () => void;
}
