import { useTracking } from 'react-tracking';

export const useTrack: UseTrack = () => {
  const { trackEvent } = useTracking();

  return {
    track: trackEvent,

    trackView: (theView, theRest = {}) => {
      trackEvent({
        event: 'aventus.screenview',
        view: theView,
        ...theRest
      });
    }
  };
};

export type UseTrack = () => {
  track: any;
  trackView: (theView: string, theRest?: object) => void;
};
