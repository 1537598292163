import * as React from 'react';
import { BladeBadge } from '../badge';
import css from './index.css';
import cx from 'classnames';

export const BladeBanner: React.FunctionComponent<IBladeBanner> = props => {
  return (
    <div
      style={{
        backgroundImage: props.background ? `url(${props.background})` : 'none'
      }}
      className={cx(
        'blade-banner',
        css.banner,
        props.className,
        props.background ? css.banner_image : css.banner_noImage
      )}
    >
      {props.title && <h2>{props.title}</h2>}

      {props.isCollapsable && (
        <BladeBadge
          label={props.isCollapsed ? 'Show' : 'Hide'}
          className={css.bannerToggle}
          onClick={() => {
            if (props.toggle) {
              props.toggle();
            }
          }}
        />
      )}
    </div>
  );
};

export interface IBladeBanner {
  background?: string;
  title?: string;
  className?: string;
  isCollapsable?: boolean;
  isCollapsed?: boolean;
  toggle?: () => void;
}
