import { createRefKey } from '..';
import {
  TobesQuestionPageState,
  TobesQuestionState
} from '../../store/form-state';

export const getQuestionState = (
  key: string,
  formState: TobesQuestionPageState
): TobesQuestionState | undefined => {
  const searchFn = (
    referenceID: string,
    questionsState: TobesQuestionState[],
    stateParentReferenceID?: string
  ): TobesQuestionState | undefined => {
    return questionsState.reduce(
      (state: TobesQuestionState | undefined, item) => {
        if (state) {
          return state;
        }
        const match = createRefKey(item.referenceID, stateParentReferenceID);

        return referenceID === match
          ? item
          : searchFn(referenceID, item.children, match);
      },
      undefined
    );
  };

  return searchFn(key, formState.questions);
};
