import { stateVersion } from '../../../state-version';

export default {
  isAppReady: false,
  stateVersion,
  privacyPolicyVersion: null,
  config: {
    env: {},
    products: {},
    auth0: {}
  },
  customConfig: {},
  activeLinesOfBusiness: [],
  showWelcomeScreen: true,
  showCookiePolicy: true,
  guestQuotes: [],
  tracking: {
    externalId: null
  },
  alerts: {
    alertsQueue: []
  },
  networkStatus: {
    isConnected: true
  },
  addressLookup: {
    data: {
      results: false,
      encryptKey: null
    },
    _flags: {
      loadingAddressList: false,
    }
  },
  _flags: {
    guardRoute: true
  }
};
