import { PricingConfiguration } from '@aventus/configuration/product';
import { MtaInformation } from '@aventus/platform';

export function getDisplayFinePrintForAdjust(
  pricingConfiguration: PricingConfiguration,
  mtaInformation?: MtaInformation
): Array<string> {
  const isEstimated =
    mtaInformation &&
    mtaInformation.creditAgreementChange &&
    mtaInformation.creditAgreementChange.isIndicative;
  const taxes: string =
    (isEstimated && pricingConfiguration.taxDescriptionWithEstimate) ||
    pricingConfiguration.taxDescription;

  return [taxes];
}
