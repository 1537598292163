import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { History } from 'history';
import { useGetProduct } from '@aventus/mvmt-simplequote/hooks/use-get-product';
import { QuoteType } from '@aventus/platform';
import { SimpleQuotePage } from './simple-quote-page';

export const SimpleQuote: React.FunctionComponent<ISimpleQuote> = props => {
  const location = useLocation<History.Search>();
  const coverType = new URLSearchParams(location.search).get('coverType');

  const { product: productData } = useGetProduct(
    props.match.params.productId,
    coverType
  );

  if (productData) {
    return (
      <SimpleQuotePage
        type={props.match.params.type}
        product={productData}
        questionSetReferenceID={props.match.params.questionSetReferenceID}
        pageIndex={props.match.params.pageIndex}
        isAuthenticated={props.isAuthenticated}
        dashboardPath={props.dashboardPath}
        hideFooter={props.hideFooter}
        history={props.history}
      />
    );
  }
  return null;
};

export interface ISimpleQuote
  extends RouteComponentProps<{
    productId: string;
    type: QuoteType;
    questionSetReferenceID: string;
    pageIndex?: string;
  }> {
  isAuthenticated: boolean;
  dashboardPath: string;
  hideFooter: () => void;
}
