export function createUpdateCommand(
  nests: Array<string>,
  nestPointer: number,
  updateCommand?: object
): object {
  if (nests.length === nestPointer + 1) {
    return { [nests[nestPointer]]: updateCommand || {} };
  } else {
    return {
      [nests[nestPointer]]: createUpdateCommand(
        nests,
        nestPointer + 1,
        updateCommand
      )
    };
  }
}
