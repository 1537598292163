import update from 'immutability-helper';

export default function clearQuoteIdsReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        guestQuotes: { $set: [] }
      })
    }
    
  };
}
