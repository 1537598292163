import { PaymentPlanType } from '@aventus/platform';
import { PricingPaymentPlanConfiguration } from '@aventus/configuration';

export function findPaymentPlanConfiguration (paymentPlanType: PaymentPlanType, configurationForPaymentPlans: PricingPaymentPlanConfiguration[]) {
  return (
    configurationForPaymentPlans.find(
      (configurationForPaymentPlan) => configurationForPaymentPlan.paymentPlanReferenceType === paymentPlanType
    )
  );
}
