import React from 'react';
import {
  BladeButton,
  BladeEmail,
  BladeInputDatePicker,
  BladeInputSelect,
  BladeInputText
} from '@aventus/blade';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { Controller, useForm } from 'react-hook-form';
import { EFieldMessages, Honorific, InputSelectFieldType } from '../..';
import css from '../../index.css';
import nationalities from './../../../../data/nationalities.json';
import countriesOfBirth from './../../../../data/countries-of-birth.json';
import { Moment } from 'moment';
import cx from 'classnames';
import { mustBeValidPhoneNumber } from '@aventus/pocketknife/validators/must-be-valid-phone-number';

const YourDetails = (props: IBNPYourDetailsProps): JSX.Element => {
  const { control, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      title: props.title,
      firstName: props.firstName,
      lastName: props.lastName,
      telephone: props.telephone,
      emailAddress: props.emailAddress,
      dateOfBirth: props.dateOfBirth,
      nationality: props.nationality,
      countryOfBirth: props.countryOfBirth
    }
  });

  const { toPlatformReadable, toApplicationReadable, timezone } =
    useApplicationTimezone();

  const onSubmit = (data: IYourDetailsForm) => {
    props.updateStore(data);
    props.nextStep();
  };

  const onError = () => {
    if (typeof props.validationError === 'function') {
      props.validationError(
        'Some fields are incomplete, please check the form and try again.'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={css.bnpFieldWrapper}>
        <Controller
          name={'title'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputSelect
                  label={'Title'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  options={[
                    {
                      text: `Mr`,
                      value: 'Mr'
                    },
                    {
                      text: `Miss`,
                      value: 'Miss'
                    },
                    {
                      text: `Ms`,
                      value: 'Ms'
                    },
                    {
                      text: `Rev`,
                      value: 'Rev'
                    },
                    {
                      text: `Dr`,
                      value: 'Dr'
                    }
                  ]}
                  matchOn="value"
                />
                {errors && 'title' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.title?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'firstName'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputText
                  label={'First name'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'firstName' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.firstName?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'lastName'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputText
                  label={'Last name'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'lastName' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.lastName?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'telephone'}
          control={control}
          rules={{
            required: EFieldMessages.required,
            validate: value =>
              mustBeValidPhoneNumber(value, 'GB') === undefined
                ? true
                : 'Must be a valid telephone number'
          }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputText
                  label={'Telephone'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'telephone' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.telephone?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'emailAddress'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeEmail
                  label={'Email'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'emailAddress' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.emailAddress?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'dateOfBirth'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputDatePicker
                  validationRules={[]}
                  timezone={timezone}
                  label={'Date of birth'}
                  toPlatformReadable={toPlatformReadable}
                  toApplicationReadable={toApplicationReadable}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'dateOfBirth' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.dateOfBirth?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'nationality'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputSelect
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  label={'Nationality'}
                  options={nationalities}
                  matchOn="referenceID"
                  labelOn="name"
                  hideSearch
                />
                {errors && 'nationality' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.nationality?.message}
                  </span>
                )}
              </div>
            );
          }}
        />

        <Controller
          name={'countryOfBirth'}
          control={control}
          rules={{ required: EFieldMessages.required }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputSelect
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                  label={'Country of birth'}
                  options={countriesOfBirth}
                  matchOn="referenceID"
                  labelOn="name"
                  hideSearch
                />
                {errors && 'countryOfBirth' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.countryOfBirth?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
      </div>
      <BladeButton type="submit" variant="primary">
        Continue
      </BladeButton>
    </form>
  );
};

interface IYourDetailsForm {
  title: Honorific;
  firstName: string;
  lastName: string;
  telephone: string;
  emailAddress: string;
  dateOfBirth: Moment;
  nationality: InputSelectFieldType;
  countryOfBirth: InputSelectFieldType;
}

interface IBNPYourDetailsProps {
  title: Honorific | null;
  firstName: string;
  lastName: string;
  telephone: string | null;
  emailAddress: string;
  dateOfBirth: Moment;
  nationality: InputSelectFieldType;
  countryOfBirth: InputSelectFieldType;
  nextStep: () => void;
  updateStore: (data: IYourDetailsForm) => void;
  validationError?: (message: string) => void;
}

export default YourDetails;
