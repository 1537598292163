import { Money } from '@aventus/platform';
import { CheckoutPaymentConfiguration } from '@aventus/configuration';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { ToHumanReadable } from '@aventus/application-timezone';

export function getPaymentGuide(
  paymentConfiguration: CheckoutPaymentConfiguration,
  isDeposit: boolean,
  replacers: Replacers,
  toHumanReadable: ToHumanReadable
): string | undefined {

  const guide = (
    isDeposit
      ? paymentConfiguration.guideForDeposit
      : paymentConfiguration.guide
  );

  if (!guide) {
    return undefined;
  }

  const replacersMap = {
    '$$_upfront_price': currencyToHumanReadable(replacers.upfrontPrice),
    '$$_monthly_price': currencyToHumanReadable(replacers.monthlyPrice),
    ...(replacers.initPaymentDate)
      ? { '$$_initial_payment_date': toHumanReadable(replacers.initPaymentDate) }
      : {}
  };

  return (
    stringReplacer(
      guide,
      replacersMap
    )
  );

}

interface Replacers {
  initPaymentDate: Nullable<string>,
  upfrontPrice: Money,
  monthlyPrice: Money
}
