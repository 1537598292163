import React from 'react';
import DatePicker from 'react-datepicker';
import dateWithinRange from '@aventus/pocketknife/date-within-range';
import cx from 'classnames';
import { DPHeader } from './dp-header';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { useValidation } from './use-validation';
import { BladeIcon } from '../../icon';
import css from './index.css';
import './datepicker.css';
import moment from 'moment';

export const BladeInputDatePicker: React.FunctionComponent<IBladeInputDatePicker> = ({
  timezone,
  validationRules = [],
  toApplicationReadable,
  toPlatformReadable,
  ...props
}) => {
  const {
    hasBeenInteractedWith,
    error,
    onChange,
    className = '',
    value,
    label,
    id
  } = useInput(props);

  const { minDate, maxDate } = useValidation(validationRules);

  const prefixZero = (n: number): string => (n > 9 ? n : '0' + n).toString();

  const onDateChanged = (date: Date) => {
    const dateToString = `${date.getFullYear()}-${prefixZero(
      date.getMonth() + 1
    )}-${prefixZero(date.getDate())}T00:00:00`;

    if (dateWithinRange(dateToString, minDate, maxDate)) {
      onChange(moment(dateToString, 'YYYY-MM-DDTHH:mm:ss'));
    } else {
      onChange('');
    }
  };

  // If we don't have a value defined, which we can assume
  // will only happen once on initial load, we will just
  // initialise a new one and trigger the onChange with it.

  if (!value) {
    onDateChanged(new Date());
  }

  const dateFormat = props.dateFormat ? props.dateFormat : 'EEE d MMMM yyyy';

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={hasBeenInteractedWith}
      error={error}
    >
      {label && <BladeInput_Label id={id} label={label} />}

      <div className={css.datepicker_wrapper}>
        <BladeIcon className={css.datepicker_icon} name="faCalendarAlt" />

        <DatePicker
          className={cx(className, css.datepicker_input)}
          selected={value ? new Date(value) : undefined}
          showPopperArrow={false}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          onChange={onDateChanged}
          onChangeRaw={e => e.preventDefault()}
          renderCustomHeader={props => (
            <DPHeader
              {...props}
              toApplicationReadable={toApplicationReadable}
              setDate={date => {
                onDateChanged(date);
              }}
            />
          )}
          dateFormat={dateFormat}
        />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputDatePicker extends IBladeInput {
  timezone: string | undefined;
  toApplicationReadable: any;
  toPlatformReadable: any;
  validationRules: Array<string>;
  dateFormat?: string;
  formatOutput?: boolean;
}
