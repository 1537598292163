export const MARGIN_GUIDE = {
  borderStyle: 'solid',
  borderColor: '#f7cb9f'
}

export const PADDING_GUIDE = {
  borderStyle: 'solid',
  borderColor: '#C1CC8C'
}

export const CONTENT_GUIDE = {
  backgroundColor: '#8BB3BE'
}

export const ROW_COL = {
  '&.center': {
    alignItems: 'center',
    justifyContent: 'center'
  },

  '&.fill': {
    height: '100%'
  },

  '&.shrink': {
    flexShrink: 1
  },

  '&.no-shrink': {
    flexShrink: 0
  },

  '&.grow': {
    flexGrow: 1
  },

  '&.no-grow': {
    flexGrow: 0
  },

  '&.flex-start': {
    justifyContent: 'flex-start'
  },

  '&.flex-end': {
    justifyContent: 'flex-end'
  },

  '&.space-between': {
    justifyContent: 'space-between'
  },

  '&.space-around': {
    justifyContent: 'space-around'
  },

  '&.space-evenly': {
    justifyContent: 'space-evenly'
  }
}
