import * as React from 'react';
import { BladeAsk } from '../../../../ask';
import css from './index.css';

export const ClaimForm: React.FunctionComponent<IClaimForm> = props => {
  return (
    <>
      <div className={css.claims_form_wrapper}>
        <BladeAsk
          variant={'alltogether'}
          getQuestionsToRender={props.getQuestions}
          getSubmitToRender={props.claimFormActions}
        />
      </div>
    </>
  );
};

export interface IClaimForm {
  getQuestions: (
    validation: any,
    dispatchValidation: any
  ) => Array<React.ReactNode>;
  claimFormActions: (isFormValid: boolean) => React.ReactNode;
}
