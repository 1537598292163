import React from 'react';
import styles from './styles.css';

const YourDuty = () => (
  <>
    <h3 className={styles.legalHeader}>
      Your duty when you apply for insurance
    </h3>

    <div className={styles.legal}>
      <p>
        By proceeding, you confirm that you have read the following important
        information.
      </p>
      <p>
        By law, you must take reasonable care not to make a misrepresentation.
        This means giving us true, complete and accurate answers to our
        questions, including where you provide information on someone else's
        behalf.
      </p>
      <p>
        We use your answers to decide whether to insure you and on what terms.
      </p>
      <p>
        If any of your answers are misleading, incomplete, inaccurate or
        fraudulent we may reduce or not pay a claim, cancel your policy or treat
        it as if it never existed.
      </p>
      <p>
        If you don't understand a question, you're unsure how to answer or if
        anything is unclear, please call us on 1300 633 811.
      </p>
      <p>Do you understand your duty when you apply for insurance?</p>
    </div>
  </>
);


export default YourDuty;