import React from 'react';
import {
  BladeGuide,
  BladeLoader,
  BladeMessage,
  BladeModal,
  BladeSurface,
  BladeButton
} from '@aventus/blade';
import { FatZebraPayByCard } from '../pay-by-card';
import {
  IFatZebraVerificationProps,
  IFatZebraVerificationResponse,
  IFatZebraConfigResponse
} from '@aventus/platform';
import css from './index.css';
import { useBladeInlineError } from '@aventus/blade/inline-error';
import { useQuery } from 'react-query';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';

export const FatZebraWrapper: React.FC<IFatZebraWrapperProps> = ({
  payment,
  quoteID,
  policyID,
  onPayNow,
  onComplete,
  setIsComplete,
  setIsCheckingOut,
  isCheckingOut,
  verificationProps,
  fatZebraConfig,
  fatZebraPaymentIntentVerification,
  error,
  ...props
}) => {
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const { setError } = useBladeInlineError();

  // This flag is set to true once the Buy Now button is clicked and allows us to track whether we actually receive the tokenization.success
  // event from FatZebra after we run FatZebra.checkout method. In some cases, the event is not published (FatZebra issue), so we in these cases
  // we need to alert the user, and provide them an option of refreshing the page to re-load the iframe form and re-attempt payment.
  const [hasInitiatedPayment, setHasInitiatedPayment] =
    React.useState<boolean>(false);

  const [showRefreshModal, setShowRefreshModal] =
    React.useState<boolean>(false);

  const _payNow = React.useCallback(
    (event: any) => {
      setHasInitiatedPayment(true);
      onPayNow(event.detail.data.token);
    },
    [onPayNow]
  );

  const _validation = React.useCallback(() => {
    setError(
      'fatzebraerror',
      'Unable to create payment form, please try again.'
    );

    // @ts-ignore
    window.Rollbar?.critical(
      new Error(`PAYMENT: FatZebra Unable to create payment form`)
    );

    setIsCheckingOut(false);
  }, [setError, setIsCheckingOut]);

  const _formValidation = React.useCallback(
    (event: any) => {
      var message =
        'Payment form incomplete, please check the fields and try again.';
      if (
        event &&
        event.type === 'fz.form_validation.error' &&
        event.detail.errors.length > 0
      ) {
        message = 'Payment form error. ';
        for (var i = 0; i < event.detail.errors.length; i++) {
          message += event.detail.errors[i] + '. ';
        }
      }
      setError('fatzebraerror', message);

      setIsCheckingOut(false);
    },
    [setError, setIsCheckingOut]
  );

  const _tokenizeError = React.useCallback(() => {
    setError(
      'fatzebraerror',
      'Our apologies, but there was an error confirming your card with our payment provider, please try again or contact customer support.'
    );

    setIsCheckingOut(false);
  }, [setError, setIsCheckingOut]);

  const _initiatePaymentError = React.useCallback(() => {
    if (isCheckingOut && !hasInitiatedPayment) {
      setIsCheckingOut(false);
      setShowRefreshModal(true);
    }

    // @ts-ignore
    window.Rollbar?.critical(
      new Error(
        `PAYMENT: FatZebra Unable to process payment - no tokenization.success event received.`
      ),
      error
    );
  }, [
    setIsCheckingOut,
    setShowRefreshModal,
    isCheckingOut,
    hasInitiatedPayment
  ]);

  // Request an access token from the API so we can
  // initialise fatzebra SDK and render the payment
  // form.
  const { data: fzConfig, isLoading: isAccessTokenLoading } = useQuery(
    ['FatZebra', 'Config', payment],
    () => fatZebraConfig(quoteID, policyID),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: response => {
        window.localStorage.setItem('fz-access-token', response.token);
      },
      onError: error => {
        setError('fatzebraerror', 'Unable to authenticate card provider.');

        // @ts-ignore
        window.Rollbar?.critical(
          new Error(`PAYMENT: FatZebra Unable to authenticate card provider.`),
          error
        );
      }
    }
  );

  // To tokenize the card we must verify that the payment
  // intent matches a hash value. This stops the value being
  // manipulated before send on the frontend.
  const { data: verification, isLoading: isVerificationLoading } = useQuery(
    ['FatZebra', 'Verification', verificationProps],
    () => fatZebraPaymentIntentVerification(verificationProps),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: error => {
        setError(
          'fatzebraerror',
          'Unable to verify payment intent. Please try again.'
        );

        // @ts-ignore
        window.Rollbar?.critical(
          new Error(`PAYMENT: FatZebra Unable to authenticate card provider.`),
          error
        );
      }
    }
  );

  const reloadPage = (e: any) => {
    e.stopPropagation();
    location.reload();
  };

  // We need to setup the FatZebra events
  // ready to intercept them when checkout() is called
  // via payNow.
  React.useEffect(() => {
    window.addEventListener('fz.tokenization.success', _payNow);
    window.addEventListener('fz.tokenization.error', _tokenizeError);
    window.addEventListener('fz.validation.error', _validation);
    window.addEventListener('fz.form_validation.error', _formValidation);

    return () => {
      window.removeEventListener('fz.tokenization.success', _payNow);
      window.removeEventListener('fz.tokenization.error', _tokenizeError);
      window.removeEventListener('fz.validation.error', _validation);
      window.removeEventListener('fz.form_validation.error', _formValidation);
    };
  }, [_payNow, _tokenizeError, _validation, _formValidation]);

  // This ensures that if we don't make a call to our payment endpoint after 10 seconds from clicking
  // the Buy Now button, the user is alerted and asked to refresh the page. Quick fix for FatZebra event
  // publishing issue.
  React.useEffect(() => {
    let checkoutTimeout: any;

    if (!checkoutTimeout && isCheckingOut && !hasInitiatedPayment) {
      checkoutTimeout = setTimeout(_initiatePaymentError, 10000);
    }

    if (checkoutTimeout && hasInitiatedPayment) {
      clearTimeout(checkoutTimeout);
    }

    return () => {
      !!checkoutTimeout && clearTimeout(checkoutTimeout);
    };
  }, [isCheckingOut, hasInitiatedPayment]);

  return (
    <>
      <BladeSurface shouldRespectViewWidth={true} title={props.title}>
        {error && (
          <BladeMessage isFlush={true} variant={'error'} message={error} />
        )}

        {props.guide && <BladeGuide markdown={props.guide} />}

        <div className={css.payWithCreditCard_inputs}>
          <div
            className={css.payWithCreditCard_inputs_scaffold}
            style={{ marginBottom: 0 }}
          >
            {(isAccessTokenLoading || isVerificationLoading) && (
              <div className={css.payWithCreditCard_loader}>
                <BladeLoader />
              </div>
            )}

            {fzConfig && verification && (
              <FatZebraPayByCard
                fzConfig={fzConfig}
                onRecoverableError={setError}
                paymentIntent={{
                  payment,
                  verification: verification
                }}
                onReady={fatZebra => {
                  onComplete({
                    fatZebra,
                    payment,
                    verification: verification
                  });

                  if (typeof setIsComplete === 'function') {
                    setIsComplete(true);
                  }
                }}
              />
            )}
          </div>
        </div>

        {props.guideFooter && <BladeGuide markdown={props.guideFooter} />}
      </BladeSurface>
      {showRefreshModal && (
        <BladeModal
          title="Error processing payment"
          withFrame={true}
          allowClose={false}
          close={() => setShowRefreshModal(false)}
        >
          <p>{organisationConfiguration?.checkout.errorMessage}</p>
          <BladeButton
            id="btn-refresh-page"
            style={{ marginTop: '50px' }}
            onClick={reloadPage}
          >
            Refresh
          </BladeButton>
        </BladeModal>
      )}
    </>
  );
};

interface IFatZebraWrapperProps {
  payment: IPayment;
  quoteID?: string;
  policyID?: string;
  title?: string;
  verificationProps: IVerification;
  titleWithOptions?: string;
  inputVariant?: BladeInputVariant;
  guide?: string;
  guideFooter?: string;
  onPayNow: (token?: string) => void;
  onComplete: (paymentRequest: IFatZebraPaymentRequest) => void;
  setIsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckingOut: (toggle: boolean) => void;
  isCheckingOut: boolean;
  error?: string | undefined;
  fatZebraConfig: (
    quoteID?: string,
    policyID?: string
  ) => Promise<IFatZebraConfigResponse>;
  fatZebraPaymentIntentVerification: (
    props: IFatZebraVerificationProps
  ) => Promise<IFatZebraVerificationResponse>;
}
