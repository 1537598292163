export function isQuestionAnswered(answer: any): boolean {
  // This conditional will consider a question to be unanswered
  // if it is undefined or null. This is to make sure we don't consider
  // 0 or false to be caught in a falsy check, which are valid answers.

  if (answer != null) {
    return true;
  }

  return false;
}
