import { ITobesQuestion } from '@aventus/platform-client-context/models/tobes';
import moment from 'moment';

export const runFieldValidation = (
  value: unknown,
  question: ITobesQuestion
): string[] => {
  switch (question.questionType) {
    case 'datedropdownfuture':
    case 'datedropdownpast':
      if (
        (moment.isMoment(value) && value.isValid()) ||
        (typeof value === 'string' &&
          moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid())
      ) {
        break;
      } else {
        return ['Please enter a valid date.'];
      }
    case 'inputdate':
      if (
        (moment.isMoment(value) && !value.isValid()) ||
        (typeof value === 'string' &&
          !moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid())
      ) {
        return ['Please enter a valid date.'];
      }
      break;
    // case "universaladdress":
    //   if (question.validation.find(x => x.referenceID === 'required') &&
    //     (value === '' || value == null)) {
    //     return ['Please enter an address']
    //   }
    //)
  }

  if (question.objectType === 'UniversalAddress') {
    // This adds validation to the top level question field
    // if the child address fields are required.
    const requiredAddressFields = question.fields.some(field => {
      return field.validation.find(
        validator =>
          validator.referenceID === 'required' && !field.existingValue
      );
    });

    if (requiredAddressFields) {
      return ['Please enter an address'];
    }
  }

  return [];
};
