import * as React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BladeBadge: React.FunctionComponent<IBladeBadge> = props => {
  return (
    <div
      onClick={() => {
        props.onClick && props.onClick();
      }}
      className={cx('blade-badge', css.badge, props.className)}
    >
      <label>{props.label}</label>
    </div>
  );
};

export interface IBladeBadge {
  label: string;
  className?: string;
  onClick?: () => void;
}
