import React from 'react';
import { VoucherInfo } from '@aventus/platform';
import { PricingSet } from '@aventus/platform';
import { BladeInputTobesVoucher } from './tobes-voucher';
import { BladeInputCheckoutVoucher } from './checkout-voucher';

export const BladeInputVoucher: React.FunctionComponent<IBladeInputVoucher> = props => {
  const isTobes = !props.quoteID;

  if (isTobes) {
    return <BladeInputTobesVoucher {...props} />;
  } else if (!isTobes) {
    return <BladeInputCheckoutVoucher {...props} />;
  } else {
    return null;
  }
};

export interface IBladeInputVoucher extends IBladeInput {
  risk: any;
  tobesState?: any;
  fieldsetProps?: any;
  quoteID?: string | undefined;
  description?: Nullable<string> | undefined;
  inputVariant?: any;
  voucherValues?: {
    voucherInfo?: VoucherInfo;
    voucherError?: string;
  };
  voucherActions?: {
    resetVoucher?: () => void;
    applyVoucher?: (voucher: string, quoteID: string) => void;
    removeVoucher?: (quoteID: string) => void;
  };
  voucherConfiguration?: {
    btnApply: string;
    btnReset: string;
    btnRemove: string;
    voucherAppliedText: string;
  };
  setRiskHash?: (value: string) => void;
  checkoutCallBack?: (updatedPricing: PricingSet) => void;
}
