import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationError } from '@aventus/errors';
import { DetailsOfCover } from '@aventus/mvmt-product';
import {
  BladeView,
  BladeSurface,
  BladeMarkdown,
  BladeButton
} from '@aventus/blade';
import { useTrack, useTrackView } from '@aventus/application-tracking';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useGetProduct } from '@aventus/symphony-client/hooks';
import { useDocumentTitle } from '@aventus/pockethooks';
import css from './index.css';

export const VriMissingData: React.FunctionComponent<IVriMissingData> = props => {
  useTrackView('VriMissingData');
  useDocumentTitle(props.documentTitle);

  const productReference: string = queryParamGet(
    props.location.search,
    'productReference'
  );

  const productCoverReference: string = queryParamGet(
    props.location.search,
    'productCoverReference'
  );

  if (!productReference || !productCoverReference) {
    throw new ApplicationError(
      '`VriMissingData` is trying to fetch the required configuration, but no productReference or productCoverReference was found'
    );
  }

  const { track } = useTrack();

  React.useEffect(() => {
    track({
      event: 'aventus.vri.missingData',
      productReference,
      productCoverReference
    });
  }, []);

  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );

  const { product } = useGetProduct(productReference, productCoverReference);

  if (productConfiguration && product) {
    return (
      <BladeView variant={'s'} className={css.vriMissingData}>
        <BladeSurface shouldRespectViewWidth={true}>
          <div className={css.vriMissingData_description}>
            <BladeMarkdown
              markdown={productConfiguration.quote.vri.missingData}
            />
          </div>

          <div className={css.vriMissingData_callToAction}>
            <BladeButton
              onClick={() =>
                props.goToQuoteStart(productReference, productCoverReference)
              }
            >
              {productConfiguration.quote.vri.callToAction || 'Continue'}
            </BladeButton>
          </div>
        </BladeSurface>

        <DetailsOfCover
          product={product}
          coverConfiguration={productConfiguration.product.cover}
          shouldRespectViewWidth={true}
        />
      </BladeView>
    );
  }

  return null;
};

export interface IVriMissingData extends RouteComponentProps {
  goToQuoteStart: (
    productReference: string,
    productCoverReference: string
  ) => void;
  documentTitle?: string;
}
