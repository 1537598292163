import * as React from 'react';
import { BladeAskAllTogether } from './all-together';
import { BladeAskOneByOne } from './one-by-one';
import { BladeAskCustomise } from './customise';
import { BladeAskCustomisePanels } from './customise-panels';
import { useRunner } from '../runner';

export const BladeAsk: React.FunctionComponent<IBladeAsk> = props => {
  const { ask } = useRunner();

  switch (props.variant || ask || 'alltogether') {
    case 'alltogether':
      return <BladeAskAllTogether {...props} />;

    case 'onebyone':
      return <BladeAskOneByOne {...props} />;

    case 'customise':
      return <BladeAskCustomise {...props} />;

    case 'customise-panels':
      return <BladeAskCustomisePanels {...props} />;

    default:
      return null;
  }
};

export interface IBladeAsk {
  variant?: BladeAskVariant;
  getQuestionsToRender: (
    validation: any,
    dispatchValidation: any
  ) => Array<React.ReactNode>;
  getSubmitToRender: (isFormValid: boolean) => React.ReactNode;
  shouldRespectViewWidth?: boolean;
  children?: React.ReactNode;
}

export type BladeAskVariant =
  | 'alltogether'
  | 'onebyone'
  | 'customise'
  | 'customise-panels';
