import * as React from 'react';

export const BladeInput_Label: React.FunctionComponent<IBladeInput_Label> = (props) => (
  props.label
    ? <label
        htmlFor={ props.id || undefined }>
        { props.label }
      </label>
    : null
);

interface IBladeInput_Label {
  id?: string,
  label?: Nullable<string>
}
