import { ROW_COL } from './common';

export const ROW = {
  display: 'flex',
  flexDirection: 'row',

  '& > *': {
    flexShrink: 1
  },

  '&.center-vertical': {
    alignItems: 'center'
  },

  '&.center-horizontal': {
    justifyContent: 'center'
  },

  '&.wrap': {
    flexWrap: 'wrap'
  },

  ...ROW_COL
}
