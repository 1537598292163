import * as React from 'react';

export const ApplicationTimezoneContext = React.createContext<ApplicationTimezone | undefined>(undefined);

export interface ApplicationTimezone {
  orgTimezone?: string,
  formats?: {
    human?: string,
    application?: string,
    platform?: string
  }
}
