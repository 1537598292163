interface IUrlQueryParams {
  [key: string]: string | undefined | null;
}

/**
 * Builds a query string for URL.
 *
 * Params object key should be the same as the query key.
 * Value should be the query value.
 *
 * @param params URL params
 * @returns string
 */
export function buildURLQueryString(params: IUrlQueryParams): string {
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => `${key.trim()}=${params[key]?.trim()}`)
    .join('&')
    .trim();

  return queryString ? `?${queryString}` : '';
}
