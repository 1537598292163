import { useEffect, useState } from 'react';

export const useMediaQuery: IUseMediaQuery = (query: string) => {

  const [ matchesQuery, setMatchesQuery ] = useState<boolean>(() => window.matchMedia(query).matches);

  useEffect(() => {

    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setMatchesQuery(!!mql.matches);
    };

    mql.addListener(onChange);
    setMatchesQuery(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };

  }, [ query ]);

  return {
    matchesQuery
  };

};

export type IUseMediaQuery = (
  query: string
) => {
  matchesQuery: boolean
};
