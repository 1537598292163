import React from 'react';
import {
  BladeLink,
  BladeMessage,
  BladePage,
  BladeSurface
} from '@aventus/blade';
import { Scaffold } from '@honey/consume-react-jss';
import appApi from '../../../api';
import routes from '../../../routes';
import track from 'react-tracking';
import { updateProductRef } from '@aventus/pocketknife/dispatch-event';
import { RouteComponentProps } from 'react-router';
import { AuthenticationConfiguration } from '@aventus/platform';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import css from './index.css';

class CreateAccount extends React.Component<any> {
  success: { message: string; detail?: string | undefined } | undefined;
  authenticationConfig: AuthenticationConfiguration;
  description?: string;

  constructor(props: ICreateAccount) {
    super(props);
    this.onBackToSignIn = this.onBackToSignIn.bind(this);

    this.success = props.location.state?.success;
    this.authenticationConfig =
      this.props.app.core.customConfig.userInterface.authentication;

    this.description = this.authenticationConfig.postPurchaseAccountCreation
      .description
      ? stringReplacer(
          this.authenticationConfig.postPurchaseAccountCreation.description,
          {
            $$_emailAddress: props.location.state?.emailAddress || ''
          }
        )
      : undefined;
  }

  onBackToSignIn() {
    this.props.handleAuthClearAllFlags();
    this.props.history.push(routes.signIn.path);
  }

  componentDidMount() {
    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.createAccount.trackingName
    });

    this.props.tracking.trackEvent({
      event: 'aventus.signup.postpurchase'
    });

    updateProductRef(null);
  }

  render() {
    return (
      <BladePage title={routes.createAccount.documentTitle}>
        <Scaffold orient={'col'} size={'large'}>
          {this.success && (
            <BladeMessage
              variant={'success'}
              icon={'faCheck'}
              message={this.success.message}
              detail={this.success.detail}
            />
          )}

          <BladeSurface
            title={
              this.authenticationConfig?.postPurchaseAccountCreation.title ||
              'Create your account'
            }
            description={this.description}
          >
            <div className={css.linkContainer}>
              <BladeLink onClick={this.onBackToSignIn}>
                {this.authenticationConfig?.signIn?.backToSignInText}
              </BladeLink>
            </div>
          </BladeSurface>
        </Scaffold>
      </BladePage>
    );
  }
}

export default appApi(track({})(CreateAccount));

export interface ICreateAccount
  extends RouteComponentProps<
    {},
    {},
    {
      emailAddress?: string;
      success?: {
        message: string;
        detail?: string;
      };
    }
  > {}
