import React from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configure';
import { subscribeStoreToLocalStorage } from './store/local-storage';
import { Initialiser } from 'homelyfe/modules';
import App from './app';
import '@aventus/css-reset';

import localConfiguration from './configuration.json';
const appConfig =
  process.env.NODE_ENV !== 'production' ? localConfiguration : window.hlfConfig;

const logReactErrorRollbar = function (error, errorInfo) {
  if (window.Rollbar && window.Rollbar.error) {
    window.Rollbar.error(error, errorInfo);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const history = createBrowserHistory();
const store = configureStore(history, appConfig);

subscribeStoreToLocalStorage(store);
store.dispatch({ type: 'APP_CONFIGURE_APP', config: appConfig });

const ProductApp = () => (
  <App
    appContent={
      <Initialiser
        // eslint-disable-next-line no-undef
        appVersion={__PKG_VERSION__}
        errorCallback={logReactErrorRollbar}
      />
    }
  />
);

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={ProductApp} />
      </ConnectedRouter>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
