export function isObjectEmpty (object: object): boolean {

  if (object == null) {
    return true;
  }

  return (
    Object
      .values(object)
      .every(prop => prop === null)
  );

}
