import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { PolicyAddon } from '@aventus/platform';

export const useGetPolicyAddons = (policyId: string | undefined) => {
  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();

  const [policyAddons, setPolicyAddons] = React.useState<
    PolicyAddon[] | undefined
  >(undefined);
  const [isGettingPolicyAddons, setIsGettingPolicyAddons] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setIsGettingPolicyAddons(true);

        try {
          const _getAddons = await symphonyClient.getPolicyAddons(policyId);
          setPolicyAddons(_getAddons);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyAddons(false);
      }
    })();
  }, [policyId]);

  return {
    policyAddons,
    isGettingPolicyAddons
  };
};
