import * as React from 'react';
import { BladeFormFieldset, IBladeFormFieldset } from '../index';
import { BladeInputRadio, IBladeInputRadio } from '../../input/radio';
import css from './index.css';

export const BladeFormFieldsetRadio: React.FunctionComponent<IBladeFormFieldsetRadio> = props => {
  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      descriptionPages={props.descriptionPages}
      help={props.help}
      tooltip={props.tooltip}
    >
      <div className={css.radio}>
        {props.options?.map((option: IBladeRadioOption, i: number) => (
          <BladeInputRadio
            variant={props.variant}
            label={option.label}
            key={option.name}
            name={option.name}
            id={option.name + `-${i}`}
            value={props.value}
            item={option.item}
            onChange={props.onChange}
            validate={props.validate}
            onValidate={props.onValidate}
            error={i === 0 ? props.error : undefined}
          />
        ))}
      </div>
    </BladeFormFieldset>
  );
};

export interface IBladeFormFieldsetRadio
  extends IBladeFormFieldset,
    IBladeInputRadio {
  options?: BladeRadioOptions;
}

export type BladeRadioOptions = Array<IBladeRadioOption>;

export interface IBladeRadioOption {
  label: string;
  name: string;
  item: any;
}
