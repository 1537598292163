import { createRefKey } from '..';
import {
  TobesQuestionPageState,
  TobesQuestionState
} from '../../store/form-state';

export const clearFieldValidationWithKey = (
  state: TobesQuestionPageState,
  fieldKey: string
) => {
  const mapFn = (
    questions: TobesQuestionState[],
    key: string,
    parentReferenceID?: string
  ): TobesQuestionState[] => {
    return questions.map(question => {
      const match = createRefKey(question.referenceID, parentReferenceID);

      if (match === key) {
        return {
          ...question,
          validationErrors: []
        };
      }

      return {
        ...question,
        children: mapFn(question.children, key, match)
      };
    });
  };

  state.validationErrorMap.delete(fieldKey);

  return {
    ...state,
    questions: mapFn(state.questions, fieldKey),
    validationErrorMap: new Map(state.validationErrorMap)
  };
};
