import * as React from 'react';
import ReactSVG from 'react-svg';
import { BladeIcon } from '../../icon';
import { IBladeCallToAction } from '../index';
import css from './index.css';
import cx from 'classnames';

export const BladeCallToActionVertical: React.FunctionComponent<IBladeCallToAction> = props => {
  const isImageSvg: boolean =
    props.labelImage && props.labelImage.includes('.svg') ? true : false;

  return (
    <div
      className={cx('blade-call-to-action-vertical', css.callToAction)}
      onClick={() => {
        if (!props.disabled) {
          props.action();
        }
      }}
    >
      {props.labelImage && !isImageSvg && (
        <img className={css.callToAction_image} src={props.labelImage} />
      )}

      {props.labelImage && isImageSvg && (
        <ReactSVG className={css.callToAction_image} src={props.labelImage} />
      )}

      {!props.labelImage && props.labelIcon && (
        <BladeIcon className={css.callToAction_icon} name={props.labelIcon} />
      )}

      <div className={css.callToAction_titles}>
        <h4>{props.title}</h4>

        {props.subTitle && <label>{props.subTitle}</label>}
      </div>

      {props.actionIcon && <BladeIcon name={props.actionIcon} />}
    </div>
  );
};
