import * as React from 'react';
import { IBladeAppFooter } from '../';
import { BladeMarkdown } from '../../markdown';
import css from './index.css';

export const CustomLpoc: React.FC<IBladeAppFooter> = props => {
  return (
    <div className={css.lpocFooter}>
      <div className={css.lpocFooter_content}>
        {props.links && props.links.length > 0 && (
          <ul className={css.lpocFooter_content_links}>
            {props.links.map((link, k) => (
              <li key={k}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}

            {props.isAuthorised && (
              <li>
                <a onClick={() => props.changePassword?.()}>CHANGE PASSWORD</a>
              </li>
            )}
          </ul>
        )}

        {props.regulatoryStatement && (
          <div className={css.lpocFooter_content_regStatement}>
            <BladeMarkdown markdown={props.regulatoryStatement} />
          </div>
        )}
      </div>
    </div>
  );
};
