import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { OrganisationConfiguration } from '@aventus/configuration';

export const useGetPaymentAuthToken: UseGetPaymentToken = function (
  organisationConfiguration,
  quoteId
) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [hasPaymentAuthToken, sethasPaymentToken] = React.useState<boolean>(symphony.isUserAuthenticated());

  React.useEffect(() => {
    (async function () {
      if (organisationConfiguration && quoteId && !hasPaymentAuthToken){
        if (organisationConfiguration.checkout?.postPurchaseSignup !== true){
          // If not enabled in orgConfig, return true to allow checkout to load
          sethasPaymentToken(true);
        }
        else {
          try {
            await symphony.getPaymentToken(quoteId);
            sethasPaymentToken(true);
          } catch (error) {
              throwError(error);
          }
        }
      }

    }());
  }, [ organisationConfiguration, quoteId ]);

  return {
    hasPaymentAuthToken
  };

}

export type UseGetPaymentToken = (
  organisationConfiguration?: OrganisationConfiguration,
  quoteId?: string,
) => {
  hasPaymentAuthToken: boolean
}
