import moment from 'moment';

export function dateMustBeBetweenNowAnd30Days (value: string): string | undefined {

  const today = moment();
  const maxDate = moment().add(31, 'days');
  const currentValue = moment(value);

  const isTodayOrAfter = currentValue.isSameOrAfter(today, 'day');
  const isBeforeMaxDays = currentValue.isBefore(maxDate, 'day');

  return (
    isTodayOrAfter && isBeforeMaxDays
      ? undefined
      : 'The date must be within the next 30 days'
  )
}
