import { Policy } from '@aventus/platform';
import { BinderConfiguration } from '../interface/binder';
import { Lifecycles } from '../interface/lifecycles';

export const adjustNowFatZebra: AdjustNowFatZebra = async (
  paymentConfiguration,
  lifecycles,
  setIsCheckingOut,
  onSuccess,
  onRecoverableError,
  cardToken
) => {
  setIsCheckingOut(true);
  switch (paymentConfiguration.adjustmentType) {
    case 'FixedSingleNoFee':
    case 'FixedSingleRefund':
      // Process MTA with no payment required. Simply call the endpoint and return the policy.
      lifecycles
        .onFatZebraMidTermAdjustmentRefund(paymentConfiguration.quoteID)
        .then(response => {
          setIsCheckingOut(false);
          onSuccess(response);
        })
        .catch((error) => {
          let extraErrorInfo = ""

          if(error.response.data && error.response.data.paymentError){
            extraErrorInfo = ` Your bank returned this message: ${error.response.data.paymentError.providerErrorMessage}`
          }

          onRecoverableError(
            'fatzebraerror',
            `Our apologies, there was an error confirming your adjustment, please try again or contact customer support. ${extraErrorInfo}`
          );

          setIsCheckingOut(false);
        });
      break;
    case 'FixedSinglePayment':
      if (cardToken) {
        lifecycles
          .onFatZebraAnnualPurchase({
            quoteID: paymentConfiguration.quoteID,
            paymentPlanReferenceID: paymentConfiguration.paymentPlanReferenceID,
            encryptedDiscount: paymentConfiguration.encryptedDiscount,
            tokenisedCard: cardToken
          })
          .then(response => {
            setIsCheckingOut(false);
            onSuccess(response);
          })
          .catch((error) => {
            let extraErrorInfo = ""
            if(error.response.data && error.response.data.paymentError){
              extraErrorInfo = ` Your bank returned this message: ${error.response.data.paymentError.providerErrorMessage}`
            }
            onRecoverableError(
              'fatzebraerror',
              `Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support. ${extraErrorInfo}`
            );
            setIsCheckingOut(false);
          });
      }
      // This one will require us to take a payment. We use the same endpoint as new business single payments.
      break;
    case 'SubscriptionNoFee':
    case 'SubscriptionPayment':
    case 'SubscriptionRefund':
      // Subscriptions can be handled behind the scenes.
      lifecycles
        .onFatZebraSubscriptionMTA(paymentConfiguration.quoteID)
        .then(response => {
          setIsCheckingOut(false);
          onSuccess(response);
        })
        .catch((error) => {
          let extraErrorInfo = ""
          if(error.response.data && error.response.data.paymentError){
            extraErrorInfo = ` Your bank returned this message: ${error.response.data.paymentError.providerErrorMessage}`
          }

          onRecoverableError(
            'fatzebraerror',
            `Our apologies, there was an error confirming your adjustment, please try again or contact customer support. ${extraErrorInfo}`
          );
          setIsCheckingOut(false);
        });
      break;
    default:
    // Any other methods are not supported by FatZebra at this time.
  }
};

type AdjustNowFatZebra = (
  paymentConfiguration: BinderConfiguration,
  lifecycles: Lifecycles,
  setIsCheckingOut: (loading: boolean) => void,
  onSuccess: (policy: Policy) => void,
  onRecoverableError: (id: string, message: string) => void,
  cardToken?: string
) => void;
