export const TYPOGRAPHY_TYPE:string = 'typography';

export function typographyMapper(rest:any, h:ITypography):ITypographyMap {
  return {
    ...rest,
    ...{
      [h.key]: {
        fontSize: `${ h.size }px`,
        color: h.color,
        fontWeight: h.weight,
        ...h.capitalisation ? { textTransform: h.capitalisation }: {}
      }
    }
  };
}

export interface ITypography {
  key:string
  size:number
  color:string
  weight:string
  capitalisation?:string
}

export interface ITypographyMap {
  [key:string]: {
    size?:string
    color?:string
    weight?:string
    capitalisation?:string
  }
}
