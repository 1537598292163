import * as React from 'react';
import { BladeViewVariant } from '../view';
import { useRunner } from '../runner';

import { Default } from './default';
import { CustomLpoc } from './custom-lpoc';

export const BladeAppBar: React.FC<IBladeAppBar> = props => {

  const { header } = useRunner();

  switch (props.overrideVariant || header) {

    case 'custom-lpoc':
      return <CustomLpoc {...props} />;

    case 'default':
    default:
      return <Default {...props} />;

  }

}

export interface IBladeAppBar {
  /** Override with any custom styles by passing in custom classes */
  className?: string;
  /** Space the individual renders from each other and within the frame. */
  size?: string;
  /** Renders content left aligned in the app bar. */
  renderLeft?: React.ReactChild;
  /** Renders content in the middle of the app bar. */
  renderCenter?: React.ReactChild;
  /** Renders content right aligned in the app bar. */
  renderRight?: React.ReactChild;
  /** Renders an optional phone number on top of the burger menu if defined */
  phone?: {
    label: string;
    number: string;
    ctmNumber? : string;
    ctmLabel? : string;
    iSelectNumber? : string;
    iSelectLabel? : string;
  };
  /** Passes on the variant to BladeView */
  viewVariant?: BladeViewVariant;
  /** Flag to identify when a user has signed in or not */
  isAuthorised?: boolean;
  signIn?: () => void;
  signOut?: () => void;
  /** In the case that this component is used directly for other uses, the inherited variant can be overriden through this */
  overrideVariant?: BladeHeaderVariant;
}
