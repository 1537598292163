import * as React from 'react';
import { BladeSurface } from '@aventus/blade';
import { BladeList } from '@aventus/blade';
import { getPolicyPremiumBreakdown } from '../../services/get-policy-premium-breakdown';
import { PolicyBundle } from '@aventus/platform';
import { InsuranceProductConfiguration } from '@aventus/configuration/product';
import MonthlyDisclaimer from '../monthly-disclaimer';

export const PolicyPremiumBreakdown: React.FunctionComponent<IPolicyPremiumBreakdown> = props => {
  return (
    <>
      <BladeSurface
        title={'Your payment breakdown'}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
      >
        <BladeList
          isFlush={true}
          shouldRespectViewWidth={props.shouldRespectViewWidth}
          list={getPolicyPremiumBreakdown(
            props.policyBundle,
            props.productConfiguration.product.addons,
            props.productConfiguration.pricing.paymentPlans,
            props.productConfiguration.pricing.basePremiumLabel
          )}
        />
      </BladeSurface>
      {(props.policyBundle.totalPaid.paymentPlanType !==
        'FixedTermSinglePayment' && props.policyBundle.totalPaid.paymentPlanType !== 'SinglePayment') && (
        <MonthlyDisclaimer
          totalPaid={props.policyBundle.totalPaid}
          additionalProducts={props.policyBundle.additionalProducts}
          pricingConfiguration={props.productConfiguration.pricing}
          shouldRespectViewWidth={props.shouldRespectViewWidth}
        />
      )}
    </>
  );
};

interface IPolicyPremiumBreakdown {
  policyBundle: PolicyBundle;
  productConfiguration: InsuranceProductConfiguration;
  shouldRespectViewWidth?: boolean;
}
