import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../alerts/queue-alert/actions';
import { SEARCH_ADDRESS_LOOKUP_REQUEST, SEARCH_ADDRESS_LOOKUP_SUCCESS, SEARCH_ADDRESS_LOOKUP_FAILURE } from './types';

import clearAddressesAction from '../clear-address-lookup/actions';

export default function searchAddressLookupAction(searchTerm, productId) {

  if (searchTerm == '') {
    clearAddressesAction();
    return false;
  }

  return (dispatch, getState) => {
    const encryptKey = getState().app.core.addressLookup.data.encryptKey || '';
    dispatch({
      type: 'API_REQUEST',
      meta: { debounce: 'quick' },
      [API_SYM]: {
        requestConfig: {
          url: 'lookup/address',
          method: 'POST',
          data: {
            query: searchTerm,
            state: encryptKey,
            productId
          }
        },
        actions: [searchAddressLookupRequestAction, searchAddressLookupSuccessAction, searchAddressLookupFailureAction]
      }
    })
  }
}

export function searchAddressLookupRequestAction() {
  return {
    type: SEARCH_ADDRESS_LOOKUP_REQUEST,
  }
}

export function searchAddressLookupSuccessAction(res) {
  const { results, state } = res;
  return {
    type: SEARCH_ADDRESS_LOOKUP_SUCCESS,
    results: results,
    encryptKey: state
  };
}

export function searchAddressLookupFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_ADDRESS_LOOKUP_FAILURE,
    });

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}