import _isNumber from 'lodash/isNumber';
import _toDecimal from './_to-decimal';

export function valueGet (value: number): string {

  if (value === null || value === undefined) {
    return '';
  }

  if (!isNaN(value)) {

    const _value = _toDecimal(value);
    if (_value) {
      return _value.toLocaleString();
    }

  }

  return value.toString();
}
