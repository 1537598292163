import { IntelligentQuoteQuestionValidationRules } from '@aventus/platform';
import { builtInInputDateValidator } from './invalid-date';
import { ToApplicationReadable } from '@aventus/application-timezone';
import { filter } from 'lodash';
import moment from 'moment';

export function isValidDateRange(
  from: string,
  to: string,
  format: string,
  validationRules : IntelligentQuoteQuestionValidationRules[],
  toApplicationReadable : ToApplicationReadable
): string | boolean {

    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    // basic date validation first
    var dateValidationFrom = builtInInputDateValidator(from, format, toApplicationReadable);
    if (dateValidationFrom !== undefined){
        return dateValidationFrom;
    }

    var dateValidationTo = builtInInputDateValidator(to, format, toApplicationReadable);
    if (dateValidationTo !== undefined){
        return dateValidationTo;
    }

    var fromMoment = moment(from, dateFormat);
    var toMoment = moment(to, dateFormat);

    if (fromMoment.isAfter(toMoment)){
        return 'The end date must be after ' + fromMoment.format(format);
    }

    const minDateMatch = filter(validationRules, s => s.indexOf('min:') !== -1);
    if (minDateMatch.length > 0) {
        const minDate = moment(minDateMatch[0].replace('min:', ''), dateFormat);

        if (minDate.isAfter(fromMoment)){
            return 'The start date must be after ' + minDate.format(format);
        }
    }
      
    const maxDateMatch = filter(validationRules, s => s.indexOf('max:') !== -1);
    if (maxDateMatch.length > 0) {
        const maxDate = moment(maxDateMatch[0].replace('max:', ''), dateFormat);

        if (maxDate.isBefore(fromMoment)){
            return 'The start date must be before ' + maxDate.format(format);
        }
    }

    const maxRangeMatch = filter(validationRules, s => s.indexOf('maxDateRange:') !== -1);
    if (maxRangeMatch.length > 0) {
        const maxRangeString = maxRangeMatch[0].replace('maxDateRange:', '');

        var maxToDate = fromMoment.add(maxRangeString, 'days');

        if (maxToDate.isBefore(toMoment)){
            return 'The end date must be before ' + maxToDate.format(format);
        }
    }
      
    return true;
}

