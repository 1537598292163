import { PaymentProvider } from '@aventus/mvmt-checkout/interface/payment-provider';
import {
  BinderConfiguration,
  BinderPaymentProvider
} from '@aventus/mvmt-checkout/interface/binder';
import { Lifecycles } from '@aventus/mvmt-checkout/interface/lifecycles';
import BNPError from '../errors/bnp-error';
import { friendlyStripeErrorMessage } from '@aventus/payment-provider-stripe';
import { Policy } from '@aventus/platform';
import { setTimeoutAsync } from '@aventus/pocketknife/set-timeout-async';

class BNPPayment implements PaymentProvider {
  private paymentID: string | undefined;
  private provider: BinderPaymentProvider;
  private configuration: BinderConfiguration;
  private lifecycles: Lifecycles;
  private errorHandler: (error: Error) => void;
  private recoverableErrorHandler: (
    identifier: string,
    message: string
  ) => void;

  public initialised: boolean = false;
  private pollThreshold: number = 5;

  constructor(
    provider: BinderPaymentProvider,
    configuration: BinderConfiguration,
    lifecycles: Lifecycles,
    errorHandler: (error: Error) => void,
    recoverableErrorHandler: (identifier: string, message: string) => void
  ) {
    this.provider = provider;
    this.configuration = configuration;
    this.lifecycles = lifecycles;
    this.errorHandler = errorHandler;
    this.recoverableErrorHandler = recoverableErrorHandler;
  }

  /**
   * Initialise the payment and store paymentID.
   * If a deposit is requied, setup deposit payment.
   */
  private async initialise() {
    try {
      const paymentInitialisation = await this.lifecycles.onInitialise(
        this.configuration.quoteID,
        this.configuration.encryptedDiscount,
        this.configuration.paymentPlanReferenceID,
        this.provider?.payment?.providerRequest
      );

      this.paymentID = paymentInitialisation.paymentID;

      if (
        this.provider.deposit &&
        this.provider.deposit.provider === 'Stripe'
      ) {
        this.stripeDepositConfirmCardPayment(
          paymentInitialisation.externalData.clientSecret
        );
      }

      this.initialised = true;
    } catch (e) {
      this.recoverableErrorHandler(
        'bnperror',
        'Our apologies, we were unable to initialise your payment, please try again or contact customer support.'
      );
      // @ts-ignore
      window.Rollbar?.critical(
        new Error(`INITIALISE PAYMENT: BNP finance provider component`),
        e
      );
    }
    return this.initialised;
  }

  private async stripeDepositConfirmCardPayment(secret: string) {
    const stripe = this.provider.deposit;

    // MTAs have a slightly different confirm object.
    // These can be handled here using this.configuration.quoteType.

    if (stripe) {
      try {
        const cardPaymentResponse =
          await stripe.providerRequest.stripe.confirmCardPayment(secret, {
            payment_method: {
              card: stripe.providerRequest.element
            },
            setup_future_usage: 'off_session'
          });

        if (cardPaymentResponse.error) {
          if (cardPaymentResponse.error?.type === 'card_error') {
            this.recoverableErrorHandler(
              'stripecard',
              friendlyStripeErrorMessage(cardPaymentResponse.error)
            );
            return;
          } else {
            this.recoverableErrorHandler(
              'stripecard',
              'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
            );
            // @ts-ignore
            window.Rollbar?.critical(
              new Error(
                `STRIPE: confirmCardPayment ${cardPaymentResponse.error.code}: ${cardPaymentResponse.error.message}`
              ),
              cardPaymentResponse.error
            );
            return;
          }
        }
      } catch (error) {
        // @ts-ignore
        window.Rollbar?.error(error);

        this.errorHandler(
          new BNPError(
            'Our apologies, we experienced an error adjusting your policy. Please contact customer support.'
          )
        );
      }
    }
  }

  public async pay() {
    if (this.provider.deposit === undefined) {
      return await this.purchase();
    } else {
      const result = await this.initialise();

      if (
        result === false ||
        (!this.paymentID && typeof this.paymentID !== 'string')
      ) {
        this.errorHandler(new BNPError('Payment was not initialised properly'));
      }
      // Polling logic here.
      return await this.pollPlatform(0);
    }
  }

  private async purchase() {
    try {
      const response =
        await this.lifecycles.onCreateBNPFinanceAgreementNoDeposit({
          ...{
            quoteID: this.configuration.quoteID,
            paymentID: this.paymentID,
            paymentPlanReferenceID: this.configuration.paymentPlanReferenceID,
            encryptedDiscount: this.configuration.encryptedDiscount
          },
          ...this.provider?.payment?.providerRequest
        });

      return response.policy;
    } catch (e) {
      this.recoverableErrorHandler(
        'bnperror',
        'Our apologies, we experienced an error whilst creating your finance. Please contact customer support.'
      );
      // @ts-ignore
      window.Rollbar?.critical(
        new Error(
          `BNP FINANCE W/O DEPOSIT: Could not purchase finance agreement`
        ),
        e
      );
    }

    return;
  }

  public async adjust() {
    switch (this.configuration.adjustmentType) {
      case 'FinancePayment':
        // @ts-ignore
        window.Rollbar?.critical(
          new Error(`BNP Finance: FinancePayment not implemented.`)
        );

        this.errorHandler(
          new BNPError(
            'Our apologies, we were unable to adjust your policy, Please contact customer support.'
          )
        );
        return;
      case 'FinanceNoFee':
      case 'FinanceRefund':
      case 'FinanceUpdate':
        try {
          const financePayment = await this.lifecycles.onUpdateFinanceAgreement(
            this.configuration.quoteID
          );

          return financePayment.policy;
        } catch {
          this.recoverableErrorHandler(
            'bnperror',
            'Our apologies, we were unable to adjust your policy, please try again or contact customer support.'
          );
          return;
        }
      default:
        this.errorHandler(new BNPError('Unsupported BNP adjustment type'));
        return;
    }
  }

  private async pollPlatform(counter: number): Promise<Policy | undefined> {
    if (counter < this.pollThreshold && this.paymentID) {
      const response = await this.lifecycles.onPoll(this.paymentID);

      switch (response.paymentStatus) {
        case 'Initialised':
          return await setTimeoutAsync(async () => {
            try {
              return await this.pollPlatform(counter + 1);
            } catch (e) {
              if (e instanceof BNPError) {
                this.errorHandler(e);
              }
              return;
            }
          }, 3000);
        case 'AwaitingSettlement':
        case 'Settled':
          try {
            await this.lifecycles.onCreateBNPFinanceAgreement({
              ...{
                paymentID: this.paymentID,
                paymentPlanReferenceID:
                  this.configuration.paymentPlanReferenceID,
                encryptedDiscount: this.configuration.encryptedDiscount
              },
              ...this.provider?.payment?.providerRequest
            });
          } catch (e) {
            this.recoverableErrorHandler(
              'bnperror',
              'Our apologies, we experienced an error whilst creating your finance. Please contact customer support.'
            );
            // @ts-ignore
            window.Rollbar?.critical(
              new Error(`BNP FINANCE: Could not create finance agreement`),
              e
            );
          }
          return await this.lifecycles.onGetPurchasedPolicy(response.id);
        default:
          throw new BNPError(
            'Our apologies, we experienced an error confirming your payment status. Please contact customer support.'
          );
      }
    } else {
      throw new BNPError(
        'Our apologies, something unexpected has gone wrong. Please try again later or contact support'
      );
    }
  }
}

export default BNPPayment;
