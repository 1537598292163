import update from 'immutability-helper';

export function getActiveLinesOfBusinessRequestReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        _flags: {
          isGettingActiveLinesOfBusiness: { $set: true }
        }
      })
    }
  };
}

export function getActiveLinesOfBusinessSuccessReducer(state, action) {
  const { res } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        activeLinesOfBusiness: { $set: res },
        _flags: {
          $merge: {
            isGettingActiveLinesOfBusiness: false,
            successfullyGotActiveLinesOfBusiness: true
          }
        }
      })
    }
  };
}

export function getActiveLinesOfBusinessFailureReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        _flags: {
          isGettingActiveLinesOfBusiness: { $set: false }
        }
      })
    }
  };
}
