import update from 'immutability-helper';

export default function setNonceReducer(state, action) {
  const { nonce } = action;
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        nonce: { $set: nonce }
      })
    }
  };
}
