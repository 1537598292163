import {
  IntelligentQuoteQuestion,
  PersonName,
  Address,
  AddressInternational
} from '@aventus/platform';
import { QuestionUnhandledType } from '@aventus/errors';
import { getAnswerIQ } from './get-answer-iq';
import { ToHumanReadable } from '@aventus/application-timezone';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';

export function getAnsweredAnswer(
  question: IntelligentQuoteQuestion,
  toHumanReadable: ToHumanReadable,
  returnAsCombined?: boolean
): IAnsweredText {
  const answeredText: string =
    question.text.answeredText || 'This answer needs to be configured ###';

  const answer: any = getAnswerIQ(question);
  let formattedAnswer: string;
  const answerArray: Array<string> = answeredText.split('###');

  switch (question.questionType) {
    case 'Name':
      const name: PersonName = answer;
      formattedAnswer = `${name.firstName} ${name.lastName}`;
      break;

    case 'Address':
      const address: Address = answer;
      formattedAnswer = `${address.address1}, ${address.postalCode}`;
      break;

    case 'YesNo':
      const yesno: boolean = answer;
      formattedAnswer = yesno === true ? 'is' : 'is not';
      break;

    case 'Date':
    case 'MonthYear':
      const date: string = answer;
      formattedAnswer = toHumanReadable(date);
      break;

    case 'Picklist':
      const picklist: string = answer.text;
      formattedAnswer = picklist;
      break;

    case 'Segmented':
      const segmented: string = answer.text;
      formattedAnswer = segmented;
      break;

    case 'Email':
    case 'PhoneNumber':
    case 'Integer':
    case 'String':
    case 'Radio':
      formattedAnswer = answer;
      break;

    case 'Money':
      formattedAnswer = currencyToHumanReadable(answer);
      break;

    case 'MotorWeb':
      formattedAnswer = answer.nvic;
      break;

    case 'Grouped':
      const addressInternational: AddressInternational = answer;
      formattedAnswer = `${addressInternational.line1}, ${addressInternational.postalCode}`;
      break;

    default:
      throw new QuestionUnhandledType(
        `\`getAnsweredAnswer\` is trying to find the answer for a question type as yet unhandled, \`${question.questionType}\``
      );
  }

  if (returnAsCombined) {
    return `${answerArray[0]}${formattedAnswer}${answerArray[1]}`;
  } else {
    return {
      pre: answerArray[0],
      is: formattedAnswer,
      post: answerArray[1]
    };
  }
}

export type IAnsweredText = AnsweredText | string | undefined;

interface AnsweredText {
  pre: string;
  is: string;
  post: string;
}
