export const SCAFFOLD_TYPE: string = 'scaffold';

export function scaffoldMapper(rest:any, h:IScaffold):IScaffoldMap {
  return {
    ...rest,
    ...{
      [h.key]: h.size + 'px'
    }
  };
}

export interface IScaffold {
  key:string
  size:number
}

export interface IScaffoldMap {
  [key:string]:string
}
