import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import getCustomConfigAction from './core/get-custom-config/actions';
import configureAppAction from './core/configure-app/actions';
import updateStateVersionAction from './core/update-state-version/actions';
import updatePrivacyPolicyVersionAction from './core/update-privacy-policy-version/actions';
import getActiveLinesOfBusinessAction from './core/get-active-lines-of-business/actions';
import updateShowWelcomeScreenAction from './core/update-show-welcome-screen/actions';
import updateShowCookiePolicyAction from './core/update-show-cookie-policy/actions';
import assignQuoteToUserAction from './core/guest-quotes/assign-quote-to-user/actions';
import saveQuoteIdAction from './core/guest-quotes/save-quote-id/actions';
import clearQuoteIdsAction from './core/guest-quotes/clear-quote-ids/actions';
import trackAction from './core/tracking/track/actions';
import setExternalIdAction from './core/tracking/set-external-id/actions';
import getExternalId from './core/tracking/get-external-id';
import clearAlertAction from './core/alerts/clear-alert/actions';
import clearAllAlertsAction from './core/alerts/clear-all-alerts/actions';
import queueAlertAction from './core/alerts/queue-alert/actions';
import clearLocalStorageAction from './core/clear-local-storage/actions';
import searchAddressLookupAction from './core/address-lookup/search-address-lookup/actions';
import clearAddressLookupAction from './core/address-lookup/clear-address-lookup/actions';
import updateNetworkStatusAction from './core/update-network-status/actions';
import  setAuthConfigAction from './core/get-auth0-config/actions';
import setAppReady from './core/set-app-ready/actions';
import {
  setRouteGuardAction,
  resetRouteGuardAction
} from './core/set-route-guard/actions';

import clearFlagAction from './auth/clear-flag/actions';
import clearAllFlagsAction from './auth/clear-all-flags/actions';
import signInAction from './auth/sign-in/actions';
import signUpAction from './auth/sign-up/actions';
import resetPasswordAction from './auth/reset-password/actions';
import resetPasswordNewPasswordAction from './auth/reset-password-new-password/actions';
import createAccountPasswordAction from './auth/create-account-password/actions';
import updateCredentialsAction from './auth/update-credentials/actions';
import validateCredentialsAction from './auth/validate-credentials/actions';
import changePasswordAction from './auth/change-password/actions';
import signOutAction from './auth/sign-out/actions';
import socialAuthAction from './auth/social-auth/actions';
import updateMidAuthFlowStateAction from './auth/update-mid-auth-flow-state/actions';
import validateMeAction from './auth/validate-me/actions';
import setNonceAction from './auth/set-nonce/actions';
import { unblockUserAction } from './auth/unblock-user';

import { AUTH_SIGN_UP_SUCCESS } from './auth/sign-up/types';
import { AUTH_VALIDATE_ME_SUCCESS } from './auth/validate-me/types';
import {
  firebaseSignInRequestAction,
  firebaseSignInSuccessAction
} from './auth/firebase/actions';
import { sendSignInLinkAction } from './auth/send-sign-in-link/actions';
import { signInWithLinkAction } from './auth/sign-in-with-link/actions';
import { startUserLoginAction } from './auth/start-user-login/actions';
import { startBasicAuthAction } from './auth/start-basic-auth/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    app: state.app,
    widget: state.widgets.partnerId ? state.widgets : false,
    asyncInitialState: state.asyncInitialState
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleConfigureApp: config => {
      dispatch(configureAppAction(config));
    },
    handleGetCustomConfig: sq => {
      dispatch(getCustomConfigAction(sq));
    },
    handleUpdateStateVersion: () => {
      dispatch(updateStateVersionAction());
    },
    handleUpdatePrivacyPolicyVersion: () => {
      dispatch(updatePrivacyPolicyVersionAction());
    },
    handleGetActiveLinesOfBusiness: () => {
      dispatch(getActiveLinesOfBusinessAction());
    },
    handleUpdateShowWelcomeScreen: value => {
      dispatch(updateShowWelcomeScreenAction(value));
    },
    handleUpdateShowCookiePolicy: value => {
      dispatch(updateShowCookiePolicyAction(value));
    },
    handleTracking: (type, name, properties) => {
      dispatch(trackAction(type, name, properties));
    },
    handleSetExternalId: id => {
      dispatch(setExternalIdAction(id));
    },
    handleClearAlert: index => {
      dispatch(clearAlertAction(index));
    },
    handleClearAllAlerts: () => {
      dispatch(clearAllAlertsAction());
    },
    handleQueueAlert: alert => {
      dispatch(queueAlertAction(alert));
    },
    handleClearLocalStorage: () => {
      dispatch(clearLocalStorageAction());
    },
    handleSearchAddressLookup: (searchTerm, productId) => {
      dispatch(searchAddressLookupAction(searchTerm, productId));
    },
    handleClearAddressLookup: () => {
      dispatch(clearAddressLookupAction());
    },
    handleUpdateNetworkStatus: isConnected => {
      dispatch(updateNetworkStatusAction(isConnected));
    },
    handleSetAuthConfig: (newState) => {
      dispatch(setAuthConfigAction(newState));
    },
    handleSetAppReady: () => {
      dispatch(setAppReady());
    },
    handleAuthUnblockUser: requestId => {
      dispatch(unblockUserAction(requestId));
    },
    handleAuthClearFlag: flagKey => {
      dispatch(clearFlagAction(flagKey));
    },
    handleAuthClearAllFlags: () => {
      dispatch(clearAllFlagsAction());
    },
    handleAuthSignIn: (username, password) => {
      dispatch(signInAction(username, password));
    },
    handleAuthSignUp: (username, password) => {
      dispatch(signUpAction(username, password));
    },
    handleAuthResetPassword: username => {
      dispatch(resetPasswordAction(username));
    },
    handleAuthResetPasswordNewPassword: (password, resetToken) => {
      dispatch(resetPasswordNewPasswordAction(password, resetToken));
    },
    handleCreateAccountPassword: (password, resetToken) => {
      dispatch(createAccountPasswordAction(password, resetToken));
    },
    handleAuthUpdateCredentials: updateCommand => {
      dispatch(updateCredentialsAction(updateCommand));
    },
    handleAuthValidateCredentials: (key, value) => {
      dispatch(validateCredentialsAction(key, value));
    },
    handleAuthChangePassword: (oldPassword, newPassword) => {
      dispatch(changePasswordAction(oldPassword, newPassword));
    },
    handleSilentAuthSignOut: () => {
      dispatch(signOutAction(true));
    },
    handleAuthSignOut: () => {
      dispatch(signOutAction());
    },
    handleAuthSocialAuth: (
      token,
      userDetails,
      accountType,
      authenticationProvider
    ) => {
      dispatch(
        socialAuthAction(
          token,
          userDetails,
          accountType,
          authenticationProvider
        )
      );
    },
    handleFirebaseSignIn: () => {
      dispatch(firebaseSignInRequestAction());
    },
    handleFirebaseSignInComplete: () => {
      dispatch(firebaseSignInSuccessAction());
    },
    handleAuthUpdateMidAuthFlowState: newState => {
      dispatch(updateMidAuthFlowStateAction(newState));
    },
    handleAuthValidateMe: () => {
      dispatch(validateMeAction());
    },
    handleAuthSetNonce: () => {
      dispatch(setNonceAction());
    },
    handleGuestQuotesAssignQuoteToUser: quoteIds => {
      dispatch(assignQuoteToUserAction(quoteIds));
    },
    handleGuestQuotesSaveQuoteId: quoteId => {
      dispatch(saveQuoteIdAction(quoteId));
    },
    handleGuestQuotesClearQuoteIds: () => {
      dispatch(clearQuoteIdsAction());
    },
    handleResetRouteGuard: () => {
      dispatch(resetRouteGuardAction());
    },
    handleSetRouteGuard: () => {
      dispatch(setRouteGuardAction());
    },
    handleSendSignInLink: (email, redirectUrl) => {
      dispatch(sendSignInLinkAction(email, redirectUrl));
    },
    handleSignInWithLink: (
      token,
      userDetails,
      accountType,
      authenticationProvider
    ) => {
      dispatch(
        signInWithLinkAction(
          token,
          userDetails,
          accountType,
          authenticationProvider
        )
      );
    },
    handleStartUserLogin: (email, recaptchaToken) => {
      dispatch(startUserLoginAction(email, recaptchaToken));
    },
    handleStartBasicAuth: () => {
      dispatch(startBasicAuthAction());
    }
  };
};

export default function create(Component) {
  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
}

export {
  configureAppAction as configureApp,
  updateStateVersionAction as updateStateVersion,
  updatePrivacyPolicyVersionAction as updatePrivacyPolicyVersion,
  getActiveLinesOfBusinessAction as getActiveLinesOfBusiness,
  updateShowWelcomeScreenAction as updateShowWelcomeScreen,
  updateShowCookiePolicyAction as updateShowCookiePolicy,
  assignQuoteToUserAction as assignQuoteToUser,
  saveQuoteIdAction as saveQuoteId,
  clearQuoteIdsAction as clearQuoteIds,
  trackAction as track,
  setExternalIdAction as setExternalId,
  getExternalId,
  clearAlertAction as clearAlert,
  clearAllAlertsAction as clearAllAlerts,
  queueAlertAction as queueAlert,
  clearLocalStorageAction as clearLocalStorage,
  searchAddressLookupAction as searchAddressLookup,
  clearAddressLookupAction as clearAddressLookup,
  updateNetworkStatusAction as updateNetworkStatus,
  setAuthConfigAction as setAuthConfig,
  clearFlagAction as clearFlag,
  clearAllFlagsAction as clearAllFlags,
  signInAction as signIn,
  signUpAction as signUp,
  resetPasswordAction as resetPassword,
  resetPasswordNewPasswordAction as resetPasswordNewPassword,
  createAccountPasswordAction as createAccountPassword,
  updateCredentialsAction as updateCredentials,
  validateCredentialsAction as validateCredentials,
  changePasswordAction as changePassword,
  signOutAction as signOut,
  socialAuthAction as socialAuth,
  updateMidAuthFlowStateAction as updateMidAuthFlowState,
  validateMeAction as validateMe,
  setNonceAction as setNonce,
  setAppReady,
  startBasicAuthAction as startBasicAuth,
};

export const types = {
  AUTH_SIGN_UP_SUCCESS,
  AUTH_VALIDATE_ME_SUCCESS
};
