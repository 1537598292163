import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { getAtomValue } from '@aventus/honey-utility';
import css from './index.css';
import cx from 'classnames';

export const BladeLoader: React.FunctionComponent<IBladeLoader> = props => {
  const [primaryColour, setPrimaryColour] = React.useState<string>('');

  React.useEffect(() => {
    const _primaryColour = getAtomValue('--primary');
    _primaryColour && setPrimaryColour(_primaryColour);
  }, []);

  if (!props.isSuccessful) {
    return (
      <BubbleLoader
        className={cx('blade-loader', css.loader, props.className)}
        style={{ margin: '0' }}
        size={'5'}
        color={props.color ?? primaryColour}
      />
    );
  }

  if (props.isSuccessful) {
    return (
      <React.Fragment>
        <div className="svg-container">
          <svg
            className="ft-green-tick"
            xmlns="http://www.w3.org/2000/svg"
            height="100"
            width="100"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <circle
              className={css.circle}
              fill="#5bb543"
              cx="24"
              cy="24"
              r="22"
            />
            <path
              className={css.tick}
              fill="none"
              stroke="#FFF"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M14 27l5.917 4.917L34 17"
            />
          </svg>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

interface IBladeLoader {
  isSuccessful?: boolean;
  className?: string;
  color?: string;
}

export default BladeLoader;
