import { initializeApp, getApp } from "firebase/app";

export default function createFirebaseApp (fbConfig) 
{
    try {
        getApp();
    } catch {
        const firebaseConfig = {
            apiKey: fbConfig.apiKey,
            authDomain: fbConfig.authDomain,
            projectId: fbConfig.projectID,
            messagingSenderId: fbConfig.messagingSenderID,
            appId: fbConfig.appID
        };
        initializeApp(firebaseConfig);
    }
    
}