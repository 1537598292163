export const poweredByAventusAscii = `
POWERED BY:

 █████╗ ██╗   ██╗███████╗███╗   ██╗████████╗██╗   ██╗███████╗
██╔══██╗██║   ██║██╔════╝████╗  ██║╚══██╔══╝██║   ██║██╔════╝
███████║██║   ██║█████╗  ██╔██╗ ██║   ██║   ██║   ██║███████╗
██╔══██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ██║   ██║╚════██║
██║  ██║ ╚████╔╝ ███████╗██║ ╚████║   ██║   ╚██████╔╝███████║
╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝    ╚═════╝ ╚══════╝
`;
