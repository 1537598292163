import React, { useState } from 'react';
import moment from 'moment';
import { SimpleSelect } from '../simpleSelect';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { generateOptions, initialValues } from './helpers';
import cx from 'classnames';
import css from './index.css';

export const BladeInputDateSelect: React.FC<IBladeInputDateSelect> = props => {
  const inputProps = useInput(props);

  const { dayOptions, monthOptions, yearOptions } = generateOptions(
    props.future
  );
  const { initialDay, initialMonth, initialYear } = initialValues(props.value);

  const [day, setDay] = useState(initialDay);
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  const updateDate = (dateString: string): void => {
    const newDate = moment(dateString, 'YYYY-M-D', true);
    inputProps.setHasBeenInteractedWith(true);
    inputProps.onChange(newDate);
  };

  const handleDateChange = (id: number, label: string, part: string) => {
    switch (part) {
      case 'D':
        return setDay(() => {
          updateDate(`${year?.id}-${month?.id}-${id}`);
          return id && label ? { id, label: Number(label) } : null;
        });
      case 'M':
        return setMonth(() => {
          updateDate(`${year?.id}-${id}-${day?.id}`);
          return id && label ? { id, label } : null;
        });
      case 'Y':
        return setYear(() => {
          updateDate(`${id}-${month?.id}-${day?.id}`);
          return id && label ? { id, label: Number(label) } : null;
        });
    }
  };

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />
      <div className={css.date_container}>
        <div className={css.date_input_container}>
          <SimpleSelect
            placeholder="Day"
            options={dayOptions}
            onChange={state => handleDateChange(state?.id, state?.label, 'D')}
            value={day}
            onBlur={inputProps.onBlur}
          />
        </div>
        <div className={css.date_input_container}>
          <SimpleSelect
            placeholder="Month"
            options={monthOptions}
            onChange={state => handleDateChange(state?.id, state?.label, 'M')}
            value={month}
            onBlur={inputProps.onBlur}
          />
        </div>
        <div
          className={cx(
            css.date_input_container,
            css.date_input_container__last
          )}
        >
          <SimpleSelect
            placeholder="Year"
            options={yearOptions}
            onChange={state => handleDateChange(state?.id, state?.label, 'Y')}
            value={year}
            onBlur={inputProps.onBlur}
          />
        </div>
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputDateSelect extends IBladeInput {
  future?: boolean;
}
