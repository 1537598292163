import { IntelligentQuoteQuestion } from '@aventus/platform';
import { isObjectEmpty } from '@aventus/pocketknife/is-object-empty';

// We want to specifically check for null, undefined or "empty" anonymous objects.
// This would allow us to let other valid answers which happen to be falsey in javascript through.
// (false, 0, "", []) are all valid answers but are falsey when handled in a condition.
const isValidAnswer = (obj: any) => {
  if (obj === null) return false;
  if (typeof obj === 'undefined') return false;
  if (typeof obj === 'function') return false;

  // Handle a couple of invalid number types.
  if (typeof obj === 'number') {
    // Something's gone wrong if we have a NaN or Infinity result
    if (obj === NaN) return false;
    if (obj === Infinity) return false;

    return true;
  }

  // Objects are complicated, some are not valid answers
  if (typeof obj === 'object') {
    // Allow arrays as an exemption, as all types of array are valid
    // even empty ones `[]` but are considered objects initially.
    if (Array.isArray(obj)) return true;

    // If the object values are null, don't allow the object. This is mostly
    // a handler for anonymous objects/dictionaries,
    if (isObjectEmpty(obj)) return false;

    return true;
  }

  // TODO: Invert this for better safety?
  // Default as valid;
  return true;
};

export function getAnswerIQ(question: IntelligentQuoteQuestion): any {
  // Order of answer precendence:
  // 1. Value provided by riskValueFromSource
  // 2. defaultAnswer

  if (isValidAnswer(question.riskValueFromSource)) {
    return question.riskValueFromSource;
  } else if (isValidAnswer(question.defaultAnswer)) {
    return question.defaultAnswer;
  } else {
    return undefined;
  }
}
