import update from 'immutability-helper';
import getUpdateCommandForFlagOrFlags from './_get-update-command-for-flag-or-flags';

const defaultState = {
  _flags: {}
};

export default function handleError(state: any = defaultState, action: any, flagKey: string) {

  if (!flagKey || !action || !action.type) {
    return state;
  }

  const flagsUpdateCommand = getUpdateCommandForFlagOrFlags(flagKey, false);

  return {
    ...state,
    _flags: update(state._flags, flagsUpdateCommand)
  };
}
