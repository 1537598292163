import React from 'react';
import appApi from '../../../../api';
import { BladeButtonSocialAuth } from '@aventus/blade';
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult  } from "firebase/auth";
import createFirebaseApp from '../../../../../middleware/firebase/initalizeApp';

class SocialAuthFirebase extends React.Component {
    constructor(props) {
        super(props);

        this.signInWithGoogle = this.signInWithGoogle.bind(this);
    }

    componentDidMount() {
        if (!this.props.app.auth._flags.isFirebaseSigningIn) 
        {
            this.props.handleFirebaseSignIn();

            createFirebaseApp(this.props.app.core.config.auth0.firebase);

            const auth = getAuth();
            if (this.props.app.core.config.auth0.firebase.tenantID) {
                auth.tenantId = this.props.app.core.config.auth0.firebase.tenantID;
            }

            getRedirectResult(auth)
                .then((result) =>{
                    this.props.handleFirebaseSignInComplete();

                    if (result != null && result.user != null){
                        this.props.history.push(`/social-callback#firebase`);
                    }
                })
        }
    }
    

    signInWithGoogle() {

        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('openid');

        const auth = getAuth();
        if (this.props.app.core.config.auth0.firebase.tenantID) {
            auth.tenantId = this.props.app.core.config.auth0.firebase.tenantID;
        }

        signInWithRedirect(auth, provider);
    }

    render() {
        return (
            <BladeButtonSocialAuth
              variant={ this.props.type }
              callToAction={ this.props.label }
              signInFunc={() => this.signInWithGoogle()} />
          );
      }
}

export default appApi(SocialAuthFirebase);