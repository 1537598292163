import React from 'react';
import PropTypes from 'prop-types';
import { guideConsumerJSS } from '../../context-api';
import typoStyles from './typo.styles';

const Typo = (props) => {
  if (props.isInline) {
    return (
      <span
        onClick={ props.onClick }
        style={{ ...props.styles }}
        className={ `honey-typo ${ props.classes.typo } ${ props.classes[props.style] } ${ props.className }` }>
        { props.children }
      </span>
    );
  } else {
    return (
      <div
        onClick={ props.onClick }
        style={{ ...props.styles }}
        className={ `honey-typo ${ props.classes.typo } ${ props.classes[props.style] } ${ props.className }` }>
        { props.children }
      </div>
    );
  }
};

Typo.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.oneOf(['bold', 'italic', 'normal']),
  className: PropTypes.string,
  isInline: PropTypes.bool,
  onClick: PropTypes.func
}

Typo.defaultProps = {
  variant: 'body',
  style: 'normal',
  isInline: false,
  onClick: null
}

export default guideConsumerJSS(Typo)(typoStyles);
