import { Risk, IntelligentQuoteQuestion } from '@aventus/platform';
import { getAnswerIQ } from './get-answer-iq';
import { createUpdateCommand } from './create-update-command';
import { isQuestionAnswered } from './is-question-answered';
import update from 'immutability-helper';

export function updateRiskFromQuestions(
  risk: Risk,
  questions: IntelligentQuoteQuestion[]
): Risk {
  let updatedRisk = { ...risk };

  // Part of dirty hack.

  let isSameAsCorr: boolean = false;

  for (const question of questions) {
    const nests: Array<string> = question.riskPath.split('.');
    const answer: any = getAnswerIQ(question);

    if (isQuestionAnswered(answer)) {
      const updateCommandAction: object = { $set: answer };
      const updateCommand: object = createUpdateCommand(
        nests,
        0,
        updateCommandAction
      );

      // Hack until backend fix this their end.

      if (
        question.referenceID ===
          'hl_q_is_this_the_same_as_your_correspondence_address' &&
        answer === true
      ) {
        isSameAsCorr = true;
        updatedRisk = update(updatedRisk, {
          proposer: { address: { $set: updatedRisk.insuredProperty.address } }
        });
      }

      if (
        question.referenceID === 'hl_q_what_s_your_correspondence_address' &&
        isSameAsCorr
      ) {
        // We dont want to update in this case because of the hack above.
      } else {
        updatedRisk = update(updatedRisk, updateCommand);
      }
    }
  }

  return updatedRisk;
}
