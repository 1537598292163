/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-pascal-case */
import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { BladeIcon } from '../../icon';
import { BladeModal } from '../../modal';
import { BladeAsk } from '../../ask';
import { BladeButton } from '../../button';
import { ITobesQuestionMap } from '@aventus/platform-client-context/models/tobes';
import css from './index.css';
import cx from 'classnames';
import { useState } from 'react';
import uuid from 'uuid/v4';
import { getAnswersPeak } from './helpers/answerHelper';

export const BladeInputCollectionV7: React.FunctionComponent<
  IBladeInputCollectionV7
> = props => {
  const inputProps = useInput(props);
  const [modelIsOpen, setModelIsOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<EFormMode>(EFormMode.NotSet);
  const [formInstanceId, setformInstanceId] = useState<string>('');

  const incomingCollection = Array.isArray(props.tobesQuestion.existingValue)
    ? props.tobesQuestion.existingValue
    : [];

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />
      <div className={cx('blade-input-collection', css.collection)}>
        {incomingCollection.map(({ id, answers }) => (
          <div key={id ? id : ''} className={css.collection_existingItem}>
            <div
              className={cx(
                css.collection_item,
                inputProps.className,
                css.collection_itemHook
              )}
              onClick={() => {
                setModelIsOpen(true);
                setFormMode(EFormMode.Edit);
                setformInstanceId(id !== null ? id : '');
              }}
            >
              <label>{getAnswersPeak(answers, props.arrayPageQuestions)}</label>

              <BladeIcon
                className={css.collection_item_update}
                name={'faPencil'}
              />
            </div>

            <div
              className={cx(
                css.collection_item_delete,
                css.collection_itemHook
              )}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                id &&
                  props.removeFromCollection(
                    id,
                    props.tobesQuestion.referenceID
                  );
                event.stopPropagation();
              }}
            >
              <BladeIcon
                className={css.collection_item_delete_icon}
                name={'faTimes'}
              />
            </div>
          </div>
        ))}

        {(!props.maxNumberOfItems ||
          props.maxNumberOfItems < incomingCollection.length) && (
          <div
            className={cx(css.collection_item)}
            onClick={() => {
              setModelIsOpen(true);
              setFormMode(EFormMode.Add);
              if (formInstanceId === '') {
                setformInstanceId(uuid());
              }
            }}
          >
            <label>
              {inputProps.value && inputProps.value.length > 0
                ? props.addAnotherToCollectionLabel || 'Add'
                : props.addToCollectionLabel || 'Add'}
            </label>

            <BladeIcon className={css.collection_item_add} name={'faPlus'} />
          </div>
        )}

        {modelIsOpen && formMode !== EFormMode.NotSet && (
          <BladeModal
            title={props.collectionTitle ? props.collectionTitle : undefined}
            close={() => {
              setModelIsOpen(false);
              setFormMode(EFormMode.NotSet);
            }}
          >
            <BladeAsk
              variant={'alltogether'}
              getQuestionsToRender={() => props.getQuestions(formInstanceId)}
              getSubmitToRender={(isFormValid: boolean) => (
                <BladeButton
                  isDisabled={!isFormValid}
                  onClick={async () => {
                    const isValid = await props.validateCollectionItem(
                      formInstanceId,
                      props.tobesQuestion.referenceID
                    );

                    if (isValid) {
                      if (formMode === EFormMode.Add) {
                        props.addToCollection(
                          formInstanceId,
                          props.tobesQuestion.referenceID
                        );
                      }
                      if (formMode === EFormMode.Edit) {
                        props.updateItemInCollection(
                          formInstanceId,
                          props.tobesQuestion.referenceID
                        );
                      }
                      setModelIsOpen(false);
                      setformInstanceId('');
                    }
                  }}
                >
                  {formMode === EFormMode.Add
                    ? props.addToCollectionLabel || 'Add'
                    : 'Edit'}
                </BladeButton>
              )}
            />
          </BladeModal>
        )}
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputCollectionV7 extends IBladeInput {
  arrayPageQuestions: ITobesQuestionMap[];
  collectionTitle: string | null;
  addToCollectionLabel: string | null;
  addAnotherToCollectionLabel: string | null;
  itemLabel: string | null;
  itemKey: string;
  maxNumberOfItems?: number;
  questionArrayAnswers: any;
  tobesQuestion: ITobesQuestionMap;
  addToCollection: (formInstanceId: string, questionName: string) => void;
  updateItemInCollection: (
    formInstanceId: string,
    questionName: string
  ) => void;
  removeFromCollection: (formInstanceId: string, questionName: string) => void;
  validateCollectionItem: (
    formID: string,
    parentReferenceID: string
  ) => Promise<boolean>;
  getQuestions: (currentId: string) => any;
}

enum EFormMode {
  NotSet = 'notset',
  Add = 'add',
  Edit = 'edit'
}
