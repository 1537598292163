import { IMolecules } from '@aventus/honey-definitions';

export const honeyMolecularSpacing: IMolecules = [
  {
    type: 'any',
    key: '.space-row',
    atoms: [
      { type: 'any', key: 'display', value: 'flex' },
      { type: 'any', key: 'flex-direction', value: 'row' }
    ]
  },
  {
    type: 'any',
    key: '.space-col',
    atoms: [
      { type: 'any', key: 'display', value: 'flex' },
      { type: 'any', key: 'flex-direction', value: 'column' }
    ]
  },
  {
    type: 'any',
    key: '.space-col-reverse',
    atoms: [
      { type: 'any', key: 'display', value: 'flex' },
      { type: 'any', key: 'flex-direction', value: 'column-reverse' }
    ]
  },
  {
    type: 'any',
    key: '.space-row.center,\n.space-col.center',
    atoms: [
      { type: 'any', key: 'justify-content', value: 'center' },
      { type: 'any', key: 'align-items', value: 'center' }
    ]
  },
  {
    type: 'any',
    key: '.space-row.center-vertical',
    atoms: [{ type: 'any', key: 'align-items', value: 'center' }]
  },
  {
    type: 'any',
    key: '.space-row.space-between',
    atoms: [{ type: 'any', key: 'justify-content', value: 'space-between' }]
  },
  {
    type: 'any',
    key: '.space-row.center-horizontal',
    atoms: [{ type: 'any', key: 'justify-content', value: 'center' }]
  },
  {
    type: 'any',
    key: '.space-col.center-vertical',
    atoms: [{ type: 'any', key: 'justify-content', value: 'center' }]
  },
  {
    type: 'any',
    key: '.space-col.center-horizontal',
    atoms: [{ type: 'any', key: 'align-items', value: 'center' }]
  },
  {
    type: 'any',
    key: '.space-row.small.frame,\n.space-col.small.frame',
    atoms: [{ type: 'any', key: 'padding', value: '--space-small' }]
  },
  {
    type: 'any',
    key: '.space-row.base.frame,\n.space-col.base.frame',
    atoms: [{ type: 'any', key: 'padding', value: '--space-default' }]
  },
  {
    type: 'any',
    key: '.space-row.large.frame,\n.space-col.large.frame',
    atoms: [{ type: 'any', key: 'padding', value: '--space-large' }]
  },
  {
    type: 'any',
    key: '.space-row.frame.inside',
    atoms: [{ type: 'any', key: 'padding-right', value: '0' }]
  },
  {
    type: 'any',
    key: '.space-row.inside:not(.frame) > *:last-child',
    atoms: [{ type: 'any', key: 'margin-right', value: '0!important' }]
  },
  {
    type: 'any',
    key: '.space-row.small.inside > *',
    atoms: [{ type: 'any', key: 'margin-right', value: '--space-small' }]
  },
  {
    type: 'any',
    key: '.space-row.base.inside > *',
    atoms: [{ type: 'any', key: 'margin-right', value: '--space-default' }]
  },
  {
    type: 'any',
    key: '.space-row.large.inside > *',
    atoms: [{ type: 'any', key: 'margin-right', value: '--space-large' }]
  },
  {
    type: 'any',
    key: '.space-col.frame.inside',
    atoms: [{ type: 'any', key: 'padding-bottom', value: '0' }]
  },
  {
    type: 'any',
    key: '.space-col.inside:not(.frame) > *:last-child',
    atoms: [{ type: 'any', key: 'margin-bottom', value: '0!important' }]
  },
  {
    type: 'any',
    key: '.space-col.small.inside > *',
    atoms: [{ type: 'any', key: 'margin-bottom', value: '--space-small' }]
  },
  {
    type: 'any',
    key: '.space-col.base.inside > *',
    atoms: [{ type: 'any', key: 'margin-bottom', value: '--space-default' }]
  },
  {
    type: 'any',
    key: '.space-col.large.inside > *',
    atoms: [{ type: 'any', key: 'margin-bottom', value: '--space-large' }]
  }
];
