import moment from 'moment';

export function min (dynamicValidationValue: string): (value: string) => string | undefined {
  const minDate = moment(dynamicValidationValue);
  return (value) => {
    return (
      moment(value).isSameOrAfter(minDate)
        ? undefined
        : `The date must be after ${ minDate.format('dddd, MMMM Do YYYY, h:mm:ss a') }`
    )
  }
}
