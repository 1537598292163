import * as React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BladeSelector: React.FunctionComponent<IBladeSelector> = props => {
  if (props.options.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(
        css.selector,
        props.isScrollable ? css.selector_isScrollable : ''
      )}
    >
      {props.options.map((option: IOption, i: number) => (
        <div
          key={i}
          onClick={() => props.onSelect(option)}
          className={css.selector_option}
        >
          <label>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export interface IBladeSelector {
  isScrollable?: boolean;
  options: IOption[];
  onSelect: (option: IOption) => void;
}

interface IOption {
  label: string;
  data?: any;
}
