import * as React from 'react';
import hash from 'object-hash';
import { getDisplayClass } from './get-display-class';
import { getVariantClass } from './get-variant-class';
import { useRunner } from '../runner';
import css from './index.css';

export function useInput({
  validateOnRender = true,
  ...props
}: IBladeInput): IBladeUseInput {
  // if displayValidationErrorsOnLoad is true, then we want to display the validation errors
  // when the input is first rendered. Defaulting the hasBeenInteractedWith state to true in 
  // this case was the  easiest way to achieve this, without having to add a new state variable and 
  // add a reference to this state variable within each type of input.
  const [hasBeenInteractedWith, setHasBeenInteractedWith] =
    React.useState<boolean>(!!props.displayValidationErrorsOnLoad);

  // Let's check what state the current input value
  // is first.

  // If the validation state is different to what
  // the form state understand, then we can trigger an
  // on validate.

  // It is important that this is done reactively, and not
  // behind an event. Validating after an event is an approach
  // that starts us handling scenarios, which makes things very
  // complex, as more scenarios come up.

  // Instead, this seeks to automatically represent, or compliment
  // the rendered state of the input at any given time,
  // and accounts for re-rendering loops by ensuring that we only
  // update if there's a need to (ie, validation results don't match).

  React.useEffect(() => {
    if (validateOnRender === true) {
      const validationState = props.validate?.(props.value);

      if (props.error !== validationState) {
        props.onValidate?.(validationState);
      }
    }
  });

  // TODO

  function _onFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (props.onFocus) {
      props.onFocus(event);
    }
  }

  function _onBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (!hasBeenInteractedWith) {
      setHasBeenInteractedWith(true);
    }

    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  // TODO

  function _onChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const value: any =
      event && typeof event === 'object' && event.target
        ? event?.target?.value
        : event;
    if (hash(props.value ?? '') !== hash(value)) {
      props.onChange(value);
    }
  }

  const { inputs } = useRunner();

  const variantClass = getVariantClass(props.variant || inputs || 'solid');
  const displayClass = getDisplayClass(props.display || 'block');
  const className: string = `${props.className || ''} ${css.input
    } ${variantClass} ${displayClass}`;

  const updatedProps: IBladeUseInput = {
    ...props,
    ...{
      // Overridden properties on the
      // existing props

      onFocus: _onFocus,
      onBlur: _onBlur,
      onChange: _onChange,
      className,
      // Additional properties created
      // by this hook, passing on functionality
      // relevant for inputs

      hasBeenInteractedWith,
      setHasBeenInteractedWith
    }
  };

  return updatedProps;
}

export interface IBladeUseInput extends IBladeInput {
  hasBeenInteractedWith: boolean;
  setHasBeenInteractedWith: (value: boolean) => void;
}
