import * as React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import { Boundary } from '../../boundary';
import { IMotorWebCarRegOrVin } from './types';
import {
  BladeFormFieldset,
  BladeInputText,
  BladeInputSelect,
  BladeButton,
  BladeMessage,
  BladeLink
} from '@aventus/blade';
import { useEnterCarDetails } from './use-enter-car-details';
import css from './index.css';
import cx from 'classnames';
import useAsyncEffect from 'use-async-effect';
import useGoogleRecaptchaV3 from '@aventus/pockethooks/use-google-recaptcha-v3';
import { useEffect } from 'react';

export const MotorWebCarRegOrVin: React.FunctionComponent<
  IMotorWebCarRegOrVin
> = props => (
  <Boundary>
    <_MotorWebCarRegOrVin {...props} />
  </Boundary>
);

const _MotorWebCarRegOrVin: React.FunctionComponent<
  IMotorWebCarRegOrVin
> = props => {
  const { getToken: getRecaptchaToken, token: recaptchaToken } =
    useGoogleRecaptchaV3('motorwebSearch');

  const {
    regOrVin,
    setRegOrVin,
    registeredState,
    setRegisteredState,
    isQuerying,
    setIsQuerying,
    findMyCar,
    findMyCarDetails,
    isInitialised
  } = useEnterCarDetails(
    props.oracleUrl,
    props.onChange,
    props.enrichmentUrl,
    props.enrichmentToken
  );

  useAsyncEffect(
    () => {
      if (
        isInitialised &&
        props.value &&
        props.value.nvic &&
        !props.error?.recoverableError?.message
      ) {
        findMyCarDetails(props.value);
      }
    },
    () => {
      setIsQuerying(false);
    },
    [isInitialised]
  );

  useEffect(() => {
    recaptchaToken && findMyCar(recaptchaToken);
  }, [recaptchaToken]);

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
      className={css.scaffold}
    >
      <div
        className={cx(
          css.carDetails,
          { [css.carDetails_isSpacious]: !matchesQuery },
          { [css.carDetails_isSpaceLimited]: matchesQuery },
          isQuerying ? 'disabled' : ''
        )}
      >
        <BladeInputSelect
          variant={props.variant}
          label={'Registered state'}
          name={props.name + 'state'}
          id={props.name + 'state'}
          value={registeredState}
          onChange={setRegisteredState}
          defaultLabel={'Select State'}
          options={[
            { id: 1, text: 'ACT', value: 'ACT' },
            { id: 2, text: 'NSW', value: 'NSW' },
            { id: 3, text: 'NT', value: 'NT' },
            { id: 4, text: 'QLD', value: 'QLD' },
            { id: 5, text: 'SA', value: 'SA' },
            { id: 6, text: 'TAS', value: 'TAS' },
            { id: 7, text: 'VIC', value: 'VIC' },
            { id: 8, text: 'WA', value: 'WA' }
          ]}
          matchOn={'id'}
        />

        <BladeInputText
          className={cx(css.registration_input)}
          variant={props.variant}
          label={'Registration number'}
          name={props.name + 'rego'}
          id={props.name + 'rego'}
          value={regOrVin}
          onChange={setRegOrVin}
          validate={props.validate}
        />

        <BladeButton
          onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault();
            getRecaptchaToken();
          }}
          isDisabled={!regOrVin || !registeredState}
          isWorking={isQuerying}
        >
          {'Find my car'}
        </BladeButton>
      </div>

      {props.useFallbackEntry && (
        <BladeLink isSmall={true} onClick={() => props.useFallbackEntry?.()}>
          {"Don't know your car's registration number?"}
        </BladeLink>
      )}

      {(props.error?.recoverableError || props.error?.unrecoverableError) && (
        <BladeMessage
          variant={'error'}
          message={
            props.error?.recoverableError?.message ||
            props.error?.unrecoverableError?.message
          }
        />
      )}
    </BladeFormFieldset>
  );
};
