import React, { useEffect, useState } from 'react';
import { BladeButton, BladeMarkdown, BladeModal } from '@aventus/blade';
import { useGetProductConfigurationById } from '@aventus/mvmt-simplequote/hooks/use-product-configuration-by-id';
import { RouteComponentProps } from 'react-router-dom';
import { getToken } from '@aventus/platform-client-context/token-service';
import { useTrack } from '@aventus/application-tracking';
import { useGetVariantSession } from '@aventus/mvmt-simplequote/hooks/use-get-variant-session';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import css from './index.css';
import { QuoteType } from '@aventus/platform';
import QuickQuoteSummaryView from '../../component/summary-view';
import { useMutation, useQuery } from 'react-query';
import usePlatform from '@aventus/platform-client-context/use-platform';
import { useAlerts } from '../../hooks/use-alerts/use-alerts';
import { AxiosError } from 'axios';
import { ICompleteSessionResponse } from '@aventus/platform-client';
import {
  coreQuestionSets,
  exposureQuestionSets
} from '@aventus/mvmt-simplequote/helpers';
import { handleEditQuestionSet } from '@aventus/mvmt-simplequote/helpers';
import { useHistory } from 'react-router';
import RemoveExposureModal from '../../component/remove-exposure-modal';

export const VariantSummary: React.FunctionComponent<
  IVariantSummary
> = props => {
  const { track } = useTrack();
  const platform = usePlatform();
  const history = useHistory();
  const { addAlert, hasAlert, clearAlert, toArray: alertsArray } = useAlerts();

  const {
    data: productConfiguration,
    isLoading: isLoadingProductConfiguration,
    isError: isProductConfigurationError
  } = useGetProductConfigurationById(props.match.params.productId);

  const quoteType = props.match.params.type || 'NewBusiness';

  const editSessionId = new URLSearchParams(window.location.search).get(
    'editSessionId'
  );

  const chrome =
    new URLSearchParams(window.location.search).get('chrome') === 'true' ||
    false;

  const variantID = queryParamGet(props.location.search || '', 'variantID');

  const [modal, setModal] = React.useState<boolean>(false);
  const [removeModal, setRemoveModal] = React.useState<{ exposureId: string, label: string } | undefined>(undefined);

  const {
    isError: isGetSessionError,
    data: policyDetailEditSession,
    isLoading: isGettingVariantSession
  } = useGetVariantSession(editSessionId);

  // TODO: Do we need this if its only for the product name?
  // Can we use the title from the productConfig above?
  // const { product, productErrored, isGettingProduct } = useGetProduct(
  //   props.match.params.productId,
  //   coverType
  // );

  const [riskExposures, setRiskExposures] = useState(policyDetailEditSession?.risk?.exposures)
  useEffect(() => {
    setRiskExposures(policyDetailEditSession?.risk?.exposures)
  }, [policyDetailEditSession])

  const completeSession = useMutation(
    (editSessionID: string) =>
      platform.variantEditSession.completeSession(editSessionID),
    {
      onSuccess: response => {
        if (response.data !== null && response.validationErrors.length === 0) {
          addAlert('variant-complate', {
            type: 'success',
            message: 'Session completed successfully'
          });
          track({
            event: 'aventus.variant.completed',
            value: response.data.id
          });
        } else if (
          response.data === null &&
          response.validationErrors.length > 0
        ) {
          addAlert('validation-errors', {
            type: 'warning',
            message:
              'Variant cannot be completed due to validation errors. Please check each questionset and try again.',
            validationErrors: response.validationErrors
          });
        }
      },
      onError: error => {
        const e = error as AxiosError<ICompleteSessionResponse>;
        if (
          e.response !== undefined &&
          e.response.data.data === null &&
          e.response.data.validationErrors.length > 0
        ) {
          addAlert('validation-errors', {
            type: 'warning',
            message:
              'Variant cannot be completed due to validation errors. Please check each questionset and try again.',
            validationErrors: e.response.data.validationErrors
          });
        } else {
          addAlert('api-error', {
            type: 'error',
            message: 'Unable to complete variant session.',
            error: new Error(e.message)
          });
        }
      }
    }
  );

  const discardSession = useMutation(
    (editSessionID: string) =>
      platform.variantEditSession.discardSession(editSessionID),
    {
      onSuccess: response => {
        addAlert('variant-discarded', {
          type: 'success',
          message: 'Session discarded successfully'
        });
        track({
          event: 'aventus.variant.discarded',
          value: response.id
        });
      },
      onError: error => {
        const e = error as AxiosError;
        addAlert('api-error', {
          type: 'error',
          message:
            'Sorry we encountered an error, session has not been discarded.',
          error: new Error(e.message)
        });
      }
    }
  );

  useQuery(
    ['Variant', 'Quotes', variantID],
    () => platform.product.getVariantQuotes(variantID),
    {
      enabled: !!variantID,
      onSuccess: response => {
        const hasReferred = response.some(
          ({ quoteState }) => quoteState === 'Referred'
        );

        if (hasReferred) {
          addAlert('referred-quote', {
            type: 'warning',
            message:
              'One or more quotes associated with this variant have been referred.'
          });
        }
      }
    }
  );

  const removeExposure = useMutation(({ editSessionId, exposureId }: { editSessionId: string | null, exposureId: string }) => 
    platform.variantEditSession.removeExposure(editSessionId as string, exposureId),
    {
      onSuccess: (response: any) => {
        if (!!response && response.exposures && Array.isArray(response.exposures)) {
          setRiskExposures(response.exposures)
        } else {
          addAlert('fetch-error', {
            type: 'error',
            message: 'There has been an error removing this exposure.'
          });
        }
        setRemoveModal(undefined);
      },
      onError: () => {
        setRemoveModal(undefined);
        addAlert('fetch-error', {
          type: 'error',
          message: 'There has been an error removing this exposure.'
        });
      }
    }
  );

  const onClickAction = handleEditQuestionSet(
    history,
    props.match.params.productId,
    editSessionId || '',
    chrome === true ? 'true' : 'false',
    platform.token || getToken(),
    quoteType
  );

  useEffect(() => {
    if (editSessionId === undefined) {
      addAlert('no-session-id', {
        type: 'error',
        message: 'Cannot find active session.'
      });
    }
  }, [editSessionId, addAlert]);

  useEffect(() => {
    if (riskExposures !== undefined && riskExposures.length === 0) {
      addAlert('min-exposures', {
        type: 'warning',
        message: 'Please add at least one exposure to this quote'
      });
    } else if (riskExposures !== undefined && riskExposures.length > 0) {
      clearAlert('min-exposures');
    }
  }, [riskExposures, addAlert, clearAlert]);

  useEffect(() => {
    if (isProductConfigurationError || isGetSessionError) {
      addAlert('fetch-error', {
        type: 'error',
        message: 'There has been an error fetching this quote session.'
      });
    }
  }, [isProductConfigurationError, isGetSessionError, addAlert]);

  const isViewLoading =
    isLoadingProductConfiguration ||
    isGettingVariantSession ||
    completeSession.isLoading ||
    discardSession.isLoading ||
    removeExposure.isLoading;

  return (
    <>
      {removeModal !== undefined && (
        <RemoveExposureModal
          close={() => setRemoveModal(undefined)}
          isLoading={removeExposure.isLoading || isGettingVariantSession}
          label={removeModal.label}
          onRemove={() => {
            removeExposure.mutate({
            exposureId: removeModal.exposureId,
            editSessionId,
          })}}
        />
      )}
      <QuickQuoteSummaryView
        title="Risk Summary"
        alerts={alertsArray}
        coreQuestionSets={
          productConfiguration
            ? coreQuestionSets('Core', productConfiguration, onClickAction)
            : []
        }
        exposures={
          productConfiguration !== undefined
            ? exposureQuestionSets(
                riskExposures || [],
                'Exposure',
                productConfiguration,
                onClickAction,
                setRemoveModal
              )
            : []
        }
        onAddExposure={onClickAction}
        actions={
          <>
            <BladeButton
              className={css.summaryActions_discard}
              isInline={true}
              isDestructive={true}
              onClick={() => {
                setModal(true);
              }}
            >
              {'Discard Changes'}
            </BladeButton>
            <BladeButton
              className={css.summaryActions_save}
              isInline={true}
              variant={'secondary'}
              onClick={() => {
                addAlert('variant-saved', {
                  type: 'success',
                  message: 'Variant saved.'
                });
                track({
                  event: 'aventus.variant.save',
                  value: editSessionId
                });
              }}
            >
              {'Save Risk'}
            </BladeButton>
            <BladeButton
              className={css.summaryActions_complete}
              isInline={true}
              variant={'primary'}
              onClick={() => {
                if (editSessionId) {
                  clearAlert('validation-error');
                  completeSession.mutateAsync(editSessionId);
                }
              }}
              isDisabled={hasAlert([
                'api-error',
                'min-exposures',
                'no-session-id',
                'validation-errors',
                'fetch-error'
              ])}
              isWorking={isViewLoading}
            >
              {isViewLoading ? 'Loading...' : 'Quote'}
            </BladeButton>
          </>
        }
      />
      {modal && (
        <BladeModal
          close={() => setModal(false)}
          withFrame={true}
          allowClose={false}
          title={'Discard confirm'}
        >
          <div className={css.scaffoldingModal}>
            <BladeMarkdown
              markdown={
                'By continuing you will discard this session and lose any changes made'
              }
            />

            <div className={css.buttonWrapper}>
              <BladeButton
                variant={'secondary'}
                style={{ flexBasis: '50px', flexGrow: 1 }}
                onClick={() => setModal(false)}
              >
                {'Back'}
              </BladeButton>

              <BladeButton
                variant={'action'}
                isDestructive={true}
                style={{ flexBasis: '50px', flexGrow: 2 }}
                onClick={() => {
                  if (editSessionId) {
                    discardSession
                      .mutateAsync(editSessionId)
                      .then(() => setModal(false));
                  }
                }}
              >
                {'Discard Changes'}
              </BladeButton>
            </div>
          </div>
        </BladeModal>
      )}
    </>
  );
};

export interface IVariantSummary
  extends RouteComponentProps<{
    type: QuoteType;
    editSessionId: string;
    productId: string;
  }> {}
