import update from 'immutability-helper';

const WIDGET_SET_PARTNER_INFO = 'WIDGET_SET_PARTNER_INFO';

const setPartnerInfoAction = (partnerInfo) => {

  const {
    partnerId = null,
    partnerReference,
    payload,
    partnerConfig
  } = partnerInfo;

  return ({
    type: WIDGET_SET_PARTNER_INFO,
    partnerId,
    partnerReference,
    partnerConfig,
    payload
  });
}

const setPartnerInfoReducer = (state, action) => {

  const {
    partnerId,
    partnerReference,
    payload,
    partnerConfig
  } = action;

  return {
    ...state,
    partnerId: update(state.partnerId, { $set: partnerId }),
    partnerReference: update(state.partnerReference, { $set: partnerReference }),
    partnerConfig: update(state.partnerConfig, { $merge: partnerConfig || {} }),
    payload: update(state.payload, { $set: payload || '' })
  };
}

export {
  WIDGET_SET_PARTNER_INFO,
  setPartnerInfoAction,
  setPartnerInfoReducer
}
