import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  useGetQuote,
  useGetQuoteProduct
} from '@aventus/symphony-client/hooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import {
  BladeButton,
  BladeView,
  BladeViewIsLoading,
  BladeAsk,
  BladeMessage
} from '@aventus/blade';
import { useAskForNew } from '../intelligent-quote/ask-for-new/use-ask-for-new';
import { QuoteCard } from '../../components/quote-card';
import { useMediaQuery } from '@aventus/pockethooks/use-media-query';
import { EstimateOverlay } from '../../components/estimate-overlay';
import { mapQuestionMetaToQuestionAnswer } from '../../services/map-question-meta-to-question-answer';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { useTrack, useTrackView } from '@aventus/application-tracking';
import cx from 'classnames';
import css from './index.css';
import './qq.css';

export const IntelligentQuickQuote: React.FunctionComponent<IIntelligentQuickQuote> = props => {
  useTrackView('IntelligentQuickQuote');

  const { toHumanReadable } = useApplicationTimezone();
  const { track } = useTrack();

  const { isGettingQuoteBundle, quoteBundle } = useGetQuote(
    props.match.params.quoteId
  );
  const { quoteProduct, isGettingQuoteProduct } = useGetQuoteProduct(
    props.match.params.quoteId
  );

  const { productConfiguration } = useGetProductConfiguration(
    quoteBundle?.requestedQuote.productReferenceID,
    quoteBundle?.requestedQuote.coverType
  );

  React.useEffect(() => {
    if (
      quoteBundle?.requestedQuote.productReferenceID &&
      quoteBundle?.requestedQuote.coverType
    ) {
      track({
        event: 'aventus.quickquote.start',
        quoteId: props.match.params.quoteId,
        partnerId: quoteBundle.requestedQuote.partnerID,
        productReference: quoteBundle.requestedQuote.productReferenceID,
        productCoverReference: quoteBundle.requestedQuote.coverType
      });
    }
  }, [quoteBundle]);

  const iq = useAskForNew(
    undefined,
    undefined,
    quoteBundle?.requestedQuote.id,
    undefined,
    true
  );

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  if (isGettingQuoteBundle || isGettingQuoteProduct || iq.isWorking) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (quoteBundle && quoteProduct && productConfiguration && iq && iq.page) {
    return (
      <React.Fragment>
        <EstimateOverlay
          quotePricing={quoteBundle.requestedQuotePricing}
          productConfiguration={productConfiguration}
          quoteType={quoteBundle.requestedQuote.quoteType}
        />

        <BladeView
          variant={'l'}
          className={cx(
            css.quickQuote,
            matchesQuery
              ? css.quickQuote_singleColumn
              : css.quickQuote_doubleColumn
          )}
        >
          <div className={css.quickQuote_card}>
            <QuoteCard
              isCollapsed={matchesQuery ? true : false}
              product={quoteProduct}
              productConfiguration={productConfiguration}
            />
          </div>

          <div className={css.quickQuote_form}>
            <BladeMessage
              variant={'info'}
              message={
                productConfiguration.quote.estimate?.description ||
                "This is what we've been able to answer for you. Please confirm your information so far and we'll take you to the rest of the quote flow. Please be aware that the price might change dependeing on how you answer the rest of it."
              }
            />

            <BladeAsk
              variant={'alltogether'}
              getQuestionsToRender={() =>
                mapQuestionMetaToQuestionAnswer(
                  iq.pages.reduce(
                    (pages: any, page: any) => [...pages, ...page.questions],
                    []
                  ),
                  iq.risk,
                  toHumanReadable
                )
              }
              getSubmitToRender={() => (
                <div className={css.quickQuote_form_controls}>
                  <BladeButton
                    basis={!matchesQuery && '35%'}
                    variant="secondary"
                    onClick={() => {
                      props.history.push(
                        `/quote/edit?quoteId=${quoteBundle.requestedQuote.id}`
                      );
                    }}
                  >
                    {productConfiguration.quote.estimate?.editCallToAction ||
                      'Edit'}
                  </BladeButton>

                  <BladeButton
                    basis={!matchesQuery && '65%'}
                    onClick={() => {
                      props.history.push(
                        `/quote/new?quoteId=${quoteBundle.requestedQuote.id}`,
                        { risk: iq.risk, riskState: iq.riskState }
                      );
                    }}
                  >
                    {productConfiguration.quote.estimate?.confirmCallToAction ||
                      'Confirm'}
                  </BladeButton>
                </div>
              )}
            />
          </div>
        </BladeView>
      </React.Fragment>
    );
  }

  return null;
};

export interface IIntelligentQuickQuote
  extends RouteComponentProps<{
    quoteId: string;
  }> {}
