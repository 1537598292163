import {
  AUTH_SEND_SIGNIN_LINK_SUCCESS,
  AUTH_SEND_SIGNIN_LINK_FAILURE
} from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../core/alerts/queue-alert/actions';

export function sendSignInLinkAction(emailAddress, redirectUrl) {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/sendsigninlink',
        method: 'POST',
        data: {
          emailAddress,
          redirectUrl
        }
      },
      actions: [sendSignInLinkSuccessAction, sendSignInLinkFailureAction]
    }
  };
}

export function sendSignInLinkSuccessAction() {
  return {
    type: AUTH_SEND_SIGNIN_LINK_SUCCESS
  };
}

export function sendSignInLinkFailureAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_SEND_SIGNIN_LINK_FAILURE
    });

    dispatch(
      queueAlertAction({
        type: 'error',
        message: res.message,
        location: false
      })
    );
  };
}
