import * as React from 'react';
import {
  PclRecoverableError,
  VoucherCodeError,
  PlatformUnauthorisedError,
  ServerError
} from '@aventus/errors';
import { StripeError } from '@aventus/payment-provider-stripe';
import { ICheckoutBoundaryProps, ICheckoutBoundaryState } from './types';
import { FatZebraError } from '@aventus/payment-provider-fatzebra';
import BNPError from '@aventus/payment-provider-bnp/errors/bnp-error';

export class CheckoutBoundary extends React.Component<
  ICheckoutBoundaryProps,
  ICheckoutBoundaryState
> {
  constructor(props: ICheckoutBoundaryProps) {
    super(props);

    this.state = {
      finance: null,
      payment: null,
      voucher: null,
      authorisation: null
    };
  }

  // This need to be any as the typed Error object is not fully defined and is almost impossible to work with
  componentDidCatch(error: any) {
    if (error instanceof PclRecoverableError) {
      this.setState({ finance: error });
    } else if (error instanceof StripeError) {
      this.setState({ payment: error });
    } else if (error instanceof FatZebraError) {
      this.setState({ payment: error });
    } else if (error instanceof BNPError) {
      this.setState({ finance: error });
    } else if (error instanceof VoucherCodeError) {
      this.setState({ voucher: error });
    } else if (
      error.response?.status === 401 ||
      error instanceof PlatformUnauthorisedError
    ) {
      this.setState({ authorisation: error });
      this.props.goToLogin && this.props.goToLogin();
    } else if (error instanceof ServerError) {
      this.props.goHome && this.props.goHome();
    } else {
      throw error; // throw it up to the application's boundary (It seems this catches it)
    }
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        errors: {
          finance: this.state.finance,
          payment: this.state.payment,
          voucher: this.state.voucher,
          authorisation: this.state.authorisation,
          clearErrors: () => this.setState({})
        }
      })
    );
  }
}
