export const australia: string[] = [
  'bigpond.net.au',
  'bigpond.com.au',
  'tpg.com.au',
  'bigpond.com',
  'optusnet.com.au',
  'westnet.com.au',
  'internode.on.net',
  'ozemail.com.au'
];

export default australia;
