import * as React from 'react';
import { BladeAppDisplay } from '../app-display';
import { BladeSurface } from '../surface';
import { BladeMarkdown } from '../markdown';
import { BladeTooltip } from '../tooltip';
import { BladeView, BladeViewVariant } from '../view';
import css from './index.css';
import cx from 'classnames';

export const BladeTitleBar: React.FunctionComponent<IBladeTitleBar> = props => {
  return (
    <BladeAppDisplay className={cx(css.display, props.cssClass)}>
      <BladeView variant={props.viewVariant} className={css.view}>
        <BladeSurface classNameContent={css.titleBar}>
          <h2>{props.title}</h2>

          <BladeMarkdown
            className={css.titleBar_description}
            markdown={props.description}
          />

          {props.tooltip && (
            <div className={css.tooltip}>
              <BladeTooltip place="left" markdown={props.tooltip} />
            </div>
          )}
        </BladeSurface>
      </BladeView>
    </BladeAppDisplay>
  );
};

export interface IBladeTitleBar {
  title: string;
  description?: string;
  tooltip?: string;
  viewVariant: BladeViewVariant;
  cssClass?: string;
}
