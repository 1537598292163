import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ProductDashboard } from '../product-dashboard';
import { MvmtProductDashboardCustom } from '../../index';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';
import { BladeViewIsLoading } from '@aventus/blade';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const ProductDashboardRedirect: React.FunctionComponent<IProductDashboardRedirect> = ({
  auth,
  customDashboard,
  ...props
}) => {
  const { applicationConfiguration } = useGetApplicationConfiguration();
  const isStella = applicationConfiguration?.designSystem == 'stella';

  if (applicationConfiguration) {
    if (auth) {
      if (applicationConfiguration?.customDashboard) {
        return <MvmtProductDashboardCustom auth={auth} {...props} />;
      } else {
        return <ProductDashboard auth={auth} {...props} />;
      }
    } else if (
      applicationConfiguration?.customDashboard &&
      !applicationConfiguration?.customRedirects &&
      !auth &&
      isStella
    ) {
      return <MvmtProductDashboardCustom auth={auth} {...props} />;
    } else if (
      (!applicationConfiguration?.customDashboard && !auth && isStella) ||
      (!applicationConfiguration?.customDashboard && !auth && !isStella)
    ) {
      return <ProductDashboard auth={auth} {...props} />;
    } else {
      props.goToSignIn();
      return null;
    }
  } else {
    return <BladeViewIsLoading variant={'s'} />;
  }
};

export interface IProductDashboardRedirect
  extends RouteComponentProps<{
    productReference: ProductReference;
    productCoverReference: ProductCoverReference;
  }> {
  auth: boolean | {};
  customDashboard: string;
  goToSignIn: () => void;
  goToPolicy: (arg: string) => void;
  goToProduct: (productReferenceId: string, name: string) => void;
  signOut: () => void;
}
