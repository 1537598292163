import * as React from 'react';
import { RouteComponentProps } from 'react-router';

export const IntelligentQuoteAdjustOverviewRedirect: React.FunctionComponent<IIntelligentQuoteAdjustOverviewRedirect> = (props) => {

  const policyId: string = props.match.params.policyId;
  props.goToIntelligentQuoteForAdjust(policyId);

  return null;

};

export interface IIntelligentQuoteAdjustOverviewRedirect extends RouteComponentProps<{
  productId: string;
  lineOfBusiness: string;
  coverType: string;
  policyId: string;
}> {
  goToIntelligentQuoteForAdjust: (policyId: string) => void
}
