import {
  CheckoutPaymentConfiguration,
  CheckoutPaymentGuideFooterConfiguration
} from '@aventus/configuration';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { ToHumanReadable } from '@aventus/application-timezone';
import {
  PricingPlan,
  RateableComponentFinalPrice
} from '@aventus/platform/pricing';

import { STELLA_ROADSIDE_IDS } from '@aventus/client-helpers/stella';

export function getPaymentGuideFooter(
  paymentConfiguration: CheckoutPaymentConfiguration,
  isDeposit: boolean,
  selectedPricingPlan: PricingPlan,
  toHumanReadable: ToHumanReadable
): string | undefined {
  const hasFees = selectedPricingPlan.fees.length > 0;
  const hasStellaRoadside = selectedPricingPlan.primaryRateableComponentFinalPrices.some(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );
  const stellaRoadsideRC = selectedPricingPlan.primaryRateableComponentFinalPrices.find(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );

  const pickGuideFooterConfigValue = (
    config: CheckoutPaymentGuideFooterConfiguration
  ) => {
    const defaultValue = config.default;
    if (hasStellaRoadside) {
      if (hasFees)
        return config?.withFeeAndStellaRoadside ?? defaultValue;
      if (!hasFees) return config?.withStellaRoadside ?? defaultValue;
    }

    if (hasFees) return config.withFee;

    // Default to returning the original one
    return defaultValue;
  };

  const guideFooter = isDeposit
    ? paymentConfiguration.guideFooterMonthly
      ? pickGuideFooterConfigValue(paymentConfiguration.guideFooterMonthly)
      : paymentConfiguration.guideFooterForDeposit
    : paymentConfiguration.guideFooter;

  if (!guideFooter) {
    return undefined;
  }

  const replacersMap = {
    $$_upfront_price: currencyToHumanReadable(selectedPricingPlan.upfrontPrice),
    $$_monthly_price: currencyToHumanReadable(selectedPricingPlan.monthlyPrice),
    ...(selectedPricingPlan.firstRecurringPaymentDate
      ? {
          $$_initial_payment_date: toHumanReadable(
            selectedPricingPlan.firstRecurringPaymentDate
          )
        }
      : {}),
    ...(hasFees
      ? {
          $$_fee_amount: currencyToHumanReadable(
            selectedPricingPlan.feesTotal
          )
        }
      : {}),
    ...(hasStellaRoadside && stellaRoadsideRC
      ? {
          $$_roadside_price: currencyToHumanReadable(
            stellaRoadsideRC.price.gross
          )
        }
      : {})
  };

  switch (selectedPricingPlan.paymentPlan.type) {
    case 'AnnualMonthlySubscription':
    case 'MonthlySubscription':
      const guideFooterInterpolated = stringReplacer(guideFooter, replacersMap);

      if (paymentConfiguration.mandateForSCA) {
        return `${guideFooterInterpolated}\n\n${paymentConfiguration.mandateForSCA}`;
      } else {
        return guideFooterInterpolated;
      }

    default:
      return undefined;
  }
}
