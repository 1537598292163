import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteProduct } from '@aventus/platform';

export const useGetPolicyProduct = function(policyId: string | undefined) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [policyProduct, setPolicyProduct] = React.useState<
    QuoteProduct | undefined
  >(undefined);
  const [isGettingPolicyProduct, setIsGettingPolicyProduct] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setIsGettingPolicyProduct(true);

        try {
          const _policyProduct = await symphony.getPolicyProduct(policyId);
          setPolicyProduct(_policyProduct);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyProduct(false);
      }
    })();
  }, [policyId]);

  return {
    policyProduct,
    isGettingPolicyProduct
  };
};
