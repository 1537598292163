import * as React from 'react';
import {
  BladeSurface,
  BladeBanner,
  BladeMarkdown,
  BladeModal
} from '@aventus/blade';
import { QuoteProduct } from '@aventus/platform';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { DetailsOfCover } from '@aventus/mvmt-product';
import css from './index.css';

export const QuoteCard: React.FunctionComponent<IQuoteCard> = props => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(
    props.isCollapsed ? true : false
  );
  const [showFinePrintModal, setShowFinePrintModal] = React.useState<boolean>(
    false
  );

  return (
    <>
      <BladeSurface className={css.quoteCard}>
        <BladeBanner
          background={props.productConfiguration.product.bannerImage}
          title={props.productConfiguration.product.label}
          isCollapsable={props.isCollapsed ? true : false}
          isCollapsed={isCollapsed}
          toggle={() => setIsCollapsed(!isCollapsed)}
        />

        {!isCollapsed && (
          <>
            <div className={css.quoteCard_content}>
              <DetailsOfCover
                product={props.product}
                coverConfiguration={
                  props.productConfiguration.quote.summary.cover
                }
              />
            </div>

            {props.productConfiguration.product.smallPrint && (
              <BladeSurface
                classNameContent={css.quoteCard_footer}
                isAside={true}
              >
                <BladeMarkdown
                  size={'small'}
                  action={() => setShowFinePrintModal(true)}
                  markdown={props.productConfiguration.product.smallPrint}
                />
              </BladeSurface>
            )}
          </>
        )}

        {isCollapsed && <div className={css.quoteCard_footer_isClosed}></div>}
      </BladeSurface>

      {showFinePrintModal && (
        <BladeModal withFrame={true} close={() => setShowFinePrintModal(false)}>
          <BladeMarkdown
            size={'base'}
            markdown={props.productConfiguration.product.smallPrintDetails}
          />
        </BladeModal>
      )}
    </>
  );
};

interface IQuoteCard {
  product: QuoteProduct;
  productConfiguration: InsuranceProductConfiguration;
  isCollapsed?: boolean;
}
