import moment from "moment";
import { ITobesArrayAnswerItem, ITobesQuestion } from "@aventus/platform-client-context/models";
import { currencyToHumanReadable } from '@aventus/pocketknife';

export const getAnswersPeak = (
    answers: ITobesArrayAnswerItem[],
    questions: ITobesQuestion[]
  ) => {
    let arrayOfAnswers:string[] = []

    questions.forEach((question) => {
      const _answer = answers.filter(
        answer => question.referenceID === answer.questionReferenceID
      )[0];

      switch (question.dataType) {
        case 'String':
          arrayOfAnswers.push(_answer.answer);
          break;
        case 'LocalDateTime':
          arrayOfAnswers.push( moment(_answer.answer).format('DD/MM/YYYY'));
          break;
          case 'Object':
            if(question.objectType === "ListItem"){
              arrayOfAnswers.push(_answer.answer.text);
            }
            if(question.objectType === "Money"){
              // Weird issue in currency human readable sees this as pence only
              var massagedMoney = {value: _answer.answer.value * 100, currencyCode: _answer.answer.currencyCode}
              arrayOfAnswers.push(currencyToHumanReadable(massagedMoney));
            }

            break;
        default:
          arrayOfAnswers.push(_answer.answer);
      }
    });
  
    return arrayOfAnswers.filter(value => value).join(' ');
  };