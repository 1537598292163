import update from 'immutability-helper';

export const blockUserRequestReducer = (state) => {
  return {
    ...state,
    auth: update(state.auth, {
      _flags: {
        isBlockingUser: { $set: true }
      }
    })
  };
}

export const blockUserSuccessReducer = (state) => {
  return {
    ...state,
    auth: update(state.auth, {
      _flags: {
        isBlockingUser: { $set: false }
      }
    })
  }
}

export const blockUserFailureReducer = (state) => {
  return {
    ...state,
    auth: update(state.auth, {
      _flags: {
        isBlockingUser: { $set: false }
      }
    })
  }
}