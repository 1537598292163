import * as React from 'react';
import cx from 'classnames';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { BladeIcon } from '../../icon';
import { useInput } from '../use-input';
import css from './index.css';

// Note: This isn't exactly an input, however it is considered a
// variation of the inputs, has the same dimensions and uses the same
// label, so it makes sense to keep it with the inputs.
// It's basically a value that cannot be changed.

export const BladeInputIncluded: React.FunctionComponent<IBladeInputIncluded> = props => {
  const inputProps = useInput(props);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <div
        className={cx(
          'blade-input-included',
          css.included,
          inputProps.className
        )}
      >
        <BladeIcon name={'faCheck'} />

        <BladeInput_Label
          id={inputProps.id}
          label={'Included with your policy'}
        />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputIncluded extends IBladeInput {}
