import * as React from 'react';
import { AddressInternationalManual } from './manual';
import { AddressInternationalDisplay } from './display';
import { IBladeFormFieldset } from '../';
import { IBladeInputText } from '../../input/text';

export const BladeFormFieldsetAddressInternational: React.FunctionComponent<IBladeFormFieldsetAddressInternational> = props => {
  // We can rely on the postalCode
  // to see if we've got an address defined already or not,
  // since it's a required property of the address object.
  const isAlreadyDefined: boolean = props.value?.line1 ? true : false;

  const [autoFocus, setAutoFocus] = React.useState<boolean>(false);

  // This flag will allow us to figure out which entry
  // to show for the user. This flag forces the manual address
  // entry.

  const [forceEnterManually, setForceEnterManually] = React.useState<boolean>(
    false
  );

  // Since we are toggling between different entry options, we need
  // keep track of the address in store when the user switches, to allow
  // them to switch back, without losing the data and having to re-enter.

  const [cachedAddress, setCachedAddress] = React.useState<any>();

  const defaultValue = {
    line1: null,
    line2: null,
    line3: null,
    locality: null,
    province: null,
    postalCode: null,
    country: props.selectedCountry
  };

  if (props.renderOracle && isAlreadyDefined && !forceEnterManually) {
    return (
      <AddressInternationalDisplay
        {...props}
        address={props.value}
        handler={() => setForceEnterManually(true)}
        showCountry={props.showCountry}
        helperText={props.renderOracle?.props.helperText}
      />
    );
  } else if (
    (props.renderOracle && isAlreadyDefined && !forceEnterManually) ||
    (props.renderOracle && forceEnterManually) ||
    !props.renderOracle
  ) {
    return (
      <AddressInternationalManual
        {...props}
        countryCode={props.selectedCountry.referenceID}
        renderOracle={props.renderOracle}
        enterWithLookup={() => {
          setCachedAddress(props.value);
          props.onChange(defaultValue);
          setAutoFocus(true);
          setForceEnterManually(false);
          props.dispatchReset();
        }}
      />
    );
  } else {
    return React.cloneElement(props.renderOracle, {
      enterManually: () => {
        props.onChange(cachedAddress || defaultValue);
        setForceEnterManually(true);
      },
      autoFocus,
      onFocus: () => setAutoFocus(false)
    });
  }
};

export interface IBladeFormFieldsetAddressInternational
  extends IBladeFormFieldset,
    IBladeInputText {
  selectedCountry: any;
  showCountry?: boolean;
  dispatchReset: () => void;
  renderOracle?: React.ReactElement<any> | null;
}
