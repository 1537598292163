import * as React from 'react';
import { ClaimElement } from './components/claim';
import { ClaimForm } from './components/claimForm';
import { BladeInput_Frame } from '../_frame';
import { BladeButton } from '../../button';
import { useInput } from '../use-input';
import { useClaims } from './use-claims';
import { useMediaQuery } from '@aventus/pockethooks';
import { ProductClaimsConfiguration } from '@aventus/configuration';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import css from './index.css';
import formCss from './components/claimForm/index.css';

export const BladeClaims: React.FunctionComponent<IBladeClaim> = props => {
  const [isFormModified, setIsFormModified] = React.useState<
    number | undefined
  >(undefined);
  const inputProps = useInput(props);

  const defaultFormState = {
    [props.itemKey]: {}
  };

  const {
    isFormOpen,
    setIsFormOpen,
    formState,
    setFormState,
    updateFormState,
    addToClaims,
    updateInClaims,
    deleteFromClaims
  } = useClaims(inputProps.value, defaultFormState);

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const shouldFormBeOpen = !inputProps.value || isFormOpen;

  const claimFormAdd = (isFormValid: boolean) => (
    <div className={formCss.claims_form}>
      <div
        className={cx(formCss.claims_form_buttons, {
          [formCss.claims_form_buttons_wide]: matchesQuery
        })}
      >
        <BladeButton
          className={cx(formCss.claims_form_button, {
            [formCss.claims_form_button_wide]: matchesQuery
          })}
          isDisabled={!isFormValid}
          onClick={() => {
            inputProps.onChange(addToClaims(formState[props.itemKey]));
            setFormState(defaultFormState);
            setIsFormOpen(false);
          }}
        >
          {props.addToCollectionLabel || 'Add claim'}
        </BladeButton>

        {inputProps.value?.length > 0 && (
          <BladeButton
            className={cx(formCss.claims_form_button, {
              [formCss.claims_form_button_wide]: matchesQuery
            })}
            variant="secondary"
            onClick={() => setIsFormOpen(false)}
          >
            Cancel
          </BladeButton>
        )}
      </div>
    </div>
  );

  const claimFormModify = (
    isFormValid: boolean,
    setIsEditMode: (value: boolean) => void,
    itemIndex: number
  ): React.ReactNode => {
    return (
      <div className={formCss.claims_form}>
        <div
          className={cx(formCss.claims_form_buttons, {
            [formCss.claims_form_buttons_wide]: matchesQuery
          })}
        >
          <BladeButton
            className={cx(formCss.claims_form_button, {
              [formCss.claims_form_button_wide]: matchesQuery
            })}
            isDisabled={!isFormValid}
            onClick={() => {
              inputProps.onChange(
                updateInClaims(formState[props.itemKey], itemIndex)
              );
              setFormState(defaultFormState);
              setIsEditMode(false);
            }}
          >
            Modify details
          </BladeButton>
          <BladeButton
            className={cx(formCss.claims_form_button, {
              [formCss.claims_form_button_wide]: matchesQuery
            })}
            variant="secondary"
            onClick={() => {
              setIsEditMode(false);
              setIsFormOpen(false);
            }}
          >
            Cancel
          </BladeButton>
        </div>
      </div>
    );
  };

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
      displayErrorAbove={true}
    >
      <div className={css.claims}>
        {inputProps.value && (
          <>
            <div className={css.claims_content}>
              <>
                {inputProps.value.map((value: any, i: number) => (
                  <ClaimElement
                    modifyClaim={claimFormModify}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                    itemKey={props.itemKey}
                    setIsFormOpen={setIsFormOpen}
                    itemIndex={i}
                    setFormState={setFormState}
                    deleteClaim={() => { 
                      inputProps.onChange(deleteFromClaims(i)); 
                      inputProps.value.length === 1 && setIsFormOpen(true);
                    }}
                    claimsQuestionNames={props.claimsQuestionNames}
                    getQuestions={(validation: any, dispatchValidation: any) =>
                      props.getQuestions(
                        formState,
                        updateFormState,
                        validation,
                        dispatchValidation
                      )
                    }
                    values={value}
                  />
                ))}
              </>
            </div>
          </>
        )}
      </div>

      {!inputProps.value ||
        (!isFormOpen && (
          <BladeButton
            className={cx(formCss.claims_form_button, {
              [formCss.claims_form_button_wide]: matchesQuery
            })}
            onClick={() => {
              setFormState(defaultFormState);
              setIsFormOpen(true);
              setIsFormModified(undefined);
            }}
          >
            {!inputProps.value || isEmpty(inputProps.value)
              ? props.addToCollectionLabel || 'Add a claim'
              : props.addAnotherToCollectionLabel || 'Add another claim'}
          </BladeButton>
        ))}

      {shouldFormBeOpen && (
        <ClaimForm
          claimFormActions={claimFormAdd}
          getQuestions={(validation: any, dispatchValidation: any) =>
            props.getQuestions(
              formState,
              updateFormState,
              validation,
              dispatchValidation
            )
          }
        />
      )}
    </BladeInput_Frame>
  );
};

export interface IBladeClaim extends IBladeInput {
  addToCollectionLabel: string | null;
  addAnotherToCollectionLabel: string | null;
  itemLabel: string | null;
  itemKey: string;
  maxNumberOfItems?: number;
  claimsQuestionNames?: ProductClaimsConfiguration;
  getQuestions: (
    formState: any,
    updateFormState: any,
    validation: any,
    dispatchValidation: any
  ) => any;
}
