import _isFunction from 'lodash/isFunction';

/*
  NOTE: We arnt actually using an "external_id" in the sense of Braze API docs
  We're just always setting a uuid to a guest session, if they sign up, we'll uses that ID to always identify them in future.
  If they sign in, we'll just switch to the new user and loose their analytics before they signed in.
*/

export default function trackEvent(type, name, properties = {}, config, partnerId) {

  const {
    productLabel
  } = properties || {};

  switch(type) {

    case 'initialise':
      break;

    case 'changeUser':
      break;

    case 'newScreen':
      const page = properties["Screen_name"];
      break;

    case 'ecommerce':
      if (window.fbAsyncInit && window.fbAsyncInit.hasRun && FB && _isFunction(FB.AppEvents.logPurchase)) {
        const coverType = properties.Cover_Type ? { Cover_Type: properties.Cover_Type } : {};
        FB.AppEvents.logPurchase(
          properties.revenue,
          'GBP',
          {
            Order_Id:properties.id,
            Line_of_Business: properties.Line_of_Business,
            ...coverType
          }
        )
      }

      if (window.fbq && properties.revenue) {
        fbq('track', 'Purchase', {
          value: properties.revenue,
          currency: 'GBP',
          content_name: properties.coverType ? properties.coverType.toLowerCase() : 'n/a',
          content_category: properties.purchaseType || 'n/a'
        });
      }
      break;

    case 'customEvent':
      break;
    case 'customFBEvent':
      if (window.fbAsyncInit && window.fbAsyncInit.hasRun && _isFunction(FB.AppEvents.logEvent)) {
        FB.AppEvents.logEvent(
          name,
          null,
          properties
        )
      }
      if (window.fbq) {
        fbq('trackCustom', name, properties);
      }
      break;

    case 'customAttribute':
      break;
  }

}
