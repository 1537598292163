import { GET_ACTIVE_LINES_OF_BUSINESS_REQUEST, GET_ACTIVE_LINES_OF_BUSINESS_SUCCESS, GET_ACTIVE_LINES_OF_BUSINESS_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../alerts/queue-alert/actions'

export default function getActiveLinesOfBusinessAction() {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: `lobs/config`,
        guest: true
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      actions: [getActiveLinesOfBusinessRequestAction, getActiveLinesOfBusinessSuccessAction, getActiveLinesOfBusinessFailureAction]
    }
  };
}

export function getActiveLinesOfBusinessRequestAction() {
  return {
    type: GET_ACTIVE_LINES_OF_BUSINESS_REQUEST
  };
}

export function getActiveLinesOfBusinessSuccessAction(res) {
  return {
    type: GET_ACTIVE_LINES_OF_BUSINESS_SUCCESS,
    res
  }
}

export function getActiveLinesOfBusinessFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: GET_ACTIVE_LINES_OF_BUSINESS_FAILURE
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
