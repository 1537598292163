import { push } from 'connected-react-router';
import { API_SYM } from '@aventus/middleware-api';
import {
  QUOTE_GET_VRI_REQUEST,
  QUOTE_GET_VRI_SUCCESS,
  QUOTE_GET_VRI_FAILURE
} from './types';
import { asyncActionRequest, asyncActionFailure } from '../../_module';

export const getVri = (lineOfBusiness, coverType, vriRequestObject) => {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        method: 'POST',
        url: 'quotes/gethomevri',
        requiresToken: false,
        data: vriRequestObject
      },
      actions: [asyncActionRequest, successAction, getVriFailureAction],
      passToAction: {
        lineOfBusiness,
        coverType,
        typeKeys: {
          request: QUOTE_GET_VRI_REQUEST,
          success: QUOTE_GET_VRI_SUCCESS,
          failure: QUOTE_GET_VRI_FAILURE
        }
      }
    }
  };
};

const successAction = (result, passToAction) => {
  return dispatch => {
    if (result.successful) {
      const { lineOfBusiness, coverType, quoteId } = result;
      const policyType = policyTypeByLob(lineOfBusiness);

      dispatch({ type: QUOTE_GET_VRI_SUCCESS, data: result });
      dispatch(
        push(
          `/vri/${policyType}/${lineOfBusiness.toLowerCase()}/${coverType.toLowerCase()}/overview/${quoteId}`
        )
      );
    } else {
      // successfully returned from endpoint but no gbg data to create a vri
      const { lineOfBusiness, coverType } = passToAction;
      const policyType = policyTypeByLob(lineOfBusiness);

      dispatch({ type: QUOTE_GET_VRI_SUCCESS, data: null });

      if (result.errorMessage.includes('declined')) {
        dispatch(
          push(
            `/vri/${policyType}/${lineOfBusiness.toLowerCase()}/${coverType.toLowerCase()}/declined`
          )
        );
      } else {
        dispatch(
          push(
            `/vri/${policyType}/${lineOfBusiness.toLowerCase()}/${coverType.toLowerCase()}/missing-data`
          )
        );
      }
    }
  };
};

const getVriFailureAction = (error, passToAction) => {
  return (dispatch, getStore) => {
    const { lineOfBusiness, coverType } = passToAction;
    const policyType = policyTypeByLob(lineOfBusiness);

    dispatch(asyncActionFailure(error, passToAction));
    dispatch({ type: QUOTE_GET_VRI_FAILURE, data: null });
    dispatch(push('/'));
  };
};

function policyTypeByLob(lineOfBusiness) {
  switch (lineOfBusiness.toLowerCase()) {
    case 'homebuyers':
      return 1;
    case 'home':
      return 2;
    case 'pirate':
      return 3;
    case 'renters':
      return 5;
  }
}
