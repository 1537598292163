import * as React from 'react';

export const ApplicationInterfaceContext = React.createContext<
  ApplicationInterface | undefined
>(undefined);

export interface ApplicationInterface {
  designSystem: {
    areBackgroundAndSurfaceEqual?: boolean;
  };
}
