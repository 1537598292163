import * as React from 'react';

import { CardElement } from '@stripe/react-stripe-js';
import type {
  StripeCardElement,
  StripeCardElementChangeEvent
} from '@stripe/stripe-js';

import { BladeInputElement, BladeInputVariant } from '@aventus/blade';
import { getAtomValue } from '@aventus/honey-utility';

import css from './index.css';

export const StripeCreditCard: React.ComponentType<IStripeCreditCard> = props => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const styleOptions = React.useMemo(
    () => ({
      base: {
        '::placeholder': {
          color: getAtomValue('--input-on') || '#000'
        },
        fontFamily: getAtomValue('--font-main') || 'inherit',
        fontSize: getAtomValue('--font-size-body') || '16px',
        color: getAtomValue('--input-on') || '#000'
      },
      invalid: {
        color: getAtomValue('--app-error') || '#e80e0e'
      }
    }),
    []
  );

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (props.setIsComplete) props.setIsComplete(event.complete);

    setErrorMessage(event.error ? event.error.message : '');

    if (props.onChange !== undefined) props.onChange(event);
  };

  return (
    <div className={css.scaffold}>
      <BladeInputElement
        name={'backdrop'}
        variant={props.inputVariant}
        onChange={() => {}}
        value={'s'}
      />

      <div className={css.stripeElement_scaffold}>
        <CardElement
          className={css.stripeElement_wrapper}
          onBlur={props?.onBlur}
          onChange={handleChange}
          onEscape={props?.onEscape}
          onFocus={props?.onFocus}
          onReady={props?.onReady}
          options={{
            style: styleOptions
          }}
        />
      </div>

      <label className={css.stripeElement_error}>{errorMessage}&nbsp;</label>
    </div>
  );
};

interface IStripeCreditCard {
  onBlur?: () => void;
  onFocus?: () => void;

  onChange?: (event: StripeCardElementChangeEvent) => void;
  onEscape?: () => void;
  onReady?: (element: StripeCardElement) => void;

  setIsComplete?: (isComplete: boolean) => void;
  inputVariant?: BladeInputVariant;
}
