import { IAventusCarDetails } from '@aventus/oracle-glasses/components/car-details/types';
import { isValidRegistration } from './is-valid-registration';

export function motorWebMustHaveVehicleDetails(
  value: IMotorWebValidationCarDetails
): string | boolean {
  const vehicle = (value.nvic && value.make) || value.make;
  const registration = isValidRegistration(value.registration, value.regOrVin);

  return vehicle && (registration || value.registrationFlag)
    ? true
    : 'We need your car details to continue with your quote.';
}

export type IMotorWebValidationCarDetails = IAventusCarDetails & {
  registrationFlag: boolean;
};
