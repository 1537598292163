export function objectMap (object: any, map: (property: any) => any): any {
  return (
    Object
      .keys(object)
      .reduce(
        (acc: any, curr: any) => ({
          ...acc,
          ...map(curr)
        }),
        {}
      )
  );
}
