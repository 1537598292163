import * as React from 'react';
import hash from 'object-hash';
import { isFormValid } from './is-form-valid';

export function useForm (getQuestions: any, getSubmit: any) {

  // We need to use useReducer because the setState from
  // a useState called within an array (each input inside this form
  // will trigger an onValidate which updates the validation state
  // through the setState passed) is not fast enough to register
  // all of the calls, and so each call overwrites the next. Using this
  // gets around the issue.

  const [ state, dispatch ] = (
    React.useReducer(
      (
        state: any,
        action: { type: string, value?: object }
      ) => {

        switch (action.type) {
          case 'validate': return { ...state, ...action.value };
          case 'reset': return {};
          default: throw new Error('no valid action found');
        }

    }, {})
  );

  // The form is validated recursively, meaning it doesn't matter
  // how nested the state form object, it will check through all of the
  // properties and return false if any of them are invalid.

  const _isFormValid = isFormValid(state);

  // Stores a hashed representation of the returned
  // questions. This allows us to identify when questions
  // have changed without triggering an un-mount and mount.

  const [ questionsHash, setQuestionsHash ] = React.useState<string>('');

  const questions = getQuestions(state, dispatch);
  const submit = getSubmit(_isFormValid);

  // We need some way to stringify the contents of the returned
  // questions, given the inputs should have a name defined
  // (our input components mark name as a required property, although
  // its not much to ask to expect that), we can build up a string representation
  // of the inner questions

  const questionsRepresentation = (
    questions.reduce(
      (acc: string, current: any) => {
        if (current && current.props) {
          return `${ acc }${ current.props.name }`;
        } else {
          return acc;
        }
      },
      ''
    )
  );

  const _hash = hash(questionsRepresentation);

  // If we've got a new hash it implies a new set of questions,
  // so we need to clear the validation we've got for the
  // current page, and save the hash for future comparisons.

  if (_hash !== questionsHash) {
    setQuestionsHash(_hash);
    dispatch({ type: 'reset' });
  }

  return {
    questions,
    submit
  };

}
