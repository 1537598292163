import * as React from 'react';
import { createGlassesClient } from '../../client';
import { Year, Make, Model, Variant, Series, AdvancedDetails } from '../../client/types';
import { GlassesClient } from '../../client/types';
import { useError } from '@aventus/pockethooks/use-error';
import { IAventusCarDetails } from './types';

export function useCarDetailsLookup(
  oracleUrl: string,
  oracleToken: string,
  updateCarDetails: (carDetails: IAventusCarDetails) => void,
  hasFailed: boolean
) {

  const [glasses, setGlasses] = React.useState<GlassesClient | undefined>(undefined);

  const [years, setYears] = React.useState<Year[]>([]);
  const [makes, setMakes] = React.useState<Make[]>([]);
  const [models, setModels] = React.useState<Model[]>([]);
  const [variants, setVariants] = React.useState<Variant[]>([]);
  const [series, setSeries] = React.useState<Series[]>([]);
  const [skipSeries, setSkipSeries] = React.useState<boolean>(false);

  const [selectedYear, setSelectedYear] = React.useState<Year | undefined>(undefined);
  const [selectedMake, setSelectedMake] = React.useState<Make | undefined>(undefined);
  const [selectedModel, setSelectedModel] = React.useState<Model | undefined>(undefined);
  const [selectedVariant, setSelectedVariant] = React.useState<Variant | undefined>(undefined);
  const [selectedSeries, setSelectedSeries] = React.useState<Series | undefined>(undefined);

  const [motors, setMotors] = React.useState<AdvancedDetails[]>([]);

  const [isQuerying, setIsQuerying] = React.useState<boolean>(false);

  const { throwError } = useError();

  // Initialise the experian client first,
  // making sure this is only done once. Using
  // [] as the observables tells React to only
  // run this on initial mount. The conditional check
  // is just extra insurance, mind the pun.

  React.useEffect(() => {
    if (!glasses) {
      const _motorWeb = createGlassesClient(
        { baseURL: oracleUrl },
        oracleToken
      );
      setGlasses(_motorWeb);
    }
  }, []);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (glasses && !hasFailed) {

        setIsQuerying(true);

        try {
          const response = await glasses.searchYear();
          setYears(response);
        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [glasses]);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (selectedYear && glasses && !hasFailed) {

        setIsQuerying(true);

        setMakes([]);
        setModels([]);
        setVariants([]);
        setSeries([]);
        setMotors([]);

        try {
          const response = await glasses.searchMake(selectedYear.YearCreate);
          setMakes(response);
        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [selectedYear]);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (selectedYear && selectedMake && glasses && !hasFailed) {

        setIsQuerying(true);

        setModels([]);
        setVariants([]);
        setSeries([]);
        setMotors([]);

        try {
          const response = await glasses.searchModel(selectedYear.YearCreate, selectedMake.code);
          setModels(response);
        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [selectedMake]);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (selectedModel && selectedYear && selectedMake && glasses && !hasFailed) {

        setIsQuerying(true);

        setVariants([]);
        setSeries([]);
        setMotors([]);

        try {
          const response = await glasses.searchVariant(selectedYear.YearCreate, selectedMake.code, selectedModel.Code);
          setVariants(response);
        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [selectedModel]);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (selectedVariant && selectedModel && selectedYear && selectedMake && glasses && !hasFailed) {

        setIsQuerying(true);

        setSeries([]);
        setMotors([]);

        try {
          const response = await glasses.searchSeries(selectedYear.YearCreate, selectedMake.code, selectedModel.Code, selectedVariant.Name);
          setSeries(response);

          // In the event that series returns without
          // any results, we can set a flag which will skip
          // waiting for a series option to be select, and
          // go straight to searching for the car

          if (response.length === 0) {
            setSkipSeries(true);
          }

        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [selectedVariant]);

  // TODO

  React.useEffect(() => {
    (async function () {
      if (
        (selectedSeries || skipSeries === true) &&
        selectedVariant && selectedModel && selectedYear && selectedMake && glasses && !hasFailed
      ) {

        setIsQuerying(true);

        setMotors([]);

        try {

          const response = await glasses.searchAdvancedDetails(
            selectedYear.YearCreate,
            selectedMake.code,
            selectedModel.Code,
            selectedVariant.Name,
            selectedSeries?.Code
          );

          if (response?.length === 1) {
            selectMotor(response[0].NVIC_CUR);
          }

          if (response?.length > 1) {
            setMotors(response);
          }

        } catch (error) {
          throwError(error);
        }

        setIsQuerying(false);

      }
    }())
  }, [selectedSeries, skipSeries]);

  async function selectMotor(nvic: string) {
    if (glasses) {
      const response = await glasses.enrichDetails(nvic);
      updateCarDetails({
        nvic: nvic,
        bodyType: response[0].BodyName,
        variant: response[0].VariantName,
        series: response[0].SeriesName,
        make: response[0].ManufacturerName,
        model: response[0].FamilyName,
        year: response[0].YearCreate,
        transmission: response[0].TransmissionName,
        cc: response[0].CCName,
        engineConfig: response[0].Engine_ConfigName,
        vinNo: null,
        regOrVin: null,
        registeredState: null,
        registration: null,
        kw: response[0].KW,
        kerbWeight: response[0].KerbWeight,
        segmentName: response[0].SegmentName,
        fuelName: response[0].FuelName
      });

    }
  }

  return {

    isQuerying,
    motors,
    selectMotor,

    years,
    selectedYear,
    setSelectedYear,

    makes,
    selectedMake,
    setSelectedMake,

    models,
    selectedModel,
    setSelectedModel,

    variants,
    selectedVariant,
    setSelectedVariant,

    series,
    skipSeries,
    selectedSeries,
    setSelectedSeries
  };

}
