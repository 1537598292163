import { Dispatch, useReducer, useState } from 'react';
import {
  ITobeStartSuccess,
  ITobesQuestion,
  ITobesArrayPage,
  ITobesArrayAnswer,
  ITobesArrayAnswerItem,
  ITobesQuoteBundle,
  ITobesGetQuestionPageRequest,
  ITobesSubmitQuestionPageRequest,
  ITobesPage,
  ITobesQuestionMap
} from '@aventus/platform-client-context/models/tobes';
import { isEmpty } from 'lodash';
import {
  getToken,
  storeToken,
  bakeTokenCookie
} from '@aventus/platform-client-context/token-service';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useApplicationTimezone } from '@aventus/application-timezone';
import moment, { Moment } from 'moment';
import { getExposureReferenceId } from '../helpers/index';
import { History } from 'history';
import { PaymentPlanType } from '@aventus/platform';
import { useMutation, useQuery } from 'react-query';
import usePlatform from '@aventus/platform-client-context/use-platform';
import { useError } from '@aventus/pockethooks/use-error';
import { TobesError } from '../errors/tobes-error';
import { useLocation } from 'react-router';
import {
  IUsePaymentPlan,
  usePaymentPlan
} from '@aventus/mvmt-pricing/hooks/use-payment-plan';
import { buildURLQueryString } from '@aventus/pocketknife/query-param-builder';
import { QuoteType } from '@aventus/platform-client-context/models/quote';
import { createQuestionMap } from '../helpers/createQuestionMap';
import {
  formStateReducer,
  initialFormState,
  TobesQuestionPageState,
  TobesQuestionPageStateActions
} from '../store/form-state';
import { validateQuestions } from '../helpers/Validation/validateQuestions';

export function useSimpleQuote(
  productID: string,
  questionSetReferenceID: string,
  history: History,
  setChangesMade: (value: boolean) => void,
  isHybridProduct: boolean,
  editSessionID?: string,
  exposureID?: string,
  quoteType?: QuoteType,
  pageIndex?: string,
  orgLevelToken?: string | null
): IUseSimpleQuote {
  const platform = usePlatform();
  const location = useLocation();

  const { throwError } = useError();

  const [pagesConfig, setPagesConfig] = useState<IUseSimeplQuotePagesConfig>({
    currentPage: pageIndex && /^\d*$/.test(pageIndex) ? Number(pageIndex) : 0,
    firstPage: 0,
    lastPage: 0,
    currentPageConfig: { canBePriced: false }
  });

  const [questions, setQuestions] = useState<ITobesQuestionMap[] | []>([]);
  const [formState, dispatch] = useReducer(formStateReducer, initialFormState);

  const chromeQuery = queryParamGet(location?.search || '', 'chrome');
  const chrome = chromeQuery === null ? true : chromeQuery === 'true';

  const { toPlatformReadable } = useApplicationTimezone();

  const exposureReference = getExposureReferenceId();

  const [storedToken, setStoredToken] = useState<string>('');

  const clearFieldValidation = (referenceID: string) =>
    dispatch({ type: 'CLEAR_FIELD_VALIDATION', payload: referenceID });

  // Start tobes
  const {
    data: tobesStartData,
    isLoading: isTobesStartLoading,
    isError: isTobesStartError
  } = useQuery<any, unknown, ITobeStartSuccess>(
    [
      'tobes',
      'start',
      {
        editSessionID,
        questionSetReferenceID,
        exposureReference,
        exposureID
      }
    ],
    () => {
      if (!editSessionID) {
        throwError(
          new Error('Unable to start tobes. Cannot find edit session.')
        );
        return;
      }

      return platform.tobes.tobesStart(
        editSessionID,
        questionSetReferenceID,
        exposureReference,
        exposureID || null
      );
    },
    {
      enabled: !!editSessionID && !!questionSetReferenceID,
      onSuccess: (tobesData: ITobeStartSuccess) => {
        // Getting first page from tobes to set current page and start page have index 2
        const [firstPage] = tobesData.pages;
        const _pageIndex = Number(pageIndex);
        const lastPage = tobesData.pages[tobesData.pages.length - 1];
        const exposureId = tobesData.exposureID;

        const newToken = getToken(orgLevelToken);
        setStoredToken(newToken);
        storeToken(newToken);
        bakeTokenCookie(newToken);

        sessionStorage.setItem(
          'question_pages',
          JSON.stringify(tobesData.pages)
        );

        setPagesConfig({
          ...pagesConfig,
          currentPage: _pageIndex || firstPage.number,
          firstPage: firstPage.number,
          lastPage: lastPage.number,
          currentPageConfig: {
            canBePriced: false
          }
        });

        if (editSessionID) {
          tobesQuestionPage.mutateAsync({
            exposureID: exposureId,
            page: _pageIndex || firstPage.number,
            questionSetReferenceID,
            editSessionID
          });
        }
      }
    }
  );

  // get question page.
  const tobesQuestionPage = useMutation(
    ({
      exposureID,
      page,
      questionSetReferenceID,
      editSessionID
    }: ITobesGetQuestionPageRequest) =>
      platform.tobes.getQuestionPage(
        exposureID,
        page,
        questionSetReferenceID,
        editSessionID
      ),
    {
      onSuccess: questionPage => {
        dispatch({ type: 'INIT_FORM_STATE', payload: questionPage.questions });
        // We want to clear this flag when we save the form state
        setChangesMade(false);
        setQuestions(questionPage.questions.map(createQuestionMap));
      },
      onError: error => {
        throwError(
          new TobesError(
            'Unable to load question page, please contact support if you are unable to continue.',
            error
          )
        );
      }
    }
  );

  // Submit question answers
  const tobesSubmitQuestionAnswers = useMutation(
    ({
      editSessionID,
      page,
      questionSetReferenceID,
      answers,
      exposureID
    }: ITobesSubmitQuestionPageRequest) =>
      platform.tobes.tobesSubmit(
        editSessionID,
        page,
        questionSetReferenceID,
        answers,
        exposureID
      ),
    {
      onError: error => {
        throwError(
          new TobesError(
            'There was a problem submitting this page, please try again and contact support if this issue continues.',
            error
          )
        );
      }
    }
  );

  const tobesConfirmMTAQuote = useMutation(
    ({ editSessionID }: { editSessionID: string }) =>
      platform.tobes.tobesMTAQuote(editSessionID, true),
    {
      onError: error => {
        throwError(
          new TobesError(
            'Unable to confirm MTA quote. Please try again.',
            error,
            true
          )
        );
      }
    }
  );

  const tobesConfirmQuote = useMutation(
    ({ editSessionID }: { editSessionID: string }) =>
      platform.tobes.confirmQuote(editSessionID),
    {
      onError: error => {
        throwError(
          new TobesError(
            'Unable to confirm quote. Please try again.',
            error,
            true
          )
        );
      }
    }
  );

  const questionPage = tobesQuestionPage.data;

  // Get tobes pricing
  const { data: tobesQuoteBundle, isLoading: isLoadingPricing } = useQuery(
    ['tobes', 'price', editSessionID],
    async () => {
      if (editSessionID === undefined) {
        return;
      }

      if (quoteType === 'MTA') {
        return platform.tobes.tobesMTAPrice(
          editSessionID,
          questionPage?.number || pagesConfig.currentPage,
          questionSetReferenceID,
          exposureReference,
          getQuestionAnswers(questions)
        );
      }

      return platform.tobes.tobesPrice(
        editSessionID,
        questionPage?.number || pagesConfig.currentPage,
        questionSetReferenceID,
        exposureReference,
        getQuestionAnswers(questions)
      );
    },
    {
      enabled:
        !!editSessionID &&
        (questionPage?.canPrice || pagesConfig.currentPageConfig.canBePriced)
    }
  );

  const paymentPlan = usePaymentPlan(tobesQuoteBundle?.requestedQuotePricing);

  const goToNextPage = async (pageNumber: number, goPrev: boolean = false) => {
    setChangesMade(false);
    if (editSessionID) {
      tobesQuestionPage
        .mutateAsync({
          exposureID: tobesStartData?.exposureID || null,
          page: pageNumber,
          questionSetReferenceID,
          editSessionID
        })
        .then(questionPage => {
          if (questionPage.questions.length === 0) {
            tobesQuestionPage.mutateAsync({
              exposureID: tobesStartData?.exposureID || null,
              page: !goPrev
                ? pageNumber + 1
                : pageNumber > 1
                ? pageNumber - 1
                : 1,
              questionSetReferenceID,
              editSessionID
            });

            return;
          }

          // This adds the pageIndex to the query when we move to a
          // new page. It should not cause any side effects because we are
          // only modifing one part of the route.

          history.push(
            `/products/${productID}/quote/${quoteType}/${questionSetReferenceID}/${
              questionPage.number
            }${buildURLQueryString({
              editSessionId: editSessionID,
              token: getToken(),
              exposureId: exposureID,
              exposureReferenceId: exposureReference,
              chrome: chrome ? 'true' : 'false'
            })}`
          );

          setPagesConfig({
            ...pagesConfig,
            currentPage: questionPage.number,
            currentPageConfig: {
              canBePriced: questionPage.canPrice || false
            }
          });

          window.scrollTo(0, 0);
        });
    }
  };

  // Test this for immutability.

  const getQuestionAnswers = (
    questions: ITobesQuestionMap[],
    isParentRendered?: boolean
  ): ITobesArrayAnswerItem[] =>
    questions.map((question: ITobesQuestionMap) => {
      // Added a check to see whether the question meets its conditions,
      // if not we set the value to null.
      const isRendered =
        isParentRendered !== false &&
        checkQuestionConditionalOperator(question) !== false;

      return {
        questionReferenceID: question.referenceID,
        answer: isRendered
          ? transformQuestionAnswer(
              question.dataType,
              question.existingValue,
              question.referenceID,
              question.arrayPage
            )
          : null,
        fields: getQuestionAnswers(question.fields, isRendered),
        arrayItems:
          isRendered && Array.isArray(question.existingValue)
            ? question.existingValue
            : []
      };
    });

  const getNextQuestionPage = async (
    isPrevious = false,
    paymentPlanType?: PaymentPlanType
  ) => {
    if (!editSessionID) {
      throwError(new Error('Cannot find an active session.'));
      return;
    }

    if (!questionPage) {
      throwError(new Error('Question page not loaded correctly.'));
      return;
    }

    const pageNumber = questionPage?.number;
    const nextPageNumber = pageNumber + 1;
    const previousPageNumber = pageNumber - 1;

    const questionsAnswers = getQuestionAnswers(questions);

    if (!isPrevious) {
      // Clear any existing validation.
      dispatch({ type: 'CLEAR_VALIDATION' });

      // Run validation before we submit.
      const validationErrors = await validateQuestions(
        checkDepedencyQuestions(questions)
      );

      if (validationErrors.size > 0) {
        // Do something to trigger validation messages?
        dispatch({
          type: 'SET_VALIDATION_ERRORS',
          payload: validationErrors
        });

        // If validation errors box pops up - scroll to it.
        setTimeout(() => {
          document
            .getElementById('ValidationErrors')
            ?.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        return;
      }

      tobesSubmitQuestionAnswers
        .mutateAsync({
          editSessionID,
          page: pageNumber,
          questionSetReferenceID,
          answers: questionsAnswers,
          exposureID: tobesStartData?.exposureID || null
        })
        .then(async () => {
          if (questionPage.number === pagesConfig?.lastPage) {
            if (isHybridProduct) {
              if (quoteType === 'MTA') {
                tobesConfirmMTAQuote
                  .mutateAsync({ editSessionID })
                  .then(response => {
                    history.push(
                      `/quote/${response.requestedQuote.id}?token=${storedToken}&plan=${paymentPlanType}`
                    );
                  });
              } else {
                tobesConfirmQuote
                  .mutateAsync({ editSessionID })
                  .then(response => {
                    history.push(
                      `/quote/${response.requestedQuote.id}?token=${storedToken}&plan=${paymentPlanType}`
                    );
                  });
              }
            } else {
              switch (quoteType) {
                case 'Vri':
                  history.push(
                    `/products/${productID}/quick-quote-summary/${editSessionID}?token=${storedToken}&chrome=${chrome}&plan=${paymentPlanType}`
                  );
                  break;
                default:
                  history.push(
                    `/products/${productID}/variantSummary/${quoteType}?editSessionId=${editSessionID}&token=${storedToken}&chrome=${chrome}&plan=${paymentPlanType}`
                  );
              }
            }
          } else {
            await goToNextPage(nextPageNumber, isPrevious);
          }
        });
    } else {
      await goToNextPage(previousPageNumber, isPrevious);
    }
  };

  const checkQuestionConditionalOperator = (question: ITobesQuestion) => {
    if (question.conditionOperator === 'And') {
      if (!checkQuestionConditions(question).includes(false)) {
        return question;
      } else {
        return false;
      }
    } else if (question.conditionOperator === 'Or') {
      if (checkQuestionConditions(question).includes(true)) {
        return question;
      } else {
        return false;
      }
    }
    // If we don't define a condition operator, lets just check the conditions and return the value
    return checkQuestionConditions(question);
  };

  const checkQuestionConditions = (question: ITobesQuestion) => {
    return question.conditions.map(condition => {
      const questionAnswer = getQuestionAnswer(condition.questionReferenceID);

      if (questionAnswer !== undefined && questionAnswer !== null) {
        switch (condition.conditionType) {
          case 'Boolean':
            return questionAnswer === condition.conditionValue;

          case 'ListItem':
            const inList = condition.conditionValue?.filter(
              (conditionName: string) => {
                return conditionName === questionAnswer[condition.fieldPath];
              }
            );

            return !isEmpty(inList);

          case 'ListItemNotIn':
            const notInList = condition.conditionValue?.filter(
              (conditionName: string) =>
                conditionName === questionAnswer[condition.fieldPath]
            );
            return isEmpty(notInList);

          case 'ArrayNotEmptyOrNull':
            return !isEmpty(condition.conditionValue);

          case 'NotNull':
            return condition.conditionValue != null;

          case 'GreaterThan':
            if (
              typeof questionAnswer === 'object' &&
              'value' in questionAnswer
            ) {
              return (
                Number(questionAnswer.value) > Number(condition.conditionValue)
              );
            }

            return Number(questionAnswer) > Number(condition.conditionValue);
          case 'LessThan':
            if (
              typeof questionAnswer === 'object' &&
              'value' in questionAnswer
            ) {
              return (
                Number(questionAnswer.value) < Number(condition.conditionValue)
              );
            }

            return Number(questionAnswer) < Number(condition.conditionValue);
          default:
            return false;
        }
      }
      return false;
    });
  };

  const checkDepedencyQuestions = (questions: ITobesQuestionMap[] | []) =>
    questions.filter(question => {
      return checkQuestionConditionalOperator(question);
    });

  // TODO: Worth breaking this out however due to getQuestionAnswer working the way it does, this requires some thought on refactoring this out

  const getQuestionAnswer = (referenceID: string, parent?: string): any => {
    const question = questions.find(
      question =>
        question.referenceID === referenceID || question.referenceID === parent
    );

    if (referenceID && parent && !isEmpty(question?.fields)) {
      const _field = question?.fields.find(
        field => field.referenceID === referenceID
      );

      return _field?.existingValue;
    } else if (isEmpty(question?.fields)) {
      return question?.existingValue;
    } else if (!isEmpty(question?.fields)) {
      return question?.fields;
    }
  };

  // This uses the questions state to get the answer.

  const getArrayDescription = (
    questionReferenceID: string,
    template?: string
  ): string => {
    const question = questions.find(
      question => question.referenceID === questionReferenceID
    );

    const arrayPage = question ? question.arrayPage : null;

    let description = '';
    if (arrayPage !== null) {
      if (template) {
        const selectedFields = template.split(',');
        selectedFields.forEach(sf => {
          description +=
            arrayPage.questions.filter(ap => ap.referenceID === sf)[0]
              .existingValue + ' ';
        });
        return description;
      }

      for (var i = 0; i < Math.min(arrayPage.questions.length, 3); i++) {
        if (
          arrayPage.questions[i].existingValue != null &&
          (typeof arrayPage.questions[i].existingValue === 'string' ||
            arrayPage.questions[i].existingValue instanceof String)
        ) {
          description += arrayPage.questions[i].existingValue + ' ';
        }
      }
    }

    return description;
  };

  const transformQuestionAnswer = (
    questionType: string,
    value: any,
    questionReferenceID: string | null,
    arrayPage?: ITobesArrayPage<ITobesQuestionMap> | null
  ) => {
    let answer: unknown = value;

    // We expect this to be null due to the way oracles work, they create the object and then put null values in the object
    // Had to add a null check here because typeof null === "object" and was breaking this code.
    if (
      questionReferenceID === 'q_province' &&
      value !== null &&
      typeof value === 'object' &&
      value.text === null &&
      value.referenceID === null
    ) {
      return null;
    }

    switch (questionType) {
      case 'DateTime':
      case 'LocalDateTime':
        if (value !== null && value !== undefined) {
          answer = toPlatformReadable(
            typeof answer === 'string' ? moment(answer) : (answer as Moment)
          );
        }
        break;
      case 'Integer':
      case 'Decimal':
        answer = Number(value);
        break;
      // we might need to pass the entire question in here as this isn't transforming array type questions
      case 'Array':
        if (arrayPage && answer) {
          const arrayAnswer = answer as Array<any>;
          arrayAnswer.forEach((answerArray, index) => {
            const transformAnswer = arrayPage?.questions.map(
              (question: ITobesQuestionMap) => ({
                questionReferenceID: question.referenceID,
                answer: transformQuestionAnswer(
                  question.dataType,
                  answerArray.answers
                    .filter(
                      (answer: { questionReferenceID: string }) =>
                        answer.questionReferenceID === question.referenceID
                    )
                    .reduce((result: { answer: any }) => result.answer).answer,
                  question.referenceID,
                  question.arrayPage
                ),
                fields: getQuestionAnswers(question.fields)
              })
            );
            arrayAnswer[index].answers = transformAnswer;
          });
          break;
        } else {
          break;
        }
    }

    return answer;
  };

  const changeQuestionAnswer = (
    referenceID: string,
    value: any,
    parentReference?: string | null,
    arrayQuestion?: boolean
  ) => {
    const isLookupAnswer =
      !arrayQuestion &&
      value &&
      typeof value === 'object' &&
      'uniqueReference' in value;

    const _questions = questions.map((question: ITobesQuestionMap) => {
      if (parentReference === question.referenceID) {
        const _questionFields = question.fields.map(
          (field: ITobesQuestionMap) => {
            if (field.referenceID === referenceID) {
              if (field.existingValue !== value) {
                setChangesMade(true);
              }
              return { ...field, existingValue: value };
            } else {
              return field;
            }
          }
        );
        return {
          ...question,
          fields: _questionFields
        };
      }

      if (
        referenceID === question.referenceID &&
        value &&
        arrayQuestion &&
        !isLookupAnswer
      ) {
        var existingArray: ITobesArrayAnswer[] = Array.isArray(
          question.existingValue
        )
          ? [...question.existingValue]
          : [];

        if (existingArray.filter(x => x.id === value.id).length > 0) {
          const index = existingArray.findIndex(obj => obj.id === value.id);

          if (value.answers === null) {
            setChangesMade(true);
            existingArray.splice(index, 1);
          } else {
            setChangesMade(true);
            existingArray[index].answers = value.answers;
          }
        } else {
          setChangesMade(true);
          existingArray.push(value);
        }
        return {
          ...question,
          existingValue: existingArray
        };
      }

      if (referenceID === question.referenceID && !isLookupAnswer) {
        if (question.existingValue !== value) {
          setChangesMade(true);
        }
        return {
          ...question,
          existingValue: value
        };
      }

      if (isLookupAnswer && referenceID === question.referenceID) {
        if (question.existingValue !== value) {
          setChangesMade(true);
        }

        return {
          ...question,
          ...{
            existingValue: value,
            fields: question.fields.map(field => ({
              ...field,
              ...{
                existingValue:
                  field.referenceID === 'q_uniquereference' ||
                  field.referenceID === 'q_address_id'
                    ? cleanAddress(field.referenceID, value)
                    : value[field.referenceID.replace('q_', '')]
              }
            }))
          }
        };
      }

      if (!isEmpty(question.conditions)) {
        if (checkQuestionConditionalOperator(question) !== false) {
          return question;
        } else {
          return { ...question, existingValue: null };
        }
      } else {
        return question;
      }
    });

    setQuestions(_questions);
  };

  const cleanAddress = (reference: string, value: any) => {
    if (reference === 'q_uniquereference') {
      return value['uniqueReference'];
    }

    if (reference === 'q_address_id') {
      return value['addressIds'];
    }
  };

  return {
    formState,
    pages: tobesStartData?.pages || [],
    pageConfig: {
      description: questionPage?.description,
      image: questionPage?.image,
      number: questionPage?.number,
      title: questionPage?.title,
      tooltip: questionPage?.tooltip,
      group: questionPage?.group,
      canPrice:
        questionPage?.canPrice || pagesConfig?.currentPageConfig?.canBePriced
    },
    getNextQuestionPage,
    getQuestionAnswer,
    changeQuestionAnswer,
    isCurrentPageFailed:
      tobesQuestionPage.isError || tobesSubmitQuestionAnswers.isError,
    getArrayDescription,
    questions: checkDepedencyQuestions(questions),
    isWorking:
      isTobesStartLoading ||
      tobesQuestionPage.isLoading ||
      tobesSubmitQuestionAnswers.isLoading ||
      tobesConfirmQuote.isLoading ||
      tobesConfirmMTAQuote.isLoading ||
      isLoadingPricing,
    isFirstPage: pagesConfig?.currentPage === pagesConfig?.firstPage,
    isLastPage: pagesConfig?.currentPage === pagesConfig?.lastPage,
    pageNumber: pagesConfig?.currentPage,
    tobesError:
      isTobesStartError ||
      tobesQuestionPage.isError ||
      tobesSubmitQuestionAnswers.isError,
    validationDetails: undefined, //TODO: did this work before anyway?
    tobesQuoteBundle,
    paymentPlan,
    isLoadingPricing,
    dispatch,
    clearFieldValidation
  };
}

interface IUseSimeplQuotePagesConfig {
  currentPage: number;
  firstPage?: number;
  lastPage?: number;
  currentPageConfig: {
    canBePriced: boolean;
  };
}

export interface IUseSimpleQuote {
  pages: ITobesPage[];
  pageNumber: number | undefined;
  questions: ITobesQuestionMap[] | [];
  formState: TobesQuestionPageState;
  isWorking: boolean;
  isFirstPage: boolean;
  isLastPage: boolean;
  isCurrentPageFailed: boolean;
  pageConfig: {
    description?: string;
    image?: string;
    number?: number;
    title?: string;
    tooltip?: string;
    canPrice: boolean;
    group?: string | null;
  };
  tobesQuoteBundle?: ITobesQuoteBundle;
  tobesError: boolean;
  getNextQuestionPage: (
    isPrevious?: boolean,
    paymentPlanType?: PaymentPlanType
  ) => void;
  getQuestionAnswer: (referenceID: string) => any;
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parentReference?: string | null
  ) => void;
  getArrayDescription: (
    questionReferenceID: string,
    template?: string
  ) => string;
  validationDetails?: string;
  paymentPlan: IUsePaymentPlan;
  isLoadingPricing: boolean;
  dispatch: Dispatch<TobesQuestionPageStateActions>;
  clearFieldValidation: (referenceID: string) => void;
}
