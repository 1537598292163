import * as React from 'react';

export function useSelect(
  value: any,
  _options: any[],
  matchOn: string,
  labelOn = 'text',
  onChange: (value: any) => void,
  multiSelect?: boolean,
  defaultLabel?: string
): IUseSelect {
  // Defines whether the select input is focused,
  // and the selector menu is open.
  // This is primarily used as a hook to perform
  // any interface updates when the selector is open
  // and closed.

  const [isSelectOpen, setIsSelectOpen] = React.useState<boolean>(false);

  // Keeps track of the index of the selected option in the
  // options list, so that we can increment and decrement that index
  // in stepper mode.

  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);

  // The default value defined when
  // none of the options are selected.

  const DEFAULT = {
    [labelOn]: defaultLabel || 'Choose',
    disabled: true
  };

  // We'll add this to the top of the options
  // list so it can be selected by the Select
  // component.

  const options: any[] = multiSelect ? [..._options] :[DEFAULT, ..._options];

  // We want to track the index of the selected
  // option in the list of options so that we can
  // enable stepper functionality.

  if (value) {
    options.find((option: any, i: number) => {
      const isAMatch = option[matchOn] === value[matchOn];
      if (isAMatch && selectedIndex !== i) {
        setSelectedIndex(i);
      }
      return isAMatch;
    });
  }

  // Wrapper around the provided onChange function.
  // This is so that the stepper can add an extra check
  // when selecting an item, and block any attempt to set
  // an invalid item, in this case the default option.

  function onSelect(selectedItem: any) {
    onChange(
      selectedItem && selectedItem[labelOn] !== DEFAULT[labelOn]
        ? selectedItem
        : null
    );
  }

  // Stepper controls to either increment
  // or decrement the index of the option,
  // and therefore the selected option.

  function stepUp() {
    if (selectedIndex + 1 <= options.length - 1) {
      onChange(options[selectedIndex + 1]);
    }
  }

  function stepDown() {
    // We are making sure the index is greater than
    // 1 instead of 0 here to account for the default
    // option that is added to the top of the options list.
    // We don't want to step into that value.

    if (selectedIndex > 1) {
      onChange(options[selectedIndex - 1]);
    }
  }

  return {
    options,
    onSelect,
    isSelectOpen,
    setIsSelectOpen,
    stepUp,
    stepDown,
    DEFAULT
  };
}

export interface IUseSelect {
  options: any[];
  onSelect: (selectedItem: any) => void;
  isSelectOpen: boolean;
  setIsSelectOpen: (value: boolean) => void;
  stepUp: () => void;
  stepDown: () => void;
  DEFAULT: any;
}
