import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { PolicyBundle } from '@aventus/platform';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const useGetPolicyBundle = (
  policyId: string | undefined,
  observable?: any
) => {
  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();
  const { updateProductRef } = useDispatchEvent();

  const [policyBundle, setPolicyBundle] = React.useState<
    PolicyBundle | undefined
  >(undefined);
  const [isGettingPolicyBundle, setIsGettingPolicyBundle] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {
      if (policyId) {
        setIsGettingPolicyBundle(true);

        try {
          const _getPolicyBundle = await symphonyClient.getPolicyBundle(
            policyId
          );
          setPolicyBundle(_getPolicyBundle);
          updateProductRef(_getPolicyBundle.product.referenceID);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyBundle(false);
      }
    })();
  }, [policyId, observable]);

  return {
    policyBundle,
    isGettingPolicyBundle
  };
};
