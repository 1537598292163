import { WIDGET_RESET_STATE } from './_reset-widget-state';
import {
  WIDGET_SET_PARTNER_INFO,
  setPartnerInfoReducer
} from './_set-partner-info';
import {
  WIDGET_SET_EXTERNAL_USER_ID,
  setExternalUserIdReducer
} from './_set-external-user-id';
import { initialState } from './initial-state';

import {
  getVriRequest,
  getVriSuccess,
  getVriFailure,
  QUOTE_GET_VRI_REQUEST,
  QUOTE_GET_VRI_SUCCESS,
  QUOTE_GET_VRI_FAILURE
} from './get-vri';

export default function(state = initialState, action) {
  switch (action.type) {
    case WIDGET_SET_PARTNER_INFO:
      return setPartnerInfoReducer(state, action);
    case WIDGET_SET_EXTERNAL_USER_ID:
      return setExternalUserIdReducer(state, action);

    case QUOTE_GET_VRI_REQUEST:
      return getVriRequest(state, action);
    case QUOTE_GET_VRI_SUCCESS:
      return getVriSuccess(state, action);
    case QUOTE_GET_VRI_FAILURE:
      return getVriFailure(state, action);

    case WIDGET_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
