import { CheckoutCallToActionsConfiguration } from '@aventus/configuration';
import { AdjustmentType } from '@aventus/platform';

export function getAdjustCallToAction (
  adjustmentType: AdjustmentType,
  callToActionConfiguration: CheckoutCallToActionsConfiguration
): string | undefined {

  switch (adjustmentType) {

    case 'FixedSingleNoFee':
    case 'FinanceNoFee':
    case 'SubscriptionNoFee':
      return callToActionConfiguration.adjustNoFee;

    case 'FixedSinglePayment':
    case 'FinancePayment':
    case 'SubscriptionPayment':
      return callToActionConfiguration.adjustPayNow;

    case 'FixedSingleRefund':
    case 'FinanceRefund':
    case 'SubscriptionRefund':
      return callToActionConfiguration.adjustRefund;

    case 'FinanceUpdate':
      return callToActionConfiguration.adjustUpdateFinance;

    default:
      return undefined;
  }

}
