import React from 'react';
import appApi from '../../../api';
import routes from '../../../routes';
import _render from './render';
import { updateProductRef } from '@aventus/pocketknife/dispatch-event';

class ChangePassword extends React.Component {
  state = {
    oldPassword: '',
    isOldPassWordVisible: '',
    newPassword: '',
    isNewPasswordVisible: ''
  };

  componentDidMount() {
    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.changePassword.trackingName
    });
    updateProductRef(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const authProps = this.props.app.auth;
    const nextAuthProps = nextProps.app.auth;

    if (
      !authProps._flags.successfullyChangedPassword &&
      nextAuthProps._flags.successfullyChangedPassword
    ) {
      this.props.handleAuthClearFlag('successfullyChangedPassword');
      this.props.handleQueueAlert({
        message: 'Your password was updated',
        location: routes.home.path,
        type: 'success'
      });
      this.props.history.push(routes.home.path);
    }
  }

  onPressChangePassword = () => {
    const { oldPassword, newPassword } = this.state;
    const { _oldPassword, _newPassword } = this.props.app.auth.credentials;

    if (oldPassword && newPassword && !_oldPassword && !_newPassword) {
      this.props.handleAuthChangePassword(oldPassword, newPassword);
    } else {
      this.props.handleAuthValidateCredentials('newPassword', newPassword);
    }
  };

  render = _render;
}

export default appApi(ChangePassword);
