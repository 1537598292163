import * as React from 'react';
import { BladeSurface, BladeList } from '@aventus/blade';
import { addressToHumanReadable } from '@aventus/pocketknife/address-to-human-readable';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { Address } from '@aventus/platform';

export const DetailsOfInsuree: React.FunctionComponent<IDetailsOfInsuree> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  return (
    <BladeSurface
      title={'Your details'}
      shouldRespectViewWidth={props.shouldRespectViewWidth}
    >
      <BladeList
        isFlush={true}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
        list={[
          {
            label: 'Policy holder',
            value: `${props.proposer.personName.firstName} ${props.proposer.personName.lastName}`
          },
          ...(props.proposer.address && props.proposer.address.address1
            ? [
                {
                  label: 'Correspondence address',
                  value: props.proposer.address.address1,
                  moreDetails: addressToHumanReadable(props.proposer.address)
                }
              ]
            : []),
          {
            label: 'Date of birth',
            value: toHumanReadable(props.proposer.dateOfBirth)
          }
        ]}
      />
    </BladeSurface>
  );
};

interface IDetailsOfInsuree {
  proposer: Proposer;
  shouldRespectViewWidth?: boolean;
}

interface Proposer {
  address: Address;
  age: number;
  dateOfBirth: string;
  emailAddress: string;
  hasPreviousClaims: boolean;
  personName: {
    title?: string;
    firstName: string;
    lastName: string;
  };
  phoneNumber: number;
  requiresEmailAddress: boolean;
}
