import React from 'react';
import { ButtonVariants } from '../button';
import css from './index.css';
import { useMediaQuery } from '@aventus/pockethooks';
import cx from 'classnames';
import { BladeButton, BladeInputSwitch, BladeIcon } from '@aventus/blade';

export const BladeListValue: React.FunctionComponent<IBladeListValue> = props => {
  const { matchesQuery } = useMediaQuery('(max-width: 600px)');
  
  if (typeof props.buttonAction === "function" || typeof props.onRemoveClick === "function") {
    return (
      <span className={css.list_item_action_panel}>
        {typeof props.buttonAction === "function" && (
          <BladeButton
            variant={props.buttonVariant}
            className={cx(css.list_item_button_action, props.buttonClass)}
            onClick={() => (props.buttonAction ? props.buttonAction() : '')}
          >
            {props.value}
          </BladeButton>
        )}

        {typeof props.onRemoveClick === "function" && (
          <BladeButton
            className={cx(props.buttonClass, css.list_item_button_close)}
            onClick={() => (props.onRemoveClick ? props.onRemoveClick() : '')}
            isDisabled={props.isRemoveDisabled}
            isDestructive
          >
            <BladeIcon name="faTimes" />
          </BladeButton>
        )}
      </span>
    )
  }

  if (props.value === true && !props.isTogglable) {
    return <BladeIcon name={'faCheck'} />;
  } else if (props.value === false && !props.isTogglable) {
    return <BladeIcon name={'faTimes'} />;
  } else if (props.isTogglable && props.togglableOnChange) {
    return (
      <span className={css.list_item_toggle}>
        <BladeInputSwitch
          value={props.value}
          name={'listToggle'}
          onChange={props.togglableOnChange}
          loading={props.togglableLoading}
          className={css.toggleOverride}
        ></BladeInputSwitch>
      </span>
    );
  } else if (Array.isArray(props.value)) {
    return (
      <ul
        className={cx(
          css.list_item_row_list,
          { [css.list_item_row_list_mobile]: matchesQuery },
          { [css.list_item_row_list_compact]: props.isCompactList }
        )}
      >
        {props.value.map((element, index) => {
          return (
            <li
              key={index}
              className={cx({
                [css.list_item_row_list_boldItem]:
                  index === 0 && props.isBoldFirstItem === true
              })}
            >
              {element}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <span
        className={cx(css.list_item_row_value, {
          [css.list_item_label_bold]: props.isBold
        })}
      >
        {props.value}
      </span>
    );
  }
};

interface IBladeListValue {
  value: boolean | string | string[];
  isBold?: boolean;
  isBoldFirstItem?: boolean;
  isCompactList?: boolean;
  isTogglable?: boolean;
  togglableOnChange?: (value: boolean) => any;
  togglableLoading?: boolean;
  buttonAction?: () => void;
  onRemoveClick?: () => void;
  isRemoveDisabled?: boolean;
  buttonVariant?: ButtonVariants;
  buttonClass?: string;
}
