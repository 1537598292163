import {
  AUTH_START_USER_LOGIN_REQUEST,
  AUTH_START_USER_LOGIN_SUCCESS,
  AUTH_START_USER_LOGIN_FAILURE
} from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../core/alerts/queue-alert/actions';

export function startUserLoginAction(email, recaptchaToken) {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/startuserlogin',
        method: 'POST',
        data: { emailAddress: email, token: recaptchaToken }
      },
      actions: [
        startUserLoginRequestAction,
        startUserLoginSuccessAction,
        startUserLoginFailureAction
      ]
    }
  };
}

export function startUserLoginRequestAction() {
  return {
    type: AUTH_START_USER_LOGIN_REQUEST
  };
}

export function startUserLoginSuccessAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_START_USER_LOGIN_SUCCESS,
      hasLoggedInPreviously: res
    });
  };
}

export function startUserLoginFailureAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_START_USER_LOGIN_FAILURE
    });

    dispatch(
      queueAlertAction({
        type: 'error',
        message: res.message,
        location: false
      })
    );
  };
}
