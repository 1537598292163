import * as React from 'react';
import css from './index.css';
import { PolicyList as MvmtPolicyList } from '@aventus/mvmt-policy';
import { useGetPolicies } from '@aventus/symphony-client/hooks';
import { BladeLoader } from '@aventus/blade';
import { useEffect, useState } from 'react';
import {
  PolicyActions,
  getPolicyListActions
} from '@aventus/mvmt-policy/services/get-policy-list-actions';
import { useApplicationTimezone } from '@aventus/application-timezone';

export const PolicyList: React.FunctionComponent<IPolicyList> = props => {
  const { policies, isGettingPolicies } = useGetPolicies(false);

  const { toHumanReadable } = useApplicationTimezone();
  const [policyActions, setPolicyActions] = useState<PolicyActions>();

  useEffect(() => {
    if (policies) {
      var _policyActions = getPolicyListActions(
        policies,
        props.goToPolicy,
        toHumanReadable,
        props.image,
        props.icon,
        props.label
      );
      setPolicyActions(_policyActions);
    }
  }, [policies]);

  return (
    <>
      {(isGettingPolicies || !policyActions) && (
        <div className={css.policyList}>
          <BladeLoader />
        </div>
      )}
      {!isGettingPolicies &&
        policyActions &&
        policyActions.active.length > 0 && (
          <MvmtPolicyList
            policies={policyActions?.active ?? []}
            layout={props.layout || 'vertical'}
            goToPolicy={props.goToPolicy}
            image={props.image}
            label={props.label}
            icon={props.icon}
            heading="Your active policies"
          />
        )}
      {!isGettingPolicies &&
        policyActions &&
        policyActions.inactive.length > 0 && (
          <MvmtPolicyList
            policies={policyActions?.inactive ?? []}
            layout={props.layout || 'vertical'}
            goToPolicy={props.goToPolicy}
            image={props.image}
            label={props.label}
            icon={props.icon}
            heading="Your previous policies"
          />
        )}
    </>
  );
};

interface IPolicyList {
  goToPolicy: (policyId: string) => void;
  image: string;
  label: string;
  layout?: string;
  icon: string;
}
