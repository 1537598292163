import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useSymphonyClient } from '@aventus/symphony-client/hooks';
import { PolicyDocument } from '@aventus/platform';

export function useDocuments(
  id: string | undefined,
  type: string
): UseDocuments {
  const symphony = useSymphonyClient();

  const [product, setProduct] = useState<
    { productReferenceID: string; coverType: string } | undefined
  >(undefined);

  const [documents, setDocuments] = useState<PolicyDocument[] | []>([]);

  useAsyncEffect(async () => {
    if (id && type) {
      if (type === 'policy') {
        const _policy = await symphony.getPolicy(id);
        if (_policy) {
          setDocuments(_policy.documents);

          setProduct({
            productReferenceID: _policy.productReferenceID,
            coverType: _policy.coverType
          });
        }
      }

      if (type === 'quote') {
        const _quote = await symphony.getQuote(id);
        if (_quote) {
          setDocuments(_quote.requestedQuote.documents);
          setProduct({
            productReferenceID: _quote.requestedQuote.productReferenceID,
            coverType: _quote.requestedQuote.coverType
          });
        }
      }
    }
  }, [id, type]);

  return {
    documents,
    product
  };
}

interface UseDocuments {
  documents: PolicyDocument[] | [];
  product: { productReferenceID: string; coverType: string } | undefined;
}
