import debounce from 'lodash/debounce';
import { app, widgets } from 'homelyfe/modules';

const loadStateFromLocal = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

const saveStateToLocal = (state) => {
  try {
    window.localStorage.removeItem('state');
    window.localStorage.setItem('state', JSON.stringify(state));
  } catch (err) {
    console.warn(err);
    // ignore write errors
  }
};

const subscribeStoreToLocalStorage = (store) => {
  store.subscribe(debounce(() => {
    const state = store.getState();
    const statePartial = {
      app: app.localStorageStatePartial(state),
      widgets: widgets.localStorageStateWidgetPartial(state)
    };

    saveStateToLocal(statePartial);
  }, 1000));
};

export {
  loadStateFromLocal,
  saveStateToLocal,
  subscribeStoreToLocalStorage
};
