import * as React from 'react';
import { IBladeFormFieldset, IBladeInputText } from '@aventus/blade';
import { ApplicationError } from '@aventus/errors';
import { OracleAddressTypes } from '@aventus/configuration';
import { AventusUkAddressLookup } from '@aventus/oracle-aventus-uk';
import { ExperianAddressLookup } from '@aventus/oracle-experian';
import { AventusAddressLookup } from '@aventus/oracle-aventus-universal-address';

export function switchAddressOracle(
  props: IBladeFormFieldset & IBladeInputText,
  oracleName: OracleAddressTypes,
  oracleUrl: string,
  oracleToken?: string | null,
  oracleMiscText?: string
) {
  switch (oracleName) {
    case 'aventus-uk':
      return (
        <AventusUkAddressLookup
          {...props}
          oracleUrl={oracleUrl}
          helperText={oracleMiscText}
        />
      );

    case 'aventus-universal':
      return (
        <AventusAddressLookup
          {...props}
          oracleUrl={oracleUrl}
          helperText={oracleMiscText}
        />
      );
    case 'experian':
      if (!oracleToken) {
        throw new ApplicationError(
          'Application is trying to use the `OracleAddressExperian` integration, but no auth token was provided.'
        );
      }

      return (
        <ExperianAddressLookup
          {...props}
          oracleUrl={oracleUrl}
          oracleToken={oracleToken}
          helperText={oracleMiscText}
        />
      );

    default:
      return null;
  }
}
