import * as React from 'react';
import { DeclineReason, IDeclineReason } from '@aventus/platform';
import {
  BladeView,
  BladeSurface,
  BladeButton,
  BladeMarkdown
} from '@aventus/blade';
import { useDocumentTitle } from '@aventus/pockethooks';
import { markdownUnorderedFromArray } from '@aventus/pocketknife/markdown-unordered-from-array';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { useTrackView } from '@aventus/application-tracking';
import css from './index.css';

export const IntelligentQuoteDecline: React.FunctionComponent<IIntelligentQuoteDecline> = props => {
  useDocumentTitle(props.documentTitle);
  useTrackView('IntelligentQuoteDecline');

  return (
    <BladeView variant={'s'} className={css.declined}>
      <BladeSurface
        title={props.title || "You've been declined"}
        shouldRespectViewWidth={true}
      >
        {props.subTitle && (
          <h3 className={css.declined_secondTitle}>
            {props.subTitle || 'What you need to do next.'}
          </h3>
        )}
        <div className={css.declined_reasons}>
          {!props.reasons && (
            <BladeMarkdown
              markdown={
                props.declineWithNoReason ||
                "We're sorry, but we weren't able to insure you this time."
              }
            />
          )}

          {props.reasons?.length === 1 && (
            <BladeMarkdown
              markdown={
                props.declineWithOneReason + ` ${props.reasons?.[0].text}.` ||
                "We're sorry, but we weren't able to insure you this time because "
              }
            />
          )}

          {props.reasons && props.reasons.length > 1 && (
            <BladeMarkdown
              markdown={`${
                props.declineWithMultipleReasons ||
                "We're sorry, but we weren't able to insure you this time because:"
              }\n${markdownUnorderedFromArray(
                props.reasons,
                (reason: IDeclineReason) => reason.text
              )}`}
            />
          )}
        </div>

        {props.help && (
          <BladeSurface isAside={true}>
            <div className={css.declined_help}>
              <BladeMarkdown
                markdown={stringReplacer(props.help, {
                  $$MATCHURL: props.supportLink || ''
                })}
              />
            </div>
          </BladeSurface>
        )}

        <div className={css.declined_trigger}>
          <BladeButton onClick={() => props.onAcknowledgement()}>
            {props.triggerLabel || 'Ok'}
          </BladeButton>
        </div>
      </BladeSurface>
    </BladeView>
  );
};

export interface IIntelligentQuoteDecline {
  documentTitle?: string;
  title?: string;
  reasons?: DeclineReason[];
  declineWithNoReason?: string;
  declineWithOneReason?: string;
  declineWithMultipleReasons?: string;
  help?: string;
  supportLink?: string;
  triggerLabel?: string;
  subTitle?: string;
  onAcknowledgement: (event?: React.ChangeEvent<HTMLButtonElement>) => void;
}
