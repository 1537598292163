import { BladeFormFieldset, BladeInputDatePicker, BladeInputDateSelect, BladeInputText } from "@aventus/blade";
import { BladeInputDateManualEntry } from "@aventus/blade/input/date-manual-entry";
import { ICommonFieldProps, ICommonInputProps } from "@aventus/mvmt-simplequote/models/commonProps";
import { IFormatters } from "@aventus/mvmt-simplequote/models/formatters";
import { ISettings } from "@aventus/mvmt-simplequote/models/settings";
import { ITobesQuestion } from "@aventus/platform-client-context/models";
import React from 'react';

export function renderDate(
    question: ITobesQuestion,
    commonFieldsetProps: ICommonFieldProps,
    commonInputProps: ICommonInputProps,
    settings: ISettings,
    formatters: IFormatters
  ) {
    if (question.questionType.toLowerCase() === 'inputdate') {
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputDateManualEntry
            {...commonInputProps}
            now={formatters.dateToPlatformReadable(
              formatters.dateToApplicationReadable()
            )}
            dateFormat={settings.dateFormat ? settings.dateFormat : 'DD/MM/YYYY'}
          />
        </BladeFormFieldset>
      );
    } else if (question.questionType.toLowerCase() === 'inputdatemonthyear') {
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputDateManualEntry
            {...commonInputProps}
            now={formatters.dateToPlatformReadable(
              formatters.dateToApplicationReadable()
            )}
            dateFormat={'MM/YYYY'}
          />
        </BladeFormFieldset>
      );
    } else if (question.questionType.toLowerCase() === 'datepicker') {
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputDatePicker
            {...commonInputProps}
            timezone={settings.timezone}
            validationRules={question.validationRules}
            toPlatformReadable={formatters.dateToPlatformReadable}
            toApplicationReadable={formatters.dateToApplicationReadable}
          />
        </BladeFormFieldset>
      );
    }else if (question?.questionType?.toLowerCase() === 'datedropdownfuture') {
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputDateSelect {...commonInputProps} future />
        </BladeFormFieldset>
      );
    } else if (question?.questionType?.toLowerCase() === 'datedropdownpast') {
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputDateSelect {...commonInputProps} />
        </BladeFormFieldset>
      );
    } else {
      return (
        // if no question type has been defined for date then use string
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputText {...commonInputProps} />
        </BladeFormFieldset>
      );
    }
  }
  