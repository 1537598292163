import _callApi from './_call-api';
import _deserializeError from './_deserialize-error';
import { blockUserAction } from '../../app/api/auth/block-user';
import {
  getAuth,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink
} from 'firebase/auth';
import { request } from 'http';
import { signInWithLinkAction } from '../../app/api/auth/sign-in-with-link';
import {
  ERROR_SIGN_IN_LINK_EXPIRED,
  ERROR_SIGN_IN_INVALID_EMAIL,
  ERROR_SIGN_IN_FAILURE_DEFAULT
} from './error-messages';

export const AUTH0_API_SYM = Symbol('AUTH0_API');

export default store => next => action => {
  const auth0Request = action[AUTH0_API_SYM];

  // If there are no requests with the AUTH0_API symbol
  // then we pass the action back to the dispatcher.

  if (typeof auth0Request === 'undefined') {
    return next(action);
  }

  const { requestConfig, actions, passToAction = {} } = auth0Request;

  if (typeof requestConfig.url !== 'string') {
    throw new Error('Specify a string URL');
  }

  if (!Array.isArray(actions) || actions.length !== 3) {
    throw new Error('Expected an array of three action types');
  }

  const [requestAction, successAction, failureAction] = actions;

  store.dispatch(requestAction(passToAction));

  const state = store.getState();

  if (
    state.app.core.config.auth0.defaultAuthenticationProvider === 'Firebase'
  ) {
    firebase(store, requestConfig, successAction, failureAction, passToAction);
  } else {
    auth0(store, requestConfig, successAction, failureAction, passToAction);
  }
};

export function firebase(
  store,
  requestConfig,
  successAction,
  failureAction,
  passToAction
) {
  const state = store.getState();
  const auth = getAuth();

  if (state.app.core.config.auth0.firebase.tenantID) {
    auth.tenantId = state.app.core.config.auth0.firebase.tenantID;
  }

  // Default Method - Sign in with one-time email link
  if (
    isSignInWithEmailLink(auth, window.location.href) &&
    requestConfig.data.username &&
    !requestConfig.data.password
  ) {
    signInWithEmailLink(auth, requestConfig.data.username, window.location.href)
      .then(userCredential => {
        userCredential.user
          .getIdToken()
          .then(token => {
            store.dispatch(
              signInWithLinkAction(
                token,
                {
                  email: userCredential.user.email,
                  sub: userCredential.user.uid
                },
                'google',
                'Firebase'
              )
            );
          })
          .catch(error => {
            store.dispatch(failureAction(error.message));
          });
      })
      .catch(error => {
        let errorMsg = '';
        switch (error.message) {
          case 'Firebase: Error (auth/invalid-action-code).':
            errorMsg = ERROR_SIGN_IN_LINK_EXPIRED;
            break;
          case 'Firebase: The email provided does not match the sign-in email address. (auth/invalid-email).':
            errorMsg = ERROR_SIGN_IN_INVALID_EMAIL;
            break;
          default:
            errorMsg = ERROR_SIGN_IN_FAILURE_DEFAULT;
        }
        store.dispatch(failureAction(errorMsg));
      });
  }

  // Sign in with username and password
  if (requestConfig.data.username && requestConfig.data.password) {
    signInWithEmailAndPassword(
      auth,
      requestConfig.data.username,
      requestConfig.data.password
    )
      .then(userCredential => {
        userCredential.user
          .getIdToken()
          .then(token => {
            store.dispatch(successAction(token, passToAction));
          })
          .catch(error => {
            store.dispatch(failureAction(error.message));
          });
      })
      .catch(error => {
        if (state.app.core.config.auth0.providers.includes('Auth0')) {
          auth0(
            store,
            requestConfig,
            successAction,
            failureAction,
            passToAction
          );
        } else {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          store.dispatch(failureAction(null));
        }
      });
  }
}

export function auth0(
  store,
  requestConfig,
  successAction,
  failureAction,
  passToAction
) {
  const state = store.getState();
  const { env, auth0 } = state.app.core.config;
  const url = `${auth0.url}/${requestConfig.url}`;

  _callApi(url, requestConfig, env)
    .then(result =>
      store.dispatch(successAction(result.data.access_token, passToAction))
    )
    .catch(error => {
      if (error?.response?.status === 429) {
        store.dispatch(blockUserAction(passToAction.username));
        store.dispatch(failureAction('NO_ERROR_MESSAGE'));
      } else {
        store.dispatch(failureAction(_deserializeError(error)));
      }
    });
}
