import {
  ITobesArrayAnswer,
  ITobesQuestionMap
} from '@aventus/platform-client-context/models';
import { IStoredFieldAnswer } from '../models/fieldAnswer';

export function initFromQuestion(
  originalArray: IStoredFieldAnswer[],
  existingAnswer: ITobesArrayAnswer[],
  questionReference: string,
  updateArray: (array: IStoredFieldAnswer[]) => void
) {
  const valuesArray = [...originalArray];
  let arrayUpdated: boolean = false;
  if (
    originalArray.filter(oa => oa.parentQuestionReference === questionReference)
      .length === 0
  ) {
    existingAnswer.forEach((array: ITobesArrayAnswer) => {
      array.answers.forEach(aw => {
        valuesArray.push({
          answer: aw.answer,
          uniqueReferenceId: array.id ? array.id : '',
          fieldReference: aw.questionReferenceID,
          parentQuestionReference: questionReference,
          validationErrors: []
        });
      });
      arrayUpdated = true;
    });
  }
  if (arrayUpdated) {
    updateArray(valuesArray);
  }
}

export function updateAnswerArray(
  originalArray: IStoredFieldAnswer[],
  updateArray: (array: IStoredFieldAnswer[]) => void,
  uniqueReferenceId: string,
  fieldReference: string,
  questionRefenceId: string,
  newAnswer: any
) {
  const valuesArray = [...originalArray];

  let value = valuesArray.findIndex(
    f =>
      f.uniqueReferenceId === uniqueReferenceId &&
      f.fieldReference === fieldReference &&
      f.parentQuestionReference === questionRefenceId
  );

  if (value === -1) {
    // We don't have this answer in our array
    valuesArray.push({
      answer: newAnswer,
      uniqueReferenceId: uniqueReferenceId,
      fieldReference: fieldReference,
      parentQuestionReference: questionRefenceId,
      validationErrors: []
    });
  } else {
    valuesArray[value].answer = newAnswer;
  }

  updateArray(valuesArray);
}

export async function validateArrayAnswers(
  formID: string,
  arrayPageQuestions: ITobesQuestionMap[],
  collectionArray: IStoredFieldAnswer[],
  setCollectionState: (array: IStoredFieldAnswer[]) => void,
  parentReferenceID: string
): Promise<boolean> {
  // This gets a bit weird because the collection doesn't
  // always contain the question if it has not been touched.
  // We therefore have to inject the missing questions back into
  // the collection further down.

  // This map loops through the expected questions and passes
  // the value if it exists, if not we send undefined to the validation.
  const validatedValues = await Promise.all(
    arrayPageQuestions.map(async question => {
      const value = collectionArray.find(
        f =>
          f.uniqueReferenceId === formID &&
          f.fieldReference === question.referenceID &&
          f.parentQuestionReference === parentReferenceID
      );

      const validationResult = await question.validate(value?.answer, question);

      if (value) {
        return {
          ...value,
          validationErrors: validationResult.errors
        };
      } else {
        return {
          answer: undefined,
          uniqueReferenceId: formID,
          fieldReference: question.referenceID,
          parentQuestionReference: parentReferenceID,
          validationErrors: validationResult.errors
        };
      }
    })
  );

  // Here we merge the two arrays back into a collection
  // ready to be updated.
  const newCollection = collectionArray.reduce(
    (a: IStoredFieldAnswer[], c: IStoredFieldAnswer) => {
      if (
        a.find(
          x =>
            x.uniqueReferenceId === c.uniqueReferenceId &&
            x.fieldReference === c.fieldReference
        )
      ) {
        return a;
      } else {
        return [...a, c];
      }
    },
    validatedValues
  );

  setCollectionState(newCollection);

  // If any of the questions fail we return a false
  return !validatedValues.some(x => x.validationErrors.length > 0);
}

export function alterAnswerInArray(
  formId: string,
  questionReferenceId: string,
  collectionArray: IStoredFieldAnswer[],
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null,
    arrayQuestion?: boolean
  ) => void
) {
  var formInstanceVariables = collectionArray
    .filter(ca => ca.uniqueReferenceId === formId)
    .map(a => ({ questionReferenceID: a.fieldReference, answer: a.answer }));

  // We really don't want malformed answers going in
  if (formInstanceVariables === undefined || formInstanceVariables === null) {
    throw new Error('Answer being added was null or undefined');
  }

  var massagedAnswer = {
    id: formId,
    answers: formInstanceVariables
  };
  // TODO: We don't support array with parent questions atm
  changeQuestionAnswer(questionReferenceId, massagedAnswer, null, true);
}

export function removeAnswerInArray(
  formId: string,
  questionReferenceId: string,
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null,
    arrayQuestion?: boolean
  ) => void
) {
  var massagedAnswer = {
    id: formId,
    answers: null //Signify that we are going to nuke this from the array
  };
  // TODO: We don't support array with parent questions atm
  changeQuestionAnswer(questionReferenceId, massagedAnswer, null, true);
}
