import update from 'immutability-helper';

export function firebaseSignInRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isFirebaseSigningIn: { $set: true }
        }
      })
    }
  };
}

export function firebaseSignInSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
            isFirebaseSigningIn: { $set: false }
        }
      })
    }
  };
}

export function firebaseSignInFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isFirebaseSigningIn: false
          }
        }
      })
    }
  }
}


