import * as React from 'react';
import { Moment } from 'moment-timezone';
import { ApplicationError } from '@aventus/errors';
import { ApplicationTimezoneContext } from './context';

import { dateSerialise } from './date-serialise';
import { dateDeserialise } from './date-deserialise';

export const useApplicationTimezone: UseApplicationTimezone = () => {
  const context = React.useContext(ApplicationTimezoneContext);

  if (context === undefined) {
    throw new ApplicationError(
      'useApplicationTimezone must be used within an ApplicationTimezoneContext.Provider'
    );
  }

  if (context.orgTimezone) {
    const timezone: string = context.orgTimezone;

    const toHumanReadable: ToHumanReadable = (date, formatOverride) => {
      return dateDeserialise(date, timezone).format(
        formatOverride || context.formats?.human || 'Do MMMM YYYY'
      );
    };

    const toApplicationReadable: ToApplicationReadable = (
      date,
      timezoneOverride
    ) => {
      return dateDeserialise(date, timezoneOverride || timezone);
    };

    const toPlatformReadable: ToPlatformReadable = date => {
      return dateSerialise(date).format(
        context.formats?.platform || 'YYYY-MM-DDTHH:mm:ss'
      );
    };

    return {
      toHumanReadable,
      toApplicationReadable,
      toPlatformReadable,
      timezone
    };
  } else {
    throw new ApplicationError(
      'useApplicationTimezone is attempting to access the orgTimezone before it was initialised'
    );
  }
};

export type UseApplicationTimezone = () => ApplicationTimezoneApi;

export interface ApplicationTimezoneApi {
  toHumanReadable: ToHumanReadable;
  toApplicationReadable: ToApplicationReadable;
  toPlatformReadable: ToPlatformReadable;
  timezone: string;
}

export type ToHumanReadable = (date: string, formatOverride?: string) => string;
export type ToApplicationReadable = (
  date?: string,
  timezoneOverride?: string
) => Moment;
export type ToPlatformReadable = (momentDate: Moment) => string;
