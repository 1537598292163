import * as React from 'react';
import css from './index.css';

export const BladeInput_Frame: React.FunctionComponent<IBladeInput_Frame> = props => {
  return (
    <div className={css._frame}>
      {props.displayErrorAbove && props.hasBeenInteractedWith && typeof props.error === 'string' && (
        <small className={css._frame_error}>
          {props.error || 'This value is invalid.'}
        </small>
      )}
      {props.children}

      {!props.displayErrorAbove && props.hasBeenInteractedWith && typeof props.error === 'string' && (
        <small className={css._frame_error}>
          {props.error || 'This value is invalid.'}
        </small>
      )}
    </div>
  );
};

interface IBladeInput_Frame {
  hasBeenInteractedWith: boolean;
  error?: string;
  displayErrorAbove?: boolean;
}
