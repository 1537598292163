import { TRACKING_SET_EXTERNAL_ID } from './types';
import { v4 as uuidV4 } from 'uuid';
import getExternalId from '../get-external-id';

export default function setExternalIdAction(externalId, email) {
  return (dispatch, getStore) => {

    const uuid = getExternalId(getStore()) || uuidV4();
    const externalIdToUse = externalId || uuid;

    dispatch({
      type: TRACKING_SET_EXTERNAL_ID,
      externalId: externalIdToUse
    });

  };
}
