import * as React from 'react';
import { Quote, Risk } from '@aventus/platform';
import { useApplicationTimezone } from '@aventus/application-timezone';
import css from './index.css';

export const ReceiptSummary: React.FunctionComponent<IReceiptSummary> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  return (
    <div className={css.receiptSummary}>
      <h2 className={css.receiptSummary_label}>{props.label}</h2>

      {props.risk && (
        <div className={css.receiptSummary_coveredRange}>
          <div className={css.receiptSummary_coveredRange_pill}>
            {toHumanReadable(props.risk.coverStartDate, 'DD MMM YYYY')}
          </div>

          <span>{'↦'}</span>

          <div className={css.receiptSummary_coveredRange_pill}>
            {toHumanReadable(props.risk.coverEndDate, 'DD MMM YYYY')}
          </div>
        </div>
      )}

      <label className={css.receiptSummary_reference}>
        {props.policyReferenceID || props.quote.quoteReferenceID}
      </label>
    </div>
  );
};

interface IReceiptSummary {
  label: string;
  quote: Quote;
  risk: Risk;
  policyReferenceID?: string;
}
