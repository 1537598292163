import update from 'immutability-helper';

export function searchAddressLookupRequestReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        addressLookup: {
          _flags: {
            loadingAddressList: { $set: true }
          }
        }
      })
    }
  }
}

export function searchAddressLookupSuccessReducer(state, action) {

  const { results, encryptKey } = action;

  const transformedAddress = results
    ?
      results.map(address => {
        return {
          address1: address.address1 ? address.address1.replace(/&amp;/g, '&') || address.address1 : null,
          address2: address.address2 ? address.address2.replace(/&amp;/g, '&') || address.address2 : null,
          town: address.town ? address.town.replace(/&amp;/g, '&') || address.town : null,
          postalCode: address.postalCode ? address.postalCode.replace(/&amp;/g, '&') || address.postalCode : null,
          country: address.country ? address.country : null
        }
      })
    : null;

  return {
    ...state,
    ...{
      core: update(state.core, {
        addressLookup: {
          data: {
            results: { $set: transformedAddress },
            encryptKey: { $set: encryptKey }
          },
          _flags: {
            loadingAddressList: { $set: false }
          }
        }
      })
    }
  }
}

export function searchAddressLookupFailureReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        addressLookup: {
          _flags: {
            loadingAddressList: { $set: false }
          }
        }
      })
    }
  }
}
