import * as React from 'react';
import { BladeMarkdown } from '../markdown';
import { BladeModal } from '../modal';
import { BladeLink } from '../link';
import { BladeTooltip } from '../tooltip';
import { BladeIcon } from '../icon';
import { BladeLinkImageIconOrLabel } from '../link-image-icon-or-label';
import css from './index.css';
import cx from 'classnames';

export const BladeFormFieldset: React.FunctionComponent<
  IBladeFormFieldset
> = props => {
  const [pageKey, setPageKey] = React.useState<string | undefined>(undefined);
  const [showMoreDetails, setShowMoreDetails] = React.useState<boolean>(false);

  if (props.isDisabled) {
    return null;
  }

  return (
    <fieldset
      id={props.referenceKey}
      className={cx(
        'blade-form-fieldset',
        css.formFieldset,
        props.containerClass,
        {
          [css.formFieldset_spacetop]: props.topSpace,
          [css.fieldSetHasError]: !!props.errorMessage
        }
      )}
    >
      {props.displayOptionalTitle && (
        <div className={css.formFieldset_topLefTitle}>Optional Cover</div>
      )}
      <div className={css.formFieldset_scaffold}>
          {(props.icon || props.image) && (
            <BladeLinkImageIconOrLabel
              icon={props.icon}
              classNameIcon={css.formFieldset_assetBar_icon}
              image={props.image}
              classNameImage={css.formFieldset_assetBar_image}
            />
          )}

          {props.rightIcon && (
            <BladeIcon
              classNameWrap={css.formFieldset_icon_right_wrapper}
              className={css.formFieldset_icon_right}
              name={props.rightIcon}
            />
          )}

          {(props.question || props.renderQuestionAction) && (
            <div className={css.formFieldset_questionBar}>
              {props.question && (
                <legend className={css.formFieldset_questionBar_legend}>
                  {props.question}{' '}
                  {props.required && (
                    <span className={css.fieldSetRequired}>*</span>
                  )}
                </legend>
              )}

              {props.tooltip && <BladeTooltip markdown={props.tooltip} />}

              {props.renderQuestionAction && props.renderQuestionAction}
            </div>
          )}

          {props.description && (
            <BladeMarkdown
              markdown={props.description}
              action={
                props.descriptionPages
                  ? (pageKeyToOpen: string) => setPageKey(pageKeyToOpen)
                  : undefined
              }
            />
          )}

          {props.help && (
            <BladeMarkdown
              className={css.formFieldset_help}
              markdown={props.help}
            />
          )}

          {props.moreDetails && (
            <BladeLink
              className={css.formFieldset_moreDetails_link}
              onClick={() => setShowMoreDetails(true)}
            >
              {'More details'}
            </BladeLink>
          )}

        <div className={css.formFieldset_scaffold_inputs}>
          <div
            className={cx(
              // css.formFieldset_inputs,
              { [css.formFieldset_inputs_isGroup]: props.isGroup },
              props.className
            )}
          >
            {props.children}
          </div>

          {props.errorMessage && (
            <div className={css.formFieldset_errorMessage}>
              {props.errorMessage}
            </div>
          )}

          {props.addonBottom && (
            <div className={css.formFieldset_addonBottom}>
              {props.addonBottom}
            </div>
          )}
        </div>

        {pageKey && props.descriptionPages && (
          <BladeModal withFrame={true} close={() => setPageKey(undefined)}>
            <BladeMarkdown markdown={props.descriptionPages[pageKey]} />
          </BladeModal>
        )}

        {showMoreDetails && (
          <BladeModal withFrame={true} close={() => setShowMoreDetails(false)}>
            <BladeMarkdown markdown={props.moreDetails} />
          </BladeModal>
        )}
      </div>
    </fieldset>
  );
};

export interface IBladeFormFieldset {
  question?: string | null;
  renderQuestionAction?: React.ReactElement;
  errorMessage?: string | null;
  addonBottom?: React.ReactElement | string;
  description?: string | null;
  containerClass?: string | null;
  descriptionPages?: Nullable<{
    [key: string]: string;
  }>;
  rightIcon?: string;
  help?: string | null;
  moreDetails?: any;
  className?: string;
  isGroup?: boolean;
  isDisabled?: boolean;
  /*Defines whether this Fieldset is beinng used as a panel question. */
  isPanel?: PanelLayout;
  isSectionHeader?: boolean;
  icon?: string;
  image?: string;
  tooltip?: string;
  displayOptionalTitle?: boolean;
  topSpace?: boolean;
  countryFilter?: string[];
  referenceKey?: string;
  required?: boolean;
}

export enum  PanelLayout {
  TwoColumns = "TwoColumns",
  ThreeColumns = "ThreeColumns"
}
