import * as React from 'react';
import { IGlassesCarDetails } from './types';
import {
  BladeFormFieldset,
  BladeLink,
  BladeInputSelect,
  BladeLoader,
  BladeMessage,
  BladeSelector
} from '@aventus/blade';
import { useCarDetailsLookup } from './use-car-details-lookup';
import { Boundary } from '../../boundary';
import css from './index.css';
import cx from 'classnames';

export const GlassesCarDetails: React.FunctionComponent<IGlassesCarDetails> = props => (
  <Boundary>
    <_GlassesCarDetails {...props} />
  </Boundary>
);

const _GlassesCarDetails: React.FunctionComponent<IGlassesCarDetails> = props => {
  const {
    isQuerying,
    motors,
    selectMotor,
    years,
    selectedYear,
    setSelectedYear,
    makes,
    selectedMake,
    setSelectedMake,
    models,
    selectedModel,
    setSelectedModel,
    variants,
    selectedVariant,
    setSelectedVariant,
    series,
    skipSeries,
    selectedSeries,
    setSelectedSeries
  } = useCarDetailsLookup(
    props.oracleUrl,
    props.oracleToken,
    props.onChange,
    props.error?.recoverableError !== null ||
      props.error?.unrecoverableError !== null
  );

  return (
    <BladeFormFieldset
      question={'Your car'}
      renderQuestionAction={
        isQuerying ? (
          <BladeLoader className={css.carDetails_isQuerying} />
        ) : undefined
      }
      className={css.scaffold}
    >
      <div className={cx(css.carDetails, isQuerying ? 'disabled' : '')}>
        <BladeInputSelect
          variant={props.variant}
          label={'Year'}
          name={props.name + 'year'}
          id={props.name + 'year'}
          value={selectedYear}
          onChange={setSelectedYear}
          options={years}
          matchOn={'YearCreate'}
          labelOn={'YearCreate'}
        />

        <BladeInputSelect
          variant={props.variant}
          label={'Make'}
          name={props.name + 'make'}
          id={props.name + 'make'}
          value={selectedMake}
          onChange={setSelectedMake}
          className={!makes ? 'disabled' : ''}
          options={makes}
          matchOn={'code'}
          labelOn={'name'}
        />

        <BladeInputSelect
          variant={props.variant}
          label={'Model'}
          name={props.name + 'model'}
          id={props.name + 'model'}
          value={selectedModel}
          onChange={setSelectedModel}
          className={!models ? 'disabled' : ''}
          options={models}
          matchOn={'Code'}
          labelOn={'Name'}
        />

        <BladeInputSelect
          variant={props.variant}
          label={'Variant'}
          name={props.name + 'variant'}
          id={props.name + 'variant'}
          value={selectedVariant}
          onChange={setSelectedVariant}
          className={!variants ? 'disabled' : ''}
          options={variants}
          matchOn={'Name'}
          labelOn={'Name'}
        />

        <BladeInputSelect
          variant={props.variant}
          label={'Series'}
          className={skipSeries === true || !series ? 'disabled' : ''}
          name={props.name + 'series'}
          id={props.name + 'series'}
          value={selectedSeries}
          onChange={setSelectedSeries}
          options={series}
          matchOn={'Name'}
          labelOn={'Name'}
        />

        {motors?.length > 1 && (
          <>
            <label>{'Results'}</label>

            <BladeSelector
              isScrollable={true}
              options={motors.map(motor => ({
                label: motor.ModelName,
                data: motor
              }))}
              onSelect={motor => {
                selectMotor(motor.data.NVIC_CUR);
              }}
            />
          </>
        )}
      </div>

      {props.useLookupEntry && (
        <BladeLink isSmall={true} onClick={() => props.useLookupEntry?.()}>
          {'Search using car registration'}
        </BladeLink>
      )}

      {(props.error?.recoverableError || props.error?.unrecoverableError) && (
        <BladeMessage
          variant={'error'}
          message={
            props.error?.recoverableError?.message ||
            props.error?.unrecoverableError?.message
          }
        />
      )}
    </BladeFormFieldset>
  );
};
