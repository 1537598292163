import {
  CreateGlassesClient,
  SearchYear,
  Year,
  SearchMake,
  Make,
  SearchModel,
  Model,
  SearchVariant,
  Variant,
  SearchSeries,
  Series,
  SearchAdvancedDetails,
  AdvancedDetails,
  GlassesEnrichmentDetails,
  EnrichDetails
} from './types';
import { AxiosPromise } from 'axios';
import { createClient } from '@aventus/client';
import { interceptResponse } from './intercept-response';

export const createGlassesClient: CreateGlassesClient = function (
  options,
  token
) {
  const glasses = createClient(options);

  glasses.interceptors.response.use(
    interceptResponse.onFulfilled,
    interceptResponse.onRejected
  );

  const searchYear: SearchYear = async () => {
    const response = await (<AxiosPromise<Year[]>>(
      glasses.get(`?operation=years&modeltypecode=A&apikey=${token}`)
    ));

    return response.data;
  };

  const searchMake: SearchMake = async year => {
    const response = await (<AxiosPromise<Make[]>>(
      glasses.get(
        `?operation=makes&modeltypecode=A&year=${year}&apikey=${token}`
      )
    ));

    return response.data;
  };

  const searchModel: SearchModel = async (year, make) => {
    const response = await (<AxiosPromise<Model[]>>(
      glasses.get(
        `?operation=models&modeltypecode=A&year=${year}&manufacturercode=${encodeURIComponent(
          make
        )}&apikey=${token}`
      )
    ));

    return response.data;
  };

  const searchVariant: SearchVariant = async (year, make, model) => {
    const response = await (<AxiosPromise<Variant[]>>(
      glasses.get(
        `?operation=variants&modeltypecode=A&year=${year}&manufacturercode=${encodeURIComponent(
          make
        )}&modelcode=${encodeURIComponent(model)}&apikey=${token}`
      )
    ));

    return response.data;
  };

  const searchSeries: SearchSeries = async (year, make, model, variant) => {
    const response = await (<AxiosPromise<Series[]>>(
      glasses.get(
        `?operation=series&modeltypecode=A&year=${year}&manufacturercode=${encodeURIComponent(
          make
        )}&modelcode=${encodeURIComponent(
          model
        )}&variantcode=${encodeURIComponent(variant)}&apikey=${token}`
      )
    ));

    return response.data;
  };

  const searchAdvancedDetails: SearchAdvancedDetails = async (
    year,
    make,
    model,
    variant,
    series
  ) => {
    const seriesQuery = series
      ? `&seriescode=${encodeURIComponent(series)}`
      : '';

    const response = await (<AxiosPromise<AdvancedDetails[]>>(
      glasses.get(
        `?operation=nvicadvancedsearch&modeltypecode=A&year=${year}&manufacturercode=${encodeURIComponent(
          make
        )}&modelcode=${encodeURIComponent(
          model
        )}&variantcode=${encodeURIComponent(
          variant
        )}${seriesQuery}&apikey=${token}`
      )
    ));

    return response.data;
  };

  const enrichDetails: EnrichDetails = async nvic => {
    const response = await (<AxiosPromise<GlassesEnrichmentDetails[]>>(
      glasses.get(
        `?operation=getbasiccardetails&modeltypecode=A&nvic=${nvic}&apikey=${token}`
      )
    ));

    return response.data;
  };

  return {
    searchYear,
    searchMake,
    searchModel,
    searchVariant,
    searchSeries,
    searchAdvancedDetails,
    enrichDetails
  };
};
