import * as React from 'react';
import { BladePriceSimple } from '../price-simple';
import chroma from 'chroma-js';
import css from './index.css';
import cx from 'classnames';

export const BladeReceipt: React.FunctionComponent<IBladeReceipt> = props => {
  const surfaceColour: string = getSurfaceColourInRGB();

  return (
    <div className={cx('blade-receipt', css.receipt)}>
      <BladePriceSimple
        price={props.price}
        isPriceDiff={props.isPriceDiff}
        badge={props.tag}
      />

      <div className={css.receipt_items}>
        <div className={css.receipt_items_content}>{props.children}</div>
      </div>

      {props.footnote && (
        <div className={css.receipt_footnote}>
          <div className={css.receipt_footnote_text}>
            <small>{props.footnote}</small>
          </div>
        </div>
      )}

      <div
        style={{
          background: `linear-gradient(-135deg, ${surfaceColour} 10px, transparent 0) 0 0px, linear-gradient(135deg, ${surfaceColour} 10px, transparent 0) 0 0px`,
          backgroundColor: 'transparent',
          backgroundSize: '20px 20px',
          backgroundRepeat: 'repeat-x'
        }}
        className={cx({
          [css.receipt_zigzag]:
            props.receiptStyledFoot === undefined
              ? true
              : props.receiptStyledFoot
        })}
      ></div>
    </div>
  );
};

function getSurfaceColourInRGB() {
  const surfaceValue = getComputedStyle(document.body).getPropertyValue(
    '--surface-aside'
  );
  if (surfaceValue) {
    return chroma(surfaceValue.trim()).alpha(1).css();
  } else {
    return 'rgba(255, 255, 255, 0.7)';
  }
}

interface IBladeReceipt {
  /** Price value of the receipt */
  price: Money;
  /** Categorise or tag the type of payment being made. This will show in a pill box under the price. */
  tag?: string;
  /** Add a small footnote at the bottom of the receipt */
  footnote?: string;
  /** Is this a price diff? If so will prefix price value with a + or - depending on the value */
  isPriceDiff?: boolean;
  /** Stripped footer bottom */
  receiptStyledFoot: boolean;
}

interface Money {
  value: number;
  currencyCode: string;
}
