import update from 'immutability-helper';

export default function updateShowWelcomeScreenReducer(state, action) {
  const { value } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        showWelcomeScreen: { $set: value }
      })
    }
  };
}
