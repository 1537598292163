import aol from './aol';
import australia from './country-specific/austraila';
import brazil from './country-specific/brazil';
import france from './country-specific/france';
import germany from './country-specific/germany';
import italy from './country-specific/italy';
import netherlands from './country-specific/netherlands';
import russia from './country-specific/russia';
import uk from './country-specific/uk';
import usa from './country-specific/usa';
import hotmail from './hotmail';
import live from './live';
import outlook from './outlook';
import popular from './popular';
import yahoo from './yahoo';

export const all = [
  ...aol,
  ...hotmail,
  ...live,
  ...outlook,
  ...popular,
  ...yahoo,
  //Country specific after here
  ...australia,
  ...brazil,
  ...france,
  ...germany,
  ...italy,
  ...netherlands,
  ...russia,
  ...uk,
  ...usa
];
