import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

export const PolicySummaryRedirect: React.FunctionComponent<IPolicySummaryRedirect> = props => {
  return (
    <Redirect
      to={{
        pathname: props.getPolicySummaryPath(props.match.params.policyId),
        state: props.location.state
      }}
    />
  );
};
export interface IPolicySummaryRedirect
  extends RouteComponentProps<IPathParams> {
  getPolicySummaryPath: (policyId: string) => string;
}

interface IPathParams {
  productId: string;
  lineOfBusiness: string;
  coverType: string;
  policyId: string;
}
