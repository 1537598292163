import * as React from 'react';
import { getFromForm } from '@aventus/formio';
import { IntelligentQuoteQuestion } from '@aventus/platform';
import { isDependencySatisfied } from './is-dependency-satisfied';
import { getAnsweredAnswer } from './get-answered-answer';
import { ToHumanReadable } from '@aventus/application-timezone';

export function mapQuestionMetaToQuestionAnswer(
  questions: IntelligentQuoteQuestion[],
  risk = {},
  toHumanReadable: ToHumanReadable
) {
  return (
    questions
      .map((question: IntelligentQuoteQuestion) => {
        // Before we do anything, let's check if the question
        // has any dependencies defined, and if it does then we'll
        // check if that dependency is satisfied. If it isn't then
        // we need to skip over this question.

        if (question.conditionRiskPath && question.conditionType) {
          const _isDependencySatisfied = isDependencySatisfied(
            question.conditionType,
            question.conditionValue,
            getFromForm(risk, question.conditionRiskPath)
          );

          if (!_isDependencySatisfied) {
            // If the dependency isn't satisfied then we don't want to
            // process and render this question, so we can just return
            // null. A filter at the bottom of this function will take
            // care of cleaning up any nulls in the mapped question array.

            return null;
          }
        }

        if (question.riskValueFromSource !== null) {
          // If we've come this far, the question has been
          // answered and we can render.

          const answer = getAnsweredAnswer(question, toHumanReadable);

          if (typeof answer === 'object') {
            return (
              <div>
                <span>{answer.pre}</span>
                <strong>{answer.is}</strong>
                <span>{answer.post}</span>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      })

      // We'll also make sure to filter out any
      // of the null values (due to dependencies not satisified).

      .filter(question => question !== null)
  );
}
