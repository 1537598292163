import * as React from 'react';
import { PclRecoverableError, PclUnrecoverableError, ServerError } from '@aventus/errors';

export class IntelligentQuoteBoundary extends React.Component<IIntelligentQuoteBoundary, IIntelligentQuoteBoundaryState> {

  constructor (props: any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch (error: any) {

    if (
      error instanceof PclRecoverableError ||
      error instanceof PclUnrecoverableError
    ) {
      this.props.goToOrigin?.() || this.props.goHome();
    };

    if (error instanceof ServerError) {
      this.props.goToOrigin?.() || this.props.goHome();
    }

    // If none of our known error states
    // are found, then we'll re-throw the error
    // to throw it further up for another boundary
    // to catch it.

    throw error;
  }

  render () {
    return this.props.children;
  }

}

export interface IIntelligentQuoteBoundary {
  goHome: () => void,
  goToOrigin?: () => void
}

export interface IIntelligentQuoteBoundaryState {
  error: Nullable<Error>
}
