import * as React from 'react';

import { BladeButton, BladeIcon, BladeLoader } from '@aventus/blade';

import styles from './css/styles.css';

interface PackageSelectorButtonsProps {
  packageSelected: boolean;
  setPackageSelected: (packageSelected: boolean) => void;
  packagePrice: string | null;
  canBeRemoved: boolean;
  showAddButton: boolean;
  packageWasInteractedWith: boolean;
  packageIsBeingAdded: boolean;
  packageIsBeingRemoved: boolean;
  priceIsUpdating: boolean;
  childComponentUpdated?: boolean;
}

const PackageButtons = (props: PackageSelectorButtonsProps) => {
  const loaderColour = '#292E39';

  return (
    <div className={styles.packageButtons}>
      {props.showAddButton && (
        <BladeButton
          className={styles.addToQuoteButton}
          onClick={() => props.setPackageSelected(true)}
        >
          {(props.packageIsBeingAdded || props.packageWasInteractedWith) && (
            <BladeLoader color="white" />
          )}
          {!props.packageIsBeingAdded && !props.packageWasInteractedWith && (
            <>
              <span>Add to quote</span>
              {props.packagePrice && !props.childComponentUpdated && (
                <div>
                  <strong>{props.packagePrice}</strong>
                </div>
              )}
              {props.packagePrice && props.childComponentUpdated && (
                <div className={styles.priceLoaderContainer}>
                  <BladeLoader color="white" className={styles.priceLoader} />
                </div>
              )}
            </>
          )}
        </BladeButton>
      )}

      {!props.showAddButton && (
        <>
          <p className={styles.packageSelected}>
            {(props.packageIsBeingRemoved ||
              props.packageWasInteractedWith) && (
              <BladeLoader color={loaderColour} />
            )}
            {!props.packageIsBeingRemoved && !props.packageWasInteractedWith && (
              <>
                <span>
                  <BladeIcon color="green" name={'check-circle'} />
                  {props.canBeRemoved ? 'Added to quote' : 'Included'}
                </span>

                {props.packagePrice && !props.childComponentUpdated && (
                  <div>
                    <strong>{props.packagePrice}</strong>
                  </div>
                )}
                {props.packagePrice && props.childComponentUpdated && (
                  <div className={styles.priceLoaderContainer}>
                    <BladeLoader
                      color={loaderColour}
                      className={styles.priceLoader}
                    />
                  </div>
                )}
              </>
            )}
          </p>

          {props.canBeRemoved && (
            <BladeButton
              className={`${styles.removeFromQuoteButton} ${styles.mobileOnly}`}
              variant="secondary"
              isInline
              onClick={() => props.setPackageSelected(false)}
              isDisabled={props.packageWasInteractedWith}
            >
              <BladeIcon name={'faTrashAlt'} />
            </BladeButton>
          )}
        </>
      )}
    </div>
  );
};

export default PackageButtons;
