import React from 'react';
import PropTypes from 'prop-types';
import inject from 'react-jss';
import { guideConsumerJSS } from '../../context-api';
import scaffoldStyle from './styles';

const POSITIONS = {
  'c': 'center',
  'ch': 'center-horizontal',
  'cv': 'center-vertical'
};

const ONLY = {
  'children': 'only-children',
  'parent': 'only-parent'
};

class Scaffold extends React.Component {

  static propTypes = {
    position: PropTypes.oneOf(Object.keys(POSITIONS)),
    only: PropTypes.oneOf(Object.keys(ONLY))
  }

  static defaultProps = {
    orient: 'row'
  }

  render = () => {

    const {
      orient = 'row',
      size,
      position,
      justify,
      fill = false,
      shrink,
      grow,
      basis,
      only,
      wrap,
      xray = false,
      onClick,
      classes = [],
      style = {},
      className = ''
    } = this.props;

    const orientCapitalised = orient.charAt(0).toUpperCase() + orient.slice(1);
    const scaffoldClass = `scaffold${orientCapitalised}`;

    return (
      <div
        onClick={ onClick }
        style={{
          ...style,
          ...(basis) ? { flexBasis: basis } : {}
        }}
        className={`
          ${ classes[orient] }
          ${ size ? classes[scaffoldClass] : '' }
          ${ position ? POSITIONS[position] : '' }
          ${ only ? ONLY[only] : '' }
          ${ justify ? justify : '' }
          ${ wrap ? 'wrap' : '' }

          ${ fill ? 'fill' : '' }
          ${ grow === true ? 'grow' : '' }
          ${ grow === false ? 'no-grow' : '' }
          ${ shrink === true ? 'shrink' : '' }
          ${ shrink === false ? 'no-shrink' : '' }

          ${ className }
          ${ xray ? 'xray' : '' }
        `}>
        { this.props.children }
      </div>
    );
  }
}

export default guideConsumerJSS(Scaffold)(scaffoldStyle)
