import * as React from 'react';
import { DocumentsBoundary, IDocumentsBoundary } from './boundary';
import { DocumentsSummary, IDocumentsSummary } from './views/documents-summary';

export const MvmtDocumentsSummary = (
  props: IDocumentsBoundary & IDocumentsSummary
) => (
  <DocumentsBoundary>
    <DocumentsSummary {...props} />
  </DocumentsBoundary>
);
