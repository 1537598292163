import { PALETTE_TYPE, paletteMapper } from '@honey/describe/palette';
import { SCAFFOLD_TYPE, scaffoldMapper } from '@honey/describe/scaffold';
import { TYPOGRAPHY_TYPE, typographyMapper } from '@honey/describe/typography';
import { FONTS_TYPE, fontsMapper } from '@honey/describe/fonts';
import { VIEWS_TYPE, viewsMapper } from '@honey/describe/views';
import { FRAMES_TYPE, framesMapper } from '@honey/describe/frames';
import { EXPERIENCE_TYPE, experienceMapper } from '@honey/describe/experience';

function describer (descriptors: IDescriptors): any {

  const supportedDescriptors = {
    [PALETTE_TYPE]: paletteMapper,
    [SCAFFOLD_TYPE]: scaffoldMapper,
    [TYPOGRAPHY_TYPE]: typographyMapper,
    [FONTS_TYPE]: fontsMapper,
    [VIEWS_TYPE]: viewsMapper,
    [FRAMES_TYPE]: framesMapper,
    [EXPERIENCE_TYPE]: experienceMapper
  };

  return Object.keys(descriptors).reduce(
    (guide: any, descriptorKey: string) => {

      if (!supportedDescriptors[descriptorKey]) {
        return guide;
      }

      return {
        ...guide,
        [descriptorKey]: descriptors[descriptorKey].reduce(supportedDescriptors[descriptorKey], {})
      };
    },
    {}
  );
}

export {
  describer
}

interface IDescriptors {
  [key:string]: any
}
