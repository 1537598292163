import React, { useState } from 'react';
import cx from 'classnames';
import { BladeInput_Frame } from '../_frame';
import { useInput } from '../use-input';
import { useMediaQuery } from '@aventus/pockethooks';
import { BladeInput_Label } from '../_label';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import css from './index.css';

export const BladeInputSegmented: React.FunctionComponent<IBladeInputSegmented> = ({
  options,
  matchOn = 'referenceID',
  isInline,
  isYesNo = false,
  ...props
}) => {
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');
  const {
    value,
    onChange,
    hasBeenInteractedWith,
    error,
    className,
    onBlur,
    onFocus,
    setHasBeenInteractedWith,
    id,
    label
  } = useInput(props);


  const [selected, setSelected] = useState(undefined);

  const getReferenceID = (item: SegmentedOption) => get(item, [matchOn], item);

  if (options && props.displayType?.toLowerCase() === 'majormoney'){
    options.forEach((element: { text: string; }) => {
      element.text = element.text?.replace('.00', '') || '';
    });
  }


  const setSelectedValue = (selected: SegmentedOption) => {
    setSelected(getReferenceID(selected));

    isYesNo && typeof selected === 'object'
      ? onChange(selected[matchOn])
      : onChange(selected);
  };

  if ((selected === null && value) || (selected == undefined && value)) {
    setSelectedValue(value);
  }

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={hasBeenInteractedWith}
      error={error}
    >
      {label && <BladeInput_Label id={id} label={label} />}
      <div
        className={cx({
          [css.segmentControl]: (!matchesQuery && !isYesNo) || isYesNo,
          [css.segmentControl_mobile]: matchesQuery && !isYesNo
        })}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {options.map((item: SegmentedOption, index: number) => (
          <div
            key={index}
            className={cx(
              className,
              css.segmentControl_item,
              {
                [css.segmentControl_item_selected]: !isYesNo
                  ? getReferenceID(item) === selected
                  : item.value === value
              },
              {
                [css.segmentedControl_item_isInline]: isInline && !matchesQuery
              }
            )}
            onClick={debounce(() => {
              setSelectedValue(item);
              setHasBeenInteractedWith(true);
            }, 25)}
          >
            {item.text}
          </div>
        ))}
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputSegmented extends IBladeInput {
  matchOn: string;
  isYesNo?: boolean;
  options: Array<SegmentedOption> | any;
  isInline?: boolean;
  displayType?: string;
}

export interface SegmentedOption {
  referenceID?: string;
  text?: string;
  value?: string;
  [key: string]: any;
}
