import * as React from 'react';
import {
  BladeSurface,
  BladeGuide,
  BladeMarkdown,
  BladeModal,
  BladeInputSelect,
  BladeInputText,
  BladeInputSortCode,
  BladeInputAccountNumber,
  BladeMessage
} from '@aventus/blade';
import { useMediaQuery } from '@aventus/pockethooks';
import css from './index.css';
import directDebitLogo from './dd-logo.png';
import { useSetupDirectDebit } from './use-setup-direct-debit';
import cx from 'classnames';
import { PaymentRequest } from '../../interface/payment-request';

import personTitles from '../../data/person-titles.json';

export const PclSetupDirectDebit: React.FunctionComponent<IPclSetupDirectDebit> = props => {
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const {
    title,
    fullName,
    sortCode,
    accountNumber,
    showMoreDetails,
    setShowMoreDetails
  } = useSetupDirectDebit(props.customerName, props.onReady);

  const { setIsComplete } = props;

  const titleIsValid = title.meta.isValid;
  const fullNameIsValid = fullName.meta.isValid;
  const sortCodeIsValid = sortCode.meta.isValid;
  const accountNumberIsValid = accountNumber.meta.isValid;

  React.useEffect(() => {
    if (setIsComplete) {
      if (
        titleIsValid &&
        fullNameIsValid &&
        sortCodeIsValid &&
        accountNumberIsValid
      )
        setIsComplete(true);
      else setIsComplete(false);
    }
  }, [
    accountNumberIsValid,
    fullNameIsValid,
    setIsComplete,
    sortCodeIsValid,
    titleIsValid
  ]);

  return (
    <BladeSurface
      shouldRespectViewWidth={true}
      title={props.title || 'Setup your direct debit'}
      className={cx(
        css.setupDirectDebit,
        matchesQuery
          ? css.setupDirectDebit_isSpaceLimited
          : css.setupDirectDebit_isSpacious
      )}
    >
      {props.error && (
        <BladeMessage isFlush={true} variant={'error'} message={props.error} />
      )}

      {props.guide && <BladeGuide markdown={props.guide} />}

      <div className={css.setupDirectDebit_inputs}>
        <div className={css.setupDirectDebit_inputs_line}>
          <BladeInputSelect
            name={'dd-title'}
            label={'Title'}
            options={personTitles}
            labelOn={'text'}
            matchOn={'referenceID'}
            value={title.value}
            onChange={title.setValue}
            error={title.meta.validation}
          />

          <BladeInputText
            name={'dd-full-name'}
            label={'Full Name'}
            value={fullName.value}
            onChange={fullName.setValue}
            error={fullName.meta.validation}
          />
        </div>

        <div className={css.setupDirectDebit_inputs_line}>
          <BladeInputSortCode
            name={'dd-sort-code'}
            label={'Sort Code'}
            value={sortCode.value}
            onChange={sortCode.setValue}
            error={sortCode.meta.validation}
          />

          <BladeInputAccountNumber
            name={'dd-account-number'}
            label={'Account Number'}
            value={accountNumber.value}
            onChange={accountNumber.setValue}
            error={accountNumber.meta.validation}
          />
        </div>
      </div>

      {props.guideFooter && (
        <BladeSurface
          isAside={true}
          classNameContent={css.setupDirectDebit_footer}
        >
          <BladeMarkdown
            size={'small'}
            action={(key: string) => setShowMoreDetails(key)}
            markdown={props.guideFooter}
          />

          <img
            className={css.setupDirectDebit_footer_logo}
            src={directDebitLogo}
          />
        </BladeSurface>
      )}

      {showMoreDetails && props.guideFooterMoreDetails?.[showMoreDetails] && (
        <BladeModal
          className={css.setupDirectDebit_footer_moreDetails}
          close={() => setShowMoreDetails('')}
        >
          <BladeMarkdown
            markdown={props.guideFooterMoreDetails[showMoreDetails]}
          />
        </BladeModal>
      )}
    </BladeSurface>
  );
};

interface IPclSetupDirectDebit {
  title?: string;
  guide?: string;
  guideFooter?: string;
  guideFooterMoreDetails?: { [name: string]: string | undefined };
  customerName?: string;
  onReady: (paymentRequest: PaymentRequest) => void;
  setIsComplete?: (isComplete: boolean) => void;
  error?: string;
}
