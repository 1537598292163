import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { PricingPlan } from '@aventus/platform';
import {
  PricingConfiguration,
  PricingPaymentPlanConfiguration
} from '@aventus/configuration/product';
import { RateableComponentFinalPrice } from '@aventus/platform/pricing';

import { STELLA_ROADSIDE_IDS } from '@aventus/client-helpers/stella';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';

/**
 * The fine print for a selected payment plan is composed
 * of three main parts.
 *  1. The breakdown, if any, of the payment schedule
 *  2. The breakdown of taxes
 *  3. The breakdown, if any, of the interest rates
 */
export const getDisplayFinePrint = (
  plan: PricingPlan,
  pricingConfiguration: PricingConfiguration
): Array<string> => {
  // We'll first try and build up the payment schedule
  // string. This would only apply to those payment plans
  // that define a schedule to pay the policy price over
  // a number of payments. Single payments have no schedule.

  let paymentSchedule: string;

  const { taxDescription, displayAPRLabel } = pricingConfiguration;
  const {
    numberOfInstallments,
    aprRate,
    upfrontPrice,
    paymentPlan: { type }
  } = plan;

  switch (type) {
    case 'AnnualMonthlyCredit':
      paymentSchedule = `${
        upfrontPrice.value < 0
          ? `Deposit of ${currencyToHumanReadable(upfrontPrice)} plus`
          : ''
      } ${numberOfInstallments} monthly payments.`;
      break;

    case 'AnnualMonthlySubscription':
      paymentSchedule = `${
        displayAPRLabel === false
          ? ''
          : `${numberOfInstallments} equal monthly installments, 0% APR`
      }`;
      break;

    default:
      paymentSchedule = '';
      break;
  }

  // This is just a tax display that comes from config

  const taxes: string = taxDescription || '';

  // Finally we can sort the interest rate. This is a bit bespoke
  // at the moment since we've only been in business in the UK. As we globalise,
  // we will need a more agnostic handling -- similar to taxes above.

  let interestRates: string;

  switch (type) {
    case 'AnnualMonthlyCredit':
      interestRates = `APR (variable) ${aprRate}%.`;
      break;

    default:
      interestRates = '';
      break;
  }

  const getFixedFeeFinePrint = () => {
    if (pricingConfiguration.feeFinePrint)
      return configDrivenFeeFinePrint(plan, pricingConfiguration);

    return plan.feesTotal && pricingConfiguration.fixedFeeInformation
      ? pricingConfiguration.fixedFeeInformation.replace(
          '{0}',
          currencyToHumanReadable(plan.feesTotal)
        )
      : '';
  };

  return [paymentSchedule, taxes, interestRates, getFixedFeeFinePrint()];
};

const MONTHLY_PAYMENT_PLAN_TYPES = [
  'AnnualMonthlySubscription',
  'MonthlySubscription'
];

export function configDrivenPricingFinePrint(
  plan: PricingPlan,
  planConfiguration: PricingPaymentPlanConfiguration
): PricingPaymentPlanConfiguration {
  const isMonthly = MONTHLY_PAYMENT_PLAN_TYPES.includes(plan.paymentPlan.type);
  const hasFees = plan.fees?.length > 0;

  const replacersMap = {
    $$_upfront_price: currencyToHumanReadable(plan.upfrontPrice),
    $$_monthly_price: currencyToHumanReadable(plan.monthlyPrice),
    $$_total_payable: currencyToHumanReadable(plan.totalPayable.gross),
    $$_monthly_instalments: plan.numberOfInstallments.toString(),
    $$_apr_rate: plan.aprRate.toString(),
    $$_annual_saving: currencyToHumanReadable(
      isMonthly
        ? plan.totalPayableDifference
        : plan.totalPayableDifference !== null
        ? {
            value: Math.abs(plan.totalPayableDifference.value),
            currencyCode: plan.totalPayableDifference.currencyCode,
            unit: plan.totalPayableDifference.unit
          }
        : {
            value: 0,
            currencyCode: plan.basePrice.gross.currencyCode,
            unit: plan.basePrice.gross.unit
          }
    ),
    ...(hasFees
      ? {
          $$_fee_amount: currencyToHumanReadable(plan.feesTotal)
        }
      : {})
  };

  const modifiedPLanConfig = { ...planConfiguration };

  Object.keys(planConfiguration).forEach(k => {
    const key = k as keyof PricingPaymentPlanConfiguration;

    if (key !== 'paymentPlanReferenceType') {
      modifiedPLanConfig[key] = stringReplacer(
        planConfiguration[key],
        replacersMap
      );
    }
  });

  return modifiedPLanConfig;
}

export function configDrivenFeeFinePrint(
  plan: PricingPlan,
  pricingConfiguration: PricingConfiguration
) {
  let finePrint: string[] = [];

  const isMonthly = MONTHLY_PAYMENT_PLAN_TYPES.includes(plan.paymentPlan.type);

  const config =
    isMonthly && pricingConfiguration.feeFinePrint?.monthly
      ? pricingConfiguration.feeFinePrint?.monthly
      : pricingConfiguration.feeFinePrint?.default;

  const hasFees = plan.fees?.length > 0;
  const hasStellaRoadside = plan.primaryRateableComponentFinalPrices.some(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );
  const stellaRoadsideRC = plan.primaryRateableComponentFinalPrices.find(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );

  if (pricingConfiguration.hideSavingsFinePrint === false) {
    // Adds savings details to fine print string.
    // Can be disabled in config if not required.
    finePrint.push(
      (isMonthly && pricingConfiguration.savingsFinePrint.monthly) ||
        pricingConfiguration.savingsFinePrint.default
    );
  }

  const pickFeeFinePrintValue = () => {
    if (pricingConfiguration.hideFeeFinePrintAnnual === true && !isMonthly) {
      return '';
    } else {
      if (hasStellaRoadside) {
        if (hasFees && config?.withFeeAndStellaRoadside)
          return config.withFeeAndStellaRoadside;

        if (!hasFees && config?.withStellaRoadside)
          return config.withStellaRoadside;
      }

      if (hasFees) {
        return config?.withFee;
      }
    }

    // Default to returning the original one
    return '';
  };

  const feeFinePrint = pickFeeFinePrintValue();

  if (feeFinePrint && feeFinePrint !== '') {
    finePrint.push(feeFinePrint);
  }

  if (finePrint.length === 0) {
    return '';
  }

  const replacersMap = {
    $$_upfront_price: currencyToHumanReadable(plan.upfrontPrice),
    $$_monthly_price: currencyToHumanReadable(plan.monthlyPrice),
    $$_annual_saving: currencyToHumanReadable(
      isMonthly
        ? plan.totalPayableDifference
        : plan.totalPayableDifference !== null
        ? {
            value: Math.abs(plan.totalPayableDifference.value),
            currencyCode: plan.totalPayableDifference.currencyCode,
            unit: plan.totalPayableDifference.unit
          }
        : {
            value: 0,
            currencyCode: plan.basePrice.gross.currencyCode,
            unit: plan.basePrice.gross.unit
          }
    ),
    ...(hasFees
      ? {
          $$_fee_amount: currencyToHumanReadable(plan.feesTotal)
        }
      : {}),
    ...(hasStellaRoadside && stellaRoadsideRC
      ? {
          $$_roadside_price: currencyToHumanReadable(
            stellaRoadsideRC.price.gross
          )
        }
      : {})
  };

  return '*' + stringReplacer(finePrint.join(' ').trim(), replacersMap);
}
