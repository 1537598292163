import React from 'react';
import {
  BladeButton,
  BladeInputSegmented,
  BladeInputText,
  BladeMarkdown
} from '@aventus/blade';
import { Controller, useForm } from 'react-hook-form';
import { BooleanFieldType, EFieldMessages } from '../..';
import css from '../../index.css';
import cx from 'classnames';

const PaymentDetails = (props: IBNPPaymentDetailsProps): JSX.Element => {
  const { control, handleSubmit, errors, watch } = useForm<any>({
    defaultValues: {
      bankAccountNumber: props.bankAccountNumber,
      sortCode: props.sortCode,
      isApplicantBankAccount: props.isApplicantBankAccount,
      isJointAccount: props.isJointAccount,
      thirdPartyAccountName: props.thirdPartyAccountName
    }
  });

  const onSubmit = (data: IPaymentDetailsForm) => {
    props.updateStore(data);
    props.nextStep();
  };

  const onError = () => {
    if (typeof props.validationError === 'function') {
      props.validationError(
        'Some fields are incomplete, please check the form and try again'
      );
    }
  };

  const isApplicantBankAccount: BooleanFieldType | null = watch(
    'isApplicantBankAccount'
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={css.bnpFieldWrapper}>
        <Controller
          name={'bankAccountNumber'}
          control={control}
          rules={{
            required: EFieldMessages.required,
            minLength: {
              value: 8,
              message: 'Bank account number must be 8 characters'
            },
            maxLength: {
              value: 8,
              message: 'Bank account number must be 8 characters'
            }
          }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputText
                  label={'Bank account number'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'bankAccountNumber' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.bankAccountNumber?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
        <Controller
          name={'sortCode'}
          control={control}
          rules={{
            required: EFieldMessages.required,
            minLength: {
              value: 6,
              message: 'Sort code must be 6 characters'
            },
            maxLength: {
              value: 6,
              message: 'Sort code must be 6 characters'
            }
          }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerHalf, css.bnpFieldset)}>
                <BladeInputText
                  label={'Sort code'}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'sortCode' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.sortCode?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
        <Controller
          name={'isApplicantBankAccount'}
          control={control}
          rules={{
            required: 'Please confirm whether this is your bank account'
          }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <BladeInputSegmented
                  label={'Is this your bank account?'}
                  matchOn="value"
                  options={[
                    { referenceID: 'yes', text: 'Yes', value: true },
                    { referenceID: 'no', text: 'No', value: false }
                  ]}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'isApplicantBankAccount' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.isApplicantBankAccount?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
        {isApplicantBankAccount && isApplicantBankAccount.value === false && (
          <Controller
            name={'thirdPartyAccountName'}
            control={control}
            rules={{
              required: EFieldMessages.required
            }}
            render={props => {
              return (
                <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                  <BladeInputText
                    label={'Third party account name'}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    name={props.name}
                  />
                  {errors && 'thirdPartyAccountName' in errors && (
                    <span className={css.bnpFieldError}>
                      {errors.thirdPartyAccountName?.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
        )}
        <Controller
          name={'isJointAccount'}
          control={control}
          rules={{ required: 'Please confirm whether this is a joint account' }}
          render={props => {
            return (
              <div className={cx(css.bnpControllerFull, css.bnpFieldset)}>
                <BladeInputSegmented
                  label={'Is this a joint account?'}
                  matchOn="value"
                  options={[
                    { referenceID: 'yes', text: 'Yes', value: true },
                    { referenceID: 'no', text: 'No', value: false }
                  ]}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  name={props.name}
                />
                {errors && 'isJointAccount' in errors && (
                  <span className={css.bnpFieldError}>
                    {errors.isJointAccount?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
      </div>
      {props.financeGuarentee && (
        <div className={css.bnpFinanceGuarenteeWrapper}>
          <BladeMarkdown markdown={props.financeGuarentee} />
        </div>
      )}
      <div className={css.bnpButtonWrap}>
        <BladeButton
          type="button"
          onClick={() => props.prevStep()}
          variant="secondary"
        >
          Previous
        </BladeButton>
        <BladeButton type="submit" variant="primary">
          Continue
        </BladeButton>
      </div>
    </form>
  );
};

interface IPaymentDetailsForm {
  bankAccountNumber: string;
  sortCode: string;
  isApplicantBankAccount: BooleanFieldType;
  isJointAccount: BooleanFieldType;
}

interface IBNPPaymentDetailsProps {
  bankAccountNumber: string | null;
  sortCode: string | null;
  isApplicantBankAccount: BooleanFieldType | null;
  isJointAccount: BooleanFieldType | null;
  financeGuarentee?: string;
  thirdPartyAccountName: string | null;
  nextStep: () => void;
  prevStep: () => void;
  updateStore: (data: IPaymentDetailsForm) => void;
  validationError?: (message: string) => void;
}

export default PaymentDetails;
