import axios from 'axios';

export default function callApi(url, requestConfig, appConfig) {

  const {
    method = 'GET',
    data = {}
  } = requestConfig;

  const axiosConfig = {
    method: method,
    url: url,
    data: {
      client_id: appConfig.AUTH0_CLIENT_ID_WEB,
      ...data
    }
  }

  try {
    return axios(axiosConfig)
  } catch (error) {
    return error
  }
}
