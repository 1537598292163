import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GuideProvider, HoneyContext } from '@honey/consume-react-jss';
import { BladeFavicon } from '@aventus/blade';
import { poweredByAventusAscii } from './ascii';
import { SymphonyApp } from './appy';

class App extends React.Component {
  componentDidMount = () => {
    window['console']['log'](poweredByAventusAscii + 'v: ' + __PKG_VERSION__);
  };

  render = () => {
    return (
      <GuideProvider
        descriptors={this.props.config.brand}
        meta={{
          tenant: this.props.config.client
            ? this.props.config.client.companyName
            : ''
        }}
      >
        <HoneyContext.Consumer>
          {({ guide }) => {
            return (
              <SymphonyApp
                mode={this.props.env.APP_MODE}
                externalId={this.props.externalId}
              >
                {this.props.appContent}
              </SymphonyApp>
            );
          }}
        </HoneyContext.Consumer>

        {this.props.config.userInterface &&
          this.props.config.userInterface.favicon && (
            <BladeFavicon href={this.props.config.userInterface.favicon} />
          )}
      </GuideProvider>
    );
  };
}

// Until we finalise the brand schema, it is best to use a hard-coded version.
// Once the branding is out in the wild, then we need to worry about versioning
// and dealing with destructive changes with great difficulty.
// Once thats sorted, we can revert back to using the brand config that comes
// from config endpoint: state.app.core.customConfig.brand

const mapStateToProps = state => ({
  env: state.app.core.config.env,
  config: state.app.core.customConfig,
  externalId: state.app.core.tracking.externalId
});

export default withRouter(connect(mapStateToProps)(App));
