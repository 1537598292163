import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { useCollection } from './use-collection';
import { BladeIcon } from '../../icon';
import { BladeModal } from '../../modal';
import { BladeAsk } from '../../ask';
import { BladeButton } from '../../button';
import { interpolate } from '@aventus/pocketknife/interpolate';
import css from './index.css';
import cx from 'classnames';

export const BladeInputCollection: React.FunctionComponent<IBladeInputCollection> = props => {
  const inputProps = useInput(props);

  const {
    isFormOpen,
    setIsFormOpen,
    formState,
    setFormState,
    updateFormState,
    selectedItemIndex,
    addToCollection,
    updateInCollection,
    deleteFromCollection
  } = useCollection(inputProps.value);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />

      <div className={cx('blade-input-collection', css.collection)}>
        {/*

          Render any existing items
          in the collection

          */}

        {inputProps.value &&
          inputProps.value.map((item: any, i: number) => (
            <div key={i} className={css.collection_existingItem}>
              <div
                className={cx(
                  css.collection_item,
                  inputProps.className,
                  css.collection_itemHook
                )}
                onClick={() => {
                  setFormState({ [props.itemKey]: item });
                  setIsFormOpen(true, i);
                }}
              >
                <label>{interpolate(item, props.itemLabel || 'Item')}</label>

                <BladeIcon
                  className={css.collection_item_update}
                  name={'faPencil'}
                />
              </div>

              <div
                className={cx(
                  css.collection_item_delete,
                  css.collection_itemHook
                )}
              >
                <BladeIcon
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    inputProps.onChange(deleteFromCollection(i));
                    event.stopPropagation();
                  }}
                  className={css.collection_item_delete_icon}
                  name={'faTimes'}
                />
              </div>
            </div>
          ))}

        {/*

          Render an empty item to trigger
          adding a new item to collection.

          */}

        {(!props.maxNumberOfItems ||
          props.maxNumberOfItems !== inputProps.value?.length) && (
          <div
            className={cx(css.collection_item, inputProps.className)}
            onClick={() => {
              setFormState({ [props.itemKey]: {} });
              setIsFormOpen(true);
            }}
          >
            <label>
              {inputProps.value && inputProps.value.length > 0
                ? props.addAnotherToCollectionLabel || 'Add'
                : props.addToCollectionLabel || 'Add'}
            </label>

            <BladeIcon className={css.collection_item_add} name={'faPlus'} />
          </div>
        )}

        {/*

          If activated, render a modal with
          a form for an item.

          */}

        {isFormOpen && (
          <BladeModal
            title={props.collectionTitle ? props.collectionTitle : undefined}
            close={() => setIsFormOpen(false)}
          >
            <BladeAsk
              variant={'alltogether'}
              getQuestionsToRender={(
                validation: any,
                dispatchValidation: any
              ) =>
                props.getQuestions(
                  formState,
                  updateFormState,
                  validation,
                  dispatchValidation
                )
              }
              getSubmitToRender={(isFormValid: boolean) => (
                <BladeButton
                  isDisabled={!isFormValid}
                  onClick={() => {
                    const updatedCollection =
                      selectedItemIndex === -1
                        ? addToCollection(formState[props.itemKey])
                        : updateInCollection(
                            formState[props.itemKey],
                            selectedItemIndex
                          );
                    inputProps.onChange(updatedCollection);
                    setIsFormOpen(false);
                  }}
                >
                  {props.addToCollectionLabel || 'Add'}
                </BladeButton>
              )}
            />
          </BladeModal>
        )}
      </div>
    </BladeInput_Frame>
  );
};

// TODO
// These values shouldn't be null,
// but are temporarily made nullable because of time.
// Ideally these are checked by the caller first, and
// an error thrown if there isn't sufficient
// data to render this component.

export interface IBladeInputCollection extends IBladeInput {
  collectionTitle: string | null;
  addToCollectionLabel: string | null;
  addAnotherToCollectionLabel: string | null;
  itemLabel: string | null;
  itemKey: string;
  maxNumberOfItems?: number;
  getQuestions: (
    formState: any,
    updateFormState: any,
    validation: any,
    dispatchValidation: any
  ) => any;
}
