import { Policy } from '@aventus/platform';
import { BinderConfiguration } from '../interface/binder';
import { Lifecycles } from '../interface/lifecycles';
import { InsuranceProductConfiguration } from '@aventus/configuration';

export const payNowFatZebra: PayNowFatZebra = (
  cardToken: string,
  paymentConfiguration: BinderConfiguration,
  lifecycles: Lifecycles,
  setIsCheckingOut,
  onSuccess,
  onRecoverableError,
  productConfiguration
) => {
  switch (paymentConfiguration.paymentPlanType) {
    case 'AnnualMonthlySubscription':
      lifecycles
        .onFatZebraSubscriptionPurchase({
          quoteID: paymentConfiguration.quoteID,
          paymentPlanReferenceID: paymentConfiguration.paymentPlanReferenceID,
          encryptedDiscount: paymentConfiguration.encryptedDiscount,
          tokenisedCard: cardToken
        })
        .then(response => {
          setIsCheckingOut(false);
          onSuccess(response);
        })
        .catch(error => {
          let message = "Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support."
          let extraErrorInfo = ""
          if(error.response.data)
          {
            if (error.response.data.paymentError)
            {
              extraErrorInfo = ` Your bank returned this message: ${error.response.data.paymentError.providerErrorMessage}`
            }
            else if (error.response.data.error === 1141){
              message = productConfiguration.checkout.error?.outstandingPayment || "There is an outstanding payment on your policy. Please contact customer support."
            }
            else if (error.response.data.error === 1142){
              message = "This quote cannot be purchased. Please contact customer support."
            }
          }
          onRecoverableError(
            'fatzebraerror',
             `${message} ${extraErrorInfo}`
          );

          // @ts-ignore
          window.Rollbar?.critical(
            new Error(`PAYMENT: FatZebra subscriptionPurchase error`),
            error
          );
          setIsCheckingOut(false);
        });
      break;
    case 'FixedTermSinglePayment':
    case 'SinglePayment':
      lifecycles
        .onFatZebraAnnualPurchase({
          quoteID: paymentConfiguration.quoteID,
          paymentPlanReferenceID: paymentConfiguration.paymentPlanReferenceID,
          encryptedDiscount: paymentConfiguration.encryptedDiscount,
          tokenisedCard: cardToken
        })
        .then(response => {
          setIsCheckingOut(false);
          onSuccess(response);
        })
        .catch(error => {
          let message = "Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support."
          let extraErrorInfo = ""
          if(error.response.data)
          {
            if (error.response.data.paymentError)
            {
              extraErrorInfo = ` Your bank returned this message: ${error.response.data.paymentError.providerErrorMessage}`
            }
            else if (error.response.data.error === 1141){
              message = productConfiguration.checkout.error?.outstandingPayment || "There is an outstanding payment on your policy. Please contact customer support."
            }
            else if (error.response.data.error === 1142){
              message = "This quote cannot be purchased. Please contact customer support."
            }
          }


          onRecoverableError(
            'fatzebraerror',
            `${message} ${extraErrorInfo}`
          );

          // @ts-ignore
          window.Rollbar?.critical(
            new Error(`PAYMENT: FatZebra annualPurchase error`),
            error
          );
          setIsCheckingOut(false);
        });
      break;
    default:
      onRecoverableError(
        'fatzebraerror',
        'Unable to detect payment plan type, please try again or contact customer support.'
      );
  }
};

type PayNowFatZebra = (
  cardToken: string,
  paymentConfiguration: BinderConfiguration,
  lifecycles: Lifecycles,
  setIsCheckingOut: (loading: boolean) => void,
  onSuccess: (policy: Policy) => void,
  onRecoverableError: (id: string, message: string) => void,
  productConfiguration: InsuranceProductConfiguration
) => void;
