export const usa: string[] = [
  "bellsouth.net",
  "bellatlantic.net",
  "charter.net",
  "comcast.net",
  "cox.net",
  "earthlink.net",
  "juno.com",
  "verizon.net",
];

export default usa;
