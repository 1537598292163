import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import middlewareApi from '@aventus/middleware-api';
import thunkMiddleware from 'redux-thunk';
import { stateVersion, app, widgets, auth0Api } from 'homelyfe/modules';
import { loadStateFromLocal, saveStateToLocal } from './local-storage';
import { errorCodeMessages, middlewareApiDebounce } from './helpers';
import { isString } from 'lodash';
import { isJwtExpired } from 'jwt-check-expiration';

export default function configureStore(history, appConfig) {
  /**
   * Bind ReduxDevTool from the Chrome Plugin.
   * This will eventually be defined internally rather than
   * through the chrome plugin, once we define a `dev` env.
   */

  let localState = loadStateFromLocal();
  if (localState) {
    const localStateVersion =
      localState.app && localState.app.core && localState.app.core.stateVersion
        ? localState.app.core.stateVersion
        : 0;
    const latestStateVersion = stateVersion;

    if (localStateVersion < latestStateVersion) {
      localState = {};
    }
  } else {
    localState = {};
  }

  // When loading from state, reset the local state so that the expired token
  // is not used.
  if (localState.app && localState.app.auth && localState.app.auth.user) {
    const authToken = localState.app.auth.user.token;
    if (
      authToken !== undefined &&
      isString(authToken) &&
      authToken.length > 0 &&
      isJwtExpired(authToken)
    ) {
      localState.app.auth.user = false;
    }
  }

  saveStateToLocal(localState);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const appReducers = combineReducers({
    router: connectRouter(history),
    app: app.apiSlice,
    widgets: widgets.reducers
  });

  const rootReducer = (state, action) => {
    if (action.type === 'AUTH_SIGN_OUT') {
      state = {
        app: app.signOutStatePartial(state)
      };
    }
    return appReducers(state, action);
  };

  return createStore(
    rootReducer,
    localState,
    composeEnhancers(
      applyMiddleware(
        middlewareApiDebounce({ default: 1000, quick: 200 }),
        routerMiddleware(history),
        thunkMiddleware,
        middlewareApi({
          apiUrlStateAddress: 'app.core.config.env.APP_API_URL',
          apiVersionStateAddress: 'app.core.config.env.APP_API_VERSION',
          authTokenStateAddress: 'app.auth.user.token',
          genericErrorMessage:
            'Something unexpected has gone wrong. Please try again later or contact support.',
          dispatchIfUnauthorized: app.signOut,
          errorCodes: errorCodeMessages
        }),
        auth0Api
      )
    )
  );
}
