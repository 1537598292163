import update from 'immutability-helper';

export default function updateNetworkStatusReducer(state, action) {
  const { isConnected } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        networkStatus: {
          isConnected: { $set: isConnected }
        }
      })
    }
  };
}