export function required (value: any): string | undefined {

  if (Array.isArray(value)) {
    if (value.length > 0) {
      return undefined;
    } else {
      return 'Please add an entry';
    }
  }

  return (
    value !== null &&
    value !== '' &&
    value !== undefined &&
    doesContainAtLeast1LetterOrNumber(value)
      ? undefined
      : 'Please fill in this field'
  )
}

const doesContainAtLeast1LetterOrNumber = (value: any):boolean => {
  if (typeof value === 'string') {
    return !value.replace(/\s/g, '').length ? false : true;
  } else {
    return true;
  }
}
