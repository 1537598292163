export const france: string[] = [
	"wanadoo.fr",
	"orange.fr",
  "neuf.fr",
  "free.fr",
  "sfr.fr",
	"voila.fr",
	"club-internet.fr",
  "aliceadsl.fr",
  "laposte.net"
];

export default france;
