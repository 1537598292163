import update from 'immutability-helper';

export default function updateStateVersionReducer(state, action) {
  const { stateVersion } = action;
  return {
    ...state,
    ...{
      core: update(state.core, {
        stateVersion: { $set: stateVersion }
      })
    }
  };
}
