import React from 'react';
import track from 'react-tracking';
import appApi from '../../../api';
import routes from '../../../routes';
import SignInComponent, { SignInFormData } from './signInComponent';
import SignInComponentOld from './signInComponentOld';
import { BladeMessage, BladePage, BladeSurface } from '@aventus/blade';
import { Scaffold, Typo } from '@honey/consume-react-jss';
import { updateProductRef } from '@aventus/pocketknife/dispatch-event';
import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import createFirebaseApp from '../../../../../lib/middleware/firebase/initalizeApp';

class SignIn extends React.Component<any, SignInFormData> {
  constructor(props: any) {
    super(props);
    this.goToSignUp = this.goToSignUp.bind(this);
    this.handleOnSubmitSignInLink = this.handleOnSubmitSignInLink.bind(this);
    this.trySignInWithEmailLink = this.trySignInWithEmailLink.bind(this);
    this.onStartUserLogin = this.onStartUserLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const { emailAddress } = props.location.state || {};
    this.state = {
      email: emailAddress || '',
      password: '',
      requiresEmailConfirmation: false,
      onSuccessGoToRoute: ''
    };
  }

  componentDidMount() {
    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.signIn.trackingName
    });

    this.props.tracking.trackEvent({
      event: 'aventus.signin'
    });

    const {
      isMidFlowAuth = false,
      onSuccessGoToRoute,
      passToNextRoute
    } = this.props.location.state || {};

    if (isMidFlowAuth) {
      this.props.handleAuthUpdateMidAuthFlowState({
        isMidFlowAuth,
        onSuccessGoToRoute,
        passToNextRoute
      });
    }

    updateProductRef(null);

    this.props.handleAuthClearAllFlags();

    if (
      this.props.app.core.customConfig.userInterface?.authentication?.signIn
        ?.oneTimeSignInLink?.enabled &&
      this.props.app.core.config.auth0.firebase
    ) {
      this.trySignInWithEmailLink();
    }
  }

  componentDidUpdate(prevProps: any) {
    const authProps = this.props.app.auth;
    const prevAuthProps = prevProps.app.auth;

    const {
      isMidFlowAuth = false,
      onSuccessGoToRoute,
      passToNextRoute = {}
    } = prevAuthProps.midAuthFlow || this.props.location.state || {};

    if (onSuccessGoToRoute !== this.state.onSuccessGoToRoute) {
      this.setState(prevState => {
        return { ...prevState, onSuccessGoToRoute };
      });
    }

    if (
      !prevAuthProps._flags.successfullyValidatedMe &&
      authProps._flags.successfullyValidatedMe
    ) {
      if (isMidFlowAuth && onSuccessGoToRoute) {
        this.props.history.replace(onSuccessGoToRoute, passToNextRoute);
      } else {
        this.props.history.push(routes.home.path);
      }

      this.props.handleAuthUpdateMidAuthFlowState(null);
    }

    if (
      !prevAuthProps._flags.successfullySignedInWithLink &&
      authProps._flags.successfullySignedInWithLink
    ) {
      const redirectUrl = new URLSearchParams(window.location.search).get(
        'redirectTo'
      );
      this.props.handleAuthUpdateMidAuthFlowState(null);
      localStorage.removeItem('emailForSignIn');
      this.props.history.push(redirectUrl ? redirectUrl : routes.home.path);
    }

    if (
      this.props.app.core.customConfig.userInterface?.authentication?.signIn
        ?.oneTimeSignInLink?.enabled &&
      !prevProps.app.core.config.auth0.firebase &&
      this.props.app.core.config.auth0.firebase
    ) {
      this.trySignInWithEmailLink();
    }
  }

  handleOnSubmitSignInLink({ email }: SignInFormData) {
    const redirectUrl = `${routes.signIn.path}${
      this.state.onSuccessGoToRoute
        ? `?redirectTo=${this.state.onSuccessGoToRoute}`
        : ''
    }`;
    this.props.handleSendSignInLink(email, redirectUrl);
    this.setState(prevState => {
      return { ...prevState, email: email };
    });
    localStorage.setItem('emailForSignIn', email);
  }

  trySignInWithEmailLink() {
    // An intermitment issue seems to sometimes occur when the firebase app is not initialised in time
    // so we need to initialise it here also. This method tries to get a handle on the app, and only when
    // it cannot be found it initialised the firebase app.
    createFirebaseApp(this.props.app.core.config.auth0.firebase);

    let auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = localStorage.getItem('emailForSignIn');

      if (email) {
        // We only pass email when we attempt sign in with link
        this.props.handleAuthSignIn(email);
      } else {
        // Render email confirmation views
        this.setState(prevState => {
          return { ...prevState, requiresEmailConfirmation: true };
        });
      }
    }
  }

  onStartUserLogin({ email }: SignInFormData, recaptchaToken: string) {
    this.setState(prevState => {
      return {
        ...prevState,
        email
      };
    });
    this.props.handleAuthUpdateCredentials({ username: email });
    this.props.handleStartUserLogin(email, recaptchaToken);
  }

  onSubmit({ email, password }: SignInFormData) {
    this.setState(prevState => {
      return {
        ...prevState,
        email,
        password
      };
    });
    this.props.handleAuthSignIn(email, password);
  }

  goToSignUp() {
    const { midAuthFlow } = this.props.app.auth;
    const { location } = this.props;

    if (midAuthFlow) {
      this.props.history.replace(routes.signUp.path, location.state);
    } else {
      this.props.history.push(routes.signUp.path, location.state);
    }
  }

  render() {
    const { _flags } = this.props.app.auth;
    const { authenticationMethods = [], defaultAuthenticationProvider } =
      this.props.app.core.config.auth0;
    const authenticationConfig =
      this.props.app.core.customConfig.userInterface?.authentication;
    const isLoading =
      (!this.props.app.core.config.auth0.url &&
        this.props.app.core._flags.isGettingAuth0Confige) ||
      _flags.isSigningIn ||
      _flags.isValidatingMe ||
      _flags.isBlockingUser ||
      _flags.isFirebaseSigningIn ||
      _flags.isSigningInWithLink ||
      _flags.isStartingUserLogin;

    return (
      <BladePage isLoadingOver={isLoading} title={routes.signIn.documentTitle}>
        {authenticationConfig?.signIn?.oneTimeSignInLink?.enabled && (
          <Scaffold orient={'col'} size={'large'}>
            {this.props.location.state?.isPostPurchase && (
              <BladeMessage
                variant={'success'}
                icon={'faCheck'}
                message={
                  this.props.location.state.isNewAccount
                    ? this.props.location.state.success?.message
                    : authenticationConfig?.signIn?.postPurchaseTitle
                }
                detail={this.props.location.state.success?.detail}
              />
            )}

            <SignInComponent
              routes={routes}
              onStartUserLogin={this.onStartUserLogin}
              onSubmit={this.onSubmit}
              onSubmitSignInLink={this.handleOnSubmitSignInLink}
              onSignInWithLink={this.trySignInWithEmailLink}
              emailPrefill={this.state.email}
              authenticationMethods={authenticationMethods}
              authenticationProvider={defaultAuthenticationProvider}
              authenticationConfig={authenticationConfig}
              midFlowAuth={this.props.midFlowAuth}
              successfullySentSignInLink={
                this.props.app.auth._flags.successfullySentSignInLink
              }
              requiresEmailConfirmation={this.state.requiresEmailConfirmation}
              locationState={this.props.location.state}
              userHasPreviouslySignedIn={
                this.props.app.auth._flags.userHasPreviouslySignedIn
              }
              signInErrorMessage={this.props.app.auth._flags.signInErrorMessage}
            />
          </Scaffold>
        )}
        {!authenticationConfig?.signIn?.oneTimeSignInLink?.enabled && (
          <Scaffold orient={'col'} size={'large'}>
            {this.props.location.state?.isPostPurchase &&
              this.props.location.state.isPostPurchase === true &&
              authenticationConfig?.signIn.postPurchaseTitle && (
                <BladeMessage
                  variant={'success'}
                  icon={'faCheck'}
                  message={authenticationConfig?.signIn?.postPurchaseTitle}
                  detail={this.props.location.state.success?.detail}
                />
              )}

            <BladeSurface
              title={authenticationConfig?.signIn.title || 'Sign in'}
              description={
                authenticationConfig?.signIn?.description || undefined
              }
              shouldRespectViewWidth={true}
            >
              <Scaffold fill={true} position={'c'}>
                <Scaffold
                  grow={true}
                  orient={'col'}
                  size={'large'}
                  style={{ paddingTop: 0 }}
                >
                  {this.props.midFlowAuth &&
                    this.props.midFlowAuth.registerDescription && (
                      <Typo variant={'body'}>
                        {this.props.midFlowAuth.registerDescription}
                      </Typo>
                    )}

                  <SignInComponentOld
                    routes={routes}
                    onSubmit={({ username, password }) => {
                      this.setState(prevState => {
                        return {
                          ...prevState,
                          username,
                          password
                        };
                      });
                      this.props.handleAuthSignIn(username, password);
                    }}
                    goToSignUp={this.goToSignUp}
                    companyName={
                      this.props.app.core.customConfig.client.companyName
                    }
                    usernamePrefill={this.state.email}
                    authenticationMethods={authenticationMethods}
                    authenticationProvider={defaultAuthenticationProvider}
                    authenticationConfig={
                      this.props.app.core.customConfig.userInterface
                        ?.authentication
                    }
                  />
                </Scaffold>
              </Scaffold>
            </BladeSurface>
          </Scaffold>
        )}
      </BladePage>
    );
  }
}

export default appApi(track()(SignIn));
