import css from './index.css';

export function getVariantClass(variant: BladeInputVariant): string {
  switch (variant) {
    case 'solid':
      return css.input_variant_solid;
    case 'border':
      return css.input_variant_border;
    case 'underline':
      return css.input_variant_underline;
    case 'no-frame':
      return css.input_variant_noFrame;
    default:
      return '';
  }
}
