import React from 'react';
import { RegisteredState } from './types';

export const useManualRegistration: UseManualRegistration = (
  value,
  _onChange,
  _onValidate
) => {
  const [registration, setRegistration] = React.useState<string>('');
  const [registeredState, setRegisteredState] = React.useState<
    RegisteredState | undefined
  >();

  const updateRegistrationDetails = (): void => {
    if (
      registeredState === undefined ||
      registration === '' ||
      registration === null ||
      registration === undefined
    ) {
      return;
    }

    _onChange?.({
      nvic: value.nvic,
      bodyType: value.bodyType || null,
      variant: value.variant || null,
      series: value.series || null,
      make: value.make,
      model: value.model || null,
      year: value.year || null,
      transmission: value.transmission || null,
      cc: value.cc || null,
      engineConfig: value.engineConfig || null,
      regOrVin: registration.trim(),
      registration: registration.trim(),
      registeredState: registeredState?.value
    });
  };

  return {
    registration,
    registeredState,
    setRegistration,
    setRegisteredState,
    updateRegistrationDetails
  };
};

export type UseManualRegistration = (
  value: any,
  _onChange: (value: any) => void,
  _onValidate: ((value: any) => void) | undefined
) => {
  registration: string;
  registeredState: RegisteredState | undefined;
  setRegistration: React.Dispatch<React.SetStateAction<string>>;
  setRegisteredState: React.Dispatch<
    React.SetStateAction<RegisteredState | undefined>
  >;
  updateRegistrationDetails: () => void;
};
