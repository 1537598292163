export function addressToHumanReadable (address:Address):string {
  return `${address.address1 ? address.address1 + ', ' : ''}${address.address2 ? address.address2 + ', ' : ''}${address.town ? address.town + ', ' : ''}${address.postalCode ? address.postalCode : ''}`;
}

interface Address {
  address1: string,
  address2?: string,
  town: string,
  postalCode: string,
  country: {
    referenceID: string,
    text: string
  },
  udprn?: number
}
