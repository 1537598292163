import * as React from 'react';
import css from './index.css';
import cx from 'classnames';

export const BNPView: React.FunctionComponent<IBNPView> = props => {
  return (
    <div className={cx(css.bnpView, { [css.bnpViewHidden]: !props.active })}>
      {props.children}
    </div>
  );
};

export interface IBNPView {
  children: any;
  title: string;
  active: boolean;
}
