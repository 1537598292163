import { Policy } from '@aventus/platform';
import { ApplicationError } from '@aventus/errors';
import { BinderPayment, BinderConfiguration } from '../interface/binder';
import { Lifecycles } from '../interface/lifecycles';
import { payNow } from './pay-now';

export const adjustNow = async (
  payment: BinderPayment | undefined,
  adjustmentConfiguration: BinderConfiguration,
  lifecycles: Lifecycles,
  throwError: (error: Error) => void,
  setError: (identifier: string, message: string) => void
): Promise<Policy | undefined> => {
  if (!adjustmentConfiguration.adjustmentType) {
    throw new ApplicationError(
      '`adjustNow` was called but no adjustment type was defined.'
    );
  }

  // When the adjustment is finished, we'll
  // expect a policyId to be defined, indicating
  // we can finish up.

  let policyId: string | undefined = undefined;

  // There are four main scenarios when it comes to
  // mid-term adjustments.

  // If the policy was bought with a 'FixedTermSinglePayment'
  // payment plan, ie: fully paid up, then refund or no fee
  // adjustments mean we need to take no money from the user,
  // but just process what is owed.

  if (
    adjustmentConfiguration.adjustmentType === 'FixedSingleNoFee' ||
    adjustmentConfiguration.adjustmentType === 'FixedSingleRefund'
  ) {
    const policy = await lifecycles.onAdjustRefund(
      adjustmentConfiguration.quoteID
    );
    policyId = policy.id;
  }

  // If the policy was bought with a finance agreement,
  // adjustments can be: no fee, refund or there are enough
  // remaining payments the adjustment can be spread across
  // them: ie either increasing the monthly finance amount or
  // decreasing it. This obviously requires nothing from
  // the user. It can all be handled behind the scenes.

  if (
    adjustmentConfiguration.adjustmentType === 'FinanceNoFee' ||
    adjustmentConfiguration.adjustmentType === 'FinanceRefund' ||
    adjustmentConfiguration.adjustmentType === 'FinanceUpdate'
  ) {
    const financePayment = await lifecycles.onUpdateFinanceAgreement(
      adjustmentConfiguration.quoteID
    );
    policyId = financePayment.policy.id;
  }

  // If the policy was fully paid for (FixedTermSinglePayment),
  // or bought on a finance agreement yet there aren't enough
  // remaining payments left to spread the adjustment change,
  // then we need to take a one-time payment from the user.

  if (
    adjustmentConfiguration.adjustmentType === 'FixedSinglePayment' ||
    adjustmentConfiguration.adjustmentType === 'FinancePayment'
  ) {
    if (payment) {
      return await payNow(
        payment,
        undefined,
        adjustmentConfiguration,
        lifecycles,
        throwError,
        setError
      );
    } else {
      throw new Error(
        '`adjustNow` is attempting to call `payNow`, but no payment provider configuration was defined.'
      );
    }
  }

  // Lastly, if the policy was bought with a subscription, then
  // it doesn't matter what kind of adjustment we've got, we can make the
  // necessary changes all behind the scenes.

  if (
    adjustmentConfiguration.adjustmentType === 'SubscriptionNoFee' ||
    adjustmentConfiguration.adjustmentType === 'SubscriptionPayment' ||
    adjustmentConfiguration.adjustmentType === 'SubscriptionRefund'
  ) {
    const subscriptionMTA = await lifecycles.onAdjustSubscription(
      adjustmentConfiguration.quoteID
    );

    if (subscriptionMTA.policy) {
      policyId = subscriptionMTA.policy.id;
    } else {
      if (payment) {
        return await payNow(
          payment,
          undefined,
          adjustmentConfiguration,
          lifecycles,
          throwError,
          setError,
          {
            clientSecret: subscriptionMTA.externalData.clientSecret,
            paymentId: subscriptionMTA.paymentID
          }
        );
      } else {
        throw new Error(
          '`adjustNow` is attempting to call `payNow`, but no payment provider configuration was defined.'
        );
      }
    }
  }

  if (policyId) {
    return await lifecycles.onGetPolicy(policyId);
  } else {
    throw new ApplicationError(
      '`adjustNow` is trying to finish, but found no policyId defined'
    );
  }
};
