export function markdownUnorderedFromArray(array:Array<any>, getValue?:Function):string {
  return array.reduce(
    (markdown, arrayItem, i) => {

      const text:string = getValue ? getValue(arrayItem) : arrayItem;

      if (i === (array.length - 1)) {
        return `${ markdown } * ${ text }.`;
      } else {
        return `${ markdown } * ${ text };\r\n\r\n`;
      }
    },
    ''
  );
}
