import type { StripeError as StripeJSError } from '@stripe/stripe-js';

export const friendlyStripeErrorMessage = (stripeError: StripeJSError) => {
  // Card decline
  if (stripeError.code === 'card_declined')
    switch (stripeError.decline_code) {
      case 'insufficient_funds':
        return 'Your card has insufficient funds, please check your balance or try with a different card.';
      case 'lost_card':
        return 'This card has been reported lost and cannot be used for this transaction. Please try with a different card or contact your card issuer for more information.';
      case 'stolen_card':
        return 'This card has been reported stolen and cannot be used for this transaction. Please try with a different card or contact your card issuer for more information.';
    }
  return `${
    stripeError.message ??
    'There was an error processing your card. Please check your card details and try again. '
  } If the problem persists please contact customer support.`;
};
