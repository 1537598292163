import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Scaffold } from '@honey/consume-react-jss';
import { BladePage, BladeMessage, BladeCallToActionSet } from '@aventus/blade';
import { getProductCallToActions } from '../../services/get-product-call-actions';
import { PolicyList } from '../../components/policy-list';
import { useGetCustomConfig } from '@aventus/symphony-client/hooks';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const ProductDashboard: React.FunctionComponent<IProductDashboard> = props => {
  const { customConfig, isGettingCustomConfig } = useGetCustomConfig();

  return (
    <BladePage viewWidth={'dashboard'} title={'Dashboard'}>
      <Scaffold
        orient={'col'}
        fill={true}
        position={'c'}
        style={{ marginTop: '20px' }}
      >
        {props.auth && (
          <PolicyList
            goToPolicy={props.goToPolicy}
            image={customConfig?.userInterface.policy.image || ''}
            icon={customConfig?.userInterface.policy.icon || ''}
            label={customConfig?.userInterface.policy.label || ''}
          />
        )}

        {customConfig && (
          <BladeCallToActionSet
            size={'large'}
            layout={customConfig?.userInterface.dashboardActionLayout}
            alternateBackground={
              customConfig?.userInterface.dashboardActionAlternateBackground
            }
            label={customConfig?.userInterface.dashboardActionLabel}
            callToActions={getProductCallToActions(
              customConfig,
              props.goToProduct
            )}
          />
        )}

        {!customConfig?.activeLinesOfBusiness.length && !isGettingCustomConfig && (
          <Scaffold orient={'col'} size={'default'}>
            <BladeMessage
              variant={'warning'}
              message={"We can't quote right now. Please check back later."}
            />
          </Scaffold>
        )}
      </Scaffold>
    </BladePage>
  );
};

interface IPathParams {
  productReference: ProductReference;
  productCoverReference: ProductCoverReference;
}

interface IProductDashboard extends RouteComponentProps<IPathParams> {
  auth?: boolean | {};
  goToPolicy: (arg: string) => void;
  goToProduct: (productReferenceId: string, name: string) => void;
}
