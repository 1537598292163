import * as React from 'react';
import { useMediaQuery } from '@aventus/pockethooks';
import { BladeAppFooterCompressed } from './compressed';
import { CustomLpoc } from './custom-lpoc';
import { BladeViewVariant } from '../view';
import { useRunner } from '../runner';

export const BladeAppFooter: React.FunctionComponent<IBladeAppFooter> = props => {

  const { footer } = useRunner();
  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  switch (props.variant || footer || 'compressed') {

    case 'compressed':
      return <BladeAppFooterCompressed isSpaceLimited={ matchesQuery === true } {...props} />;
    
    case 'custom-lpoc':
      return <CustomLpoc {...props} />
    
    default:
      return null;
  }

};

export interface IBladeAppFooter {
  variant?: BladeAppFooterVariant;
  viewVariant?: BladeViewVariant;
  company?: {
    logo?: string;
    name?: string;
    link?: string;
    description?: string;
  };
  regulatoryStatement?: string;
  navigation?: BladeImageIconOrLabelLink[];
  connections?: BladeImageIconOrLabelLink[];
  links?: BladeImageIconOrLabelLink[];
  isAuthorised?: boolean;
  changePassword?: () => void;
}

export type BladeAppFooterVariant = 'compressed' | 'quote' | 'custom-lpoc';
