import * as React from 'react';
import { BladeLoader } from '../loader';
import css from './index.css';

export const BladeLoadOver: React.FunctionComponent<IBladeLoadOver> = props => {
  if (!props.isLoading && !props.isSuccessful) {
    return null;
  }

  return (
    <div className={css.loadOver}>
      <BladeLoader isSuccessful={props.isSuccessful} />
    </div>
  );
};

export interface IBladeLoadOver {
  isLoading: boolean;
  isSuccessful?: boolean;
}
