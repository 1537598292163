import { useEffect } from 'react';

/**
 * React useEffect hook to set the browser's
 * document title, aka the browser tab name.
 *
 * @example
 *  useDocumentTitle('Homepage')
 */
export function useDocumentTitle (title?: string) {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [ title ]);
}
