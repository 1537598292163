import * as React from 'react';
import css from './index.css';
import cx from 'classnames';
import { useMediaQuery } from '@aventus/pockethooks';

export const BladePageCover: React.FunctionComponent<IBladePageCover> = props => {
  const { matchesQuery } = useMediaQuery('(max-width: 992px)');
  return (
    <div
      className={cx(css.pageCover, { [css.pageCover_mobile]: matchesQuery })}
      style={{
        backgroundImage: `url('${props.pageBackground}')` || 'none',
        backgroundColor: props.pageBackgroundColor || '#FFD9D6'
      }}
    >
      {props.children}
    </div>
  );
};

interface IBladePageCover {
  pageBackground?: string;
  pageBackgroundColor?: string;
}
