import { ITobesQuestion } from "@aventus/platform-client-context/models";
import { IFormatters } from "../models/formatters";

export function massageValue(
    question: ITobesQuestion,
    formatters: IFormatters,
    _value: any
  ) {
    if (
      (question.dataType === 'DateTime' ||
        question.dataType === 'LocalDateTime') &&
      typeof _value === 'string'
    ) {
      return formatters.dateToApplicationReadable(_value);
    }
  
    // Handles case when we have a province set as a string and the type returned is an object
    if (
      question.referenceID === 'q_province' &&
      _value != null &&
      question.dataType === 'String' &&
      typeof _value == 'object'
    ) {
      return _value.text;
    }
  
    return _value;
  }
  