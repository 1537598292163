import * as React from 'react';
import { guideConsumerJSS } from '@honey/consume-react-jss';
import { BladeLoader } from '../index';
import { Helmet } from 'react-helmet';
import styles from './index.style';
import css from './index.css';
import cx from 'classnames';

const _BladePage: React.FunctionComponent<IBladePage> = props => {
  return (
    <>
      <Helmet
        titleTemplate={`%s | ${props.meta.tenant}`}
        defaultTitle={props.meta.tenant}
      >
        <title>{`${props.title}`}</title>
      </Helmet>
      <div
        style={props.style}
        className={cx('blade-page', css.page, props.classes.page)}
      >
        {props.isLoading && !props.isLoadingOver && (
          <div className={css.page_loader}>
            <BladeLoader />
          </div>
        )}

        {!props.isLoading && (
          <React.Fragment>
            <div
              className={cx(
                css.page_content,
                props.isLoadingOver ? css.page_content_isLoadingOver : ''
              )}
            >
              {props.children}
            </div>
            {props.isLoadingOver && (
              <div className={css.page_content_loadOver}>
                {props.loadOver ?? <BladeLoader />}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  );
};

interface IBladePage {
  /** Set a title for the actual page. Notice the title in the tab browser is set to `...` */
  title: string;
  /** If set to `true`, the component will only return a loading component. */
  isLoading?: boolean;
  /** If set to `true`, the component will return disabled content with an optional overlayed loader. */
  isLoadingOver?: boolean;
  loadOver?: React.ReactNode;
  //loadingMessage: PropTypes.string,
  /** Pass the actual page content as a child. */
  children: React.ReactChildren;
  /** If you want to set an instance of this component to use a different viewWidth, you
  can define the key of that view here. */
  viewWidth?: string;
  style: any;
  /** JSS */
  classes: any;
  /** Meta obj put on by Honey */
  meta: {
    tenant: string;
  };
}

export const BladePage = guideConsumerJSS(_BladePage)(styles);
