import * as React from 'react';
import {
  Policy,
  QuoteType,
  AdjustmentType,
  PaymentPlanType,
  PurchasedPolicy
} from '@aventus/platform';
import { CheckoutSuccessConfiguration } from '@aventus/configuration';
import { OnFinish } from '../interface/lifecycles';
import { getSuccessMessage } from '../services/get-success-message';

export const usePurchasedPolicy = (
  successConfiguration: CheckoutSuccessConfiguration,
  quoteType: QuoteType,
  paymentPlanType: PaymentPlanType | undefined,
  adjustmentType: AdjustmentType | undefined,
  replacements: {
    support: string;
    trustpilot?: string;
    policyReferenceID?: string
  },
  onFinish: OnFinish
): UsePurchasedPolicy => {
  const [purchasedPolicy, setPurchasedPolicy] = React.useState<
    PurchasedPolicy | undefined
  >(undefined);

  React.useEffect(() => {
    if (purchasedPolicy && purchasedPolicy.id) {
      replacements.policyReferenceID = purchasedPolicy.policyReferenceID;

      const success = getSuccessMessage(
        successConfiguration,
        quoteType,
        paymentPlanType,
        adjustmentType,
        replacements,
        purchasedPolicy.createAccount
      );

      setTimeout(() => onFinish(purchasedPolicy, success), 4000);
    }
  }, [purchasedPolicy]);

  return {
    purchasedPolicy,
    setPurchasedPolicy
  };
};

export interface UsePurchasedPolicy {
  purchasedPolicy: Policy | undefined;
  setPurchasedPolicy: (purchasedPolicy: Policy) => void;
}
