import * as React from 'react';
import { ExperianClient, AddressSuggestion } from '../../client/types';
import { AddressInternational } from '@aventus/platform';
import { useError } from '@aventus/pockethooks/use-error';
import { createExperianClient } from '../../client/index';
import mapProvince from './map-province.json';

export function useLookupAddress(
  oracleUrl: string,
  oracleToken: string,
  onChange: (value: AddressInternational) => void,
  clearErrors?: () => void
): UseLookupAddress {
  const [experian, setExperian] = React.useState<ExperianClient | undefined>(
    undefined
  );

  const [query, setQuery] = React.useState<string>('');
  const [addresses, setAddresses] = React.useState<AddressSuggestion[]>([]);
  const [isQuerying, setIsQuerying] = React.useState<boolean>(false);

  const { throwError } = useError();

  // Initialise the experian client first,
  // making sure this is only done once. Using
  // [] as the observables tells React to only
  // run this on initial mount. The conditional check
  // is just extra insurance, mind the pun.

  React.useEffect(() => {
    if (!experian) {
      const _experian = createExperianClient(
        { baseURL: oracleUrl },
        oracleToken
      );
      setExperian(_experian);
    }
  }, []);

  // Experian have a two step process when it comes to selecting
  // an address. The first is the search, which offers a list of
  // suggested addresses the user may be looking for.

  async function search(query: string) {
    setQuery(query);

    // TODO
    // Error handle

    if (!experian || !query) {
      return;
    }

    clearErrors?.();
    setIsQuerying(true);

    try {
      const response = await experian.search(query, 'AUS');
      setAddresses(response.results);
    } catch (error) {
      throwError(error);
    }

    setIsQuerying(false);
  }

  // The next step comes after the user has identified one of the
  // suggestions, and wants to select it. To do that, we then we need to
  // turn that suggestion into a formatted address using experian's
  // format option. Each suggestion get returned with the format url
  // prepared for us.

  async function format(suggestion: AddressSuggestion) {
    // TODO
    // Error handle

    if (!experian || !suggestion) {
      return;
    }

    clearErrors?.();
    setIsQuerying(true);

    try {
      const response = await experian.format(suggestion.format);

      onChange({
        line1: response.address[0].addressLine1,
        line2: response.address[1].addressLine2,
        line3: response.address[2].addressLine3,
        locality: response.address[3].locality,
        province:
          mapProvince[response.address[6].country][
            response.address[4].province
          ],
        postalCode: response.address[5].postalCode,
        country: { referenceID: 'AU', text: 'Australia' },
        uniqueReference: response.metadata.addressIds?.gnafPid || null,
        addressIds: null
      });
    } catch (error) {
      throwError(error);
    }

    setIsQuerying(false);
  }

  return {
    query,
    setQuery: search,
    formatQuery: format,
    addresses,
    isQuerying
  };
}

interface UseLookupAddress {
  query: string;
  setQuery: (query: string) => void;
  formatQuery: (suggestion: AddressSuggestion) => void;
  addresses: AddressSuggestion[];
  isQuerying: boolean;
}
