import * as React from 'react';
import css from './index.css';
import { BladeView, BladeViewVariant } from '../view';
import { BladeLoader } from '../loader';

export const BladeViewIsLoading: React.FunctionComponent<IBladeViewIsLoading> = ({
  className,
  variant
}) => {
  return (
    <BladeView variant={variant} className={className}>
      <div className={css.viewIsLoading}>
        <BladeLoader />
      </div>
    </BladeView>
  );
};

export interface IBladeViewIsLoading {
  variant: BladeViewVariant;
  className?: string;
}
