import { IMajorMoney } from '@aventus/platform-client-context/models';
import { convertLocale } from '@aventus/pocketknife/currency-to-human-readable';
import { useState } from 'react';
import numeral from 'numeral';
import 'numeral/locales';

export const COMMA_NUMBER_INPUT_REGEX = /^$|^\d(\d|,|\.)*$/;

const Formatter = (value: number, locale?: string) =>
new Intl.NumberFormat(locale, {
  minimumFractionDigits: 2
}).format(value);

export function useMoney(
  _value: IMajorMoney,
  _onChange: BladeInputOnChangeCustom,
  currencyCode: string,
  _onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  _onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
): IUseMoney {
  const [inputValue, setInputValue] = useState<string>(
    String(_value?.value || '')
  );

  const locale = convertLocale(currencyCode);
  numeral.locale(locale);

  const onChange: BladeInputOnChange = e => {
    const stringValue = String(e.target.value);
  
    // restrict user input to numbers, commas, and dots
    if (COMMA_NUMBER_INPUT_REGEX.test(stringValue)) {

      // split decimals from the rest of the value
      // "10,000.22" converts to ["10,000", ".22"];
      const [_matchedValue = "", _decimals = ""] = stringValue
        .match(/(\.\d{0,2})|(\d{1,3},?)*/g) || [];
      const matchedValue = _matchedValue || "";
      const decimals = _decimals || "";

      // format value ignoring decimals
      const valueNumeral = numeral(matchedValue);
      const formattedValue = valueNumeral.format();

      // putting value back together with decimal part
      const completeString = valueNumeral.input() === "" ? "" : `${formattedValue}${decimals}`;
      const completeNumber = Number(`${valueNumeral.value()}${decimals}`);

      // numeral format() method returns NaN as a string
      if (formattedValue !== "NaN") {
        setInputValue(completeString);
        _onChange({ value: completeNumber, currencyCode });
      }
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (typeof _onFocus === 'function') {
      _onFocus(event);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const numeralValue = numeral(inputValue).value();

    if (numeralValue !== null) {
      const newValue = inputValue === "" ? "" : Formatter(numeralValue, locale);
      setInputValue(newValue);
    }

    if (typeof _onBlur === 'function') {
      _onBlur(event);
    }
  };

  return {
    value: inputValue,
    onChange,
    onFocus,
    onBlur
  };
}

interface IUseMoney {
  value: string;
  onChange: BladeInputOnChange;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
}
