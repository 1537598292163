import * as React from 'react';
import {
  PricingSet,
  PaymentProvider,
  MtaInformation,
  AdjustmentType,
  PaymentPlanType
} from '@aventus/platform';
import { IUsePaymentPlan, usePaymentPlan } from '@aventus/mvmt-pricing';
import { BinderPayment } from '../interface/binder';
import { ApplicationError } from '@aventus/errors';

export const usePaymentPlanWithProviders = (
  plans: PricingSet | undefined,
  defaultPaymentPlanType: PaymentPlanType | undefined,
  adjustmentInformation: MtaInformation | undefined,
  adjustmentType: AdjustmentType | undefined
): UsePaymentPlanWithProviders => {
  const { selectedPricingPlan, setSelectedPricingPlan, otherPricingPlan } =
    usePaymentPlan(plans, defaultPaymentPlanType);

  // We want to keep a record, based on the
  // selected payment plan, of the provider payments.

  // The purpose of this record is to indicate
  // - which providers are active, given the user selected
  //   payment plan
  // - store the finalised payment request for that
  //   provider
  // - serve as references for the root payment store

  const [providerPayments, setProviderPayments] = React.useState<
    ProviderPayments | undefined
  >(undefined);

  // A payment plan has two kinds of payments:
  // - a payment
  // - a deposit payment (optional)

  const [payment, setPayment] = React.useState<PaymentProvider | undefined>(
    undefined
  );
  const [depositPayment, setDepositPayment] = React.useState<
    PaymentProvider | undefined
  >(undefined);

  // Since the providers is based on the payment plan,
  // we only want to run this check or setup whenever
  // the selected payment plan changes.

  React.useEffect(() => {
    // For new payments, we'd expect to find
    // the set of payment plans associated with the
    // quote.

    if (selectedPricingPlan) {
      // We want to make a copy of the state instead of directly
      // updating the state so that we can control the re-renders.
      // We'll update the state once for every run through, rather
      // than multiple times within the same execution.

      let _providerPayments: ProviderPayments | undefined = providerPayments;

      const paymentProvider: PaymentProvider =
        selectedPricingPlan.paymentPlan.paymentProvider;
      const depositProvider: PaymentProvider =
        selectedPricingPlan.paymentPlan.depositPaymentProvider;

      // We'll check both of these to figure out
      // which providers are necessary. For each payment,
      // if the provider was already initialised, then we want to
      // re-use, otherwise we'll initialise.

      if (paymentProvider !== 'None') {
        _providerPayments = mergeProviderObj(
          _providerPayments,
          paymentProvider
        );
      }

      if (depositProvider !== 'None') {
        _providerPayments = mergeProviderObj(
          _providerPayments,
          depositProvider
        );
      }

      setPayment(paymentProvider);
      setDepositPayment(depositProvider);
      setProviderPayments(_providerPayments);
    }

    // For existing policies, the adjustment is different.
    // There are no payment plans, but just adjustment information.

    if (adjustmentInformation && adjustmentType) {
      // We want to make a copy of the state instead of directly
      // updating the state so that we can control the re-renders.
      // We'll update the state once for every run through, rather
      // than multiple times within the same execution.

      let _providerPayments: ProviderPayments | undefined = providerPayments;

      const paymentProvider: PaymentProvider =
        adjustmentInformation.paymentPlan.paymentProvider;
      const depositProvider: PaymentProvider =
        adjustmentInformation.paymentPlan.depositPaymentProvider;

      if (
        adjustmentType === 'FinancePayment' ||
        adjustmentType === 'FixedSinglePayment'
      ) {
        if (paymentProvider !== 'None') {
          _providerPayments = mergeProviderObj(
            _providerPayments,
            paymentProvider
          );
        }

        if (depositProvider !== 'None') {
          _providerPayments = mergeProviderObj(
            _providerPayments,
            depositProvider
          );
        }

        setPayment(paymentProvider);
        setDepositPayment(depositProvider);
        setProviderPayments(_providerPayments);
      }

      if (adjustmentType === 'SubscriptionPayment') {
        if (paymentProvider !== 'None') {
          _providerPayments = mergeProviderObj(
            _providerPayments,
            paymentProvider
          );
        }

        if (depositProvider !== 'None') {
          _providerPayments = mergeProviderObj(
            _providerPayments,
            depositProvider
          );
        }

        if (paymentProvider !== 'FatZebra') {
          setPayment(paymentProvider);
          setDepositPayment(depositProvider);
          setProviderPayments(_providerPayments);
        }
      }
    }
  }, [
    selectedPricingPlan,
    adjustmentInformation,
    adjustmentType,
    providerPayments
  ]);

  const mergeProviderObj = (
    current: ProviderPayments | undefined,
    provider: PaymentProvider
  ) => {
    if (!current || !current[provider]) {
      return {
        ...current,
        ...{
          [provider]: {
            provider: provider,
            providerRequest: undefined
          }
        }
      };
    }

    return current;
  };

  // When any of the provider specific components
  // finish their own data collection, they need to update
  // the provider store here with those details. This will indicate
  // that the specific provider has completed it's data collection.

  function onProviderPaymentReady(
    provider: PaymentProvider,
    providerRequest: any
  ) {
    if (providerPayments?.[provider]) {
      setProviderPayments({
        ...providerPayments,
        ...{
          [provider]: {
            ...providerPayments[provider],
            ...{ providerRequest }
          }
        }
      });
    } else {
      throw new ApplicationError(
        `\`onProviderPaymentReady\` was called for an unregistered or uninitialised payment provider; ${provider}`
      );
    }
  }

  // Based on what's defined for payment and deposit payment,
  // we can figure out if the form is ready for purchase.
  // The conditional logic below is simply checking:

  // - Is the type of payment required for the selected plan?
  //   - If not required, then consider it to be valid
  //   - If required, then check the request object to see if it's been added.
  //     - Yes added, then valid
  //     - Not added, then not valid yet

  const canMakePayment: boolean =
    (payment && payment !== 'None'
      ? providerPayments?.[payment]?.providerRequest !== undefined
        ? true
        : false
      : true) &&
    (depositPayment && depositPayment !== 'None'
      ? providerPayments?.[depositPayment]?.providerRequest !== undefined
        ? true
        : false
      : true);

  return {
    selectedPricingPlan,
    setSelectedPricingPlan,
    otherPricingPlan,
    providerPayments,
    payment,
    depositPayment,
    onProviderPaymentReady,
    canMakePayment
  };
};

export interface UsePaymentPlanWithProviders extends IUsePaymentPlan {
  providerPayments: ProviderPayments | undefined;
  payment: PaymentProvider | undefined;
  depositPayment: PaymentProvider | undefined;
  onProviderPaymentReady: (
    provider: PaymentProvider,
    providerRequest: any
  ) => void;
  canMakePayment: boolean;
}

export type ProviderPayments = Partial<Record<PaymentProvider, BinderPayment>>;
