// Taken form https://github.com/sl-julienamblard/email-misspelled/
export const aol: string[] = [
	"aol.at",
	"aol.be",
	"aol.ch",
	"aol.cl",
	"aol.co.nz",
	"aol.co.uk",
	"aol.com",
	"aol.com.ar",
	"aol.com.au",
	"aol.com.br",
	"aol.com.co",
	"aol.com.mx",
	"aol.com.tr",
	"aol.com.ve",
	"aol.cz",
	"aol.de",
	"aol.dk",
	"aol.es",
	"aol.fi",
	"aol.fr",
	"aol.hk",
	"aol.in",
	"aol.it",
	"aol.jp",
	"aol.kr",
	"aol.nl",
	"aol.pl",
	"aol.ru",
	"aol.se",
	"aol.tw",
]
export default aol
