import * as React from 'react';
import { ProductCustomDashboardError } from '../views/product-dashboard-custom-error';

import { PlatformUnauthorisedError } from '@aventus/errors';

export class ProductBoundary extends React.Component<
  IProductBoundary,
  IProductBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, redirect: false };
  }

  componentDidCatch(error: any) {
    if (error.response?.status === 400) {
      this.setState({ error: true });
    } else if (error.response?.status === 401) {
      this.setState({ error: true, redirect: true });
    } else if (error instanceof PlatformUnauthorisedError) {
      this.setState({ error: true, redirect: true });
    }
  }

  render() {
    if (this.state.error && !this.state.redirect) {
      return <ProductCustomDashboardError />;
    } else if (this.state.error && this.state.redirect) {
      this.props.signOut && this.props.signOut();
      return null;
    } else {
      return this.props.children;
    }
  }
}

export interface IProductBoundary {
  children: any;
  signOut?: () => void;
}

export interface IProductBoundaryState {
  error: boolean | null;
  redirect: boolean;
}
