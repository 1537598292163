import {
  AUTH_SIGN_UP_REQUEST,
  AUTH_SIGN_UP_SUCCESS,
  AUTH_SIGN_UP_FAILURE
} from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import clearAllAlertsAction from '../../../api/core/alerts/clear-all-alerts/actions';
import getExternalId from '../../../api/core/tracking/get-external-id';
import signInAction from '../sign-in/actions';

export default function signUpAction(username, password) {
  return (dispatch, getStore) => {
    const externalId = getExternalId(getStore());
    dispatch({
      type: 'API_REQUEST',
      [API_SYM]: {
        requestConfig: {
          url: 'user/signup',
          method: 'POST',
          data: {
            username,
            password,
            externalID: externalId
          }
        },
        actions: [
          signUpRequestAction,
          signUpSuccessAction,
          signUpFailureAction
        ],
        passToAction: {
          username,
          password
        }
      }
    });
  };
}

export function signUpRequestAction() {
  return {
    type: AUTH_SIGN_UP_REQUEST
  };
}

export function signUpSuccessAction(res, passToAction) {
  return dispatch => {
    dispatch({
      type: AUTH_SIGN_UP_SUCCESS,
      res
    });

    const { username, password } = passToAction;

    dispatch(signInAction(username, password, true));
  };
}

export function signUpFailureAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_SIGN_UP_FAILURE
    });

    dispatch(
      queueAlertAction({
        type: 'error',
        message: res.message,
        location: false
      })
    );
  };
}
