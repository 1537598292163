import { AxiosRequestConfig } from 'axios';
import { createClient } from '@aventus/client';
import querystring from 'querystring';
import { interceptResponse } from './intercept-response';
import * as rax from 'retry-axios';
import * as SymphonyClient from '@aventus/symphony-client';
import { isJwtExpired } from 'jwt-check-expiration';

import {
  OrganisationSettings,
  IntelligentQuoteRequest,
  IntelligentQuoteResponse,
  QuoteBundle,
  QuoteProduct,
  Policy,
  PolicyBundle,
  PaymentInitialisation,
  PolicyCancellationInfo,
  Payment,
  Product,
  FinancePayment,
  StripeCreateIntent,
  SubscriptionInitialisation,
  SubscriptionMTA,
  PricingSet,
  CreditAgreement,
  PolicyAddon,
  CustomConfig,
  Risk,
  Scheme,
  PaymentMethod,
  QuickQuoteResponse,
  DiscountInfo,
  IRenewalInfo
} from '@aventus/platform';
import { SymphonyResponse } from '@aventus/symphony-client';
import { getToken } from '@aventus/platform-client-context/token-service';

export const createSymphonyClient: SymphonyClient.CreateSymphonyClient =
  function (
    options: AxiosRequestConfig
    // auth0?: Auth0Client
  ) {
    const symphony = createClient(options);

    symphony.defaults.raxConfig = {
      instance: symphony
    };

    rax.attach(symphony);

    symphony.interceptors.response.use(
      interceptResponse.onFulfilled,
      interceptResponse.onRejected
    );

    let orgSettings: OrganisationSettings;
    let externalId: string;
    let isAuthenticated: boolean = false;
    let authToken: undefined | string;
    let paymentAuthToken: undefined | string;

    // Get an organisation's global configuration settings.
    // These include global definitions for a given org like what
    // timezone they are based in along with what currency.

    const getOrganisationSettings: SymphonyClient.GetOrganisationSettings =
      async () => {
        if (orgSettings) {
          return orgSettings;
        }

        const response = await symphony.get<
          SymphonyResponse<OrganisationSettings>
        >(`/config/organisation`, { raxConfig: { retry: 3 } });

        orgSettings = response.data.data;
        return orgSettings;
      };

    // Fetch address data from GBG lookup. This is a
    // hard-coded address lookup service that is associated with the
    // client because Homelyfe. Hopefully this is something we can
    // fix down the line.

    const lookupAddress: SymphonyClient.LookupAddress = async (
      addressQuery: string
    ) => {
      const response = await symphony.post<
        SymphonyResponse<SymphonyClient.AddressLookup>
      >('/lookup/address', { query: addressQuery, state: '' });
      return response.data.data;
    };

    const getCustomConfig: SymphonyClient.GetCustomConfig = async () => {
      const response = await symphony.get<SymphonyResponse<CustomConfig>>(
        'config/app',
        { raxConfig: { retry: 3 } }
      );

      return response.data.data;
    };

    const getProduct: SymphonyClient.GetProduct = async (
      productReference,
      coverType
    ) => {
      const response = await symphony.get<SymphonyResponse<Product>>(
        `product?` + querystring.stringify({ productReference, coverType })
      );
      return response.data.data;
    };

    // TODO
    // comment

    const startIntelligentQuoteForNew: SymphonyClient.StartIntelligentQuoteForNew =
      async (productReferenceID, coverType, quoteID, partnerId, voucherCode, partnerReference) => {
        const response = await symphony.get<
          SymphonyResponse<IntelligentQuoteRequest>
        >(
          `tobes/start?` +
          querystring.stringify({
            productReferenceID,
            coverType,
            quoteID,
            partnerId,
            voucherCode,
            partnerReference
          })
        );

        let risk = response.data.data.risk;
        let tobesState = response.data.data.tobesState;

        if (externalId) {
          risk = {
            ...risk,
            ...{ externalID: externalId }
          };
        }

        return { risk, tobesState };
      };

    // TODO
    // comment

    const startIntelligentQuoteForRenew: SymphonyClient.StartIntelligentQuoteForRenew =
      async (quoteID, policyID, partnerId) => {
        const response = await symphony.get<
          SymphonyResponse<IntelligentQuoteRequest>
        >(
          `tobes/startrenewal?` +
          querystring.stringify({
            quoteID,
            policyID,
            partnerId
          })
        );

        let risk = response.data.data.risk;
        let tobesState = response.data.data.tobesState;

        if (externalId) {
          risk = {
            ...risk,
            ...{ externalID: externalId }
          };
        }

        return { risk, tobesState };
      };

    // TODO
    // comment

    const startIntelligentQuoteForAdjust: SymphonyClient.StartIntelligentQuoteForAdjust =
      async (policyID, partnerId) => {
        const response = await symphony.get<
          SymphonyResponse<IntelligentQuoteRequest>
        >(
          `tobes/startmta?` +
          querystring.stringify({
            policyID,
            partnerId
          })
        );

        let risk = response.data.data.risk;
        let tobesState = response.data.data.tobesState;

        if (externalId) {
          risk = {
            ...risk,
            ...{ externalID: externalId }
          };
        }

        return { risk, tobesState };
      };

    // TODO
    // comment

    const nextIntelligentQuote: SymphonyClient.NextIntelligentQuote = async (
      risk,
      tobesState
    ) => {
      // ----------------
      // HACKS
      // (https://aventusplatform.atlassian.net/browse/AVT-245)

      if (
        risk.productReferenceID === 'hl_pr_home' &&
        risk.insuredProperty?.addressAsCorrespondence === true
      ) {
        risk.proposer.address = risk.insuredProperty.address;
      }

      if (
        risk.productReferenceID === 'hl_pr_home' &&
        risk.proposer.hasPreviousClaims === false
      ) {
        risk.previousClaims = [];
      }

      // -----------------

      const response = await symphony.post<
        SymphonyResponse<IntelligentQuoteResponse>
      >('tobes/next', { risk, tobesState });

      return response.data.data;
    };

    // TODO
    // comment

    const nextQuoteIntelligentQuoteForNew: SymphonyClient.NextQuoteIntelligentQuote =
      async (risk, tobesState, confirm) => {
        const response = await symphony.post<SymphonyResponse<QuoteBundle>>(
          `tobes/nextquote?confirm=${confirm === true ? true : false}`,
          { risk, tobesState }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const nextQuoteIntelligentQuoteForRenew: SymphonyClient.NextQuoteIntelligentQuote =
      async (risk, tobesState, confirm) => {
        const response = await symphony.post<SymphonyResponse<QuoteBundle>>(
          `tobes/nextquoterenewal?confirm=${confirm === true ? true : false}`,
          { risk, tobesState }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const nextQuoteIntelligentQuoteForAdjust: SymphonyClient.NextQuoteIntelligentQuote =
      async (risk, tobesState, confirm) => {
        const response = await symphony.post<SymphonyResponse<QuoteBundle>>(
          `tobes/nextquotemta?confirm=${confirm === true ? true : false}`,
          { risk, tobesState }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const getQuote: SymphonyClient.GetQuote =
      async (quoteId, allowQuoteReRate) => {
        const response = await symphony.get<SymphonyResponse<QuoteBundle>>(
          `quotes/${quoteId}?allowQuoteReRate=${allowQuoteReRate}`
        );
        return response.data.data;
      };

    // TODO
    // comment

    const getVoucherInfo: SymphonyClient.GetVoucherInfo = async tobesState => {
      const response = await symphony.post<SymphonyResponse<any>>(
        `tobes/voucher/info`,
        { tobesState }
      );
      return response.data.data;
    };

    const removeVoucher: SymphonyClient.RemoveVoucher = async tobesState => {
      const response = await symphony.post<SymphonyResponse<any>>(
        `tobes/voucher/remove`,
        { tobesState }
      );
      return response.data.data;
    };

    // TODO
    // comment

    const applyVoucher: SymphonyClient.ApplyVoucher = async (
      risk,
      voucherCode,
      tobesState
    ) => {
      const response = await symphony.post<SymphonyResponse<any>>(
        `tobes/voucher`,
        { risk, voucherCode, tobesState }
      );
      return response.data.data;
    };

    // TODO
    // comment

    const getQuoteRenewal: SymphonyClient.GetQuoteRenewal =
      async activeRenewalQuoteID => {
        const response = await symphony.get<SymphonyResponse<QuoteBundle>>(
          `quotes/renewal/${activeRenewalQuoteID}`
        );
        return response.data.data;
      };

    // TODO
    // comment

    const getQuoteProduct: SymphonyClient.GetQuoteProduct = async quoteId => {
      const response = await symphony.get<SymphonyResponse<QuoteProduct>>(
        `quotes/${quoteId}/product`
      );
      return response.data.data;
    };

    // Fetch policy cancel data.

    const getPolicyCancellationInfo: SymphonyClient.GetPolicyCancellationInfo =
      async policyId => {
        const response = await symphony.get<
          SymphonyResponse<PolicyCancellationInfo>
        >(`policies/cancel/${policyId}/info`);
        return response.data.data;
      };

    // Cancel policy

    const cancelPolicy: SymphonyClient.CancelPolicy = async (
      policyID,
      selectedCancellationReason
    ) => {
      const response = await symphony.post<SymphonyResponse<Policy>>(
        `policies/cancel`,
        { policyID, selectedCancellationReason }
      );
      return response.data.data;
    };

    // TODO
    // comment

    const getPolicy: SymphonyClient.GetPolicy = async policyId => {
      const response = await symphony.get<SymphonyResponse<Policy>>(
        `policies/${policyId}`
      );
      return response.data.data;
    };

    const getPolicyBundle: SymphonyClient.GetPolicyBundle = async policyId => {
      const response = await symphony.get<SymphonyResponse<PolicyBundle>>(
        `policies/${policyId}/bundle`
      );
      return response.data.data;
    };

    // TODO
    // comment

    const getPayment: SymphonyClient.GetPayment = async paymentId => {
      const response = await symphony.get<SymphonyResponse<Payment>>(
        `payments/${paymentId}`
      );
      return response.data.data;
    };

    // TODO
    // comment

    const getPolicyForPayment: SymphonyClient.GetPolicyForPayment =
      async paymentId => {
        const response = await symphony.get<SymphonyResponse<Policy>>(
          `payments/${paymentId}/policy`
        );
        return response.data.data;
      };

    // TODO
    // comment

    const createFinanceAgreement: SymphonyClient.CreateFinanceAgreement =
      async (
        accountNumber,
        encryptedCreditPlan,
        encryptedDiscount,
        fullName,
        paymentID,
        paymentPlanReferenceID,
        sortCode,
        title
      ) => {
        const response = await symphony.post<SymphonyResponse<FinancePayment>>(
          `payments/finance`,
          {
            accountNumber,
            encryptedCreditPlan,
            encryptedDiscount,
            fullName,
            paymentID,
            paymentPlanReferenceID,
            sortCode,
            title
          }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const updateFinanceAgreement: SymphonyClient.UpdateFinanceAgreement =
      async quoteID => {
        const response = await symphony.post<SymphonyResponse<FinancePayment>>(
          `payments/finance/mta`,
          {
            quoteID
          }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const initialisePayment: SymphonyClient.InitialisePayment = async (
      quoteID,
      encryptedDiscount,
      paymentPlanReferenceID,
      financeRequestData
    ) => {
      const response = await symphony.post<
        SymphonyResponse<PaymentInitialisation>
      >(`payments/initialise`, {
        encryptedDiscount,
        paymentPlanReferenceID,
        quoteID,
        financeRequestData
      });
      return response.data.data;
    };

    // TODO
    // comment

    const initialiseSubscription: SymphonyClient.InitialiseSubscription =
      async (
        quoteID,
        encryptedDiscount,
        paymentPlanReferenceID,
        paymentMethodID
      ) => {
        const response = await symphony.post<
          SymphonyResponse<SubscriptionInitialisation>
        >(`subscriptions/initialise`, {
          quoteID,
          encryptedDiscount,
          paymentPlanReferenceID,
          paymentMethodID
        });
        return response.data.data;
      };

    // TODO
    // comment

    const stripeCreateSetupIntent: SymphonyClient.StripeCreateSetupIntent =
      async () => {
        const response = await symphony.get<
          SymphonyResponse<StripeCreateIntent>
        >(`stripe/savedcards/setup`);
        return response.data.data;
      };

    /**
     * BNP Finance
     *
     * @returns
     */
    const createBNPFinanceAgreement: SymphonyClient.CreateBNPFinanceAgreement =
      async (paymentRequest: any) => {
        const response = await symphony.post(
          `payments/bnp/finance`,
          paymentRequest
        );
        return response.data.data;
      };

    const createBNPFinanceAgreementNoDeposit: SymphonyClient.CreateBNPFinanceAgreementNoDeposit =
      async (paymentRequest: any) => {
        const response = await symphony.post(`bnp/purchase`, paymentRequest);
        return response.data.data;
      };

    /**
     * FatZebra Get OAuth Token
     *
     * @param
     * @returns IFatZebraTokenResponse
     */

    const fatZebraGetConfig: SymphonyClient.FatZebraGetConfig =
      async (quoteID?: string, policyID?: string) => {
        const response = await symphony.get<SymphonyResponse<any>>(
          `fatzebra/config?` + querystring.stringify({
            quoteID,
            policyID
          })
        );
        return response.data.data;
      };

    /**
     * FatZebra generate payment intent verification token
     *
     * @param props
     * @returns IFatZebraVerificationResponse
     */
    const fatZebraGeneratePaymentIntentVerification: SymphonyClient.FatZebraGeneratePaymentIntentVerification =
      async props => {
        const response = await symphony.post(
          `fatzebra/paymentintent/verification`,
          props
        );
        return response.data.data;
      };

    const fatZebraAnnualPurchase: SymphonyClient.FatZebraAnnualPurchase =
      async props => {
        const response = await symphony.post(`fatzebra/purchase`, props);
        return response.data.data;
      };

    const fatZebraSubscriptionPurchase: SymphonyClient.FatZebraSubscriptionPurchase =
      async props => {
        const response = await symphony.post(
          `fatzebra/subscription/purchase`,
          props
        );
        return response.data.data;
      };

    const fatZebraMidTermAdjustmentRefund: SymphonyClient.FatZebraMidTermAdjustmentRefund =
      async quoteID => {
        const response = await symphony.post(`fatzebra/mtarefund`, {
          quoteID
        });
        return response.data.data;
      };

    const fatZebraSubscriptionMTA: SymphonyClient.FatZebraSubscriptionMTA =
      async quoteID => {
        const response = await symphony.post(`fatzebra/subscription/mta`, {
          quoteID
        });
        return response.data.data;
      };

    // TODO
    // comment

    const adjustRefund: SymphonyClient.AdjustRefund = async quoteID => {
      const response = await symphony.post<SymphonyResponse<Policy>>(
        `payments/mtarefund`,
        {
          quoteID
        }
      );
      return response.data.data;
    };

    // TODO
    // comment

    const adjustSubscription: SymphonyClient.AdjustSubscription =
      async quoteID => {
        const response = await symphony.post<SymphonyResponse<SubscriptionMTA>>(
          `subscriptions/mta`,
          {
            quoteID
          }
        );
        return response.data.data;
      };

    // TODO
    // comment

    const applyVoucherCode: SymphonyClient.ApplyVoucherCode = async (
      voucherCode,
      quoteID
    ) => {
      const response = await symphony.post<SymphonyResponse<PricingSet>>(
        `payments/voucher`,
        {
          quoteID,
          voucherCode
        }
      );
      return response.data.data;
    };

    const removeVoucherCode: SymphonyClient.RemoveVoucherCode =
      async quoteID => {
        const response = await symphony.post<SymphonyResponse<PricingSet>>(
          `payments/${quoteID}/voucher/remove`,
          {
            quoteID
          }
        );
        return response.data.data;
      };

    const getPolicyCreditAgreement: SymphonyClient.GetPolicyCreditAgreement =
      async policyId => {
        const response = await symphony.get<SymphonyResponse<CreditAgreement>>(
          `policies/${policyId}/creditagreement`
        );
        return response.data.data;
      };

    const getPolicyDiscount: SymphonyClient.GetPolicyDiscount =
      async policyId => {
        const response = await symphony.get<SymphonyResponse<DiscountInfo>>(
          `policies/${policyId}/discountinfo`
        );
        return response.data.data;
      };

    const getPolicyAddons: SymphonyClient.GetPolicyAddons = async policyId => {
      const response = await symphony.get<SymphonyResponse<PolicyAddon[]>>(
        `policies/${policyId}/addons`
      );
      return response.data.data;
    };

    const getPolicyRisk: SymphonyClient.GetPolicyRisk = async policyId => {
      const response = await symphony.get<SymphonyResponse<Risk>>(
        `policies/${policyId}/risk`
      );
      return response.data.data;
    };

    const getPolicyProduct: SymphonyClient.GetPolicyProduct =
      async policyId => {
        const response = await symphony.get<SymphonyResponse<QuoteProduct>>(
          `policies/${policyId}/product`
        );
        return response.data.data;
      };

    const getPolicyScheme: SymphonyClient.GetPolicyScheme = async policyId => {
      const response = await symphony.get<SymphonyResponse<Scheme>>(
        `policies/${policyId}/scheme`
      );
      return response.data.data;
    };

    const getPolicies: SymphonyClient.GetPolicies = async (activeOnly = true) => {
      const response = await symphony.get<SymphonyResponse<Policy[]>>(
        'policies/mypolicies', { params: { activeOnly } }
      );
      return response.data.data;
    };

    const getPolicyPaymentMethod: SymphonyClient.GetPolicyPaymentMethod =
      async policyId => {
        const response = await symphony.get<SymphonyResponse<PaymentMethod>>(
          `policies/${policyId}/paymentmethod`
        );
        return response.data.data;
      };

    const updatePolicyPaymentMethod: SymphonyClient.UpdatePolicyPaymentMethod =
      async (policyId, paymentMethodId) => {
        const response = await symphony.post<SymphonyResponse<PaymentMethod>>(
          `policies/${policyId}/updatepaymentmethod?paymentMethodId=${paymentMethodId}`
        );
        return response.data.data;
      };

    const generateQuickQuote: SymphonyClient.GenerateQuickQuote = async (
      coverTypes,
      risk,
      partnerId,
      partnerReference,
      productReferenceID
    ) => {
      const response = await symphony.post<
        SymphonyResponse<QuickQuoteResponse[]>
      >(`quickquote`, {
        coverTypes,
        risk,
        partnerId,
        partnerReference,
        productReferenceID
      });
      return response.data.data;
    };

    const generateQuickQuoteFromPartialRisk: SymphonyClient.GenerateQuickQuoteFromPartialRisk =
      async (
        coverTypes,
        partialRisk,
        partnerId,
        partnerReference,
        productReferenceID
      ) => {
        const response = await symphony.post<
          SymphonyResponse<QuickQuoteResponse[]>
        >(`quickquote/frompartialrisk`, {
          coverTypes,
          partialRisk,
          partnerId,
          partnerReference,
          productReferenceID
        });
        return response.data.data;
      };

    const setAutoRenewalStatus: SymphonyClient.SetAutoRenewalStatus = async (
      policyId,
      allowAutoBind
    ) => {
      const response = await symphony.post<SymphonyResponse<IRenewalInfo>>(
        `renewal/${policyId}/settings`,
        {
          allowAutoBind
        }
      );
      return response.data.data;
    };

    const editQuote: SymphonyClient.EditQuote = async renewalQuoteId => {
      const response = await symphony.get<
        SymphonyResponse<IntelligentQuoteResponse>
      >(`tobes/start/${renewalQuoteId}/editfull`);
      return response.data.data;
    };

    const getRenewalSettings: SymphonyClient.GetRenewalSettings =
      async productId => {
        const response = await symphony.get(
          `product/${productId}/renewalsettings`
        );
        return response.data.data;
      };

    // TODO
    // comment

    // Temporary hack to save the external id from
    // current redux store.

    const saveExternalId = (_externalId: string) => (externalId = _externalId);
    const getExternalId = () => externalId;

    // Another temporary hack to support bridging existing authentication
    // with this new client.

    const logIn = (token: string) => {
      authToken = token;
      isAuthenticated = true;
    };

    const logOut = () => {
      authToken = undefined;
      isAuthenticated = false;
    };

    const isUserAuthenticated = () => {
      if (authToken === undefined) {
        // For hybrid flows in portal we need to take in the passed in token
        var _token = getToken();
        if (_token !== '') {
          logIn(_token);
        }
      }
      return (
        isAuthenticated && authToken !== undefined && !isJwtExpired(authToken)
      );
    };

    const getPaymentToken: SymphonyClient.GetPaymentToken =
      async quoteId => {
        const response = await symphony.get<SymphonyResponse<string>>(
          `token/payment?quoteID=${quoteId}`
        );

        paymentAuthToken = response.data.data;
      };

    const clearPaymentToken: SymphonyClient.ClearPaymentToken = () => {
      paymentAuthToken = undefined;
    }

    const beginBasicAuthentication: SymphonyClient.BeginBasicAuthentication = async (username?: string, password?: string) => {
      let basicAuthString = "";

      if (username && password) {
        basicAuthString = Buffer.from(`${username}:${password}`).toString('base64');
      }

      await symphony.get<SymphonyResponse<boolean>>(
        `auth`, { headers: { Authorization: `Basic ${basicAuthString}` } }
      );

      return true;
    }

    symphony.interceptors.request.use(request => {
      if (isUserAuthenticated()) {
        request.headers = Object.assign(
          {},
          {
            Authorization: `Bearer ${authToken}`
          },
          request.headers
        );
      }
      else if (paymentAuthToken !== undefined && !isJwtExpired(paymentAuthToken)) {
        request.headers = Object.assign(
          {},
          {
            Authorization: `Bearer ${paymentAuthToken}`
          },
          request.headers
        );
      }
      return request;
    });

    return {
      saveExternalId,
      getExternalId,
      logIn,
      logOut,
      isUserAuthenticated,
      getOrganisationSettings,
      lookupAddress,
      startIntelligentQuoteForNew,
      startIntelligentQuoteForRenew,
      startIntelligentQuoteForAdjust,
      nextIntelligentQuote,
      nextQuoteIntelligentQuoteForNew,
      nextQuoteIntelligentQuoteForAdjust,
      nextQuoteIntelligentQuoteForRenew,
      getProduct,
      getQuote,
      getQuoteRenewal,

      // Tobes Voucher
      getVoucherInfo,
      applyVoucher,
      removeVoucher,
      removeVoucherCode,

      getQuoteProduct,
      getPolicy,
      getPolicyCancellationInfo,
      getPolicyCreditAgreement,
      cancelPolicy,
      initialisePayment,
      initialiseSubscription,
      getPayment,
      getPolicyForPayment,
      createFinanceAgreement,
      updateFinanceAgreement,
      stripeCreateSetupIntent,

      createBNPFinanceAgreement,
      createBNPFinanceAgreementNoDeposit,

      fatZebraGetConfig,
      fatZebraGeneratePaymentIntentVerification,
      fatZebraAnnualPurchase,
      fatZebraSubscriptionPurchase,
      fatZebraMidTermAdjustmentRefund,
      fatZebraSubscriptionMTA,

      adjustRefund,
      adjustSubscription,
      applyVoucherCode,
      getPolicyDiscount,
      getPolicyAddons,
      getPolicyBundle,
      getPolicyRisk,
      getPolicyProduct,
      getPolicyScheme,
      getPolicies,
      getCustomConfig,
      getPolicyPaymentMethod,
      updatePolicyPaymentMethod,
      generateQuickQuote,
      generateQuickQuoteFromPartialRisk,
      setAutoRenewalStatus,
      editQuote,
      getRenewalSettings,
      getPaymentToken,
      clearPaymentToken,
      beginBasicAuthentication
    };
  };
