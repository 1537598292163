import * as React from 'react';
import {
  InsuranceProductConfiguration,
  OrganisationConfiguration
} from '@aventus/configuration';
import {
  Quote,
  QuoteEmbargo,
  PricingSet,
  Risk,
  QuoteProduct,
  MtaInformation,
  PaymentPlanType,
} from '@aventus/platform';
import {
  BladeView,
  BladeSurface,
  BladeMarkdown,
  BladeActionList,
  BladeButton,
  BladeMessage,
  BladeProgressBar
} from '@aventus/blade';
import {
  usePaymentPlan,
  PaymentPlanSelector,
  PriceDisplay,
  getDisplayPrice
} from '@aventus/mvmt-pricing';
import { useEmbargoes } from '../../hooks/use-embargoes';
import { useApplicationInterface } from '@aventus/application-interface';
import { DetailsOfInsuree } from '../../components/details-of-insuree';
import { DetailsOfPolicy } from '../../components/details-of-policy';
import { DetailsOfCover } from '@aventus/mvmt-product';
import { useMediaQuery } from '@aventus/pockethooks/use-media-query';
import { IntelligentQuoteExpiredOrConverted } from '../intelligent-quote-expired-or-converted';
import { useDocumentTitle } from '@aventus/pockethooks/use-document-title';
import { useBeforeUnload } from '@aventus/pockethooks/use-before-unload';
import { useTrack, useTrackView } from '@aventus/application-tracking';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import css from './index.css';
import cx from 'classnames';
import { DetailsOfDrivers } from '../../components/details-of-drivers';
import { DetailsOfPremium } from '../../components/details-of-premium';
import { DetailsOfYourCover } from '../../components/details-of-your-cover';
import { IntelligentQuoteDecline } from '../intelligent-quote-decline';
import { IntelligentQuoteReferred } from '../intelligent-quote-referred';
import { CustomConfig } from '@aventus/platform';
import { ITobesPage } from '@aventus/platform-client-context/models/tobes';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { RouteComponentProps } from 'react-router-dom';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';

export const IntelligentQuoteSummary: React.FunctionComponent<
  IIntelligentQuoteSummary
> = props => {
  useTrackView('IntelligentQuoteSummary');
  useDocumentTitle(props.documentTitle);

  const { embargo } = useEmbargoes(props.quoteEmbargoes);

  const rerated: string = queryParamGet(
    props.location.search,
    'rerated'
  );

  const { designSystem } = useApplicationInterface();

  // Because navigation of the question pages is
  // virtual, and they are not actual browser-stacked pages,
  // we want to make sure to warn the user that the browser back
  // will not go back in the question pages.

  useBeforeUnload();

  const { matchesQuery } = useMediaQuery('(max-width: 500px)');

  const getEditQuoteRoute = (quoteId: string, showExpiry: boolean = true) =>
    `/quote/edit?quoteId=${quoteId}&showExpiry=${showExpiry}`;

  const getHybridEditRoute = (quoteId: string, productId: string) =>
    window.location !== window.parent.location
      ? `/products/${productId}/quote/Adjust/avt_qs_core?chrome=false&quoteId=${quoteId}`
      : `/products/${productId}/quote/Adjust/avt_qs_core?quoteId=${quoteId}`;
  const { track } = useTrack();

  const getBuyButtonText = () => {
    if (props.quote.quoteType === 'MTA') {
      return 'Confirm';
    }

    if (!props.productConfiguration.quote.summary.redirectToDocuments) {
      return (
        props.productConfiguration.quote.summary.buyCallToAction || 'Confirm'
      );
    }

    return 'Documents';
  };

  React.useEffect(() => {
    if (props.quote.quoteType === 'Renewal') {
      track({
        event: 'aventus.renewal.confirm',
        quoteId: props.quote.id,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference
      });
    }
  }, [props.quote.id, props.quote.quoteType]);

  React.useEffect(() => {
    track({
      event: 'aventus.quote.view',
      quoteId: props.quote.id,
      productReference: props.productConfiguration.productReference,
      productCoverReference: props.productConfiguration.productCoverReference,
      quoteType: props.quote.quoteType
    });
  }, []);

  React.useEffect(() => {
    track({
      event: 'aventus.quote.summary',
      quoteId: props.quote.id,
      productReference: props.productConfiguration.productReference,
      productCoverReference: props.productConfiguration.productCoverReference
    });
  }, []);

  React.useEffect(() => {
    if (props.quote.expired === true) {
      track({
        event: 'aventus.quote.expired',
        quoteId: props.quote.id,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference
      });
    }
  }, [props.quote.expired]);

  React.useEffect(() => {
    if (props.quote.converted === true) {
      track({
        event: 'aventus.quote.converted',
        quoteId: props.quote.id,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference
      });
    }
  }, [props.quote.converted]);

  const paymentPlan = usePaymentPlan(
    props.quotePricing,
    props.defaultPaymentPlanType,
    props.quote.paymentPlanReferenceID
  );

  // If this quote is a renewal, then we need to enforce
  // that the user is logged in, given renewal quotes are always associated
  // with a user. All other quote types can be viewed anonymously.

  if (!props.isAuthenticated() && props.quote.quoteType === 'Renewal') {
    if (props.goToLogin) {
      props.goToLogin();
    } else {
      props.goHome();
      // Could probably throw an authentication
      // error here.
    }
  }

  // TODO
  // Comment

  if (props.quote.referred === true) {
    return (
      <IntelligentQuoteReferred
        title={props.productConfiguration.quote?.referred?.title}
        subTitle={props.productConfiguration.quote?.referred?.subTitle}
        quote={props.quote}
        bodyContent={props.productConfiguration.quote?.referred?.bodyContent}
        supportLink={props.organisationConfiguration.links.support}
        acknowledgementLabel={
          props.productConfiguration.quote?.referred?.acknowledgementLabel
        }
        onAcknowledgement={props.goHome}
      />
    );
  }
  if (props.quote.declined === true) {
    return (
      <IntelligentQuoteDecline
        title={props.productConfiguration.quote.decline.unrecoverableTitle}
        declineWithNoReason={
          props.productConfiguration.quote.decline.declineWithNoReason
        }
        declineWithOneReason={
          props.productConfiguration.quote.decline.declineWithOneReason
        }
        declineWithMultipleReasons={
          props.productConfiguration.quote.decline.declineWithMultipleReasons
        }
        help={props.productConfiguration.quote.decline.help}
        supportLink={props.organisationConfiguration.links.support}
        reasons={props.quote?.declineReasons}
        onAcknowledgement={props.goHome}
      />
    );
  }

  if (props.quote.expired === true) {
    let expiryReason = props.productConfiguration.quote.expired.reason;
    if (props.quote.quoteType === 'NewBusiness' && props.productConfiguration.quote.expired.reasonNewBusiness){
      expiryReason = props.productConfiguration.quote.expired.reasonNewBusiness
    }
    else if (props.quote.quoteType === 'Renewal' && props.productConfiguration.quote.expired.reasonRenewal){
      expiryReason = props.productConfiguration.quote.expired.reasonRenewal
    }

    return (
      <IntelligentQuoteExpiredOrConverted
        title={props.productConfiguration.quote.expired.title}
        reason={expiryReason}
        help={props.productConfiguration.quote.expired.help}
        supportLink={props.organisationConfiguration.links.support}
        onAcknowledgement={() => {
          if (props.productConfiguration.quote.expired.canProceed) {
            props.customiseQuote(getEditQuoteRoute(props.quote.id, false));
          } else {
            props.goHome();
          }
        }}
      />
    );
  }

  // TODO
  // Comment

  if (props.quote.converted === true) {
    return (
      <IntelligentQuoteExpiredOrConverted
        title={props.productConfiguration.quote.converted.title}
        reason={props.productConfiguration.quote.converted.reason}
        help={props.productConfiguration.quote.converted.help}
        supportLink={props.organisationConfiguration.links.support}
        onAcknowledgement={props.goHome}
      />
    );
  }

  const progressBarPages: ITobesPage[] =
    JSON.parse(String(sessionStorage.getItem('question_pages'))) || [];

  const renderReRatedMessage = () => {
    const title = props.productConfiguration.quote.summary.reRateMessageTitle;
    let detail = props.productConfiguration.quote.summary.reRateMessageDetailNoUpdate;

    const previousEstimate = sessionStorage.getItem(`quote-${props.quote.id}-previousPrice`);
    const newProductVersion = sessionStorage.getItem(`quote-${props.quote.id}-newProductVersion`);
    
    if (previousEstimate && paymentPlan.selectedPricingPlan) {
      const newPrice = currencyToHumanReadable(getDisplayPrice(paymentPlan.selectedPricingPlan));
      if (previousEstimate !== newPrice) {
        detail = stringReplacer(props.productConfiguration.quote.summary.reRateMessageDetailPriceUpdated, { $$_updated_price: previousEstimate });
      }
    }

    if  (newProductVersion === 'true' && props.productConfiguration.quote.summary.reRateMessageDetailNewProductVersion) {
      detail += ' ' + props.productConfiguration.quote.summary.reRateMessageDetailNewProductVersion;
    }

    return (
      <BladeMessage
        isDismissable
        dismissLabel="Close"
        icon="faExclamation"
        variant="info"
        message={title}
        detail={detail}
      />
    )
  }

  return (
    <div className={css.scaffolding}>
      {props.quote.quoteType !== 'Renewal' &&
        props.customConfiguration &&
        props.customConfiguration.userInterface.progressBar &&
        props.customConfiguration.userInterface.progressBar.showProgressBar &&
        props.customConfiguration.userInterface.progressBar.showPaymentStep &&
        progressBarPages.length > 0 && (
          <BladeProgressBar
            pages={progressBarPages}
            currentStep={{ group: 'Payment', order: 999 }}
            showPaymentStep
            showQuickQuoteStep={
              props.customConfiguration.userInterface.progressBar
                .showQuickQuoteStep
            }
          />
        )}

      {(paymentPlan.selectedPricingPlan || props.adjustPlan) && (
        <PriceDisplay
          plan={paymentPlan.selectedPricingPlan}
          adjustPlan={props.adjustPlan}
          productConfiguration={props.productConfiguration}
          isUpdatingPrice={false}
          useStickyMode={true}
          quoteType={props.quote.quoteType}
        />
      )}

      <BladeView variant={'s'} className={css.summary}>
        {rerated != null && renderReRatedMessage()}

        {embargo && (
          <BladeMessage
            variant={'warning'}
            message={
              props.productConfiguration.quote.summary.embargoTitle ||
              'Important information about your quote'
            }
            detail={embargo.errorMessage}
          />
        )}

        {props.quote.quoteType === 'MTA' &&
          props.adjustPlan !== undefined &&
          props.adjustPlan.recurringPaymentChange !== null &&
          props.adjustPlan.recurringPaymentChange.isIndicative === true &&
          props.productConfiguration.checkout.adjustmentInformation
            .monthlyFinanceEstimateMessage && (
            <BladeMessage
              variant={'info'}
              message={'Important information about your quote'}
              detail={stringReplacer(
                props.productConfiguration.checkout.adjustmentInformation
                  .monthlyFinanceEstimateMessage,
                {
                  $$_change_in_monthly_price: currencyToHumanReadable(
                    props.adjustPlan.recurringPaymentChange.changeInMonthlyPrice
                  )
                }
              )}
            />
          )}

        {(props.quote.quoteType === 'NewBusiness' ||
          props.quote.quoteType === 'Renewal') && (
          <BladeSurface shouldRespectViewWidth={true}>
            <div className={css.summary_description}>
              {props.quote.quoteType === 'NewBusiness' && (
                <BladeMarkdown
                  markdown={
                    props.productConfiguration.quote.summary.descriptionForNew
                  }
                />
              )}

              {props.quote.quoteType === 'Renewal' &&
                paymentPlan.selectedPricingPlan?.paymentPlan.type ===
                  'FixedTermSinglePayment' && (
                  <BladeMarkdown
                    markdown={
                      props.productConfiguration.quote.summary
                        .descriptionForRenewAnnual
                    }
                  />
                )}

              {props.quote.quoteType === 'Renewal' &&
                paymentPlan.selectedPricingPlan?.paymentPlan.type ===
                  'AnnualMonthlySubscription' && (
                  <BladeMarkdown
                    markdown={
                      props.productConfiguration.quote.summary
                        .descriptionForRenewMonthly
                    }
                  />
                )}

              {props.quote.endorsements?.length > 0 && (
                <BladeMarkdown
                  markdown={
                    props.productConfiguration.quote.summary
                      ?.descriptionOfEndorsements
                  }
                />
              )}
            </div>
          </BladeSurface>
        )}

        {props.quotePricing &&
          props.productConfiguration.quote.summary.showPriceSelection && (
            <PaymentPlanSelector
              label={props.productConfiguration.pricing?.paymentPlanToggleLabel}
              description={
                props.productConfiguration.pricing.paymentPlanDescription
              }
              plans={props.quotePricing}
              plansConfiguration={
                props.productConfiguration.pricing.paymentPlans
              }
              selectedPaymentPlan={paymentPlan.selectedPricingPlan}
              setSelectedPricingPlan={paymentPlan.setSelectedPricingPlan}
            />
          )}
        {props.productConfiguration.quote.summary.showInsurerDetails && (
          <DetailsOfInsuree
            proposer={props.risk.proposer}
            shouldRespectViewWidth={true}
          />
        )}
        <DetailsOfPolicy
          productName={props.productConfiguration.product.label}
          quote={props.quote}
          risk={props.risk}
          isMotorQuote={
            props.productConfiguration.quote.summary.showMotorDetails
          }
          coverStartLabel={
            props.productConfiguration.quote.summary.coverStartLabel
          }
          coverEndLabel={props.productConfiguration.quote.summary.coverEndLabel}
          shouldRespectViewWidth={true}
        />
        {props.productConfiguration.quote.summary.showDriverDetails && (
          <DetailsOfDrivers
            proposer={props.risk.proposer}
            driverDetails={props.risk.vehicle.driverDetails}
            shouldRespectViewWidth={true}
          />
        )}
        {props.productConfiguration.quote.summary.showPremiumSummary &&
          props.quotePricing &&
          props.quote.quoteType !== 'MTA' && (
            <DetailsOfPremium
              quote={props.quote}
              pricingPlan={paymentPlan.selectedPricingPlan}
              productConfiguration={props.productConfiguration}
              shouldRespectViewWidth={true}
            />
          )}
        <DetailsOfCover
          product={props.product}
          coverConfiguration={props.productConfiguration.quote.summary.cover}
          shouldRespectViewWidth={true}
        />
        {props.productConfiguration.quote.summary.coverTypesMarkdown && (
          <DetailsOfYourCover
            title={
              props.productConfiguration.quote.summary.coverTypesMarkdownTitle
            }
            markdown={
              props.productConfiguration.quote.summary.coverTypesMarkdown
            }
          />
        )}
        {props.quote.quoteType !== 'MTA' &&
          !props.productConfiguration.quote.summary.redirectToDocuments && (
            <BladeActionList
              actions={[
                {
                  icon: 'faFile',
                  label:
                    props.productConfiguration.quote.summary
                      .viewDocumentsCallToAction || 'View your documents',
                  action: () =>
                    window.open(props.quote.documents[0].documentUrl, '_blank')
                }
              ]}
            />
          )}
        <BladeSurface shouldRespectViewWidth={true}>
          <div
            className={cx(css.summary_controls, {
              [css.summary_controls_withNoFrame]:
                designSystem.areBackgroundAndSurfaceEqual
            })}
          >
            {props.quote.quoteType !== 'MTA' &&
              props.quote.isExternal === false && (
                <BladeButton
                  basis={!matchesQuery && '35%'}
                  variant="secondary"
                  onClick={() => {
                    if (props.quote.quoteType === 'Renewal') {
                      props.customiseQuoteForRenew(props.quote.id);
                    } else {
                      let route = '';
                      if (
                        props.isHybrid === true &&
                        props.isHybrid !== undefined
                      ) {
                        route = getHybridEditRoute(
                          props.quote.id,
                          props.quote.productID.toString()
                        );
                      } else {
                        route = getEditQuoteRoute(props.quote.id);
                      }

                      props.customiseQuote(route);
                    }
                  }}
                >
                  {props.productConfiguration.quote.summary
                    .customiseCallToAction || 'Customise'}
                </BladeButton>
              )}

            {props.quote.isExternal === false && (
              <BladeButton
                basis={
                  (!matchesQuery && props.quote.quoteType !== 'MTA' && '65%') ||
                  (props.quote.quoteType === 'MTA' && 'initial')
                }
                isDisabled={embargo !== undefined}
                onClick={() => {
                  if (
                    props.quote.quoteType !== 'MTA' &&
                    props.productConfiguration.quote.summary.redirectToDocuments
                  ) {
                    props.goToDocuments('quote', props.quote.id || '');
                  } else if (props.quote.quoteType === 'MTA') {
                    props.goToCheckout(
                      props.quote.id,
                      paymentPlan.selectedPricingPlan?.paymentPlan.type
                    );
                  } else {
                    props.goToCheckout(
                      props.quote.id,
                      paymentPlan.selectedPricingPlan?.paymentPlan.type
                    );
                  }
                }}
              >
                {getBuyButtonText()}
              </BladeButton>
            )}
          </div>

          <BladeSurface isAside={true}>
            <div className={css.summary_disclaimer}>
              <BladeMarkdown
                markdown={stringReplacer(
                  props.productConfiguration.quote.summary.disclaimer,
                  {
                    $$MATCHURL_TERMS: '/terms-and-conditions',
                    $$MATCHURL_PRIVACYPOLICY: '/privacy-policy'
                  }
                )}
              />
            </div>
          </BladeSurface>
        </BladeSurface>
      </BladeView>
    </div>
  );
};

export interface IIntelligentQuoteSummary extends RouteComponentProps {
  isAuthenticated: () => boolean;
  documentTitle?: string;
  quoteEmbargoes?: QuoteEmbargo[];
  quote: Quote;
  quotePricing: PricingSet;
  defaultPaymentPlanType?: PaymentPlanType;
  adjustPlan?: MtaInformation;
  risk: Risk;
  product: QuoteProduct;
  productConfiguration: InsuranceProductConfiguration;
  organisationConfiguration: OrganisationConfiguration;
  customConfiguration?: CustomConfig;
  isHybrid?: boolean;
  goHome: () => void;
  goToDocuments: (type: string, id: string, quoteID?: string) => void;
  goToCheckout: (
    quoteId: string,
    defaultPaymentPlanType?: PaymentPlanType
  ) => void;
  goToLogin?: () => void;
  customiseQuote: (route: string) => void;
  customiseQuoteForRenew: (quoteId: string) => void;
}
