import { AxiosResponse } from 'axios';
import { GlassesRecoverableError } from '../errors/glasses-recoverable-error';

export const interceptResponse = {
  onFulfilled,
  onRejected
}

export function onFulfilled (response: AxiosResponse<any>) {
  return response;
}

export function onRejected () {
  throw new GlassesRecoverableError(
    "Something went wrong. Please try using the registration or vin lookup, or contact support."
  );
}
