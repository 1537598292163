export const outlook: string[] = [
	"outlook.com",
	"outlook.sa",
	"outlook.com.ar",
	"outlook.com.au",
	"outlook.at",
	"outlook.be",
	"outlook.com.br",
	"outlook.cz",
	"outlook.cl",
	"outlook.dk",
	"outlook.fr",
	"outlook.com.gr",
	"outlook.de",
	"outlook.hu",
	"outlook.in",
	"outlook.co.id",
	"outlook.ie",
	"outlook.co.il",
	"outlook.it",
	"outlook.jp",
	"outlook.kr",
	"outlook.lv",
	"outlook.my",
	"outlook.ph",
	"outlook.pt",
	"outlook.sg",
	"outlook.sk",
	"outlook.es",
	"outlook.co.th",
	"outlook.com.tr",
	"outlook.com.vn",
]

export default outlook
