import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { PolicyCancellationInfo } from '@aventus/platform';
import { PolicyCancelDescriptionsCancellationTypesConfiguration } from '@aventus/configuration';

export const getPolicyCancellationInformation = (
  policyCancellationInfo: PolicyCancellationInfo,
  cancellations: PolicyCancelDescriptionsCancellationTypesConfiguration | undefined,
  toHumanReadable: (...args: any) => any
) => {

  const refundAmountConverted: string | boolean = policyCancellationInfo.refundAmount
    ? currencyToHumanReadable({
        value: policyCancellationInfo.refundAmount.value,
        currencyCode: policyCancellationInfo.refundAmount.currencyCode
      })
    : '';

  const cancellationEffectiveDate: string = toHumanReadable(
    policyCancellationInfo.effectiveCancellationDate || ''
  );

  switch (policyCancellationInfo.refundReason) {

    case 'ClaimsRaised':
      return cancellations?.claimRaised;

    case 'WithinStatutoryPeriod':
      return policyCancellationInfo.refundAmount
        ? stringReplacer(cancellations?.withinStatutoryPeriodWithInfo, {
            $$_refund: refundAmountConverted,
            $$_cancel_date: cancellationEffectiveDate
          })
        : cancellations?.withinStatutoryPeriod;

    case 'OutsideStatutoryPeriod':

      if (
        policyCancellationInfo.refundAmount &&
        policyCancellationInfo.refundAmount.value === 0
      ) {
        return cancellations?.outsideStatutoryPeriodNoRefund;
      }

      return refundAmountConverted
        ? stringReplacer(cancellations?.outsideStatutoryPeriodWithInfo, {
            $$_refund: refundAmountConverted,
            $$_cancel_date: cancellationEffectiveDate
          })
        : cancellations?.outsideStatutoryPeriod;

    default:
      return null;

  }
};
