import * as React from 'react';

export const BladeRunnerContext = React.createContext<
  BladeRunnerConfiguration | undefined
>(undefined);

export interface BladeRunnerConfiguration {
  ask?: BladeAskVariant;
  inputs?: BladeInputVariant;
  layer?: BladeLayerVariant;
  trim?: BladeTrimVariant;
  header?: BladeHeaderVariant;
  footer?: BladeAppFooterVariant;
  space?: BladeSpaceVariant;
}
