import * as React from 'react';
import { IBladeFormFieldset } from '../index';
import { IBladeInputText } from '../../input/text';
import { MotorwebCarDetails } from './car-details';
import { MotorwebManualRegistration } from './manual-registration';
import css from './index.css';
import { isValidRegistration } from '@aventus/pocketknife/validators/is-valid-registration';

export const BladeFormFieldsetMotor: React.FunctionComponent<IBladeFormFieldsetMotor> = props => {
  const [enterCarDetails, setEnterCarDetails] = React.useState<boolean>(false);
  const [registrationFlag, setRegistrationFlag] = React.useState<boolean>(
    false
  );

  const hasMotorWebDetails =
    props.value && !!(props.value[props.identifierKey] && props.value.make);

  const hasFallbackCarDetails =
    props.value &&
    !!(props.identifierKeyFallback && props.value[props.identifierKeyFallback]);

  const validationState = props.validate?.({
    ...props.value,
    registrationFlag
  });

  React.useEffect(() => {
    if (props.error !== validationState) {
      props.onValidate?.(validationState);
    }
  });

  const renderManualEntry = (): React.ReactElement | void => {
    if (!isValidRegistration(props.value.registration, props.value.regOrVin)) {
      return (
        <MotorwebManualRegistration
          {...props}
          key={`manual_registation`}
          registrationFlag={registrationFlag}
          setRegistrationFlag={setRegistrationFlag}
          maxLength={props.registationMaxLength}
        ></MotorwebManualRegistration>
      );
    }
  };

  if (hasMotorWebDetails || hasFallbackCarDetails) {
    return (
      <div className={css.motorDetails}>
        <MotorwebCarDetails
          make={props.value.make}
          model={props.value.model}
          bodyType={props.value.bodyType}
          year={props.value.year}
          variant={props.value.variant}
          series={props.value.series}
          engine={props.value.engineConfig}
          transmission={props.value.transmission}
          cc={props.value.cc}
          rego={props.value.registration?.trim() || props.value.regOrVin?.trim()}
          maxLength={props.registationMaxLength}
          registeredState={props.value.registeredState}
          clearDetails={() => {
            props.onChange({
              nvic: false,
              make: undefined,
              regOrVin: undefined,
              registration: undefined,
              registeredState: undefined
            });
          }}
        />
        {renderManualEntry()}
      </div>
    );
  } else {
    if (props.renderRegistrationOracle && !enterCarDetails) {
      // If the car details oracle is defined, then we can
      // setup the requried handlers for that fallback.
      // If we don't have this oracle though, it's important
      // we don't add this definition, so that the reg oracle
      // can decide not to render the option to trigger the
      // fallback to begin with.

      return React.cloneElement(props.renderRegistrationOracle, {
        useFallbackEntry: props.renderCarDetailsOracle
          ? () => setEnterCarDetails(true)
          : undefined,
        variant: props.variant
      });
    }
    if (props.renderCarDetailsOracle && enterCarDetails) {
      return React.cloneElement(props.renderCarDetailsOracle, {
        useLookupEntry: () => setEnterCarDetails(false),
        variant: props.variant
      });
    }

    return null;
  }
};

export interface IBladeFormFieldsetMotor
  extends IBladeFormFieldset,
    IBladeInputText {
  identifierKey: string;
  identifierKeyFallback?: string;
  renderRegistrationOracle?: React.ReactElement | null;
  renderCarDetailsOracle?: React.ReactElement | null;
  renderManualEntry?: React.ReactElement | null;
  registationMaxLength: number;
}
