import trackEvent from './_track-event';

export default function trackAction(type, name, properties) {
  return (dispatch, getState) => {
    const state = getState();
    const config = state.app.core.config.env;
    const partnerId = state.widgets.partnerId || null;
    trackEvent(type, name, properties, config, partnerId);
  };
}
