import * as React from 'react';
import { AddressInternationalManual } from './manual';
import { AddressInternationalDisplay } from './display';
import { IBladeFormFieldset } from '../';
import { IBladeInputText } from '../../input/text';
import { ITobesQuestion } from '@aventus/platform-client-context/models';

export const BladeFormFieldsetAddressInternationalV7: React.FunctionComponent<
  IBladeFormFieldsetAddressInternationalV7
> = props => {
  // We can rely on the postalCode
  // to see if we've got an address defined already or not,
  // since it's a required property of the address object.

  // This flag will allow us to figure out which entry
  // to show for the user. This flag forces the manual address
  // entry.

  const [forceEnterManually, setForceEnterManually] =
    React.useState<boolean>(false);

  let _value: { [key: string]: any } = props.value || {};

  if (props.value && Array.isArray(props.value)) {
    props.value.forEach(
      (field: ITobesQuestion) =>
        (_value[field.referenceID.replace('q_', '')] = field.existingValue)
    );
  }

  const isAlreadyDefined: boolean =
    _value?.line1 || props.value?.line1 ? true : false;

  const [autoFocus, setAutoFocus] = React.useState<boolean>(false);

  // Since we are toggling between different entry options, we need
  // keep track of the address in store when the user switches, to allow
  // them to switch back, without losing the data and having to re-enter.

  const [cachedAddress, setCachedAddress] = React.useState<any>();

  const defaultValue = {
    line1: null,
    line2: null,
    line3: null,
    locality: null,
    postalCode: null,
    uniqueReference: null,
    country: props.selectedCountry,
    addressIds: null
  };

  if (props.renderOracle && isAlreadyDefined && !forceEnterManually) {
    return (
      <AddressInternationalDisplay
        {...props}
        address={_value}
        handler={() => setForceEnterManually(true)}
        showCountry={props.showCountry}
        helperText={props.renderOracle?.props.helperText}
      />
    );
  } else if (
    (props.renderOracle && isAlreadyDefined && !forceEnterManually) ||
    (props.renderOracle && forceEnterManually) ||
    !props.renderOracle
  ) {
    return (
      <AddressInternationalManual
        {...props}
        countryCode={props.selectedCountry}
        renderOracle={props.renderOracle}
        enterWithLookup={() => {
          setCachedAddress(_value);
          props.onChange(defaultValue);
          setAutoFocus(true);
          setForceEnterManually(false);
        }}
      />
    );
  } else {
    return React.cloneElement(props.renderOracle, {
      enterManually: () => {
        props.onChange(cachedAddress);
        setForceEnterManually(true);
      },
      autoFocus,
      onFocus: () => setAutoFocus(false),
      countryFilter: props.countryFilter,
      errorMessage: props.errorMessage
    });
  }
};

export interface IBladeFormFieldsetAddressInternationalV7
  extends IBladeFormFieldset,
    IBladeInputText {
  selectedCountry: any;
  showCountry?: boolean;
  renderOracle?: React.ReactElement<any> | null;
  countryFilter?: string[];
}
