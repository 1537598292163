import { useState } from 'react';
import { validators as _validators } from '../validators';

export function use(initialValue?: any, validators?: Array<string>): IFormio {
  const [value, setValue] = useState(initialValue);
  const [isTouched, setIsTouched] = useState(false);

  const initialValidationResult: string | boolean = validate(
    initialValue,
    validators
  );

  const [isValid, setIsValid] = useState(
    initialValidationResult === true ? true : false
  );

  const [validation, setValidation] = useState(
    typeof initialValidationResult === 'string' ? initialValidationResult : null
  );

  function onChange(changeEventOrValue: any) {
    if (!isTouched) {
      setIsTouched(true);
    }

    const updatedValue =
      changeEventOrValue &&
      changeEventOrValue.nativeEvent &&
      changeEventOrValue.nativeEvent instanceof Event
        ? changeEventOrValue.target.value
        : changeEventOrValue;

    setValue(updatedValue);

    const validationResult: string | boolean = validate(
      updatedValue,
      validators
    );

    if (validationResult === true) {
      setIsValid(true);
      setValidation(null);
    } else if (typeof validationResult === 'string') {
      setIsValid(false);
      setValidation(validationResult);
    }
  }

  function validate(value?: any, validators?: Array<string>): boolean | string {
    if (validators && validators.length > 0) {
      for (const validator of validators) {
        const _validator = validator.split(':');

        const _validatorFnReference = _validator[0];
        const _validatorDynamicValue = _validator[1];

        if (_validators[_validatorFnReference]) {
          const result =
            _validatorDynamicValue != null
              ? _validators[_validatorFnReference](
                  value,
                  _validatorDynamicValue
                )
              : _validators[_validatorFnReference](value);

          if (typeof result === 'string') {
            return result;
          } else {
            continue;
            // We do nothing in this case, to allow the iterator to cycle
            // through all the validator functions. If the value is valid against
            // all validator cases, then the return after this iterator is finished
            // will be called.
          }
        } else {
          throw new ReferenceError('Validator is not found.');
        }
      }
    }
    return true;
  }

  return {
    value,
    setValue: onChange,
    meta: {
      isTouched,
      isValid,
      validation
    },
    __type: 'formio'
  };
}

interface IFormio {
  value: any;
  setValue: (value: any) => void;
  meta: {
    isTouched: boolean;
    isValid: boolean;
    validation?: string | null;
  };
  __type: 'formio';
}

interface IForm {
  [key: string]: IFormio;
}

interface IFormioTree {
  [key: string]: IForm | IFormio;
}

export { IFormio, IForm, IFormioTree };
