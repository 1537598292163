import React from 'react';
import routes from '../../../routes';
import { Scaffold } from '@honey/consume-react-jss';
import {
  BladeButton,
  BladePage,
  BladeInputText,
  BladeInputPassword,
  BladeFormFieldset,
  BladeSurface
} from '@aventus/blade';

export default function renderResetPassword() {
  const { credentials, _flags } = this.props.app.auth;

  return (
    <BladePage title={routes.resetPasswordNewPassword.documentTitle}>
      <Scaffold orient={'col'} size={'large'}>
        <BladeSurface>
          <Scaffold grow={true} orient={'col'} size={'large'}>
            <BladeFormFieldset addonBottom="Choose a strong password to keep your information safe. Make sure you have at least one uppercase character, one lowercase, a digit and a symbol (like '%' or '$').">
              <BladeInputPassword
                name="password"
                label="Choose a password"
                error={credentials?._password?.error}
                value={this.state.password || ''}
                onChange={value => this.setState({ password: value })}
                onBlur={e => {
                  this.props.handleAuthValidateCredentials(
                    'password',
                    this.state.password
                  );
                }}
              />
            </BladeFormFieldset>

            <BladeButton
              isWorking={_flags.isResettingPasswordNewPassword}
              isDisabled={credentials._password && credentials._password.error}
              onClick={this.onPressResetPassword}
            >
              {'Reset Password'}
            </BladeButton>
          </Scaffold>
        </BladeSurface>
      </Scaffold>
    </BladePage>
  );
}
