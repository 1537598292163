import { IOrganisationSettings } from "@aventus/platform-client";
import usePlatform from "@aventus/platform-client-context/use-platform";
import React from "react";
import useAsyncEffect from "use-async-effect";

export function useGetOrganisationSettings(): UseGetOrganisationSettings
{
  const platform = usePlatform();
  const [organisationSettings, setOrganisationSettings] = React.useState<IOrganisationSettings | undefined>(undefined);
  const [orgSettingsErrored, setErrored] = React.useState<boolean>(false);
  useAsyncEffect(async() => {
    try{
      const _organisationSettings = await platform.configuration.organisation();
      setOrganisationSettings(_organisationSettings)
    }
    catch{
      setErrored(true)
    }

  }, [])

  return {
    organisationSettings,
    orgSettingsErrored
  }

}


export interface UseGetOrganisationSettings {
  organisationSettings: IOrganisationSettings | undefined;
  orgSettingsErrored: boolean
}
