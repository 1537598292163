import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Policy } from '@aventus/platform';

export const useGetPolicy: UseGetPolicy = function (policyId, observable) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [policy, setPolicy] = React.useState<Policy | undefined>(undefined);
  const [isGettingPolicy, setIsGettingPolicy] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {
      if (policyId) {
        setIsGettingPolicy(true);
        try {
          const _policy = await symphony.getPolicy(policyId);
          setPolicy(_policy);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicy(false);
      }
    })();
  }, [policyId, observable]);

  return {
    policy,
    isGettingPolicy
  };
};

export type UseGetPolicy = (
  policyId?: string,
  observable?: any
) => {
  policy: Policy | undefined;
  isGettingPolicy: boolean;
};
