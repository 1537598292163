import WebFont from 'webfontloader';

export const FONTS_TYPE:string = 'fonts';

export function fontsMapper(rest:any, h:IFonts):IFontsMap {

  if (h.source !== 'custom') {
    WebFont.load({
      [h.source]: {
        families: [`${h.family}${ h.styles ? ':' + h.styles : '' }`]
      }
    });
  }

  return {
    ...rest,
    ...{
      [ h.key ]: {
        family: `"${ h.family }", ${ h.category }`,
        fontName: h.family
      }
    }
  };
}

export interface IFonts {
  key:string,
  family:string,
  category:string,
  source:string,
  styles?:string
}

export interface IFontsMap {
  [key:string]:string
}
