import * as React from 'react';
import { QuoteEmbargo } from '@aventus/platform';

export const useEmbargoes = (embargoes: QuoteEmbargo[] | undefined) => {
  const [embargo, setEmbargo] = React.useState<QuoteEmbargo | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (Array.isArray(embargoes) && embargoes.length > 0) {
      const lastestEmbargo = embargoes.reduce(
        (a: QuoteEmbargo, b: QuoteEmbargo) => {
          return new Date(a.endTime) > new Date(b.endTime) ? a : b;
        }
      );
      setEmbargo(lastestEmbargo);
    }
  }, [embargoes]);

  return {
    embargo
  };
};

export interface UseAdjustmentInformation {
  embargo: QuoteEmbargo | undefined;
}
