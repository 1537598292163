import { useEffect } from 'react';

export const useScrollToTop: UseScrollToTop = (
  observable,
  condition = true,
  scrollBehaviour,
  onFinish
) => {

  useEffect(() => {
    if (condition) {

      try {

        // First try to use the new API
        // (https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo)

        window.scroll({
          top: 0,
          left: 0,
          behavior: scrollBehaviour || 'auto',
        });

      } catch (error) {

        // Otherwise we'll fallback to the
        // default supported by older browsers.

        window.scrollTo(0, 0);
      }

      onFinish?.();

    }
  }, [ ...observable ]);

}

export type UseScrollToTop = (
  observable: any[],
  condition?: boolean,
  scrollBehaviour?: ScrollBehavior,
  onFinish?: () => void
) => void;
