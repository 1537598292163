import { PricingPlan, Tax } from '@aventus/platform';
import { IListItem } from '@aventus/blade/list';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { PricingPaymentPlanConfiguration } from '@aventus/configuration';
import { findPaymentPlanConfiguration } from '@aventus/mvmt-pricing';

import {
  AddonConfiguration,
  taxRecalculationConfiguration
} from '@aventus/configuration/product';
import { findAddonConfiguration } from './find-addon-configuration';
import { STELLA_ROADSIDE_IDS } from '@aventus/client-helpers/stella';

export function getPremiumBreakdown(
  plansConfiguration: PricingPaymentPlanConfiguration[],
  addonConfigurations: AddonConfiguration[],
  selectedPricingPlan?: PricingPlan,
  taxRecalculationConfiguration?: taxRecalculationConfiguration,
  basePremiumLabel?: string
): IListItem[] {
  var premiumBreakdownList: IListItem[] = [];

  if (selectedPricingPlan?.paymentPlan.type !== undefined) {
    premiumBreakdownList.push({
      isBold: true,
      label: 'Payment frequency',
      value: findPaymentPlanConfiguration(
        selectedPricingPlan?.paymentPlan.type,
        plansConfiguration
      )?.label
    });
  }

  if (selectedPricingPlan) {
    const discountInfo = selectedPricingPlan.discountInfo;
    const voucherBasePremium =
      discountInfo && discountInfo.type === 'VoucherBasePremium'
        ? `(incl. ${currencyToHumanReadable(discountInfo.amount)} discount)`
        : '';

    const voucherNetPremiumAllComponents =
        discountInfo && discountInfo.type === 'VoucherNetPremium'
          ? `(incl. ${currencyToHumanReadable(discountInfo.amount)} discount)`
          : '';

    const basePremium = basePremiumLabel ? basePremiumLabel : `Base premium`; 

    premiumBreakdownList.push({
      hasBorder: true,
      label: `${basePremium} ${voucherBasePremium}`,
      value: currencyToHumanReadable(
        selectedPricingPlan?.primaryRateableComponentFinalPrices?.filter(
          re => re.referenceID === '_primary'
        )[0].price.net
      )
    });

    // The next few sections only output the non-standalone items, standalone items are listed
    // at the bottom before total.

    const includedComponents = selectedPricingPlan?.primaryRateableComponentFinalPrices.filter(
      ({ isStandaloneProduct }) => isStandaloneProduct === false
    );

    includedComponents.forEach(re => {
      if (re.referenceID === '_primary') return; // We've already called out _primary earlier
      const addonConfiguration = findAddonConfiguration(
        re.referenceID,
        addonConfigurations
      );
      const addonLabel = addonConfiguration?.receiptLabel
        ? addonConfiguration?.receiptLabel
        : addonConfiguration?.label;

      premiumBreakdownList.push({
        label: addonLabel,
        value: currencyToHumanReadable(re.price.net, { forceMinor: true })
      });
    });

    if (selectedPricingPlan?.paymentPlan.type === 'SinglePayment'){
      const includeComponentsNetSum = includedComponents
      .map(({ price }) => price.net.value)
      .reduce((acc: number, next: number) => acc + next, 0);

      premiumBreakdownList.push({
        isBold: true,
        hasBorder: true,
        label: `Premium ${voucherNetPremiumAllComponents}`,
        value: currencyToHumanReadable(
          {
            value: includeComponentsNetSum,
            currencyCode: selectedPricingPlan
              ?.primaryRateableComponentFinalPrices[0]
              ? selectedPricingPlan?.primaryRateableComponentFinalPrices[0].price
                  .net.currencyCode
              : 'GBP'
          },
          { forceMinor: true }
        )
      });
    }

    const includedComponentsAllTaxes = includedComponents.flatMap(
      x => x.price.tax.taxes
    );

    // Group the taxes by taxReferenceID
    const includedComponentsTaxesGrouped = Array.from<Tax[]>(
      includedComponentsAllTaxes
        .reduce(
          (m: Map<string, Tax[]>, o) =>
            m.set(o.taxReferenceID, (m.get(o.taxReferenceID) || []).concat(o)),
          new Map()
        )
        .values()
    );

    // Combine by keeping first obj properties and only adding amount.value each time
    const includedComponentsTaxTotals = includedComponentsTaxesGrouped.map(
      taxes => {
        return taxes.reduce((prev, curr) => {
          if (prev === undefined) return curr;
          const { amount, ...obj } = prev;
          const newAmount = Object.assign({}, amount, {
            value: amount.value + curr.amount.value
          });
          return { amount: newAmount, ...obj };
        });
      }
    );

    includedComponentsTaxTotals.forEach(tax => {
      if (
        (selectedPricingPlan?.paymentPlan.type === 'FixedTermSinglePayment' || selectedPricingPlan?.paymentPlan.type === 'SinglePayment') &&
        taxRecalculationConfiguration &&
        tax.taxReferenceID === taxRecalculationConfiguration.taxReferenceID &&
        selectedPricingPlan?.primaryRateableComponentFinalPrices.filter(
          prc =>
            prc.referenceID === taxRecalculationConfiguration.addonReferenceID
        ).length > 0
      ) {
        premiumBreakdownList.push({
          label: tax.name,
          value: currencyToHumanReadable(
            { currencyCode: tax.amount.currencyCode, value: tax.amount.value },
            { forceMinor: true }
          )
        });
      } else {
        premiumBreakdownList.push({
          label: tax.name,
          value: currencyToHumanReadable(tax.amount, { forceMinor: true })
        });
      }
    });

    if (selectedPricingPlan?.paymentPlan.type !== 'SinglePayment')
    {

      const includeComponentsGrossSum = includedComponents
        .map(({ price }) => price.gross.value)
        .reduce((acc: number, next: number) => acc + next, 0);

      premiumBreakdownList.push({
        isBold: true,
        hasBorder: true,
        label: 'Annual Premium',
        value: currencyToHumanReadable(
          {
            value: includeComponentsGrossSum,
            currencyCode: selectedPricingPlan
              ?.primaryRateableComponentFinalPrices[0]
              ? selectedPricingPlan?.primaryRateableComponentFinalPrices[0].price
                  .gross.currencyCode
              : 'GBP'
          },
          { forceMinor: true }
        )
      });
    }

    // List the standalone items

    selectedPricingPlan?.primaryRateableComponentFinalPrices.forEach(re => {
      if (re.referenceID !== '_primary' && re.isStandaloneProduct === true) {
        const addonConfiguration = findAddonConfiguration(
          re.referenceID,
          addonConfigurations
        );

        const addonLabel =
          addonConfiguration?.receiptLabel ?? addonConfiguration?.label;

        // This could be moved into config as an optional
        // display toggle later on when price is 0.
        const addonValueDisplayFree =
          STELLA_ROADSIDE_IDS.includes(re.referenceID) &&
          re.price.gross.value === 0;

        premiumBreakdownList.push({
          label: addonLabel,
          value: addonValueDisplayFree
            ? 'FREE'
            : currencyToHumanReadable(re.price.gross, { forceMinor: true })
        });
      }
    });

    selectedPricingPlan?.fees.forEach(fee => {
      premiumBreakdownList.push({
        label: fee.name,
        value: currencyToHumanReadable(fee.amount, { forceMinor: true })
      });
    });

    const totalCostLabel = selectedPricingPlan?.paymentPlan.type === 'SinglePayment' ? 'Total Cost' : 'Total annual cost';

    premiumBreakdownList.push({
      hasBorder: true,
      label: totalCostLabel,
      value: currencyToHumanReadable(selectedPricingPlan?.totalPayable.gross, {
        forceMinor: true
      }),
      isBold: true
    });
  }
  if ((selectedPricingPlan?.paymentPlan.type !== 'FixedTermSinglePayment' && selectedPricingPlan?.paymentPlan.type !== 'SinglePayment')) {
    if (
      selectedPricingPlan?.upfrontPrice.value !==
      selectedPricingPlan?.monthlyPrice.value
    ) {
      premiumBreakdownList.push({
        hasBorder: true,
        label: `First payment *`,
        value: selectedPricingPlan?.upfrontPrice
          ? currencyToHumanReadable(selectedPricingPlan?.upfrontPrice, {
              forceMinor: true
            })
          : '',
        isBold: true
      });
      premiumBreakdownList.push({
        hasBorder: true,
        label: `Remaining premium instalments`,
        value: selectedPricingPlan?.monthlyPrice
          ? currencyToHumanReadable(selectedPricingPlan?.monthlyPrice, {
              forceMinor: true
            })
          : '',
        isBold: true
      });
    } else {
      premiumBreakdownList.push({
        label: `Monthly premium instalments`,
        value: selectedPricingPlan?.monthlyPrice
          ? currencyToHumanReadable(selectedPricingPlan?.monthlyPrice, {
              forceMinor: true
            })
          : '',
        isBold: true
      });
    }
  }

  return premiumBreakdownList;
}
