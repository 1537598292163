import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetOrganisationConfiguration, useGetProductConfiguration } from '@aventus/configuration-client-context';
import { useGetPolicyBundle } from '@aventus/symphony-client/hooks/use-get-policy-bundle';
import { useGetQuoteRenewal } from '@aventus/symphony-client/hooks/use-get-quote-renewal';
import { useGetQuoteProduct } from '@aventus/symphony-client/hooks/use-get-quote-product';
import { MvmtPolicyRenew } from '@aventus/mvmt-policy';

export const PolicyRenew: React.FC<IPolicyRenew> = props => {
  const policyId = props.match.params.policyId.trim();

  const { policyBundle } = useGetPolicyBundle(policyId);

  const { productConfiguration } = useGetProductConfiguration(
    policyBundle?.policy?.productReferenceID,
    policyBundle?.policy?.coverType
  );

  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const { quoteBundle } = useGetQuoteRenewal(
    policyBundle?.renewal?.activeRenewalQuoteID
  );

  const { quoteProduct } = useGetQuoteProduct(quoteBundle?.requestedQuote.id);

  return (
    <MvmtPolicyRenew
      policyId={policyId}
      policyBundle={policyBundle}
      productConfiguration={productConfiguration}
      organsationConfiguration={organisationConfiguration}
      quoteBundle={quoteBundle}
      quoteProduct={quoteProduct}
      goHome={props.goHome}
      goToOrigin={props.goToOrigin}
      onCheckoutRenewal={() => {
        quoteBundle?.requestedQuote.id &&
          props.history.push(
            `/checkout/${quoteBundle.requestedQuote.id}?plan=${policyBundle?.totalPaid.paymentPlanType}&originalPolicyId=${policyId}`
          );
      }}
      onEditRenewal={() => {
        policyBundle?.renewal?.activeRenewalQuoteID &&
          props.history.push(
            `/quote/renew?quoteId=${quoteBundle?.requestedQuote.id}&policyId=${policyId}`
          );
      }}
      onViewDocuments={() => {
        quoteBundle?.requestedQuote.documents[0].documentUrl &&
          window.open(
            quoteBundle.requestedQuote.documents[0].documentUrl,
            '_blank'
          );
      }}
      onViewConvertedPolicy={() => {
        policyBundle?.renewal?.renewedPolicyID &&
          props.history.push(`/policy/${policyBundle.renewal.renewedPolicyID}`);
      }}
      goBackToPolicy={()=>{
        props.history.push(`/policy/${policyId}`);
      }}
    />
  );
};

interface IPolicyRenew
  extends RouteComponentProps<{
    policyId: string;
  }> {
  goHome: () => void;
  goToOrigin: () => void;
}
