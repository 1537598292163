import * as React from 'react';
import { ApplicationInterface, ApplicationInterfaceContext } from './context';

export function useApplicationInterface(): ApplicationInterface {
  const client = React.useContext(ApplicationInterfaceContext);
  if (client === undefined) {
    throw new Error(
      'useApplicationInterface must be used within a ApplicationInterfaceContext.Provider'
    );
  }
  return client;
}
