export const yahoo: string[] = [
	"yahoo.com",
	"yahoo.fr",
	"yahoo.cl",
	"yahoo.in",
	"yahoo.nl",
	"yahoo.no",
	"yahoo.pl",
	"yahoo.ro",
	"yahoo.es",
	"yahoo.it",
	"yahoo.de",
	"yahoo.ca",
	"yahoo.co.uk",
	"yahoo.co.in",
	"yahoo.co.jp",
	"yahoo.co.id",
	"yahoo.co.th",
	"yahoo.com.co",
	"yahoo.com.my",
	"yahoo.com.tr",
	"yahoo.com.ve",
	"yahoo.com.vn",
	"yahoo.com.br",
	"yahoo.com.au",
	"yahoo.com.ar",
	"yahoo.com.mx",
	"yahoo.com.sg",
	"yahoo.at",
	"yahoo.be",
	"yahoo.bg",
	"yahoo.cn",
	"yahoo.co.hu",
	"yahoo.co.il",
	"yahoo.co.kr",
	"yahoo.co.nz",
	"yahoo.co.za",
	"yahoo.com.cn",
	"yahoo.com.hk",
	"yahoo.com.hr",
	"yahoo.com.pe",
	"yahoo.com.ph",
	"yahoo.com.tw",
	"yahoo.com.ua",
	"yahoo.cz",
	"yahoo.dk",
	"yahoo.ee",
	"yahoo.fi",
	"yahoo.gr",
	"yahoo.hu",
	"yahoo.ie",
	"yahoo.lt",
	"yahoo.lv",
	"yahoo.ne.jp",
	"yahoo.pt",
	"yahoo.rs",
	"yahoo.se",
	"yahoo.si",
	"yahoo.sk",
	"yahoogroups.ca",
	"yahoogroups.co.in",
	"yahoogroups.co.uk",
	"yahoogroups.com",
	"yahoogroups.com.au",
	"yahoogroups.com.hk",
	"yahoogroups.com.sg",
	"yahoogroups.de",
	"yahooxtra.co.nz",
]

export default yahoo
