import { AUTH_UNBLOCK_USER_REQUEST, AUTH_UNBLOCK_USER_SUCCESS, AUTH_UNBLOCK_USER_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';

export const unblockUserAction = (requestid) => {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/unblock',
        method: 'POST',
        data: { requestid }
      },
      actions: [ unblockUserRequestAction, unblockUserSuccessAction, unblockUserFailureAction ]
    }
  }
}

const unblockUserRequestAction = () => {
  return { type: AUTH_UNBLOCK_USER_REQUEST }
}

const unblockUserSuccessAction = (res) => {
  return (dispatch) => {
    dispatch({ type: AUTH_UNBLOCK_USER_SUCCESS, res });
    dispatch(queueAlertAction({
      id: 'UNBLOCK_USER_SUCCESS_ALERT',
      type: 'success',
      message: 'Your account has been unblocked. Please sign in to continue.',
      location: false
    }))
  }
}

const unblockUserFailureAction = () => {
  return (dispatch) => {
    dispatch({ type: AUTH_UNBLOCK_USER_FAILURE });
    dispatch(queueAlertAction({
      id: 'UNBLOCK_USER_ERROR_ALERT',
      type: 'error',
      message: 'This link has already been used or expired.',
      location: false
    }))
  }
}