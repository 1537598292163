export function buildObjectFromDotAddress (objectAddress: string, depthPointer: number, value?: object): object {

  // The expected object address is a.b.c.d,
  // with each string in between the dots represent
  // a node in the object tree.

  const nodes = objectAddress.split('.');

  if (nodes.length === (depthPointer + 1)) {

    // This function is recursively called until we reach
    // the last node of the object. If we're there, then we can give
    // it a value and return everything.

    return {
      [nodes[depthPointer]]: value || {}
    };

  } else {

    // If we're still somewhere in the chain of nodes, then
    // we'll pass on the immediate child node.

    return {
      [nodes[depthPointer]]: (
        buildObjectFromDotAddress(objectAddress, depthPointer + 1, value)
      )
    };

  }
}
