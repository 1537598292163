import React from 'react';
import appApi from '../../../api';
import routes from '../../../routes';
import _render from './render';

class ResetPasswordNewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: ''
    };
    this.onPressResetPassword = this.onPressResetPassword.bind(this);
    this.render = _render.bind(this);
  }

  componentDidMount() {
    this.props.handleAuthClearAllFlags();
    this.props.handleTracking('newScreen', 'Screen_name', {
      Screen_name: routes.resetPasswordNewPassword.trackingName
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const authProps = this.props.app.auth;
    const nextAuthProps = nextProps.app.auth;
    if (
      !authProps._flags.successfullyResetPasswordNewPassword &&
      nextAuthProps._flags.successfullyResetPasswordNewPassword
    ) {
      this.props.handleQueueAlert({
        message: 'Your password was reset',
        location: routes.signIn.path,
        type: 'success'
      });
      this.props.history.push(routes.signIn.path, { view: 'password' });
    }
  }

  onPressResetPassword() {
    const { password } = this.state;
    const { _password } = this.props.app.auth.credentials;
    const { resetToken } = this.props.match.params;

    if (!_password) {
      this.props.handleAuthResetPasswordNewPassword(password, resetToken);
    } else {
      this.props.handleAuthValidateCredentials('password', password);
    }
  }
}

export default appApi(ResetPasswordNewPassword);
