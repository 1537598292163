import { isEnumMember } from '@aventus/pocketknife/typeguards';

export enum MaxEndDateRules {
  MaxDate = 'MaxDate',
  MaxRange = 'MaxRange'
}

export const parseValidationRules = (validation: Array<string>) => {
  var parsedRules = validation.reduce<{ [index: string]: string }>(
    (output, rule) => {
      var match = rule.match(/^([^:]*):(.*)$/);

      if (match) {
        return { ...output, [match[1]]: match[2] };
      }

      return output;
    },
    {}
  );

  return {
    minDate: dateOrNull(parsedRules['min']),
    maxDate: dateOrNull(parsedRules['max']),
    maxDaysRange: intOrNull(parsedRules['maxDateRange']),
    minDaysRange: intOrNull(parsedRules['minDateRange']),
    maxEndDateRule: isEnumMember(MaxEndDateRules, parsedRules['maxEndDateRule'])
      ? parsedRules['maxEndDateRule']
      : MaxEndDateRules.MaxDate
  };
};

const dateOrNull = (candidate: string) =>
  candidate ? new Date(candidate) : null;

const intOrNull = (candidate: string) =>
  candidate ? parseInt(candidate) : null;
