import * as React from 'react';
import cx from 'classnames';
import css from './index.css';

export const BNPNavigation: React.FunctionComponent<IBNPNavigation> = props => {
  return (
    <nav className={css.bnpNavigation}>
      {props.navigation.map((elem, i) => (
        <span
          key={i}
          className={cx(css.bnpNavigationItem, {
            [css.bnpNavigationActive]: props.currentIndex === elem.index,
            [css.bnpNavigationComplete]: props.currentIndex > elem.index
          })}
        >
          {elem.title}
        </span>
      ))}
    </nav>
  );
};

export interface IBNPNavigation {
  navigation: { title: string; index: number }[];
  currentIndex: number;
}
