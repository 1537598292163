import * as React from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import { IBladeInputRangeSlider } from './types';
import css from './index.css';
import { BladeLink, BladeMarkdown } from '@aventus/blade';
import { currencyToHumanReadable } from '@aventus/pocketknife';

const { Handle } = Slider;

export const BladeInputRangeSlider: React.FunctionComponent<IBladeInputRangeSlider> = props => {
  const [sliderValue, setSliderValue] = React.useState<number>(
    props.defaultValue
  );
  const currencyCode = props.currencyCode;
  const middleValue = props.min + (props.max - props.min) / 2;

  function reset() {
    setSliderValue(props.defaultValue);
    props.onChange({ currencyCode: currencyCode, value: props.defaultValue });
  }

  React.useEffect(() => {
    // If we have a value on MTA lets set it to that
    if(props.previousValue){
      setSliderValue(props.previousValue.value)
      props.onChange({currencyCode: props.previousValue.currencyCode, value: props.previousValue.value})
    }
      else{
      props.onChange({currencyCode: currencyCode, value: props.defaultValue})
    }
  /*
    We currently disable this rule as this incorrectly asks us to pass in the entire
    props object which causes infinite re-renders
    https://stackoverflow.com/questions/56599583/useeffect-hook-example-what-causes-the-re-render
  */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handle = (props: any) => {
    const { value, index, ...restProps } = props;

    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={currencyToHumanReadable({
          value: value,
          currencyCode: currencyCode
        })}
        visible={true}
        getTooltipContainer={(node: HTMLElement): HTMLElement => {
          return node;
        }}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const marks = {
    [props.min]: currencyToHumanReadable({
      value: props.min,
      currencyCode: currencyCode
    }),
    [middleValue]: currencyToHumanReadable({
      value: middleValue,
      currencyCode: currencyCode
    }),
    [props.max]: currencyToHumanReadable({
      value: props.max,
      currencyCode: currencyCode
    })
  };

  return (
    <>
      <div className={css.slider}>
        <Slider
          marks={marks}
          min={props.min}
          max={props.max}
          step={props.interval}
          defaultValue={props.defaultValue}
          value={sliderValue}
          onChange={setSliderValue}
          onAfterChange={(value: unknown) => {
            props.onChange({ currencyCode: currencyCode, value: value });
          }}
          handle={handle}
        />
      </div>
      <div className={css.resetLink}>
        <BladeLink onClick={reset}>Reset</BladeLink>
      </div>

      {props.footerText && (
        <BladeMarkdown className={css.footerText} markdown={props.footerText} />
      )}
    </>
  );
};
