import * as React from 'react';
import { AddressManual } from './manual';
import { IBladeFormFieldset } from '../';
import { IBladeInputText } from '../../input/text';
import { IBladeInputSelect } from '../../input/select';

export const BladeFormFieldsetAddress: React.FunctionComponent<IBladeFormFieldsetAddress> = props => {
  // We can rely on the postalCode
  // to see if we've got an address defined already or not,
  // since it's a required property of the address object.

  const isAlreadyDefined: boolean = props.value?.postalCode ? true : false;

  const [forceEnterManually, setForceEnterManually] = React.useState<boolean>(
    false
  );

  if (
    (props.renderOracle && forceEnterManually) ||
    (props.renderOracle && isAlreadyDefined) ||
    !props.renderOracle
  ) {
    return (
      <AddressManual
        {...props}
        enterWithLookup={() => {
          props.onChange({
            address1: null,
            address2: null,
            town: null,
            postalCode: null,
            country: null,
            udprn: null
          });
          setForceEnterManually(false);
        }}
      />
    );
  } else {
    return React.cloneElement(props.renderOracle, {
      enterManually: () => setForceEnterManually(true)
    });
  }
};

export interface IBladeFormFieldsetAddress
  extends IBladeFormFieldset,
    IBladeInputText,
    IBladeInputSelect {
  renderOracle?: React.ReactElement<any> | null;
}
