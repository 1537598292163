import * as React from 'react';
import { useTrack } from './use-track';

export const useTrackView: UseTrackView = (theView, theRest = {}) => {
  const { trackView } = useTrack();

  React.useEffect(() => {
    trackView(theView, theRest);
  }, []);
};

export type UseTrackView = (theView: string, theRest?: object) => void;
