import update from 'immutability-helper';

export default function updateMidAuthFlowStateReducer(state, action) {
  const { newState } = action;

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        midAuthFlow: { $set: newState }
      })
    }
  }
}
