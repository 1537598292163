import update from 'immutability-helper';
import initialState from './initial-state';

// Cherry pick what we want to keep in state from the app module
// if the user signs out.
// Everything else will use initialState like when you open the app
// for the first time.

export default function signOutStatePartial(state) {

  const { core } = state.app;
  return {
    ...initialState,
    core: update(initialState.core, {
      isAppReady: { $set: core.isAppReady },
      stateVersion: { $set: core.stateVersion },
      privacyPolicyVersion: { $set: core.privacyPolicyVersion },
      showCookiePolicy: { $set: core.showCookiePolicy },
      showWelcomeScreen: { $set: core.showWelcomeScreen },
      activeLinesOfBusiness: { $set: core.activeLinesOfBusiness },
      config: { $set: core.config },
      customConfig: { $set: core.customConfig },
      tracking: {
        externalId: { $set: core.tracking.externalId }
      }
    })
  };
}
