import { IDesignSystem, IAtoms, IAtom, IMolecule, IMolecules } from '@aventus/honey-definitions';

export function interpolateValues (system: IDesignSystem): IDesignSystem {

  // Gather all the molecules' atom collections
  // into one single collection.

  const moleculeDefinedAtoms: IAtoms = (
    system.molecules
      ? (
        system.molecules.reduce(
          (acc: Array<IAtom>, molecule: IMolecule) => {
            acc = [ ...acc, ...molecule.atoms ];
            return acc;
          },
          []
        )
      )
    : []
  );

  // Merge the molecules' atom combined collection
  // with the system atoms into a single collection.

  const systemAtoms: IAtoms = [
    ...moleculeDefinedAtoms,
    ...system.atoms ? system.atoms : []
  ];

  // Turn the collections into an object
  // map.

  const systemAtomsMap: IAtomMap = (
    systemAtoms.reduce(
      (acc: IAtomMap, atom: IAtom) => {
        return {
          ...acc,
          ...{ [atom.key]: atom.value }
        }
      },
      {}
    )
  );

  // Do another pass over the system's atoms and
  // molecules atoms and replace any value that are references
  // with the referenced value.

  const interpolatedSystemAtoms: IAtoms = (
    system.atoms
      ? (
        system.atoms.map(
          (atom: IAtom) => {
            if (systemAtomsMap[atom.value]) {
              return { ...atom, ...{ value: systemAtomsMap[atom.value] } };
            } else {
              return atom;
            }
          }
        )
      )
    : []
  );

  const interpolatedSystemMolecules: IMolecules = (
    system.molecules
      ? (
        system.molecules.map(
          (molecule: IMolecule) => {
            return {
              ...molecule,
              ...{
                atoms: molecule.atoms.map(
                  (atom: IAtom) => {
                    if (systemAtomsMap[atom.value]) {
                      return { ...atom, ...{ value: systemAtomsMap[atom.value] } };
                    } else {
                      return atom;
                    }
                  }
                )
              }
            };
          }
        )
      )
      : []
  )

  return {
    ...system,
    atoms: interpolatedSystemAtoms,
    molecules: interpolatedSystemMolecules
  };
}

interface IAtomMap {
  [key: string]: string
}
