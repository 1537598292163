import * as React from 'react';
import { ISimpleQuoteBoundary, SimpleQuoteBoundary } from './boundary';
import QuickQuoteSummary, {
  QuickQuoteSummaryRouteProps
} from './views/quick-quote-summary';
import { SimpleQuote, ISimpleQuote } from './views/simplequote';
import {
  ISimpleQuoteConsole,
  SimpleQuoteConsole
} from './views/simplequote/simple-quote-console';
import {
  SimpleQuoteSF,
  ISimpleQuoteSF
} from './views/simplequote/simple-quote-sf';
import { IVariantSummary, VariantSummary } from './views/variant-summary';

export const MvmtSimpleQuote = (props: ISimpleQuoteBoundary & ISimpleQuote) => (
  <SimpleQuoteBoundary>
    <SimpleQuote {...props} />
  </SimpleQuoteBoundary>
);

export const MvmtSimpleQuoteSf = (
  props: ISimpleQuoteBoundary & ISimpleQuoteSF
) => (
  <SimpleQuoteBoundary>
    <SimpleQuoteSF {...props} />
  </SimpleQuoteBoundary>
);

export const MvmtSimpleQuoteConsole = (
  props: ISimpleQuoteBoundary & ISimpleQuoteConsole
) => (
  <SimpleQuoteBoundary>
    <SimpleQuoteConsole {...props} />
  </SimpleQuoteBoundary>
);

export const MvmtSimpleQuoteVariantSummary = (
  props: ISimpleQuoteBoundary & IVariantSummary
) => (
  <SimpleQuoteBoundary>
    <VariantSummary {...props} />
  </SimpleQuoteBoundary>
);

export const MvmtSimpleQuoteQuickQuoteSummary = (
  props: QuickQuoteSummaryRouteProps
) => (
  <SimpleQuoteBoundary>
    <QuickQuoteSummary {...props} />
  </SimpleQuoteBoundary>
);
