import React from 'react';
import { ITobesPage } from '@aventus/platform-client-context/models/tobes';
import css from './index.css';
import cx from 'classnames';
import slugify from 'slugify';
import { BladeView } from '@aventus/blade';
import { BladeViewVariant } from '../view';
import { CSSTransition } from 'react-transition-group';

const BladeProgressBar = ({
  pages = [],
  showQuickQuoteStep = false,
  showPaymentStep = false,
  currentStep,
  viewVariant = 'l'
}: IBladeProgressBarProps) => {
  const currentStepKey =
    currentStep && currentStep.group ? slugify(currentStep.group) : undefined;

  const reducedSteps = pages.reduce((a: ProgressStep[], c: ITobesPage) => {
    if (!c.group) {
      return a;
    }

    const groupSlug = slugify(c.group);

    if (a.find(step => slugify(step.name) === groupSlug)) {
      return a;
    }

    return [
      ...a,
      {
        order: c.number,
        name: c.group,
        key: groupSlug
      }
    ];
  }, []);

  // At least one external step is required
  // to display progress.
  if (reducedSteps.length === 0) {
    return null;
  }

  // The two togglable values could
  // come from config entirely.
  const steps = [
    ...(showQuickQuoteStep
      ? [
          {
            order: 0,
            name: 'Quick Quote',
            key: 'quick-quote'
          }
        ]
      : []),
    ...reducedSteps,
    ...(showPaymentStep
      ? [
          {
            order: 999,
            name: 'Payment',
            key: 'Payment'
          }
        ]
      : [])
  ];

  return (
    <div className={css.progressSurface}>
      <BladeView variant={viewVariant}>
        <CSSTransition classNames={'progress-bar'} in appear timeout={400}>
          <div className={css.progressBarWrapper} key={'progress-bar'}>
            <ul className={css.progressBar}>
              {steps
                .sort((a, b) => a.order - b.order)
                .map(step => (
                  <li
                    key={step.key}
                    className={cx(css.progressStep, {
                      [css.progressStepActive]: currentStepKey === step.key,
                      [css.progressStepComplete]: currentStep
                        ? currentStep.order > step.order &&
                          currentStepKey !== step.key
                        : false
                    })}
                  >
                    <span className={css.progressStepName}>{step.name}</span>
                  </li>
                ))}
            </ul>
            <div className={css.progressLabel}>{currentStep?.group}</div>
          </div>
        </CSSTransition>
      </BladeView>
    </div>
  );
};

type ProgressStep = {
  order: number;
  name: string;
  key: string;
};

export interface IBladeProgressBarProps {
  pages?: ITobesPage[];
  currentStep?: { group: string; order: number };
  showPaymentStep?: boolean;
  showQuickQuoteStep?: boolean;
  viewVariant?: BladeViewVariant;
}

export default BladeProgressBar;
