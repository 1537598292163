import React, { createElement } from 'react';
import marksy from 'marksy';
import { BladeMessage, BladeButton, BladeLink } from '../index';

import { ShimUl } from './shims/ul';
import { ShimOl } from './shims/ol';

export default function compiler(options) {
  const { size, action, style } = options;

  return marksy({
    createElement,
    elements: {
      h1({ children }) {
        return <h1>{children}</h1>;
      },

      h2({ children }) {
        return <h2>{children}</h2>;
      },

      h6({ children }) {
        return <small>{children}</small>;
      },

      strong({ children }) {
        return <strong>{children}</strong>;
      },

      em({ children }) {
        return <em>{children}</em>;
      },

      p({ children }) {
        return <p>{children}</p>;
      },

      a({ href, title, target, children }) {
        // We use the title as a flag to distinguish
        // between a button and inline link.

        const externalLinks = href.match(/(((https?)|mailto|tel)\:[/]?[/]?)/);
        const isExternalLink = externalLinks && externalLinks.length > 0;

        if (title && title === 'button') {
          return (
            <BladeButton
              onClick={() => {
                if (isExternalLink) {
                  window.open(href, '_blank');
                }
              }}
            >
              {children}
            </BladeButton>
          );
        } else {
          return (
            <BladeLink
              url={href}
              isExternal={isExternalLink}
              onClick={action}
              size={size}
            >
              {children}
            </BladeLink>
          );
        }
      },

      ul({ children }) {
        return <ShimUl size={size} items={children.filter(child => child)} />;
      },

      ol({ children }) {
        return <ShimOl size={size} items={children.filter(child => child)} />;
      },

      li({ children }) {
        return <li>{children}</li>;
      },

      blockquote({ id, children }) {
        return <BladeMessage variant={'info'}>{children}</BladeMessage>;
      }
    }
  });
}
