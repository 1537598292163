import axios, { AxiosRequestConfig } from 'axios';

export function createClient (options: AxiosRequestConfig = {}) {

  const client = axios.create({
    ...options,
    ...{
      headers: {
        'Content-Type': 'application/json'
      }
    }
  });

  return client;

}
