import { AUTH_BLOCK_USER_REQUEST, AUTH_BLOCK_USER_SUCCESS, AUTH_BLOCK_USER_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import clearAlertsAction from '../../../api/core/alerts/clear-all-alerts/actions';

export const blockUserAction = (username) => {
  return {
    type: 'API_REQUEST',
    [API_SYM]: {
      requestConfig: {
        url: 'user/block',
        method: 'POST',
        data: { username }
      },
      actions: [ blockUserRequestAction, blockUserSuccessAction, blockUserFailureAction ],
      passToAction: { username }
    }
  }
}

const blockUserRequestAction = () => {
  return { type: AUTH_BLOCK_USER_REQUEST }
}

const blockUserSuccessAction = (res) => {
  return (dispatch) => {
    dispatch({ type: AUTH_BLOCK_USER_SUCCESS });
    dispatch(clearAlertsAction());
    dispatch(queueAlertAction({
      id: "BLOCKED_USER_ALERT",
      type: "error",
      message: "Your account has been blocked after consecutive login attempts. We've sent you an email with instructions on how to unblock it.",
      location: "/sign-in"
    }))
  }
}

const blockUserFailureAction = () => {
  return { type: AUTH_BLOCK_USER_FAILURE }
}