import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Risk } from '@aventus/platform';

export const useGetPolicyRisk = function(policyId: string | undefined) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [risk, setRisk] = React.useState<Risk | undefined>(undefined);
  const [isGettingRisk, setisGettingRisk] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setisGettingRisk(true);
        try {
          const _risk = await symphony.getPolicyRisk(policyId);
          setRisk(_risk);
        } catch (error) {
          throwError(error);
        }

        setisGettingRisk(false);
      }
    })();
  }, [policyId]);

  return {
    risk,
    isGettingRisk
  };
};
