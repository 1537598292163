import React from 'react';
import {
  BladeView,
  BladeButton,
  BladeViewIsLoading,
  BladeMarkdown,
  BladeSurface
} from '@aventus/blade';
import {
  useGetPolicy,
  useGetPolicyCreditAgreement
} from '@aventus/symphony-client/hooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { getPolicyRefundMessage } from '../../services/get-policy-refund-message';
import { useApplicationTimezone } from '@aventus/application-timezone';
import css from './index.css';

export const PolicyCancelSuccess: React.FunctionComponent<IPolicyCancelSuccess> = ({
  policyId,
  cancelledPolicy,
  goHome
}) => {
  const { policy, isGettingPolicy } = useGetPolicy(policyId);
  const {
    policyCreditAgreement,
    isGettingPolicyCreditAgreement
  } = useGetPolicyCreditAgreement(policy?.id);

  const { toHumanReadable } = useApplicationTimezone();

  const { productConfiguration } = useGetProductConfiguration(
    policy?.productReferenceID,
    policy?.coverType
  );

  if (isGettingPolicy || isGettingPolicyCreditAgreement) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (
    policy &&
    policyCreditAgreement &&
    cancelledPolicy &&
    productConfiguration
  ) {
    const refundMessage = getPolicyRefundMessage(
      policyCreditAgreement,
      cancelledPolicy,
      productConfiguration.policy.cancel.success.confirmationMessage,
      toHumanReadable
    );

    return (
      <BladeView variant={'s'} className={css.scaffolding}>
        <BladeSurface
          title={
            productConfiguration.policy.cancel.success?.title ||
            "You've cancelled"
          }
        >
          <div className={css.policyCancelSuccess}>
            {productConfiguration.policy.cancel.success?.description && (
              <BladeMarkdown
                markdown={
                  productConfiguration.policy.cancel.success?.description
                }
              />
            )}

            {refundMessage && <p>{refundMessage}</p>}

            <BladeButton onClick={goHome}>
              {productConfiguration.policy.cancel.success?.finishCallToAction ||
                'Done'}
            </BladeButton>
          </div>
        </BladeSurface>
      </BladeView>
    );
  }

  return null;
};

interface IPolicyCancelSuccess {
  goHome: () => void;
  policyId: any;
  cancelledPolicy: any;
}
