import {
  BladeFormFieldsetAddressInternationalV7,
  BladeFormFieldsetAddress,
  BladeFormFieldset,
  BladeInputMajorMoney,
  BladeFormFieldsetMotor
} from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import {
  QuestionUnhandledType,
  QuestionMissingConfiguration
} from '@aventus/errors';
import { switchAddressOracle } from '@aventus/mvmt-quote/services/switch-address-oracle';
import { switchMotorOracle } from '@aventus/mvmt-quote/services/switch-motor-oracle';
import {
  ICommonFieldProps,
  ICommonInputProps
} from '@aventus/mvmt-simplequote/models/commonProps';
import { IStoredFieldAnswer } from '@aventus/mvmt-simplequote/models/fieldAnswer';
import { IFormatters } from '@aventus/mvmt-simplequote/models/formatters';
import { ISettings } from '@aventus/mvmt-simplequote/models/settings';
import { ITobesQuestionMap } from '@aventus/platform-client-context/models';
import { renderExtra } from './renderExtra';
import { renderQuestionsForPage } from './renderQuestion';
import countries from '../../data/countries.json';
import React from 'react';
import {
  TobesQuestionPageState,
  TobesQuestionState
} from '../../store/form-state';

export function renderObjectType(
  question: ITobesQuestionMap,
  commonFieldsetProps: ICommonFieldProps,
  commonInputProps: ICommonInputProps,
  settings: ISettings,
  formatters: IFormatters,
  parent: string | null,
  getQuestionAnswer: (referenceID: string) => any,
  changeQuestionAnswer: (
    referenceID: string,
    value: any,
    parent?: string | null
  ) => void,
  getArrayDescription: (referenceID: string, template?: string) => string,
  collectionStates: IStoredFieldAnswer[],
  setCollectionState: (array: IStoredFieldAnswer[]) => void,
  formState: TobesQuestionPageState,
  getQuestionState: (
    questionReferenceID: string,
    formState: TobesQuestionPageState,
    parentReferenceID?: string
  ) => TobesQuestionState | undefined,
  clearFieldValidation: (questionKey: string) => void,
  productConfiguration?: InsuranceProductConfiguration
) {
  switch (question.objectType) {
    case 'NotSet':
      throw new QuestionUnhandledType('Question objectype should be set');
    case 'UniversalAddress':
      return (
        <BladeFormFieldsetAddressInternationalV7
          {...commonFieldsetProps}
          {...commonInputProps}
          isGroup={true}
          selectedCountry={settings.countryCode}
          showCountry={
            question.questionType ? question.questionType === 'hidden' : true
          }
          countryFilter={
            'addressLookupCountryFilter' in question.customProperties
              ? question.customProperties.addressLookupCountryFilter
              : undefined
          }
          renderOracle={
            settings.oracles.address?.name && settings.oracles.address?.url
              ? switchAddressOracle(
                  { ...commonFieldsetProps, ...commonInputProps },
                  settings.oracles.address.name,
                  settings.oracles.address.url,
                  settings.oracles.address.token,
                  settings.oracles.misc?.text
                )
              : null
          }
        >
          {renderQuestionsForPage(
            question.fields,
            formatters,
            settings,
            getQuestionAnswer,
            changeQuestionAnswer,
            getArrayDescription,
            parent,
            collectionStates,
            setCollectionState,
            formState,
            getQuestionState,
            clearFieldValidation,
            productConfiguration
          )}
        </BladeFormFieldsetAddressInternationalV7>
      );
    case 'Address': //This is the legacy Address
      return (
        <BladeFormFieldsetAddress
          {...commonFieldsetProps}
          {...commonInputProps}
          options={
            question.answerOptions && question.answerOptions.length > 0
              ? question.answerOptions
              : countries
          }
          matchOn={'referenceID'}
          renderOracle={
            settings.oracles.address?.name && settings.oracles.address?.url
              ? switchAddressOracle(
                  { ...commonFieldsetProps, ...commonInputProps },
                  settings.oracles.address.name,
                  settings.oracles.address.url,
                  settings.oracles.address.token
                )
              : null
          }
        />
      );
    case 'Excesses':
      return renderExtra(question, commonFieldsetProps, commonInputProps);
    case 'ListItem':
      return renderExtra(question, commonFieldsetProps, commonInputProps);
    case 'Name':
      return (
        <BladeFormFieldset isGroup={true}>
          {renderQuestionsForPage(
            commonInputProps.value,
            formatters,
            settings,
            getQuestionAnswer,
            changeQuestionAnswer,
            getArrayDescription,
            question.referenceID,
            collectionStates,
            setCollectionState,
            formState,
            getQuestionState,
            clearFieldValidation,
            productConfiguration
          )}
        </BladeFormFieldset>
      );
    case 'Money':
      return (
        <BladeFormFieldset {...commonFieldsetProps}>
          <BladeInputMajorMoney
            {...commonInputProps}
            currencyCode={settings.currencyCode}
            currencySymbol={settings.currencySymbol}
          />
        </BladeFormFieldset>
      );

    case 'Custom':
      if (question.questionType === 'motorweb') {
        return (
          <BladeFormFieldsetMotor
            {...commonFieldsetProps}
            {...commonInputProps}
            identifierKey={'nvic'}
            identifierKeyFallback={'make'}
            registationMaxLength={9}
            renderRegistrationOracle={
              settings.oracles.motor?.registration.name &&
              settings.oracles.motor?.registration.url
                ? switchMotorOracle(
                    { ...commonFieldsetProps, ...commonInputProps },
                    settings.oracles.motor.registration.name,
                    settings.oracles.motor.registration.url,
                    settings.oracles.motor.registration.token,
                    settings.oracles.motor.carDetails.url,
                    settings.oracles.motor.carDetails.token
                  )
                : null
            }
            renderCarDetailsOracle={
              settings.oracles.motor?.carDetails.name &&
              settings.oracles.motor?.carDetails.url
                ? switchMotorOracle(
                    { ...commonFieldsetProps, ...commonInputProps },
                    settings.oracles.motor.carDetails.name,
                    settings.oracles.motor.carDetails.url,
                    settings.oracles.motor.carDetails.token
                  )
                : null
            }
          />
        );
      }
      throw new QuestionMissingConfiguration('Unhandled custom type');
    default:
      return (
        <p>{question.objectType}</p>
      ); /*TODO: temporarily render to see if we are missing question types*/
  }
}
