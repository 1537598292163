import { PolicyDocument } from '@aventus/platform';
import { ToHumanReadable } from '@aventus/application-timezone';
import { orderBy } from 'lodash';
import { IActionItem } from '@aventus/blade/action-list';

export interface PolicyDocumentsList {
  latest?: IActionItem;
  previous: IActionItem[];
}

export function getDocuments(
  documents: PolicyDocument[],
  toHumanReadable: ToHumanReadable,
  policyReferenceId?: string
): PolicyDocumentsList {

  const latest = orderBy(documents, (doc) => { return doc.version }, ['desc'])[0];

  const latestDocument = {
    id: 'policy-summary-goto-docs-button',
    icon: 'faFileCertificate',
    label: `${latest.name || `${policyReferenceId} Policy Document v${latest.version}`} - ${toHumanReadable(latest.dateCreated)}`,
    url: `/${latest.documentUrl}`,
    action: () => {
      window.open(`${latest.documentUrl}`, '_blank');
    }
  };

  return {
    latest: latestDocument,
    previous: documents.filter(x => x.version !== latest.version).map(document => ({
      id: 'policy-summary-goto-docs-button',
      icon: 'faFileCertificate',
      label: `${document.name || `${policyReferenceId} Policy Document v${document.version}`} - ${toHumanReadable(document.dateCreated)}`,
      url: `/${document.documentUrl}`,
      action: () => {
        window.open(`${document.documentUrl}`, '_blank');
      }
    }))
  }
}
