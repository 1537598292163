export default function toDecimal (value: number): number | null {

  if (value === 0) {
    return 0;
  }

  if (!value) {
    return null;
  }

  return (value / 100);
}
