import debounce from 'lodash/debounce';
import mapValues from 'lodash/mapValues';

type TObject = { [key: string]: unknown };
type TOption = number | { wait: number, [key: string]: unknown };
type TAction = { type: string, res: unknown };
type TNext = (action: TObject | (() => void)) => TAction;

const apiDebounceMiddleware = ( config = {} ) => () => (next: TNext) => {
  const debouncers = mapValues(config, (option: TOption): any => {
    if (typeof option === 'number') {
      return debounce(next, option);
    }

    const { wait = 0, ...options } = option;
    return debounce(next, wait, options);
  });

  // an action can be either a function or a TObject
  // it would complicate things to cast it as anything but any
  return (action: any) => {
    if (!action) {
      return;
    }

    if (action instanceof Function) {
      return next(action);
    }

    const { meta = {} } = action;
    const debouncer = debouncers[meta.debounce];
    if (debouncer) {
      return debouncer(action);
    }
    return next(action);
  }
}

export default apiDebounceMiddleware
