import moment, { Moment } from 'moment';

function verifyAge(value: string): (date: string | Moment) => string | true {
  const [year, operation, message] = value.split('|');
  const formats = [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
    'YYYY-MM-DDT00:00:00',
    'YYYY-MM-DDT00:00:00.0000000'
  ];

  const diffYear = (date: string | Moment): number => {
    return moment().diff(moment(date), 'years', false);
  };

  const validDate = (date: string | Moment): boolean => {
    return moment(date, formats, true).isValid();
  };

  return (date: string | Moment) => {
    if (validDate(date)) {
      switch (operation) {
        case 'max':
          return diffYear(date) > parseInt(year)
            ? message ||
                `Policy holders are required to be under ${year} years of age`
            : true;
        case 'equals':
          return diffYear(date) === parseInt(year)
            ? true
            : message || `Policy holders must be ${year} years of age`;
        case 'min':
        default:
          return diffYear(date) < parseInt(year)
            ? message ||
                `Policy holders are required to be over ${year} years of age`
            : true;
      }
    } else {
      return 'This date is invalid';
    }
  };
}

export default verifyAge;
