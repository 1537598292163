import * as React from 'react';
import { Boundary } from '../../boundary';
import {
  BladeFormFieldset,
  BladeInputText,
  BladeLink,
  BladeSelector,
  BladeMessage
} from '@aventus/blade';
import css from './index.css';
import { useLookupAddress } from './use-lookup-address';
import { IExperianAddressLookup } from './types';

export const ExperianAddressLookup: React.FunctionComponent<IExperianAddressLookup> = props => (
  <Boundary>
    <_ExperianAddressLookup {...props} />
  </Boundary>
);

const _ExperianAddressLookup: React.FunctionComponent<IExperianAddressLookup> = props => {
  const {
    query,
    setQuery,
    formatQuery,
    addresses,
    isQuerying
  } = useLookupAddress(
    props.oracleUrl,
    props.oracleToken,
    props.onChange,
    props.error?.clearErrors
  );

  const helperText =
    props.helperText || "Can't find your address? Enter it manually";

  return (
    <BladeFormFieldset
      question={props.question}
      description={props.description}
      help={props.help}
    >
      <div className={css.addressLookup}>
        <BladeInputText
          variant={props.variant}
          name={props.name}
          id={props.name}
          value={query}
          onChange={setQuery}
          isLoading={isQuerying}
        />

        {addresses?.length > 0 && (
          <BladeSelector
            isScrollable={true}
            options={addresses.map(address => ({
              label: address.suggestion,
              data: address
            }))}
            onSelect={option => {
              formatQuery(option.data);
            }}
          />
        )}

        {props.enterManually && (
          <BladeLink isSmall={true} onClick={() => props.enterManually?.()}>
            {helperText}
          </BladeLink>
        )}

        {(props.error?.recoverableError || props.error?.unrecoverableError) && (
          <BladeMessage
            variant={'error'}
            message={
              props.error?.recoverableError?.message ||
              props.error?.unrecoverableError?.message
            }
          />
        )}
      </div>
    </BladeFormFieldset>
  );
};
