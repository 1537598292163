import * as React from 'react';
import GoogleTagManager from 'react-gtm-module';

export const useInitialiseTracking: UseInitialiseTracking = (
  platformId,
  tenantId
) => {
  React.useEffect(() => {
    if (platformId) {
      GoogleTagManager.initialize({ gtmId: platformId });
    }
  }, [platformId]);

  React.useEffect(() => {
    if (tenantId) {
      GoogleTagManager.initialize({ gtmId: tenantId });
    }
  }, [tenantId]);
};

export type UseInitialiseTracking = (
  platformId: string | undefined | false,
  tenantId: string | undefined | false
) => void;
