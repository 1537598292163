import React, { useEffect, useState } from 'react';
import { BladeLink, BladeButton, BladeSurface } from '@aventus/blade';
import { useForm } from 'react-hook-form';
import { Scaffold, Typo } from '@honey/consume-react-jss';
import {
  AuthenticationConfiguration,
  AuthenticationProvider
} from '@aventus/platform';
import css from './index.css';
import { useHistory } from 'react-router';
import EmailField from './email-field';
import PasswordField from './password-field';
import ExternalProviders from './external-providers';
import { ERROR_SIGN_IN_LINK_EXPIRED } from '../../../../middleware/auth-api/error-messages';
import useGoogleRecaptchaV3 from '@aventus/pockethooks/use-google-recaptcha-v3';

export type SignInFormData = {
  email: string;
  password: string;
  requiresEmailConfirmation: boolean;
  onSuccessGoToRoute: string;
};

type LocationState = {
  isPostPurchase: string;
  isNewAccount: boolean;
  success: {
    message: string;
    detail: string;
  };
  view: CurrentView;
};

type SignInComponentProps = {
  routes: any;
  onStartUserLogin: (data: SignInFormData, recaptchaToken: string) => void;
  onSubmit: (data: SignInFormData) => void;
  onSubmitSignInLink: (data: SignInFormData) => void;
  emailPrefill: string;
  authenticationMethods: string[];
  authenticationConfig: AuthenticationConfiguration;
  authenticationProvider: AuthenticationProvider;
  successfullySentSignInLink: boolean;
  midFlowAuth: any;
  requiresEmailConfirmation: boolean;
  onSignInWithLink: VoidFunction;
  locationState?: LocationState;
  userHasPreviouslySignedIn: boolean;
  signInErrorMessage: string;
};

type SignInDetails = {
  email: string;
  password: string;
};

type CurrentView =
  | 'onetimelink'
  | 'password'
  | 'onetimelinksentconfirmation'
  | 'linkemailconfirmation'
  | 'startuserlogin';

type SignInForm = {
  title: string;
  detail: string;
  showEmailConfirmationReason: boolean;
};

const SignInComponent = ({
  routes,
  onStartUserLogin,
  onSubmit,
  onSubmitSignInLink,
  emailPrefill = '',
  authenticationConfig,
  authenticationMethods,
  authenticationProvider,
  successfullySentSignInLink = false,
  midFlowAuth,
  requiresEmailConfirmation,
  onSignInWithLink,
  locationState,
  userHasPreviouslySignedIn,
  signInErrorMessage = ''
}: SignInComponentProps) => {
  const history = useHistory();

  const { getToken: getRecaptchaToken, token: recaptchaToken } =
    useGoogleRecaptchaV3('startuserlogin');

  const externalProviderLabel =
    authenticationConfig?.signIn?.socialAuthText || 'Sign in with';

  const [currentView, setCurrentView] = useState<CurrentView>('onetimelink');

  const [form, setForm] = useState<SignInForm>({
    title: authenticationConfig?.signIn.title,
    detail: authenticationConfig?.signIn.description,
    showEmailConfirmationReason: false
  });

  const { handleSubmit, errors, control, getValues } = useForm<SignInFormData>({
    defaultValues: {
      email: emailPrefill,
      password: ''
    }
  });

  /* CLICK HANDLERS */
  const handleBackToSignIn = () => {
    const values = getValues();
    if (values.email) {
      getRecaptchaToken();
    } else {
      setCurrentView('startuserlogin');
    }
  };

  const handleToOneTimeLink = () => {
    setCurrentView('onetimelink');
    setForm({
      ...form,
      title: authenticationConfig?.signIn.title,
      detail: authenticationConfig?.signIn.description
    });
  };

  const handleConfirmEmail = ({ email }: SignInDetails) => {
    localStorage.setItem('emailForSignIn', email);
    onSignInWithLink();
  };

  const handleOnSubmit = (data: SignInFormData) => {
    switch (currentView) {
      case 'onetimelink':
        onSubmitSignInLink(data);
        break;
      case 'password':
        onSubmit(data);
        break;
      case 'startuserlogin':
        getRecaptchaToken();
        break;
      case 'linkemailconfirmation':
        handleConfirmEmail(data);
        break;
      default:
        console.log('No submit method available');
    }
  };
  /* END CLICK HANDLERS */

  /* FORM FIELDS */
  const OneTimeLinkFields = (
    <>
      <BladeButton id="sign-in-sign-in-button" type="submit" className={css.emailOneTimeLink}>
        {authenticationConfig?.signIn?.oneTimeSignInLink?.buttonText ||
          `Email me a one-time sign in link`}
      </BladeButton>
      <div className={css.description}>
        {authenticationConfig?.signIn?.oneTimeSignInLink?.description}
        <div className={css.separated}>
          <BladeLink size={'small'} onClick={handleBackToSignIn}>
            {authenticationConfig?.signIn?.oneTimeSignInLink
              ?.switchToPasswordText ?? 'Or sign in with a password instead'}
          </BladeLink>
        </div>
      </div>
      <div className={css.divider}>Or</div>
    </>
  );

  const BackToOneTimeLinkFooter = (
    <>
      <div className={css.centered}>
        <BladeLink onClick={() => setCurrentView('onetimelink')}>
          {authenticationConfig?.signIn?.oneTimeSignInLinkText ||
            `Or email me a one-time sign in link`}
        </BladeLink>
        <p className={css.separated}>
          {authenticationConfig?.signIn?.oneTimeSignInLink?.description}
        </p>
      </div>
      <div className={css.divider}>Or</div>
    </>
  );

  const StartUserLoginFields = (
    <>
      <BladeButton id="sign-in-sign-in-button" type="submit">
        {authenticationConfig?.signIn?.continueButtonText || `Continue`}
      </BladeButton>
      {BackToOneTimeLinkFooter}
    </>
  );

  const PasswordSignInFields = (
    <>
      <PasswordField
        errors={errors}
        control={control}
        authenticationConfig={authenticationConfig}
        routes={routes}
        email={getValues().email}
      />
      <BladeButton id="sign-in-sign-in-button" type="submit">
        {authenticationConfig?.signIn?.signInButtonText || 'Sign in'}
      </BladeButton>
      {BackToOneTimeLinkFooter}
    </>
  );

  const LinkEmailConfirmationFields = (
    <BladeButton id="sign-in-sign-in-button" type="submit">
      {authenticationConfig?.signIn?.oneTimeSignInLink
        ?.emailConfirmationButtonText || `Confirm`}
    </BladeButton>
  );

  const OneTimeLinkSentConfirmation = (
    <BladeLink onClick={handleToOneTimeLink}>
      {authenticationConfig?.signIn?.backToSignInText || `Back to sign in`}
    </BladeLink>
  );
  /* END FORM FIELDS */

  // RECAPTCHA USEEFFEC HOOK
  useEffect(() => {
    if (recaptchaToken) {
      const values = getValues();
      onStartUserLogin(values, recaptchaToken);
    }
  }, [recaptchaToken]);

  // USEFFECT REDUX API REQUEST HOOKS
  useEffect(() => {
    if (successfullySentSignInLink) {
      setCurrentView('onetimelinksentconfirmation');
      setForm({
        ...form,
        title:
          authenticationConfig?.signIn?.oneTimeSignInLink?.confirmationTitle ||
          'Sign into your Account',
        detail:
          authenticationConfig?.signIn?.oneTimeSignInLink?.confirmationDetail ||
          'We have emailed you a one-time link that you can use to sign in. Please do not forward the email.'
      });
    }
  }, [successfullySentSignInLink]);

  useEffect(() => {
    if (requiresEmailConfirmation) {
      setCurrentView('linkemailconfirmation');
      setForm({
        ...form,
        title:
          authenticationConfig?.signIn?.oneTimeSignInLink?.confirmationTitle ||
          'Sign into your Account',
        detail:
          authenticationConfig?.signIn?.oneTimeSignInLink
            ?.emailConfirmationDescriptionText ||
          'Please confirm your email address to sign in.'
      });
    }
  }, [requiresEmailConfirmation]);

  useEffect(() => {
    if (userHasPreviouslySignedIn == null) return;

    userHasPreviouslySignedIn
      ? setCurrentView('password')
      : history.push('/create-account', {
          ...locationState,
          emailAddress: getValues().email
        });
  }, [userHasPreviouslySignedIn]);

  useEffect(() => {
    if (signInErrorMessage === ERROR_SIGN_IN_LINK_EXPIRED) {
      history.push(routes.signIn.path);
      setCurrentView('onetimelink');
    }
  }, [signInErrorMessage]);

  useEffect(() => {
    if (locationState?.view) setCurrentView(locationState.view);
  }, [locationState?.view]);

  return (
    <BladeSurface
      title={form.title}
      description={form.detail}
      shouldRespectViewWidth={true}
      helpLink={
        currentView === 'linkemailconfirmation' &&
        !form.showEmailConfirmationReason && (
          <BladeLink
            className={css.helpLink}
            onClick={() =>
              setForm({ ...form, showEmailConfirmationReason: true })
            }
          >
            {
              authenticationConfig?.signIn?.oneTimeSignInLink
                ?.emailConfirmationLinkText
            }
          </BladeLink>
        )
      }
    >
      <Scaffold fill={true} position={'c'}>
        <Scaffold
          grow={true}
          orient={'col'}
          size={'large'}
          style={{ paddingTop: 0 }}
        >
          {midFlowAuth && midFlowAuth.registerDescription && (
            <Typo variant={'body'}>{midFlowAuth.registerDescription}</Typo>
          )}
          {currentView !== 'onetimelinksentconfirmation' &&
            authenticationMethods.includes('form') && (
              <>
                <form
                  className={css.form}
                  onSubmit={handleSubmit(handleOnSubmit)}
                >
                  <EmailField
                    authenticationConfig={authenticationConfig}
                    control={control}
                    errors={errors}
                    showEmailConfirmationReason={
                      form.showEmailConfirmationReason
                    }
                  />
                  {currentView === 'onetimelink' && OneTimeLinkFields}
                  {currentView === 'startuserlogin' && StartUserLoginFields}
                  {currentView === 'password' && PasswordSignInFields}
                  {currentView === 'linkemailconfirmation' &&
                    LinkEmailConfirmationFields}
                </form>
                {(currentView === 'onetimelink' ||
                  currentView === 'password' ||
                  currentView === 'startuserlogin') && (
                  <ExternalProviders
                    authenticationMethods={authenticationMethods}
                    authenticationProvider={authenticationProvider}
                    externalProviderLabel={externalProviderLabel}
                  />
                )}
              </>
            )}
          {currentView === 'onetimelinksentconfirmation' &&
            OneTimeLinkSentConfirmation}
        </Scaffold>
      </Scaffold>
    </BladeSurface>
  );
};

export default SignInComponent;
