export type Nullable<T> = T | null;

export interface IPolicyDetailHeader {
  dateCreated: string;
  id: string;
  history: IPolicyDetailHistory;
}

export interface IPolicyDetailHistory {
  createdByID: string;
  dateCreated: string;
  id: string;
  policyDetail: IPolicyDetail;
  policyDetailHeaderID: string;
  risk: IRisk;
  sourcePolicyDetailHistoryID: string;
  version: number;
}

export interface IPolicyDetailEditSession {
  createdByID: string;
  dateCreated: string;
  dateUpdated: string;
  id: string;
  policyDetail: IPolicyDetail;
  policyDetailHeaderID: string;
  risk: IRisk;
  sourcePolicyDetailHistoryID: string;
  state: EditState;
  version: number;
}

export interface IPriceHeader {
  immediateAmount: IPriceSectionHeader;
  planType: PaymentPlanType;
  pricingPlanReferenceID: string;
  recurringAmount: IPriceSectionHeader;
  recurringInitial: IPriceSectionHeader;
  total: IPriceSectionHeader;
}

export interface IPriceSectionHeader {
  accountingDiscount: IMoney;
  commission: IMoney;
  customerDiscount: IMoney;
  gross: IMoney;
  net: IMoney;
  netDiscount: IMoney;
  netPremiumInsurer: IMoney;
  retailCommission: IMoney;
  retailGross: IMoney;
  retailNet: IMoney;
  retailTax: IFinanceTaxTaxSet;
  rows: IPriceRow[];
  tax: IFinanceTaxTaxSet;
}

export interface IPriceRow {
  accountingDiscount: IMoney;
  commission: IMoney;
  customerDiscount: IMoney;
  description: Nullable<string>;
  gross: IMoney;
  net: IMoney;
  netDiscount: IMoney;
  netPremiumInsurer: IMoney;
  order: number;
  policySectionID: Nullable<string>;
  retailCommission: IMoney;
  retailGross: IMoney;
  retailNet: IMoney;
  retailTax: IFinanceTaxTaxSet;
  subRows: IPriceRow[];
  tax: IFinanceTaxTaxSet;
  type: PriceRowType;
}

export type PriceRowType =
  | 'NotSet'
  | 'InsuranceProduct'
  | 'Section'
  | 'Fee'
  | 'Interest'
  | 'PaymentAggregate'
  | 'Other'
  | 'Subsection';

export type EditState = 'Active' | 'Completed' | 'Discarded';

export interface IPolicyDetail {
  declineReasons: Nullable<IDeclineReason[]>;
  endorsements: Nullable<IEndorsement[]>;
  fees: Nullable<IFinanceFee[]>;
  productID: number;
  productReferenceID: Nullable<string>;
  productVersion: number;
  referralReasons: IReferralReason[];
  sections: Nullable<ISection[]>;
  totalCommission: Nullable<IMoney>;
  totalGrossDiscount: Nullable<IMoney>;
  totalGrossPremium: Nullable<IMoney>;
  totalIncludingFees: Nullable<IMoney>;
  totalNetDiscount: Nullable<IMoney>;
  totalNetPremium: Nullable<IMoney>;
  totalNetPremiumInsurer: Nullable<IMoney>;
  totalSectionTax: Nullable<IMoney>;
  totalSectionTaxAggregate: Nullable<IFinanceTax[]>;
  version: number;
}

export interface ISection {
  commission: IFinanceCommission;
  grossDiscounts: Nullable<IFinanceGrossDiscount[]>;
  id: string;
  name: Nullable<string>;
  netLoadingDiscounts: Nullable<IFinanceNetLoadingDiscount[]>;
  referenceID: string;
  schemeID: number;
  subsections: ISubSection[];
  tax: Nullable<IFinanceTax[]>;
  totalCommission: Nullable<IMoney>;
  totalGrossDiscount: Nullable<IMoney>;
  totalGrossPremium: Nullable<IMoney>;
  totalNetDiscount: Nullable<IMoney>;
  totalNetPremium: Nullable<IMoney>;
  totalNetPremiumInsurer: Nullable<IMoney>;
  totalTax: Nullable<IMoney>;
}

export interface ISubSection {
  coverages: ICoverage[];
  dependentOnSectionReference: Nullable<string>;
  exposureIDs: string[];
  id: string;
  name: string;
  netLoadingDiscounts: Nullable<IFinanceNetLoadingDiscount[]>;
  referenceID: string;
  totalNetDiscount: Nullable<IMoneyHighAccuracy>;
  totalPremium: Nullable<IMoneyHighAccuracy>;
}

export interface ICoverage {
  premium: Nullable<IMoneyHighAccuracy>;
  commission: Nullable<IFinanceCommission>;
  rateMultiplier: number;
  baseRateMultiplier: number;
  baseRatedAmount: Nullable<IMoneyHighAccuracy>;
  id: string;
  name: Nullable<string>;
  referenceID: Nullable<string>;
  declaredAmount: Nullable<IMoney>;
  sumInsured: Nullable<IMoney>;
  type: CoverageType;
}

export interface IRisk {
  core: Nullable<IRiskCore>;
  exposures: IRiskExposure[]; // Nullable
}

/**
 * TODO: Check whether risk core typed correctly
 */
export interface IRiskCore {
  coverStartDate: Nullable<string>;
  coverEndDate: Nullable<string>;
  productID: number;
  productReferenceID: string;
  excesses: {
    voluntaryExcess: Nullable<IMoney>;
    compulsoryExcess: Nullable<IMoney>;
    totalExcess: Nullable<IMoney>;
    totalExcessSelection: Nullable<IMoney>;
  };
  proposer: {
    personName: {
      firstName: Nullable<string>;
      lastName: Nullable<string>;
      title: Nullable<string>;
    };
    emailAddress: Nullable<string>;
  };
  businessDescription: Nullable<string>;
}

/**
 * TODO: Need to type exposures correctly
 */
export interface IRiskExposure {
  id: string;
  referenceID: string;
  name: Nullable<string>;
  order: Nullable<number>;
  value: Nullable<never>;
}

export interface IMoney {
  currencyCode: string;
  value: number;
}

export interface IMoneyHighAccuracy {
  currencyCode: Nullable<string>;
  value: number;
}

export interface IDeclineReason {
  coverageID: string;
  referenceID: string;
  sectionID: string;
  subSectionID: string;
  text: string;
  title: string;
}

export interface IEndorsement {
  referenceID: string;
  id: string;
  modiferOwners: IModiferOwner[];
  systemEndorsementID: string;
  text: string;
  title: string;
  modifierType: string;
}

export interface INewEndorsement {
  referenceID: string;
  schemeReferenceID: string;
  sectionReferenceID: string;
  providerReference: string;
  title: string;
  text: string;
  validFromDateTime: string;
  validToDateTime: string;
}

export interface INewEndorsementResponse {
  id: string;
  referenceID: string;
  version: number;
  versionReferenceID: string;
  sectionReferenceID: string;
  schemeReferenceID: string;
  title: string;
  text: string;
  providerReference: string;
  validFromDateTime: string;
  validToDateTime: string;
}

export type IModiferOwner = {
  componentID: string;
  componentType: EModiferOwnerComponentType | string;
  enabled: boolean;
  source: EModiferOwnerSource | string;
};

export type EModiferOwnerComponentType =
  | 'None'
  | 'Section'
  | 'Subsection'
  | 'Coverage';

export enum EModiferOwnerSource {
  Manual = 'Manual',
  Rules = 'Rules',
  None = 'None',
  Ratings = 'Ratings'
}

export interface IReferralReason {
  coverageID: string;
  referenceID: string;
  sectionID: string;
  subSectionID: string;
  text: string;
}

export interface IFinanceFee {
  gross: IMoney;
  id: string;
  name: string;
  net: IMoney;
  order: number;
  referenceID: string;
  tax: IFinanceTaxTaxSet;
}

export interface IFinanceTaxTaxSet {
  taxes: ITax[];
  taxRuleID: string;
  taxRuleReferenceID: string;
  total?: IMoney;
}

export interface IFinanceTax {
  amount: Nullable<IMoney>;
  applicationMethod: FinanceTaxApplicationMethod;
  name: Nullable<string>;
  order: number;
  referenceID: Nullable<string>;
  ruleAmount: number;
  taxType: FinanceTaxType;
}

export interface ITax {
  amount: IMoney;
  name: string;
  order: number;
  percentage: number;
  taxReferenceID: string;
}

export interface IFinanceCommission {
  amount: Nullable<IMoneyHighAccuracy>;
  baseRuleAmount: number;
  commissionCalculationType: FinanceCommissionCalculationType;
  commissionType: FinanceCommissionType;
  name: Nullable<string>;
  referenceID: Nullable<string>;
  ruleAmount: number;
}

export interface IFinanceGrossDiscount {
  accountingDiscount: IMoney;
  customerDiscount: IMoney;
  isAuthorized: boolean;
  name: Nullable<string>;
  order: number;
  ruleAmount: number;
  type: FinanceDiscountType;
}

export interface IFinanceNetLoadingDiscount {
  amount: Nullable<IMoney>;
  baseRuleAmount: number; // Might be removed in future, see API docs
  name: Nullable<string>;
  order: number;
  referenceID: string;
  ruleAmount: number;
  type: FinanceDiscountType;
}

export type CoverageType =
  | 'Rated'
  | 'NonRated'
  | 'Custom'
  | 'Fixed'
  | 'RatedWithUplift';

export type FinanceCommissionType = 'FixedRate' | 'Percentage';

export type FinanceCommissionCalculationType =
  | 'NetPremiumInsurer'
  | 'NetPremium';

export type FinanceTaxApplicationMethod = 'None' | 'Cumulative' | 'Fixed';

export type FinanceTaxType = 'None' | 'FixedRate' | 'Percentage';

export type FinanceDiscountType = 'Fixed' | 'Percentage';

export type PaymentPlanType =
  | 'NotSet'
  | 'FixedTermSinglePayment'
  | 'AnnualMonthlyCredit'
  | 'AnnualMonthlySubscription'
  | 'MonthlySubscription'
  | 'ExternalCollection'
  | 'SinglePayment';
