import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest } from '@aventus/platform';

export const useStartIntelligentQuoteForAdjust: UseStartIntelligentQuoteForAdjust = function (
  policyId,
  partnerId
) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [ intelligentQuoteRequest, setIntelligentQuoteRequest ] = React.useState<IntelligentQuoteRequest | undefined>(undefined);
  const [ isStartingIntelligentQuoteForAdjust, setIsStartingIntelligentQuoteForAdjust ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (policyId) {

        setIsStartingIntelligentQuoteForAdjust(true);
        
        try {

          const _intelligentQuoteRequest = await symphony.startIntelligentQuoteForAdjust(
            policyId,
            partnerId
          );

          setIntelligentQuoteRequest(_intelligentQuoteRequest);

        } catch (error) {
          throwError(error);
        }

        setIsStartingIntelligentQuoteForAdjust(false);

      }

    }());
  }, [ policyId ]);

  return {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForAdjust
  };

}

export type UseStartIntelligentQuoteForAdjust = (
  policyId?: string,
  partnerId?: string
) => {
  intelligentQuoteRequest: IntelligentQuoteRequest | undefined,
  isStartingIntelligentQuoteForAdjust: boolean
}
