import { Policy } from '@aventus/platform';
import { BinderPayment, BinderConfiguration } from '../interface/binder';
import { Lifecycles } from '../interface/lifecycles';
import { PclRecoverableError, ServerError } from '@aventus/errors';
import { setTimeoutAsync } from '@aventus/pocketknife/set-timeout-async';
import {
  StripeError,
  friendlyStripeErrorMessage
} from '@aventus/payment-provider-stripe';
import { CheckoutErrorConfiguration } from '@aventus/configuration';

export const payNow = async (
  payment: BinderPayment,
  depositPayment: BinderPayment | undefined,
  paymentConfiguration: BinderConfiguration,
  lifecycles: Lifecycles,
  throwError: (error: Error) => void,
  setError: (identifier: string, message: string) => void,
  existingInitialisation?: any,
  paymentMessages?: CheckoutErrorConfiguration
): Promise<Policy | undefined> => {
  // An Aventus Platform API ID for the
  // payment being processed.

  let paymentId: string | undefined =
    existingInitialisation?.paymentId || undefined;

  // Stripe specific secret, to be used as
  // the ID for the payment when talking to the
  // Stripe API

  let stripeClientSecret: string | undefined =
    existingInitialisation?.clientSecret || undefined;

  // For subscription payment plans, there are a few potential
  // extra steps required, often given the provider, that need
  // to be executed before the payment is initialised in the platform.
  // This is generally because these kinds of payment plans rely
  // on automatic payments being taken at a later date, so the card
  // setup process is slightly different.

  if (
    !existingInitialisation?.clientSecret &&
    !existingInitialisation?.paymentId
  ) {
    if (
      paymentConfiguration.paymentPlanType === 'AnnualMonthlySubscription' ||
      paymentConfiguration.paymentPlanType === 'MonthlySubscription'
    ) {
      // If it's a subscription, and based on our current provider (Stripe),
      // we can safely assume that there is only a payment, and no deposit.
      // This assumption might need to change as more providers are added
      // to the platform.

      if (payment.provider === 'Stripe') {
        try {
          const setupIntent = await lifecycles.onStripeCreateSetupIntent();
          const setupResponse =
            await payment.providerRequest.stripe.confirmCardSetup(
              setupIntent.clientSecret,
              {
                payment_method: {
                  card: payment.providerRequest.element
                }
              }
            );

          if (setupResponse.error !== undefined) {
            if (setupResponse.error.type === 'card_error') {
              setError(
                'stripecard',
                friendlyStripeErrorMessage(setupResponse.error)
              );
              return;
            } else {
              setError(
                'stripecard',
                'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
              );

              // @ts-ignore
              window.Rollbar?.critical(
                new Error(
                  `PAYMENT: Stripe confirmCardSetup error: ${setupResponse.error.message}`
                ),
                setupResponse.error
              );
              return;
            }
          }

          if (setupResponse.setupIntent.payment_method === null) {
            setError(
              'stripecard',
              'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
            );

            // @ts-ignore
            window.Rollbar?.critical(
              new Error(
                `PAYMENT: Stripe error: setupIntent.payment_method was null`
              ),
              setupResponse.error
            );
            return;
          }

          const subscriptionInitialisation =
            await lifecycles.onInitialiseSubscription(
              paymentConfiguration.quoteID,
              paymentConfiguration.encryptedDiscount,
              paymentConfiguration.paymentPlanReferenceID,
              setupResponse.setupIntent.payment_method
            );

          paymentId = subscriptionInitialisation.depositID;
          stripeClientSecret =
            subscriptionInitialisation.externalData.clientSecret;
        } catch (e) {
          throwError(
            new StripeError(
              paymentMessages?.initialisation ||
                'There was an error setting up your subscription. Please try again'
            )
          );
        }
      }
    } else {
      // Initialisation is an Aventus Platform level
      // operation, which doesn't really care who the provider
      // is yet.
      try {
        const paymentInitialisation = await lifecycles.onInitialise(
          paymentConfiguration.quoteID,
          paymentConfiguration.encryptedDiscount,
          paymentConfiguration.paymentPlanReferenceID
        );

        paymentId = paymentInitialisation.paymentID;

        if (
          payment.provider === 'Stripe' ||
          depositPayment?.provider === 'Stripe'
        ) {
          stripeClientSecret = paymentInitialisation.externalData.clientSecret;
        }
      } catch (e) {
        throwError(
          new StripeError(
            paymentMessages?.initialisation ||
              'Payment was not initialised properly'
          )
        );
      }
    }
  }

  if (!paymentId && typeof paymentId !== 'string') {
    throw new Error('Payment was not initialised properly');
  }

  // With the payment initialised in the platform,
  // we can start handling the provider specific flows.

  const paymentObj = depositPayment || payment;

  if (paymentObj && paymentObj.provider === 'Stripe') {
    if (!stripeClientSecret) {
      throw new Error('Stripe was not initialised properly');
    }

    // Allow Stripe integration code to initialise
    // it's own pay sequence, passing in what the platform
    // initialised so far.

    // TODO
    // If is adjustment, and a subscription then stripe.handleCardPayment
    // only needs the clientSecret.

    try {
      if (
        paymentConfiguration.quoteType === 'MTA' &&
        paymentConfiguration.adjustmentType === 'SubscriptionPayment'
      ) {
        const cardPaymentResponse =
          await paymentObj.providerRequest.stripe.confirmCardPayment(
            stripeClientSecret
          );

        if (cardPaymentResponse.error) {
          if (cardPaymentResponse.error?.type === 'card_error') {
            setError(
              'stripecard',
              friendlyStripeErrorMessage(cardPaymentResponse.error)
            );
            return;
          } else {
            setError(
              'stripecard',
              'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
            );
            // @ts-ignore

            window.Rollbar?.critical(
              new Error(
                `STRIPE: confirmCardPayment ${cardPaymentResponse.error.code}: ${cardPaymentResponse.error.message}`
              ),
              cardPaymentResponse.error
            );
            return;
          }
        }
      } else {
        const cardPaymentResponse =
          await paymentObj.providerRequest.stripe.confirmCardPayment(
            stripeClientSecret,
            {
              payment_method: {
                card: paymentObj.providerRequest.element
              },
              setup_future_usage: 'off_session'
            }
          );

        if (cardPaymentResponse.error) {
          if (cardPaymentResponse.error?.type === 'card_error') {
            setError(
              'stripecard',
              friendlyStripeErrorMessage(cardPaymentResponse.error)
            );
            return;
          } else {
            setError(
              'stripecard',
              'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
            );
            // @ts-ignore
            window.Rollbar?.critical(
              new Error(
                `STRIPE: confirmCardPayment ${cardPaymentResponse.error.code}: ${cardPaymentResponse.error.message}`
              ),
              cardPaymentResponse.error
            );
            return;
          }
        }
      }
    } catch (error) {
      // @ts-ignore
      window.Rollbar?.error(error);

      throwError(
        new StripeError(
          paymentMessages?.cardPayment ||
            'Our apologies, we experienced an error adjusting your policy. Please contact customer support.'
        )
      );
    }
  }

  // When Stripe is ready, we can begin polling the platform
  // to see when the payment has registered all the way through.

  const pollThreshold = 5;
  async function pollPlatform(counter: number): Promise<Policy | undefined> {
    if (!paymentId && typeof paymentId !== 'string') {
      throw new Error('Payment was not initialised properly');
    }

    if (counter < pollThreshold) {
      const response = await lifecycles.onPoll(paymentId);

      switch (response.paymentStatus) {
        case 'Initialised':
          return await setTimeoutAsync(async () => {
            try {
              return await pollPlatform(counter + 1);
            } catch (e) {
              throwError(e as Error);
              return;
            }
          }, 3000);

        case 'AwaitingSettlement':
          if (paymentConfiguration.paymentPlanType === 'AnnualMonthlyCredit') {
            try {
              await lifecycles.onCreateFinanceAgreement(
                payment.providerRequest.accountNumber,
                paymentConfiguration.encryptedCreditPlan,
                paymentConfiguration.encryptedDiscount,
                payment.providerRequest.fullName,
                paymentId,
                paymentConfiguration.paymentPlanReferenceID,
                // paymentConfiguration.pricingPlanID,
                payment.providerRequest.sortCode,
                payment.providerRequest.title.text
              );
            } catch (e) {
              if (e instanceof PclRecoverableError) {
                setError('pclfinance', e.message);
                return;
              } else {
                throw e;
              }
            }
            return await lifecycles.onGetPurchasedPolicy(response.id);
          } else {
            return await setTimeoutAsync(async () => {
              try {
                return await pollPlatform(counter + 1);
              } catch (e) {
                throwError(e as Error);
                return;
              }
            }, 3000);
          }

        case 'Settled':
          if (paymentConfiguration.paymentPlanType === 'AnnualMonthlyCredit') {
            await lifecycles.onCreateFinanceAgreement(
              payment.providerRequest.accountNumber,
              paymentConfiguration.encryptedCreditPlan,
              paymentConfiguration.encryptedDiscount,
              payment.providerRequest.fullName,
              paymentId,
              paymentConfiguration.paymentPlanReferenceID,
              // paymentConfiguration.pricingPlanID,
              payment.providerRequest.sortCode,
              payment.providerRequest.title.text
            );
            return await lifecycles.onGetPurchasedPolicy(response.id);
          } else {
            return await lifecycles.onGetPurchasedPolicy(response.id);
          }

        default:
          throw new Error('');
      }
    } else {
      throw new ServerError(
        'Something unexpected has gone wrong. Please try again later or contact support'
      );
    }
  }

  return await pollPlatform(0);
};
