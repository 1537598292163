// Taken form https://github.com/sl-julienamblard/email-misspelled/
export const popular: string[] = [
  "126.com",
  "163.com",
  "21cn.com",
  "aim.com",
  "aliyun.com",
  "att.net",
  "bluewin.ch",
  "centurytel.net",
  "citlink.net",
  "cox.net",
  "cs.com",
  "email.com",
  "facebook.com",
  "fastmail.fm",
  "foxmail.com",
  "frontier.com",
  "frontiernet.net",
  "games.com",
  "gmail.com",
  "gmx.net",
  "googlemail.com",
  "goowy.com",
  "gte.net",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "keemail.me",
  "lavabit.com",
  "love.com",
  "mac.com",
  "mail.com",
  "me.com",
  "netscape.net",
  "optonline.net",
  "pobox.com",
  "prodigy.net.mx",
  "protonmail.ch",
  "protonmail.com",
  "qq.com",
  "rediffmail.com",
  "rocketmail.com",
  "rocketmail.com",
  "rogers.com",
  "safe-mail.net",
  "sbcglobal.net",
  "shaw.ca",
  "sina.cn",
  "sina.com",
  "skynet.be",
  "sympatico.ca",
  "telenet.be",
  "tuta.io",
  "tutamail.com",
  "tutanota.com",
  "tutanota.de",
  "wild4music.com",
  "windstream.net",
  "wmconnect.com",
  "wow.com",
  "y7mail.com",
  "yandex.com",
  "ybb.ne.jp",
  "yeah.net",
  "ygm.com",
  "ymail.com",
  "zoho.com",
];

export default popular;
