import * as React from 'react';
import { PricingPlan, Risk, Quote } from '@aventus/platform';
import { BladeList, BladeReceipt } from '@aventus/blade';
import { ReceiptSummary } from '../receipt-summary';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';

import css from './index.css';

export const ReceiptPay: React.FunctionComponent<IReceiptPay> = props => {
  let discountSummary = null;

  // For now, we do not show the FreeAdditionalProduct discounts on pay summary.
  if (
    props.plan.discountInfo &&
    props.plan.discountInfo.type !== 'FreeAdditionalProduct' &&
    props.plan.discountInfo.discountPercent === 0.0 &&
    props.plan.discountInfo.discountFixed?.value === 0.0
  ) {
    let discountPercentOrFixed;

    if (props.plan.discountInfo.discountPercent) {
      discountPercentOrFixed = {
        label: props.plan.discountInfo.description,
        value:
          props.plan.discountInfo.amount.value > 0
            ? `-${currencyToHumanReadable(props.plan.discountInfo.amount)}`
            : '--'
      };
    } else {
      discountPercentOrFixed = {
        label: props.plan.discountInfo.description,
        value:
          props.plan.discountInfo.amount.value > 0
            ? `-${currencyToHumanReadable(props.plan.discountInfo.amount)}`
            : '--'
      };
    }

    const basePriceGross = props.plan.basePriceGrossWithUplift.value;
    const finalPriceGross = props.plan.finalPrice.gross.value;
    const totalAnnualPremium =
      basePriceGross - finalPriceGross + props.plan.totalPayable.gross.value;

    discountSummary = (
      <BladeList
        isFlush={true}
        isSmall={true}
        size={'small'}
        className={css.receiptPay_itemisedList}
        moreDetailsStyle={{ fontSize: '12px' }}
        shouldRespectViewWidth={true}
        list={[
          {
            label: props.discountSubTotalLabel
              ? props.discountSubTotalLabel
              : 'Subtotal',
            value: currencyToHumanReadable({
              value: totalAnnualPremium,
              currencyCode: props.plan.totalPayable.gross.currencyCode
            })
          },
          discountPercentOrFixed
        ]}
      />
    );
  }

  let discountAmt = '';
  if (props.plan.discountInfo && props.plan.discountInfo.type !== 'FreeAdditionalProduct' && props.plan.discountInfo.amount.value > 0){
    discountAmt = `(incl. ${currencyToHumanReadable(props.plan.discountInfo.amount)} discount)`
  }

  if (props.plan.paymentPlan.type === 'FixedTermSinglePayment' || props.plan.paymentPlan.type === 'SinglePayment') {

    let tagLabel;
    let totalCostLabel = '';
    

    if (props.plan.paymentPlan.type === 'FixedTermSinglePayment'){
      tagLabel = 'Annual';
      totalCostLabel = 'Total annual cost';
    }
    else if (props.plan.paymentPlan.type === 'SinglePayment'){
      tagLabel = 'Single';
      totalCostLabel = 'Total cost'
    }

    return (
      <BladeReceipt
        price={props.plan.upfrontPrice}
        tag={props.receiptPaymentPlanLabel ?? tagLabel}
        footnote={props.taxLabel}
        receiptStyledFoot={props.receiptStyledFoot}
      >
        <div className={css.receiptPay_scaffold}>
          <ReceiptSummary
            label={props.productLabel}
            quote={props.quote}
            risk={props.risk}
            policyReferenceID={props.policyReferenceID}
          />
          {discountSummary}

          <BladeList
            size={'small'}
            isFlush={true}
            isSmall={true}
            className={css.receiptPay_itemisedList}
            moreDetailsStyle={{ fontSize: '14px' }}
            shouldRespectViewWidth={true}
            list={[
              props.plan.feesTotal !== null
                ? {
                    label: props.fixedFeeTotalLabel,
                    value: currencyToHumanReadable(props.plan.feesTotal)
                  }
                : {},
              {
                label: props.totalAnnualCostLabel
                  ? props.totalAnnualCostLabel
                  : totalCostLabel,
                subLabel: discountAmt,
                value: currencyToHumanReadable(props.plan.totalPayable.gross)
              }
            ]}
          />
        </div>
      </BladeReceipt>
    );
  }

  if (props.plan.paymentPlan.type === 'AnnualMonthlyCredit') {
    return (
      <BladeReceipt
        price={props.plan.monthlyPrice}
        tag={'Monthly'}
        receiptStyledFoot={props.receiptStyledFoot}
        footnote={`${props.taxLabel} ${
          props.displayAprLabel === false
            ? ``
            : `APR (variable) ${props.plan.aprRate}%`
        }`}
      >
        <div className={css.receiptPay_scaffold}>
          <ReceiptSummary
            label={props.productLabel}
            quote={props.quote}
            risk={props.risk}
            policyReferenceID={props.policyReferenceID}
          />

          {discountSummary}

          {props.plan.paymentPlan.paymentProvider === 'PremiumCredit' && (
            <BladeList
              size={'small'}
              isFlush={true}
              isSmall={true}
              className={css.receiptPay_itemisedList}
              moreDetailsStyle={{ fontSize: '14px' }}
              shouldRespectViewWidth={true}
              list={[
                props.plan.feesTotal !== null
                  ? {
                      label: props.fixedFeeTotalLabel,
                      value: currencyToHumanReadable(props.plan.feesTotal)
                    }
                  : {},
                {
                  label: 'Deposit',
                  value: currencyToHumanReadable(props.plan.upfrontPrice)
                },
                props.plan.facilityFee?.value === 0
                  ? props.plan.firstInstalmentAmount?.value ===
                    props.plan.monthlyPrice.value
                    ? {
                        label: `Instalments ${
                          props.displayNumberOfInstalments === false
                            ? ``
                            : `(x${props.plan.numberOfInstallments})}`
                        }`,
                        value: currencyToHumanReadable(props.plan.monthlyPrice),
                        moreDetails:
                          'Your first payment will be collected one month after your cover begins, followed by payments on or around the same day each month.'
                      }
                    : [
                        {
                          label: '1st Instalment',
                          value: currencyToHumanReadable(
                            props.plan.firstInstalmentAmount
                          ),
                          moreDetails:
                            'Your first payment will be collected one month after your cover begins, followed by payments on or around the same day each month.'
                        },
                        {
                          label: `Remaining Instalments (x${
                            props.plan.numberOfInstallments - 1
                          })`,
                          value: currencyToHumanReadable(
                            props.plan.monthlyPrice
                          )
                        }
                      ]
                  : [
                      {
                        label: '1st Instalment',
                        value: currencyToHumanReadable(
                          props.plan.firstInstalmentAmount
                        ),
                        moreDetails: `Includes ${currencyToHumanReadable(
                          props.plan.facilityFee
                        )} facility fee. Your first payment will be collected one month after your cover begins, followed by payments on or around the same day each month.`
                      },
                      {
                        label: `Remaining Instalments (x${
                          props.plan.numberOfInstallments - 1
                        })`,
                        value: currencyToHumanReadable(props.plan.monthlyPrice)
                      }
                    ],
                {
                  label: 'Transaction fee',
                  value: currencyToHumanReadable(
                    props.plan.totalPayable.totalInterest
                  ),
                  moreDetails: `This is calculated from an interest rate of ${props.plan.interestRate}% applied to the premium amount left over after subtracting the deposit. This corresponds to a representative APR of ${props.plan.aprRate}%.`
                },
                {
                  label: 'Total amount payable',
                  subLabel: discountAmt,
                  value: currencyToHumanReadable(props.plan.totalPayable.gross)
                }
              ].flat()}
            />
          )}

          {props.plan.paymentPlan.paymentProvider !== 'PremiumCredit' && (
            <BladeList
              size={'small'}
              isFlush={true}
              isSmall={true}
              className={css.receiptPay_itemisedList}
              moreDetailsStyle={{ fontSize: '14px' }}
              shouldRespectViewWidth={true}
              list={[
                props.plan.feesTotal !== null
                  ? {
                      label: props.fixedFeeTotalLabel,
                      value: currencyToHumanReadable(props.plan.feesTotal)
                    }
                  : {},
                {
                  label: 'Deposit',
                  value: currencyToHumanReadable(props.plan.upfrontPrice)
                },
                {
                  label: `Instalments ${
                    props.displayNumberOfInstalments === false
                      ? ``
                      : `(x${props.plan.numberOfInstallments})`
                  }`,
                  value: currencyToHumanReadable(props.plan.monthlyPrice),
                  moreDetails:
                    'Your first payment will be collected one month after your cover begins, followed by payments on or around the same day each month.'
                },
                {
                  label: 'Interest amount',
                  value: currencyToHumanReadable(
                    props.plan.totalPayable.totalInterest
                  ),
                  moreDetails: `This is calculated from an interest rate of ${props.plan.interestRate}% applied to the premium amount left over after subtracting the deposit. This corresponds to a representative APR of ${props.plan.aprRate}%.`
                },
                {
                  label: 'Total amount payable',
                  subLabel: discountAmt,
                  value: currencyToHumanReadable(props.plan.totalPayable.gross)
                }
              ]}
            />
          )}
        </div>
      </BladeReceipt>
    );
  }

  if (
    props.plan.paymentPlan.type === 'AnnualMonthlySubscription' ||
    props.plan.paymentPlan.type === 'MonthlySubscription'
  ) {
    const hasLargerFirstInstalment =
      props.plan.upfrontPrice.value != props.plan.monthlyPrice.value;
    const instalmentAmount =
      props.plan.upfrontPrice.value != props.plan.monthlyPrice.value
        ? props.plan.numberOfInstallments - 1
        : props.plan.numberOfInstallments;
    const instalmentsLabelPrefix = hasLargerFirstInstalment
      ? `Remaining instalments`
      : `instalments`;
    const instalmentsLabelSuffix =
      props.displayNumberOfInstalments === false
        ? ``
        : `(x${instalmentAmount})`;
    return (
      <BladeReceipt
        price={props.plan.monthlyPrice}
        tag={'Monthly'}
        footnote={props.taxLabel}
        receiptStyledFoot={props.receiptStyledFoot}
      >
        <div className={css.receiptPay_scaffold}>
          <ReceiptSummary
            label={props.productLabel}
            quote={props.quote}
            risk={props.risk}
            policyReferenceID={props.policyReferenceID}
          />

          {discountSummary}

          <BladeList
            size={'small'}
            isFlush={true}
            isSmall={true}
            className={css.receiptPay_itemisedList}
            moreDetailsStyle={{ fontSize: '14px' }}
            shouldRespectViewWidth={true}
            list={[
              props.plan.feesTotal !== null
                ? {
                    label: props.fixedFeeTotalLabel,
                    value: currencyToHumanReadable(props.plan.feesTotal)
                  }
                : {},
              {
                label: props.totalAnnualCostLabel
                  ? props.totalAnnualCostLabel
                  : 'Total annual cost',
                subLabel: discountAmt,
                value: currencyToHumanReadable(props.plan.totalPayable.gross)
              },
              hasLargerFirstInstalment
                ? {
                    label: 'First payment',
                    value: currencyToHumanReadable(props.plan.upfrontPrice)
                  }
                : {},
              {
                label: [instalmentsLabelPrefix, instalmentsLabelSuffix].join(
                  ' '
                ),
                value: currencyToHumanReadable(props.plan.monthlyPrice)
              }
            ]}
          />
        </div>
      </BladeReceipt>
    );
  }

  return null;
};

interface IReceiptPay {
  productLabel: string;
  taxLabel: string;
  fixedFeeTotalLabel: string;
  displayAprLabel: boolean;
  quote: Quote;
  plan: PricingPlan;
  risk: Risk;
  receiptStyledFoot: boolean | undefined;
  displayNumberOfInstalments: boolean;
  policyReferenceID?: string;
  discountSubTotalLabel?: string;
  totalAnnualCostLabel?: string;
  receiptPaymentPlanLabel?: string;
}
