import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Product } from '@aventus/platform';
import usePlatform from '@aventus/platform-client-context/use-platform';

export const useGetProduct: UseGetProduct = function (
  productReference,
  productCoverReference,
  productID
) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const platform = usePlatform();

  const [product, setProduct] = React.useState<Product | undefined>(undefined);
  const [isGettingProduct, setIsGettingProduct] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    (async function () {
      if (productReference && productCoverReference) {
        setIsGettingProduct(true);

        try {
          if (!productID && !product) {
            const _product = await symphony.getProduct(
              productReference,
              productCoverReference
            );
            setProduct(_product);
          } else if (productID && !product) {
            const _product = await platform.product.getHybridProduct(productID);

            setProduct(_product);
          }
        } catch (error) {
          throwError(error);
        }

        setIsGettingProduct(false);
      }
    })();
  }, [productReference, productCoverReference]);

  return {
    product,
    isGettingProduct
  };
};

export type UseGetProduct = (
  productReference?: string,
  productCoverReference?: string,
  productID?: string
) => {
  product: Product | undefined;
  isGettingProduct: boolean;
};
