import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  BladeButton,
  BladeFormFieldset,
  BladeInputPassword,
} from '@aventus/blade';

import { AuthenticationConfiguration } from '@aventus/platform';

export type SetAccountPasswordFormData = {
  password: string;
};

type CreateAccountComponentProps = {
  onSubmit: (data: SetAccountPasswordFormData) => void;
  authenticationConfig: AuthenticationConfiguration;
  _flags: any; 
};

const CreateAccountComponent = ({
  onSubmit,
  authenticationConfig,
  _flags
}: CreateAccountComponentProps) => {
  const { control, errors, handleSubmit } = useForm<SetAccountPasswordFormData>({
    defaultValues: {}
  });


  return (
    <>
    <React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
        <BladeFormFieldset
            errorMessage={errors?.password && errors.password.message}
            addonBottom={
            authenticationConfig?.signUp?.passwordHelperText ||
            'Ensure your password includes at least 8 characters, upper and lower case letters and at least 1 number.'
            }
        >
            <Controller
            name="password"
            control={control}
            rules={{
                required: {
                value: true,
                message:
                    authenticationConfig?.signUp?.validationMessages
                    ?.passwordRequired || 'Password is required'
                },
                minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long'
                },
                validate: {
                hasNumbers: (value: string) =>
                    new RegExp('(?=.*[0-9])').test(value) ||
                    'Ensure your password includes at least one number',
                hasUppercase: (value: string) =>
                    new RegExp('(?=.*[A-Z])').test(value) ||
                    'Ensure your password includes at least one uppercase letter',
                hasLowercase: (value: string) =>
                    new RegExp('(?=.*[a-z])').test(value) ||
                'Ensure your password includes at least one lowercase letter'
            }
        }}
        render={({ onChange, onBlur, value }) => (
            <BladeInputPassword
            name="password"
            label={
                authenticationConfig?.postPurchaseAccountCreation?.setPasswordLabel ||
                'Choose a password'
            }
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            />
        )}
        />
    </BladeFormFieldset>
    <BladeButton id="sign-up-sign-up-button" 
        type="submit"
        isWorking={_flags.isCreatingAccountPassword}>
        {'Create Account'}
    </BladeButton>

    </form>
</React.Fragment>

    </>
  );
};

export default CreateAccountComponent;
