import React from 'react';
import styles from './styles.css';

const ProgressBar = () => (
  <>
    <h2 className={styles.centred}>Sounding Good?</h2>
    <h2 className={styles.centred}>Apply now in 5 easy steps!</h2>

    <div className={styles.progressBarContainer}>
      <div className={styles.currentStep}>
        <div>1</div>
        <div>Your duty</div>
      </div>

      <div className={styles.progressStep}>2</div>
      <div className={styles.progressStep}>3</div>
      <div className={styles.progressStep}>4</div>
      <div className={styles.progressStep}>5</div>
    </div>
  </>
);

export default ProgressBar;
