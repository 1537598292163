import {
  ITobesQuestion,
  ITobesQuestionMap
} from '@aventus/platform-client-context/models';
import { runValidation } from '@aventus/pocketknife/validator/filter';
import { convertArrayToObject } from '@aventus/pocketknife/convert-array-to-object';
import { runFieldValidation } from './Validation/fieldRules';

export const createQuestionMap = (
  question: ITobesQuestion
): ITobesQuestionMap => {
  const mapper = (question: ITobesQuestion): ITobesQuestionMap => ({
    ...question,
    fields: question.fields.map(createQuestionMap),
    validate: async (value: unknown, question: ITobesQuestionMap) => {
      const validationFromConfig = runValidation(
        question.validation.map(
          ({ referenceID }: { referenceID: string }) => referenceID
        ),
        convertArrayToObject(question.validation, 'referenceID'),
        true
      )(value);

      const fieldValidation = runFieldValidation(value, question);

      const validationResult = [
        ...fieldValidation,
        ...(Array.isArray(validationFromConfig)
          ? validationFromConfig
          : validationFromConfig !== true
          ? [validationFromConfig]
          : [])
      ];

      if (validationResult.length > 0) {
        return {
          result: false,
          errors: validationResult
        };
      }

      return {
        result: true,
        errors: []
      };
    },
    arrayPage:
      question.arrayPage !== null
        ? {
            ...question.arrayPage,
            questions: question.arrayPage.questions.map(createQuestionMap)
          }
        : null
  });

  return mapper(question);
};
