import * as React from 'react';
import css from './index.css';
import { BladeView, BladeActionList, BladeLoader } from '@aventus/blade';
import { PolicyDocumentsList, getDocuments } from '../../services/get-documents';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { RouteComponentProps } from 'react-router';
import { useGetPolicy } from '@aventus/symphony-client/hooks';
import { useEffect, useState } from 'react';

export const PolicyDocuments: React.FunctionComponent<IPolicyDocuments> = (
  props: IPolicyDocuments
) => {
  const { policy } = useGetPolicy(props.match.params.policyId.trim());
  const { toHumanReadable } = useApplicationTimezone();


  const [documents, setDocuments] = useState<PolicyDocumentsList>({ latest: undefined, previous: [] });

  useEffect(() => {
    policy && setDocuments(getDocuments(policy?.documents, toHumanReadable, policy?.policyReferenceID));
  }, [policy]);

  return (
    <BladeView variant={'s'}>
      <div className={css.documents}>
        <h2>Policy documents {policy?.policyReferenceID && `- ${policy.policyReferenceID}`}</h2>
        {!documents.latest && (<div className={css.loading}><BladeLoader /></div>)}
        {documents.latest && (
          <>
            <h5>Current policy</h5>
            <BladeActionList
              actions={documents.latest ? [documents.latest] : []}
            />
            <h5>Previous versions of policy</h5>
            <BladeActionList
              actions={documents.previous}
            />
          </>
        )}
      </div>
      
    </BladeView>
  );
};

export interface IPolicyDocuments extends RouteComponentProps<IPathParams> {}

interface IPathParams {
  policyId: string;
}
