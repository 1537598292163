import React from 'react'
import { BladeIcon } from '../../index';
import { useSelect } from 'downshift';
import cx from 'classnames';
import css from './index.css';

type TItem = {
  label: string,
  id: string,
}

const itemToString = (item: TItem | null) => (item ? item.label : '')

export const SimpleSelect: React.FC<IBladeInputSimpleSelect> = ({
  onChange = () => null,
  onBlur = () => null,
  ...props
}) => {
  const select = useSelect({
    items: props.options,
    selectedItem: props.value,
    onSelectedItemChange: (e) => onChange(e.selectedItem),
    itemToString,
  });

  const selectedItemString = itemToString(select.selectedItem);

  return (
    <div className={css.select}>
      <div className={css.select_wrap}>
        <button type="button" className={css.select_button} {...select.getToggleButtonProps()}>
          <div className={css.select_button_label}>
            {selectedItemString ? (
              <div>{selectedItemString}</div>
            ) : (
              <div className={css.select_placeholder}>{props.placeholder || "Please choose"}</div>
            )}
            <div className={css.select_input_icon_wrap}>
              {selectedItemString && (
                <BladeIcon
                  ignoreTriggerStyle
                  className={css.select_input_icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    select.closeMenu();
                    select.reset();
                  }}
                  name="faTimes"
                />
              )}
              <BladeIcon
                className={cx(css.select_input_icon, css.select_input_icon__last)}
                name={select.isOpen ? 'faCaretUp' : 'faCaretDown'}
              />
            </div>
          </div>
        </button>
        <ul className={css.select_list_wrap} {...select.getMenuProps({ onBlur })}>
          {select.isOpen && (props.options || []).map((item, index) => (
            <li
              className={cx(css.select_list_item, { highlighted: select.highlightedIndex === index })}
              key={`${item?.id}-${index}`}
              {...select.getItemProps({ item, index })}
            >
              {item?.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export interface IBladeInputSimpleSelect {
  onChange: (value: any) => void;
  onBlur?: any;
  value: any;
  options: any[];
  placeholder?: string;
}
