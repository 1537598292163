import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { CreditAgreement } from '@aventus/platform';

export const useGetPolicyCreditAgreement: useGetPolicyCreditAgreement = function(
  policyId
) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [policyCreditAgreement, setPolicyCreditAgreement] = React.useState<
    CreditAgreement | undefined
  >(undefined);
  const [
    isGettingPolicyCreditAgreement,
    setIsGettingPolicyCreditAgreement
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {
        setIsGettingPolicyCreditAgreement(true);

        try {
          const _policyCreditAgreement = await symphony.getPolicyCreditAgreement(
            policyId
          );
          setPolicyCreditAgreement(_policyCreditAgreement);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyCreditAgreement(false);
      }
    })();
  }, [policyId]);

  return {
    policyCreditAgreement,
    isGettingPolicyCreditAgreement
  };
};

export type useGetPolicyCreditAgreement = (
  policyId?: string
) => {
  policyCreditAgreement: CreditAgreement | undefined;
  isGettingPolicyCreditAgreement: boolean;
};
