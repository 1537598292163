import { Money } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { useGetQuote } from '@aventus/symphony-client/hooks';
import { useMemo } from 'react';

export const useLandingPageQuote = (quoteId: string) => {
  var { quoteBundle, isGettingQuoteBundle } = useGetQuote(quoteId);

  const quote = useMemo(() => {
    if (isGettingQuoteBundle || !quoteBundle) {
      return;
    }

    const formatPrice = (value: Money): string =>
      currencyToHumanReadable(value, { forceMinor: true });

    const monthlyPlan = quoteBundle?.requestedQuotePricing?.plans.find(
      plan => plan.numberOfInstallments === 12
    );

    const annualPlan = quoteBundle?.requestedQuotePricing?.plans.find(
      plan => plan.type === 'Annual'
    );

    if (!monthlyPlan || !annualPlan) {
      return null;
    }

    return {
      percentageSaving: monthlyPlan.paymentPlan.loadingPercentage * 100,
      adminFee: formatPrice(monthlyPlan.feesTotal),
      annualSaving: formatPrice(monthlyPlan.totalPayableDifference),
      firstMonthlyPayment: formatPrice(monthlyPlan.upfrontPrice),
      monthlyPrice: formatPrice(monthlyPlan.monthlyPrice),
      annualPrice: formatPrice(annualPlan.totalPayable.gross),
      registration: quoteBundle.risk.vehicle?.specification?.registration,
      stackedPriceDisplay: annualPlan.totalPayable.gross.value >= 1000000,
      partnerName: quoteBundle.requestedQuote.defaultPartner
        ? null
        : quoteBundle.requestedQuote.partnerName,
      id: quoteBundle.requestedQuote.id,
      partnerId: quoteBundle.requestedQuote.partnerID,
      productReferenceId: quoteBundle.requestedQuote.productReferenceID,
      coverType: quoteBundle.requestedQuote.coverType
    };
  }, [isGettingQuoteBundle, quoteBundle]);

  return {
    quote,
    error: quote === null
  };
};
