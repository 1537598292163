import * as React from 'react';
import { uppercaseFirstLetter } from '@aventus/pocketknife/uppercase-first-letter';
import google from './google.svg';
import css from './index.css';
import cx from 'classnames';

export const BladeButtonSocialAuth: React.FunctionComponent<IBladeButtonSocialAuth> = props => {
  return (
    <button
      className={cx(
        'blade-button-social-auth',
        css.buttonSocialAuth,
        props.variant === 'google' ? css.buttonSocialAuth_variant_google : ''
      )}
      onClick={() => {
        if (props.authUrl != null){
          window.location.href = props.authUrl;
        }
        else
        {
          props.signInFunc()
        }
      }}
    >
      <div className={css.buttonSocialAuth_icon}>
        {props.variant === 'google' && (
          <img className={css.buttonSocialAuth_logo} src={google} />
        )}
      </div>

      <span>
        {`${props.callToAction} ${uppercaseFirstLetter(props.variant)}`}
      </span>
    </button>
  );
};

export interface IBladeButtonSocialAuth {
  authUrl: string;
  variant: SocialAuthVariants;
  callToAction: string;
  signInFunc: () => void;
}

export type SocialAuthVariants = 'google';
