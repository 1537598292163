import { BladeSurface } from '@aventus/blade';
import { STELLA_ROADSIDE_IDS } from '@aventus/client-helpers/stella';
import { PricingPlan, RateableComponentFinalPrice } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { PricingConfiguration } from '@aventus/configuration';
import React from 'react';
import css from './index.css';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';

const MonthlyDisclaimer: React.FC<IMonthlyDisclaimerProps> = ({
  plan,
  pricingConfiguration,
  ...props
}) => {
  const config = pricingConfiguration.paymentSummaryDisclaimer
    ? pricingConfiguration.paymentSummaryDisclaimer
    : undefined;

  const hasFees = plan.fees?.length > 0;

  const hasStellaRoadside = plan.primaryRateableComponentFinalPrices.some(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );
  const stellaRoadsideRC = plan.primaryRateableComponentFinalPrices.find(
    (component: RateableComponentFinalPrice) =>
      STELLA_ROADSIDE_IDS.includes(component.referenceID)
  );

  const replacersMap = {
    $$_upfront_price: currencyToHumanReadable(plan.upfrontPrice),
    $$_monthly_price: currencyToHumanReadable(plan.monthlyPrice),
    $$_annual_saving: currencyToHumanReadable(plan.totalPayableDifference),
    ...(hasFees
      ? {
          $$_fee_amount: currencyToHumanReadable(plan.feesTotal)
        }
      : {}),
    ...(hasStellaRoadside && stellaRoadsideRC
      ? {
          $$_roadside_price: currencyToHumanReadable(
            stellaRoadsideRC.price.gross
          )
        }
      : {})
  };

  let disclaimer: string | undefined;

  if (hasFees) {
    disclaimer = config?.withFee;
    if (hasFees && hasStellaRoadside) {
      disclaimer = config?.withFeeAndStellaRoadside;
    }
  }

  if (disclaimer === undefined) {
    return null;
  }

  return (
    <BladeSurface shouldRespectViewWidth={props.shouldRespectViewWidth}>
      <div className={css.monthly_payment_summary_disclaimer}>
        <p className={css.monthly_payment_summary_disclaimer_text}>
          {stringReplacer(disclaimer.trim(), replacersMap)}
        </p>
      </div>
    </BladeSurface>
  );
};

interface IMonthlyDisclaimerProps {
  plan: PricingPlan;
  pricingConfiguration: PricingConfiguration;
  shouldRespectViewWidth?: boolean;
}

export default MonthlyDisclaimer;
