import * as React from 'react';
import { BladeLink } from '@aventus/blade';
import { BladeFormFieldset, IBladeFormFieldset } from '../../index';
import configuration from './configuration.json';

export const AddressInternationalManual: React.FunctionComponent<IAddressInternationalManual> = props => {
  // We only support specific configuration for a handful of the major countries,
  // treating all the others with an umbrella 'rest of the world' configuration,
  // denoted by '*',
  // See https://www.edq.com/documentation/apis/address-validate/global-intuitive/
  // for more details.

  let selectedCountry = configuration.supportedCountries.includes(
    props.countryCode
  )
    ? props.countryCode
    : '*';

    const supportedFields:[{question: string, reference: string}] = configuration[selectedCountry]

    let acceptedNodes: React.ReactNode[] = [];
    React.Children.forEach(props.children, (input: any)=> {

      if(supportedFields.filter(x=> x.reference === input.key).length > 0){
        acceptedNodes.push(input)
      }
    })

  const fieldsetInputs = React.Children.map(
    acceptedNodes,
    (input: any, i: number) =>
      React.cloneElement(input, {
        ...configuration[selectedCountry][i]
      })
  );

  return (
    <BladeFormFieldset {...props}>
      {fieldsetInputs}
      <BladeLink isSmall onClick={props.enterWithLookup}>Back to address lookup</BladeLink>
    </BladeFormFieldset>
  );
};

interface IAddressInternationalManual extends IBladeFormFieldset {
  isGroup?: boolean;
  countryCode: string;
  enterWithLookup: any;
  renderOracle: any;
}
