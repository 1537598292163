import { initializeApp, getApp } from "firebase/app";

export const createFirebaseApp = function(fbConfig: any){
    try {
        getApp();
    } catch {
        const firebaseConfig = {
            apiKey: fbConfig.apiKey,
            authDomain: fbConfig.authDomain,
            projectId: fbConfig.projectID,
            messagingSenderId: fbConfig.messagingSenderID,
            appId: fbConfig.appID
        };
        initializeApp(firebaseConfig);
    }
};