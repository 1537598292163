import * as React from 'react';

import {
  AdditionalComponentPrices,
  IntelligentQuoteQuestion
} from '@aventus/platform';

import PackageButtons from './package-buttons';
import PackageFooter from './package-footer';
import PackageInfoText from './package-info';
import PackageMoreInfoModal from './package-more-info-modal';
import PackagePanel from './package-panel';
import { usePackageSelector } from './use-package-selector';

export interface IPackageSelector extends IBladeInput {
  questionData: IntelligentQuoteQuestion;
  additionalComponentPrices?: AdditionalComponentPrices;
  children: React.ReactNode;
  selectedInOriginalRisk: boolean | null;
  isLoading: boolean;
  childComponentWasUpdated: boolean;
  setChildComponentWasUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PackageSelector = (props: IPackageSelector) => {
  const packageSelectedValue = Boolean(props.value || false);

  const {
    canBeRemoved,
    setShowMoreInfo,
    showMoreInfo,
    packageLimit,
    packagePrice,
    packageIsSelected,
    packageIsBeingInteractedWith,
    packageIsBeingAdded,
    packageIsBeingRemoved,
    handlePackageClick,
    childComponentUpdated
  } = usePackageSelector(
    props.questionData,
    packageSelectedValue,
    props.selectedInOriginalRisk,
    props.isLoading,
    props.onChange,
    props.additionalComponentPrices,
    props.childComponentWasUpdated,
    props.setChildComponentWasUpdated
  );

  return (
    <PackagePanel
      questionReferenceID={props.questionData.referenceID}
      packageIsSelected={packageIsSelected}
      questionText={props.questionData.text.questionText}
    >
      <PackageInfoText
        packageDescription={props.questionData.text.summaryText}
        onMoreInfoClick={() => setShowMoreInfo(true)}
      />

      <PackageFooter
        canBeRemoved={
          canBeRemoved && packageSelectedValue && !packageIsBeingInteractedWith
        }
        hasMoreInfo={props.questionData.text.summaryText !== null}
        onMoreInfoClick={() => setShowMoreInfo(true)}
        packageLimit={packageLimit}
        unselectPackage={() => handlePackageClick(false)}
        packageSelected={packageIsSelected}
        limitControls={props.children}
      />

      <PackageButtons
        canBeRemoved={canBeRemoved}
        packageSelected={packageIsSelected}
        setPackageSelected={handlePackageClick}
        packagePrice={packagePrice}
        showAddButton={!packageIsSelected}
        packageWasInteractedWith={packageIsBeingInteractedWith}
        packageIsBeingAdded={packageIsBeingAdded}
        packageIsBeingRemoved={packageIsBeingRemoved}
        priceIsUpdating={props.isLoading}
        childComponentUpdated={childComponentUpdated}
      />

      {showMoreInfo && (
        <PackageMoreInfoModal
          title={props.questionData.text.questionText}
          summaryText={props.questionData.text.summaryText}
          detailText={props.questionData.text.detailText}
          onClose={() => setShowMoreInfo(false)}
        />
      )}
    </PackagePanel>
  );
};
