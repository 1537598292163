import React from 'react';
import styles from './styles.css';

interface HeaderProps {
  partnerName: string | null;
  registration: string;
}

const Header = (props: HeaderProps) => (
  <>
    <div
      className={`${styles.header} ${
        !props.registration ? styles.noRego : null
      }`}
    >
      <h1>
        Your Quick Quote
        {props.partnerName && (
          <>
            &nbsp;from
            <br />
            {props.partnerName}
          </>
        )}
      </h1>

      {props.registration && (
        <div className={styles.rego}>{props.registration}</div>
      )}
    </div>
  </>
);

export default Header;
