// Taken form https://github.com/sl-julienamblard/email-misspelled/
export const live: string[] = [
	"windowslive.com",
	"live.com.ar",
	"live.com.au",
	"live.at",
	"live.be",
	"live.com",
	"live.ca",
	"live.cl",
	"live.cn",
	"live.dk",
	"live.fi",
	"live.fr",
	"live.de",
	"live.hk",
	"live.in",
	"live.ie",
	"live.it",
	"live.jp",
	"live.co.kr",
	"live.com.my",
	"live.com.mx",
	"live.nl",
	"live.no",
	"live.com.ph",
	"live.com.pt",
	"live.ru",
	"live.com.sg",
	"live.co.za",
	"live.se",
	"livemail.tw",
	"live.co.uk",
]

export default live
