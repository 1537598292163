import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import css from './index.css';
import cx from 'classnames';

const _BladeLink: React.FunctionComponent<IBladeLink> = props => {
  if (props.isExternal) {
    return (
      <a
        href={props.url}
        target={'_blank'}
        rel="noreferrer"
        className={cx(
          'blade-link',
          css.link,
          props.className,
          props.isSmall ? css.link_isSmall : ''
        )}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <a
        style={props.style}
        onClick={() => {
          if (props.url && props.url.includes('@') && props.onClick) {
            props.onClick(props.url.replace('@', ''));
          } else {
            if (props.url) {
              props.history.push(props.url);
            } else {
              props.onClick && props.onClick();
            }
          }
        }}
        className={cx(
          'blade-link',
          props.className,
          css.link,
          props.isSmall ? css.link_isSmall : ''
        )}
      >
        {props.children}
      </a>
    );
  }
};

export const BladeLink = withRouter(_BladeLink);

export interface IBladeLink extends RouteComponentProps {
  style?: any;
  /** If set to `true`, the component will assume the link goes out to an external domain, rendering the link as an `<a />`. */
  isExternal?: boolean;
  /**
    The url of the link in question. A link could be:
    * External, which requires the above flag;
    * Internal route, which requires no extra props;
    * Internal action, which needs the `onClick` defined and the url prepended with `@`
    */
  url?: string;
  /** Triggered by simply being defined, bypassing routing, or with an interal action. */
  onClick?: (url?: string) => void;
  /** Control the guided variant of the link text, based on the typography key. */
  size?: string;
  className?: string;
  isSmall?: boolean;
}
