import { ITobesQuestionMap } from '@aventus/platform-client-context/models/tobes';
import { createRefKey } from '..';
import { TobesValidationMap } from '../../store/form-state';

export const validateQuestions = (questions: ITobesQuestionMap[]) => {
  const validator = (
    questions: ITobesQuestionMap[],
    parentReferenceID?: string,
    validationMap?: TobesValidationMap
  ): Promise<TobesValidationMap> =>
    questions.reduce(async (a: Promise<TobesValidationMap>, question) => {
      const validationMap = await a;

      const current = await question.validate(question.existingValue, question);
      const key = createRefKey(question.referenceID, parentReferenceID);

      if (current.result === false) {
        validationMap.set(key, current);
      }

      if (question.fields.length > 0) {
        await validator(question.fields, key, validationMap);
      }

      return validationMap;
    }, Promise.resolve(validationMap || new Map()));

  return validator(questions);
};
