import * as React from 'react';

import {
  IntelligentQuoteBoundary,
  IIntelligentQuoteBoundary
} from './boundary';

import {
  IntelligentQuoteRedirect,
  IIntelligentQuoteRedirect
} from './views/intelligent-quote-redirect';
import {
  IntelligentQuoteAsk,
  IIntelligentQuoteAsk
} from './views/intelligent-quote-ask';
import {
  IntelligentQuoteSummary,
  IIntelligentQuoteSummary
} from './views/intelligent-quote-summary';
import {
  IntelligentQuoteSummaryRedirect,
  IIntelligentQuoteSummaryRedirect
} from './views/intelligent-quote-summary-redirect';
import {
  IntelligentQuoteAdjustOverview,
  IIntelligentQuoteAdjustOverview
} from './views/intelligent-quote-adjust-overview';
import {
  IntelligentQuoteAdjustOverviewRedirect,
  IIntelligentQuoteAdjustOverviewRedirect
} from './views/intelligent-quote-adjust-overview-redirect';
import {
  IntelligentQuickQuote,
  IIntelligentQuickQuote
} from './views/intelligent-quick-quote';
import { VriSummary, IVriSummary } from './views/vri-summary';
import {
  VriSummaryRedirect,
  IVriSummaryRedirect
} from './views/vri-summary-redirect';
import { VriMissingData, IVriMissingData } from './views/vri-missing-data';
import {
  VriMissingDataRedirect,
  IVriMissingDataRedirect
} from './views/vri-missing-data-redirect';
import { VriDeclined, IVriDeclined } from './views/vri-declined';
import {
  VriDeclinedRedirect,
  IVriDeclinedRedirect
} from './views/vri-declined-redirect';

export { useIntelligentQuoting } from './hooks/use-intelligent-quoting';
export type { UseIntelligentQuoting } from './hooks/use-intelligent-quoting';

export const MvmtIntelligentQuoteRedirect = (
  props: IIntelligentQuoteRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteRedirect {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuoteAsk = (
  props: IIntelligentQuoteAsk & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteAsk {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuoteSummary = (
  props: IIntelligentQuoteSummary & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteSummary {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuoteSummaryRedirect = (
  props: IIntelligentQuoteSummaryRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteSummaryRedirect {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuoteAdjustOverview = (
  props: IIntelligentQuoteAdjustOverview & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteAdjustOverview {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuoteAdjustOverviewRedirect = (
  props: IIntelligentQuoteAdjustOverviewRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuoteAdjustOverviewRedirect {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtIntelligentQuickQuote = (
  props: IIntelligentQuickQuote & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <IntelligentQuickQuote {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriSummary = (
  props: IVriSummary & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriSummary {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriSummaryRedirect = (
  props: IVriSummaryRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriSummaryRedirect {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriMissingData = (
  props: IVriMissingData & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriMissingData {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriMissingDataRedirect = (
  props: IVriMissingDataRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriMissingDataRedirect {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriDeclined = (
  props: IVriDeclined & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriDeclined {...props} />
  </IntelligentQuoteBoundary>
);

export const MvmtVriDeclinedRedirect = (
  props: IVriDeclinedRedirect & IIntelligentQuoteBoundary
) => (
  <IntelligentQuoteBoundary goHome={props.goHome} goToOrigin={props.goToOrigin}>
    <VriDeclinedRedirect {...props} />
  </IntelligentQuoteBoundary>
);
