import * as React from 'react';
import { GlassesRecoverableError } from '../errors/glasses-recoverable-error';
import { GlassesUnrecoverableError } from '../errors/glasses-unrecoverable-error';
import { IBoundary, IBoundaryState } from './types';

export class Boundary extends React.Component<IBoundary, IBoundaryState> {

  constructor (props: any) {

    super(props);

    this.state = {
      recoverableError: null,
      unrecoverableError: null
    };

  }

  componentDidCatch (error: any) {

    if (error instanceof GlassesRecoverableError) {
      this.setState({ recoverableError: error });
    };

    if (error instanceof GlassesUnrecoverableError) {
      this.setState({ unrecoverableError: error });
    }

    // TODO
    // This should throw the error further up, so it can
    // be caught by the top-level boundary and log the error,
    // however there's a weirdness with React which unmounts
    // on catch, and so it's rendering a white page since it's
    // unmounting at the top level. Need to either figure out a
    // way to pass the log function and use it here directly, or
    // throw it further up the chain preserving the component.
    // Another option is to not use the inline error component,
    // but the use the global toast error component which
    // overlays.

  }

  render () {
    return (
      React.Children.map(
        this.props.children,
        (child: any) => (
          React.cloneElement(
            child,
            {
              error: {
                recoverableError: this.state.recoverableError,
                unrecoverableError: this.state.unrecoverableError,
                clearErrors: () => this.setState({ recoverableError: null, unrecoverableError: null })
              }
            }
          )
        )
      )
    );
  }

}
