import {
  BladeFormFieldset,
  BladeInputRadio,
  BladeInputSegmented,
  BladeInputSwitch
} from '@aventus/blade';
import {
  ICommonFieldProps,
  ICommonInputProps
} from '@aventus/mvmt-simplequote/models/commonProps';

import { ITobesQuestion } from '@aventus/platform-client-context/models';
import { PanelLayout } from '@aventus/blade/form-fieldset';
import React from 'react';

export function renderBoolean(
  question: ITobesQuestion,
  commonFieldsetProps: ICommonFieldProps,
  commonInputProps: ICommonInputProps
) {
  if (['yesno', 'CorrectIncorrect',  'ConfirmDoNotConfirm'].includes(question.questionType)) {
    let options = [
      {
        value: true,
        text: 'Yes'
      },
      {
        value: false,
        text: 'No'
      }
    ];

    switch (question.questionType) {
      case 'CorrectIncorrect':
        options = [
          {
            value: true,
            text: 'Correct'
          },
          {
            value: false,
            text: 'Incorrect'
          }
        ];
        break;
      case 'ConfirmDoNotConfirm':
        options = [
          {
            value: true,
            text: 'Confirm'
          },
          {
            value: false,
            text: 'Do not confirm'
          }
        ];
        break;
    }

    return (
      <BladeFormFieldset {...commonFieldsetProps}>
        <BladeInputSegmented
          {...commonInputProps}
          isYesNo={true}
          isInline={true}
          options={options}
          matchOn={'value'}
        />
      </BladeFormFieldset>
    );
  }

  if (question.questionType === 'boxboolean') {
    return (
      <BladeFormFieldset isPanel={PanelLayout.ThreeColumns} {...commonFieldsetProps}>
        <BladeInputSwitch
          {...commonInputProps}
          label={question.text.footer}
          variant={'no-frame'}
        />
      </BladeFormFieldset>
    );
  }

  if (question.questionType === 'radio') {
    return (
      <BladeFormFieldset {...commonFieldsetProps}>
        <BladeInputRadio {...commonInputProps} />
      </BladeFormFieldset>
    );
  }

  return (
    // if no question type has been defined for date then use string
    <BladeFormFieldset {...commonFieldsetProps}>
      <BladeInputSwitch
        {...commonInputProps}
        label={question.text.footer}
        variant={'no-frame'}
      />
    </BladeFormFieldset>
  );
}
