import * as React from 'react';

export class DocumentsBoundary extends React.Component<
  IDocumentsBoundary,
  IDocumentsBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, redirect: false };
  }

  render() {
    return this.props.children;
  }
}

export interface IDocumentsBoundary {}

export interface IDocumentsBoundaryState {
  error: boolean | null;
  redirect: boolean;
}
