import React from 'react';
import { PolicyBoundary, IPolicyBoundary } from './boundary';

import {
  PolicyCancelAgentRequired,
  IPolicyCancelAgentRequired
} from './views/policy-cancel-agent-required';
import {
  PolicySummaryOfCover,
  IPolicySummaryOfCover
} from './views/policy-summary-of-cover';
import {
  PolicySummaryOfCoverBasic,
  IPolicySummaryOfCoverBasic
} from './views/policy-summary-of-cover-basic';
import { PolicySummary, IPolicySummary } from './views/policy-summary';
import { PolicyClaimInfo, IPolicyClaimInfo } from './views/policy-claim-info';
import { PolicyDocuments, IPolicyDocuments } from './views/policy-documents';
import {
  PolicySummaryRedirect,
  IPolicySummaryRedirect
} from './views/policy-summary-redirect';
import { PolicyCancel, IPolicyCancel } from './views/policy-cancel';
import { PolicyRenew, IPolicyRenew } from './views/policy-renew';

export { YourPolicy } from './components/your-policy';
export type { IYourPolicy } from './components/your-policy';
export { YourDetails } from './components/your-details';
export type { IYourDetails } from './components/your-details';
export { PolicyList } from './components/policy-list';
export type { IPolicyList } from './components/policy-list';

export const MvmtPolicyRenew = (props: IPolicyRenew & IPolicyBoundary) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicyRenew {...props} />
  </PolicyBoundary>
);

export const MvmtPolicyCancelAgentRequired = (
  props: IPolicyCancelAgentRequired & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicyCancelAgentRequired {...props} />
  </PolicyBoundary>
);

export const MvmtPolicySummaryOfCover = (
  props: IPolicySummaryOfCover & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicySummaryOfCover {...props} />
  </PolicyBoundary>
);

export const MvmtPolicySummaryOfCoverBasic = (
  props: IPolicySummaryOfCoverBasic & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicySummaryOfCoverBasic {...props} />
  </PolicyBoundary>
);

export const MvmtPolicySummary = (props: IPolicySummary & IPolicyBoundary) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicySummary {...props} />
  </PolicyBoundary>
);

export const MvmtPolicyClaimInfo = (
  props: IPolicyClaimInfo & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicyClaimInfo {...props} />
  </PolicyBoundary>
);

export const MvmtPolicyDocuments = (
  props: IPolicyDocuments & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicyDocuments {...props} />
  </PolicyBoundary>
);

export const MvmtPolicySummaryRedirect = (
  props: IPolicySummaryRedirect & IPolicyBoundary
) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicySummaryRedirect {...props} />
  </PolicyBoundary>
);

export const MvmtPolicyCancel = (props: IPolicyCancel & IPolicyBoundary) => (
  <PolicyBoundary goHome={props.goHome}>
    <PolicyCancel {...props} />
  </PolicyBoundary>
);
