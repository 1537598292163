export function maxValue(
  dynamicValidationValue: string
): (value: number) => string | undefined {
  if (isNaN(Number(dynamicValidationValue)))
    throw new Error('maxValue validator configured without a valid number');

  const parsedMaxValue = Number(dynamicValidationValue);
  const validationMessage = `Please enter a value less than ${parsedMaxValue.toLocaleString()}`;

  return (value: any) => {
    const isMoney = value.hasOwnProperty('value') && value.hasOwnProperty('currencyCode')
    const sourceValue = isMoney ? value.value : value;
    const numberValue = Number(sourceValue);

    if (isNaN(numberValue)) return validationMessage;
    if (isMoney) {
      if(numberValue > parsedMaxValue*100) return validationMessage;
    } else {
      if (numberValue > parsedMaxValue) return validationMessage;
    }

    return undefined;
  };
}
