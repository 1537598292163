import * as React from 'react';
import { ApplicationInterfaceContext } from './context';
import { useAreAtomsEqual } from '@aventus/honey-utility-react';

export const ApplicationInterfaceProvider: React.FunctionComponent<IApplicationInterfaceProvider> = props => {
  const { areAtomsEqual: areBackgroundAndSurfaceEqual } = useAreAtomsEqual(
    '--background',
    '--surface'
  );

  return (
    <ApplicationInterfaceContext.Provider
      value={{
        designSystem: {
          areBackgroundAndSurfaceEqual
        }
      }}
    >
      {props.children}
    </ApplicationInterfaceContext.Provider>
  );
};

interface IApplicationInterfaceProvider {}
