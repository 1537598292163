import * as React from 'react';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { RouteComponentProps, Redirect } from 'react-router';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const VriDeclinedRedirect: React.FunctionComponent<IVriDeclinedRedirect> = props => {
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  if (organisationConfiguration) {
    const productReference: ProductReference = `${organisationConfiguration?.reference}_pr_${props.match.params.lineOfBusiness}`;
    const productCoverReference: ProductCoverReference =
      props.match.params.coverType;

    const path = props.getVriDeclinedPath(
      productReference,
      productCoverReference
    );

    const pathDeconstructed = path.split('?');

    return (
      <Redirect
        to={{
          pathname: pathDeconstructed[0],
          search: `?${pathDeconstructed[1]}`,
          state: props.location.state
        }}
      />
    );
  }

  return null;
};

export interface IVriDeclinedRedirect extends RouteComponentProps<{
  productId: string;
  lineOfBusiness: string;
  coverType: string;
}> {
  getVriDeclinedPath: (
    productReference: string,
    productCoverReference: string
  ) => string;
}
