import * as React from 'react';
import css from './index.css';
import cx from 'classnames';
import { BladeLink, BladeIcon } from '@aventus/blade';
import { isEmpty } from 'lodash';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { PolicyDocument } from '@aventus/platform';
import { BladeDocumentsGroup } from './document-group';

export const BladeDocuments: React.FunctionComponent<IBladeDocuments> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  const isDocumentsEmpty = isEmpty(props.documents);

  if (!props.documentsGrouped) {
    return (
      <div className={css.listDocuments}>
        <h5 className={css.listDocumentsTitle}>{props.title}</h5>
        <div className={css.listDocumentsContent}>
          {!isDocumentsEmpty ? (
            (props.documents as PolicyDocument[])?.map(
              (document: PolicyDocument | IBladeDocument, i: number) => (
                <div key={i} className={css.listDocumentsElem}>
                  <div className={css.listDocumentsElemTitleWrapper}>
                    <span className={css.listDocumentsElemSubTitle}>
                      {(document?.dateCreated &&
                          toHumanReadable(document?.dateCreated))}
                    </span>
                    <span>{document?.name || document.filename}</span>
                  </div>
                  <BladeLink
                    className={css.listDocumentsElemUrl}
                    url={document.documentUrl}
                    isExternal={true}
                  >
                    {props.openMessage || 'Open'}
                    <BladeIcon
                      className={css.listDocumentsElemIcon}
                      name={'faExternalLink'}
                    />
                  </BladeLink>
                </div>
              )
            )
          ) : (
            <div className={css.listDocumentsEmpty}>
              {props.emptyMessage || 'Sorry there are no documents available'}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.listDocuments}>
        <h5 className={css.listDocumentsTitle}>{props.title}</h5>
        <div className={css.listDocumentsContent}>
          {props.documentsGrouped ? (
            Object.keys(props.documentsGrouped).map(documentGroup => (
              <BladeDocumentsGroup groupTitle={documentGroup}>
                {props.documentsGrouped &&
                  (props.documentsGrouped[documentGroup] as any)?.map(
                    (document: PolicyDocument | IBladeDocument, i: number) => (
                      <div
                        key={i}
                        className={cx(
                          css.listDocumentsElem,
                          css.listDocumentsElemInside
                        )}
                      >
                        <div className={css.listDocumentsElemTitleWrapper}>
                          <span className={css.listDocumentsElemSubTitle}>
                            {(document?.dateCreated &&
                                toHumanReadable(document?.dateCreated))}
                          </span>
                          <span>{document?.name || document.filename}</span>
                        </div>
                        <BladeLink
                          className={css.listDocumentsElemUrl}
                          url={document.documentUrl}
                          isExternal={true}
                        >
                          {props.openMessage || 'Open'}
                          <BladeIcon
                            className={css.listDocumentsElemIcon}
                            name={'faExternalLink'}
                          />
                        </BladeLink>
                      </div>
                    )
                  )}
              </BladeDocumentsGroup>
            ))
          ) : (
            <div className={css.listDocumentsEmpty}>
              {props.emptyMessage || 'Sorry there are no documents available'}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export interface IBladeDocuments {
  title: string;
  documentsGrouped?: {
    [key: string]: PolicyDocument[] | IBladeDocument[] | [];
  };
  documents?: PolicyDocument[] | IBladeDocument[] | [];
  emptyMessage?: string;
  openMessage?: string;
}

type IBladeDocument = {
  policyDocumentID: string;
  version: number;
  referenceID: string;
  documentUrl: string;
  policyDocumentStatus: string;
  fileType: string;
  fileExtension: string;
  dateCreated?: string;
  filename: string;
  name?: string;
};
