import * as React from 'react';

export function useFontFace(fonts: BladeFontFace[]): void {
  React.useEffect(() => {
    if (fonts && fonts.length > 0) {
      const style = document.createElement('style');

      const theCSS = fonts.reduce((fontFaceCSS, font: BladeFontFace) => {
        const _fontFaceCSS = font.srcs.reduce((srcCSS, src) => {
          // We need to do some handling here to ensure
          // if a numeric weight was defined, then we dont
          // wrap with the "".

          let weightValue: string | number | undefined;

          if (src.weight && isNaN(parseInt(src.weight))) {
            weightValue = `"${src.weight}"`;
          } else {
            weightValue = src.weight;
          }

          return `
${srcCSS}

@font-face {
  font-family: "${font.name}";
  font-weight: ${weightValue || '"normal"'};
  font-style: "${src.style || 'normal'}";
  src: url("${src.src}.eot");
  src:
    url("${src.src}.eot?#iefix") format('embedded-opentype'),
    url("${src.src}.woff2") format('woff2'),
    url("${src.src}.woff") format('woff'),
    url("${src.src}.ttf") format('truetype');
}`;
        }, '');

        return `
${fontFaceCSS}
${_fontFaceCSS}
            `;
      }, '');

      style.id = 'BladeFontFace';
      style.innerHTML = theCSS;
      document.getElementsByTagName('head')[0].append(style);
    }
  }, [fonts]);
}

export interface UseFontFace {}
