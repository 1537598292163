import {
  AUTH_SIGN_IN_REQUEST,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_IN_FAILURE
} from './types';
import { AUTH0_API_SYM } from '../../../../middleware/auth-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import validateMeAction from '../validate-me/actions';
import clearAllAlertsAction from '../../core/alerts/clear-all-alerts/actions';
import assignQuoteToUserAction from '../../core/guest-quotes/assign-quote-to-user/actions';

/**
 * param: isChained=Bool
 * We needed to use this bool `chained` to be able to represent
 * whether this function was triggered directly or it was triggered
 * by another action that was triggered (ie: it's chained).
 * The reason we need this is to conditionally perform certain
 * actions based on whether it is chained or not.
 */
export default function signInAction(username, password, isChained) {
  // https://auth0.com/docs/api/authentication#resource-owner-password
  return (dispatch, getStore) => {
    const store = getStore();

    const { audience, connections } = store.app.core.config.auth0;

    dispatch({
      type: 'AUTH0_API_REQUEST',
      [AUTH0_API_SYM]: {
        requestConfig: {
          url: 'oauth/token',
          method: 'POST',
          data: {
            grant_type: 'http://auth0.com/oauth/grant-type/password-realm', // password
            realm: connections.form,
            username,
            password,
            audience,
            scope: 'openid profile email'
          }
        },
        actions: [
          signInRequestAction,
          signInSuccessAction,
          signInFailureAction
        ],
        passToAction: { username, isChained }
      }
    });
  };
}

export function signInRequestAction() {
  return {
    type: AUTH_SIGN_IN_REQUEST
  };
}

export function signInSuccessAction(res, { username, isChained }) {
  const data = {
    access_token: res,
    username
  };

  return dispatch => {
    dispatch(clearAllAlertsAction());
    dispatch({ type: AUTH_SIGN_IN_SUCCESS, data });
    dispatch(validateMeAction({ fromSignIn: !isChained ? true : false }));
    dispatch(assignQuoteToUserAction());
  };
}

export function signInFailureAction(errorMessage) {
  return dispatch => {
    dispatch({ type: AUTH_SIGN_IN_FAILURE, errorMessage });
    if (errorMessage !== 'NO_ERROR_MESSAGE') {
      const defaultErrorMessage = 'Incorrect email or password';
      dispatch(
        queueAlertAction({
          type: 'error',
          message: errorMessage || defaultErrorMessage,
          location: true,
          dissmissable: false
        })
      );
    }
  };
}
