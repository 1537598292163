import * as React from 'react';
import css from './index.css';
import { BladePage, BladePageCover, BladeView } from '@aventus/blade';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';

export const ProductCustomDashboardError: React.FunctionComponent<IProductCustomDashboardError> = () => {
  const { applicationConfiguration } = useGetApplicationConfiguration();
  return (
    <BladePageCover
      pageBackground={
        applicationConfiguration?.customDashboardSettings?.dashboardImage || ''
      }
      pageBackgroundColor={
        applicationConfiguration?.customDashboardSettings?.dashboardColor || ''
      }
    >
      <BladePage viewWidth={'dashboard'} title={'Dashboard'}>
        <BladeView className={css.productDashboardCustom} variant="s">
          <div className={css.productDashboardCustomError}>
            There was a problem loading your policies. Please try again.
          </div>
        </BladeView>
      </BladePage>
    </BladePageCover>
  );
};

export interface IProductCustomDashboardError {}
