import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { Policy } from '@aventus/platform';

export const useGetPolicies = (activeOnly: boolean = true) => {
  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();

  const [policies, setPolicies] = React.useState<Policy[] | undefined>(
    undefined
  );
  const [policiesCount, setPoliciesCount] = React.useState<number | undefined>(
    undefined
  );
  const [isGettingPolicies, setisGettingPolicies] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    (async function () {
      setisGettingPolicies(true);

      try {
        const _policies = await symphonyClient.getPolicies(activeOnly);
        setPolicies(_policies);
        setPoliciesCount(_policies.length);
      } catch (error) {
        throwError(error);
      }

      setisGettingPolicies(false);
    })();
  }, []);

  return {
    policies,
    policiesCount,
    isGettingPolicies
  };
};
