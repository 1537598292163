import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MvmtUpdateCardDetails } from '@aventus/mvmt-checkout';

export const UpdateCardDetails: React.FunctionComponent<IUpdateCardDetails> = (props) => {

  const getLoginRoute = () => `/sign-in`;
  return (
    <MvmtUpdateCardDetails
      policyId={ props.match.params.policyId }
      onFinish={(paymentMethod, message) => {
        props.history.push(
          `/policy/${ props.match.params.policyId }`,
          {
            success: {
              detail: `Your policy has been successfully updated with the card ending in ${ paymentMethod.last4Digits }.\r\n\r\n${message}`
            }
          }
        )
      }}
      goHome={() => props.history.push('/dashboard')}
      goToLogin={() => {
        props.history.push(getLoginRoute(), {
          origin: props.location.pathname,
          isMidFlowAuth: true,
          onSuccessGoToRoute: props.location.pathname
        });
      }}
      />
  );
};


interface IUpdateCardDetails extends RouteComponentProps<{
  policyId: string
}> {

}
