import update from 'immutability-helper';

export default function queueAlertReducer(state, action) {
  const { alert } = action;
  const { alertsQueue } = state.core.alerts;

  const isDuplictateAlert = alertsQueue.filter(
    (alertInQ) => ((alertInQ.id === alert.id) || (alertInQ.message === alert.message))
  ).length > 0;

  if (isDuplictateAlert) {
    return state;
  }


  return {
    ...state,
    ...{
      core: update(state.core, {
        alerts: {
          alertsQueue: { $push: [alert] }
        }
      })
    }
  };
}
