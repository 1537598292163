import moment, { Moment } from 'moment';

function noFutureDates(date: string): boolean | string {
  const formats = [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
    'YYYY-MM-DDT00:00:00',
    'YYYY-MM-DDT00:00:00.0000000'
  ];
  const today = moment();
  const toValidate = moment(date, formats, true);

  const validDate = (date: string | Moment): boolean => {
    return moment(date).isValid();
  };

  if (validDate(toValidate) !== true) {
    return 'Invalid date';
  }

  return today.diff(toValidate) >= 0 ? true : 'Date cannot be in the future';
}

export default noFutureDates;
