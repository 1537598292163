import * as React from 'react';
import { BladeView } from '../../view';
import { BladeAppBarPhone } from './phone';
import { useRunner } from '../../runner';
import css from './index.css';
import cx from 'classnames';

import { IBladeAppBar } from '../'

export const Default: React.FunctionComponent<IBladeAppBar> = props => {
  const { space } = useRunner();
  var ref = document.referrer;
  let phoneNumber = props.phone?.number;
  let phoneLabel = props.phone?.label;
  if(ref.includes("comparethemarket.com.au")){
    phoneNumber = props.phone?.ctmNumber;
    phoneLabel = props.phone?.ctmLabel;
  }
  else if (ref.includes("iselect.com.au")){
    phoneNumber = props.phone?.iSelectNumber;
    phoneLabel = props.phone?.iSelectLabel;
  }
  return (
    <div className={cx('blade-app-bar', css.appBar, props.className)}>
      <BladeView variant={props.viewVariant || 's'} className={css.appBar_view}>
        {props.phone?.label && props.phone?.number && space === 'limited' && (
          <div className={css.appBar_phone}>
            <BladeAppBarPhone phone={props.phone} />
          </div>
        )}

        <div className={css.appBarContent}>
          {props.renderLeft}
          {props.renderCenter}

          <div className={css.appBarContent_right}>
            {phoneLabel &&
              phoneNumber &&
              space === 'comfortable' && (
                <BladeAppBarPhone phone={{label:phoneLabel, number:phoneNumber}} />
              )}

            <div className={css.appBarContent_right_render}>
              {props.renderRight}
            </div>
          </div>
        </div>
      </BladeView>
    </div>
  );
};
