import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { PaymentMethod } from '@aventus/platform';

export const useGetPolicyPaymentMethod = (policyId: string) => {

  const symphonyClient = useSymphonyClient();
  const { throwError } = useError();

  const [ policyPaymentMethod, setPolicyPaymentMethod ] = React.useState<PaymentMethod | undefined>(undefined);
  const [ isGettingPolicyPaymentMethod, setIsGettingPolicyPaymentMethod ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {
      if (policyId) {

        setIsGettingPolicyPaymentMethod(true);

        try {
          const _policyPaymentMethod = await symphonyClient.getPolicyPaymentMethod(policyId);
          setPolicyPaymentMethod(_policyPaymentMethod);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyPaymentMethod(false);
      }
    })();
  }, [policyId]);

  return {
    policyPaymentMethod,
    isGettingPolicyPaymentMethod
  };

};
