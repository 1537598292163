import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const IntelligentQuoteRedirect: React.FunctionComponent<IIntelligentQuoteRedirect> = props => {
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const productReference: ProductReference = `${organisationConfiguration?.reference}_pr_${props.match.params.lineOfBusiness}`;
  const productCoverReference: ProductCoverReference =
    props.match.params.coverType;
  const quoteId: string = queryParamGet(props.location.search, 'quoteId');

  let type;
  switch (props.match.params.routeAction) {
    case 'quote':
      type = 'new';
      break;
    case 'vri':
      type = 'new';
      break;
    case 'mta':
      type = 'adjust';
      break;
    case 'renew':
      type = 'renew';
      break;
    default:
      type = 'new';
  }

  if (organisationConfiguration) {
    const path = quoteId
      ? props.goToIntelligentQuote(type, undefined, undefined, quoteId)
      : props.goToIntelligentQuote(
          type,
          productReference,
          productCoverReference
        );

    const pathDeconstructed = path.split('?');

    return (
      <Redirect
        to={{
          pathname: pathDeconstructed[0],
          search: `?${pathDeconstructed[1]}`,
          state: props.location.state
        }}
      />
    );
  }

  return null;
};

export interface IIntelligentQuoteRedirect
  extends RouteComponentProps<{
    routeAction: string;
    productId: string;
    lineOfBusiness: string;
    coverType: string;
  }> {
  goToIntelligentQuote: (
    quoteActionOrId: string,
    productReference?: string,
    productCoverReference?: string,
    quoteId?: string,
    policyId?: string
  ) => string;
}
