import * as React from 'react';
import { ApplicationConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetApplicationConfiguration(): UseGetApplicationConfiguration {
  const client = useConfigurationClient();
  const [
    applicationConfiguration,
    setApplicationConfiguration
  ] = React.useState<ApplicationConfiguration | undefined>(undefined);

  useAsyncEffect(async () => {
    const _applicationConfiguration = await client.getApplicationsConfiguration(
      'symphony'
    );

    setApplicationConfiguration(_applicationConfiguration);
  }, []);

  return {
    applicationConfiguration
  };
}

interface UseGetApplicationConfiguration {
  applicationConfiguration: ApplicationConfiguration | undefined;
}
