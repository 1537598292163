import React from 'react';
import createPlatformClient from '@aventus/platform-client';
import { useGetAppConfiguration } from '@aventus/configuration-client-context';
import PlatformContext from './context';
import { getToken } from './token-service';

interface IPlatformProviderProps {
  children?: React.ReactNode;
}

export const PlatformProvider = (
  props: IPlatformProviderProps
): JSX.Element => {
  const opusToken = getToken();
  const [token, setToken] = React.useState<string | undefined>(opusToken);

  const { appConfiguration } = useGetAppConfiguration();

  React.useEffect(() => {
    if (opusToken) {
      setToken(opusToken);
    }
  }, [opusToken]);

  return (
    <PlatformContext.Provider
      value={{
        token,
        ...createPlatformClient(token, appConfiguration?.API)
      }}
    >
      {props.children}
    </PlatformContext.Provider>
  );
};
