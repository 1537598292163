import {
  AUTH_SIGN_IN_WITH_LINK_REQUEST,
  AUTH_SIGN_IN_WITH_LINK_SUCCESS,
  AUTH_SIGN_IN_WITH_LINK_FAILURE
} from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import getExternalId from '../../../api/core/tracking/get-external-id';
import setExternalIdAction from '../../../api/core/tracking/set-external-id/actions';

export function signInWithLinkAction(
  token,
  userDetails,
  accountType,
  authenticationProvider
) {
  return (dispatch, getStore) => {
    const externalId = getExternalId(getStore());
    dispatch({
      type: 'API_REQUEST',
      [API_SYM]: {
        requestConfig: {
          url: 'user/loginlinksignup',
          method: 'POST',
          data: {
            userDetails: userDetails,
            externalId: externalId,
            authenticationProvider
          }
        },
        actions: [
          signInWithLinkRequestAction,
          signInWithLinkSuccessAction,
          signInWithLinkActionFailureAction
        ],
        passToAction: {
          token,
          accountType
        }
      }
    });
  };
}

export function signInWithLinkRequestAction() {
  return {
    type: AUTH_SIGN_IN_WITH_LINK_REQUEST
  };
}

export function signInWithLinkSuccessAction(res, passToAction) {
  const { externalID, email } = res.user;

  const { token, accountType } = passToAction;

  return dispatch => {
    dispatch({
      type: AUTH_SIGN_IN_WITH_LINK_SUCCESS,
      token,
      email,
      accountType
    });

    dispatch(setExternalIdAction(externalID, email));
  };
}

export function signInWithLinkActionFailureAction(res) {
  return dispatch => {
    dispatch({
      type: AUTH_SIGN_IN_WITH_LINK_FAILURE
    });

    dispatch(
      queueAlertAction({
        type: 'error',
        message: res.message,
        location: false
      })
    );
  };
}
