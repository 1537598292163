import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import { currencyToHumanReadable } from '../currency-to-human-readable';
import moment from 'moment';

/**
 * TODO
 */
export function interpolate(item = {}, answeredText = '', missingText = '') {
  // Regex to select '{value}'
  const braceRegex = /\{([\w\.-|\s']+)\}/g;

  // Get the value from the item object
  // We may need to add special conditions in some cases e.g. currency.

  function formatValue(match, p1, offset, string) {
    let value = '';
    let fallbackText = '';

    const orConditionSplit = p1.split('||');
    const firstPart = orConditionSplit[0].trim();
    value = _get(item, firstPart, '');
    fallbackText = orConditionSplit[1] || '';

    // Support config fallback text of nothing i.e. empty string ('')
    if (!fallbackText.trim().replaceAll("'", '')) {
      fallbackText = null;
      missingText = '';
    }

    // If currency
    // TODO
    // We should write a helper function that knows how to handle our specific currency
    // object. This will allow us to add an interface layer over our specific APIs.
    // And it should probably be called from inside the currency function. Otherwise we'll
    // need to do this logic check everytime we want to use the function. If it's inside,
    // it's nicely isolated inside of currency-related code.

    if (
      _isObject(value) &&
      value.hasOwnProperty('value') &&
      value.hasOwnProperty('currencyCode')
    ) {
      return currencyToHumanReadable({
        value: value.value,
        currencyCode: value.currencyCode
      });
    }

    if (_isObject(value) && value.hasOwnProperty('value')) {
      return currencyToHumanReadable({ value: value.value });
    }

    if (_isObject(value)) {
      return value.text;
    }

    if ((p1 === 'dateOfBirth' || firstPart === 'dateOfBirth') && !!value) {
      return moment(value).format('DD-MM-YYYY');
    }

    // To allow for numeric fields
    if (value === 0) return value;

    return value || fallbackText || missingText;
  }
  // Replace each set of braces with values from the item
  return answeredText.replace(braceRegex, formatValue);
}
