import * as React from 'react';
import axios, { AxiosPromise } from 'axios';

export function useLookupAddress (oracleUrl: string): UseLookupAddress {

  const [ query, setQuery ] = React.useState<string>('');
  const [ addresses, setAddresses ] = React.useState<AventusAddress[]>([]);
  const [ isQuerying, setIsQuerying ] = React.useState<boolean>(false);
  const [ error, setError ] = React.useState<string>('');

  async function setQueryAsync (query: string) {

    setQuery(query);
    setIsQuerying(true);
    setError('');

    try {

      const response = (
        await <AxiosPromise<LookupResponse>>
        axios.post(oracleUrl, { query, state: '' })
      );

      if (response.data.error === -1 && response.data.data) {
        setAddresses(response.data.data.results);
      } else {
        throw new Error();
      }

      setIsQuerying(false);

    } catch (e) {
      setError('Something went wrong. Please try again or contact us for any help');
      setIsQuerying(false);
    }

  }

  return {
    query,
    setQuery: setQueryAsync,
    addresses,
    isQuerying,
    error
  };

}

interface UseLookupAddress {
  query: string,
  setQuery: (query: string) => void,
  addresses: AventusAddress[],
  isQuerying: boolean,
  error: string
}

interface LookupResponse {
  data?: {
    results: AventusAddress[],
    state?: string
  },
  error: number,
  error_description: string,
  successful: boolean,
  validationErrors: string[]
}

interface AventusAddress {
  address1: string,
  address2?: string,
  town: string,
  postalCode: string,
  country: {
    referenceID: string,
    text: string
  },
  udprn?: number
}
