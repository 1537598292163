import {
    FIREBASE_SIGN_IN_REQUEST,
    FIREBASE_SIGN_IN_SUCCESS,
    FIREBASE_SIGN_IN_FAILURE
  } from './types';
  
  
  export function firebaseSignInRequestAction() {
    return {
      type: FIREBASE_SIGN_IN_REQUEST
    };
  }
  
  export function firebaseSignInSuccessAction() {
    return {
        type: FIREBASE_SIGN_IN_SUCCESS
    };
  }
  
  export function firebaseSignInFailureAction() {
    return {
        type: FIREBASE_SIGN_IN_FAILURE
    };
  }
  