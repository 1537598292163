import update from 'immutability-helper';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function validateEmail(statePartial, emailKey = false) {

  if (!emailKey) {
    return statePartial;
  }

  const isValidEmail = emailRegex.test(statePartial[emailKey]) || false;

  if (!isValidEmail) {
    return {
      ...statePartial,
      ...{
        [`_${emailKey}`]: {
          error: 'That email is not valid. Try again.'
        }
      }
    }
  } else {
    return {
      ...statePartial,
      ...{
        [`_${emailKey}`]: false
      }
    }
  }
};
