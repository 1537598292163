import {
  IExposure,
  IProductConfiguration,
  QuestionSetType
} from '@aventus/platform-client';
import { QuoteType } from '@aventus/platform-client-context/models/quote';
import { History } from 'history';
import { buildURLQueryString } from '@aventus/pocketknife/query-param-builder';
import { IRiskExposure } from '@aventus/platform-client-context/models/quote-edit-session';
import { IExposureQuestionSetList } from '../views/quick-quote-summary';

export const getExposureReferenceId = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const exposureReferenceID = urlParams.get('exposureReferenceId');
  return exposureReferenceID || null;
};

export const questionSetNameMap: Map<QuestionSetType, string> = new Map([
  ['CoreQuickQuote', 'Core quick quote questions'],
  ['Core', 'Core quote questions'],
  ['Secondary', 'Secondary questions'],
  ['Exposure', ''],
  ['ExposureQuickQuote', '- Quick quote']
]);

export const createRefKey = (referenceID: string, parentReferenceID?: string) =>
  [parentReferenceID, referenceID].filter(x => x).join('.');

export const handleEditQuestionSet =
  (
    history: History,
    productID: string,
    editSessionID: string,
    chrome: string,
    token: string,
    quoteType: QuoteType
  ) =>
  (
    questionSetReferenceID: string,
    exposureReferenceID?: string,
    exposureID?: string
  ): void => {
    const query = buildURLQueryString({
      editSessionId: editSessionID,
      token,
      chrome,
      exposureReferenceId: exposureReferenceID,
      exposureId: exposureID
    });

    history.push(
      `/products/${productID}/quote/${quoteType}/${questionSetReferenceID}${query}`
    );
  };

export const coreQuestionSets = (
  questionSetType: QuestionSetType,
  productConfiguration: IProductConfiguration,
  onClickAction: (
    questionSetReferenceID: string,
    exposureReferenceID?: string,
    exposureID?: string
  ) => void
) =>
  productConfiguration.coreQuestionSets
    .filter(questionSet => questionSet.questionSetType === questionSetType)
    .map(questionSet => {
      return {
        label:
          questionSet.name || 
          questionSetNameMap.get(questionSet.questionSetType) ||
          'Default questions',
        value: 'Edit',
        buttonVariant: 'secondary',
        buttonAction: () => onClickAction(questionSet.referenceID)
      };
    });

export const exposureQuestionSets = (
  riskExposures: IRiskExposure[],
  questionSetType: QuestionSetType,
  productConfiguration: IProductConfiguration,
  onClickAction: (
    questionSetReferenceID: string,
    exposureReferenceID?: string,
    exposureID?: string
  ) => void,
  onClickRemove: (params: {
    exposureId: string,
    label: string,
  }) => void
) => {
  return productConfiguration
    ? productConfiguration.exposures.reduce(
        (exposures: IExposureQuestionSetList[], exposure: IExposure) => {
          const exposureName = exposure?.name || "Exposure";
          const matchingExposures = riskExposures.filter(
            risk => risk.referenceID === exposure.referenceID
          );
          const merged = exposure.questionSets
            .filter(
              questionSet => questionSet.questionSetType === questionSetType
            )
            .map(questionSet => ({
              ...exposure,
              ...{
                questionSetReferenceID: questionSet.referenceID,
                questionSetType: questionSet.questionSetType,
                riskExposures: matchingExposures.map(
                  (exposure: IRiskExposure, i: number) => ({
                    label: exposure.name || `Exposure #${i + 1}`,
                    value: 'Edit',
                    buttonVariant: 'secondary',
                    buttonAction: () =>
                      onClickAction(
                        questionSet.referenceID,
                        exposure.referenceID,
                        exposure.id
                      ),
                    removeAction: () => {
                      return onClickRemove({
                        exposureId: exposure.id,
                        label: exposureName,
                      })
                    }
                  }),
                )
              }
            }));

          return [...exposures, ...merged];
        },
        []
      )
    : [];
};
