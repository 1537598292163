import * as React from 'react';
import moment from 'moment';
import { ApplicationError } from '@aventus/errors';
import { StripeCreateIntent, PaymentMethod } from '@aventus/platform';
import { useError } from '@aventus/pockethooks';
import { StripeError } from '../../errors/stripe-error';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useBladeInlineError } from '@aventus/blade/inline-error';
import { friendlyStripeErrorMessage } from '../../friendly-error-message';

export function useUpdateSavedCard(
  policyId: string,
  paymentMethod: PaymentMethod,
  lifecycles: Lifecycles,
  setIsUpdatingSavedCard: (state: boolean) => void
): UseUpdateSavedCard {
  const { setError } = useBladeInlineError();
  const { throwError } = useError();

  const elements = useElements();
  const stripe = useStripe();
  const [updatedPaymentMethod, setUpdatedPaymentMethod] = React.useState<
    PaymentMethod | undefined
  >(undefined);

  const [isCardExpired, setIsCardExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    const now = moment();
    const expires = moment(
      `${paymentMethod.expiryYear}-${paymentMethod.expiryMonth}-01`
    );

    if (now.isAfter(expires)) {
      !isCardExpired && setIsCardExpired(true);
    } else {
      isCardExpired && setIsCardExpired(false);
    }
  }, [
    isCardExpired,
    paymentMethod.expiryMonth,
    paymentMethod.expiryYear,
    paymentMethod.last4Digits
  ]);

  const updateSavedCard = async () => {
    if (!stripe) {
      throwError(
        new ApplicationError(
          '`useUpdateSavedCard` is trying to update before stripe was initialised.'
        )
      );
    } else if (!elements) {
      throwError(
        new ApplicationError(
          '`useUpdateSavedCard` is trying to update before the Stripe credit card element was initialised.'
        )
      );
    } else {
      const cardElement = elements!.getElement('card');

      if (cardElement === null)
        throwError(new ApplicationError('cardElement unavailable'));
      else {
        setIsUpdatingSavedCard(true);

        try {
          const setupIntent = await lifecycles.onInitialise();

          const response = await stripe.confirmCardSetup(
            setupIntent.clientSecret,
            {
              payment_method: {
                card: cardElement
              }
            }
          );

          if (response.error !== undefined) {
            if (response.error.type === 'card_error') {
              setError(
                'stripecard',
                friendlyStripeErrorMessage(response.error)
              );
              setIsUpdatingSavedCard(false);
              return;
            } else {
              setError(
                'stripecard',
                'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
              );

              // @ts-ignore
              window.Rollbar?.critical(
                new Error(
                  `PAYMENT: Stripe confirmCardSetup error: ${response.error.message}`
                ),
                response.error
              );

              setIsUpdatingSavedCard(false);
              return;
            }
          }

          if (response.setupIntent?.payment_method) {
            const _updatedPaymentMethod = await lifecycles.onUpdateSavedCard(
              policyId,
              response.setupIntent?.payment_method
            );

            if (_updatedPaymentMethod) {
              setUpdatedPaymentMethod(_updatedPaymentMethod);

              setTimeout(
                () => lifecycles.onFinish(_updatedPaymentMethod, undefined),
                3000
              );
            }
          } else {
            throwError(
              new StripeError(
                "We weren't able to update your card information, please try again later or contact customer support."
              )
            );
          }
        } catch (e) {
          throwError(e);
        }
      }
    }

    setIsUpdatingSavedCard(false);
  };

  return {
    updateSavedCard,
    updatedPaymentMethod,
    isCardExpired
  };
}

export interface UseUpdateSavedCard {
  updateSavedCard: () => void;
  updatedPaymentMethod?: PaymentMethod;
  isCardExpired: boolean;
}

export interface Lifecycles {
  onInitialise: () => Promise<StripeCreateIntent>;
  onUpdateSavedCard: (
    policyId: string,
    paymentMethodId: string
  ) => Promise<PaymentMethod>;
  onFinish: (paymentMethod: PaymentMethod, message: string|undefined) => void;
}
