import update from 'immutability-helper';

export default function clearAddressLookupReducer(state, action) {
  return {
    ...state,
    ...{
      core: update(state.core, {
        addressLookup: {
          data: {
            results: { $set: false }
          }
        }
      })
    }
  }
}
