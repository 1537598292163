import * as React from 'react';
import { OpusClient } from '@aventus/opus-client';

export const OpusClientContext = React.createContext<any>(undefined);

export function useOpusClient(ignoreUsageWithoutContext?: boolean): OpusClient {
  const client = React.useContext(OpusClientContext);
  if (client === undefined && !ignoreUsageWithoutContext) {
    throw new Error(
      'useOpusClient must be used within a OpusClientContext.Provider'
    );
  }
  return client;
}
