import * as React from 'react';

import { BladeIcon, BladeLink } from '@aventus/blade';

import styles from './css/styles.css';

interface PackageInfoTextProps {
  packageDescription: string | null;
  onMoreInfoClick: () => void;
}

const PackageInfoText = (props: PackageInfoTextProps) => {
  return (
    <>
      <div className={`${styles.packageDescription} ${styles.desktopOnly}`}>
        {props.packageDescription}
      </div>

      <BladeLink
        className={`${styles.moreInfo} ${styles.desktopOnly}`}
        onClick={props.onMoreInfoClick}
      >
        More info&nbsp; <BladeIcon name={'faChevronRight'} />
      </BladeLink>
    </>
  );
};

export default PackageInfoText;
