/*
  ***IMPORTANT***

  Only update the state version if there is a change to state
  that gets stored in local storage.

  At the moment only app module is stored in local storage with a few
  select keys.

  If any of these keys are moved / updated we need to update the state version

  The keys are set inside:
  - applications/lib/modules/lib/app/api/local-storage-state-partial.js

  NOTE: This will sign the user out and treat the app as a fresh install / guest
  so avoid doing this where possible.
*/

export const stateVersion = 20200713;
