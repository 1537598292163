export const VIEWS_TYPE:string = 'views';

export function viewsMapper(rest:any, h:IViews):IViewsMap {
  return {
    ...rest,
    ...{
      [ h.key ]: {
        ...h.viewWidth ? { viewWidth: h.viewWidth + (h.viewWidthUnit || '') } : {},
        ...h.viewHeight ? { viewHeight: h.viewHeight + (h.viewHeightUnit || '') } : {}
      }
    }
  };
}

export interface IViews {
  key:string,
  viewWidth?:number
  viewHeight?:number
  viewWidthUnit?:string
  viewHeightUnit?:string
}

export interface IViewsMap {
  [key:string]: {
    viewWidth?:number
    viewHeight?:number
  }
}
