import { VoucherInfo } from '@aventus/platform';
import { PricingSet } from '@aventus/platform';

export function useCheckoutVoucher(
  voucherActions: {
    setVoucherInfo: (arg: VoucherInfo | undefined) => void;
    setIsWorking: (arg: boolean) => void;
    setVoucherError: (arg: string | undefined) => void;
  },
  voucherCalls: {
    applyVoucherCode?: (voucher: string, quoteID: string) => any;
    removeVoucherCode?: (quoteID: string) => any;
  },
  voucherValue: string | undefined,
  quoteID: string | undefined,
  checkoutCallBack?: (updatedPricing: PricingSet) => void
): IUseVoucher {
  const applyVoucher = async () => {
    voucherActions.setIsWorking(true);
    voucherActions.setVoucherError(undefined);
    if (voucherCalls.applyVoucherCode && voucherValue && quoteID) {
      try {
        const _response = await voucherCalls.applyVoucherCode(
          voucherValue,
          quoteID
        );

        if (_response) {
          voucherActions.setVoucherInfo({
            id: 1,
            code: 'Checkout',
            terms: null,
            description: null
          });

          if (checkoutCallBack) {
            checkoutCallBack(_response);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          voucherActions.setVoucherError(error.message);
        }

        voucherActions.setIsWorking(false);
      }
    }
    voucherActions.setIsWorking(false);
  };

  const removeVoucher = async () => {
    voucherActions.setIsWorking(true);
    if (voucherCalls.removeVoucherCode && quoteID) {
      try {
        const _response = await voucherCalls.removeVoucherCode(quoteID);

        if (checkoutCallBack && _response) {
          checkoutCallBack(_response);
        }
      } catch (error) {
        if (error instanceof Error) {
          voucherActions.setVoucherError(error.message);
        }

        voucherActions.setIsWorking(false);
      }
    }
    voucherActions.setIsWorking(false);
  };

  const resetVoucher = () => {
    voucherActions.setVoucherInfo(undefined);
    voucherActions.setVoucherError(undefined);
    voucherActions.setIsWorking(false);
  };

  return {
    checkout: { applyVoucher, removeVoucher, resetVoucher }
  };
}

export interface IUseVoucher {
  checkout: { applyVoucher: any; removeVoucher: any; resetVoucher: any };
}
