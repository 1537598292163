import * as React from 'react';
import { BladeViewIsLoading } from '@aventus/blade';
import { PaymentMethod } from '@aventus/platform';
import {
  useGetOrganisationSettings,
  useGetPolicyBundle,
  useGetPolicyPaymentMethod,
  useSymphonyClient
} from '@aventus/symphony-client/hooks';
import {
  useGetProductConfiguration,
  useGetPaymentProvidersConfiguration
} from '@aventus/configuration-client-context';
import { UpdateSavedCard } from '@aventus/payment-provider-stripe';
import { ICheckoutBoundaryError } from '../../boundary/types';
import { FatZebraUpdateCardDetails } from '@aventus/payment-provider-fatzebra';

export const UpdateCardDetails: React.FunctionComponent<IUpdateCardDetails> = props => {
  const {
    stripeCreateSetupIntent,
    updatePolicyPaymentMethod,
    fatZebraGetConfig,
    fatZebraGeneratePaymentIntentVerification
  } = useSymphonyClient();

  const {
    organisationSettings,
    isGettingOrganisationSettings
  } = useGetOrganisationSettings();

  const {
    policyPaymentMethod,
    isGettingPolicyPaymentMethod
  } = useGetPolicyPaymentMethod(props.policyId);

  const { policyBundle, isGettingPolicyBundle } = useGetPolicyBundle(
    props.policyId
  );

  const { productConfiguration } = useGetProductConfiguration(
    policyBundle?.policy.productReferenceID,
    policyBundle?.policy.coverType
  );

  const {
    paymentProvidersConfiguration
  } = useGetPaymentProvidersConfiguration();

  if (
    isGettingPolicyPaymentMethod ||
    isGettingOrganisationSettings ||
    isGettingPolicyBundle
  ) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (
    policyBundle &&
    productConfiguration &&
    organisationSettings &&
    paymentProvidersConfiguration &&
    policyPaymentMethod &&
    policyPaymentMethod.paymentPlanType === 'AnnualMonthlySubscription'
  ) {
    switch (policyBundle.creditAgreement.provider) {
      case 'FatZebra':
        if (paymentProvidersConfiguration.FatZebra) {
          return (
            <FatZebraUpdateCardDetails
              policyBundle={policyBundle}
              paymentMethod={policyPaymentMethod}
              productConfiguration={productConfiguration}
              paymentProviderConfiguration={
                paymentProvidersConfiguration.FatZebra
              }
              organisationSettings={organisationSettings}
              lifecycles={{
                onFatZebraGetConfig: fatZebraGetConfig,
                onFatZebraGeneratePaymentIntentVerification: fatZebraGeneratePaymentIntentVerification,
                onUpdateSavedCard: updatePolicyPaymentMethod,
                onFinish: props.onFinish
              }}
            />
          );
        }
        return null;
      default:
        if (paymentProvidersConfiguration.Stripe?.key) {
          return (
            <UpdateSavedCard
              policyId={props.policyId}
              stripeKey={paymentProvidersConfiguration.Stripe?.key}
              paymentMethod={policyPaymentMethod}
              productConfiguration={productConfiguration}
              lifecycles={{
                onInitialise: stripeCreateSetupIntent,
                onUpdateSavedCard: updatePolicyPaymentMethod,
                onFinish: props.onFinish
              }}
              error={props.errors?.payment}
            />
          );
        }
    }
  }

  return null;
};

export interface IUpdateCardDetails {
  policyId: string;
  onFinish: (paymentMethod: PaymentMethod, message: string|undefined) => void;
  errors?: ICheckoutBoundaryError;
  goToLogin?: () => void;
  goHome?: () => void;
}
