import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import loadingStyles from './loading.styles';

const FADEOUT_TIME = 1;

class Loading extends React.Component {

  state = {
    remove: this.props.off ? true : false
  }

  static propTypes = {
    off: PropTypes.any,
    loadingComponent: PropTypes.any,
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.off && this.props.off) {

      // The `off` class tansitions only the opacity
      // to fade out since you can't fade out using CSS
      // transitions `display:none`.

      // This timeout works around this by waiting until
      // the opacity transition is finished, and then
      // appends the `remove` class, which sets
      // display to none.

      setTimeout(
        () => { this.setState({ remove: true }) },
        FADEOUT_TIME * 2000
      );
    }
  }

  render = () => {
    return (
      <div className={ `
        ${ this.props.classes.splash }
        ${ this.props.off ? 'off' : '' }
        ${ this.state.remove ? 'remove' : '' }
        ` }>
        <div className='splash-content'>
          { this.props.loadingComponent }
        </div>
      </div>
    );
  }
}

const generatedLoadingStyles = loadingStyles({ offTime: FADEOUT_TIME });
export default injectSheet(generatedLoadingStyles)(Loading);
