import { useEffect } from 'react';

export function useBeforeUnload(callBack?: IBeforeUnloadCallback) {
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    let isPhoneUrl;
    let isRefreshButton;

    callBack && callBack();
    if (event.target instanceof Document) {
      const activeElement = event.target.activeElement;
      isPhoneUrl =
        activeElement && activeElement?.getAttribute('href')?.includes('tel:');
      isRefreshButton = activeElement?.getAttribute('id') === 'btn-refresh-page';
    }

    if (!isPhoneUrl && !isRefreshButton) {
      event.returnValue =
        'Going back will take you out of the quote page, do you still want to proceed?';
      return event;
    } else {
      return null;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);
}

type IBeforeUnloadCallback = (...arg: any) => any;
