import * as React from 'react';
import { BladeViewIsLoading } from '@aventus/blade';
import {
  PaymentPlanType,
  ProductReference,
  ProductCoverReference
} from '@aventus/platform';
import {
  useSymphonyClient,
  useGetQuote,
  useGetQuoteProduct,
  useGetCustomConfig
} from '@aventus/symphony-client/hooks';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration
} from '@aventus/configuration-client-context';
import { MvmtIntelligentQuoteSummary } from '@aventus/mvmt-quote';
import { useIsHybridProduct } from '@aventus/mvmt-simplequote/hooks/use-is-hybrid-product';
import { RouteComponentProps } from 'react-router-dom';
import { getDisplayPrice, usePaymentPlan } from '@aventus/mvmt-pricing';
import { currencyToHumanReadable } from '@aventus/pocketknife';

export const Summary: React.FunctionComponent<ISummary> = props => {
  const symphony = useSymphonyClient();

  const [productReference, setProductReference] = React.useState<
    ProductReference | undefined
  >(undefined);
  const [productCoverReference, setProductCoverReference] = React.useState<
    ProductCoverReference | undefined
  >(undefined);

  // Given these two references, we can pick out the specific configuration
  // for this insurance product.

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );

  // We can kick off getting the quote, both for the quote
  // object and quote pricing as well as to update our
  // productReference and productCoverReference

  const { quoteBundle, isGettingQuoteBundle } = useGetQuote(props.quoteId, true);

  React.useEffect(() => {
    if (quoteBundle) {
      setProductReference(quoteBundle.requestedQuote.productReferenceID);
      setProductCoverReference(quoteBundle.requestedQuote.coverType);
    }
  }, [quoteBundle]);

  // As part the quote definition, we also need to get the quote product
  // that is associated with this particular quote. This holds the quote-specific
  // product items, as defined by the user.yarn

  const { quoteProduct, isGettingQuoteProduct } = useGetQuoteProduct(
    props.quoteId
  );

  const { customConfig } = useGetCustomConfig();

  const { isHybridProduct } = useIsHybridProduct(
    customConfig,
    quoteBundle?.requestedQuote.productID.toString() || ''
  );

  const paymentPlan = usePaymentPlan(
    quoteBundle?.requestedQuotePricing,
    props.defaultPaymentPlanType,
    quoteBundle?.requestedQuote.paymentPlanReferenceID
  );

  if (isGettingQuoteBundle || isGettingQuoteProduct) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  // We've got everything we need to begin rendering the quote
  // once we've got the configuration.

  if (quoteBundle?.reratedQuote && paymentPlan?.selectedPricingPlan) {
    sessionStorage.setItem(`quote-${quoteBundle.reratedQuote}-previousPrice`, currencyToHumanReadable(getDisplayPrice(paymentPlan.selectedPricingPlan)))
    sessionStorage.setItem(`quote-${quoteBundle.reratedQuote}-newProductVersion`, quoteBundle?.reratedNewProductVersion || false);
    window.location.href = `/quote/${quoteBundle.reratedQuote}?rerated`;

    return <BladeViewIsLoading variant={'s'} />;
  }

  if (
    productConfiguration &&
    quoteProduct &&
    quoteBundle &&
    organisationConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteSummary
        {...props}
        isAuthenticated={symphony.isUserAuthenticated}
        documentTitle={`Quote summary | ` + organisationConfiguration?.name}
        quote={quoteBundle.requestedQuote}
        quotePricing={quoteBundle.requestedQuotePricing}
        quoteEmbargoes={quoteBundle.embargoes}
        defaultPaymentPlanType={props.defaultPaymentPlanType}
        adjustPlan={quoteBundle.mtaInfo}
        risk={quoteBundle.risk}
        product={quoteProduct}
        productConfiguration={productConfiguration}
        organisationConfiguration={organisationConfiguration}
        customConfiguration={customConfig}
        customiseQuote={props.customiseQuote}
        customiseQuoteForRenew={props.customiseQuoteForRenew}
        goHome={props.goHome}
        goToDocuments={props.goToDocuments}
        goToCheckout={props.goToCheckout}
        goToLogin={props.goToLogin}
        isHybrid={isHybridProduct}
      />
    );
  }

  return null;
};

interface ISummary extends RouteComponentProps {
  quoteId: string;
  defaultPaymentPlanType?: PaymentPlanType;
  goHome: () => void;
  goToCheckout: (
    quoteId: string,
    defaultPaymentPlanType?: PaymentPlanType
  ) => void;
  goToDocuments: (type: string, id: string) => void;
  goToLogin: () => void;
  customiseQuote: (quoteId: string) => void;
  customiseQuoteForRenew: (quoteId: string) => void;
}
