import React from 'react';
import { ProductBoundary } from './boundary';
import {
  ProductDashboardCustom,
  IProductDashboardCustom
} from './views/product-dashboard-custom';
export { DetailsOfCover } from './components/details-of-cover';
export { ProductDashboard } from './views/product-dashboard';
export { ProductDashboardRedirect } from './views/product-dashboard-redirect';
export { ProductBaseRedirect } from './views/product-base-redirect';
export { ProductOverview } from './views/product-overview';
export { ProductOverviewRedirect } from './views/product-overview-redirect';

export const MvmtProductDashboardCustom = (props: IProductDashboardCustom) => (
  <ProductBoundary signOut={props.signOut}>
    <ProductDashboardCustom {...props} />
  </ProductBoundary>
);
