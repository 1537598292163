import { ToHumanReadable } from '@aventus/application-timezone';
import { RiskProposer } from '@aventus/platform';
import { addressToHumanReadable } from '@aventus/pocketknife/address-to-human-readable';
import { IListItem } from '@aventus/blade/list';

export function getPolicyHolderDetails(
  proposer: RiskProposer,
  toHumanReadable: ToHumanReadable,
  showEmail: boolean
): IListItem[] {
  let summaryList = [
    <IListItem>{
      label: 'Policy holder',
      value: `${proposer.personName.firstName} ${proposer.personName.lastName}`
    }
  ];

  if (proposer.address && proposer.address.address1) {
    summaryList.push(<IListItem>{
      label: 'Correspondence address',
      value: proposer.address.address1,
      moreDetails: addressToHumanReadable(proposer.address)
    });
  }

  summaryList.push(<IListItem>{
    label: 'Date of birth',
    value: toHumanReadable(proposer.dateOfBirth)
  });

  if (showEmail) {
    summaryList.push(<IListItem>{
      label: 'Email',
      value: proposer.emailAddress
    });
  }

  return summaryList;
}
