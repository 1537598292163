
export const STELLA_TENANT_IDS = [
  'avt_tnt_stelladev',
  'avt_tnt_stellauat',
  'avt_tnt_stella'
]

export const STELLA_ROADSIDE_IDS = [
  'ste_ratecomp_roadsideAssistance',
  'stella_ratecomp_roadsideAssistance'
];

