import * as React from 'react';
import { areAtomsEqual as _areAtomsEqual } from '@aventus/honey-utility';

export function useAreAtomsEqual(
  atomKey: string,
  anotherAtomKey: string
): UseSurface {
  const [areAtomsEqual, setAreAtomsEqual] = React.useState<boolean>(false);

  React.useEffect(() => {
    const __areAtomsEqual = _areAtomsEqual(atomKey, anotherAtomKey);
    setAreAtomsEqual(__areAtomsEqual);
  }, []);

  return {
    areAtomsEqual
  };
}

export interface UseSurface {
  areAtomsEqual: boolean;
}
