import React from 'react';
import ReactTooltip, { TooltipProps, Place } from 'react-tooltip';
import { BladeIcon } from '@aventus/blade';
import { BladeMarkdown } from '../markdown';
import css from './index.css';

export const BladeTooltip: React.FunctionComponent<IBladeTooltip> = ({
  event = 'click',
  type = 'light',
  place = 'bottom',
  icon = 'faInfo',
  markdown,
  children
}) => (
  <>
    <div className={css.tooltipWrapper} data-tip="">
      {children ? children : <BladeIcon name={icon} />}
      <ReactTooltip
        type={type}
        place={place}
        event={event}
        effect="solid"
        className={css.tooltip}
        globalEventOff={'click'}
        getContent={() => {
          return markdown && <BladeMarkdown markdown={markdown} />;
        }}
      />
    </div>
  </>
);

interface IBladeTooltip extends TooltipProps {
  markdown?: string | null | undefined;
  place?: Place;
  icon?: string;
}
