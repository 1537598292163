import { IListItem } from "@aventus/blade/list";
import { useApplicationTimezone } from '@aventus/application-timezone';

export function getDriverDetails (
    proposer: any,
    driverDetails: any
): IListItem[] {
    const { toHumanReadable } = useApplicationTimezone();
    let driverDetailsArray: IListItem[] = [];

    // Proposer.mainDriver is not populated if we dont ask the main driver question, this only comes if there is another driver on the policy
    const isProposerMainDriver = (proposer.isMainDriver || proposer.isMainDriver == null);

    const driverLabelMap = {
        'Main driver': isProposerMainDriver ? proposer : driverDetails.mainDriver,
        'Second driver': isProposerMainDriver ? driverDetails.secondDriver : proposer,
        'Third driver': driverDetails.thirdDriver,
        'Fourth driver': driverDetails.fourthDriver
    }

    for (const [label, driverObj] of Object.entries(driverLabelMap)) {
        // Proposer object uses .personName
        const name = driverObj.personName !== undefined ? driverObj.personName : driverObj.name;
        if (name?.firstName !== null && name?.lastName !== null && driverObj?.gender !== null) {
            driverDetailsArray.push({
                label,
                value: `${name.firstName} ${name.lastName} (${driverObj.gender.text.toLowerCase()}), DOB: ${toHumanReadable(driverObj.dateOfBirth)}`
            });
        }
    }

    return driverDetailsArray;
}
