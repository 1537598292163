import update from 'immutability-helper';

export function validateMeRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isValidatingMe: { $set: true }
        }
      })
    }
  };
}

export function validateMeSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isValidatingMe: { $set: false },
          successfullyValidatedMe: { $set: true }
        }
      })
    }
  };
}

export function validateMeFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isValidatingMe: false,
            successfullyValidatedMe: false
          }
        }
      })
    }
  }
}
