import * as React from 'react';
import { BladeView, BladeTitleBar } from '@aventus/blade';
import { YourDetails } from '../../components/your-details';
import { YourPolicy } from '../../components/your-policy';
import { useApplicationTimezone } from '@aventus/application-timezone/use-application-timezone';
import { DetailsOfCover } from '@aventus/mvmt-product';
import css from './index.css';
import { RouteComponentProps } from 'react-router';
import { useGetPolicyProduct } from '@aventus/symphony-client/hooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { DetailsOfDrivers } from '@aventus/mvmt-quote/components/details-of-drivers';
import { PolicyPremiumBreakdown } from '@aventus/mvmt-policy/components/policy-premium-breakdown';
import { useGetPolicyBundle } from '@aventus/symphony-client/hooks/use-get-policy-bundle';

export const PolicySummaryOfCover: React.FunctionComponent<IPolicySummaryOfCover> = (
  props: IPolicySummaryOfCover
) => {
  const { policyBundle } = useGetPolicyBundle(
    props.match.params.policyId.trim()
  );

  const { productConfiguration } = useGetProductConfiguration(
    policyBundle?.policy?.productReferenceID,
    policyBundle?.policy?.coverType
  );

  const { policyProduct } = useGetPolicyProduct(policyBundle?.policy?.id);

  const { toHumanReadable } = useApplicationTimezone();

  if (policyBundle && productConfiguration && policyProduct) {
    return (
      <div className={css.scaffold}>
        {productConfiguration?.policy.summaryOfCover.title && (
          <BladeTitleBar
            viewVariant={'s'}
            title={productConfiguration?.policy.summaryOfCover.title}
          />
        )}

        <BladeView variant={'s'} className={css.policyOverview}>
          {productConfiguration.quote.summary.showInsurerDetails && (
            <YourDetails
              proposer={policyBundle.risk.proposer}
              toHumanReadable={toHumanReadable}
              showEmail={false}
              shouldRespectViewWidth={true}
            />
          )}

          <YourPolicy
            policyBundle={policyBundle}
            toHumanReadable={toHumanReadable}
            isMotorPolicy={productConfiguration.quote.summary.showMotorDetails}
            shouldRespectViewWidth={true}
            paymentPlanLabels={
              productConfiguration.quote.summary.summaryPaymentPlanLabels
            }
            coverStartLabel={productConfiguration.quote.summary.coverStartLabel}
            coverEndLabel={productConfiguration.quote.summary.coverEndLabel}
            showPurchasedDate={productConfiguration.policy.summary.showPurchasedDate}
          />

          {productConfiguration.quote.summary.showDriverDetails && (
            <DetailsOfDrivers
              proposer={policyBundle.risk.proposer}
              driverDetails={policyBundle.risk.vehicle.driverDetails}
              shouldRespectViewWidth={true}
            />
          )}

          {productConfiguration.quote.summary.showPremiumSummary && (
            <PolicyPremiumBreakdown
              policyBundle={policyBundle}
              productConfiguration={productConfiguration}
              shouldRespectViewWidth={true}
            />
          )}
          {policyProduct.productItems.length > 0 && (
            <DetailsOfCover
              product={policyProduct}
              coverConfiguration={productConfiguration.policy.cover}
              shouldRespectViewWidth={true}
            />
          )}
        </BladeView>
      </div>
    );
  }
  return null;
};

export interface IPolicySummaryOfCover
  extends RouteComponentProps<IPathParams> {}

interface IPathParams {
  policyId: string;
}
