import * as React from 'react';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { PricingSet, QuoteType } from '@aventus/platform';
import {
  BladeModal,
  BladeBanner,
  BladeSurface,
  BladeButton
} from '@aventus/blade';
import { PriceDisplay, usePaymentPlan } from '@aventus/mvmt-pricing';
import css from './index.css';

export const EstimateOverlay: React.FunctionComponent<IEstimateOverlay> = props => {
  const [isDismissed, setIsDismissed] = React.useState<boolean>(false);

  const paymentPlan = usePaymentPlan(props.quotePricing);

  if (isDismissed) {
    return null;
  }

  return (
    <BladeModal useOnlyModalWrapper={true} close={() => {}}>
      <BladeSurface className={css.estimateOverlay}>
        <BladeBanner
          background={props.productConfiguration.product.bannerImage}
          title={props.productConfiguration.product.label || 'Insurance Quote'}
          className={css.estimateOverlay_banner}
        />

        <PriceDisplay
          hideTitle={true}
          plan={paymentPlan.selectedPricingPlan}
          alternatePlan={paymentPlan.otherPricingPlan}
          productConfiguration={props.productConfiguration}
          quoteType={props.quoteType}
        />

        <BladeSurface className={css.estimateOverlay_disclaimer} isAside={true}>
          <p>
            {props.productConfiguration.quote.estimate?.info ||
              "We've based this estimate on the information you've provided. We need to check a few more things before finalising your quote."}
          </p>
        </BladeSurface>

        <BladeSurface className={css.estimateOverlay_action}>
          <BladeButton
            onClick={() => {
              setIsDismissed(true);
              props.onDismiss?.();
            }}
          >
            {props.productConfiguration.quote.estimate?.startCallToAction ||
              'Get my quote'}
          </BladeButton>
        </BladeSurface>
      </BladeSurface>
    </BladeModal>
  );
};

interface IEstimateOverlay {
  quotePricing: PricingSet;
  productConfiguration: InsuranceProductConfiguration;
  onDismiss?: () => void;
  quoteType?: QuoteType;
}
