import { PolicyCancelSuccessConfirmationMessageConfiguration } from '@aventus/configuration';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { PolicyCancellationInfo, CreditAgreement } from '@aventus/platform';
import { ToHumanReadable } from '@aventus/application-timezone';

export const getPolicyRefundMessage = (
  creditAgreement: CreditAgreement | undefined,
  policyCancellationInfo: PolicyCancellationInfo | undefined,
  confirmationMessages:
    | PolicyCancelSuccessConfirmationMessageConfiguration
    | undefined,
  toHumanReadable: ToHumanReadable
) => {
  if (
    creditAgreement?.paymentPlanType === 'AnnualMonthlyCredit' &&
    !policyCancellationInfo?.refundAmount
  ) {
    return confirmationMessages?.cancelledFinance;
  }

  switch (policyCancellationInfo?.refundReason) {
    case 'WithinStatutoryPeriod':
      const withinRefundAmount =
        policyCancellationInfo?.refundAmount &&
        currencyToHumanReadable({
          value: policyCancellationInfo?.refundAmount.value,
          currencyCode: policyCancellationInfo?.refundAmount.currencyCode
        });
      const withinCancellationEffectDate =
        policyCancellationInfo?.effectiveCancellationDate &&
        toHumanReadable(policyCancellationInfo?.effectiveCancellationDate);

      return stringReplacer(confirmationMessages?.withinStatutoryPeriod, {
        $$_refund: withinRefundAmount,
        ...(withinCancellationEffectDate
          ? {
              $$MATCHDATA_CANCELLATIONEFFECTIVEDATE: withinCancellationEffectDate
            }
          : {})
      });

    case 'OutsideStatutoryPeriod':
      const outsideRefundAmount =
        policyCancellationInfo?.refundAmount &&
        currencyToHumanReadable({
          value: policyCancellationInfo?.refundAmount.value,
          currencyCode: policyCancellationInfo?.refundAmount.currencyCode
        });

      const outsideCancellationEffectDate =
        policyCancellationInfo?.effectiveCancellationDate &&
        toHumanReadable(policyCancellationInfo?.effectiveCancellationDate);

      return policyCancellationInfo?.refundAmount.value > 0
        ? stringReplacer(confirmationMessages?.outsideStatutoryPeriod, {
            $$_refund: outsideRefundAmount,
            ...(outsideCancellationEffectDate
              ? {
                  $$MATCHDATA_CANCELLATIONEFFECTIVEDATE: outsideCancellationEffectDate
                }
              : {})
          })
        : confirmationMessages?.outsideStatutoryPeriodNoRefund;

    default:
      return '';
  }
};
