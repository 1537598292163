import { required } from './required';
import { isTrue } from './is-true';
import { isOnlyAlpha } from './is-only-alpha';
import { isOnlyAlphaAndSpaces } from './is-only-alpha-and-spaces';
import { isOnlyAlphaWithAccents } from './is-only-alpha-with-accents';
import { isOnlyAlphaWithAccentsAndSpaces } from './is-only-alpha-with-accents-and-spaces';
import { isOnlyNumber } from './is-only-number';

export const validators :any = {
  required,
  isTrue,
  isOnlyAlpha,
  isOnlyAlphaAndSpaces,
  isOnlyAlphaWithAccents,
  isOnlyAlphaWithAccentsAndSpaces,
  isOnlyNumber
}

export enum Validators {
  required = 'required',
  isTrue = 'isTrue',
  isOnlyAlpha = 'isOnlyAlpha',
  isOnlyAlphaAndSpaces = 'isOnlyAlphaAndSpaces',
  isOnlyAlphaWithAccents = 'isOnlyAlphaWithAccents',
  isOnlyAlphaWithAccentsAndSpaces = 'isOnlyAlphaWithAccentsAndSpaces',
  isOnlyNumber = 'isOnlyNumber'
}
