import { useStripe } from '@stripe/react-stripe-js';

import useAsyncEffect from 'use-async-effect';
import { PaymentRequest } from '../../interface/payment-request';
import { StripeElement } from '@stripe/stripe-js';

export const usePayWithCreditCard: UsePayWithCreditCard = (
  onComplete,
  isHidden
) => {
  const stripe = useStripe();

  // If the component is in hidden mode, it implies
  // we only want the stripe client initalised, and not
  // the actual input elements.

  useAsyncEffect(async () => {
    if (stripe && isHidden) {
      onComplete({ stripe });
    }
  }, [stripe, isHidden, onComplete]);

  // onReady of the credit card entry, we collect
  // all the data in this context that stripe needs to
  // take payment from the user, and pass it on to the
  // onReady caller.

  const handleOnReady = (element: StripeElement) =>
    onComplete({
      element,
      stripe
    });

  return {
    handleOnReady
  };
};

export type UsePayWithCreditCard = (
  onComplete: (paymentRequest: PaymentRequest) => void,
  isHidden?: boolean
) => {
  handleOnReady: (element: StripeElement) => void;
};
