import React, { useState, useEffect } from 'react';
import { BladeInput_Frame as BladeInputFrame } from '../_frame';
import { useInput } from '../use-input';
import { VoucherInfo } from '@aventus/platform';
import {
  BladeFormFieldset,
  BladeInputText,
  BladeButton,
  BladeMarkdown
} from '@aventus/blade';
import { useCheckoutVoucher } from './use-checkout-voucher';
import { IBladeInputVoucher } from './';
import css from './index.css';
import cx from 'classnames';

export const BladeInputCheckoutVoucher: React.FunctionComponent<
  IBladeInputVoucher
> = props => {
  const inputProps = useInput(props);

  const [voucherInfo, setVoucherInfo] = useState<VoucherInfo | undefined>(
    undefined
  );

  const [voucherError, setVoucherError] = useState<string | undefined>(
    undefined
  );

  const [isWorking, setIsWorking] = useState<boolean>(false);

  const [voucherValue, setVoucherValue] = useState<any>(undefined);

  const { checkout } = useCheckoutVoucher(
    {
      setVoucherInfo,
      setIsWorking,
      setVoucherError
    },
    {
      applyVoucherCode: props.voucherActions?.applyVoucher,
      removeVoucherCode: props.voucherActions?.removeVoucher
    },
    voucherValue,
    props.quoteID,
    props.checkoutCallBack
  );

  useEffect(() => {
    if (props.voucherValues?.voucherInfo) {
      setVoucherInfo(props.voucherValues?.voucherInfo);
      setVoucherValue(props.voucherValues?.voucherInfo.description ?? props.voucherValues?.voucherInfo.code);
    } else {
      setVoucherInfo(undefined);
      setVoucherValue(undefined);
    }
  }, [props.voucherValues?.voucherInfo]);

  return (
    <BladeFormFieldset
      {...(!voucherInfo
        ? {
            ...props.fieldsetProps
          }
        : {
            ...props.fieldsetProps,
            help: null
          })}
      question={
        voucherInfo?.id
          ? props.voucherConfiguration?.voucherAppliedText || 'Voucher Applied'
          : props.fieldsetProps.question
      }
      containerClass={css.voucher_container}
      rightIcon={voucherInfo?.id ? 'faCheck' : null}
    >
      <BladeInputFrame
        hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
        error={inputProps.error}
      >
        <>
          {!voucherInfo?.id && (
            <div className={css.voucher_description}>
              <BladeMarkdown markdown={props.description} />
            </div>
          )}

          <BladeFormFieldset className={css.voucher_fieldset}>
            <BladeInputText
              name="input_voucher"
              value={voucherValue}
              onChange={setVoucherValue}
              className={css.voucher_input}
              disabled={isWorking || Boolean(voucherInfo?.id)}
              variant={props.inputVariant}
            />

            <div
              className={cx(css.voucher_button_wrap, {
                [css.voucher_button_wrap_applied]: voucherInfo?.id
              })}
            >
              {!voucherInfo?.id && (
                <BladeButton
                  onClick={() => checkout.applyVoucher()}
                  className={cx(css.voucher_button)}
                  isDisabled={!voucherValue}
                  isWorking={isWorking}
                >
                  {props.voucherConfiguration?.btnApply || 'Apply'}
                </BladeButton>
              )}

              {!voucherInfo?.id && (
                <BladeButton
                  type="reset"
                  variant="secondary"
                  className={css.voucher_button}
                  onClick={() => {
                    setVoucherValue(undefined);
                    checkout.resetVoucher();
                  }}
                >
                  {props.voucherConfiguration?.btnReset || 'Reset'}
                </BladeButton>
              )}
            </div>

            {voucherInfo?.id && (
              <BladeButton
                variant="secondary"
                className={css.voucher_button}
                onClick={() => {
                  setVoucherValue(undefined);
                  checkout.removeVoucher();
                  checkout.resetVoucher();
                }}
              >
                {props.voucherConfiguration?.btnRemove || 'Remove'}
              </BladeButton>
            )}
          </BladeFormFieldset>

          {voucherError && (
            <div className={css.voucher_error}>{voucherError}</div>
          )}

          {voucherInfo?.terms && (
            <BladeMarkdown
              className={css.voucher_terms}
              markdown={voucherInfo?.terms}
            />
          )}
        </>
      </BladeInputFrame>
    </BladeFormFieldset>
  );
};
