import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

export const IntelligentQuoteSummaryRedirect: React.FunctionComponent<IIntelligentQuoteSummaryRedirect> = (props) => {
  const _token =
    new URLSearchParams(window.location.search).get('token') ||
    undefined;
  const quoteId: string = props.match.params.quoteId;
  const path = props.goToIntelligentQuote(quoteId, undefined, undefined, undefined,undefined, _token);
  const pathDeconstructed = path.split('?');
  
  return (
    <Redirect
      to={{
        pathname: pathDeconstructed[0],
        search: `?${ pathDeconstructed[1] }`,
        state: props.location.state
      }} />
  );

};

export interface IIntelligentQuoteSummaryRedirect extends RouteComponentProps<{
  routeAction: string;
  productId: string;
  lineOfBusiness: string;
  coverType: string;
  quoteId: string;
}> {
  goToIntelligentQuote: (
    quoteActionOrId: string,
    productReference?: string,
    productCoverReference?: string,
    quoteId?: string,
    policyId?: string, 
    token?: string
  ) => string
}
