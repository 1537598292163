import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import cx from 'classnames';
import css from './index.css';
import './radio.css';

export const BladeInputRadio: React.FunctionComponent<IBladeInputRadio> = props => {
  const inputProps = useInput(props);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <div
        className={cx(
          `blade-input-radio`,
          css.scaffold,
          inputProps.className,
          inputProps.value === props.item ? css.scaffold_isSelected : ''
        )}
        onClick={() => inputProps.onChange(props.item)}
      >
        <input
          type={'radio'}
          id={inputProps.id}
          name={inputProps.name}
          className={''}
          checked={inputProps.value === props.item}
          onChange={() => inputProps.onChange(props.item)}
          onFocus={inputProps.onFocus}
          onBlur={inputProps.onBlur}
        />

        <div className={`rs ${css.radio_label} `}></div>

        <BladeInput_Label id={inputProps.id} label={inputProps.label} />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputRadio extends IBladeInput {
  item?: any;
}
