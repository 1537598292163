import React from 'react';
import css from './index.css';
import { BladeLink } from '@aventus/blade';
import { BladeFormFieldset } from '../../index';
import { isEmpty } from 'lodash';

export const AddressInternationalDisplay: React.FunctionComponent<IAddressInternationalDisplay> = ({
  address: { line1, line2, line3, locality, province, postalCode, country },
  helperText = "Can't find your address? Enter it manually",
  handler,
  ...props
}) => {
  return (
    <BladeFormFieldset {...props}>
      <div className={css.addressInternational_display}>
        <div className={css.addressInternational_wrapper}>
          {!isEmpty(line1) && <span className="line1">{line1}</span>}
          {!isEmpty(line2) && <span className="line2">{line2}</span>}
          {!isEmpty(line3) && <span className="line3">{line3}</span>}
          {!isEmpty(locality) && <span className="locality">{locality}</span>}
          {!isEmpty(province) && !isEmpty(province.text) ? (
            <span className="province">{province.text}</span>
          ) : (
            !isEmpty(province) &&
            !isEmpty(province.province) && (
              <span className="province">{province.province}</span>
            )
          )}

          {!isEmpty(postalCode) && (
            <span className="postalCode">{postalCode}</span>
          )}
          {props.showCountry === true && (
            <span className="country">{country.text}</span>
          )}
        </div>

        <BladeLink className={css.addressInternational_link} onClick={handler}>
          {helperText}
        </BladeLink>
      </div>
    </BladeFormFieldset>
  );
};

interface IAddressInternationalDisplay {
  handler: () => void;
  address: any;
  showCountry?: boolean;
  helperText?: string;
}
