import * as React from 'react';

export const BladeFavicon: React.FunctionComponent<IBladeFavicon> = (props) => {

  const [ hasAppendedFavicon, setHasAppendedFavicon ] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      if (!hasAppendedFavicon) {

        const faviconLink = document.createElement('link');
        faviconLink.type = 'image/x-icon';
        faviconLink.rel = 'icon';
        faviconLink.href = props.href;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(faviconLink);

        setHasAppendedFavicon(true);
      }
    }
  );

  return null;
}

interface IBladeFavicon {
  /** An absolute URL for the icon. It can be in any format, but must be hosted. */
  href: string
}
