import { getAtomValue } from './get-atom-value';

export function areAtomsEqual(
  atomKey: string,
  anotherAtomKey: string
): boolean {
  if (!atomKey || !anotherAtomKey) {
    throw new Error('`areAtomsEqual` expects two atom keys to be defined');
  }

  const atomValue: string | undefined = getAtomValue(atomKey);
  const theOtherAtomValue: string | undefined = getAtomValue(anotherAtomKey);

  if (
    !atomValue ||
    typeof atomValue !== 'string' ||
    !theOtherAtomValue ||
    typeof theOtherAtomValue !== 'string'
  ) {
    return false;
  }

  return atomValue.toLowerCase().trim() ===
    theOtherAtomValue.toLowerCase().trim()
    ? true
    : false;
}
