import * as React from 'react';
import { useFontFace } from './use-font-face';

export const BladeFontFace: React.FunctionComponent<IBladeFontFace> = props => {
  useFontFace(props.fonts);
  return null;
};

export interface IBladeFontFace {
  fonts: BladeFontFace[];
}
