import * as React from 'react';
import { IBladeFormFieldset, IBladeInputText } from '@aventus/blade';
import { ApplicationError } from '@aventus/errors';
import { OracleMotorTypes } from '@aventus/configuration';
import { MotorWebCarRegOrVin } from '@aventus/oracle-motor-web';
import { GlassesCarDetails } from '@aventus/oracle-glasses';

export function switchMotorOracle (
  props: IBladeFormFieldset & IBladeInputText,
  oracleName: OracleMotorTypes,
  oracleUrl: string,
  oracleToken?: string,
  enrichmentUrl?: string,
  enrichmentToken?:string
) {

  switch (oracleName) {

    case 'motorweb':
      return (
        <MotorWebCarRegOrVin
          { ...props }
          oracleUrl={ oracleUrl }
          enrichmentUrl= { enrichmentUrl }
          enrichmentToken={ enrichmentToken } />
      );

    case 'glasses':

      if (!oracleToken) {
        throw new ApplicationError(
          'Application is trying to use the `GlassesCarDetails` integration, but no auth token was provided.'
        );
      }

      return (
        <GlassesCarDetails
          { ...props }
          oracleUrl={ oracleUrl }
          oracleToken={ oracleToken } />
      );

    default:
      return null;

  }

}
