export const EXPERIENCE_TYPE:string = 'experience';

export function experienceMapper(rest:any, h:IExperience):IExperienceMap {
  return {
    ...rest,
    ...{
      [ h.key ]: {
        transform: h.transform,
        transition: (property) => `${ property } ${ h.transition }`
      }
    }
  };
}

export interface IExperience {
  key:string,
  transform:string,
  transition:string
}

export interface IExperienceMap {
  [key:string]: {
    transform:string
    transition:(property:string) => string
  }
}
