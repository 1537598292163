import * as React from 'react';
import { AdjustmentType, MtaInformation } from '@aventus/platform';
import { CheckoutAdjustmentInformationConfiguration } from '@aventus/configuration';
import { BladeMessage } from '@aventus/blade';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { useApplicationTimezone } from '@aventus/application-timezone';

export const AdjustInfo: React.FunctionComponent<IAdjustInfo> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  switch (props.adjustmentType) {
    case 'FixedSingleNoFee':
      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.annualNoFeeMessage}
        />
      );

    case 'FixedSingleRefund':
      const fixedSingleRefundMessage = stringReplacer(
        props.adjustmentConfiguration.annualRefundMessage!,
        {
          $$_amount_to_pay_now: currencyToHumanReadable(
            props.adjustmentInformation.amountToPayNow
          )
        }
      );

      return (
        <BladeMessage variant={'info'} detail={fixedSingleRefundMessage} />
      );

    case 'FixedSinglePayment':
      const fixedSinglePaymentMessage = stringReplacer(
        props.adjustmentConfiguration.annualPaymentMessage!,
        {
          $$_amount_to_pay_now: currencyToHumanReadable(
            props.adjustmentInformation.amountToPayNow
          )
        }
      );

      return (
        <BladeMessage variant={'info'} detail={fixedSinglePaymentMessage} />
      );

    case 'FinanceNoFee':
      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.monthlyNoFeeMessage}
        />
      );

    case 'FinanceRefund':
      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.monthlyRefundMessage}
        />
      );

    case 'FinancePayment':
      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.monthlyPaymentMessage}
        />
      );

    case 'FinanceUpdate':
      if (
        props.adjustmentInformation.recurringPaymentChange !== null &&
        props.adjustmentInformation.recurringPaymentChange.isIndicative ===
          true &&
        props.adjustmentConfiguration.monthlyFinanceEstimateMessage
      ) {
        return (
          <BladeMessage
            variant={'info'}
            detail={stringReplacer(
              props.adjustmentConfiguration.monthlyFinanceEstimateMessage,
              {
                $$_change_in_monthly_price: currencyToHumanReadable(
                  props.adjustmentInformation.recurringPaymentChange
                    .changeInMonthlyPrice
                )
              }
            )}
          />
        );
      }

      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.monthlyUpdateFinanceMessage}
        />
      );

    case 'SubscriptionNoFee':
      return (
        <BladeMessage
          variant={'info'}
          detail={props.adjustmentConfiguration.subscriptionNoFeeMessage}
        />
      );

    case 'SubscriptionPayment':
      const paymentMessage = stringReplacer(
        props.adjustmentConfiguration.subscriptionPaymentMessage!,
        {
          $$_total_new_monthly_price: currencyToHumanReadable(
            props.adjustmentInformation.recurringPaymentChange
              .totalNewMonthlyPrice
          ),
          $$_first_payment: toHumanReadable(
            props.adjustmentInformation.recurringPaymentChange.firstPayment
          ),
          $$_amount_to_pay_now: currencyToHumanReadable(
            props.adjustmentInformation.amountToPayNow
          ),
          $$_last_4_digits: `${props.last4Digits || ''}`
        }
      );

      return <BladeMessage variant={'info'} detail={paymentMessage} />;

    case 'SubscriptionRefund':
      const refundMessage = stringReplacer(
        props.adjustmentConfiguration.subscriptionRefundMessage!,
        {
          $$_total_new_monthly_price: currencyToHumanReadable(
            props.adjustmentInformation.recurringPaymentChange
              .totalNewMonthlyPrice
          ),
          $$_first_payment: toHumanReadable(
            props.adjustmentInformation.recurringPaymentChange.firstPayment
          ),
          $$_amount_to_pay_now: currencyToHumanReadable(
            props.adjustmentInformation.amountToPayNow
          ),
          $$_last_4_digits: `${props.last4Digits || ''}`
        }
      );

      return <BladeMessage variant={'info'} detail={refundMessage} />;
  }
};

export interface IAdjustInfo {
  adjustmentType: AdjustmentType;
  adjustmentInformation: MtaInformation;
  adjustmentConfiguration: CheckoutAdjustmentInformationConfiguration;
  last4Digits?: string;
}
