import { AUTH_SOCIAL_AUTH_REQUEST, AUTH_SOCIAL_AUTH_SUCCESS, AUTH_SOCIAL_AUTH_FAILURE } from './types';
import { API_SYM } from '@aventus/middleware-api';
import queueAlertAction from '../../../api/core/alerts/queue-alert/actions';
import getExternalId from '../../../api/core/tracking/get-external-id';
import setExternalIdAction from '../../../api/core/tracking/set-external-id/actions';

export default function socialAuthAction(token, userDetails, accountType, authenticationProvider) {
  return (dispatch, getStore) => {
    const externalId = getExternalId(getStore());
    dispatch({
      type: 'API_REQUEST',
      [API_SYM]: {
        requestConfig: {
          url: 'user/socialsignup',
          method: 'POST',
          data: {
            userDetails: userDetails,
            externalId: externalId,
            authenticationProvider: authenticationProvider
          }
        },
        actions: [ socialAuthRequestAction, socialAuthSuccessAction, socialAuthFailureAction ],
        passToAction: {
          token,
          accountType
        }
      }
    });
  }
}

export function socialAuthRequestAction() {
  return {
    type: AUTH_SOCIAL_AUTH_REQUEST
  };
}

export function socialAuthSuccessAction(res, passToAction) {
  const {
    externalID,
    email
  } = res.user;

  const {
    token,
    accountType
  } = passToAction;

  return (dispatch) => {
    dispatch({
      type: AUTH_SOCIAL_AUTH_SUCCESS,
      token,
      email,
      accountType
    })

    dispatch(setExternalIdAction(externalID, email));
  }
}

export function socialAuthFailureAction(res) {
  return (dispatch) => {
    dispatch({
      type: AUTH_SOCIAL_AUTH_FAILURE
    })

    dispatch(queueAlertAction({
      type: 'error',
      message: res.message,
      location: false
    }));
  }
}
