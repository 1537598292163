import chroma from 'chroma-js';

export const PALETTE_TYPE:string = 'palette';

export function paletteMapper(rest:any, h:IPalette):IPaletteMap {

  // TODO
  // This can move into another function.

  const theColor = chroma(h.color);

  const shades = [ -9, -8, -7, -6, -5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9 ].reduce(
    (rest:any, p:number):any => {
      const desensitizedShade: number = p / 3;

      if (p < 0) {
        return {
          ...rest,
          [`m${ Math.abs(p) }`]: theColor.darken(Math.abs(desensitizedShade)).hex()
        }
      } else {
        return {
          ...rest,
          [`p${ p }`]: theColor.brighten(desensitizedShade).hex()
        }
      }
    },
    {}
    );

  return {
    ...rest,
    ...{
      [ h.key ]: {
        color: h.color,
        shades,
        ...(typeof h.onColor !== 'undefined')
          ? { onColor: h.onColor }
          : {}
      }
    }
  };
}

export interface IPalette {
  key:string
  color:string
  onColor?:string
}

export interface IPaletteMap {
  [key:string]: {
    color:string
    shades:any
    onColor?:string
  }
}
