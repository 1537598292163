import update from 'immutability-helper';

export function startUserLoginRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isStartingUserLogin: { $set: true },
          successfullyStartedUserLogin: { $set: false },
          userHasPreviouslySignedIn: { $set: undefined }
        }
      })
    }
  };
}

export function startUserLoginSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isStartingUserLogin: { $set: false },
          successfullyStartedUserLogin: { $set: true },
          userHasPreviouslySignedIn: { $set: action.hasLoggedInPreviously }
        }
      })
    }
  };
}

export function startUserLoginFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isStartingUserLogin: { $set: false },
            successfullyStartedUserLogin: { $set: false },
            userHasPreviouslySignedIn: { $set: undefined }
          }
        }
      })
    }
  };
}
