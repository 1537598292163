import * as React from 'react';
import { updateForm } from '@aventus/formio';

export function useClaims(
  claims: Claims = [],
  defaultFormState: {
    [key: number]: {};
  }
): IUseClaims {
  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(false);

  const [formState, setFormState] = React.useState<any>(defaultFormState);

  function updateFormState(formStateAddress: string, value: any) {
    const updatedFormState = updateForm(formState, formStateAddress, {
      $set: value
    });
    setFormState(updatedFormState);
  }

  function addToClaims(item: any) {
    return [...(claims || []), item];
  }

  function deleteFromClaims(index: number) {
    const _claims = [...claims];

    _claims.splice(index, 1);

    return _claims;
  }

  function updateInClaims(item: any, index: number) {
    const _claims = [...claims];
    _claims[index] = item;
    return _claims;
  }

  return {
    isFormOpen,
    setIsFormOpen,
    formState,
    setFormState,
    updateFormState,
    addToClaims,
    deleteFromClaims,
    updateInClaims
  };
}

export type Claims = Array<any>;

export interface IUseClaims {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean, index?: number) => void;
  formState: any;
  setFormState: (value: any) => void;
  updateFormState: (formStateAddress: string, value: any) => void;
  addToClaims: (item: any) => Claims;
  deleteFromClaims: (index: number) => Claims;
  updateInClaims: (item: any, index: number) => Claims;
}
