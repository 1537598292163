import update from 'immutability-helper';

export function changePasswordRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isChangingPassword: { $set: true }
        }
      })
    }
  };
}

export function changePasswordSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isChangingPassword: false,
            successfullyChangedPassword: true
          }
        }
      })
    }
  };

}

export function changePasswordFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isChangingPassword: false,
            successfullyChangedPassword: false
          }
        }
      })
    }
  }
}
