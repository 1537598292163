import { useEffect, useState } from 'react';

/**
 * React useEffect hook used as a bridge
 * from an async stack to a React component stack,
 * in order to throw errors caught by componentDidCatch.
 * Error boundaries do not catch errors in asynchronous
 * code, so this bridge allows us bridge that divide until
 * React developers better support for this.
 *
 * @example
 *  const { throwError } = useError()
 *  throwError(new Error("Something happened"))
 */

export function useError(): UseError {
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return {
    throwError: setError
  };
}

export interface UseError {
  throwError: (error: Error | any) => void;
}
