import * as React from 'react';
import { useRunner } from '../runner';
import css from './index.css';
import cx from 'classnames';

export const BladeSurface: React.FunctionComponent<IBladeSurface> = props => {
  const { trim, layer } = useRunner();

  return (
    <div
      ref={props.elementRef}
      className={cx(
        'blade-surface',
        css.base_surface,
        {
          [css.surface_shouldRespectViewWidth]:
            props.shouldRespectViewWidth === true
        },
        { [css.surface_layerCombined]: layer === 'combine' },
        { [css.base_surface_isScrollable]: props.isScrollable === true },
        props.className
      )}
    >
      <div
        style={props.style}
        className={cx(
          css.surface,
          { [css.surface_isFullHeight]: props.shouldFillHeight === true },
          {
            [css.surface_shouldRespectViewWidth]:
              props.shouldRespectViewWidth === true
          },
          { [css.surface_layerCombined]: layer === 'combine' },
          { [css.surface_isAside]: props.isAside === true },
          { [css.surface_isMain]: !props.isAside }
        )}
      >
        {(props.title || props.actions) && (
          <div
            className={cx(css.surface_titleBar, {
              [css.surface_titleBar_trimDivider]: trim === 'divider'
            })}
          >
            <div
              className={
                props.borderlessButton
                  ? css.borderless_button_titleBar
                  : css.surface_titleBar_title
              }
            >
              {props.title && <h3>{props.title}</h3>}
              {props.description && (
                <p className={css.surface_titleBar_description}>
                  {props.description}
                </p>
              )}
              {props.helpLink && <>{props.helpLink}</>}
            </div>

            {props.actions && (
              <div className={css.surface_titleBar_actions}>
                {props.actions.map(action => (
                  <div
                    onClick={() => action.onAction()}
                    className={
                      props.borderlessButton
                        ? css.borderless_button
                        : css.surface_titleBar_actions_action
                    }
                  >
                    {action.icon}
                  </div>
                ))}
              </div>
            )}

            {props.titleBarContentRight && props.titleBarContentRight}
          </div>
        )}

        <div className={cx(css.surface_content, props.classNameContent)}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export interface IBladeSurface {
  /** Bind a React.createRef() instance to the wrapping div's ref property */
  elementRef?: any;
  /** Custom style override */
  style?: any;
  /** Add a title within a heading bar at the top of the frame */
  title?: string;
  description?: string;
  /** Add an action */
  actions?: IBladeSurfaceAction[];
  /** Add some custom content on the right of the title bar */
  titleBarContentRight?: React.ReactNode;
  /** Aside content. This will tint the background color */
  isAside?: boolean;
  className?: string;
  classNameContent?: string;
  isScrollable?: boolean;
  /** If set to true, then the surface component will check certain design system
  properties, and make sure that any adjustments to the alignments are mindful of the fact that
  they are top level components that view the view width. */
  shouldRespectViewWidth?: boolean;
  /** Flag which will set the content wrapper to fill the height to 100%. This is useful for aligning cards in a row */
  shouldFillHeight?: boolean;

  borderlessButton?: boolean;
  /** React element which shows as a help link and appears underneath the title description */
  helpLink?: React.ReactNode;
}

export interface IBladeSurfaceAction {
  onAction: () => void;
  icon: React.ReactElement;
}
