import * as React from 'react';
import { RiskProposer } from '@aventus/platform';
import { BladeList, BladeSurface } from '@aventus/blade';
import { ToHumanReadable } from '@aventus/application-timezone';
import { getPolicyHolderDetails } from '../../services/get-policy-holder-details';

export const YourDetails: React.FunctionComponent<IYourDetails> = props => {
  return (
    <BladeSurface
      title={'Your details'}
      shouldRespectViewWidth={props.shouldRespectViewWidth}
    >
      <BladeList
        isFlush={true}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
        list={getPolicyHolderDetails(
          props.proposer,
          props.toHumanReadable,
          props.showEmail)}
      />
    </BladeSurface>
  );
};

export interface IYourDetails {
  proposer: RiskProposer;
  toHumanReadable: ToHumanReadable;
  shouldRespectViewWidth?: boolean;
  showEmail: boolean;
}
