import * as React from 'react';
import { IBladeAsk } from '../index';
import { BladeButton } from '../../button';
import { BladeIcon } from '../../icon';
import { BladeSurface } from '../../surface';
import { BladeLink } from '../../link';
import { Transition, Spring, animated } from 'react-spring/renderprops';
import MediaQuery from 'react-responsive';
import css from './index.css';
import cx from 'classnames';
import { useForm } from '../../form';
import { useOneByOne } from './use-one-by-one';

export const BladeAskOneByOne: React.FunctionComponent<IBladeAsk> = props => {
  const { questions, submit } = useForm(
    props.getQuestionsToRender,
    props.getSubmitToRender
  );

  const {
    animatedQuestionRef,
    frameRef,
    pointer,
    isForward,
    frameHeight,
    nextQuestion,
    previousQuestion,
    calculateHeightWithDelay
  } = useOneByOne(questions);

  return (
    <div className={cx('blade-ask-one-by-one', css.oneByOne)}>
      {pointer !== 0 && (
        <MediaQuery query={'(max-width:699px)'}>
          <BladeLink
            onClick={() => previousQuestion()}
            className={css.oneByOne_navLink}
          >
            {`‹ Previous`}
          </BladeLink>
        </MediaQuery>
      )}

      <BladeSurface elementRef={frameRef} className={css.oneByOne_frame}>
        <div className={css.oneByOne_question} style={{ height: frameHeight }}>
          <MediaQuery query={'(min-width:700px)'}>
            <div
              className={`
                ${css.oneByOne_question_navButton}
                ${pointer === 0 ? css.oneByOne_question_isHidden : ''}
              `}
              onClick={() => previousQuestion()}
            >
              <BladeIcon name={'faCaretLeft'} />
            </div>
          </MediaQuery>

          <div className={css.oneByOne_question_input}>
            <Transition
              native
              items={pointer}
              config={{ tension: 2000, friction: 100 }}
              from={{
                opacity: 0,
                transform: isForward
                  ? 'translate3d(10%,0,0)'
                  : 'translate3d(-10%,0,0)'
              }}
              enter={{
                opacity: 1,
                transform: 'translate3d(0%,0,0)'
              }}
              leave={{
                opacity: 0,
                transform: isForward
                  ? 'translate3d(-10%,0,0)'
                  : 'translate3d(10%,0,0)'
              }}
              onStart={() => calculateHeightWithDelay()}
              onRest={() => calculateHeightWithDelay()}
            >
              {questionIndex => transitionStyle => (
                <animated.div
                  id={'animo'}
                  ref={animatedQuestionRef}
                  className={css.oneByOne_question_input_wrap}
                  style={transitionStyle}
                >
                  {questions[questionIndex]}
                </animated.div>
              )}
            </Transition>
          </div>

          <MediaQuery query={'(min-width:700px)'}>
            <div
              className={`
                ${css.oneByOne_question_navButton}
                ${
                  pointer === questions.length - 1
                    ? css.oneByOne_question_isHidden
                    : ''
                }
                ${true ? '' : css.oneByOne_question_isDisabled}
              `}
              onClick={() => nextQuestion()}
            >
              <BladeIcon name={'faCaretRight'} />
            </div>
          </MediaQuery>
        </div>
      </BladeSurface>

      {pointer !== questions.length - 1 && (
        <MediaQuery query={'(max-width:699px)'}>
          <BladeButton
            className={`
                ${true ? '' : 'disabled'}
              `}
            onClick={() => nextQuestion()}
          >
            {'Next'}
          </BladeButton>
        </MediaQuery>
      )}

      {pointer === questions.length - 1 && (
        <Spring
          config={{ tension: 2000, friction: 100 }}
          from={{ opacity: 0, transform: 'translate3d(0,100px,0)' }}
          to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
        >
          {transitionProps => (
            <div className={css.oneByOne_submit} style={transitionProps}>
              {submit}
            </div>
          )}
        </Spring>
      )}
    </div>
  );
};
