import { currencyPenceToPounds } from '../currency-pence-to-pounds';

/**
 * Convert our currency object, into a human readable format. A string with the
 * correct currency symbol, and locale formatting.
 * For example: { value: '5000', currencyCode: 'GBP' } = "£5,000"
 * @returns {string}
 * @param money Aventus money object eg { value: '5000', currencyCode: 'GBP' }
 * @param {Object} options Options
 * @param {boolean | undefined} options.forceMinor Force the minor current amount to be
 *                                     returned (eg cents or pence)?
 * @param {boolean | undefined} options.isMajorCurrency Force pounds over pence.
 */
export function currencyToHumanReadable(
  money: Money,
  options?: { forceMinor?: boolean; isMajorCurrency?: boolean }
): string {
  const { value = 0, currencyCode = 'GBP' } = money;

  // Setup default options
  const _options = Object.assign(
    { forceMinor: false, isMajorCurrency: false },
    options
  );

  let poundsPenceValue: string | number;

  if (_options.isMajorCurrency === false) {
    poundsPenceValue = currencyPenceToPounds(value);
  } else {
    poundsPenceValue = value;
  }

  const locale = convertLocale(currencyCode);
  const localeCurrencyCode = convertCurrencyCode(currencyCode);

  const decimal = Number(poundsPenceValue) % 1;
  const minFractionDigits =
    decimal === 0 && _options.forceMinor === false ? 0 : 2;

  const formattingConfig = {
    style: 'currency',
    currency: localeCurrencyCode,
    minimumFractionDigits: minFractionDigits
  };

  if (typeof Intl !== 'undefined' && Intl.NumberFormat) {
    const formatter = new Intl.NumberFormat(locale, formattingConfig);
    return formatter.format(Number(poundsPenceValue));
  } else {
    return Number(poundsPenceValue).toLocaleString(locale, formattingConfig);
  }
}

// in case we will start displaying different currencies
// these methods below are for automatically customising options for the 'toLocaleString() method.
// Add additional cases for additional currencyCodes

export function convertLocale(code: string) {
  switch (code) {
    case 'GBP':
      return 'en-GB';
    case 'AUD':
      return 'en-AU';
    default:
      return 'en-GB';
  }
}

export function convertCurrencyCode(code: string) {
  switch (code) {
    default:
      return code;
  }
}

interface Money {
  value: number;
  currencyCode: string;
}
