import { IPolicyDetailEditSession } from './quote-edit-session';

export * from './quote-edit-session';
export * from './tobes';

export const InitialEditSessionState: IPolicyDetailEditSession = {
  createdByID: '',
  dateCreated: '',
  dateUpdated: '',
  id: '',
  policyDetailHeaderID: '',
  sourcePolicyDetailHistoryID: '',
  policyDetail: {
    sections: [],
    totalGrossPremium: null,
    totalNetPremium: null,
    totalSectionTax: null,
    totalSectionTaxAggregate: [],
    totalCommission: null,
    totalNetPremiumInsurer: null,
    totalNetDiscount: null,
    totalGrossDiscount: null,
    fees: [],
    totalIncludingFees: null,
    productID: 0,
    productReferenceID: '',
    productVersion: 0,
    version: 0,
    declineReasons: [],
    referralReasons: [],
    endorsements: []
  },
  risk: {
    core: null,
    exposures: []
  },
  state: 'Active',
  version: 0
};
