/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useCombobox, UseComboboxStateChange } from 'downshift';

import { BladeLoader } from '../../loader';
import { useInput } from '../use-input';
import { BladeInput_Frame } from '../_frame';
import css from './index.css';
import inputCss from '../text/index.css';
import classNames from 'classnames';

export interface IBladeComboBoxProps<ComboboxItemType>
  extends Omit<IBladeInput, 'onChange'> {
  isLoading?: boolean;
  items?: ComboboxItemType[];
  onInputValueChange?: (
    changes: UseComboboxStateChange<ComboboxItemType>
  ) => void;
  onSelectedItemChange?: (
    changes: UseComboboxStateChange<ComboboxItemType>
  ) => void;
  itemToString: (item: ComboboxItemType | null) => string;
  itemToDisplay?: (item: ComboboxItemType | null) => React.ReactElement | null;
  autoFocus?: boolean;
}

export const BladeComboBox = <ComboboxItemType extends unknown>(
  props: React.PropsWithChildren<IBladeComboBoxProps<ComboboxItemType>>
): React.ReactElement | null => {
  // @ts-ignore we don't want to do any magic onChange handling here, it's yuck.
  const inputProps = useInput(props);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu
  } = useCombobox<ComboboxItemType>({
    items: props.items ?? [],
    itemToString: props.itemToString,
    onInputValueChange: props.onInputValueChange,
    onSelectedItemChange: props.onSelectedItemChange
  });

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      {inputProps.label !== undefined && (
        <label {...getLabelProps}>{inputProps.label}</label>
      )}
      {inputProps.questionImage && (
        <div>
          <img
            src={inputProps.questionImage}
            alt={'question'}
            className={css.imagebox}
          />
        </div>
      )}
      <div {...getComboboxProps()} className={css.itemListParent}>
        <div className={inputCss.text_scaffold}>
          <input
            {...getInputProps({
              placeholder: 'Start typing address to search',
              disabled: props.disabled ?? false,
              onFocus: event => {
                isOpen === false && openMenu();
                props.onFocus?.(event);
              }
            })}
            className={inputProps.className ?? ''}
            autoFocus={props.autoFocus ?? false}
          />
          <div className={inputCss.text_action}>
            {props.isLoading && (
              <BladeLoader className={inputCss.text_action_loader} />
            )}
          </div>
        </div>
        <ul
          {...getMenuProps()}
          className={classNames(css.itemList, {
            [`${css.itemListOpen}`]:
              isOpen && props.items && props.items?.length > 0
          })}
        >
          {isOpen &&
            props.items?.map((item, index) => (
              <li
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                className={classNames(css.itemListItem, {
                  [css.itemListItemHighlighted]: highlightedIndex === index
                })}
              >
                {props.itemToDisplay !== undefined
                  ? props.itemToDisplay(item)
                  : props.itemToString(item)}
              </li>
            ))}
        </ul>
      </div>
    </BladeInput_Frame>
  );
};
