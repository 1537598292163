import * as React from 'react';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetProductConfiguration(
  productReference?: string,
  productCoverReference?: string
): UseGetProductConfiguration {
  const client = useConfigurationClient();
  const [productConfiguration, setProductConfiguration] = React.useState<
    InsuranceProductConfiguration | undefined
  >(undefined);

  useAsyncEffect(async () => {
    if (productReference && productCoverReference) {
      const _productConfiguration = await client.getProductConfiguration(
        productReference,
        productCoverReference
      );
      setProductConfiguration(_productConfiguration);
    }
  }, [productReference, productCoverReference]);

  return {
    productConfiguration
  };
}

interface UseGetProductConfiguration {
  productConfiguration: InsuranceProductConfiguration | undefined;
}
