import { currencyToHumanReadable } from '@aventus/pocketknife';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { AdditionalProducts, IRisk } from '@aventus/platform';

export function getMotorPolicyDetails(
  risk: IRisk,
  additionalProducts?: AdditionalProducts[]
) {
  /*
   * This is quite specific to the way Stella's risk is shaped, if we have another client that does motor policies then it may not work
   */
  const { toHumanReadable } = useApplicationTimezone();
  var optionalExtras = parseVehicleAddons(risk.addon, additionalProducts);
  var motorList = [
    {
      label: 'Your car',
      isBoldFirstItem: true,
      isCompactList: true,
      value: [
        `${risk.vehicle.specification.year} ${risk.vehicle.specification.make} ${risk.vehicle.specification.model}`,
        `${risk.vehicle.specification.variant || ''} ${
          risk.vehicle.specification.series || ''
        } ${risk.vehicle.specification.bodyType || ''} ${
          risk.vehicle.specification.transmission || ''
        } ${risk.vehicle.specification.cc || ''} ${
          risk.vehicle.specification.engineConfig || ''
        }`
      ]
    },
    {
      label: 'Registration',
      value: risk.vehicle.specification.registration
        ? risk.vehicle.specification.registration
        : 'TBA'
    },
    {
      label: 'Start date',
      value: toHumanReadable(risk.coverStartDate)
    },
    {
      label: 'Basic excess',
      value: currencyToHumanReadable(risk.excesses.totalExcess)
    }
  ];

  if (risk.vehicle?.insuredValue != null) {
    motorList.push({
      label: 'Agreed value',
      value: currencyToHumanReadable(risk.vehicle?.insuredValue?.value)
    });
  }
  if (optionalExtras.length > 0) {
    motorList.push({
      label: 'Optional cover',
      value: optionalExtras
    });
  }

  return motorList;
}

function parseVehicleAddons(
  addon: any,
  additionalProducts?: AdditionalProducts[]
): string[] {
  var options = [];
  var roadsideAssistanceProduct = additionalProducts?.filter(ad =>
    ad.referenceID.endsWith('_ratecomp_roadsideAssistance')
  );

  if (
    addon.roadsideAssistance ||
    (roadsideAssistanceProduct && roadsideAssistanceProduct.length > 0)
  ) {
    options.push('Roadside Assistance');
  }
  if (addon.hireCar) {
    options.push('Hire Car – Extra');
  }
  if (addon.excessFreeWindscreen) {
    options.push('‘No Excess’ Windscreen');
  }
  return options;
}
