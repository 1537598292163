import update from 'immutability-helper';

export function signInWithLinkRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isSigningInWithLink: { $set: true },
          successfullySignedInWithLink: { $set: false }
        }
      })
    }
  };
}

export function signInWithLinkSuccessReducer(state, action) {
  const { email, token, accountType } = action;

  return {
    ...state,
    ...{
      auth: update(state.auth, {
        user: {
          $set: {
            email,
            token,
            socialAccount: true,
            accountType
          }
        },
        _flags: {
          $set: {
            isSigningInWithLink: false,
            successfullySignedInWithLink: true
          }
        }
      })
    }
  };
}

export function signInWithLinkFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isSigningInWithLink: false,
            successfullySignedInWithLink: false
          }
        }
      })
    }
  };
}
