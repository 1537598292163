import * as React from 'react';
import { PricingPaymentPlanConfiguration } from '@aventus/configuration';
import { PricingSet, PricingPlan } from '@aventus/platform';
import { BladeFormFieldsetRadio, BladeSurface } from '@aventus/blade';
import { findPaymentPlanConfiguration } from '@aventus/mvmt-pricing';
import css from './index.css';

export const PaymentPlanSelector: React.FunctionComponent<IPaymentPlanSelector> = props => {
  if (props.plans.plans.length === 1) {
    return null;
  }

  return (
    <BladeSurface
      shouldRespectViewWidth={true}
      className={css.paymentPlanSelector}
    >
      <BladeFormFieldsetRadio
        name={'payment-plan-select'}
        question={props.label || 'Select payment plan'}
        description={props.description}
        options={props.plans?.plans.map((plan: PricingPlan) => ({
          label:
            findPaymentPlanConfiguration(
              plan.paymentPlan.type,
              props.plansConfiguration
            )?.label || plan.paymentPlan.type,
          name: plan.paymentPlan.type,
          item: plan
        }))}
        value={props.selectedPaymentPlan}
        onChange={props.setSelectedPricingPlan}
      />
    </BladeSurface>
  );
};

interface IPaymentPlanSelector {
  label?: string;
  description?: string;
  plans: PricingSet;
  plansConfiguration: PricingPaymentPlanConfiguration[];
  selectedPaymentPlan?: PricingPlan;
  setSelectedPricingPlan: (pricingPlan: PricingPlan) => void;
}
