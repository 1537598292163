import * as React from 'react';

export const ConfigurationClientContext = React.createContext<any>(undefined);

export function useConfigurationClient () {
  const client = React.useContext(ConfigurationClientContext);
  if (client === undefined) {
    throw new Error('useConfigurationClient must be used within a ConfigurationClientContext.Provider');
  }
  return client;
}
