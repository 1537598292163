import * as React from 'react';
import {
  Quote,
  QuoteEmbargo,
  PricingSet,
  Risk,
  MtaInformation,
  PaymentPlanType,
  CallingApplication,
  CustomConfig
} from '@aventus/platform';
import {
  InsuranceProductConfiguration,
  PaymentProvidersConfiguration,
  OrganisationConfiguration,
  ProductInterfaceConfiguration,
  ApplicationConfiguration
} from '@aventus/configuration';
import { ApplicationError } from '@aventus/errors';
import { BladeProgressBar, BladeView } from '@aventus/blade';
import { BladeInlineErrorBoundary } from '@aventus/blade/inline-error';
import {
  useDocumentTitle,
  useMediaQuery,
  useBeforeUnload
} from '@aventus/pockethooks';
import { useTrackView, useTrack } from '@aventus/application-tracking';
import { Lifecycles } from '../../interface/lifecycles';
import { ICheckoutBoundaryError } from '../../boundary/types';
import cx from 'classnames';
import css from './index.css';
import { useCheckout } from './use-checkout';
import { CheckoutNew } from './checkout-new';
import { CheckoutAdjust } from './checkout-adjust';
import { currencyPenceToPounds } from '@aventus/pocketknife/currency-pence-to-pounds';
import { ITobesPage } from '@aventus/platform-client-context/models/tobes';

export const Checkout: React.FunctionComponent<ICheckout> = props => {
  const { track } = useTrack();
  useTrackView('Checkout');
  useDocumentTitle('Checkout | ' + props.organisationConfiguration?.name);

  const progressBarPages: ITobesPage[] =
    JSON.parse(String(sessionStorage.getItem('question_pages'))) || [];

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  React.useEffect(() => {
    if (
      props.quoteEmbargoes &&
      Array.isArray(props.quoteEmbargoes) &&
      props.quoteEmbargoes.length > 0 &&
      props.defaultPaymentPlanType
    ) {
      props.goToQuoteSummary(props.quote.id, props.defaultPaymentPlanType);
    }
  }, [props.quoteEmbargoes]);

  const { selectedPricingPlan } = useCheckout(
    props.quote,
    props.quotePricing,
    props.defaultPaymentPlanType,
    undefined,
    props.lifecycles,
    props.productConfiguration,
    props.organisationConfiguration
  );

  React.useEffect(() => {
    track({
      event: 'aventus.checkout',
      quoteId: props.quote.id,
      productReference: props.productConfiguration.productReference,
      productCoverReference: props.productConfiguration.productCoverReference,
      policyGross: selectedPricingPlan?.totalPayable.gross.value
        ? currencyPenceToPounds(selectedPricingPlan?.totalPayable.gross.value)
        : undefined,
      quoteType: props.quote.quoteType
    });
  }, []);

  useBeforeUnload(() =>
    track({
      event: 'aventus.checkout.abandon',
      quoteId: props.quote.id,
      productReference: props.quote.productReferenceID,
      productCoverReference: props.quote.coverType,
      policyGross: selectedPricingPlan?.totalPayable.gross.value
        ? currencyPenceToPounds(selectedPricingPlan?.totalPayable.gross.value)
        : undefined,
      quoteType: props.quote.quoteType
    })
  );

  React.useEffect(() => {
    // We need to make sure the requested quote hasn't already
    // been purchased.

    if (props.quote.converted) {
      props.onIsAlreadyConverted?.();
      return;
    }

    // Not the nicest UX to kick the user back to dashboard with no
    // reason, but consider this temporary.

    if (props.quote.quoteStatus === 'Invalidated') {
      props.onIsInvalid?.();

      return;
    }
  });

  // feature flag the postPurchase Signup

  // We must make sure the user signs in or creates an account
  // beforing continuing to checkout.

  React.useEffect(() => {
    if (
      (!props.organisationConfiguration.checkout ||
        props.organisationConfiguration.checkout.postPurchaseSignup !== true) &&
      !props.isAuthenticated()
    ) {
      props.onIsUnauthenticated?.();
      return;
    } else if (
      props.quote.quoteType === 'Renewal' &&
      !props.isAuthenticated()
    ) {
      props.lifecycles.onClearPaymentToken();
      props.onIsUnauthenticated?.();
      return;
    }
  });

  // We've got a quote, so we can render the checkout interface.
  // We need to make sure we've also retrieved the copy configuration for
  // this module, and that the quote has not already been purchased.

  return (
    <>
      {props.quote.quoteType !== 'Renewal' &&
        props.customConfiguration &&
        props.customConfiguration.userInterface.progressBar &&
        props.customConfiguration.userInterface.progressBar.showProgressBar &&
        props.customConfiguration.userInterface.progressBar.showPaymentStep &&
        progressBarPages.length > 0 && (
          <BladeProgressBar
            pages={progressBarPages}
            currentStep={{ group: 'Payment', order: 999 }}
            showPaymentStep
            showQuickQuoteStep={
              props.customConfiguration.userInterface.progressBar
                .showQuickQuoteStep
            }
          />
        )}
      <BladeView variant={'m'}>
        <BladeInlineErrorBoundary>
          <div
            className={cx(
              css.checkout_frame,
              matchesQuery
                ? css.checkout_frame_isSpaceLimited
                : css.checkout_frame_isSpacious
            )}
          >
            {(function () {
              switch (props.quote.quoteType) {
                case 'NewBusiness':
                case 'Renewal':
                  return (
                    <CheckoutNew
                      quote={props.quote}
                      quotePricing={props.quotePricing}
                      policyReferenceID={props.policyReferenceID}
                      onBackToRenewal={props.onBackToRenewal}
                      risk={props.risk}
                      defaultPaymentPlanType={props.defaultPaymentPlanType}
                      productConfiguration={props.productConfiguration}
                      productInterfaceConfiguration={
                        props.productInterfaceConfiguration
                      }
                      paymentProvidersConfiguration={
                        props.paymentProvidersConfiguration
                      }
                      organisationConfiguration={
                        props.organisationConfiguration
                      }
                      applicationConfiguration={props.applicationConfiguration}
                      lifecycles={props.lifecycles}
                      errors={props.errors}
                      callingApplication={props.callingApplication}
                    />
                  );

                case 'MTA':
                  if (!props.adjustmentInformation) {
                    throw new ApplicationError(
                      `Checkout is attempting to load CheckoutAdjust, but not adjustment information (mtaInfo) was found.`
                    );
                  }

                  return (
                    <CheckoutAdjust
                      quote={props.quote}
                      quotePricing={props.quotePricing}
                      risk={props.risk}
                      applicationConfiguration={props.applicationConfiguration}
                      adjustmentInformation={props.adjustmentInformation}
                      productConfiguration={props.productConfiguration}
                      productInterfaceConfiguration={
                        props.productInterfaceConfiguration
                      }
                      paymentProvidersConfiguration={
                        props.paymentProvidersConfiguration
                      }
                      organisationConfiguration={
                        props.organisationConfiguration
                      }
                      lifecycles={props.lifecycles}
                      errors={props.errors}
                      callingApplication={props.callingApplication}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </BladeInlineErrorBoundary>
      </BladeView>
    </>
  );
};

export interface ICheckout {
  quoteId: string;
  quote: Quote;
  policyReferenceID?: string;
  quoteEmbargoes: QuoteEmbargo[] | undefined;
  quotePricing: PricingSet;
  defaultPaymentPlanType?: PaymentPlanType;
  risk: Risk;
  adjustmentInformation: MtaInformation | undefined | null;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  paymentProvidersConfiguration: PaymentProvidersConfiguration;
  organisationConfiguration: OrganisationConfiguration;
  applicationConfiguration: ApplicationConfiguration;
  customConfiguration?: CustomConfig;
  isAuthenticated: () => boolean;
  emailAddress?: string;
  lifecycles: Lifecycles;
  onIsAlreadyConverted?: () => void;
  onIsInvalid?: () => void;
  onIsUnauthenticated?: () => void;
  onBackToRenewal?: () => void;
  goToQuoteSummary: (quoteID: string, quotePlan: string) => void;
  goToLogin?: () => void;
  errors?: ICheckoutBoundaryError;
  callingApplication: CallingApplication;
}
