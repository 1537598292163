import { validatorFunctions } from './index';
import get from 'lodash/get';

function getValidatorByName(validatorName: string) {
  // The split regex is using capturing parentheses,
  // to allow to matching to happen only with the first instance
  // of ':'. This is to allow for dynamic values (ex: YYYY MM DD HH:mm:ss)
  // to contain ':' and not be split on.

  const [validationFunctionString, dynamicValue] = validatorName.split(/:(.+)/);

  if (!dynamicValue) {
    return validatorFunctions[validatorName];
  } else {
    return validatorFunctions[validationFunctionString](dynamicValue);
  }
}

/**

  This function allows you to filter the available validator
  functions by passing in an array of the desired validator function
  names. For any validator string found, this function will try and search
  for a validator function with that name and return that if found.

  We can expect here two kinds of validation strings:

    1. static string: a consistent validation string referring to a specific
       validation function. (ex: required, mustBeOver18)
    2. dynamic string: a validation string with a changing value to be
       validated against. The dynamic part is separated by a :
       (ex: min:)
 */
export function filterValidators(validatorStrings: Array<string>): Array<any> {
  return validatorStrings.map(getValidatorByName);
}

// TODO: this validation logic currently only supports a single validator
//       failing we should add logic to handle multiple validation fails in a
//       single validation run.
export function runValidation(
  validatorStrings: Array<string>,
  validatorMessages: {
    [key: string]: {
      message: string;
    };
  },
  errorsAsArray = false
) {
  return (value: any) => {
    const errorMessages = validatorStrings.reduce(
      (errorMessages: Array<string>, validatorName: string) => {
        // Skips processing of extra validators, as we only expect one right now
        if (errorMessages.length > 0) return errorMessages;

        const validator = getValidatorByName(validatorName);

        const customMessage = get(
          validatorMessages,
          [validatorName, 'message'],
          undefined
        );

        const result = validator(value);

        if (typeof result === 'string') {
          // If we have a custom error message supplied with the validation
          // rules then we can use that. Otherwise we will use the returned
          // message from the validator. This will allows us to support the
          // new meta data with validation, as well as remaining backwards
          // compatible with the old way.
          errorMessages.push(customMessage || result);
        }

        return errorMessages;
      },
      []
    );

    if (errorMessages.length > 0) {
      return errorsAsArray ? errorMessages : errorMessages[0];
    }

    return true;
  };
}
