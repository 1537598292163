import routes from '../../../routes';

export default function navConfig(options: navOptions) {

  const {
    guest,
    isSocial,
    email,
    push,
    signOut,
    appMode,
    clearStorage,
    supportButton,
    privacyUrlOverride,
    termsUrlOverride
  } = options;


  return [
    {
      title: 'Your Account',
      subTitle: (guest) ? null : email,
      items: [
        {
          label: 'Home',
          icon: 'faHome',
          onClick: () => push(routes.home.path)
        },
        {
          ...(guest)
            ?
            {
              label: 'Sign in',
              icon: 'faSignInAlt',
              onClick: () => push(routes.signIn.path)
            }
            :
            {
              label: 'Sign out',
              icon: 'faSignOutAlt',
              onClick: () => signOut()
            }
        },
        {
          ...(guest)
            ?
            {
              label: 'Reset password',
              icon: 'faDotCircle',
              onClick: () => push(routes.resetPassword.path)
            }
            :
            { ignore: true }
        },
        {
          ...(guest || isSocial)
            ?
            { ignore: true }
            :
            {
              label: 'Change password',
              icon: 'faExchangeAlt',
              onClick: () => push(routes.changePassword.path)
            }
        }
      ]
    },
    {
      title: 'About us',
      items: [
        {
          label: 'Privacy policy',
          icon: 'faAlignLeft',
          onClick: () => window.open(privacyUrlOverride ?? routes.privacyPolicy.path, "_blank")
        },
        {
          label: 'Terms & conditions',
          icon: 'faAlignLeft',
          onClick: () => window.open(termsUrlOverride ?? routes.termsAndConditions.path, "_blank")
        },
        {
            ...(supportButton?.label)
            ?
            {
              label: supportButton.label,
              icon: 'faLifeRing',
              type: 'support',
              onClick: () => {
                if(supportButton?.openInNewTab === true){
                  window.open(supportButton.externalLink);
                }else{
                  window.location.href = supportButton.externalLink
                }

              }
            }
            :
            {
              ignore: true
            }
        }
      ]
    },
    {
      ...(appMode === 'dev')
        ?
        {
          title: 'Developer Options',
          items: [
            {
              label: 'Clear local storage',
              icon: 'faTrash',
              onClick: () => clearStorage()
            },
            {
              label: 'Checkout',
              icon: 'faCreditCard',
              onClick: () => push('/checkout')
            },
            {
              label: 'Component API',
              icon: 'faPlug',
              onClick: () => push('/blade-api')
            },
            {
              label: 'Trigger exception!',
              icon: 'faCode',
              onClick: () => { throw "Test exception triggered at " + (new Date(Date.now())).toUTCString(); }
            }
          ]
        }
        : { ignore: true }
    }
  ];
}

export interface navOptions {
  guest: boolean,
  isSocial: ()=> void,
  email: any,
  push: any,
  signOut: any,
  appMode: any,
  clearStorage: any,
  supportButton: supportButton,
  privacyUrlOverride: string,
  termsUrlOverride: string
}

export interface supportButton {
  label: string,
  externalLink: string,
  openInNewTab: boolean
}