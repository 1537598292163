function verifyAgeNumber(value: string) {
  const [year, operation, message] = value.split('|');

  return (age?: number) => {
    if (age != null) {
      switch (operation) {
        case 'max':
          return age > parseInt(year)
            ? message ||
            `Policy holders are required to be under ${year} years of age`
            : true;
        case 'equals':
          return age === parseInt(year)
            ? true
            : message || `Policy holders must be ${year} years of age`;
        case 'min':
        default:
          return age < parseInt(year)
            ? message ||
            `Policy holders are required to be over ${year} years of age`
            : true;
      }
    } else {
      return 'This age is invalid';
    }
  }
}

export default verifyAgeNumber;