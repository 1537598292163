import * as React from 'react';
import { BladeLink } from '../../../../link';
import { useMediaQuery } from '@aventus/pockethooks';
import { ClaimForm } from '../claimForm';
import { ProductClaimsConfiguration } from '@aventus/configuration';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { isEmpty } from 'lodash';
import moment from 'moment';
import css from './index.css';

export const ClaimElement: React.FunctionComponent<IClaimElement> = props => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const formatAnswer = (value: any, type: string) => {
    switch (type) {
      case 'money':
        return currencyToHumanReadable(value);

      case 'date':
        if (!value) return '[Please provide date]';
        return moment(value).format('MM-YYYY');

      case 'dateOfBirth':
        return moment(value).format('DD-MM-YYYY');

      case 'name':
        return `${value?.firstName} ${value?.lastName}`;

      default:
        return value;
    }
  };

  if (isEditMode) {
    if (props.isFormModified !== props.itemIndex) {
      setIsEditMode(false);
    }
    return (
      <ClaimForm
        getQuestions={props.getQuestions}
        claimFormActions={isFormValid =>
          props.modifyClaim(isFormValid, setIsEditMode, props.itemIndex)
        }
      />
    );
  }

  return (
    <>
      <div className={css.claims_element}>
        {!matchesQuery ? (
          <table className={css.claims_table}>
            {Object.values(props.values).map(
              (item: { [key: string]: string }) => {
                const objectKey: string =
                  Object.keys(props.values).find(
                    (key: string) => props.values[key] === item
                  ) || '';

                const matchOn =
                  props.claimsQuestionNames?.references[objectKey]?.matchOn ||
                  '';
                const answerType =
                  props.claimsQuestionNames?.references[objectKey]?.type || '';

                  return (
                    <tr>
                      <td>
                        <b>
                          {
                            props.claimsQuestionNames?.references[objectKey]
                              ?.title
                          }
                          :
                        </b>
                      </td>
                      <td>
                        {answerType
                          ? formatAnswer(item, answerType)
                          : item[matchOn]}
                      </td>
                    </tr>
                  );
              }
            )}
          </table>
        ) : (
          <ul className={css.claims_list}>
            {Object.values(props.values).map(
              (item: { [key: string]: string }) => {
                const objectKey: string =
                  Object.keys(props.values).find(
                    (key: string) => props.values[key] === item
                  ) || '';

                const matchOn =
                  props.claimsQuestionNames?.references[objectKey]?.matchOn ||
                  '';
                const answerType =
                  props.claimsQuestionNames?.references[objectKey]?.type || '';

                if (isEmpty(item)) {
                  return null;
                } else {
                  return (
                    <li>
                      <b>
                        {
                          props.claimsQuestionNames?.references[objectKey]
                            ?.title
                        }
                        :
                      </b>
                      <td>
                        {answerType
                          ? formatAnswer(item, answerType)
                          : item[matchOn]}
                      </td>
                    </li>
                  );
                }
              }
            )}
          </ul>
        )}

        <div className={css.claims_element_footer}>
          <BladeLink
            onClick={() => props.deleteClaim()}
            className={css.claims_element_footer_link}
          >
            Remove
          </BladeLink>
          <BladeLink
            onClick={() => {
              props.setFormState({ [props.itemKey]: props.values });
              props.setIsFormOpen(false);
              props.setIsFormModified(props.itemIndex);
              setIsEditMode(!isEditMode);
            }}
            className={css.claims_element_footer_link}
          >
            Update
          </BladeLink>
        </div>
      </div>
    </>
  );
};

export interface IClaimElement {
  isFormModified: number | undefined;
  setIsFormModified: (value: number | undefined) => void;
  modifyClaim: (
    isFormValid: boolean,
    setIsEditMode: (value: boolean) => void,
    itemIndex: number
  ) => React.ReactNode;
  deleteClaim: () => void;
  values: {
    [key: string]: {
      listReferenceID: string;
      answeredText: string;
      text: string;
      value: string;
    };
  };
  setIsFormOpen: (value: boolean) => void;
  setFormState: (value: any) => void;
  getQuestions: (
    validation: any,
    dispatchValidation: any
  ) => Array<React.ReactNode>;
  itemKey: number | string;
  itemIndex: number;
  claimsQuestionNames?: ProductClaimsConfiguration;
}
