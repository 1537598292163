import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

export const VriSummaryRedirect: React.FunctionComponent<IVriSummaryRedirect> = props => {
  return (
    <Redirect
      to={{
        pathname: props.getVriSummaryPath(props.match.params.quoteId),
        state: props.location.state
      }}
    />
  );
};

export interface IVriSummaryRedirect extends RouteComponentProps<{
  productId: string;
  lineOfBusiness: string;
  coverType: string;
  quoteId: string;
}> {
  getVriSummaryPath: (quoteId: string) => string;
}
