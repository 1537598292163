import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteProduct } from '@aventus/platform';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const useGetQuoteProduct: UseGetQuoteProduct = function (quoteId) {
  const symphony = useSymphonyClient();
  const { throwError } = useError();
  const { updateProductRef } = useDispatchEvent();

  const [quoteProduct, setQuoteProduct] = React.useState<
    QuoteProduct | undefined
  >(undefined);
  const [isGettingQuoteProduct, setIsGettingQuoteProduct] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {
      if (quoteId) {
        setIsGettingQuoteProduct(true);

        try {
          const _quoteProduct = await symphony.getQuoteProduct(quoteId);
          setQuoteProduct(_quoteProduct);
          updateProductRef(_quoteProduct.referenceID);
        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteProduct(false);
      }
    })();
  }, [quoteId]);

  return {
    quoteProduct,
    isGettingQuoteProduct
  };
};

export type UseGetQuoteProduct = (quoteId?: string) => {
  quoteProduct: QuoteProduct | undefined;
  isGettingQuoteProduct: boolean;
};
