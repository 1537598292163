import { IRisk, IntelligentQuoteQuestion } from '@aventus/platform';

import { getAnswerIQ } from './get-answer-iq';
import { getFromForm } from '@aventus/formio';
import { isDependencySatisfied } from './is-dependency-satisfied';
import { isQuestionAnswered } from './is-question-answered';

export function percentageOfAnsweredQuestions(
  questions: Array<IntelligentQuoteQuestion>,
  risk: IRisk
): number {
  const totalNumberOfQuestions: number = questions.length;

  const totalAnsweredQuestions: number = questions.reduce(
    (total: number, currentQuestion: IntelligentQuoteQuestion) => {
      const answer: any = getAnswerIQ(currentQuestion);

      if (
        isQuestionAnswered(answer) ||
        questionDoesNotNeedToBeAnswered(currentQuestion, risk)
      ) {
        return total + 1;
      }

      return total;
    },
    0
  );

  const percentage: number =
    (100 * totalAnsweredQuestions) / totalNumberOfQuestions;

  return Math.floor(percentage);
}

const questionDoesNotNeedToBeAnswered = (
  question: IntelligentQuoteQuestion,
  risk: IRisk
) => {
  if (question.conditionRiskPath && question.conditionType) {
    // if dependency is not satisfied then the question is not applicable/visible and is treated as being answered

    const questionIsVisible = isDependencySatisfied(
      question.conditionType,
      question.conditionValue,
      getFromForm(risk, question.conditionRiskPath)
    );

    if (!questionIsVisible) {
      return true;
    }
  }

  return false;
};
