import update from 'immutability-helper';

export function resetPasswordRequestReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          isResettingPassword: { $set: true }
        }
      })
    }
  };
}

export function resetPasswordSuccessReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isResettingPassword: false,
            successfullyResetPassword: true
          }
        }
      })
    }
  };
}

export function resetPasswordFailureReducer(state, action) {
  return {
    ...state,
    ...{
      auth: update(state.auth, {
        _flags: {
          $set: {
            isResettingPassword: false,
            successfullyResetPassword: false
          }
        }
      })
    }
  }
}
