import * as React from 'react';
import { BladeSurface } from '../../surface';
import { useRunner } from '../../runner';
import { IBladeAsk } from '../index';
import css from './index.css';
import { useForm } from '../../form';
import cx from 'classnames';

export function BladeAskAllTogether(props: IBladeAsk) {
  const { trim } = useRunner();

  const { questions, submit } = useForm(
    props.getQuestionsToRender,
    props.getSubmitToRender
  );

  return (
    <div className={css.allTogether_scaffold}>
      <BladeSurface
        className={css.allTogether}
        shouldRespectViewWidth={props.shouldRespectViewWidth}
      >
        {questions.map((question: any, i: number) => {
          if (!question) {
            return null;
          }

          return (
            <div
              id={`avt-qw-${question.key}`}
              data-question={question.props.question}
              data-question-id={question.key}
              key={i}
              className={cx('avt-question-wrapper', css.allTogether_question, {
                [css.allTogether_question_trimIsDivider]: trim === 'divider',
                [css.allTogether_question_hidden]: question.props.hidden
              })}
            >
              {question}
            </div>
          );
        })}

        <div className={css.allTogether_question}>{submit}</div>
      </BladeSurface>
    </div>
  );
}
