import React from 'react';

type IInlineErrorMessageAction =
  | { type: 'SET'; identifier: string; message: string }
  | { type: 'RESET'; identifier: string }
  | { type: 'CLEAR' };

type InlineErrorMessages = Map<string, string>;

const BladeInlineErrorContext = React.createContext<{
  state: InlineErrorMessages;
  dispatch: React.Dispatch<IInlineErrorMessageAction>;
}>({ state: new Map(), dispatch: () => null });

const errorMessageReducer: React.Reducer<
  InlineErrorMessages,
  IInlineErrorMessageAction
> = (state, action) => {
  switch (action.type) {
    case 'SET':
      state.set(action.identifier, action.message);
      // React doesn't like Map because it expects it to be immutable.
      // Therefore we have to create a new map so that state !== newState in memory.
      return new Map(state);
    case 'RESET':
      state.delete(action.identifier);
      return new Map(state);
    case 'CLEAR':
      return new Map();
    default:
      throw Error();
  }
};

/**
 * Blade Error Message Boundary, used in conjunction with the
 * useBladeInlineError hook, allowing hooks and sub components to set and
 * retrieve error messages;
 */
export const BladeInlineErrorBoundary: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(errorMessageReducer, new Map());
  const context = React.useMemo(() => ({ state, dispatch }), [state]);

  return (
    <BladeInlineErrorContext.Provider value={context}>
      {children}
    </BladeInlineErrorContext.Provider>
  );
};

/**
 * Blade useBladeInlineError hook, requires a configured
 * <BladeInlineErrorBoundary> which sets up the context for which these
 * messages are shared.
 */
export const useBladeInlineError = () => {
  const { state, dispatch } = React.useContext(BladeInlineErrorContext);

  return {
    /**
     * Gets the error message for the specified identifier
     * @param identifier Unique identifier for the error target
     * @returns
     */
    getError: (identifier: string) => state.get(identifier),

    /**
     * Sets an error message for the identifier
     * @param {string} identifier Unique identifier for the error target
     * @param {string} message String of the error message
     * @returns
     */
    setError: (identifier: string, message: string) =>
      dispatch({ type: 'SET', identifier, message }),

    /**
     * Resets/clears any error message for the identifier
     * @param {string} identifier Unique identifier for the error target
     * @returns
     */
    resetError: (identifier: string) => dispatch({ type: 'RESET', identifier }),

    /**
     * Clears all error messages (used when reseting a form for example)
     * @returns
     */
    clearErrors: () => dispatch({ type: 'CLEAR' })
  };
};
