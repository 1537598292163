import moment from 'moment';

const dateWithinRange: DateWithinRange = (date, min, max) => {
  const selected = moment(date, 'YYYY-MM-DDTHH:mm:ss');
  const minDate = moment(min, 'YYYY-MM-DDTHH:mm:ss');
  const maxDate = moment(max, 'YYYY-MM-DDTHH:mm:ss');

  const sameOrAfter = min ? selected.isSameOrAfter(minDate) : true;
  const sameOrBefore = max ? selected.isSameOrBefore(maxDate) : true;

  return sameOrAfter && sameOrBefore;
};

type DateWithinRange = (
  date: Date | string,
  min?: Date | string | null,
  max?: string | Date | null
) => boolean;

export default dateWithinRange;
