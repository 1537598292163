export const uk: string[] = [
  'sky.com',
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'freeserve.co.uk',
  'ntlworld.com',
  'o2.co.uk',
  'orange.net',
  'talktalk.co.uk',
  'tiscali.co.uk',
  'virgin.net',
  'wanadoo.co.uk',
  'bt.com'
];

export default uk;
