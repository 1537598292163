import _toCents from './_to-cents';

export function valueSet (value: string) {

  let owner = {
    delimiter: ',',
    numeralDecimalMark: '.',
    numeralDecimalScale: 2,
    numeralThousandsGroupStyle: 'thousand'
  };

  let regexedValue =
    value
      .replace(/[A-Za-z]/g, '')
      .replace(owner.numeralDecimalMark, 'M') // replace the first decimal mark with reserved placeholder
      .replace(/[^\dM]/g, '') // strip the non numeric letters except M
      .replace('M', owner.numeralDecimalMark) // replace mark
      .replace(/^(-)?0+(?=\d)/, '$1'); // strip leading 0

  let parts = null,
      partInteger = regexedValue,
      partDecimal = '';

  if (regexedValue.indexOf(owner.numeralDecimalMark) >= 0) {
    parts = regexedValue.split(owner.numeralDecimalMark);
    partInteger = parts[0];
    partDecimal = owner.numeralDecimalMark + parts[1].slice(0, owner.numeralDecimalScale);
  }

  switch (owner.numeralThousandsGroupStyle) {
    default:
        partInteger = partInteger.replace(/(\d)(?=(\d{3})+$)/g, '$1' + owner.delimiter);
  }

  const formattedValue = partInteger.toString() + partDecimal.toString();

	return _toCents(Number(formattedValue.replace(/[,]/g, '')))
}
