import * as React from 'react';
import { Redirect, Prompt, useHistory } from 'react-router';
import {
  Quote,
  PricingSet,
  PaymentPlanType,
  DeclineReason,
  IPolicyAction,
  QuoteType
} from '@aventus/platform';
import {
  InsuranceProductConfiguration,
  ProductInterfaceConfiguration,
  OrganisationConfiguration,
  OraclesConfiguration
} from '@aventus/configuration';
import {
  BladeRunner,
  BladeView,
  BladeAsk,
  BladeButton,
  BladeLink,
  BladeViewIsLoading,
  BladeMessageNotification,
  BladeMessage,
  BladeTitleBar,
  BladeModal
} from '@aventus/blade';
import { useDocumentTitle } from '@aventus/pockethooks/use-document-title';
import { useBeforeUnload } from '@aventus/pockethooks/use-before-unload';
import { useMediaQuery } from '@aventus/pockethooks/use-media-query';
import { useScrollToTop } from '@aventus/pockethooks/use-scroll-to-top';
import { useScrollToBottom } from '@aventus/pockethooks/use-scroll-to-bottom';
import { useApplicationTimezone } from '@aventus/application-timezone';
import { IntelligentQuoteDecline } from '../intelligent-quote-decline';
import {
  PaymentPlanSelector,
  usePaymentPlan,
  PriceDisplay
} from '@aventus/mvmt-pricing';
import { EstimateOverlay } from '../../components/estimate-overlay';
import { mapQuestionMetaToQuestionFieldset } from '../../services/map-question-meta-to-question-fieldset';
import { markdownUnorderedFromArray } from '@aventus/pocketknife/markdown-unordered-from-array';
import { useTrack, useTrackView } from '@aventus/application-tracking';
import { IntelligentQuoteExpiredOrConverted } from '../intelligent-quote-expired-or-converted';
import { UseIntelligentQuoting } from '../../hooks/use-intelligent-quoting';
import { BladeMarkdown } from '@aventus/blade';
import css from './index.css';
import cx from 'classnames';
import slugify from 'slugify';

export const IntelligentQuoteAsk: React.FunctionComponent<
  IIntelligentQuoteAsk
> = props => {
  useTrackView('IntelligentQuoteAsk');
  useDocumentTitle(props.documentTitle);

  const history = useHistory();

  const {
    toHumanReadable,
    toPlatformReadable,
    toApplicationReadable,
    timezone
  } = useApplicationTimezone();

  // Tracks when the estimate overlay, if displayed at all,
  // has been dismissed so as to not show it again.

  const [isOverlayDismissed, setIsOverlayDismissed] =
    React.useState<boolean>(false);

  // This is a flag that will be set whenever the PriceDisplay
  // changes to and from sticky mode. Certain inner components,
  // for example BladeInputSlider, calculate positioning of some elements
  // on the DOM. Since the PriceDisplay (in reality it's BladeAppDisplay
  // used by this component) changes the DOM, we need to ensure
  // the inner components get a chance to recalculate. Since the PriceDisplay
  // is a sibling to the other components, we need this shared flag
  // atop both of them, to trigger off re-renders whenever this
  // flag changes.

  const [, setTriggerRender] = React.useState<boolean>(false);

  // Set a flag to know when we're in a particular
  // browser size, using media queries.

  const { matchesQuery } = useMediaQuery('(max-width: 500px)');

  // Allows us to keep track of whether the user has gone to a new
  // question page, or back to a previous one.

  const [isNext, setIsNext] = React.useState<boolean>(false);
  const [isPrevious, setIsPrevious] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<boolean>(false);

  const [showExpiryMessage, setShowExpiryMessage] = React.useState(
    props.showExpiry ?? true
  );

  const [expiryMessageShown, setExpiryMessageShown] = React.useState(false);

  // If the view mode matches the above media query,
  // the message becomes an inline component. Given this page
  // can get very long, if an inline error message is triggered
  // at the top, the user will not be aware of this.
  // This scroll to top ensures we highlight the error.

  // We also want to scroll to top if the user has gone to the
  // next question page. The only difference here is that we don't
  // want the scroll to be animated, but instant.

  useScrollToTop(
    [props.iq.quote?.declinedStatus, isNext],
    (props.iq.quote?.declinedStatus === 'Recoverable' && matchesQuery) ||
      isNext === true,
    props.iq.quote?.declinedStatus === 'Recoverable' && matchesQuery
      ? 'smooth'
      : 'auto',
    () => {
      isNext === true && setIsNext(false);
    }
  );

  // And if the user is going back, then we want to
  // scroll (instantly, remember) to the bottom of the page.

  useScrollToBottom([isPrevious], isPrevious === true, 'auto', () => {
    isPrevious === true && setIsPrevious(false);
  });

  // Because navigation of the question pages is
  // virtual, and they are not actual browser-stacked pages,
  // we want to make sure to warn the user that the browser back
  // will not go back in the question pages.

  if (!props.productConfiguration.quote.ask.externalSummaryPage)
    useBeforeUnload();

  // Track the different states of the quote form.
  // For now, we only really care about when a quote (form) is started,
  // when has reached a state that is can be priced (ie customisation)
  // and when it is declined. Potentially there are quote lifecycles
  // we want to handle but this is a good start for now.

  const { track } = useTrack();

  const renewalRedirectStopperLogic =
    props.iq.quote?.quoteType === 'Renewal' && props.iq.confirmedQuoteId;

  React.useEffect(() => {
    track({
      event: 'aventus.quote.start',
      quoteId: props.iq.quoteId,
      partnerId: props.iq.partnerId,
      productReference: props.productConfiguration.productReference,
      productCoverReference: props.productConfiguration.productCoverReference,
      quoteType: props.quoteType
    });
  }, []);

  React.useEffect(() => {
    if (props.iq.confirmedQuoteId && props.iq.quote?.declined !== true) {
      track({
        event: 'aventus.quote.confirm',
        quoteId: props.iq.quote?.id,
        partnerId: props.iq.partnerId,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
        quoteType: props.quoteType
      });
    }
  }, [props.iq.confirmedQuoteId, props.iq.quote?.declined]);

  React.useEffect(() => {
    if (props.iq.isCustomisationPage === true) {
      track({
        event: 'aventus.quote.customise',
        quoteId: props.iq.quote?.id,
        partnerId: props.iq.partnerId,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
        quoteType: props.quoteType
      });
    }
  }, [props.iq.isCustomisationPage]);

  React.useEffect(() => {
    if (props.iq.pagePointer >= 0 && props.iq.page.questionPage !== undefined) {
      const pageHash = slugify(props.iq.page.questionPage.title ?? '', {
        lower: true
      });

      track({
        event: 'aventus.quote.questionSetPage',
        pageTitle: props.iq.page.questionPage.title,
        pageNumber: props.iq.pagePointer,
        pageHash: pageHash,
        quoteId: props.iq.quote?.id,
        partnerId: props.iq.partnerId,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
        quoteType: props.quoteType
      });
      if (props.iq.page.questionPage.title) {
        const basePath = `${window.location.pathname}${window.location.search}`;
        window.history.pushState(null, '', `${basePath}#${pageHash}`);
      }
    }
  }, [props.iq.page, props.iq.pagePointer]);

  React.useEffect(() => {
    if (props.iq.quote?.declined === true) {
      track({
        event: 'aventus.quote.decline',
        quoteId: props.iq.quote?.id,
        partnerId: props.iq.partnerId,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
        quoteType: props.quoteType
      });
    }
  }, [props.iq.quote?.declined]);

  React.useEffect(() => {
    if (props.iq.savedQuoteId) {
      track({
        event: 'aventus.quote.saved',
        quoteId: props.iq.savedQuoteId,
        partnerId: props.iq.partnerId,
        productReference: props.productConfiguration.productReference,
        productCoverReference: props.productConfiguration.productCoverReference,
        quoteType: props.quoteType
      });
    }
  }, [props.iq.savedQuoteId]);

  React.useEffect(() => {
    // This triggers an auto redirect when we reach the confirmation page
    // We skip this page due to a requirement for the Westcor broker portal
    // We still need to render the confirmation page breifly otherwise Opus has a heart attack
    if (
      !props.iq.isFirstPage &&
      props.iq != null &&
      props.iq.page?.questionPage != null &&
      props.iq.page.questionPage.questionStyle === 'NoQuestions' &&
      props.iq.quoteState === 'Buyable' &&
      props.skipConfirmationPage
    ) {
      props.iq.nextPage();
      setIsNext(true);
    }
  });

  const paymentPlan = usePaymentPlan(
    props.iq.quotePricing,
    props.defaultPaymentPlanType,
    props.iq.quote?.paymentPlanReferenceID
  );

  // TODO
  // Comment
  if (props.iq.isWorking && !props.iq.isCustomisationPage) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  // TODO
  // Comment

  if (props.mtaAction && props.mtaAction.allowed == false) {
    var message = props.productConfiguration.quote.adjust.disableAdjustments;
    if (props.mtaAction.errorCode === 1140) {
      message =
        props.productConfiguration.quote.adjust
          .disableAdjustmentOutstandingPayment;
    }

    return (
      <BladeRunner>
        <div className={css.scaffolding}>
          <BladeTitleBar
            viewVariant={'s'}
            title={'Adjustment Error'}
            description={undefined}
          />
          <BladeView variant={'s'} className={css.ask}>
            <BladeMarkdown markdown={message} />
          </BladeView>
        </div>
      </BladeRunner>
    );
  }

  if (
    props.iq.quote?.declined === true &&
    props.iq.quote?.declinedStatus === 'Declined' &&
    props.iq.originator === 'renew'
  ) {
    return (
      <IntelligentQuoteDecline
        title={props.productConfiguration.quote.renewalDecline.title}
        subTitle={props.productConfiguration.quote.renewalDecline.subTitle}
        declineWithNoReason={
          props.productConfiguration.quote.renewalDecline.declineWithNoReason
        }
        onAcknowledgement={props.goHome}
      />
    );
  }

  if (
    props.iq.quote?.declined === true &&
    props.iq.quote?.declinedStatus === 'Declined'
  ) {
    return (
      <IntelligentQuoteDecline
        title={props.productConfiguration.quote.decline.unrecoverableTitle}
        declineWithNoReason={
          props.productConfiguration.quote.decline.declineWithNoReason
        }
        declineWithOneReason={
          props.productConfiguration.quote.decline.declineWithOneReason
        }
        declineWithMultipleReasons={
          props.productConfiguration.quote.decline.declineWithMultipleReasons
        }
        help={props.productConfiguration.quote.decline.help}
        supportLink={props.organisationConfiguration.links.support}
        reasons={props.iq.quote?.declineReasons}
        onAcknowledgement={props.goHome}
      />
    );
  }

  // TODO
  // Comment

  if (
    props.iq.confirmedQuoteId &&
    !props.productConfiguration.quote.ask.externalSummaryPage &&
    props.iq.quote?.quoteType !== 'Renewal'
  ) {
    return (
      <Redirect
        push
        to={props.getSummaryRoute(
          props.iq.confirmedQuoteId,
          paymentPlan?.selectedPricingPlan?.paymentPlan.type
        )}
      />
    );
  } else if (
    props.iq.confirmedQuoteId &&
    !props.productConfiguration.quote.ask.externalSummaryPage &&
    props.iq.quote?.quoteType === 'Renewal'
  ) {
    if (props.renewalConfirmationConfirmLink) {
      props.renewalConfirmationConfirmLink();
    } else {
      return (
        <Redirect
          push
          to={props.getSummaryRoute(
            props.iq.confirmedQuoteId,
            paymentPlan?.selectedPricingPlan?.paymentPlan.type
          )}
        />
      );
    }
  } else if (
    props.iq.confirmedQuoteId &&
    props.productConfiguration.quote.ask.externalSummaryPage
  ) {
    return <BladeViewIsLoading variant="s" />;
  }

  if (
    props.quote?.expired === true &&
    props.productConfiguration?.productReference === 'stella_pr_travel' &&
    showExpiryMessage
  ) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={props.productConfiguration.quote.expired.title}
          reason={props.productConfiguration.quote.expired.reason}
          help={props.productConfiguration.quote.expired.help}
          supportLink={props.organisationConfiguration.links.support}
          onAcknowledgement={() => setShowExpiryMessage(false)}
        />
      </div>
    );
  }

  if (props.iq.invalidProductError) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={props.productConfiguration.quote.expired.title}
          reason={props.productConfiguration.quote.expired.reason}
          help={props.productConfiguration.quote.expired.help}
          supportLink={props.organisationConfiguration.links.support}
          onAcknowledgement={props.goHome}
        />
      </div>
    );
  }

  if (props.iq.mtaError) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title=""
          reason={props.iq.mtaError.message}
          supportLink={props.organisationConfiguration.links.support}
          onAcknowledgement={props.goHome}
        />
      </div>
    );
  }

  if (props.quote?.converted === true) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={props.productConfiguration.quote.converted.title}
          reason={props.productConfiguration.quote.converted.reason}
          help={props.productConfiguration.quote.converted.help}
          supportLink={props.organisationConfiguration.links.support}
          onAcknowledgement={props.goHome}
        />
      </div>
    );
  }

  // We only pass quote prop from Opus, and not Symphony. Therefore, this will only work for expired motor quotes through opus/salesforce.
  if (props.quote?.expired && !expiryMessageShown) {

    let expiryReason = props.productConfiguration.quote.expired.reason;
    if (props.quote?.quoteType === 'NewBusiness' && props.productConfiguration.quote.expired.reasonNewBusiness){
      expiryReason = props.productConfiguration.quote.expired.reasonNewBusiness
    }
    else if (props.quote?.quoteType === 'Renewal' && props.productConfiguration.quote.expired.reasonRenewal){
      expiryReason = props.productConfiguration.quote.expired.reasonRenewal
    }

    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={props.productConfiguration.quote.expired.title}
          reason={expiryReason}
          help={props.productConfiguration.quote.expired.help}
          supportLink={props.organisationConfiguration.links.support}
          onAcknowledgement={() => {
            let url = new URL(location.href);
            url.searchParams.delete('quoteId');
            history.replace(url.pathname + url.search);
            setExpiryMessageShown(true);
          }}
          triggerLabel={props.productConfiguration.quote.expired.okLabel}
        />
      </div>
    );
  }

  return (
    <BladeRunner
      ask={
        props.iq.isCustomisationPage
          ? props.productInterfaceConfiguration.askCustomisation || 'customise'
          : props.productInterfaceConfiguration.ask
      }
      inputs={props.productInterfaceConfiguration.inputs}
    >
      <div className={css.scaffolding}>
        {!modal && (
          <Prompt
            when={!renewalRedirectStopperLogic}
            message={
              'You have unsaved changes, are you sure you want to leave?'
            }
          />
        )}

        {props.iq.page?.questionPage?.title &&
          !props.iq.isCustomisationPage && (
            <BladeTitleBar
              viewVariant={'s'}
              title={props.iq.page.questionPage.title}
              description={props.iq.page.questionPage.description || undefined}
            />
          )}
        {props.iq.isCustomisationPage &&
          (paymentPlan.selectedPricingPlan || props.iq.adjustPricing) && (
            <PriceDisplay
              isDeclined={
                props.iq.quote?.declined === true &&
                props.iq.quote?.declinedStatus === 'Recoverable'
              }
              plan={paymentPlan.selectedPricingPlan}
              adjustPlan={props.iq.adjustPricing}
              productConfiguration={props.productConfiguration}
              isUpdatingPrice={props.iq.isWorking}
              quoteType={props.iq.quote?.quoteType}
              useStickyMode={true}
              onStickyModeChange={(isSticky: boolean) => {
                setTimeout(() => setTriggerRender(isSticky), 500);
              }}
            />
          )}

        {props.quotePricing && props.showEstimate && !isOverlayDismissed && (
          <EstimateOverlay
            productConfiguration={props.productConfiguration}
            quotePricing={props.quotePricing}
            onDismiss={() => setIsOverlayDismissed(true)}
            quoteType={props.iq.quote?.quoteType}
          />
        )}

        <BladeView variant={'s'} className={css.ask}>
          {props.iq.quote?.declined === true &&
            props.iq.quote?.declinedStatus === 'Recoverable' && (
              <>
                {matchesQuery ? (
                  <BladeMessage
                    variant={'error'}
                    message={
                      props.productConfiguration.quote.decline
                        .recoverableTitle || "We're unable to price this quote"
                    }
                    detail={markdownUnorderedFromArray(
                      props.iq.quote?.declineReasons,
                      (reason: DeclineReason) =>
                        reason.text.charAt(0).toUpperCase() +
                        reason.text.slice(1)
                    )}
                  />
                ) : (
                  <BladeMessageNotification
                    variant={'error'}
                    message={
                      props.productConfiguration.quote.decline
                        .recoverableTitle || "We're unable to price this quote"
                    }
                    detail={markdownUnorderedFromArray(
                      props.iq.quote?.declineReasons,
                      (reason: DeclineReason) =>
                        reason.text.charAt(0).toUpperCase() +
                        reason.text.slice(1)
                    )}
                  />
                )}
              </>
            )}

          {!props.iq.isFirstPage && (
            <BladeLink
              onClick={() => {
                props.iq.previousPage();
                setIsPrevious(true);
              }}
              className={css.backLink}
            >
              {props.productConfiguration?.quote?.ask?.previousCallToAction ||
                'Back'}
            </BladeLink>
          )}

          {props.iq.isCustomisationPage &&
            props.iq.quotePricing &&
            props.iq.originator !== 'renew' && (
              <PaymentPlanSelector
                label={
                  props.productConfiguration.pricing?.paymentPlanToggleLabel
                }
                description={
                  props.productConfiguration.pricing.paymentPlanDescription
                }
                plans={props.iq.quotePricing}
                plansConfiguration={
                  props.productConfiguration.pricing.paymentPlans
                }
                selectedPaymentPlan={paymentPlan.selectedPricingPlan}
                setSelectedPricingPlan={paymentPlan.setSelectedPricingPlan}
              />
            )}

          {props.iq.page?.questionPage && (
            <BladeAsk
              shouldRespectViewWidth={true}
              getQuestionsToRender={(
                validation: any,
                dispatchValidation: any
              ) =>
                mapQuestionMetaToQuestionFieldset(
                  props.iq.page.questionPage.questions,
                  props.iq.risk,
                  props.iq.originalRisk,
                  props.iq.setRisk,
                  validation,
                  dispatchValidation,
                  {
                    currencyCode: props.currencyCode,
                    currencySymbol: props.currencySymbol,
                    countryCode: props.countryCode,
                    dateFormat: props.dateFormat,
                    oracles: props.oraclesConfiguration,
                    timezone: timezone,
                    questionIcons:
                      props.productConfiguration.quote.customise.questionIcons,
                    questionImages:
                      props.productConfiguration.quote.customise.questionImages
                  },
                  {
                    dateToHumanReadable: toHumanReadable,
                    dateToPlatformReadable: toPlatformReadable,
                    dateToApplicationReadable: toApplicationReadable
                  },
                  props.organisationConfiguration,
                  props.productConfiguration,
                  props.iq.riskState,
                  props.iq.setRiskState,
                  props.questionsConfig,
                  paymentPlan.selectedPricingPlan,
                  props.iq.adjustPricing,
                  !props.quote?.expired ? props.quote?.id : undefined,
                  props.iq.isWorking,
                  undefined,
                  props.iq.quote?.quoteType
                )
              }
              getSubmitToRender={(isFormValid: boolean) => (
                <div
                  className={cx(css.ask_controls, {
                    [css.ask_controls_isSpaceLimited]: matchesQuery
                  })}
                >
                  {!props.iq.isFirstPage && (
                    <BladeButton
                      variant="secondary"
                      className={css.ask_control_padding}
                      basis={!matchesQuery && '35%'}
                      onClick={() => {
                        props.iq.previousPage();
                        setIsPrevious(true);
                      }}
                    >
                      {props.productConfiguration.quote.ask
                        .previousCallToAction || 'Back'}
                    </BladeButton>
                  )}

                  <div className={css.ask_controls_nextWrap}>
                    <BladeButton
                      isDisabled={
                        !isFormValid ||
                        (props.iq.quote?.declined === true &&
                          props.iq.isCustomisationPage)
                      }
                      basis={!props.iq.isFirstPage && !matchesQuery && '65%'}
                      onClick={() => {
                        if (
                          props.iq.isCustomisationPage &&
                          props.iq.quote?.quoteType === 'Renewal'
                        ) {
                          setModal(true);
                        } else {
                          props.iq.nextPage();
                          setIsNext(true);
                        }
                      }}
                    >
                      {props.productConfiguration.quote.ask.nextCallToAction ||
                        'Next'}
                    </BladeButton>

                    {props.iq.allowSaveQuote &&
                      !props.iq.isCustomisationPage && (
                        <BladeLink onClick={props.iq.saveQuote}>
                          {props.productConfiguration.quote.ask
                            .saveCallToAction || 'Save for later'}
                        </BladeLink>
                      )}
                  </div>
                </div>
              )}
            />
          )}
          {modal && (
            <BladeModal
              close={() => setModal(false)}
              withFrame={true}
              allowClose={false}
              title={
                props.productConfiguration.quote?.renewalConfirmation?.title ||
                'Confirm changes'
              }
            >
              <div className={css.scaffoldingModal}>
                <BladeMarkdown
                  markdown={
                    props.productConfiguration.quote?.renewalConfirmation
                      ?.description ||
                    'By continuing you  are confirming the changes you have made to your renewal quote'
                  }
                />

                <div className={css.buttonWrapper}>
                  <BladeButton
                    variant={'secondary'}
                    style={{ flexBasis: '50px', flexGrow: 1 }}
                    onClick={() => setModal(false)}
                  >
                    {'Back'}
                  </BladeButton>

                  <BladeButton
                    variant={'primary'}
                    style={{ flexBasis: '50px', flexGrow: 2 }}
                    onClick={() => {
                      props.iq.nextPage();
                      setIsNext(true);
                    }}
                  >
                    {'Continue'}
                  </BladeButton>
                </div>
              </div>
            </BladeModal>
          )}
        </BladeView>
      </div>
    </BladeRunner>
  );
};

export interface IIntelligentQuoteAsk {
  iq: UseIntelligentQuoting;
  documentTitle?: string;
  quote?: Quote;
  quotePricing?: PricingSet;
  defaultPaymentPlanType?: PaymentPlanType;
  getSummaryRoute: (
    quoteId: string,
    defaultPaymentPlanType?: PaymentPlanType
  ) => string;
  getHomeRoute: () => string;
  goHome: () => void;
  renewalConfirmationConfirmLink?: () => void;
  currencyCode: string;
  currencySymbol: string;
  countryCode: string;
  dateFormat: string;
  oraclesConfiguration: OraclesConfiguration;
  organisationConfiguration: OrganisationConfiguration;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  showEstimate?: boolean;
  skipConfirmationPage: boolean;
  questionsConfig?: {
    hideVoucher: boolean;
  };
  mtaAction?: IPolicyAction;
  quoteType?: QuoteType;
  showExpiry?: boolean;
}
