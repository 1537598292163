import initialState from './initial-state';

import { APP_CONFIGURE_APP } from './core/configure-app/types';
import configureAppReducer from './core/configure-app/reducers';
import { APP_UPDATE_STATE_VERSION } from './core/update-state-version/types';
import updateStateVersionReducer from './core/update-state-version/reducers';
import { APP_UPDATE_PRIVACY_POLICY_VERSION } from './core/update-privacy-policy-version/types';
import updatePrivacyPolicyVersionReducer from './core/update-privacy-policy-version/reducers';
import {
  GET_ACTIVE_LINES_OF_BUSINESS_REQUEST,
  GET_ACTIVE_LINES_OF_BUSINESS_SUCCESS,
  GET_ACTIVE_LINES_OF_BUSINESS_FAILURE
} from './core/get-active-lines-of-business/types';
import {
  getActiveLinesOfBusinessRequestReducer,
  getActiveLinesOfBusinessSuccessReducer,
  getActiveLinesOfBusinessFailureReducer
} from './core/get-active-lines-of-business/reducers';
import { UPDATE_SHOW_WELCOME_SCREEN } from './core/update-show-welcome-screen/types';
import updateShowWelcomeScreenReducer from './core/update-show-welcome-screen/reducers';
import { UPDATE_SHOW_COOKIE_POLICY } from './core/update-show-cookie-policy/types';
import updateShowCookiePolicyReducer from './core/update-show-cookie-policy/reducers';
import {
  GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_REQUEST,
  GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_SUCCESS,
  GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_FAILURE
} from './core/guest-quotes/assign-quote-to-user/types';
import {
  assignQuoteToUserRequestReducer,
  assignQuoteToUserSuccessReducer,
  assignQuoteToUserFailureReducer
} from './core/guest-quotes/assign-quote-to-user/reducers';
import { GUEST_QUOTES_SAVE_QUOTE_ID } from './core/guest-quotes/save-quote-id/types';
import saveQuoteIdReducer from './core/guest-quotes/save-quote-id/reducers';
import { GUEST_QUOTES_CLEAR_QUOTE_IDS } from './core/guest-quotes/clear-quote-ids/types';
import clearQuoteIdsReducer from './core/guest-quotes/clear-quote-ids/reducers';
import { TRACKING_SET_EXTERNAL_ID } from './core/tracking/set-external-id/types';
import setExternalIdReducer from './core/tracking/set-external-id/reducers';
import { ALERTS_QUEUE_ALERT } from './core/alerts/queue-alert/types';
import queueAlertReducer from './core/alerts/queue-alert/reducers';
import { ALERTS_CLEAR_ALERT } from './core/alerts/clear-alert/types';
import clearAlertReducer from './core/alerts/clear-alert/reducers';
import { ALERTS_CLEAR_ALL_ALERTS } from './core/alerts/clear-all-alerts/types';
import clearAllAlertsReducer from './core/alerts/clear-all-alerts/reducers';
import {
  SEARCH_ADDRESS_LOOKUP_REQUEST,
  SEARCH_ADDRESS_LOOKUP_SUCCESS,
  SEARCH_ADDRESS_LOOKUP_FAILURE
} from './core/address-lookup/search-address-lookup/types';
import {
  searchAddressLookupRequestReducer,
  searchAddressLookupSuccessReducer,
  searchAddressLookupFailureReducer
} from './core/address-lookup/search-address-lookup/reducers';
import { CLEAR_ADDRESS_LOOKUP } from './core/address-lookup/clear-address-lookup/types';
import clearAddressLookupReducer from './core/address-lookup/clear-address-lookup/reducers';
import { UPDATE_NETWORK_STATUS } from './core/update-network-status/types';
import updateNetworkStatusReducer from './core/update-network-status/reducers';
import { SET_AUTH_CONFIG } from './core/get-auth0-config/types';
import setAuthConfigReducer from './core/get-auth0-config/reducers';
import { APP_CORE_SET_APP_READY } from './core/set-app-ready/types';
import setAppReady from './core/set-app-ready/reducers';

import { AUTH_CLEAR_FLAG } from './auth/clear-flag/types';
import clearFlagReducer from './auth/clear-flag/reducers';
import { AUTH_CLEAR_ALL_FLAGS } from './auth/clear-all-flags/types';
import clearAllFlagsReducer from './auth/clear-all-flags/reducers';
import {
  AUTH_SIGN_IN_REQUEST,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_IN_FAILURE
} from './auth/sign-in/types';
import {
  signInRequestReducer,
  signInSuccessReducer,
  signInFailureReducer
} from './auth/sign-in/reducers';
import {
  FIREBASE_SIGN_IN_REQUEST,
  FIREBASE_SIGN_IN_SUCCESS,
  FIREBASE_SIGN_IN_FAILURE
} from './auth/firebase/types';
import {
  firebaseSignInRequestReducer,
  firebaseSignInSuccessReducer,
  firebaseSignInFailureReducer
} from './auth/firebase/reducers';
import {
  AUTH_SIGN_UP_REQUEST,
  AUTH_SIGN_UP_SUCCESS,
  AUTH_SIGN_UP_FAILURE
} from './auth/sign-up/types';
import {
  signUpRequestReducer,
  signUpSuccessReducer,
  signUpFailureReducer
} from './auth/sign-up/reducers';
import {
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILURE
} from './auth/reset-password/types';
import {
  resetPasswordRequestReducer,
  resetPasswordSuccessReducer,
  resetPasswordFailureReducer
} from './auth/reset-password/reducers';
import {
  AUTH_RESET_PASSWORD_NEW_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_NEW_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_NEW_PASSWORD_FAILURE
} from './auth/reset-password-new-password/types';
import {
  AUTH_CREATE_ACCOUNT_PASSWORD_REQUEST,
  AUTH_CREATE_ACCOUNT_PASSWORD_SUCCESS,
  AUTH_CREATE_ACCOUNT_PASSWORD_FAILURE
} from './auth/create-account-password/types';
import {
  resetPasswordNewPasswordRequestReducer,
  resetPasswordNewPasswordSuccessReducer,
  resetPasswordNewPasswordFailureReducer
} from './auth/reset-password-new-password/reducers';
import {
  createAccountPasswordRequestReducer,
  createAccountPasswordSuccessReducer,
  createAccountPasswordFailureReducer
} from './auth/create-account-password/reducers';
import { AUTH_UPDATE_CREDENTIALS } from './auth/update-credentials/types';
import updateCredentialsReducer from './auth/update-credentials/reducers';
import { AUTH_VALIDATE_CREDENTIALS } from './auth/validate-credentials/types';
import validateCredentialsReducer from './auth/validate-credentials/reducers';
import {
  AUTH_CHANGE_PASSWORD_REQUEST,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE
} from './auth/change-password/types';
import {
  changePasswordRequestReducer,
  changePasswordSuccessReducer,
  changePasswordFailureReducer
} from './auth/change-password/reducers';
import {
  AUTH_SOCIAL_AUTH_REQUEST,
  AUTH_SOCIAL_AUTH_SUCCESS,
  AUTH_SOCIAL_AUTH_FAILURE
} from './auth/social-auth/types';
import {
  socialAuthRequestReducer,
  socialAuthFailureReducer,
  socialAuthSuccessReducer
} from './auth/social-auth/reducers';
import { AUTH_UPDATE_MID_AUTH_STATE } from './auth/update-mid-auth-flow-state/types';
import updateMidAuthFlowStateReducer from './auth/update-mid-auth-flow-state/reducers';
import {
  AUTH_VALIDATE_ME_REQUEST,
  AUTH_VALIDATE_ME_SUCCESS,
  AUTH_VALIDATE_ME_FAILURE
} from './auth/validate-me/types';
import {
  validateMeRequestReducer,
  validateMeSuccessReducer,
  validateMeFailureReducer
} from './auth/validate-me/reducers';
import { AUTH_SET_NONCE } from './auth/set-nonce/types';
import setNonceReducer from './auth/set-nonce/reducers';
import {
  GET_CUSTOM_CONFIG_REQUEST,
  GET_CUSTOM_CONFIG_SUCCESS,
  GET_CUSTOM_CONFIG_FAILURE
} from './core/get-custom-config';
import {
  getCustomConfigRequestReducer,
  getCustomConfigSuccessReducer,
  getCustomConfigFailureReducer
} from './core/get-custom-config';
import {
  AUTH_BLOCK_USER_REQUEST,
  AUTH_BLOCK_USER_SUCCESS,
  AUTH_BLOCK_USER_FAILURE,
  blockUserRequestReducer,
  blockUserSuccessReducer,
  blockUserFailureReducer
} from './auth/block-user';
import {
  APP_SET_GUARD_ROUTE,
  APP_RESET_GUARD_ROUTE
} from './core/set-route-guard/types';
import {
  setRouteGuard,
  resetRouteGuard
} from './core/set-route-guard/reducers';
import {
  AUTH_SEND_SIGNIN_LINK_FAILURE,
  AUTH_SEND_SIGNIN_LINK_SUCCESS
} from './auth/send-sign-in-link/types';
import {
  sendSignInLinkSuccessReducer,
  sendSignInLinkFailureReducer
} from './auth/send-sign-in-link/reducers';

import {
  AUTH_SIGN_IN_WITH_LINK_REQUEST,
  AUTH_SIGN_IN_WITH_LINK_SUCCESS,
  AUTH_SIGN_IN_WITH_LINK_FAILURE
} from './auth/sign-in-with-link/types';
import {
  signInWithLinkRequestReducer,
  signInWithLinkSuccessReducer,
  signInWithLinkFailureReducer
} from './auth/sign-in-with-link/reducers';
import {
  AUTH_START_USER_LOGIN_FAILURE,
  AUTH_START_USER_LOGIN_REQUEST,
  AUTH_START_USER_LOGIN_SUCCESS
} from './auth/start-user-login/types';
import {
  startUserLoginFailureReducer,
  startUserLoginRequestReducer,
  startUserLoginSuccessReducer
} from './auth/start-user-login/reducers';
import {
  AUTH_START_BASIC_AUTH_REQUEST,
  AUTH_START_BASIC_AUTH_SUCCESS,
  AUTH_START_BASIC_AUTH_FAILURE
} from './auth/start-basic-auth/types';
import { startBasicAuthFailureReducer, startBasicAuthRequestReducer, startBasicAuthSuccessReducer } from './auth/start-basic-auth/reducers';


export default function (state = initialState, action) {
  switch (action.type) {
    case APP_CONFIGURE_APP:
      return configureAppReducer(state, action);

    case GET_CUSTOM_CONFIG_REQUEST:
      return getCustomConfigRequestReducer(state, action);
    case GET_CUSTOM_CONFIG_SUCCESS:
      return getCustomConfigSuccessReducer(state, action);
    case GET_CUSTOM_CONFIG_FAILURE:
      return getCustomConfigFailureReducer(state, action);

    case APP_UPDATE_STATE_VERSION:
      return updateStateVersionReducer(state, action);
    case APP_UPDATE_PRIVACY_POLICY_VERSION:
      return updatePrivacyPolicyVersionReducer(state, action);

    case GET_ACTIVE_LINES_OF_BUSINESS_REQUEST:
      return getActiveLinesOfBusinessRequestReducer(state, action);
    case GET_ACTIVE_LINES_OF_BUSINESS_SUCCESS:
      return getActiveLinesOfBusinessSuccessReducer(state, action);
    case GET_ACTIVE_LINES_OF_BUSINESS_FAILURE:
      return getActiveLinesOfBusinessFailureReducer(state, action);

    case UPDATE_SHOW_WELCOME_SCREEN:
      return updateShowWelcomeScreenReducer(state, action);
    case UPDATE_SHOW_COOKIE_POLICY:
      return updateShowCookiePolicyReducer(state, action);

    case GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_REQUEST:
      return assignQuoteToUserRequestReducer(state, action);
    case GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_SUCCESS:
      return assignQuoteToUserSuccessReducer(state, action);
    case GUEST_QUOTES_ASSIGN_QUOTE_TO_USER_FAILURE:
      return assignQuoteToUserFailureReducer(state, action);

    case GUEST_QUOTES_SAVE_QUOTE_ID:
      return saveQuoteIdReducer(state, action);
    case GUEST_QUOTES_CLEAR_QUOTE_IDS:
      return clearQuoteIdsReducer(state, action);

    case TRACKING_SET_EXTERNAL_ID:
      return setExternalIdReducer(state, action);

    case ALERTS_QUEUE_ALERT:
      return queueAlertReducer(state, action);
    case ALERTS_CLEAR_ALERT:
      return clearAlertReducer(state, action);
    case ALERTS_CLEAR_ALL_ALERTS:
      return clearAllAlertsReducer(state, action);

    case SEARCH_ADDRESS_LOOKUP_REQUEST:
      return searchAddressLookupRequestReducer(state, action);
    case SEARCH_ADDRESS_LOOKUP_SUCCESS:
      return searchAddressLookupSuccessReducer(state, action);
    case SEARCH_ADDRESS_LOOKUP_FAILURE:
      return searchAddressLookupFailureReducer(state, action);

    case CLEAR_ADDRESS_LOOKUP:
      return clearAddressLookupReducer(state, action);

    case UPDATE_NETWORK_STATUS:
      return updateNetworkStatusReducer(state, action);

    case SET_AUTH_CONFIG:
      return setAuthConfigReducer(state, action);

    case APP_CORE_SET_APP_READY:
      return setAppReady(state, action);

    case AUTH_CLEAR_FLAG:
      return clearFlagReducer(state, action);
    case AUTH_CLEAR_ALL_FLAGS:
      return clearAllFlagsReducer(state, action);

    case AUTH_SIGN_IN_REQUEST:
      return signInRequestReducer(state, action);
    case AUTH_SIGN_IN_SUCCESS:
      return signInSuccessReducer(state, action);
    case AUTH_SIGN_IN_FAILURE:
      return signInFailureReducer(state, action);

    case FIREBASE_SIGN_IN_REQUEST:
      return firebaseSignInRequestReducer(state, action);
    case FIREBASE_SIGN_IN_SUCCESS:
      return firebaseSignInSuccessReducer(state, action);
    case FIREBASE_SIGN_IN_FAILURE:
      return firebaseSignInFailureReducer(state, action);

    case AUTH_SIGN_UP_REQUEST:
      return signUpRequestReducer(state, action);
    case AUTH_SIGN_UP_SUCCESS:
      return signUpSuccessReducer(state, action);
    case AUTH_SIGN_UP_FAILURE:
      return signUpFailureReducer(state, action);

    case AUTH_RESET_PASSWORD_REQUEST:
      return resetPasswordRequestReducer(state, action);
    case AUTH_RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccessReducer(state, action);
    case AUTH_RESET_PASSWORD_FAILURE:
      return resetPasswordFailureReducer(state, action);

    case AUTH_RESET_PASSWORD_NEW_PASSWORD_REQUEST:
      return resetPasswordNewPasswordRequestReducer(state, action);
    case AUTH_RESET_PASSWORD_NEW_PASSWORD_SUCCESS:
      return resetPasswordNewPasswordSuccessReducer(state, action);
    case AUTH_RESET_PASSWORD_NEW_PASSWORD_FAILURE:
      return resetPasswordNewPasswordFailureReducer(state, action);

    case AUTH_CREATE_ACCOUNT_PASSWORD_REQUEST:
      return createAccountPasswordRequestReducer(state, action);
    case AUTH_CREATE_ACCOUNT_PASSWORD_SUCCESS:
      return createAccountPasswordSuccessReducer(state, action);
    case AUTH_CREATE_ACCOUNT_PASSWORD_FAILURE:
      return createAccountPasswordFailureReducer(state, action);

    case AUTH_UPDATE_CREDENTIALS:
      return updateCredentialsReducer(state, action);
    case AUTH_VALIDATE_CREDENTIALS:
      return validateCredentialsReducer(state, action);

    case AUTH_CHANGE_PASSWORD_REQUEST:
      return changePasswordRequestReducer(state, action);
    case AUTH_CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccessReducer(state, action);
    case AUTH_CHANGE_PASSWORD_FAILURE:
      return changePasswordFailureReducer(state, action);

    case AUTH_SOCIAL_AUTH_REQUEST:
      return socialAuthRequestReducer(state, action);
    case AUTH_SOCIAL_AUTH_SUCCESS:
      return socialAuthSuccessReducer(state, action);
    case AUTH_SOCIAL_AUTH_FAILURE:
      return socialAuthFailureReducer(state, action);

    case AUTH_UPDATE_MID_AUTH_STATE:
      return updateMidAuthFlowStateReducer(state, action);

    case AUTH_VALIDATE_ME_REQUEST:
      return validateMeRequestReducer(state, action);
    case AUTH_VALIDATE_ME_SUCCESS:
      return validateMeSuccessReducer(state, action);
    case AUTH_VALIDATE_ME_FAILURE:
      return validateMeFailureReducer(state, action);

    case AUTH_BLOCK_USER_REQUEST:
      return blockUserRequestReducer(state, action);
    case AUTH_BLOCK_USER_SUCCESS:
      return blockUserSuccessReducer(state, action);
    case AUTH_BLOCK_USER_FAILURE:
      return blockUserFailureReducer(state, action);

    case APP_RESET_GUARD_ROUTE:
      return resetRouteGuard(state, action);
    case APP_SET_GUARD_ROUTE:
      return setRouteGuard(state, action);

    case AUTH_SET_NONCE:
      return setNonceReducer(state, action);

    case AUTH_SEND_SIGNIN_LINK_SUCCESS:
      return sendSignInLinkSuccessReducer(state, action);
    case AUTH_SEND_SIGNIN_LINK_FAILURE:
      return sendSignInLinkFailureReducer(state, action);

    case AUTH_SIGN_IN_WITH_LINK_REQUEST:
      return signInWithLinkRequestReducer(state, action);
    case AUTH_SIGN_IN_WITH_LINK_SUCCESS:
      return signInWithLinkSuccessReducer(state, action);
    case AUTH_SIGN_IN_WITH_LINK_FAILURE:
      return signInWithLinkFailureReducer(state, action);

    case AUTH_START_USER_LOGIN_REQUEST:
      return startUserLoginRequestReducer(state, action);
    case AUTH_START_USER_LOGIN_SUCCESS:
      return startUserLoginSuccessReducer(state, action);
    case AUTH_START_USER_LOGIN_FAILURE:
      return startUserLoginFailureReducer(state, action);

    case AUTH_START_BASIC_AUTH_REQUEST:
      return startBasicAuthRequestReducer(state, action);
    case AUTH_START_BASIC_AUTH_SUCCESS:
      return startBasicAuthSuccessReducer(state, action);
    case AUTH_START_BASIC_AUTH_FAILURE:
      return startBasicAuthFailureReducer(state, action);
    default:
      return state;
  }
}
