import React from 'react'
import { lowerCase } from 'lodash';
import { BladeButton, BladeModal } from "@aventus/blade";
import css from './index.css';

const RemoveExposureModal:React.FC<IRemoveExposureModal> = (props) => {
  const label = props.label ? lowerCase(props.label) : "exposure"

  // disable ability to close the modal while the call is being resolved
  const onClose = () => {
    if (props.isLoading) return null;
    return props.close();
  };

  return (
    <BladeModal
      withFrame
      close={onClose}
      title={`Remove ${label}`}
    >
      <p className={css.description}>{`Are you sure you want to remove this ${label}?`}</p>
      <div>
        <BladeButton className={css.action_button} isDisabled={props.isLoading} isWorking={props.isLoading} isDestructive onClick={props.onRemove}>Remove</BladeButton>
        <BladeButton className={css.action_button} isDisabled={props.isLoading} onClick={props.close} variant="secondary">Cancel</BladeButton>
      </div>
    </BladeModal>
  )
}

interface IRemoveExposureModal {
  onRemove: () => void
  close: () => void | null
  isLoading: boolean
  label: string
}

export default RemoveExposureModal;
