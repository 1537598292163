import { CreatAddressClient, Search, Format } from './types';
import { createClient } from '@aventus/client';
import { interceptResponse } from './intercept-response';

export const createAddressClient: CreatAddressClient = function (options) {
  const aventusClient = createClient(options);

  aventusClient.interceptors.response.use(
    interceptResponse.onFulfilled,
    interceptResponse.onRejected
  );

  const search: Search = async (query, countryCodes) => {
    const response = await aventusClient.post('/search', {
      query,
      countryCodes
    });

    return response.data;
  };

  const format: Format = async (query: string) => {
    const response = await aventusClient.post(`/detail`, { query });

    return response.data;
  };

  return {
    search,
    format
  };
};
