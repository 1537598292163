import React from 'react';
import PropTypes from 'prop-types';
import compiler from './compiler';
import cx from 'classnames';

// see: https://marked.js.org/#/USING_ADVANCED.md#options
const markedConfig = {
  mangle: false
};

export const BladeMarkdown = ({
  size = 'base',
  action,
  style = {},
  className = '',
  markdown = ''
}) => {
  const defaultSize = size === 'default' ? 'base' : size;
  const compile = compiler({
    size: defaultSize,
    action,
    style
  });

  return (
    <div className={cx(className, 'space-col', defaultSize, 'inside')}>
      {compile(markdown, markedConfig).tree}
    </div>
  );
};

BladeMarkdown.propTypes = {
  markdown: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  action: PropTypes.any,
  style: PropTypes.any
};
