import React, { ReactElement } from 'react';
import css from './index.css';

export const ShimUl: React.FunctionComponent<IShimUl> = ({ items }) => (
  <ul className={css.ul}>
    {items.map((child: ReactElement, i: number) =>
      React.cloneElement(child, { key: i })
    )}
  </ul>
);

interface IShimUl {
  items: ReactElement[];
}
