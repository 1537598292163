import * as React from 'react';
import { BladeList, BladeButton } from '@aventus/blade';
import css from './index.css';
import { IDataStore } from '../bnp-component';

export const BNPOverview: React.FunctionComponent<IBNPOverview> = ({
  store,
  formCallback
}) => {
  return (
    <div className={css.bnpSummaryWrapper}>
      <BladeList
        listTitle="Bank details"
        list={[
          {
            label: `Bank account number`,
            value: store.bankAccountNumber
          },
          {
            label: `Sort code`,
            value: store.sortCode
          },
          {
            label: `Is this your bank account?`,
            value: store.isApplicantBankAccount?.value ? 'Yes' : 'No'
          },
          {
            label: `Is this a joint account?`,
            value: store.isJointAccount?.value ? 'Yes' : 'No'
          }
        ]}
      />

      <BladeButton onClick={() => formCallback(false)} variant="secondary">
        Edit
      </BladeButton>
    </div>
  );
};

export interface IBNPOverview {
  store: IDataStore;
  formCallback: (arg: boolean) => void;
}
