import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import {
  IRenewalInfo,
  IRenewalSettings,
  PolicyBundle
} from '@aventus/platform';

const useAutoRenewalSettings = function (
  policyId: string,
  policyBundle?: PolicyBundle
) {
  const symphony = useSymphonyClient();
  const [isSettingStatus, setisSettingStatus] = React.useState<boolean>(false);
  const [renewalStatus, setRenewalStatus] = React.useState<boolean>();
  const [renewalSettings, setRenewalSettings] = React.useState<
    IRenewalSettings
  >();

  const getRenewalSettings = async (productID: number) => {
    try {
      const settings = await symphony.getRenewalSettings(productID);

      if (settings) {
        setRenewalSettings(settings);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (policyBundle && renewalStatus === undefined) {
      setRenewalStatus(policyBundle.renewal?.allowAutoBind);
    }
    if (policyBundle && renewalSettings === undefined) {
      getRenewalSettings(policyBundle.policy.productID);
    }
  }, [symphony, policyBundle, renewalStatus, renewalSettings]);

  const toggleOnChange = async (status: boolean) => {
    setisSettingStatus(true);
    symphony
      .setAutoRenewalStatus(policyId, status)
      .then((response: IRenewalInfo) => {
        setRenewalStatus(response.allowAutoBind);
        setTimeout(() => setisSettingStatus(false), 500);
      })
      .catch((error: Error) => console.error(error));
  };

  return {
    renewalStatus,
    renewalSettings,
    isSettingStatus,
    toggleOnChange
  };
};

export default useAutoRenewalSettings;
