import React from 'react';
import { BladeFormFieldset, BladeInputText } from '@aventus/blade';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { SignInFormData } from './signInComponent';
import { AuthenticationConfiguration } from '@aventus/platform';
import isemail from 'isemail';

type EmailFieldProps = {
  errors: DeepMap<SignInFormData, FieldError>;
  control: Control<SignInFormData>;
  authenticationConfig: AuthenticationConfiguration;
  showLabel?: boolean;
  showEmailConfirmationReason?: boolean;
};

const EmailField: React.FC<EmailFieldProps> = ({
  errors,
  control,
  authenticationConfig,
  showLabel = true,
  showEmailConfirmationReason = false
}) => {
  return (
    <>
      {showEmailConfirmationReason && (
        <p>
          {
            authenticationConfig?.signIn?.oneTimeSignInLink
              ?.emailConfirmationHelpText
          }
        </p>
      )}

      <BladeFormFieldset errorMessage={errors?.email && errors.email.message}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message:
                authenticationConfig?.signIn?.validationMessages
                  ?.usernameRequired || 'Email is required'
            },
            validate: {
              isEmail: (value: string) =>
                isemail.validate(value, { minDomainAtoms: 2 })
                  ? true
                  : authenticationConfig?.signIn?.validationMessages
                      ?.emailAddressInvalid ||
                    'Email address provided is invalid'
            }
          }}
          render={({ onChange, onBlur, value }) => (
            <BladeInputText
              name="email"
              label={
                showLabel
                  ? authenticationConfig?.signIn?.usernameLabel || 'Your email'
                  : ''
              }
              type="text"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </BladeFormFieldset>
    </>
  );
};

export default EmailField;
