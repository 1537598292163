import * as React from 'react';

import { BladeIcon } from '@aventus/blade';
import { ReactNode } from 'react';
import styles from './css/styles.css';

interface PackagePanelProps {
  questionReferenceID: string;
  questionText: string | null;
  packageIsSelected: boolean;
  children: ReactNode;
}

const PackagePanel = (props: PackagePanelProps) => {
  return (
    <div
      className={`${props.questionReferenceID} ${styles.packageWrapper}${
        props.packageIsSelected ? ` ${styles.selected}` : ''
      }`}
    >
      {props.packageIsSelected && (
        <div className={styles.tickIconWrapper}>
          <BladeIcon name={'faCheck'} color="#FFF" />
        </div>
      )}

      <div className={styles.titleWrapper}>
        <div className={styles.packageIcon}></div>
        <div>{props.questionText}</div>
      </div>

      {props.children}
    </div>
  );
};

export default PackagePanel;
