import * as React from 'react';
import { PaymentProvidersConfiguration } from '@aventus/configuration';
import { useAsyncEffect } from 'use-async-effect';
import { useConfigurationClient } from './context';

export function useGetPaymentProvidersConfiguration (): UseGetPaymentProvidersConfiguration {

  const client = useConfigurationClient();
  const [ paymentProvidersConfiguration, setPaymentProvidersConfiguration ] = React.useState<PaymentProvidersConfiguration | undefined>(undefined);

  useAsyncEffect(async () => {
    const _paymentProvidersConfiguration = await client.getPaymentProvidersConfiguration();
    setPaymentProvidersConfiguration(_paymentProvidersConfiguration);
  }, []);

  return {
    paymentProvidersConfiguration
  };

}

interface UseGetPaymentProvidersConfiguration {
  paymentProvidersConfiguration: PaymentProvidersConfiguration | undefined
}
